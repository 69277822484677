import React, { PureComponent } from "react";
import Button from '@material-ui/core/Button';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-modal';
import ISO6391 from 'iso-639-1';

class MyLanguageSelection extends PureComponent {
  
    constructor(props) {
        
        super(props);

        this.state = {
            name: props.field.name,
            originalValue: props.originalValue,
            currentValue: props.originalValue,
            showLanguageSelection: false
        };


    }

    toggleLanguageSelectionMenu = () => {  

        this.setState({  
            showLanguageSelection: !this.state.showLanguageSelection 
        });  

    }  

    handleError = error => {
        this.props.form.setFieldError(this.state.name, error);
    };

    moveUp = (index) => {

        //console.log(index, this.state.currentValue)

        let arr = this.state.currentValue;
        [arr[index],arr[index-1]] = [arr[index-1],arr[index]]; 
        
        this.setState({
            currentValue: arr,
        });
        
        this.props.form.setFieldTouched(`${this.state.name}.value`);
        this.props.form.setFieldValue(this.state.name, arr );

        this.forceUpdate();

    };

    moveDown = (index) => {

        let arr = this.state.currentValue;
        [arr[index],arr[index+1]] = [arr[index+1],arr[index]]; 
        
        this.setState({
            currentValue: arr,
        });
        
        this.props.form.setFieldTouched(`${this.state.name}.value`);
        this.props.form.setFieldValue(this.state.name, arr );

        this.forceUpdate();

    };
    
    removeLanguage = async (index) => {

        var arr = this.state.currentValue;
        
        await delete arr[index]
        var remainingItems = arr.filter(el => { return el != null; });

        this.setState({
            currentValue: remainingItems
        });
    
        this.props.form.setFieldTouched(`${this.state.name}.value`);
        this.props.form.setFieldValue(this.state.name, remainingItems );

        this.forceUpdate();

    };

    addLanguage = async (code) => {  

        var arr = this.state.currentValue;
        
        await arr.push(code)

        this.setState({
            currentValue: arr
        });
    
        this.props.form.setFieldTouched(`${this.state.name}.value`);
        this.props.form.setFieldValue(this.state.name, arr );

        this.forceUpdate();
        
        if (this.props.doForceLanguageUpdate) {

            // console.log('is set, do it')
            this.props.doForceLanguageUpdate(arr);

        }

        this.toggleLanguageSelectionMenu();

    }  


    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    render() {

        //console.log( this.props );
        //console.log( this.state );
        //console.log( this.capitalize(ISO6391.getNativeName('en')) ); // 'English'
        //console.log( this.capitalize(ISO6391.getNativeName('et')) ); // 'English'
        //console.log( ISO6391.getAllCodes() ); // 'English'

        return (
            
            <>

            { this.props.label ? <LabelStyled>{this.props.label}</LabelStyled> : null}

            <table style={{width: 400}}>
                <tbody>
                { 
                    this.state.currentValue.map((language, index) => {


                        const languageName = this.capitalize(ISO6391.getNativeName(language));

                        return (

                            <tr key={index} style={{height: 48, width: 200}}>
                                <td>
                                    <LanguageName>{languageName}</LanguageName>
                                </td>
                                <td>
                                    
                                 
                                    <GreyButton onClick={() => this.moveUp(index)} disabled={ index === 0 ? true : false} >
                                        <FAIcon icon={faChevronUp} color={ index === 0 ? '#fff' : '#cbcbcb'} />
                                    </GreyButton>
                                    
                                    <GreyButton onClick={() => this.moveDown(index)} disabled={ index === this.state.currentValue.length-1 ? true : false} >
                                        <FAIcon icon={faChevronDown} color={ index === this.state.currentValue.length-1 ? '#fff' : '#cbcbcb'} />
                                    </GreyButton>

                                    <GreyButton onClick={() => this.removeLanguage(index)} disabled={ this.state.currentValue.length === 1 ? true : false} >
                                        <FAIcon icon={faTrashAlt}/>
                                    </GreyButton>

                                </td>
                            </tr>

                        );
                    })
                }
                </tbody>
            </table>

            <LinkButton 
                onClick={this.toggleLanguageSelectionMenu} 
            >
                Add a language
            </LinkButton>


            <Modal
                isOpen={this.state.showLanguageSelection}
                //onAfterOpen={afterOpenModal}
                onRequestClose={this.toggleLanguageSelectionMenu}
                style={customStyles}
                // contentLabel="Example Modal"
                onAfterOpen={() => {
                    document.body.style.top = `0px`
                    document.body.style.width = `100vw`
                    document.body.style.position = 'fixed'
                }}

                onAfterClose={() => {
                    const scrollY = document.body.style.top
                    document.body.style.position = ''
                    document.body.style.top = ''
                    document.body.style.width = ''
                    window.scrollTo(0, parseInt(scrollY || '0') * -1)
                }}
            >

                {
                    ISO6391.getAllCodes().map((code, index) => {
                    
                        return (
                            <div key={index} >
                                <ModalItem
                                    onClick={() => { this.addLanguage(code); } }
                                >

                                    { this.capitalize(ISO6391.getNativeName(code)) }
                                
                                </ModalItem>

                                <Spacing/>
                            </div>
                        )

                    })


                }

                <ButtonArea>
                                                            
                    <StyledCancel
                        onClick={() => {
                            this.toggleLanguageSelectionMenu();
                        }}
                        >
                        Cancel
                    </StyledCancel>

                </ButtonArea>

            </Modal>


            </>
        );
    }
}

const GreyButton = styled(Button)`
    && {
    height: 40px;

    min-width: 40px;
    max-width: 40px;
    border: 2px solid #EBEBEB;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);
    /* border-radius: 20px; */

    margin: 0 8px 0 0; 

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;
    font-weight: 400;
    font-size: 17px;

    &:hover {
        background-color: #ebebeb;
        border: 2px solid #cbcbcb;
        opacity: 0.9;
        }
    
    &:disabled {
        background-color: #fff;
        border: 2px solid #fff;
        color: #fff;
        box-shadow: 0 0 0 0;
        }

    }
    
`;

const FAIcon = styled(FontAwesomeIcon)`
    && {
    font-size: 16px;
    font-weight: 300;
    }
`;

const LinkButton = styled.div`
    
    color: #4C9AFF;

    margin: 0 8px 0 0; 

    font-weight: 400;
    font-size: 16px;

    &:hover {
        text-decoration: underline;
        cursor: pointer
        }

`;

const LabelStyled = styled.div`
    width: 100%;
    margin: 0px;
    padding: 0px;
    color: #333333;
    font-size: 16px;
    
    margin-bottom: 4px;
    margin-top: 0px;
`;

const LanguageName = styled.div`
    
    width: 100%;
    margin: 0px;
    padding: 0px;
    color: #333333;
    
    font-size: 16px;
    font-weight: 400;

    margin-left: -3px;

`;


const customStyles = {
    content : {
        boxShadow: '0 20px 50px rgba(0, 0, 0, 0.13)',
        borderRadius: '5px',
        height: '85%',
        overflow : 'auto',
        width: '400px',
        top                                 : '50%',
        left                                : '50%',
        right                               : 'auto',
        bottom                              : 'auto',
        marginRight                         : '-50%',
        transform                           : 'translate(-50%, -50%)'
    },
    overlay: {zIndex: 1000}

  };


  const Spacing = styled.div`

  width: 24px;
  display: absolute;
  
`;


const ModalItem = styled.div`

  width: 100%;

  margin: 0px;

  border: 1px solid #fff;

  padding-top: 12px;
  padding-bottom: 12px;


  justify-content: center;
  align-items: center;
  text-align: center;

  cursor: pointer;

  background: #ffffff;

  font-size: 17px;
  font-weight: 400;

  color: #333333;

  &:hover {
      background: #f8f8f8;
      color: #4C9AFF;
      text-decoration: underline;
      border: 1px solid #4C9AFF;
      }
  
`;

const ButtonArea = styled.div`

    color: #333333;
    align-items: flex-end;

    margin: 0px 0px 16px 0px;

`;


const StyledCancel = styled(Button)`
    && {
    height: 40px;
    top: 18px;
    color: #333333;
    width: 100%;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    }
    
`;

export default MyLanguageSelection;
