import React, { PureComponent } from 'react'

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

import { withStore } from '@spyna/react-store'
import { withApollo } from "react-apollo";
import { withRouter } from 'react-router-dom';
import { EventCard } from "../events/EventCard";
import Footer from '../../modules/components/Footer/Footer'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import Button from '@material-ui/core/Button';
import Modal from 'react-modal';

import { Formik, Form, Field } from "formik";
import { RadioButtonGroup } from 'react-rainbow-components';
import InputNumber from 'rc-input-number';

import 'react-dates/initialize';
import { DayPickerRangeController } from 'react-dates'
import "./../../styles/ReactDatesOverride.css"

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';

import styled from 'styled-components';

import { CategoryButtons } from "./../components/CategoryButtons";
import MyPlacesAutoComplete from "./../components/MyPlacesAutoComplete";
import { MySelectField } from "./../components/MySelectField";
import getIPInfo from '../../utils/getIPInfo'
//import getBrowserLocales from '../../utils/getBrowserLocales'
import DateRange from '../../utils/DateRange'


import moment from "moment"; 
import i18next from "i18next";


import gql from "graphql-tag"
import { Query } from "react-apollo";


const getEvents = gql`
    query (
        $around: Int, $lng: String, $lat: String, 
        $category: Int, 
        $startDate: String, $endDate: String,
        $isPro: Boolean, $isVerified: Boolean, $isFoundingMember: Boolean, $isTopRated: Boolean, $isTop2023: Boolean, $isTop2024: Boolean, $isTop2025: Boolean, $isTop2026: Boolean, $isTop2027: Boolean, $isTop2028: Boolean, $isTop2029: Boolean, $isTop2030: Boolean, $isTop2031: Boolean, $isTop2032: Boolean, $isTop2033: Boolean, 
        $virtual: Boolean, $languages: [String], $minPrice: Float, $maxPrice: Float,
        ) {
            getEvents( 
                around: $around, lng: $lng, lat: $lat, 
                category: $category, 
                startDate: $startDate, endDate: $endDate,
                isPro: $isPro, isVerified: $isVerified, isFoundingMember: $isFoundingMember, isTopRated: $isTopRated, isTop2023: $isTop2023, isTop2024: $isTop2024, isTop2025: $isTop2025, isTop2026: $isTop2026, isTop2027: $isTop2027, isTop2028: $isTop2028, isTop2029: $isTop2029, isTop2030: $isTop2030, isTop2031: $isTop2031, isTop2032: $isTop2032, isTop2033: $isTop2033, 
                virtual: $virtual, languages: $languages, minPrice: $minPrice, maxPrice: $maxPrice, 
                ) { 
                    id, status, title { lng, title, summary }, categories,
                    dates { id, start, end, st, et, lenDays, lenHours, price, currency, limit, reserved, booked, },
                    virtual, coverPhoto, cpw, cph,
                    address, addr_locality, addr_area2, addr_area1, country,
                    location { type, coordinates, }
                    languages, 

                    premiumURL, publicURL,

                    sections { title { lng, title }, desc { lng, desc }, },

                    albums { name { lng, name }, category, coverPhotoURL, cpw, cph, },
                    
                    hosts { memberId, name, title { lng, title }, intro { lng, intro }, coverPhoto, cpw, cph, } 

                    reviewsTotal, avgRating, cancellations, instaBook, minLen,

                    isPro, isVerified, isFoundingMember, isTopRated, isTop2023, isTop2024, isTop2025, isTop2026, isTop2027, isTop2028, isTop2029, isTop2030, isTop2031, isTop2032, isTop2033,

                    serviceProviderName, serviceProviderAddress, serviceProviderEmail,
                }
        }
`;

// const getIPInfoQuery = gql`
//     query ($clientIP: String!) {
//         getIPInfo(clientIP: $clientIP) {
//             ip, city, region, country, country_code, currency, currency_symbol, lat, lng, src
//         }
//     }
// `;
// let amazonIP = ''
// amazonIP = await axios.get('https://checkip.amazonaws.com/')
// await this.props.client.query({ query: getIPInfoQuery, variables: { ip: amazonIP.data }, fetchPolicy: 'no-cache', })
// .then(result => { console.log('GraphQL response ', result.data) })
// .catch(error => { ipData = fallbackIPInfo; })

const today = new Date();


class C extends PureComponent {

    constructor(props) {
        
        super(props);

        this.state = {
            catFilterSetEvent: false,
            catFilterModalEvent: false,
            catFilterCategoryEvent: "",
            catFilterNameEvent: "",

            ipCity: 'San Francisco',
            ipRegion: 'California',
            ipCountry: 'United States',
            ipCurrency: 'USD',
            ipLng: '-122.4952',  
            ipLat: '37.7833',
            ipAddress: 'San Francisco, California, United States',

            setLocationFilter: false,
            locationDistance: 300,
            distanceMode: '0',
            modalTopPos: 100,
            modalHeight: 350,
            modalMargin: '0',

            setDatesFilter: false,
            dateFilterModal: false,
            startDate: null,
            endDate: null,

            moreFiltersSet: false,
            moreFiltersModal: false,
            minPrice: 0,
            maxPrice: 9999,
            eventDuration: 0,
            eventType: 0,
            lngEN: false,
            lngES: false,
            lngFR: false,
            lngIT: false,
            lngDE: false,
            lngRU: false,
            chkPro: false,
            chkVerified: false,
            chkInstant: false,

            mountCompleted: false,
            
        }

        //this.handleDatePickerChange = this.handleDatePickerChange.bind(this)
     
    }

    // handleDatePickerChange (date) {

    //     const dates = this.state.selectedAvailabilityDates
    //     const formattedDate = moment(date).format('YYYY-MM-DD');

    //     const newDates = dates.includes(formattedDate) ? dates.filter(d => formattedDate !== d) : [...dates, formattedDate] 

    //     this.setState({ selectedAvailabilityDates: newDates })

    //     this.props.onChange && this.props.onChange(newDates.toJS())

    // }

  
    async componentDidMount() {
   
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

        let ipData
        const today = new Date();

        // console.log('ipCity: ', localStorage.getItem('ipCity'))
        if ((localStorage.getItem('ipCity') === null) 
            || (localStorage.getItem('ipCity') === 'null') 
            || (localStorage.getItem('ipCity') === 'undefined') 
            || (localStorage.getItem('ipLocatedDate') === null) 
            || (localStorage.getItem('ipLocatedDate') !== moment(today).format('YYYY-MM-DD'))) {
        
                // NO geolocation in the local storage yet. gelocate!
                // and no need to do it more than once a day. YEs there are circumstances where people travel etc, but then they can manually change the addess, and that will eb saved also!
                // but once a day vs every request makes a massive difference at api usage cost when running at scale.
                ipData = await getIPInfo(); 
                //console.log(ipData)

                let ipAddress
                if (ipData.city !== ipData.region) {
                    ipAddress = ipData.city + ', ' + ipData.region
                } else {
                    ipAddress = ipData.city
                }
                ipAddress = ipAddress + ', ' + ipData.country
                //console.log('ipAddress', ipAddress)

                //save ip date to the local storage immediatelly after geolocated!
                localStorage.setItem('ipCity', ipData.city);
                localStorage.setItem('ipRegion', ipData.region);
                localStorage.setItem('ipCountry', ipData.country);
                localStorage.setItem('ipCurrency', ipData.currency);
                localStorage.setItem('ipLng', ipData.lng);
                localStorage.setItem('ipLat', ipData.lat);
                localStorage.setItem('ipAddress', ipAddress);
                localStorage.setItem('ipLocatedDate', moment(today).format('YYYY-MM-DD'));

        }       


        if ((localStorage.getItem('catFilterCategoryEvent') === 'null') 
            || (localStorage.getItem('catFilterCategoryEvent') === null)) {
                localStorage.setItem('catFilterCategoryEvent', "");
        }
        if ((localStorage.getItem('locationDistance') === 'null') 
            || (localStorage.getItem('locationDistance') === null)) {
                localStorage.setItem('locationDistance', 300);
                localStorage.setItem('distanceMode', '0');
        }
        if ((localStorage.getItem('startDate') === 'null') 
            || (localStorage.getItem('startDate') === 'Invalid date')) {
                localStorage.setItem('startDate', null);
                localStorage.setItem('endDate', null);
        }


       



        if (JSON.parse(localStorage.getItem('moreFiltersSet')) === true) {
            this.setState({ 
                moreFiltersSet: JSON.parse(localStorage.getItem('moreFiltersSet')),
                minPrice: localStorage.getItem('minPrice'), 
                maxPrice: localStorage.getItem('maxPrice'), 
                eventDuration: localStorage.getItem('eventDuration'),
                eventType: localStorage.getItem('eventType'), 
                lngEN: JSON.parse(localStorage.getItem('lngEN')),
                lngES: JSON.parse(localStorage.getItem('lngES')),
                lngFR: JSON.parse(localStorage.getItem('lngFR')),
                lngIT: JSON.parse(localStorage.getItem('lngIT')),
                lngDE: JSON.parse(localStorage.getItem('lngDE')),
                lngRU: JSON.parse(localStorage.getItem('lngRU')),
                chkPro: JSON.parse(localStorage.getItem('chkPro')),
                chkVerified: JSON.parse(localStorage.getItem('chkVerified')),
                chkInstant: JSON.parse(localStorage.getItem('chkInstant')),

                catFilterSetEvent: JSON.parse(localStorage.getItem('catFilterSetEvent')), 
                catFilterCategoryEvent: localStorage.getItem('catFilterCategoryEvent'),
                catFilterNameEvent: localStorage.getItem('catFilterNameEvent'),
                
                ipCity: localStorage.getItem('ipCity'),
                ipRegion: localStorage.getItem('ipRegion'),
                ipCountry: localStorage.getItem('ipCountry'),
                ipCurrency: localStorage.getItem('ipCurrency'),
                ipLng: localStorage.getItem('ipLng'), 
                ipLat: localStorage.getItem('ipLat'),
                ipAddress: localStorage.getItem('ipAddress'), 

                locationDistance: localStorage.getItem('locationDistance'), 
                distanceMode: localStorage.getItem('distanceMode'),

                setDatesFilter: JSON.parse(localStorage.getItem('setDatesFilter')),
                startDate: localStorage.getItem('startDate') === 'null' || localStorage.getItem('startDate') === null || localStorage.getItem('startDate') === 'Invalid date' ? null : moment(localStorage.getItem('startDate')),
                endDate: localStorage.getItem('endDate') === 'null' || localStorage.getItem('endDate') === null || localStorage.getItem('endDate') === 'Invalid date' ? null : moment(localStorage.getItem('endDate')),

                mountCompleted: true,

            });
        } else {

            this.setState({ 

                catFilterSetEvent: JSON.parse(localStorage.getItem('catFilterSetEvent')), 
                catFilterCategoryEvent: localStorage.getItem('catFilterCategoryEvent'),
                catFilterNameEvent: localStorage.getItem('catFilterNameEvent'),
                
                ipCity: localStorage.getItem('ipCity'),
                ipRegion: localStorage.getItem('ipRegion'),
                ipCountry: localStorage.getItem('ipCountry'),
                ipCurrency: localStorage.getItem('ipCurrency'),
                ipLng: localStorage.getItem('ipLng'), 
                ipLat: localStorage.getItem('ipLat'),
                ipAddress: localStorage.getItem('ipAddress'), 
    
                locationDistance: localStorage.getItem('locationDistance'), 
                distanceMode: localStorage.getItem('distanceMode'),
    
                setDatesFilter: JSON.parse(localStorage.getItem('setDatesFilter')),
                startDate: localStorage.getItem('startDate') === 'null' || localStorage.getItem('startDate') === null || localStorage.getItem('startDate') === 'Invalid date' ? null : moment(localStorage.getItem('startDate')),
                endDate: localStorage.getItem('endDate') === 'null' || localStorage.getItem('endDate') === null || localStorage.getItem('endDate') === 'Invalid date' ? null : moment(localStorage.getItem('endDate')),
    
                mountCompleted: true,

            });

        }
        

        
    
    }


    componentDidUpdate(prevProps, prevState) {

        localStorage.setItem('catFilterSetEvent', this.state.catFilterSetEvent);
        localStorage.setItem('catFilterCategoryEvent', this.state.catFilterCategoryEvent);
        localStorage.setItem('catFilterNameEvent', this.state.catFilterNameEvent);
        
        //console.log('storage updated')
        localStorage.setItem('locationDistance', this.state.locationDistance);
        localStorage.setItem('distanceMode', this.state.distanceMode);

        localStorage.setItem('setDatesFilter', this.state.setDatesFilter);
        localStorage.setItem('startDate', this.state.startDate);
        localStorage.setItem('endDate', this.state.endDate);
      
        

    }

    handleChkChange = event => {

        this.setState({
            [event.target.value]: event.target.checked,
        });
    
        // this.props.values[event.target.value] = event.target.checked ? true : false

    };


    render() {

        //moment.locale(getBrowserLocales);
        moment.locale(i18next.language);
        // i18next.changeLanguage(i18next.language)



        const filterStyles = {
            overlay: { zIndex: 1000 },
            content: {

                top: `${ this.state.modalTopPos }`,

                left: '5%',
                margin: `${ this.state.modalMargin }`,
                
                bottom: 'auto',
                
                height: `${ this.state.modalHeight }`,
                width: `${ this.state.modalWidth }px`,

                maxWidth: '74%',

                boxShadow: '0 20px 50px rgba(0, 0, 0, 0.33)',
                borderRadius: '5px',
                paddingTop: '16px',
                paddingLeft: '32px',
                paddingRight: '32px',
                                                    
                overflow : 'auto',
                 

            }
        }

        const filterStylesMobile = {
            overlay: { zIndex: 1000 },
            content: {

                top: `90px`,

                left: '5%',
                margin: `auto`,
                
                bottom: 'auto',
                
                height: `${ this.state.modalHeight }`,
                width: `73%`,

                boxShadow: '0 20px 50px rgba(0, 0, 0, 0.33)',
                borderRadius: '5px',
                paddingTop: '16px',
                paddingLeft: '32px',
                paddingRight: '32px',
                                                    
                overflow : 'auto',
                 

            }
        }

        const distanceOptions = [
            { value: '0', label: 'km', disabled: false },
            { value: '1', label: 'miles', disabled: false },
        ];

        
        const eventDurationOptions = [
            { value: '0', label: "All the time needed" },
            { value: '1', label: 'Couple of hours' },
            { value: '2', label: 'Half a day' },
            { value: '3', label: 'Entire day' },
            { value: '4', label: 'Two days' },
            { value: '4', label: 'Three days' },
            { value: '6', label: 'Up to a week' },
            { value: '7', label: 'Up to two weeks' },
            { value: '8', label: 'More than two weeks' },
        ];
        
        const eventTypeOptions = [
            { value: '0', label: "Show events of all types" },
            { value: '1', label: 'Real-life events only' },
            { value: '2', label: 'Online events only' },
        ];

        let getEventsFilters = {}
            
        //around area (km or miles)
        if (this.state.distanceMode === '0') {
            getEventsFilters = { ...getEventsFilters, ...{ around: parseInt(this.state.locationDistance) } }
        } else {
            getEventsFilters = { ...getEventsFilters, ...{ around: (parseInt(this.state.locationDistance) * 1.609) } }
        }
        
        //lat & lng
        getEventsFilters = { ...getEventsFilters, ...{ lng: this.state.ipLng.toString(), lat: this.state.ipLat.toString() } }

        //virtual ?         
        getEventsFilters = { ...getEventsFilters, ...{ virtual: this.state.setVirtualEvents } }
        
        //category
        if (this.state.catFilterSetEvent) { 
            getEventsFilters = { ...getEventsFilters, ...{ category: parseInt(this.state.catFilterCategoryEvent) } }
        }

        //date filter
        if (this.state.setDatesFilter && this.state.startDate) {

            let algus = moment(this.state.startDate).format('YYYY-MM-DD');
            let lopp = moment(this.state.endDate).format('YYYY-MM-DD');
            if (this.state.startDate && !this.state.endDate) { 
                lopp = algus 
            }

            getEventsFilters = { ...getEventsFilters, ...{ startDate: algus, endDate: lopp } }

        }
        

        if (this.state.moreFiltersSet) {

            if (this.state.chkPro) {
                getEventsFilters = { ...getEventsFilters, ...{ isPro: true } }
            }

            if (this.state.chkVerified) {
                getEventsFilters = { ...getEventsFilters, ...{ isVerified: true } }
            }


            if (this.state.chkInstant) {
                getEventsFilters = { ...getEventsFilters, ...{ instaBook: true } }
            }


            // if (this.state.chkTFP) {
            //     getEventsFilters = { ...getEventsFilters, ...{ tfp: true } }
            // }


            if ( (this.state.lngEN) || (this.state.lngES) || (this.state.lngFR) || (this.state.lngIT) || (this.state.lngDE) || (this.state.lngRU) ) {

                let languageArray = []

                if (this.state.lngEN) { languageArray.push("en") } 
                if (this.state.lngES) { languageArray.push("es") } 
                if (this.state.lngFR) { languageArray.push("fr") } 
                if (this.state.lngIT) { languageArray.push("it") }
                if (this.state.lngDE) { languageArray.push("de") } 
                if (this.state.lngRU) { languageArray.push("ru") }

                getEventsFilters = { ...getEventsFilters, ...{ languages: languageArray } }

            }

            if (this.state.minPrice > 0) {
                getEventsFilters = { ...getEventsFilters, ...{ minPrice: parseFloat(this.state.minPrice) } }
            }

            if (this.state.maxPrice < 9999) {
                getEventsFilters = { ...getEventsFilters, ...{ maxPrice: parseFloat(this.state.maxPrice) } }
            }
            
            if (this.state.gender > 0) {
                getEventsFilters = { ...getEventsFilters, ...{ gender: parseInt(this.state.gender) } }
            }


        }

        if (this.state.mountCompleted === false) {

            // console.log('---------- :  return NULL (as loading not completed')

            return null;
        } else {

            // console.log('+++++++++++++++++++++++    # return starts. State: ', this.state)
            // console.log('+++++++++++++++++++++++    # return starts. Props: ', this.props)

            return (
                
                <>

                    <Helmet>
                        <title>Photography Workshops & Tours | PhotoSouls - Elevate Your Skills</title>
                        <meta name="description" content="Join inspiring photography workshops and immersive tours at PhotoSouls. Learn from experts, discover unique locations, and refine your photographic vision" />
                        <meta name="keywords" content="photosouls,workshops, tours, events" />
                    </Helmet>
                    

                    <div className="bodywrapper">

                        
                        <FilterButtons>
                        
                            <MainTitle>
                            Top Workshops, Tours & Events to Elevate Your Photography
                            </MainTitle>
                            


                            {/* 
                            ------------- Location filter ---------
                            */}
                            <FilterButton 
                                isSet={!this.state.setVirtualEvents} 
                                //onClick={ (e) => { this.setState({ setLocationFilter: !this.state.setLocationFilter, modalTopPos: e.clientY+32+'px', modalHeight: '350px', modalMargin: '0', modalWidth: 500,}) }}
                                onClick={ (e) => { this.setState({ setLocationFilter: !this.state.setLocationFilter, modalTopPos: '16px', modalHeight: window.innerWidth > 790 ? '380px' : '390px', modalMargin: 'auto', modalWidth: 500,}) }}
                                >
                                Around {this.state.ipCity}, &plusmn; {this.state.locationDistance}  {this.state.distanceMode === '0' ? distanceOptions[0].label : distanceOptions[1].label }
                            </FilterButton>
                            <Modal
                                isOpen={this.state.setLocationFilter}
                                onRequestClose={() => { this.setState({ setLocationFilter: !this.state.setLocationFilter, })  }}
                                ariaHideApp={false}
                                style={window.innerWidth > 790 ? filterStyles : filterStylesMobile}
                                onAfterOpen={() => {
                                    document.body.style.top = `0px`
                                    document.body.style.width = `100vw`
                                    document.body.style.position = 'fixed'
                                }}

                                onAfterClose={() => {
                                    const scrollY = document.body.style.top
                                    document.body.style.position = ''
                                    document.body.style.top = ''
                                    document.body.style.width = ''
                                    window.scrollTo(0, parseInt(scrollY || '0') * -1)
                                }}
                                >
                            
                                <BackButton onClick={() => { this.setState({ setLocationFilter: !this.state.setLocationFilter, }) }} >
                                    <FAIcon icon={faChevronLeft} color={'#000'} />
                                </BackButton>
                                
                                {/* <ClearFilter onClick={() => { this.setState({ setLocationFilter: !this.state.setLocationFilter, }) }} >
                                    Cancel
                                </ClearFilter> */}


                                <br/>

                                <SubTitle>Where should it happen?</SubTitle>
                                <IncreaseHeight16/>
                                <Formik
                                    initialValues={this.state}
                                    onSubmit={async (values) => {

                                        await new Promise((r) => setTimeout(r, 50));
                                        //alert(JSON.stringify(values, null, 2));

                                        // first check if location field has been changed.... only then can/and should update.
                                        if (values.location) {


                                            setTimeout(() => { this.setState({ 
                                            
                                                ipCity: values.addr_locality,
                                                ipRegion: values.addr_area1,
                                                ipCountry: values.country,
                                                ipLng: values.location.coordinates[0],
                                                ipLat: values.location.coordinates[1],
                                                ipAddress: values.ipAddress,

                                                }); 
                                            }, 50)

                                            localStorage.setItem('ipCity', values.addr_locality);
                                            localStorage.setItem('ipRegion', values.addr_area1);
                                            localStorage.setItem('ipCountry', values.country);
                                            localStorage.setItem('ipLng', values.location.coordinates[0]);
                                            localStorage.setItem('ipLat', values.location.coordinates[1]);
                                            localStorage.setItem('ipAddress', values.ipAddress);

                                        } 

                                        
                                        this.setState({ setLocationFilter: !this.state.setLocationFilter, setVirtualEvents: false, }) 

                                        

                                    }}
                                    >
                                    
                                    <Form>

                                        <Field
                                            name="ipAddress"
                                            label='Enter Location:'
                                            component={MyPlacesAutoComplete}
                                            value={this.state.ipAddress}
                                        />


                                        <IncreaseHeight16/>

                                        <FlexContainer>

                                            <LeftContainer>   
                                                <LabelStyled>Max distance:</LabelStyled>             
                                                <InputNumberStyled 
                                                    name='locationDistance'
                                                    defaultValue={this.state.locationDistance} 
                                                    //style={{ border: '1px solid #EBE' }}
                                                    onChange={(value) => {
                                                        this.setState({ locationDistance: parseFloat(value) }); 
                                                        }}
                                                    precision={0}
                                                    max={1000}
                                                    min={1}
                                                    type="number"
                                                />
                                                
                                            </LeftContainer>

                                            <DistanceContainer>
                                                <RadioButtonGroup
                                                    id="selectMaxDistanceModeSelection"
                                                    options={distanceOptions}
                                                    value={this.state.distanceMode}
                                                    onChange={ (event) => {
                                                        
                                                        const newModeValue = event.target.value;
                                                        // const distanceMilesToKm = (this.state.locationDistance * 1.609)
                                                        setTimeout(() => { this.setState({ distanceMode: newModeValue, }); }, 50) 


                                                    }}
                                                    //size='small'
                                                />
                                            </DistanceContainer>

                                        </FlexContainer>
                                        

                                        <IncreaseHeight16/>
                                        <ButtonArea>

                                            <StyledSave 
                                                type="submit"
                                                >
                                                Save
                                            </StyledSave>
                                            
                                            {/* <StyledCancel 
                                                onClick={() => { 
                                                    
                                                    this.setState({ setLocationFilter: !this.state.setLocationFilter, }) 

                                                }} 
                                                >
                                                Cancel
                                            </StyledCancel> */}

                                        </ButtonArea>


                                    </Form> 


                                </Formik>
                            
                            </Modal>
                    

                            {/* 
                            ------------- Virtual events ---------
                            */}
                            <FilterButton 
                                isSet={this.state.setVirtualEvents} 
                                onClick={ () => {
                                    setTimeout(() => { this.setState({ setVirtualEvents: !this.state.setVirtualEvents }); }, 50) 
                                    this.forceUpdate();
                                }}
                                >
                                Virtual events
                            </FilterButton>

                            
                            {/* 
                            ---------- Category filter ------- 
                            */}
                            { this.state.catFilterSetEvent ? 
                                <FilterButton 
                                    isSet={this.state.catFilterSetEvent}
                                    onClick={ () => {
                                        setTimeout(() => { this.setState({ catFilterModalEvent: true  }); }, 50) 
                                        this.forceUpdate();
                                    }}
                                >
                                    Category: {this.state.catFilterNameEvent}
                                </FilterButton>
                                :
                                null
                            }


                            {/* 
                            ---------- Date filter ------- 
                            */}
                            <FilterButton 
                                isSet={this.state.setDatesFilter} 
                                onClick={ (e) => { this.setState({ dateFilterModal: !this.state.dateFilterModal, modalTopPos: '16px', modalHeight: window.innerWidth > 790 ? '535px' : '575px', modalMargin: 'auto', modalWidth: 500,}) }}
                                >
                                { 
                                    this.state.setDatesFilter ? 
                                        DateRange(this.state.startDate, this.state.endDate, today) 
                                        :
                                        'Dates' 
                                }
                            </FilterButton>
                            <Modal
                                isOpen={this.state.dateFilterModal}
                                onRequestClose={() => { this.setState({ dateFilterModal: !this.state.dateFilterModal, })  }}
                                ariaHideApp={false}
                                style={window.innerWidth > 790 ? filterStyles : filterStylesMobile}

                                onAfterOpen={() => {
                                    document.body.style.top = `0px`
                                    document.body.style.width = `100vw`
                                    document.body.style.position = 'fixed'
                                }}

                                onAfterClose={() => {
                                    const scrollY = document.body.style.top
                                    document.body.style.position = ''
                                    document.body.style.top = ''
                                    document.body.style.width = ''
                                    window.scrollTo(0, parseInt(scrollY || '0') * -1)
                                }}
                                >

                                <BackButton onClick={ () => setTimeout(() => { this.setState({ dateFilterModal: false }); }, 50) } >
                                    <FAIcon icon={faChevronLeft} color={'#000'} />
                                </BackButton>
                                { 
                                    this.state.setDatesFilter && this.state.dateFilterModal && this.state.startDate !== null ? 
                                        <ClearFilter onClick={() => { 
                                                    this.setState({ dateFilterModal: !this.state.dateFilterModal, setDatesFilter: false, startDate: null, endDate: null }) 
                                            }}>
                                            Clear filter
                                        </ClearFilter>
                                        :
                                        null
                                }

                                <br/>
                            
                                <SubTitle>Select when you would have time for the event </SubTitle>
                                
                                <PushLeft20>

                                    <DayPickerRangeController
                                        startDate={this.state.startDate ? this.state.startDate.locale(i18next.language) : null} //? is used for workaround. without this, moment.locale is not applied.
                                        endDate={this.state.endDate ? this.state.endDate.locale(i18next.language) : null}
                                        onDatesChange={(dates) => { this.setState({ startDate: dates.startDate, endDate: dates.endDate, setDatesFilter: true }) }}
                                        focusedInput={this.state.focusedInput || "startDate"}
                                        onFocusChange={(focusedInput) => { this.setState({ focusedInput }) }}
                                        numberOfMonths={1}
                                        minimumNights={0}
                                        noBorder={true}

                                    />

                                </PushLeft20>

                                
                                <ButtonArea>

                                    <StyledSave 
                                        onClick={() => { this.setState({ dateFilterModal: !this.state.dateFilterModal, }) }}
                                        disabled={this.state.startDate === null} 
                                        >
                                        Save
                                    </StyledSave>
                                    
                                    {/* <StyledCancel 
                                        onClick={() => { 
                                            
                                            this.setState({ dateFilterModal: !this.state.dateFilterModal, setDatesFilter: false, startDate: null, endDate: null }) 

                                        }} 
                                        >
                                        Clear filter
                                    </StyledCancel> */}

                                </ButtonArea>
                    
                                { 
                                    window.innerWidth > 790 ?
                                        null 
                                        : 
                                        <><br/><br/><br/><br/><br/></>
                                }
                            
                            
                            </Modal>


                            {/* 
                            ---------- More filters ------- 
                            */}
                            <FilterButton isSet={this.state.moreFiltersSet} 
                                onClick={ (e) => { this.setState({ moreFiltersModal: !this.state.moreFiltersModal, modalTopPos: '16px', modalHeight: '90%',  modalMargin: 'auto', modalWidth: 500,}) }}
                                >
                                More filters
                            </FilterButton>
                            <Modal
                                isOpen={this.state.moreFiltersModal}
                                onRequestClose={() => { this.setState({ moreFiltersModal: !this.state.moreFiltersModal, })  }}
                                ariaHideApp={false}
                                style={window.innerWidth > 790 ? filterStyles : filterStylesMobile}
                                
                                onAfterOpen={() => {
                                    document.body.style.top = `0px`
                                    document.body.style.width = `100vw`
                                    document.body.style.position = 'fixed'
                                }}

                                onAfterClose={() => {
                                    const scrollY = document.body.style.top
                                    document.body.style.position = ''
                                    document.body.style.top = ''
                                    document.body.style.width = ''
                                    window.scrollTo(0, parseInt(scrollY || '0') * -1)
                                }}
                                >

                            

                                <BackButton onClick={ () => setTimeout(() => { this.setState({ moreFiltersModal: false }); }, 50) } >
                                    <FAIcon icon={faChevronLeft} color={'#000'} />
                                </BackButton>
                                { 
                                    this.state.moreFiltersSet && this.state.moreFiltersModal ? 
                                        <ClearFilter onClick={() => { 
                                                    this.setState({ 
                                                        
                                                        moreFiltersSet: false,
                                                        moreFiltersModal: false,
                                                        minPrice: 0,
                                                        maxPrice: 9999,
                                                        eventDuration: 0,
                                                        eventType: 0,
                                                        lngEN: false,
                                                        lngES: false,
                                                        lngFR: false,
                                                        lngIT: false,
                                                        lngDE: false,
                                                        lngRU: false,
                                                        chkPro: false,
                                                        chkVerified: false,
                                                        chkInstant: false,

                                                        }) 
                                            }}>
                                            Clear filter
                                        </ClearFilter>
                                        :
                                    null
                                }


                                <br/>
                        
                                <Formik
                                    initialValues={this.state}
                                    onSubmit={async (values) => {

                                        await new Promise((r) => setTimeout(r, 50));
                                        //alert(JSON.stringify(values, null, 2));

                                        localStorage.setItem('minPrice', this.state.minPrice);
                                        localStorage.setItem('maxPrice', this.state.maxPrice);
                                        localStorage.setItem('eventDuration', this.state.eventDuration);
                                        localStorage.setItem('eventType', this.state.eventType);
                                        localStorage.setItem('lngEN', this.state.lngEN);
                                        localStorage.setItem('lngES', this.state.lngES);
                                        localStorage.setItem('lngFR', this.state.lngFR);
                                        localStorage.setItem('lngIT', this.state.lngIT);
                                        localStorage.setItem('lngDE', this.state.lngDE);
                                        localStorage.setItem('lngRU', this.state.lngRU);
                                        localStorage.setItem('chkPro', this.state.chkPro);
                                        localStorage.setItem('chkVerified', this.state.chkVerified);
                                        localStorage.setItem('chkInstant', this.state.chkInstant);

                                        localStorage.setItem('moreFiltersSet', true);

                                        this.setState({ 
                                            moreFiltersModal: !this.state.moreFiltersModal, 
                                            moreFiltersSet: true 
                                        })
                                                
                                        
                                        

                                    }}
                                    >

                                    <Form>

                                        
                                        <SubTitle>Price range</SubTitle>
                                        <LabelStyled>How much are you willing to spend?</LabelStyled>    
                                        
                                        {/* Currently the average hourly rate in the selected area is €18/hour */}
                                        <IncreaseHeight16/>
                                        <FlexContainer>

                                            <Width30>   
                                                <LabelStyled>Min price:</LabelStyled>             
                                                <InputNumberStyled 
                                                    name='minPrice'
                                                    defaultValue={this.state.minPrice} 
                                                    //style={{ border: '1px solid #EBE' }}
                                                    onChange={(value) => {
                                                        this.setState({ minPrice: parseFloat(value) }); 
                                                        }}
                                                    precision={0}
                                                    max={1000}
                                                    min={0}
                                                    type="number"
                                                />
                                                
                                            </Width30>
                                            
                                            <Width30>
                                                <LabelStyled>Max price:</LabelStyled>             
                                                <InputNumberStyled 
                                                    name='maxPrice'
                                                    defaultValue={this.state.maxPrice} 
                                                    //style={{ border: '1px solid #EBE' }}
                                                    onChange={(value) => {
                                                        this.setState({ maxPrice: parseFloat(value) }); 
                                                        }}
                                                    precision={0}
                                                    max={9999}
                                                    min={0}
                                                    type="number"
                                                />
                                            </Width30>

                                        </FlexContainer>
                                        
                                        <IncreaseHeight16/>
                                        <IncreaseHeight16/>


                                        <SubTitle>Duration</SubTitle>
                                        <Field
                                            name="eventDuration"
                                            label='How much time you have?'
                                            component={MySelectField}

                                            originalOptions={eventDurationOptions}
                                            originalValue={this.state.eventDuration}
                                            onChange={ (e) => {
                                                this.setState({ eventDuration: parseInt(e.value) }); 
                                            }}
                                                
                                        />
                                        <IncreaseHeight16/>
                                        <IncreaseHeight16/>


                                        <SubTitle>Type</SubTitle>
                                        <Field
                                            name="eventType"
                                            label='What kind of event are you looking for?'
                                            component={MySelectField}

                                            originalOptions={eventTypeOptions}
                                            originalValue={this.state.eventType}
                                            onChange={ (e) => {
                                                this.setState({ eventType: parseInt(e.value) }); 
                                            }}
                                                
                                        />
                                        <IncreaseHeight16/>
                                        <IncreaseHeight16/>

                                        <SubTitle>Language</SubTitle>
                                        <LabelStyled>If you have a preference in the language you would like use when communicating with your host, please specify it here.</LabelStyled>    
                                        {/* 
                                        Ceate a link at the bottom of checkboxes: "Show more". 
                                        This will expand and open the list of all languages used by the listings (in this area only maybe?).
                                        */}

                                        <ChkGrid>
                                            <ChkContainer>
                                                <FormControlLabel 
                                                    control={
                                                        <OrangeCheckBox
                                                            checked={this.state.lngEN}
                                                            onChange={this.handleChkChange}
                                                            value="lngEN"
                                                        />
                                                    }
                                                label={'English'}
                                                />          
                                            </ChkContainer>      
                                            <ChkContainer>
                                                <FormControlLabel 
                                                    control={
                                                        <OrangeCheckBox
                                                            checked={this.state.lngES}
                                                            onChange={this.handleChkChange}
                                                            value="lngES"
                                                        />
                                                    }
                                                label={'Spanish'}
                                                />          
                                            </ChkContainer>     
                                            <ChkContainer>
                                                <FormControlLabel 
                                                    control={
                                                        <OrangeCheckBox
                                                            checked={this.state.lngFR}
                                                            onChange={this.handleChkChange}
                                                            value="lngFR"
                                                        />
                                                    }
                                                label={'French'}
                                                />          
                                            </ChkContainer>   
                                            <ChkContainer>
                                                <FormControlLabel 
                                                    control={
                                                        <OrangeCheckBox
                                                            checked={this.state.lngIT}
                                                            onChange={this.handleChkChange}
                                                            value="lngIT"
                                                        />
                                                    }
                                                label={'Italian'}
                                                />          
                                            </ChkContainer>   

                                            <ChkContainer>
                                                <FormControlLabel 
                                                    control={
                                                        <OrangeCheckBox
                                                            checked={this.state.lngDE}
                                                            onChange={this.handleChkChange}
                                                            value="lngDE"
                                                        />
                                                    }
                                                label={'German'}
                                                />          
                                            </ChkContainer>     
                                            <ChkContainer>
                                                <FormControlLabel 
                                                    control={
                                                        <OrangeCheckBox
                                                            checked={this.state.lngRU}
                                                            onChange={this.handleChkChange}
                                                            value="lngRU"
                                                        />
                                                    }
                                                label={'Russian'}
                                                />          
                                            </ChkContainer>     
                                            
                                        </ChkGrid>    

                                        <IncreaseHeight16/>
                                        <IncreaseHeight16/>



                                        <ButtonArea>

                                            <StyledSave type="submit"
                                                >
                                                Save
                                            </StyledSave>
                                            
                                            <StyledCancel 
                                                onClick={() => { 
                                                    
                                                    this.setState({ 
                                                        moreFiltersSet: false,
                                                        moreFiltersModal: false,
                                                        minPrice: 0,
                                                        maxPrice: 9999,
                                                        eventDuration: 0,
                                                        eventType: 0,
                                                        lngEN: false,
                                                        lngES: false,
                                                        lngFR: false,
                                                        lngIT: false,
                                                        lngDE: false,
                                                        lngRU: false,
                                                        chkPro: false,
                                                        chkVerified: false,
                                                        chkInstant: false,
                                                    }) 

                                                    localStorage.removeItem('minPrice');
                                                    localStorage.removeItem('maxPrice');
                                                    localStorage.removeItem('eventDuration');
                                                    localStorage.removeItem('eventType');
                                                    localStorage.removeItem('lngEN');
                                                    localStorage.removeItem('lngES');
                                                    localStorage.removeItem('lngFR');
                                                    localStorage.removeItem('lngIT');
                                                    localStorage.removeItem('lngDE');
                                                    localStorage.removeItem('lngRU');
                                                    localStorage.removeItem('chkPro');
                                                    localStorage.removeItem('chkVerified');
                                                    localStorage.removeItem('chkInstant');

                                                    localStorage.setItem('moreFiltersSet', false);


                                                }} 
                                                >
                                                Clear filters
                                            </StyledCancel>

                                        </ButtonArea>
                    
                                    </Form> 


                                </Formik>
                            
                            </Modal>
                        
                        </FilterButtons>
                            


                        {/* On-page events category */}
                        { !this.state.catFilterSetEvent ? 
                            <SubTitle>Interested in a specific category?</SubTitle> 
                            : null 
                        }
                        <CategoryButtons
                            catFilterModal={this.state.catFilterModalEvent}
                            catFilterSet={this.state.catFilterSetEvent}
                            categories='event'
                            chkNSFW={this.props.store.get("chkNSFW")}
                            winLen={window.innerWidth}
                            onClick={(cat, name) => {
                                
                                if (cat === '000') {
                                    //remove category filter
                                    setTimeout(() => { this.setState({ catFilterSetEvent: false, catFilterCategoryEvent: '', catFilterNameEvent: '', catFilterModalEvent: false,  }); }, 50) 
                                } else {
                                    //set category filter
                                    setTimeout(() => { this.setState({ catFilterSetEvent: true, catFilterCategoryEvent: cat, catFilterNameEvent: name, catFilterModalEvent: false,  }); }, 50) 
                                }

                                window.scrollTo({top: 0, behavior: 'smooth'});

                            }}
                        />





                        { this.state.moreFiltersModal || this.state.dateFilterModal || this.state.setLocationFilter ? 

                            null

                        :

                        <ProfileResults>

                            <Query
                                query={getEvents}
                                variables={getEventsFilters}
                                fetchPolicy="no-cache"
                            >
                            {
                                ({ data, loading }) => {
                                
                                    //console.log('-------- getEventsFilters', getEventsFilters)
                                    //console.log('-------- data: ', data)

                                    //if ((data === undefined) || (data === null) || (loading === true)) {
                                    if ((data === undefined) || (data === null) || ((loading === true) && (JSON.stringify(data).length < 35))) {

                                        return (
                                            <MainTitle>Searching for events, please wait...</MainTitle> 
                                        )    

                                    } else if (JSON.stringify(data).length > 35) {
                                        
                                        // console.log('data 2: ', data) 
                                        return (<>

                                            { 
                                                
                                                data.getEvents.map((event, index) => {

                                                    //console.log('event (data for event card details)', event)

                                                    let profileURL = ""
                                                    if (event.premiumURL) {
                                                        profileURL = '/' + event.premiumURL
                                                    } else if (event.publicURL) {
                                                        profileURL = '/' + event.publicURL
                                                    } else {
                                                        profileURL = '/event/' + event.id
                                                    }


                                                    return (
                                                        
                                                        <EventCard
                                                            key={index}  

                                                            albums={event.albums} 

                                                            title={event.title} 

                                                            address={event.address} 
                                                            dates={event.dates} 

                                                            virtual={event.virtual}
                                                            //avatar={event.avatar} 
                                                            // avgPrice={event.avgPrice} 
                                                            // currency={event.currency}
                                                            // instaBook={event.instaBook}
                                                            avgRating={event.avgRating}
                                                            reviewsTotal={event.reviewsTotal}
                                                            
                                                            category={-1} //no category for favorites. only use if seach filter has set specific category
                                                            id={event._id} 
                                                            isPro={event.isPro} 
                                                            isVerified={event.isVerified} 
                                                            isFoundingMember={event.isFoundingMember} 
                                                            isTopRated={event.isTopRated} 
                                                            isTop2023={event.isTop2023} 
                                                            isTop2024={event.isTop2024} 
                                                            isTop2025={event.isTop2025} 
                                                            isTop2026={event.isTop2026} 
                                                            isTop2027={event.isTop2027} 
                                                            isTop2028={event.isTop2028} 
                                                            isTop2029={event.isTop2029} 
                                                            isTop2030={event.isTop2030} 
                                                            isTop2031={event.isTop2031} 
                                                            isTop2032={event.isTop2032} 
                                                            isTop2033={event.isTop2033} 
                                                            name={event.name} 
                                                            url={profileURL}
                                                            // onClick={() => { this.props.history.push(profileURL) }}
                                                        /> 
                                
                                                    );

                                                })


                                            }

                                        </>);

                                    } else {
                                        return(<>
                                            <SubTitle><Em><span role="img" aria-label='no'>😔</span></Em>Sorry, we didn't find any events matching the filter set above. But hey, that could change – with you!</SubTitle>
                                                <IncreaseHeight16/>
                                                <SubTitle>Are you organizing tours, workshops, or any other exciting events? This is your golden opportunity to be among the first ones to showcase your events and fill those seats! By signing up now, you'll not only be pioneering the event scene but also getting ahead of the competition.</SubTitle>
                                                <IncreaseHeight16/>
                                                <SubTitle>Click below to create your profile and start connecting with potential participants today!</SubTitle>
                                                <IncreaseHeight16/>
                                                <NewLineSection>
                                                <BtnPrimary onClick={ () => this.props.history.push({ pathname: 'signup/' }) }>Sign Up Now</BtnPrimary>
                                                </NewLineSection>
                                            <ProfileResults>
                                            {
                                                this.state.setDatesFilter || this.state.catFilterSet || this.state.moreFiltersSet ? 
                                                <LinkButton onClick={() => { 

                                                    this.setState({ 

                                                        setDatesFilter: false, 
                                                        startDate: null, 
                                                        endDate: null,

                                                        moreFiltersSet: false,
                                                        minPrice: 0,
                                                        maxPrice: 9999,
                                                        duration: 0,
                                                        startTime: 0,
                                                        gender: 0,
                                                        lngEN: false,
                                                        lngES: false,
                                                        lngFR: false,
                                                        lngIT: false,
                                                        lngDE: false,
                                                        lngRU: false,
                                                        chkPro: false,
                                                        chkVerified: false,
                                                        chkInstant: false, 

                                                        catFilterSet: false,

                                                        setVirtualEvents: false,

                                                    })

                                                    }}>
                                                    Click here to clear filters
                                                </LinkButton>
                                                :
                                                null

                                            }
                                            <br/><br/><br/>
                                            </ProfileResults>
                                        </>)
                                    }
                                    
                                
                                }

                            }

                            </Query>
                            
                        </ProfileResults>

                        }




                        <Footer showbutton="true"/>
        

                    </div>

                    

                </>

            )

        }
        
    }
}



const personalInfoFields = [
    'fName', 'lName', 'bDay', 'email', 'phone', 'phoneDisplay', 'phoneCountry', 'avatar', 'isVerified', 'isPro', 'temperror', 'pwdUpdated',
    'chkReminders', 'chkRemindersP', 'chkMessages', 'chkMessagesP', 'chkRecomm', 'chkRecommP', 'chkInsp', 'chkInspP', 'chkLocal', 'chkLocalP', 
    'chkHow', 'chkHowP', 'chkInvite', 'chkInviteP', 'chkSurvey', 'chkSurveyP', 'chkPolicy', 'chkPolicyP', 'chkTips', 'chkTipsP'
]

export const ListEvents = withRouter(withStore(withApollo(C), personalInfoFields));



const LinkButton = styled.div`
    
    color: #4C9AFF;

    margin: 0 8px 0 0; 

    font-weight: 400;
    font-size: 20px;

    font-family: 'Fira Sans';


    &:hover {
        text-decoration: underline;
        cursor: pointer
        }

`;

const StyledSave = styled(Button)`
    && {
    height: 40px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    color: white;
    
    
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 16px;
    margin-bottom: 32px;
    margin-top: 16px;

    text-transform: none;
    font-weight: 400;
    font-size: 17px;
        &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }
    }
    
`;

const StyledCancel = styled(Button)`
    && {
    height: 40px;
    margin-top: 16px;

    padding-left: 16px;
    padding-right: 16px;

    margin-bottom: 32px;

    color: #333333;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    }
    
`;


const ClearFilter = styled(Button)`
    && {
    height: 40px;

    margin-top: 8px;

    padding-left: 16px;
    padding-right: 16px;


    color: #333333;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    }
    
`;


const FilterButtons = styled.div`
    
    padding-bottom: 32px;
    

`;



const MainTitle = styled.h1`
    
    font-size: 22px;
    font-weight: 500;
    padding: 0px 24px 24px 0px;


    font-family: 'Fira Sans';
    color: #333333;

`;


const SubTitle = styled.div`
    
    font-size: 20px;
    font-weight: 400;
    padding: 16px 24px 16px 0px;


    font-family: 'Fira Sans';
    color: #333333;

`;




const FilterButton = styled(Button)`
    && {
    height: 40px;

    padding: 16px;

    margin-right: 8px;
    margin-bottom: 8px;

    background: ${(props) => props.isSet ? '#f5f5f5': "white"};
    border: ${(props) => props.isSet ? '4px double #cbcbcb;' : "1px solid #cbcbcb;"};

    border-radius: 20px;  


    font-family: 'Fira Sans';
    color: #333333;

    
    /* box-shadow: ${(props) => props.isSet ? '0 2px 8px 0 rgba(170, 170, 170, 0.4);' : "0"}; */


    text-transform: none;
    font-weight: 400;
    font-size: 17px;

    &:hover {
        border-color: #333333;
        }

    }
    
`;



const ProfileResults = styled.div`

    width: 100%;
    padding-top: 24px; 

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    align-items: flex-start;
    justify-content: center;
   
    flex-direction: row;
    justify-content: flex-start;

    z-index: 200;

`;


const ButtonArea = styled.div`

    display: flex;

    padding-bottom: 0px;
    padding-left: 0px;

    color: #333333;
    align-items: flex-end;

`;



const IncreaseHeight16 = styled.div`
    
    height: 16px;  

`;

const LeftContainer = styled.div`

`;

const Width30 = styled.div`
    width: 30%;
    min-width: 30%;
    padding-right: 32px;

`;


const DistanceContainer = styled.div`
    
    margin-top: 24px;
    margin-left: 16px;

`;

const FlexContainer = styled.div`
    display: flex;

`;




const InputNumberStyled = styled(InputNumber)`
    
    width: calc(100% - 18px);
    padding: 0px;
    margin: 0px;
    
    && {
        input {
            
            
            font-size: 16px;
            font-family: 'Fira Sans';
            font-weight: 400;

            width: 100%;
            padding: 0px;
            margin: 0px;
            height: 24px;
            padding: 8px;
            
            color: #333333;

            border: 1px solid #cbcbcb;
            border-radius: 4px;  
            box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

            :focus {
                outline-color: #4C9AFF;
            }

            :disabled {
                background-color: #f7f7f7; 
            }

        }
    }
    
`;

const LabelStyled = styled.div`
    width: 100%;
    margin: 0px;
    padding: 0px;
    color: #333333;
    font-size: 16px;
    
    margin-bottom: 4px;
    margin-top: 0px;
    margin-left: 2px;
`;



const PushLeft20 = styled.div`
    margin-left: -20px;
`;

const OrangeCheckBox = withStyles({
    root: {
      color: orange[400],
      '&$checked': {
        color: orange[600],
      },
    },
    checked: {},
    })(props => <Checkbox color="default" {...props} /> )
;


const ChkContainer = styled.div`
    
  padding-left: 0px;
  padding-top: 8px;
  padding-bottom: 0px;
  width: 155px;
  
`;


const ChkGrid = styled.div`
    
    display: flex;
    flex-wrap: wrap;    

`;


const BackButton = styled(Button)`
    && {
        
    /* margin: -4px -4px 0 0px; */

    height: 48px;
    min-width: 48px;
    max-width: 48px;
    border-radius: 24px;

    margin-right: 16px;
    margin-top: 8px;
    /* margin-right: 4px;
    margin-left: 4px; */

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;

    
    font-weight: 400;
    font-size: 19px;

    background-color: #EBEBEB;
    opacity: 0.6;

    &:hover {
        background-color: #EBEBEB;
        opacity: 0.6;
        }
    
    &:disabled {
        background-color: #fff;
        color: #fff;
        }

    }
    
`;

const FAIcon = styled(FontAwesomeIcon)`
    && {
    font-size: 22px;
    font-weight: 400;
    }
`;

const Em = styled.span`
    padding-right: 8px;
`;

const NewLineSection = styled.div`
    
    margin-top: 16px; 
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    justify-content: space-between;

`;

const BtnPrimary = styled(Button)`
    
    && {
    min-height: 64px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    display: block;
    color: white;
    
    padding-left: 32px;
    padding-right: 32px;
  
    text-transform: none;
    font-weight: 400;
    font-size: 22px;
    
    &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }

}     
`;