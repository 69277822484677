
import React from "react";
import { Form } from "antd";
import styled from 'styled-components';
import Select from 'react-select'

const FormItem = Form.Item;

export const MySelectField = ({
    field: { onChange, ...field },
    form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label, originalOptions, originalValue, ...props
    }) =>  {

        const errorMsg = touched[field.name] && errors[field.name];
        
        // const handleChange = (e) => {
        //     console.log('onchange e.value: ', e.value)
        //     setFieldValue(e.value)
        // };


        // const [gender, setGender] = useState("");
        // const handleCategoryChange = event => {
        //     // set category change in state
        //     setGender(event.target.value);
        //     // Set formik change
        //     handleChange(event);
        //   };

        
        return (
            <>
            

                { label ? <LabelStyled>{label}</LabelStyled> : null}
                
                <FormItem validateStatus={errorMsg ? "error" : undefined}>

                    <Select 
                        //name={field.name}
                        classNamePrefix="select"
                        styles={colourStyles}


                        options={originalOptions} 
                        defaultValue={originalOptions[originalValue]}
                        isSearchable={true}
                        
                        //{...field}
                        {...props}
                       
                        //onChange={(e) => handleChange(e)}

                    />


                </FormItem>

                { errorMsg ? <ErrorStyled>{errorMsg}</ErrorStyled> : <></>}

            </>
        );
      };



const colourStyles = {

    control: (styles, {isDisabled, isFocused}) => ({ ...styles, 
        
        margin: 0,
        height: 42,
        paddingTop: 2,
        
        
        fontSize: 16,
        fontFamily: 'Fira Sans',
        fontWeight: '400',
        
        
        backgroundColor: isDisabled ? '#f7f7f7' : null,
        color: '#333333',
        
        borderRadius: 4,
        border: isFocused ? '2px solid #4C9AFF' : '1px solid #cbcbcb',
        boxShadow: '0 2px 8px 0 rgba(170, 170, 170, 0.2);',
        //boxShadow: isFocused ? '0 2px 8px 0 rgba(76, 154, 255, 0.8)' : '0 2px 8px 0 rgba(170, 170, 170, 0.2)',
        
        }),
    
    singleValue: base => ({
        ...base,
        paddingTop: 2,
        color: '#333333',
        marginLeft: 0,
        }),
    
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        
        return {
            ...styles,

            cursor: isDisabled ? 'not-allowed' : 'default',
        
            fontSize: 16,
            fontFamily: 'Fira Sans',
            fontWeight: '400',
            height: 42,

            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',


            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && ('#4C9AFF'),
                

            },


        };
    },
    menu: base => ({
        ...base,
        // color: '#333333',
        
        borderRadius: 4,
        border: '2px solid #4C9AFF',
        boxShadow: '0 2px 8px 0 rgba(170, 170, 170, 0.2);',

        marginLeft: '-1px',

        width: 'calc(100% + 2px);',

    }),

}



const LabelStyled = styled.div`
    width: 100%;
    margin: 0px;
    padding: 0px;
    color: #333333;
    font-size: 16px;
    margin-bottom: 4px;
    margin-top: 0px;
`;


const ErrorStyled = styled.div`
  width: 100%;
  margin: 0px;
  padding: 0px;
  height: 22px;
  color: #FF4343; 
  font-size: 13px;
  margin-top: 4px;
  
`;


