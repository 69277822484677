import React, { PureComponent } from 'react'
import { withApollo } from "react-apollo";

import { MyInputField } from "../../../components/MyInputField";
import { MyTextAreaField } from "../../../components/MyTextAreaField";

import { Field } from "formik";
import styled from 'styled-components';



class C extends PureComponent { 


    constructor(props) {
        
        super(props);
        
        this.state = {
            serviceProviderAddress: '',
            
        };
    
        this.fldValueChange = this.fldValueChange.bind(this);
          
    }

    fldValueChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({ [name]: value, });
        
    }


    handleChange = event => {

        this.setState({
            [event.target.value]: event.target.checked,
        });
    
        this.props.values[event.target.value] = event.target.checked ? true : false

    };


    render() {


        return (

            <PageContent>


                <PageTitle showAlways='true'>Service provider info</PageTitle> 

                <LabelStyled>To confirm bookings and process your payments, please enter your contact information that will be used on the client receipt. It will only be used on the receipt the client receives after the booking is confirmed.</LabelStyled>


                <FieldContainer>
                    <Field
                        name="serviceProviderName"
                        label='Name'
                        component={MyInputField}
                        enabled={true} 
                    />
                </FieldContainer>

                <FieldContainer>
                    <Field
                        name="serviceProviderAddress"
                        label='Address'
                        component={MyTextAreaField}
                        enabled={true} 
                    />
                </FieldContainer>



                <FieldContainer>
                    <Field
                        name="serviceProviderEmail"
                        label='Email'
                        component={MyInputField}
                        enabled={true} 
                    />
                </FieldContainer>



                <IncreaseHeight/>

                
            </PageContent>

        )

    }
    
}




export const ContactPage = (withApollo(C));



const IncreaseHeight = styled.div`
    
    height: 23px;  

`;


const PageContent = styled.div`
    
    width: 100%;
    /* height: 100%;
    min-height: 75vh; */
    font-family: 'Fira Sans';
    
`;

const PageTitle = styled.div`
    
    display: flex;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;

    display: ${(props) => props.showAlways === 'true' ? "block" : 'none'};

    @media (max-width: 790px) {
        display: block;
    }


`;


const LabelStyled = styled.div`
    width: 100%;
    margin: 0px;
    padding: 0px;
    color: #333333;
    font-size: 16px;
    
    margin-bottom: 4px;
    margin-top: 0px;
    margin-left: 2px;
`;


const FieldContainer = styled.div`
    
    margin: 32px auto 32px auto;
    
`;

