
import React, { PureComponent } from 'react'
import { Form as AntForm } from "antd";
import { withFormik, Field, Form } from "formik";
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'

import toast from 'toasted-notes' 
import 'toasted-notes/src/styles.css';
import styled from 'styled-components';

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';
// import { logEvent, setUserId } from '@psd/web/src/utils/analytics/amplitude';

import "./SignupView.css";
import getIPInfo from '../../../utils/getIPInfo'
import moment from "moment"; 

import { validUserSchema } from "@psd/common";
// import { msg_Unable } from "@psd/common";

import { MyInputField } from "../../components/MyInputField";
import { MyH1 } from "../../components/MyH1";
import { MyH3 } from "../../components/MyH3";
import { MyError } from "../../components/MyError";
import { MyButton } from "../../components/MyButton";
import { Divider } from "../../components/Divider";

import { LoginFacebookConnector } from '../loginFacebook/LoginFacebookConnector';


// <p>{t('weather.temprature', {number: temprature})}</p>
// "temprature": "Today in Lyon is cloudy: {{number}}°C."

// <p>{t('weather.date', {date: Date.now()})}</p>
// "weather": { "date": "Today's date: {{date, currentDate}}" },



const FormItem = AntForm.Item;


class C extends PureComponent {


    state = {
         readyToMutate: false,
         googleLoginStart: false,
         thirdparty: "email", //default
         userID: "",
         name: "",
         token: "",
    };


    async componentDidMount() {

        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        
        let ipData
        const today = new Date();

        // console.log('ipCity: ', localStorage.getItem('ipCity'))
        if ((localStorage.getItem('ipCity') === null) 
            || (localStorage.getItem('ipCity') === 'null') 
            || (localStorage.getItem('ipCity') === 'undefined') 
            || (localStorage.getItem('ipLocatedDate') === null) 
            || (localStorage.getItem('ipLocatedDate') !== moment(today).format('YYYY-MM-DD'))) {
        
                // NO geolocation in the local storage yet. gelocate!
                // and no need to do it more than once a day. YEs there are circumstances where people travel etc, but then they can manually change the addess, and that will eb saved also!
                // but once a day vs every request makes a massive difference at api usage cost when running at scale.
                ipData = await getIPInfo(); 
                //console.log(ipData)

                let ipAddress
                if (ipData.city !== ipData.region) {
                    ipAddress = ipData.city + ', ' + ipData.region
                } else {
                    ipAddress = ipData.city
                }
                ipAddress = ipAddress + ', ' + ipData.country
                //console.log('ipAddress', ipAddress)

                //save ip date to the local storage immediatelly after geolocated!
                localStorage.setItem('ipCity', ipData.city);
                localStorage.setItem('ipRegion', ipData.region);
                localStorage.setItem('ipCountry', ipData.country);
                localStorage.setItem('ipCurrency', ipData.currency);
                localStorage.setItem('ipLng', ipData.lng);
                localStorage.setItem('ipLat', ipData.lat);
                localStorage.setItem('ipAddress', ipAddress);
                localStorage.setItem('ipLocatedDate', moment(today).format('YYYY-MM-DD'));

        }       


        if ((localStorage.getItem('refProfile') === null) 
        || (localStorage.getItem('refProfile') === 'null') 
        || (localStorage.getItem('refProfile') === '') 
        || (localStorage.getItem('refProfile') === 'undefined')) { 
            localStorage.setItem('refProfile', '-');
        } else {
            //refProfile was already set in LocalStorage
        }

        
        let GOOGLE_CLIENT_ID = '';
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            // console.log('development')
            GOOGLE_CLIENT_ID = '377900433114-1ppl7emq8t86dpk6iq0a7a0s4qkr9sr0.apps.googleusercontent.com';
        } else {
            // console.log('production')
            GOOGLE_CLIENT_ID = '377900433114-ehqng9q16qr293jpcp38o7sg6n39qd9m.apps.googleusercontent.com'
        }

        // console.log(window.location.host)

        /* global google */
        google.accounts.id.initialize({
            client_id: GOOGLE_CLIENT_ID,
            callback: this.handleGoogle,
        });

        google.accounts.id.renderButton(document.getElementById("signUpDiv"), {
            // type: "standard",
            theme: "filled_blue",
            size: "large",
            text: "continue_with", //
            shape: "rectangular",
            width: 300,
            logo_alignment: "left",
        });
    


    }
    

    responseFacebook = async (response) => {

        //ReactGA.event({ category: "User", action: "Signup - Facebook", });
          
        this.setState({
            readyToMutate: true,
            userID: response.userID,
            email: response.email,
            token: response.accessToken,
            thirdparty: "Facebook"
        });
          
        // console.log('#FB response: ', response);
        //console.log("response.accessToken: ", response.accessToken);

    };

    
    handleGoogle = async (response) => {
        
        // console.log(response)

        //send to backend
        this.setState({
            googleLoginStart: true,
            userID: "response.userID,",
            email: "response.email,",
            token: response.credential,
            thirdparty: "Google",
        });

    };

    

    render() {

        
        const pageTitle = 'PhotoSouls - Sign up'
        const pageDescription = "Sign up for PhotoSouls account."
        const pageKeywords = "photosouls,photographers,models,workshops"
        const header = "Sign up"
        const btnFacebook = 'Sign in with Facebook';
        const option1 = "- or -" 
        const lblEmail = "Enter your email"
        const lblPassword = "and password"

        const testimonial1 = "I traveled to France a few months ago and was eager to have a photoshoot there. I found Adrien, and I couldn't be happier with both the photos and the session experience."
        const testimonial1author = "Emily"
        const testimonial2 = "I can't thank you guys enough! The number of new clients I have found here is amazing!"
        const testimonial2author = "Beata"
        const testimonial3 = "I was surprised that even landscape photographers could find something here. The last-minute photo tour to Scotland was something to remember for the rest of my life! Thanks!"
        const testimonial3author = "Roman"
        
        const fineprint1 = "By loggin in or signing up, I agree to PhotoSouls.com's"
        const fineprint2 = "Terms of Service"
        const fineprint3 = "and"
        const fineprint4 = "Privacy Policy"

        

        const { loadingVisible } = this.props.state;

        let loginFacebookButton;

        if (this.state.readyToMutate) {

            loginFacebookButton = (

                <>

                    <StyledFacebook disabled>
                        <LocalImage src={require('./../../../assets/whiteLoader.gif')} />
                    </StyledFacebook>
                    
                    <LoginFacebookConnector
                        thirdparty='Facebook'
                        email={this.state.email}
                        token={this.state.token}
                        userId={this.state.userID}
                        currency={localStorage.getItem('ipCurrency')}
                        city={localStorage.getItem('ipCity')}
                        country={localStorage.getItem('ipCountry')}
                        fName=''
                        lName=''
                        referralId={localStorage.getItem('refProfile')}
                        onFinish={this.props.onFinish}
                    />

                </>

            );
            
        } else {

            loginFacebookButton = (

                <FacebookLogin
                    appId="331812170862368"
                    fields="name,email"
                    //onClick={this.componentClicked}
                    callback={this.responseFacebook}
                    render={renderProps => (<StyledFacebook onClick={renderProps.onClick}><MyIcon icon={faFacebookSquare}/>{btnFacebook}</StyledFacebook>)}

                    autoLoad={false}
                    disableMobileRedirect={true}

                />

            );
            
        }


        return (
        

            <div className="SignupWrapper">

            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="keywords" content={pageKeywords} />
            </Helmet>
        

            <div className="SignupLeftSide">
                <div className="SignupHero1">
                    <Carousel autoPlay swipeable infiniteLoop showThumbs={false} showStatus={false}
                        interval={7000} transitionTime={700}>
                        
                        <div className="SignupHero1">
                            <div className="SignupTestimonial">{testimonial1}</div>
                            <div className="SignupAuthor">{testimonial1author}</div>
                            <img src="" alt=""/>
                        </div>

                        <div className="SignupHero2">
                            <div className="SignupTestimonial">{testimonial2}</div>
                            <div className="SignupAuthor">{testimonial2author}</div>
                            <img src="" alt=""/>
                        </div>

                        <div className="SignupHero3">
                            <div className="SignupTestimonial">{testimonial3}</div>
                            <div className="SignupAuthor">{testimonial3author}</div>
                            <img src="" alt=""/>
                        </div>
                        
                    </Carousel> 
                </div>
            </div>



            <div className="SignupRightSide">
                
                <div className="SignupRightSideWrapper">


                    <MyH1 headline={header} />
                    <MyH1 headline="-" />

                    
                    <Form>
                        {loginFacebookButton}
                    </Form>

                    <div id="signUpDiv" data-text="signup_with"></div>
                    {
                        this.state.googleLoginStart ?
                            <LoginFacebookConnector
                                thirdparty='Google'
                                email={this.state.email}
                                token={this.state.token}
                                userId={this.state.userID}
                                currency={localStorage.getItem('ipCurrency')}
                                city={localStorage.getItem('ipCity')}
                                country={localStorage.getItem('ipCountry')}
                                fName=''
                                lName=''
                                referralId={localStorage.getItem('refProfile')}
                                onFinish={this.props.onFinish}
                            />
                            :
                            null
                    }
                    
                    <IncreaseHeight16 />

                    <MyH3 headline={option1}/>

                    <Form>
                        
                        <Field
                            name="email"
                            label={lblEmail}
                            component={MyInputField}
                        />

                        <Divider />

                        <Field
                            name="pwd"
                            label={lblPassword}
                            type="password"
                            component={MyInputField}
                        />


                        {
                            this.props.errors.eMessage ? (
                                <MyError
                                    message={this.props.errors.eMessage}
                                    />
                                ): null
                        }


                        <Divider />
                        
                        <FormItem>
                            
                            <MyButton 
                                type='submit'
                                caption='Sign up'
                                loading={loadingVisible}
                                />

                        </FormItem>
                        
                        
                    </Form>
                    

                    <Fineprint>
                        {fineprint1} <StyledLink to="/terms/terms-of-service">{fineprint2}</StyledLink> {fineprint3} <StyledLink to="/terms/privacy-policy">{fineprint4}</StyledLink>.
                    </Fineprint>

                    {/* <StyledLink to="/user/logout">Logout</StyledLink> */}

                </div>

            </div>

        </div>

        )
    }
}


export const SignupView = withFormik({
    
    validationSchema: validUserSchema,
    
    mapPropsToValues: () => ({ email: "", pwd: "" }),
    
    handleSubmit: async (values, { props, setErrors }) => {
        
        props.updateLoader(true);

        try {

            // NB !!!   NB !!!   NB !!!   NB !!!   NB !!!   NB !!!    STATE IS NOT AVAILABLE HERE 
            //values.thirdparty = this.state.thirdparty; //'Facebook'

            values.email = values.email.toLowerCase();
            values.currency = localStorage.getItem('ipCurrency')
            values.city = localStorage.getItem('ipCity')
            values.country = localStorage.getItem('ipCountry')
            values.referralId = localStorage.getItem('refProfile')
            
            //console.log('nonii 2')
            //console.log('frondis referralId enne submitti', values.referralId)

            const apiResponse = await props.submit(values);
            
            //console.log('nonii 3')

            props.updateLoader(false);

            if (apiResponse.responseType === "data") {     

                if (apiResponse.data.signup.sessionId) {     

                    toast.notify(
                        <div>Thank you for signing up! You are now logged in.</div>, 
                        { duration: 6000 }
                        );

                    // logEvent('signup with email')
                    // setUserId(apiResponse.data.signup.userId)

                    props.onFinish(apiResponse.data.signup.userId);

                } else {
                    
                    setErrors({ eMessage: "Unable to create user. Please try again later", });

                }

            } else if (apiResponse.responseType === "error") {
                
                setErrors({ eMessage: apiResponse.message, });

            } else {

                setErrors({ eMessage: "Unable to create user. Please try again later", });

            }

        
           
        } catch (e) {

            // console.log('nonii ERR')
                           
            props.updateLoader(false);
            
            // if (e.graphQLErrors[0].message === "APIErorr") {

            //     setErrors({ eMessage: e.graphQLErrors[0].data[0].message, });

            // } else {

                setErrors({ eMessage: "Unable to connect to PhotoSouls. Please try again later", });

            // }


        }

    }
  })(C);
  

const IncreaseHeight16 = styled.div`
    height: 16px;
    min-height: 16px;
    width: 100%;
`;


const Fineprint = styled.div`
    margin-top: 16px;
    font-size: 12px;
    color: #333333;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #3e6ca0;
    &:hover {
        text-decoration: underline
    }
`;

const StyledFacebook = styled(Button)`
&& {
    height: 48px;
    text-transform: none;
    margin-top: 16px;
    background: #4267b2;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.4);
    color: white;
    font-size: 16px;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 17px;
        &:hover {
        background: #4267b2;
        opacity: 0.9;
        color: white;
        }
        @media (max-width: 400px) {
            font-weight: 700;
        }
}

`;

const MyIcon = styled(FontAwesomeIcon)`
&& {
    color: white;
    padding-right: 24px;
    font-size: 32px;
}
`;

const LocalImage = styled.img`
    height: 40px;
    width: 40px;
    margin: -2px auto auto auto;
`;

