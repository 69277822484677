import React, { PureComponent } from 'react'
import { withApollo } from "react-apollo";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';

// import { Field } from "formik";

import styled from 'styled-components';



class C extends PureComponent { 


    handleChange = event => {

        this.setState({
            [event.target.value]: event.target.checked,
        });
    
        this.props.values[event.target.value] = event.target.checked ? true : false

    };


    render() {


        return (

            <PageContent>


                <PageTitle>Categories</PageTitle>

                <PHeading>Select the categories you are working in</PHeading>


                    <ChkGrid>

                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catActing}
                                        onChange={this.handleChange}
                                        value="catActing"
                                    />
                                }
                            label={'Acting'}
                            />          
                        </ChkContainer>             


                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catArt}
                                        onChange={this.handleChange}
                                        value="catArt"
                                    />
                                }
                            label={'Art'}
                            />          
                        </ChkContainer> 

                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catBodypaint}
                                        onChange={this.handleChange}
                                        value="catBodypaint"
                                    />
                                }
                            label={'Bodypaint'}
                            />          
                        </ChkContainer> 

                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catBoudoir}
                                        onChange={this.handleChange}
                                        value="catBoudoir"
                                    />
                                }
                            label={'Boudoir'}
                            />          
                        </ChkContainer> 

                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catCosplay}
                                        onChange={this.handleChange}
                                        value="catCosplay"
                                    />
                                }
                            label={'Cosplay'}
                            />          
                        </ChkContainer> 

                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catDance}
                                        onChange={this.handleChange}
                                        value="catDance"
                                    />
                                }
                            label={'Dance'}
                            />          
                        </ChkContainer> 

                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catEditorial}
                                        onChange={this.handleChange}
                                        value="catEditorial"
                                    />
                                }
                            label={'Editorial'}
                            />          
                        </ChkContainer> 

                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catFashion}
                                        onChange={this.handleChange}
                                        value="catFashion"
                                    />
                                }
                            label={'Fashion'}
                            />          
                        </ChkContainer> 

                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catFetish}
                                        onChange={this.handleChange}
                                        value="catFetish"
                                    />
                                }
                            label={'Fetish'}
                            />          
                        </ChkContainer> 

                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catFitness}
                                        onChange={this.handleChange}
                                        value="catFitness"
                                    />
                                }
                            label={'Fitness'}
                            />          
                        </ChkContainer> 

                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catLifestyle}
                                        onChange={this.handleChange}
                                        value="catLifestyle"
                                    />
                                }
                            label={'Lifestyle'}
                            />          
                        </ChkContainer> 

                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catLingerie}
                                        onChange={this.handleChange}
                                        value="catLingerie"
                                    />
                                }
                            label={'Lingerie'}
                            />          
                        </ChkContainer> 

                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catNude}
                                        onChange={this.handleChange}
                                        value="catNude"
                                    />
                                }
                            label={'Nude'}
                            />          
                        </ChkContainer> 

                        {/* <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catPinup}
                                        onChange={this.handleChange}
                                        value="catPinup"
                                    />
                                }
                            label={'Pinup'}
                            />          
                        </ChkContainer>  */}

                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catPortrait}
                                        onChange={this.handleChange}
                                        value="catPortrait"
                                    />
                                }
                            label={'Portrait'}
                            />          
                        </ChkContainer> 

                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catPregnancy}
                                        onChange={this.handleChange}
                                        value="catPregnancy"
                                    />
                                }
                            label={'Pregnancy'}
                            />          
                        </ChkContainer> 

                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catPromotional}
                                        onChange={this.handleChange}
                                        value="catPromotional"
                                    />
                                }
                            label={'Promotional'}
                            />          
                        </ChkContainer> 

                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catRunway}
                                        onChange={this.handleChange}
                                        value="catRunway"
                                    />
                                }
                            label={'Runway'}
                            />          
                        </ChkContainer> 

                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catSport}
                                        onChange={this.handleChange}
                                        value="catSport"
                                    />
                                }
                            label={'Sport'}
                            />          
                        </ChkContainer> 

                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catSwimwear}
                                        onChange={this.handleChange}
                                        value="catSwimwear"
                                    />
                                }
                            label={'Swimwear'}
                            />          
                        </ChkContainer> 

                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catTopless}
                                        onChange={this.handleChange}
                                        value="catTopless"
                                    />
                                }
                            label={'Topless'}
                            />          
                        </ChkContainer> 

                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catUnderwater}
                                        onChange={this.handleChange}
                                        value="catUnderwater"
                                    />
                                }
                            label={'Underwater'}
                            />          
                        </ChkContainer> 

                    </ChkGrid>

                    <IncreaseHeight/>
                
            </PageContent>

        )

    }
    
}




export const CategoriesPage = (withApollo(C));


const OrangeCheckBox = withStyles({
    root: {
      color: orange[400],
      '&$checked': {
        color: orange[600],
      },
    },
    checked: {},
    })(props => <Checkbox color="default" {...props} /> )
;


const ChkContainer = styled.div`
    
  padding-left: 16px;
  padding-top: 2px;
  padding-bottom: 2px;
  width: 155px;
  
`;

const ChkGrid = styled.div`
    
    display: flex;
    flex-wrap: wrap;    

`;

const IncreaseHeight = styled.div`
    
    height: 23px;  

`;

const PHeading = styled.div`
    
    font-size: 16px;  
    font-family: 'Fira Sans';
    font-weight: 300;

`;


const PageContent = styled.div`
    
    width: 100%;
    /* height: 100%; */
    /* min-height: 75vh; */
    font-family: 'Fira Sans';
    
`;


const PageTitle = styled.div`
    
    display: flex;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;

    display: none;
    @media (max-width: 790px) {
        display: block;
        }


`;

