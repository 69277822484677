import React, { PureComponent } from 'react'
import { withFormik } from "formik";



import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

//import axios from 'axios';
import { withApollo } from "react-apollo";

  
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

// import i18next from "i18next";
import { withStore } from '@spyna/react-store'

import "./../settings.css";
import './../../../../i18n'


import gql from "graphql-tag"
const updateMutation = gql`
    mutation($fld: String!, $value: String!) {
        updateField(fld: $fld, value: $value)
    }
`;



const OrangeCheckBox = withStyles({
    root: {
      color: orange[400],
      '&$checked': {
        color: orange[600],
      },
    },
    checked: {},
  })(props => <Checkbox color="default" {...props} />);




class C extends PureComponent {


    componentDidMount() {

        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        
        
        
    }

    
      
    handleChange = event => {

        this.setState({
            [event.target.value]: event.target.checked,
        });

    
        // console.log(event.target.value)
        // console.log(event.target.checked)

        this.props.store.set([event.target.value], event.target.checked ? "true" : false)

        this.props.client.mutate({
            mutation: updateMutation,
            variables: {
                fld: event.target.value,
                value: event.target.checked.toString()
            },
        }).then(results => {
            //console.log(results)
        }).catch(error => {
            //console.log("Error: ", error);
        })

      };





    render() {

        const chkReminders = this.props.store.get('chkReminders') === "true" ? true : false; 
        const chkRemindersP = this.props.store.get('chkRemindersP') === "true" ? true : false; 
        const chkMessages = this.props.store.get('chkMessages') === "true" ? true : false; 
        const chkMessagesP = this.props.store.get('chkMessagesP') === "true" ? true : false; 
        const chkRecomm = this.props.store.get('chkRecomm') === "true" ? true : false; 
        const chkRecommP = this.props.store.get('chkRecommP') === "true" ? true : false; 
        const chkInsp = this.props.store.get('chkInsp') === "true" ? true : false; 
        const chkInspP = this.props.store.get('chkInspP') === "true" ? true : false; 
        const chkLocal = this.props.store.get('chkLocal') === "true" ? true : false; 
        const chkLocalP = this.props.store.get('chkLocalP') === "true" ? true : false; 
        const chkHow = this.props.store.get('chkHow') === "true" ? true : false; 
        const chkHowP = this.props.store.get('chkHowP') === "true" ? true : false; 
        const chkInvite = this.props.store.get('chkInvite') === "true" ? true : false; 
        const chkInviteP = this.props.store.get('chkInviteP') === "true" ? true : false; 
        const chkSurvey = this.props.store.get('chkSurvey') === "true" ? true : false; 
        const chkSurveyP = this.props.store.get('chkSurveyP') === "true" ? true : false; 
        const chkPolicy = this.props.store.get('chkPolicy') === "true" ? true : false; 
        const chkPolicyP = this.props.store.get('chkPolicyP') === "true" ? true : false; 
        const chkTips = this.props.store.get('chkTips') === "true" ? true : false; 
        const chkTipsP = this.props.store.get('chkTipsP') === "true" ? true : false; 


        return (

            
            <ExtraWrapper>

                <Helmet>
                    <title>Notification Settings</title>
                    <meta name="description" content="Notifications" />
                    <meta name="keywords" content="photosouls,notifications" />
                </Helmet>
            


                        <ModalTitle>

                            <BackButton onClick={ () => { this.props.history.push({ pathname: '../settings' }) } } >
                                <FAIcon icon={faChevronLeft} color={'#000'} />
                            </BackButton>

                            <TitleHeadline>
                                Notifications
                            </TitleHeadline>

                        </ModalTitle>
                        
                        

                        <StyledH1>
                        Your Bookings
                        </StyledH1>

                            <StyledH2>
                            Reminders
                            </StyledH2>

                                <StyledPRow>
                                    <StyledP>
                                    Receive booking reminders, requests to write a review, pricing notices, and other reminders related to your activities on PhotoSouls
                                    </StyledP>
                                    
                                    <StyledP2>
                                        <FormControlLabel 
                                            control={
                                                <OrangeCheckBox
                                                    checked={chkReminders}
                                                    onChange={this.handleChange}
                                                    value="chkReminders"
                                                />
                                            }
                                        label="E-mail"
                                        />

                                        <FormControlLabel 
                                            control={
                                                <OrangeCheckBox
                                                    checked={chkRemindersP}
                                                    onChange={this.handleChange}
                                                    value="chkRemindersP"
                                                />
                                            }
                                        label="Push Notifications"
                                        />
                                    </StyledP2>
                                </StyledPRow>



                            <StyledH2>
                            Messages
                            </StyledH2>

                                <StyledPRow>
                                    <StyledP>
                                    Receive messages from service providers and buyers, including booking requests
                                    </StyledP>

                                    <StyledP2>
                                        <FormControlLabel 
                                            control={
                                                <OrangeCheckBox
                                                    checked={chkMessages}
                                                    onChange={this.handleChange}
                                                    value="chkMessages"
                                                />
                                            }
                                        label="E-mail"
                                        />

                                        <FormControlLabel 
                                            control={
                                                <OrangeCheckBox
                                                    checked={chkMessagesP}
                                                    onChange={this.handleChange}
                                                    value="chkMessagesP"
                                                />
                                            }
                                        label="Push Notifications"
                                        />
                                    </StyledP2>
                                </StyledPRow>

                            <StyledH2>
                            Recommendations
                            </StyledH2>

                                <StyledPRow>                    
                                    <StyledP>
                                    Get useful tips for your upcoming photoshoots
                                    </StyledP>

                                    <StyledP2>
                                        <FormControlLabel 
                                            control={
                                                <OrangeCheckBox
                                                    checked={chkRecomm}
                                                    onChange={this.handleChange}
                                                    value="chkRecomm"
                                                />
                                            }
                                        label="E-mail"
                                        />

                                        <FormControlLabel 
                                            control={
                                                <OrangeCheckBox
                                                    checked={chkRecommP}
                                                    onChange={this.handleChange}
                                                    value="chkRecommP"
                                                />
                                            }
                                        label="Push Notifications"
                                        />
                                    </StyledP2>
                                </StyledPRow>
        

                        <StyledH1>
                        Inspiration and local activities
                        </StyledH1>

                            <StyledH2>
                            Photo session inspiration and ideas
                            </StyledH2>

                                <StyledPRow>                    
                                    <StyledP>
                                    Get photoshoot ideas, suggestions based on your recent searches, discounts, and other deals
                                    </StyledP>

                                    <StyledP2>
                                        <FormControlLabel 
                                            control={
                                                <OrangeCheckBox
                                                    checked={chkInsp}
                                                    onChange={this.handleChange}
                                                    value="chkInsp"
                                                />
                                            }
                                        label="E-mail"
                                        />

                                        <FormControlLabel 
                                            control={
                                                <OrangeCheckBox
                                                    checked={chkInspP}
                                                    onChange={this.handleChange}
                                                    value="chkInspP"
                                                />
                                            }
                                        label="Push Notifications"
                                        />
                                    </StyledP2>
                                </StyledPRow>
                   


                            <StyledH2>
                            Things to do in your area
                            </StyledH2>

                                <StyledPRow>                    
                                    <StyledP>
                                    Find interesting opportunities in the area you're currently at.
                                    </StyledP>

                                    <StyledP2>
                                        <FormControlLabel 
                                            control={
                                                <OrangeCheckBox
                                                    checked={chkLocal}
                                                    onChange={this.handleChange}
                                                    value="chkLocal"
                                                />
                                            }
                                        label="E-mail"
                                        />

                                        <FormControlLabel 
                                            control={
                                                <OrangeCheckBox
                                                    checked={chkLocalP}
                                                    onChange={this.handleChange}
                                                    value="chkLocalP"
                                                />
                                            }
                                        label="Push Notifications"
                                        />
                                    </StyledP2>
                                </StyledPRow>



                        <StyledH1>
                        Features And Feedback
                        </StyledH1>

                            <StyledH2>
                            How PhotoSouls works
                            </StyledH2>

                                <StyledPRow>                    
                                    <StyledP>
                                    Learn how to use PhotoSouls and stay up-to-date on new features.
                                    </StyledP>

                                    <StyledP2>
                                        <FormControlLabel 
                                            control={
                                                <OrangeCheckBox
                                                    checked={chkHow}
                                                    onChange={this.handleChange}
                                                    value="chkHow"
                                                />
                                            }
                                        label="E-mail"
                                        />

                                        <FormControlLabel 
                                            control={
                                                <OrangeCheckBox
                                                    checked={chkHowP}
                                                    onChange={this.handleChange}
                                                    value="chkHowP"
                                                />
                                            }
                                        label="Push Notifications"
                                        />
                                    </StyledP2>
                                </StyledPRow>


                            <StyledH2>
                            Invites and referrals
                            </StyledH2>

                                <StyledPRow>                    
                                    <StyledP>
                                    Get notified about invites and referrals, and get photoshoot credit reminders
                                    </StyledP>

                                    <StyledP2>
                                        <FormControlLabel 
                                            control={
                                                <OrangeCheckBox
                                                    checked={chkInvite}
                                                    onChange={this.handleChange}
                                                    value="chkInvite"
                                                />
                                            }
                                        label="E-mail"
                                        />

                                        <FormControlLabel 
                                            control={
                                                <OrangeCheckBox
                                                    checked={chkInviteP}
                                                    onChange={this.handleChange}
                                                    value="chkInviteP"
                                                />
                                            }
                                        label="Push Notifications"
                                        />
                                    </StyledP2>
                                </StyledPRow>

                    

                            <StyledH2>
                            Surveys and research studies
                            </StyledH2>

                                <StyledPRow>                    
                                    <StyledP>
                                    Give valuable feedback in surveys and get invites to our research studies - sometimes there are fun rewards!
                                    </StyledP>

                                    <StyledP2>
                                        <FormControlLabel 
                                            control={
                                                <OrangeCheckBox
                                                    checked={chkSurvey}
                                                    onChange={this.handleChange}
                                                    value="chkSurvey"
                                                />
                                            }
                                        label="E-mail"
                                        />

                                        <FormControlLabel 
                                            control={
                                                <OrangeCheckBox
                                                    checked={chkSurveyP}
                                                    onChange={this.handleChange}
                                                    value="chkSurveyP"
                                                />
                                            }
                                        label="Push Notifications"
                                        />
                                    </StyledP2>
                                </StyledPRow>

                            <StyledH2>
                            Policy and community
                            </StyledH2>

                                <StyledPRow>                    
                                    <StyledP>
                                    Receive updates on policies and community.
                                    </StyledP>

                                    <StyledP2>
                                        <FormControlLabel 
                                            control={
                                                <OrangeCheckBox
                                                    checked={chkPolicy}
                                                    onChange={this.handleChange}
                                                    value="chkPolicy"
                                                />
                                            }
                                        label="E-mail"
                                        />

                                        <FormControlLabel 
                                            control={
                                                <OrangeCheckBox
                                                    checked={chkPolicyP}
                                                    onChange={this.handleChange}
                                                    value="chkPolicyP"
                                                />
                                            }
                                        label="Push Notifications"
                                        />
                                    </StyledP2>
                                </StyledPRow>


                            <StyledH2>
                            Tips on providing photography services
                            </StyledH2>

                                <StyledPRow>                    
                                    <StyledP>
                                    Get updates and tips about new features and tools, to help you provide your services successfully.
                                    </StyledP>

                                    <StyledP2>
                                        <FormControlLabel 
                                            control={
                                                <OrangeCheckBox
                                                    checked={chkTips}
                                                    onChange={this.handleChange}
                                                    value="chkTips"
                                                />
                                            }
                                        label="E-mail"
                                        />

                                        <FormControlLabel 
                                            control={
                                                <OrangeCheckBox
                                                    checked={chkTipsP}
                                                    onChange={this.handleChange}
                                                    value="chkTipsP"
                                                />
                                            }
                                        label="Push Notifications"
                                        />
                                    </StyledP2>
                                </StyledPRow>


                        <StyledH1>
                        Account Support
                        </StyledH1>

                            <StyledH2>
                            Account Support
                            </StyledH2>

                                <StyledPRow>                    
                                    <StyledP>
                                    We may need to send you messages regarding your account, your bookings, legal notifications, security and privacy matters, and customer support requests. For your security, you cannot disable e-mail notifications and we may contact you by phone or other means if needed.
                                    </StyledP>

                                    <StyledP2>
                                        <FormControlLabel 
                                            control={
                                                <Checkbox
                                                    checked={true}
                                                    //onChange={this.handleChange2}
                                                    value="chkAccount"
                                                    disabled
                                                />
                                            }
                                        label="E-mail"
                                        />

                                        <FormControlLabel 
                                            control={
                                                <Checkbox
                                                    checked={true}
                                                    //onChange={this.handleChange2}
                                                    value="chkAccountP"
                                                    disabled
                                                />
                                            }
                                        label="Push Notifications"
                                        />
                                    </StyledP2>
                                </StyledPRow>
                            
                        <StyledH1></StyledH1>


                    

    
            </ExtraWrapper>


        )
    }
}


const AccountSettingsNotifications = withFormik({
    
    //validationSchema: validEmailField,
    //console.log(this);

    mapPropsToValues: () => ({}),
    
    handleSubmit: async (values, { props, setErrors }) => {
        //console.log('handlesubmit')
    }
  })(C);
  

const personalInfoFields = [
    'fName', 'lName', 'bDay', 'email', 'phone', 'phoneDisplay', 'phoneCountry', 'avatar', 'isVerified', 'isPro', 'temperror', 'pwdUpdated',
    'chkReminders', 'chkRemindersP', 'chkMessages', 'chkMessagesP', 'chkRecomm', 'chkRecommP', 'chkInsp', 'chkInspP', 'chkLocal', 'chkLocalP', 
    'chkHow', 'chkHowP', 'chkInvite', 'chkInviteP', 'chkSurvey', 'chkSurveyP', 'chkPolicy', 'chkPolicyP', 'chkTips', 'chkTipsP'
]


export default withStore(withApollo(AccountSettingsNotifications), personalInfoFields);



const StyledH1 = styled.div`
  width: 100%;
  padding-bottom: 24px;
  padding-top: 24px;

  font-size: 20px;
  font-family: 'Fira Sans';
  font-weight: 500;
  color: #333333;

  margin-left: 24px;
  
  @media (max-width: 600px) {
    margin-left: 24px;
  }

  
`;

const StyledH2 = styled.div`
  width: 100%;
  margin-left: 48px;

  font-size: 18px;
  font-family: 'Fira Sans';
  font-weight: 700;
  color: #333333;
  margin-bottom: 4px;

  @media (max-width: 600px) {
    margin-left: 24px;
  }

`;


const StyledPRow = styled.div`
  width: 100%;
  display: flex;
  
`;

const StyledP = styled.div`
  width: 100%;
  margin-left: 48px;
  margin-bottom: 24px;
  
  @media (max-width: 600px) {
    margin-left: 24px;
  }
`;


const StyledP2 = styled.div`
  width: 100%;
  margin-left: 32px;
  margin-top: -12px;
  @media (max-width: 600px) {
  }
`;




const ExtraWrapper = styled.div`

    white-space: pre-line;
    width: calc(80% - 64px);
    max-width: 1200px;

    margin: 40px auto 32px auto;

    font-family: 'Fira Sans';

    @media (max-width: 790px) {
        width: calc(100% - 32px);
        margin: 0;
        overflow-x: hidden;
    }

`;


const ModalTitle = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;

    margin-left: 24px;

    @media (max-width: 970px) {
        flex-direction: column;
        margin-top: 16px;
    }


`;


const BackButton = styled(Button)`
    && {
        
    /* margin: -4px -4px 0 0px; */

    height: 48px;
    min-width: 48px;
    max-width: 48px;
    border-radius: 24px;

    margin-right: 16px;
    /* margin-right: 4px;
    margin-left: 4px; */

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;

    
    font-weight: 400;
    font-size: 19px;

    background-color: #EBEBEB;
    opacity: 0.6;

    &:hover {
        background-color: #EBEBEB;
        opacity: 0.6;
        }
    
    &:disabled {
        background-color: #fff;
        color: #fff;
        }

    }
    
`;

const FAIcon = styled(FontAwesomeIcon)`
    && {
    font-size: 22px;
    font-weight: 400;
    }
`;



const TitleHeadline = styled.div`
    
    padding: 12px 24px 24px 0px;

    font-family: 'Fira Sans';
    font-size: 22px;
    font-weight: 500;

    color: #333333;

`;
