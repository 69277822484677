
import React, { PureComponent } from 'react'
import { ChangePasswordView } from './ChangePasswordView'
import { ChangePasswordController } from '@psd/controller'

//import ReactGA from 'react-ga4';
// import { logEvent } from '@psd/web/src/utils/analytics/amplitude';

export class ChangePasswordConnector extends PureComponent {
  
    state = {
      loadingVisible: false
    };
      
    onFinish = () => {

      //ReactGA.event({ category: "User", action: "Password changed", });
      // logEvent('password reset completed')
      
      this.props.history.push("/");
      
    };

    updateLoader = ( staatus ) => {
      this.setState({ loadingVisible: staatus });
    }

  render() {

    const {
        match: {
          params: { key }
        }
      } = this.props;
    
    //console.log(key)

    return (

        <ChangePasswordController>
            {({ submit }) => (
                <ChangePasswordView 
                  state={this.state}
                  updateLoader={this.updateLoader}
                  onFinish={this.onFinish} 
                  token={key} 
                  submit={submit} 

                  />)}
        </ChangePasswordController>

    );
  }
}
