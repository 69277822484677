
import React, { Component } from 'react';
import styled from 'styled-components';
// import Button from '@material-ui/core/Button';

// import TimeAgo from './../../utils/TimeAgo'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faStar, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

// import getSymbolFromCurrency from 'currency-symbol-map'

import moment from "moment"; 
import i18next from "i18next";
import '../../i18n'

const photogCategories = [
    { name: 'Boudoir photoshoot', catNumber: 1, position: 14, thumb: 'https://www.photosouls.com/images/categories/boudoir.jpg', catField: 'catBoudoir' },
    { name: 'Couples photoshoot', catNumber: 2, position: 3, thumb: 'https://www.photosouls.com/images/categories/couples.jpg', catField: 'catCouples' },
    { name: 'Family photoshoot', catNumber: 3, position: 2, thumb: 'https://www.photosouls.com/images/categories/family.jpg', catField: 'catFamily' },
    { name: 'Fashion photoshoot', catNumber: 4, position: 13, thumb: 'https://www.photosouls.com/images/categories/fashion.jpg', catField: 'catFashion' },
    { name: 'Kids photoshoot', catNumber: 5, position: 10, thumb: 'https://www.photosouls.com/images/categories/kids.jpg', catField: 'catKids' },
    { name: 'Lifestyle photoshoot', catNumber: 6, position: 12, thumb: 'https://www.photosouls.com/images/categories/lifestyle.jpg', catField: 'catLifestyle' },
    { name: 'Newborn photoshoot', catNumber: 7, position: 9, thumb: 'https://www.photosouls.com/images/categories/newborn.jpg', catField: 'catNewborn' },
    { name: 'Nude photoshoot', catNumber: 11, position: 15, thumb: 'https://www.photosouls.com/images/categories/nude.jpg', catField: 'catNude' },         
    { name: 'Portrait photoshoot', catNumber: 8, position: 1, thumb: 'https://www.photosouls.com/images/categories/portrait.jpg', catField: 'catPortrait' },      
    { name: 'Pregnancy photoshoot', catNumber: 9, position: 8, thumb: 'https://www.photosouls.com/images/categories/pregnancy.jpg', catField: 'catPregnancy' },
    { name: 'Wedding photoshoot', catNumber: 10, position: 5, thumb: 'https://www.photosouls.com/images/categories/wedding.jpg', catField: 'catWedding' },
    { name: '--------------------', catNumber: 999, position: 999, thumb: '', catField: '--------------------' },
    { name: 'Architecture photoshoot', catNumber: 32, position: 16, thumb: 'https://www.photosouls.com/images/categories/architecture.jpg', catField: 'catArchitecture' },
    { name: 'Event photoshoot', catNumber: 33, position: 4, thumb: 'https://www.photosouls.com/images/categories/event.jpg', catField: 'catEvent' },
    { name: 'Food photoshoot', catNumber: 34, position: 17, thumb: 'https://www.photosouls.com/images/categories/food.jpg', catField: 'catFood' },
    { name: 'Landscape photoshoot', catNumber: 28, position: 18, thumb: 'https://www.photosouls.com/images/categories/landscape.jpg', catField: 'catLandscape' },
    { name: 'Nature photoshoot', catNumber: 29, position: 19, thumb: 'https://www.photosouls.com/images/categories/nature.jpg', catField: 'catNature' },
    { name: 'Pets photoshoot', catNumber: 30, position: 7, thumb: 'https://www.photosouls.com/images/categories/pets.jpg', catField: 'catPets' },
    { name: 'Product photoshoot', catNumber: 35, position: 11, thumb: 'https://www.photosouls.com/images/categories/product.jpg', catField: 'catProduct' },
    { name: 'Real estate photoshoot', catNumber: 36, position: 6, thumb: 'https://www.photosouls.com/images/categories/realestate.jpg', catField: 'catRealestate' },
    { name: 'Sport photoshoot', catNumber: 37, position: 20, thumb: 'https://www.photosouls.com/images/categories/sport.jpg', catField: 'catSport' },
    { name: 'Street photoshoot', catNumber: 38, position: 21, thumb: 'https://www.photosouls.com/images/categories/street.jpg', catField: 'catStreet' },
    { name: 'Wildlife photoshoot', catNumber: 31, position: 22, thumb: 'https://www.photosouls.com/images/categories/wildlife.jpg', catField: 'catWildlife' },
    { name: '--------------------', catNumber: 999, position: 999, thumb: '', catField: '--------------------' },
    { name: 'Acting photoshoot', catNumber: 12, position: 23, thumb: 'https://www.photosouls.com/images/categories/acting.jpg', catField: 'catActing' },
    { name: 'Art photoshoot', catNumber: 13, position: 24, thumb: 'https://www.photosouls.com/images/categories/art.jpg', catField: 'catArt' },
    { name: 'Bodypaint photoshoot', catNumber: 14, position: 25, thumb: 'https://www.photosouls.com/images/categories/bodypaint.jpg', catField: 'catBodypaint' },
    { name: 'Cosplay photoshoot', catNumber: 15, position: 26, thumb: 'https://www.photosouls.com/images/categories/cosplay.jpg', catField: 'catCosplay' },
    { name: 'Dance photoshoot', catNumber: 16, position: 27, thumb: 'https://www.photosouls.com/images/categories/dance.jpg', catField: 'catDance' },
    { name: 'Editorial photoshoot', catNumber: 17, position: 28, thumb: 'https://www.photosouls.com/images/categories/editorial.jpg', catField: 'catEditorial' },
    { name: 'Fetish photoshoot', catNumber: 18, position: 29, thumb: 'https://www.photosouls.com/images/categories/fetish.jpg', catField: 'catFetish' },
    { name: 'Fitness photoshoot', catNumber: 19, position: 30, thumb: 'https://www.photosouls.com/images/categories/fitness.jpg', catField: 'catFitness' },
    { name: 'Lingerie photoshoot', catNumber: 20, position: 31, thumb: 'https://www.photosouls.com/images/categories/lingerie.jpg', catField: 'catLingerie' },
    // { name: 'Pinup', catNumber: 21, position: 32, thumb: 'https://www.photosouls.com/images/categories/lingerie.jpg' },
    { name: 'Promotional photoshoot', catNumber: 23, position: 33, thumb: 'https://www.photosouls.com/images/categories/promotional.jpg', catField: 'catPromotional' },
    { name: 'Runway photoshoot', catNumber: 24, position: 34, thumb: 'https://www.photosouls.com/images/categories/runway.jpg', catField: 'catRunway' },
    { name: 'Swimwear photoshoot', catNumber: 25, position: 35, thumb: 'https://www.photosouls.com/images/categories/swimwear.jpg', catField: 'catSwimwear' },
    { name: 'Topless photoshoot', catNumber: 26, position: 36, thumb: 'https://www.photosouls.com/images/categories/topless.jpg', catField: 'catTopless' },
    { name: 'Underwater photoshoot', catNumber: 27, position: 37, thumb: 'https://www.photosouls.com/images/categories/underwater.jpg', catField: 'catUnderwater' },
]; 


export class BookingCard extends Component {
    
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //     }

    // }

    localeUses24HourTime = (locale) => {

        // if locale === undefined => current user's locale, otherwise uses "locale"

        //returns true, if 24H time is used in this locale/language
        return new Intl.DateTimeFormat(locale, { hour: 'numeric' })
            .formatToParts(new Date(2020, 0, 1, 13))
            .find(part => part.type === 'hour')
            .value.length === 2;

    }

    timeOptions = (use24h) => {

        if (use24h) {
            return [
                { value: '0', code: '00:00', label: '00:00' },
                { value: '1', code: '00:30', label: '00:30' },
                { value: '2', code: '01:00', label: '01:00' },
                { value: '3', code: '01:30', label: '01:30' },
                { value: '4', code: '02:00', label: '02:00' },
                { value: '5', code: '02:30', label: '02:30' },
                { value: '6', code: '03:00', label: '03:00' },
                { value: '7', code: '03:30', label: '03:30' },
                { value: '8', code: '04:00', label: '04:00' },
                { value: '9', code: '04:30', label: '04:30' },
                { value: '10', code: '05:00', label: '05:00' },
                { value: '11', code: '05:30', label: '05:30' },
                { value: '12', code: '06:00', label: '06:00' },
                { value: '13', code: '06:30', label: '06:30' },
                { value: '14', code: '07:00', label: '07:00' },
                { value: '15', code: '07:30', label: '07:30' },
                { value: '16', code: '08:00', label: '08:00' },
                { value: '17', code: '08:30', label: '08:30' },
                { value: '18', code: '09:00', label: '09:00' },
                { value: '19', code: '09:30', label: '09:30' },
                { value: '20', code: '10:00', label: '10:00' },
                { value: '21', code: '10:30', label: '10:30' },
                { value: '22', code: '11:00', label: '11:00' },
                { value: '23', code: '11:30', label: '11:30' },
                { value: '24', code: '12:00', label: '12:00' },
                { value: '25', code: '12:30', label: '12:30' },
                { value: '26', code: '13:00', label: '13:00' },
                { value: '27', code: '13:30', label: '13:30' },
                { value: '28', code: '14:00', label: '14:00' },
                { value: '29', code: '14:30', label: '14:30' },
                { value: '30', code: '15:00', label: '15:00' },
                { value: '31', code: '15:30', label: '15:30' },
                { value: '32', code: '16:00', label: '16:00' },
                { value: '33', code: '16:30', label: '16:30' },
                { value: '34', code: '17:00', label: '17:00' },
                { value: '35', code: '17:30', label: '17:30' },
                { value: '36', code: '18:00', label: '18:00' },
                { value: '37', code: '18:30', label: '18:30' },
                { value: '38', code: '19:00', label: '19:00' },
                { value: '39', code: '19:30', label: '19:30' },
                { value: '40', code: '20:00', label: '20:00' },
                { value: '41', code: '20:30', label: '20:30' },
                { value: '42', code: '21:00', label: '21:00' },
                { value: '43', code: '21:30', label: '21:30' },
                { value: '44', code: '22:00', label: '22:00' },
                { value: '45', code: '22:30', label: '22:30' },
                { value: '46', code: '23:00', label: '23:00' },
                { value: '47', code: '23:30', label: '23:30' },
            ]
        } else {
            return [
                { value: '0', code: '00:00', label: '12:00 AM' },
                { value: '1', code: '00:30', label: '12:30 AM' },
                { value: '2', code: '01:00', label: '01:00 AM' },
                { value: '3', code: '01:30', label: '01:30 AM' },
                { value: '4', code: '02:00', label: '02:00 AM' },
                { value: '5', code: '02:30', label: '02:30 AM' },
                { value: '6', code: '03:00', label: '03:00 AM' },
                { value: '7', code: '03:30', label: '03:30 AM' },
                { value: '8', code: '04:00', label: '04:00 AM' },
                { value: '9', code: '04:30', label: '04:30 AM' },
                { value: '10', code: '05:00', label: '05:00 AM' },
                { value: '11', code: '05:30', label: '05:30 AM' },
                { value: '12', code: '06:00', label: '06:00 AM' },
                { value: '13', code: '06:30', label: '06:30 AM' },
                { value: '14', code: '07:00', label: '07:00 AM' },
                { value: '15', code: '07:30', label: '07:30 AM' },
                { value: '16', code: '08:00', label: '08:00 AM' },
                { value: '17', code: '08:30', label: '08:30 AM' },
                { value: '18', code: '09:00', label: '09:00 AM' },
                { value: '19', code: '09:30', label: '09:30 AM' },
                { value: '20', code: '10:00', label: '10:00 AM' },
                { value: '21', code: '10:30', label: '10:30 AM' },
                { value: '22', code: '11:00', label: '11:00 AM' },
                { value: '23', code: '11:30', label: '11:30 AM' },
                { value: '24', code: '12:00', label: '12:00 PM' },
                { value: '25', code: '12:30', label: '12:30 PM' },
                { value: '26', code: '13:00', label: '01:00 PM' },
                { value: '27', code: '13:30', label: '01:30 PM' },
                { value: '28', code: '14:00', label: '02:00 PM' },
                { value: '29', code: '14:30', label: '02:30 PM' },
                { value: '30', code: '15:00', label: '03:00 PM' },
                { value: '31', code: '15:30', label: '03:30 PM' },
                { value: '32', code: '16:00', label: '04:00 PM' },
                { value: '33', code: '16:30', label: '04:30 PM' },
                { value: '34', code: '17:00', label: '05:00 PM' },
                { value: '35', code: '17:30', label: '05:30 PM' },
                { value: '36', code: '18:00', label: '06:00 PM' },
                { value: '37', code: '18:30', label: '06:30 PM' },
                { value: '38', code: '19:00', label: '07:00 PM' },
                { value: '39', code: '19:30', label: '07:30 PM' },
                { value: '40', code: '20:00', label: '08:00 PM' },
                { value: '41', code: '20:30', label: '08:30 PM' },
                { value: '42', code: '21:00', label: '09:00 PM' },
                { value: '43', code: '21:30', label: '09:30 PM' },
                { value: '44', code: '22:00', label: '10:00 PM' },
                { value: '45', code: '22:30', label: '10:30 PM' },
                { value: '46', code: '23:00', label: '11:00 PM' },
                { value: '47', code: '23:30', label: '11:30 PM' },
            ]
        }
    }


    render() {
      
        moment.locale(i18next.language);
        
        // console.log('this.props.bookingData', this.props.bookingData)
        
        // const lenDays = this.props.lenDays
        // const albums = this.props.albums ? this.props.albums : []
        // const name = this.props.name ? this.props.name : '';
        // const instaBook = this.props.instaBook ? this.props.instaBook : false;
        // const avgRating = this.props.bookingData.avgRating ? this.props.bookingData.avgRating : 0;
        // const reviewsTotal = this.props.bookingData.reviewsTotal ? this.props.bookingData.reviewsTotal : 0;
        // let currencySymbol = getSymbolFromCurrency(currency);
        // if (currencySymbol === undefined) { currencySymbol = '' }
        // bookingInfo = `Starting from ${currencySymbol}${Math.round(avgPrice)} ${currency}`

        // const isMobileScreen = window.matchMedia('(max-width: 790px)').matches;
        
        let isClient = false;
        if (this.props.bookingData.clientUserId === this.props.myUserId) {
           isClient = true
        } 
       
        
        // isClient = false;

        const now = moment(new Date()); //todays date
        const bookingDate = moment(this.props.bookingData.dt/1000*1000)
        // const bookingDate2 = moment(this.props.bookingData.dt2/1000*1000)
        const bookedDate =  moment(this.props.bookingData.booked/1000*1000)
        const deadline = moment(bookedDate.add(1, 'days')).format('LLL');

        let inThePast = false;
        if (bookingDate < now) { inThePast = true; }

        const daysBetween = now.diff(bookingDate, 'days') * -1;
        var topTitle = '';
        var hasTopTitle = true; 
        if (daysBetween === 0) { topTitle = 'Today' }
        else if (daysBetween === 1) { topTitle = 'Tomorrow' }
        else if (daysBetween > 1 && daysBetween <= 4) { topTitle = 'In few days' }
        else if (daysBetween > 4 && daysBetween <= 7) { topTitle = 'Within a week' }
        else if (daysBetween > 7 && daysBetween <= 14) { topTitle = 'Coming soon' }
        else { topTitle = ''; hasTopTitle = false; }


        let dateTitle = moment(this.props.bookingData.dt/1000*1000).format('LL'); //#ANOMALY! without /1000*1000 it is giving "invalid date"
        
        let startTime = this.props.bookingData.st
        let endTime = this.props.bookingData.et
        const use24h = this.localeUses24HourTime(); 
        startTime = this.timeOptions(use24h).find(op => { return op.code === this.props.bookingData.st }).label
        endTime = this.timeOptions(use24h).find(op => { return op.code === this.props.bookingData.et }).label
        let dateTitle2 = `${startTime} - ${endTime}`


        if ((this.props.bookingData.provider === 4) && (this.props.bookingData.dt !== this.props.bookingData.dt2)) {
            dateTitle = "From " + moment(this.props.bookingData.dt/1000*1000).format('LL') + " (" + this.props.bookingData.st + ")"; 
            dateTitle2 = "to " + moment(this.props.bookingData.dt2/1000*1000).format('LL') + " (" + this.props.bookingData.et + ")";
        }

        

        
        let catName = '';
        for (var i = photogCategories.length-1; i >= 0; i--) {
            if (photogCategories[i].catNumber === this.props.bookingData.catNumber) {
                catName = photogCategories[i].name;
            } 
        };


        const location = this.props.bookingData.location;

        const packageName = this.props.bookingData.packageName


        let totalAmountLabel = '';
        if (isClient) {
            totalAmountLabel = `Total: ${parseFloat(this.props.bookingData.userCurrencyTotal).toFixed(2)} ${this.props.bookingData.userCurrency}`
        } else {
            totalAmountLabel = `Total: ${parseFloat(this.props.bookingData.total).toFixed(2)} ${this.props.bookingData.currency}`
        }

        // let personName = ''
        // let personType = ''

        
        const status = this.props.bookingData.status
        let statusColor = 0;
        let statusText = '';

        
        if (status === 1) {

            statusColor = 1; //green
            statusText = 'This booking is confirmed';

        } else if (status === 0) {
            
            if (isClient) {
                statusColor = 2; //orange'
                statusText = `Your booking will be confirmed or rejected by ${deadline}`;
            } else {
                statusColor = 2; //orange
                statusText = `Confirmation required. Please confirm or reject this booking by ${deadline}. Otherwise it will be rejected automatically.`;
            }
       
        } else if (status === 3) {
            
            statusColor = 3; //red
            statusText = 'This booking has been rejected';
            hasTopTitle = false;
       
        } else if (status === 4) {
            
            statusColor = 3; //red
            statusText = 'This booking was cancelled';
            hasTopTitle = false;
       
        } else if (status === 5) {
            
            statusColor = 3; //red
            statusText = 'This booking was refunded';
            hasTopTitle = false;
       
        } else if (status === 9) {
            
            statusColor = 3; //red
            statusText = isClient ? 'Payment failed. Please re-enter the payment information.' : 'Payment failed. Client was asked to re-enter the payment information.';
            hasTopTitle = false;
       
        } 





        return (
        
            <>

                <PackageCard 
                    key={this.props.index}
                    onClick={this.props.onClick}
                    >

                    {/* <ClickOverlay onClick={this.props.onClick}></ClickOverlay> */}

                    {
                        hasTopTitle ? 
                            <> 
                                <IncreaseHeight8/> 
                                <TopTitle>{topTitle}</TopTitle>
                            </>
                           
                            :
                            <IncreaseHeight8/>
                    }

                    {
                        this.props.inThePast && this.props.bookingData.status === 0 ?
                            
                            <TopTitle statusColor={'2'}>
                                Unconfirmed
                            </TopTitle>
                        
                        : this.props.inThePast && this.props.bookingData.status === 1 ?

                            <TopTitle statusColor={'1'}>
                                Confirmed
                            </TopTitle>

                        : this.props.inThePast && this.props.bookingData.status === 3 ?

                            <TopTitle statusColor={'3'}>
                                Rejected
                            </TopTitle>

                        : this.props.inThePast && this.props.bookingData.status === 4 ?

                            <TopTitle statusColor={'3'}>
                                Cancelled
                            </TopTitle>

                        : this.props.inThePast && this.props.bookingData.status === 5 ?

                            <TopTitle statusColor={'3'}>
                                Refunded
                            </TopTitle>

                        : 
                        null
                            
                    }

                    {

                        inThePast === false ?
                            statusColor === 1 ? 
                                <TopTitle statusColor='1'>{statusText}</TopTitle>
                            :
                            statusColor === 2 ? 
                                <TopTitle statusColor='2'>{statusText}</TopTitle>
                                :
                                <TopTitle statusColor='3'>{statusText}</TopTitle>
                            :
                            null

                    }


                    <DateTitle statusColor={statusColor}>{dateTitle}</DateTitle>
                    <Title2 statusColor={statusColor}>{dateTitle2}</Title2>

                    <TypeAndLocation>{catName}</TypeAndLocation>
                    { this.props.bookingData.provider === 4 ? 
                        <TypeAndLocation>Event in {location}</TypeAndLocation>
                        : 
                        <TypeAndLocation>{location}</TypeAndLocation>
                    }


                    <DateTitle>{packageName}</DateTitle>
                    <PackageInfo>{totalAmountLabel}</PackageInfo>

                    <IncreaseHeight8/>
                    <IncreaseHeight8/>

                    
                    
                </PackageCard> 

            </>
        
        );

    }


}




const PackageCard = styled.div`
   
   /* background-color: #ded; */
    width: 375px;
   
    margin-top: 16px;
    margin-bottom: 32px;
    margin-right: 48px;
    margin-left: 5px;

    align-items: flex-start;
    border-radius: 5px;
    
    
    border: 1px solid #cbcbcb;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    z-index: 1;

    &:hover {
        transform: scale(1.02);
        cursor: pointer;
       
    }

    @media (max-width: 790px) {
        width: calc(100% - 24px);
    }


`;


const TopTitle = styled.div`
    
    display: absolute;
    position: relative;
    text-align: left;
    float: left; 

    width: calc(100% - 96px);
    
    margin-right: auto;
    margin-left: 32px;
    
    margin-top: 8px; 

    border-radius: 0px;
   

    
    padding: 4px 4px 4px 4px;
    border-bottom: ${(props) => props.statusColor === '1' ? '2px solid #4c8c17' : props.statusColor === '2' ? '2px solid #ff9500' : '2px solid #FF6A6A' };
    
    color: ${(props) => props.statusColor === '1' ? '#4c8c17' : props.statusColor === '2' ? '#ff9500' : '#FF6A6A' };

    font-family: 'Fira Sans';
    font-size: 18px;
    font-weight: 400;

    &:hover {
        cursor: pointer;
    }

    z-index: 3;

`;


const DateTitle = styled.div`
    
    display: absolute;
    position: relative;
    text-align: left;
    float: left; 

    margin-right: 0;
    margin-left: 32px;
    margin-top: 16px;
        

    width: 335px;

    color: ${(props) => props.statusColor === 3 ? '#bdbdbd' : '#333333' };
    text-decoration: ${(props) => props.statusColor === 3 ? 'line-through' : 'none' };;
    

    font-family: 'Fira Sans';
    font-size: 18px;
    font-weight: 500;
    
    white-space: pre-wrap;
    margin-bottom: 8px;

    z-index: 4;

    &:hover {
        cursor: pointer;
    }

`;

const Title2 = styled.div`
    
    display: absolute;
    position: relative;
    text-align: left;
    float: left; 

    margin-right: 0;
    margin-left: 32px;
    margin-top: -8px;
        

    width: 335px;

    color: ${(props) => props.statusColor === 3 ? '#bdbdbd' : '#333333' };
    text-decoration: ${(props) => props.statusColor === 3 ? 'line-through' : 'none' };
    
    font-family: 'Fira Sans';
    font-size: 18px;
    font-weight: 500;
    
    white-space: pre-wrap;
    margin-bottom: 8px;

    z-index: 4;

    &:hover {
        cursor: pointer;
    }

`;

const TypeAndLocation = styled.div`
    
    display: absolute;
    position: relative;
    text-align: left;
    float: left; 

    margin-right: 0;
    margin-left: 32px;
    margin-top: 0px;
        

    width: 335px;

    color: #333333;
    font-family: 'Fira Sans';
    font-size: 16px;
    font-weight: 300;
    
    white-space: pre-wrap;

    z-index: 4;

    &:hover {
        cursor: pointer;
    }

`;

const PackageInfo = styled.div`
    
    display: absolute;
    position: relative;
    text-align: left;
    float: left; 

    margin-right: 0;
    margin-left: 32px;
    margin-top: -8px;
        

    width: 335px;

    color: #333333;
    font-family: 'Fira Sans';
    font-size: 16px;
    font-weight: 400;
    
    white-space: pre-wrap;

    z-index: 4;

    &:hover {
        cursor: pointer;
    }

`;

const IncreaseHeight8 = styled.div`
    
    display: absolute;
    position: relative;
    text-align: left;
    float: left; 

    width: 335px;
    height: 16px;  
    
    
`;

