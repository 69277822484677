
import React, { PureComponent } from 'react'
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";

import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/RadioGroup';
// import FormControl from '@material-ui/core/RadioGroup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import { MyError } from "../components/MyError";


import moment from "moment"; 
import i18next from "i18next";
import '../../i18n'
import TimeAgo from './../../utils/TimeAgo'

import { withStore } from '@spyna/react-store'
import { withApollo } from "react-apollo";
import { withRouter } from 'react-router-dom';

import gql from "graphql-tag";

import toast from 'toasted-notes' 
import 'toasted-notes/src/styles.css';
import StarRating from '../components/StarRating';


const photogCategories = [
    { name: 'Boudoir photoshoot', catNumber: 1, position: 14, thumb: 'https://www.photosouls.com/images/categories/boudoir.jpg', catField: 'catBoudoir' },
    { name: 'Couples photoshoot', catNumber: 2, position: 3, thumb: 'https://www.photosouls.com/images/categories/couples.jpg', catField: 'catCouples' },
    { name: 'Family photoshoot', catNumber: 3, position: 2, thumb: 'https://www.photosouls.com/images/categories/family.jpg', catField: 'catFamily' },
    { name: 'Fashion photoshoot', catNumber: 4, position: 13, thumb: 'https://www.photosouls.com/images/categories/fashion.jpg', catField: 'catFashion' },
    { name: 'Kids photoshoot', catNumber: 5, position: 10, thumb: 'https://www.photosouls.com/images/categories/kids.jpg', catField: 'catKids' },
    { name: 'Lifestyle photoshoot', catNumber: 6, position: 12, thumb: 'https://www.photosouls.com/images/categories/lifestyle.jpg', catField: 'catLifestyle' },
    { name: 'Newborn photoshoot', catNumber: 7, position: 9, thumb: 'https://www.photosouls.com/images/categories/newborn.jpg', catField: 'catNewborn' },
    { name: 'Nude photoshoot', catNumber: 11, position: 15, thumb: 'https://www.photosouls.com/images/categories/nude.jpg', catField: 'catNude' },         
    { name: 'Portrait photoshoot', catNumber: 8, position: 1, thumb: 'https://www.photosouls.com/images/categories/portrait.jpg', catField: 'catPortrait' },      
    { name: 'Pregnancy photoshoot', catNumber: 9, position: 8, thumb: 'https://www.photosouls.com/images/categories/pregnancy.jpg', catField: 'catPregnancy' },
    { name: 'Wedding photoshoot', catNumber: 10, position: 5, thumb: 'https://www.photosouls.com/images/categories/wedding.jpg', catField: 'catWedding' },
    { name: '--------------------', catNumber: 999, position: 999, thumb: '', catField: '--------------------' },
    { name: 'Architecture photoshoot', catNumber: 32, position: 16, thumb: 'https://www.photosouls.com/images/categories/architecture.jpg', catField: 'catArchitecture' },
    { name: 'Event photoshoot', catNumber: 33, position: 4, thumb: 'https://www.photosouls.com/images/categories/event.jpg', catField: 'catEvent' },
    { name: 'Food photoshoot', catNumber: 34, position: 17, thumb: 'https://www.photosouls.com/images/categories/food.jpg', catField: 'catFood' },
    { name: 'Landscape photoshoot', catNumber: 28, position: 18, thumb: 'https://www.photosouls.com/images/categories/landscape.jpg', catField: 'catLandscape' },
    { name: 'Nature photoshoot', catNumber: 29, position: 19, thumb: 'https://www.photosouls.com/images/categories/nature.jpg', catField: 'catNature' },
    { name: 'Pets photoshoot', catNumber: 30, position: 7, thumb: 'https://www.photosouls.com/images/categories/pets.jpg', catField: 'catPets' },
    { name: 'Product photoshoot', catNumber: 35, position: 11, thumb: 'https://www.photosouls.com/images/categories/product.jpg', catField: 'catProduct' },
    { name: 'Real estate photoshoot', catNumber: 36, position: 6, thumb: 'https://www.photosouls.com/images/categories/realestate.jpg', catField: 'catRealestate' },
    { name: 'Sport photoshoot', catNumber: 37, position: 20, thumb: 'https://www.photosouls.com/images/categories/sport.jpg', catField: 'catSport' },
    { name: 'Street photoshoot', catNumber: 38, position: 21, thumb: 'https://www.photosouls.com/images/categories/street.jpg', catField: 'catStreet' },
    { name: 'Wildlife photoshoot', catNumber: 31, position: 22, thumb: 'https://www.photosouls.com/images/categories/wildlife.jpg', catField: 'catWildlife' },
    { name: '--------------------', catNumber: 999, position: 999, thumb: '', catField: '--------------------' },
    { name: 'Acting photoshoot', catNumber: 12, position: 23, thumb: 'https://www.photosouls.com/images/categories/acting.jpg', catField: 'catActing' },
    { name: 'Art photoshoot', catNumber: 13, position: 24, thumb: 'https://www.photosouls.com/images/categories/art.jpg', catField: 'catArt' },
    { name: 'Bodypaint photoshoot', catNumber: 14, position: 25, thumb: 'https://www.photosouls.com/images/categories/bodypaint.jpg', catField: 'catBodypaint' },
    { name: 'Cosplay photoshoot', catNumber: 15, position: 26, thumb: 'https://www.photosouls.com/images/categories/cosplay.jpg', catField: 'catCosplay' },
    { name: 'Dance photoshoot', catNumber: 16, position: 27, thumb: 'https://www.photosouls.com/images/categories/dance.jpg', catField: 'catDance' },
    { name: 'Editorial photoshoot', catNumber: 17, position: 28, thumb: 'https://www.photosouls.com/images/categories/editorial.jpg', catField: 'catEditorial' },
    { name: 'Fetish photoshoot', catNumber: 18, position: 29, thumb: 'https://www.photosouls.com/images/categories/fetish.jpg', catField: 'catFetish' },
    { name: 'Fitness photoshoot', catNumber: 19, position: 30, thumb: 'https://www.photosouls.com/images/categories/fitness.jpg', catField: 'catFitness' },
    { name: 'Lingerie photoshoot', catNumber: 20, position: 31, thumb: 'https://www.photosouls.com/images/categories/lingerie.jpg', catField: 'catLingerie' },
    // { name: 'Pinup', catNumber: 21, position: 32, thumb: 'https://www.photosouls.com/images/categories/lingerie.jpg' },
    { name: 'Promotional photoshoot', catNumber: 23, position: 33, thumb: 'https://www.photosouls.com/images/categories/promotional.jpg', catField: 'catPromotional' },
    { name: 'Runway photoshoot', catNumber: 24, position: 34, thumb: 'https://www.photosouls.com/images/categories/runway.jpg', catField: 'catRunway' },
    { name: 'Swimwear photoshoot', catNumber: 25, position: 35, thumb: 'https://www.photosouls.com/images/categories/swimwear.jpg', catField: 'catSwimwear' },
    { name: 'Topless photoshoot', catNumber: 26, position: 36, thumb: 'https://www.photosouls.com/images/categories/topless.jpg', catField: 'catTopless' },
    { name: 'Underwater photoshoot', catNumber: 27, position: 37, thumb: 'https://www.photosouls.com/images/categories/underwater.jpg', catField: 'catUnderwater' },
]; 

const postMessageMutation = gql`
    mutation postMessage($bookingId: String, $dt: String, $from: Int, $message: String) {
        postMessage(bookingId: $bookingId, dt: $dt, from: $from, message: $message) { 
            dt, from, message 
        }
    }
`;

const postReviewMutation = gql`
    mutation postReview(
                $bookingId: String, 
                $id: String, 
                $dt: String, 
                $from: Int, 
                $rating: Int, 
                $message: String,
                $privateMessage: String,
                $response: String,
                $reported: Boolean,
                $reportResolved: Boolean,
                $hideBasedOnReport: Boolean,
                $reportDecision: String,

                ) {
                    postReview(
                            bookingId: $bookingId, 
                            id: $id,
                            dt: $dt, 
                            from: $from, 
                            rating: $rating, 
                            message: $message,
                            privateMessage: $privateMessage,
                            response: $response,
                            reported: $reported,
                            reportResolved: $reportResolved,
                            hideBasedOnReport: $hideBasedOnReport,
                            reportDecision: $reportDecision,
                            ) { 
                                id, dt, from, rating, message, privateMessage, response, reported, reportResolved, hideBasedOnReport, reportDecision 
                            }
                }
`;

const cancelBookingMutation = gql`
    mutation cancelBooking($bookingId: String, $from: Int, $percentage: Int, $cancellationReason: String) {
        cancelBooking(bookingId: $bookingId, from: $from, percentage: $percentage, cancellationReason: $cancellationReason)
    }
`;

const rejectBookingMutation = gql`
    mutation rejectBooking($bookingId: String, $from: Int, $rejectionReason: String) {
        rejectBooking(bookingId: $bookingId, from: $from, rejectionReason: $rejectionReason)
    }
`;

const confirmBookingMutation = gql`
    mutation confirmBooking($bookingId: String, $from: Int, $confirmationReason: String) {
        confirmBooking(bookingId: $bookingId, from: $from, confirmationReason: $confirmationReason)
    }
`;

const refundBookingMutation = gql`
    mutation refundBooking($bookingId: String, $from: Int, $refundReason: String) {
        refundBooking(bookingId: $bookingId, from: $from, refundReason: $refundReason)
    }
`;



class C extends PureComponent {
    
    constructor(props) {
        super(props);
        this.state = {
            
            errorMsg: '',
            newMessagePopup: false,
            newReviewPopup: false,
            newReviewType: 0,
            reviewId: '0',
            ratingSet: 0,
            messageField: '',
            reviewField: '',
            cancellationPopup: false,
            cancellationReason: '',
            rejectionPopup: false,
            rejectionReason: '',
            confirmationPopup: false,
            confirmationReason: '',
            refundPopup: false,
            refundReason: '',
            refundPercentage: 0,

        }

        this.fldValueChange = this.fldValueChange.bind(this);

    }


    fldValueChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({ [name]: value, });
        
    }
    


    newMessage = async () => {

        setTimeout(() => { 
            this.setState({ 
                newMessagePopup: true
            }) 
        }, 50);

    }

    newReview = async (type, id) => {

        setTimeout(() => { 
            this.setState({ 
                newReviewPopup: true,
                newReviewType: type,
                reviewId: id,

            }) 
        }, 50);

    }

    postMessage = async (isClient, bookingId) => {

        let resultOK = false

        await this.props.client
            .mutate({
                mutation: postMessageMutation,
                variables: {
                    
                    bookingId: bookingId,
                    dt: new Date(), 
                    from: isClient ? 0 : 1,  //0=client, 1=provider
                    message: this.state.messageField,
                    
                },
            })
            .then(results => {

                // console.log(results)
                
                if (JSON.stringify(results).length > 15) {

                    resultOK = true

                } else {
                    toast.notify(
                        <div style={{ "color": "red" }}>Error #735. Unable to post a new message. Please send us the error number so we could fix it.</div>, 
                        { duration: 10000 }
                        );
                }
                
            })
            .catch(error => {
                
                toast.notify(
                    <div style={{ "color": "red" }}>Error #738. Unable to post a new message. Please send us the error number so we could fix it.</div>, 
                    { duration: 10000 }
                    );
                
                //console.log("Error: ", error);
            })
    

        if (resultOK) { 

            setTimeout(() => { 
                this.setState({ 
                    newMessagePopup: false
                }) 
            }, 50);

            window.location.reload();
            // this.props.history.push({ pathname: '/' })
        }

    }


    postReview = async (isClient, bookingId) => {

        let resultOK = false

        //isClient
        //bookingId
        //this.state.newReviewType
        //console.log('post id: ', this.state.reviewId)

        await this.props.client
            .mutate({
                mutation: postReviewMutation,
                variables: {
                    
                    bookingId: bookingId,
                    
                    id: this.state.reviewId,
                    dt: new Date(), 

                    from: isClient ? 0 : 1,
                    rating: this.state.ratingSet, 
                    message: this.state.newReviewType === 0 ? this.state.reviewField : "",
                    privateMessage: "",
                    response: this.state.newReviewType === 1 ? this.state.reviewField : "",
                    reported: false,
                    reportResolved: false,
                    hideBasedOnReport: false,
                    reportDecision: "",
                    
                },
            })
            .then(results => {

                // console.log(results)
                
                if (JSON.stringify(results).length > 15) {

                    resultOK = true

                } else {
                    toast.notify(
                        <div style={{ "color": "red" }}>Error #795. Unable to post a review. Please send us the error number so we could fix it.</div>, 
                        { duration: 10000 }
                        );
                }
                
            })
            .catch(error => {
                
                toast.notify(
                    <div style={{ "color": "red" }}>Error #798. Unable to post a review. Please send us the error number so we could fix it.</div>, 
                    { duration: 10000 }
                    );
                
                //console.log("Error: ", error);
            })
    

        if (resultOK) { 

            setTimeout(() => { 
                this.setState({ 
                    newReviewPopup: false,
                    reviewField: false,
                }) 
            }, 50);

            window.location.reload();
            // this.props.history.push({ pathname: '/' })
        }

    }

    confirmBooking = async () => {

        setTimeout(() => { 
            this.setState({ 
                confirmationPopup: true
            }) 
        }, 50);

    }


    postConfirmation = async (isClient, bookingId) => {

        let resultOK = false

        await this.props.client
            .mutate({
                mutation: confirmBookingMutation,
                variables: {
                    
                    bookingId: bookingId,
                    from: isClient ? 0 : 1,  //0=client, 1=provider
                    confirmationReason: this.state.confirmationReason,
                    
                },
            })
            .then(results => {

                // console.log(results)
                
                if (JSON.stringify(results).length > 15) {

                    resultOK = true

                } else {
                    toast.notify(
                        <div style={{ "color": "red" }}>Error #642. Unable to confirm the booking. Please send us the error number so we could fix it.</div>, 
                        { duration: 10000 }
                        );
                }
                
            })
            .catch(error => {
                
                toast.notify(
                    <div style={{ "color": "red" }}>Error #643. Unable to confirm the booking. Please send us the error number so we could fix it.</div>, 
                    { duration: 10000 }
                    );
                
                //console.log("Error: ", error);
            })
    

        if (resultOK) { 

            setTimeout(() => { 
                this.setState({ 
                    confirmationPopup: false
                }) 
            }, 50);

            window.location.reload();
            // this.props.history.push({ pathname: '/' })
        }

    }



    rejectBooking = async () => {

        setTimeout(() => { 
            this.setState({ 
                rejectionPopup: true
            }) 
        }, 50);

    }

    postRejection = async (isClient, bookingId) => {

        let resultOK = false

        await this.props.client
            .mutate({
                mutation: rejectBookingMutation,
                variables: {
                    
                    bookingId: bookingId,
                    from: isClient ? 0 : 1,  //0=client, 1=provider
                    rejectionReason: this.state.rejectionReason,
                    
                },
            })
            .then(results => {

                // console.log(results)
                
                if (JSON.stringify(results).length > 15) {

                    resultOK = true

                } else {
                    toast.notify(
                        <div style={{ "color": "red" }}>Error #782. Unable to reject the booking. Please send us the error number so we could fix it.</div>, 
                        { duration: 10000 }
                        );
                }
                
            })
            .catch(error => {
                
                toast.notify(
                    <div style={{ "color": "red" }}>Error #781. Unable to reject the booking. Please send us the error number so we could fix it.</div>, 
                    { duration: 10000 }
                    );
                
                //console.log("Error: ", error);
            })
    

        if (resultOK) { 

            setTimeout(() => { 
                this.setState({ 
                    rejectionPopup: false
                }) 
            }, 50);

            window.location.reload();
            // this.props.history.push({ pathname: '/' })
        }

    }



    cancelBooking = async () => {

        setTimeout(() => { 
            this.setState({ 
                cancellationPopup: true
            }) 
        }, 50);

        // console.log('this.state.refundPercentage', this.state.refundPercentage)

    }

    postCancellation = async (isClient, bookingId) => {

        let resultOK = false

        await this.props.client
            .mutate({
                mutation: cancelBookingMutation,
                variables: {
                    
                    bookingId: bookingId,
                    from: isClient ? 0 : 1,  //0=client, 1=provider
                    cancellationReason: this.state.cancellationReason,
                    percentage: this.state.refundPercentage,
                    
                },
            })
            .then(results => {

                // console.log(results)
                
                if (JSON.stringify(results).length > 15) {

                    resultOK = true

                } else {
                    toast.notify(
                        <div style={{ "color": "red" }}>Error #852. Unable to cancel the booking. Please send us the error number so we could fix it.</div>, 
                        { duration: 10000 }
                        );
                }
                
            })
            .catch(error => {
                
                toast.notify(
                    <div style={{ "color": "red" }}>Error #853. Unable to cancel the booking. Please send us the error number so we could fix it.</div>, 
                    { duration: 10000 }
                    );
                
                //console.log("Error: ", error);
            })
    

        if (resultOK) { 

            setTimeout(() => { 
                this.setState({ 
                    cancellationPopup: false
                }) 
            }, 50);

            window.location.reload();
            // this.props.history.push({ pathname: '/' })
        }

    }



    refundBooking = async (isClient) => {

        setTimeout(() => { 
            this.setState({ 
                refundPopup: true
            }) 
        }, 50);

    }

    postRefund = async (isClient, bookingId) => {

        let resultOK = false

        await this.props.client
            .mutate({
                mutation: refundBookingMutation,
                variables: {
                    
                    bookingId: bookingId,
                    from: isClient ? 0 : 1,  //0=client, 1=provider
                    refundReason: this.state.refundReason,
                    
                },
            })
            .then(results => {

                // console.log(results)
                
                if (JSON.stringify(results).length > 15) {

                    resultOK = true

                } else {
                    toast.notify(
                        <div style={{ "color": "red" }}>Error #8523. Unable to refund the booking. Please send us the error number so we could fix it.</div>, 
                        { duration: 10000 }
                        );
                }
                
            })
            .catch(error => {
                
                toast.notify(
                    <div style={{ "color": "red" }}>Error #8513. Unable to refund the booking. Please send us the error number so we could fix it.</div>, 
                    { duration: 10000 }
                    );
                
                //console.log("Error: ", error);
            })
    

        if (resultOK) { 

            setTimeout(() => { 
                this.setState({ 
                    refundPopup: false
                }) 
            }, 50);

            window.location.reload();
            // this.props.history.push({ pathname: '/' })
        }


    }


    // async componentDidMount() {
    // }    


    localeUses24HourTime = (locale) => {

        // if locale === undefined => current user's locale, otherwise uses "locale"

        //returns true, if 24H time is used in this locale/language
        return new Intl.DateTimeFormat(locale, { hour: 'numeric' })
            .formatToParts(new Date(2020, 0, 1, 13))
            .find(part => part.type === 'hour')
            .value.length === 2;

    }

    timeOptions = (use24h) => {

        if (use24h) {
            return [
                { value: '0', code: '00:00', label: '00:00' },
                { value: '1', code: '00:30', label: '00:30' },
                { value: '2', code: '01:00', label: '01:00' },
                { value: '3', code: '01:30', label: '01:30' },
                { value: '4', code: '02:00', label: '02:00' },
                { value: '5', code: '02:30', label: '02:30' },
                { value: '6', code: '03:00', label: '03:00' },
                { value: '7', code: '03:30', label: '03:30' },
                { value: '8', code: '04:00', label: '04:00' },
                { value: '9', code: '04:30', label: '04:30' },
                { value: '10', code: '05:00', label: '05:00' },
                { value: '11', code: '05:30', label: '05:30' },
                { value: '12', code: '06:00', label: '06:00' },
                { value: '13', code: '06:30', label: '06:30' },
                { value: '14', code: '07:00', label: '07:00' },
                { value: '15', code: '07:30', label: '07:30' },
                { value: '16', code: '08:00', label: '08:00' },
                { value: '17', code: '08:30', label: '08:30' },
                { value: '18', code: '09:00', label: '09:00' },
                { value: '19', code: '09:30', label: '09:30' },
                { value: '20', code: '10:00', label: '10:00' },
                { value: '21', code: '10:30', label: '10:30' },
                { value: '22', code: '11:00', label: '11:00' },
                { value: '23', code: '11:30', label: '11:30' },
                { value: '24', code: '12:00', label: '12:00' },
                { value: '25', code: '12:30', label: '12:30' },
                { value: '26', code: '13:00', label: '13:00' },
                { value: '27', code: '13:30', label: '13:30' },
                { value: '28', code: '14:00', label: '14:00' },
                { value: '29', code: '14:30', label: '14:30' },
                { value: '30', code: '15:00', label: '15:00' },
                { value: '31', code: '15:30', label: '15:30' },
                { value: '32', code: '16:00', label: '16:00' },
                { value: '33', code: '16:30', label: '16:30' },
                { value: '34', code: '17:00', label: '17:00' },
                { value: '35', code: '17:30', label: '17:30' },
                { value: '36', code: '18:00', label: '18:00' },
                { value: '37', code: '18:30', label: '18:30' },
                { value: '38', code: '19:00', label: '19:00' },
                { value: '39', code: '19:30', label: '19:30' },
                { value: '40', code: '20:00', label: '20:00' },
                { value: '41', code: '20:30', label: '20:30' },
                { value: '42', code: '21:00', label: '21:00' },
                { value: '43', code: '21:30', label: '21:30' },
                { value: '44', code: '22:00', label: '22:00' },
                { value: '45', code: '22:30', label: '22:30' },
                { value: '46', code: '23:00', label: '23:00' },
                { value: '47', code: '23:30', label: '23:30' },
            ]
        } else {
            return [
                { value: '0', code: '00:00', label: '12:00 AM' },
                { value: '1', code: '00:30', label: '12:30 AM' },
                { value: '2', code: '01:00', label: '01:00 AM' },
                { value: '3', code: '01:30', label: '01:30 AM' },
                { value: '4', code: '02:00', label: '02:00 AM' },
                { value: '5', code: '02:30', label: '02:30 AM' },
                { value: '6', code: '03:00', label: '03:00 AM' },
                { value: '7', code: '03:30', label: '03:30 AM' },
                { value: '8', code: '04:00', label: '04:00 AM' },
                { value: '9', code: '04:30', label: '04:30 AM' },
                { value: '10', code: '05:00', label: '05:00 AM' },
                { value: '11', code: '05:30', label: '05:30 AM' },
                { value: '12', code: '06:00', label: '06:00 AM' },
                { value: '13', code: '06:30', label: '06:30 AM' },
                { value: '14', code: '07:00', label: '07:00 AM' },
                { value: '15', code: '07:30', label: '07:30 AM' },
                { value: '16', code: '08:00', label: '08:00 AM' },
                { value: '17', code: '08:30', label: '08:30 AM' },
                { value: '18', code: '09:00', label: '09:00 AM' },
                { value: '19', code: '09:30', label: '09:30 AM' },
                { value: '20', code: '10:00', label: '10:00 AM' },
                { value: '21', code: '10:30', label: '10:30 AM' },
                { value: '22', code: '11:00', label: '11:00 AM' },
                { value: '23', code: '11:30', label: '11:30 AM' },
                { value: '24', code: '12:00', label: '12:00 PM' },
                { value: '25', code: '12:30', label: '12:30 PM' },
                { value: '26', code: '13:00', label: '01:00 PM' },
                { value: '27', code: '13:30', label: '01:30 PM' },
                { value: '28', code: '14:00', label: '02:00 PM' },
                { value: '29', code: '14:30', label: '02:30 PM' },
                { value: '30', code: '15:00', label: '03:00 PM' },
                { value: '31', code: '15:30', label: '03:30 PM' },
                { value: '32', code: '16:00', label: '04:00 PM' },
                { value: '33', code: '16:30', label: '04:30 PM' },
                { value: '34', code: '17:00', label: '05:00 PM' },
                { value: '35', code: '17:30', label: '05:30 PM' },
                { value: '36', code: '18:00', label: '06:00 PM' },
                { value: '37', code: '18:30', label: '06:30 PM' },
                { value: '38', code: '19:00', label: '07:00 PM' },
                { value: '39', code: '19:30', label: '07:30 PM' },
                { value: '40', code: '20:00', label: '08:00 PM' },
                { value: '41', code: '20:30', label: '08:30 PM' },
                { value: '42', code: '21:00', label: '09:00 PM' },
                { value: '43', code: '21:30', label: '09:30 PM' },
                { value: '44', code: '22:00', label: '10:00 PM' },
                { value: '45', code: '22:30', label: '10:30 PM' },
                { value: '46', code: '23:00', label: '11:00 PM' },
                { value: '47', code: '23:30', label: '11:30 PM' },
            ]
        }
    }


    findCancellationPolicy = (bookingData, isClient, chargedBy) => {
        

        let description = ''
        let cancelAfterWord = '24 hours'

        const now = moment(new Date()); //todays date
        const bookingDate = moment(bookingData.dt/1000*1000)
        // const bookedDate =  moment(bookingData.booked/1000*1000)
        const hoursUntil = bookingDate.diff(now, 'hours')

        // const freeInitialCancellationDate = moment(bookedDate).add(bookingData.cancelAfter, 'd');
        const finalFreeRefundDate = moment(bookingDate).subtract(bookingData.cancelBefore, 'd');
        const finalRefundDateString = moment(finalFreeRefundDate).format('LL');

        this.setState({ refundPercentage: 100 }); //default. If not changed, it will be 100%. If the one who cancels is service provider, 100% must be refunded anyway.
    

        if (bookingData.cancelAfter === 1) { cancelAfterWord = '24 hours' }
        else if (bookingData.cancelAfter === 2) { cancelAfterWord = '2 days' }
        else if (bookingData.cancelAfter === 3) { cancelAfterWord = '3 days' }
        else if (bookingData.cancelAfter === 5) { cancelAfterWord = '5 days' }
        else if (bookingData.cancelAfter === 7) { cancelAfterWord = '7 days' }
        else { cancelAfterWord = '7 days' }

        
        //console.log('now', now.format('LL HH:mm'))
        //console.log('bookingDate', bookingDate.format('LL HH:mm'))
        // console.log('hoursUntil', hoursUntil)
        // console.log('bookingData.noCancellationsAfter', bookingData.noCancellationsAfter)
        // console.log('finalFreeRefundDate', finalFreeRefundDate.format('LL HH:mm'))


      

        if (finalFreeRefundDate < now) {


            if (isClient) {
                description = `As agreed, cancellation was free ${cancelAfterWord} after booking, and at least ${bookingData.cancelBefore} days before the photoshoot date.`
            } else {
                description = `Cancellation according to your cancellation policy was free ${cancelAfterWord} after booking, or at least ${bookingData.cancelBefore} days before the photoshoot date.`
            }

            if (hoursUntil < bookingData.noCancellationsAfter) {

                if (isClient) {
                    description = description + `\n\nSince your photoshoot is scheduled to begin in less than ${bookingData.noCancellationsAfter} hours, if you need to cancel, we can offer a refund of 50% of the total paid amount.`
                    this.setState({ refundPercentage: 50 }); 
                } else {
                    description = description + `\n\nSince the photoshoot is scheduled to begin in less than ${bookingData.noCancellationsAfter} hours, the client is refunded 50% of the total paid amount should they cancel.`
                }

            } else {

                if (isClient) {
                    description = description + `\n\nWe can refund you ${(100-bookingData.lateCancellationKeeps)}% of the total paid amount if you make the cancellation request at least ${bookingData.noCancellationsAfter} hours before your scheduled booking. If you cancel with less than ${bookingData.noCancellationsAfter} hours notice, we can refund you 50% of the total paid amount.` 
                    this.setState({ refundPercentage: 100-bookingData.lateCancellationKeeps }); 
                } else {
                    description = description + `\n\nThe client will get ${(100-bookingData.lateCancellationKeeps)}% refund on cancellation if the cancellation request is made at least ${bookingData.noCancellationsAfter} hours before the scheduled booking. If they cancel with less than ${bookingData.noCancellationsAfter} hours notice, the refund is 50% of the total paid amount.` 
                }

            }


        } else {

            if (isClient) {
                description = `Cancelling your photoshoot is free and you will get full refund if you cancel within ${cancelAfterWord} after booking, or at least ${bookingData.cancelBefore} days before the photoshoot date.`
            } else {
                description = `Cancellation according to your cancellation policy is free for the client if they cancel within ${cancelAfterWord} after booking, or at least ${bookingData.cancelBefore} days before the photoshoot date.`
            }

            if (isClient) {
                description = description + `\n\nIf you request a cancellation after ${finalRefundDateString} but at least ${bookingData.noCancellationsAfter} hours prior to your scheduled booking, we will refund you ${(100-bookingData.lateCancellationKeeps)}% of the total paid amount.\n\nIf you cancel less than ${bookingData.noCancellationsAfter} hours before your scheduled booking, we will refund you 50% of the total paid amount.` 
            } else {
                description = description + `\n\nIf the client requests a cancellation after ${finalRefundDateString} but at least ${bookingData.noCancellationsAfter} hours prior to the scheduled booking, they'll get ${(100-bookingData.lateCancellationKeeps)}% refund of the total paid amount.\n\nIf they cancel less than ${bookingData.noCancellationsAfter} hours before the scheduled booking, they will get 50% refunded of the total paid amount.` 
            }

        }
        
        
        if (isClient) {
            description = description + `\n\nBefore cancelling your booking, we suggest reaching out ${chargedBy} to see if it's possible to reschedule for a different date and time, which would eliminate the need to cancel altogether.`
        } else {
            description = description + `\n\nBefore cancelling your booking, we suggest reaching out the client to see if it's possible to reschedule for a different date and time, which would eliminate the need to cancel altogether.`
        }
       
        
        return description


    }

    

    render() {
      
        moment.locale(i18next.language);
        
        
        let isClient = false;
        if (this.props.bookingData.clientUserId === this.props.myUserId) {
           isClient = true
        }
        
        // isClient = true;

        const bookingId = this.props.bookingData.bookingId;
        const now = moment(new Date()); //todays date
        const bookingDate = moment(this.props.bookingData.dt/1000*1000)
        const bookedDate =  moment(this.props.bookingData.booked/1000*1000)
        const deadline = moment(bookedDate.add(1, 'days')).format('LLL');

        
        let inThePast = false;
        if (bookingDate < now) { 
            inThePast = true; 
        }
        // const daysPast = now.diff(bookingDate, 'days')


        let dateTitle = moment(this.props.bookingData.dt/1000*1000).format('LL'); //#ANOMALY! without /1000*1000 it is giving "invalid date"
        
        let startTime = this.props.bookingData.st
        let endTime = this.props.bookingData.et
        const use24h = this.localeUses24HourTime(); 
        startTime = this.timeOptions(use24h).find(op => { return op.code === this.props.bookingData.st }).label
        endTime = this.timeOptions(use24h).find(op => { return op.code === this.props.bookingData.et }).label
        let dateTitle2 = `${startTime} - ${endTime}`

        if ((this.props.bookingData.provider === 4) && (this.props.bookingData.dt !== this.props.bookingData.dt2)) {
            dateTitle = "From " + moment(this.props.bookingData.dt/1000*1000).format('LL') + " (" + this.props.bookingData.st + ")"; 
            dateTitle2 = "to " + moment(this.props.bookingData.dt2/1000*1000).format('LL') + " (" + this.props.bookingData.et + ")";
        }

        let catName = '';
        for (var i = photogCategories.length-1; i >= 0; i--) {
            if (photogCategories[i].catNumber === this.props.bookingData.catNumber) {
                catName = photogCategories[i].name;
            } 
        };

        const location = this.props.bookingData.location;


        let avatarImage = '';
        let bookingName = '';
        let withWhoLabel = ''
        let chargedBy = '';
        if (isClient) {

            avatarImage = this.props.bookingData.providerAvatar
            if (this.props.bookingData.provider === 0 ) { withWhoLabel = 'Photographer'; chargedBy = 'the photographer'}
            else if (this.props.bookingData.provider === 1 ) { withWhoLabel = 'Model'; chargedBy = 'the model' }
            else if (this.props.bookingData.provider === 2 ) { withWhoLabel = 'Makeup & hair'; chargedBy = 'the makeup/style artist' }
            else if (this.props.bookingData.provider === 3 ) { withWhoLabel = 'Studio'; chargedBy = 'the studio' }
            else if (this.props.bookingData.provider === 4 ) { withWhoLabel = 'Host'; chargedBy = 'the host'}

            bookingName = this.props.bookingData.providerName

        } else {

            withWhoLabel = 'Client';
            avatarImage = this.props.bookingData.clientAvatar
            bookingName = this.props.bookingData.clientFName + ' ' + this.props.bookingData.clientLName 
            chargedBy = 'me'
        }

        //console.log(avatarImage)


        const status = this.props.bookingData.status
        let statusText = '';
        //let statusColor = 1;

        if (status === 1) {

            //statusColor = 1; //green
            statusText = 'This booking is confirmed';
            if (inThePast) { statusText = 'Confirmed booking'; }

        } else if (status === 0) {
            
            if (isClient) {
                //statusColor = 2; //orange'
                statusText = `Your booking will be confirmed or rejected by ${deadline}`;
            } else {
                //statusColor = 2; //orange
                statusText = `Confirmation required. Please confirm or reject this booking by ${deadline}. Otherwise it will be rejected automatically. `;
            }
       
        } else if (status === 3) {
            
            //statusColor = 3; //red
            statusText = 'This booking was rejected';
       
        } else if (status === 4) {
            
            //statusColor = 3; //red
            statusText = 'This booking was cancelled';
       
        } else if (status === 5) {
            
            //statusColor = 3; //red
            statusText = 'This booking was refunded';
       
        } else if (status === 9) {
            
            //statusColor = 3; //red
            statusText = 'Payment failed. Booking is not confirmed';
       
        }



        const booked = moment(this.props.bookingData.booked/1000*1000).format('LLL')
        const confirmed = moment(this.props.bookingData.confirmed/1000*1000).format('LLL')
        const rejected = moment(this.props.bookingData.rejected/1000*1000).format('LLL')
        const canceled = moment(this.props.bookingData.canceled/1000*1000).format('LLL')
        const refunded = moment(this.props.bookingData.refunded/1000*1000).format('LLL')
        
        
        // let packagePriceString = '';
        // let bookingFeeString = '';
        let totalAmountString = '';
        let paidNow = '';
        let paidLater = '';
        
        if (isClient) {

            // packagePriceString = this.props.bookingData.userCurrencyPrice + ' ' + this.props.bookingData.userCurrency;
            // bookingFeeString = this.props.bookingData.userCurrencyBookingFee + ' ' + this.props.bookingData.userCurrency;
            totalAmountString = parseFloat(this.props.bookingData.userCurrencyTotal).toFixed(2) + ' ' + this.props.bookingData.userCurrency;
            paidNow = parseFloat(this.props.bookingData.userCurrencyPaidNow).toFixed(2) + ' ' + this.props.bookingData.userCurrency;
            paidLater = parseFloat(this.props.bookingData.userCurrencyPaidLater).toFixed(2) + ' ' + this.props.bookingData.userCurrency;

        } else {

            // packagePriceString = this.props.bookingData.price + ' ' + this.props.bookingData.currency;
            // bookingFeeString = this.props.bookingData.bookingFee + ' ' + this.props.bookingData.currency;
            totalAmountString = parseFloat(this.props.bookingData.total).toFixed(2) + ' ' + this.props.bookingData.currency;
            paidNow = parseFloat(this.props.bookingData.paidNow).toFixed(2) + ' ' + this.props.bookingData.currency;
            paidLater = parseFloat(this.props.bookingData.paidLater).toFixed(2) + ' ' + this.props.bookingData.currency;

            //commission
            if (this.props.bookingData.paidNow > this.props.bookingData.total) {
                totalAmountString = parseFloat(paidNow).toFixed(2) + ' ' + this.props.bookingData.currency;
            }

            if (this.props.bookingData.paidLater < 0) {
                paidLater = parseFloat(0).toFixed(2) + ' ' + this.props.bookingData.currency;
            }

        }
        
         

        let providerStars = '';
        if (this.props.bookingData.providerRating < 1.5) { providerStars = '⭐☆☆☆☆' }
        if (this.props.bookingData.providerRating >= 1.5) { providerStars = '⭐⭐☆☆☆' }
        if (this.props.bookingData.providerRating >= 2.5) { providerStars = '⭐⭐⭐☆☆' }
        if (this.props.bookingData.providerRating >= 3.5) { providerStars = '⭐⭐⭐⭐☆' }
        if (this.props.bookingData.providerRating >= 4.5) { providerStars = '⭐⭐⭐⭐⭐' }

        let clientStars = '';
        if (this.props.bookingData.clientRating < 1.5) { clientStars = '⭐☆☆☆☆' }
        if (this.props.bookingData.clientRating >= 1.5) { clientStars = '⭐⭐☆☆☆' }
        if (this.props.bookingData.clientRating >= 2.5) { clientStars = '⭐⭐⭐☆☆' }
        if (this.props.bookingData.clientRating >= 3.5) { clientStars = '⭐⭐⭐⭐☆' }
        if (this.props.bookingData.clientRating >= 4.5) { clientStars = '⭐⭐⭐⭐⭐' }


        return (
        
            <ExtraWrapper>


                <Header>
                    <Link to="/">
                        <img src={require("./../../assets/PhotoSouls-logo.svg")} className="Logo" alt="logo" />
                    </Link>


                </Header>
                
                <BookingContainer>
                    
                {/* Title */}
                <ModalTitle>

                    <BackButton onClick={ () => { this.props.hideModal() } } >
                        <FAIcon icon={faChevronLeft} color={'#000'} />
                    </BackButton>

                    <TitleHeadline>
                        Booking details
                    </TitleHeadline>

                </ModalTitle>



                    {/* Status */}
                    <Section>

                        <SectionTitle>
                            
                            <SectionHeadline>
                                {

                                    status === 1 ? 
                                        <StatusText statusColor='1'>
                                            
                                            {statusText}<br/>
                                            <br/>
                                            Booked: {booked}<br/>
                                            Confirmed:  {confirmed}
                                            
                                        </StatusText>
                                    :
                                    status === 2 ? 
                                        <StatusText statusColor='2'>

                                            {statusText}

                                        </StatusText>
                                    :
                                    status === 3 ? 
                                        <StatusText statusColor='3'>

                                            {statusText}<br/>
                                            <br/>
                                            Rejected: {rejected}<br/>
                                            Reason:  {this.props.bookingData.rejectedReason}

                                        </StatusText>
                                    :
                                    status === 4 ? 
                                        <StatusText statusColor='3'>

                                            {statusText}<br/>
                                            <br/>
                                            Canceled: {canceled}<br/>
                                            Reason:  {this.props.bookingData.cancelReason}

                                        </StatusText>
                                    :
                                    status === 5 ? 
                                        <StatusText statusColor='3'>

                                            {statusText}<br/>
                                            <br/>
                                            Refunded: {refunded}<br/>
                                            Reason:  {this.props.bookingData.refundReason}

                                        </StatusText>
                                    :
                                    status === 9 ? 
                                        <StatusText statusColor='3'>

                                            {statusText}<br/>
                                            <br/>
                                            { isClient ? 
                                                <>
                                                    Please e-mail support@photosouls.com so we could generate a new payment link for your booking.
                                                </>
                                                :
                                                <>
                                                    Client has been asked to re-enter their payment information. When they do that, booking will be confirmed automatically and you will be notified by e-mail.
                                                </>
                                            }
                                        </StatusText>
                                    :
                                    <StatusText statusColor='3'>

                                        {statusText}

                                    </StatusText>
                                
                                }


                            </SectionHeadline>

                        </SectionTitle>

                    </Section>


                    {/* Date & location */}
                    <Section>

                        <SectionTitle>
                            
                            <SectionHeadline>
                                {dateTitle}<br/>
                                {dateTitle2}
                            </SectionHeadline>

                        </SectionTitle>

                        { this.props.bookingData.provider === 4 ? 
                            <SectionBody>
                                <br/>
                                Event in {location}
                            </SectionBody>
                            :
                            <SectionBody>
                                <br/>
                                {catName} in {location}
                            </SectionBody>
                        }
                    </Section>


                    {/* With who */}
                    <Section>

                        <SectionTitle>
                            
                            <SectionHeadline>
                                {withWhoLabel}
                            </SectionHeadline>

                        </SectionTitle>

                        <CardDiv>
                            
                            <AvatarArea>
                                { 
                                    avatarImage ? 
                                        <Avatar 
                                            src={avatarImage} 
                                            alt=" " 
                                            onLoad={(e) => {
                                                //e.target.src = values.avatar --- causes huge CPU spike. but looks like not necessary anyway!
                                                e.target.style = 'display: flex' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                            }}
                                            onError={(e) => {
                                                //e.target.src = '' /--- causes huge CPU spike. but looks like not necessary anyway!
                                                e.target.style = 'display: none' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                            }}

                                        /> : <></>
                                }
                            </AvatarArea>

                            <MemberInfoCard>

                                <CardTitle>{bookingName}</CardTitle>
                                
                                {

                                    isClient && this.props.bookingData.providerReviews >= 5 ?
                                        <SubTitle><span role="img" aria-label="rating">{providerStars}</span> ({this.props.bookingData.providerRating}), {this.props.bookingData.providerReviews} reviews</SubTitle>
                                    : isClient && this.props.bookingData.providerReviews < 5 ?
                                        <SubTitle>{TimeAgo(this.props.bookingData.providerJoined)}</SubTitle>
                                    :
                                        null
                                    
                                }

                                {

                                    !isClient && this.props.bookingData.clientReviews >= 5 ?
                                        <SubTitle><span role="img" aria-label="rating">{clientStars}</span> ({this.props.bookingData.clientRating}), {this.props.bookingData.clientReviews} reviews</SubTitle>
                                    : !isClient && this.props.bookingData.clientReviews < 5 ?
                                        <SubTitle>{TimeAgo(this.props.bookingData.clientJoined)}</SubTitle>
                                    :
                                        null

                                }

                                {
                                    isClient && this.props.bookingData.providerIsPro ?
                                        <SubTitle>Pro</SubTitle>
                                        :
                                        null

                                }

                                {
                                    !isClient && this.props.bookingData.clientIsPro ?
                                        <SubTitle>Pro</SubTitle>
                                        :
                                        null

                                }

                                {
                                    isClient ?
                                        <>
                                            <br/> 
                                            <SubTitle><strong>Service provided by:</strong></SubTitle>
                                            <SubTitle>{this.props.bookingData.providerReceiptName}</SubTitle>
                                            <SubTitle>{this.props.bookingData.providerAddress}</SubTitle>
                                            <SubTitle>{this.props.bookingData.providerEmail}</SubTitle>
                                        </>
                                        :
                                        null

                                }
                                
                                    
                            </MemberInfoCard>

                        </CardDiv>

                    </Section>


                    {/* Package / Event description */}
                    <Section>

                        <SectionTitle>
                            
                            <SectionHeadline>
                                {
                                    this.props.bookingData.provider === 4 ?
                                        <>Event</>
                                        :
                                        <>Package</>
                                }
                            </SectionHeadline>

                        </SectionTitle>

                        <SectionBody>

                            <SectionSubTitle>{this.props.bookingData.packageName}</SectionSubTitle>
                            
                            { 
                                this.props.bookingData.provider === 4 ?
                                <>
                                    {
                                        this.props.bookingData.eventDesc.map((eventDescSection, index) => {

                                            return ( 
                                                <div key={index}>
                                                    <br/>
                                                    <SectionSubTitle><strong>{ eventDescSection.title }</strong></SectionSubTitle>
                                                    {
                                                        parse(DOMPurify.sanitize(eventDescSection.desc))                                                                
                                                    }
                                                    <br/>
                                                </div>
                                            )

                                        })
                                    }
                                </>
                                :
                                <>
                                    {this.props.bookingData.packageDesc}
                                </>
                                
                            } 

                            

                        </SectionBody>

                    </Section>


                    {/* Price */}
                    <Section>

                        <SectionTitle>
                            
                            <SectionHeadline>
                            Price & payment
                            </SectionHeadline>

                        </SectionTitle>

                        <SectionBody>
                        
                            <Tbl>
                                <tbody> 
                                <TblRow>
                                    <TblColLeft>
                                        Total:
                                    </TblColLeft>
                                    <TblColRight>
                                        {totalAmountString}
                                    </TblColRight>
                                </TblRow>

                                <TblRow>
                                    <TblColLeft>
                                        Paid (service and booking fee):
                                    </TblColLeft>
                                    <TblColRight>
                                        {paidNow}
                                    </TblColRight>
                                </TblRow>

                                { paidLater > 0 ?
                                    <TblRow>
                                        <TblColLeft>
                                            Paid later (charged by {chargedBy}):
                                        </TblColLeft>
                                        <TblColRight>
                                            {paidLater}
                                        </TblColRight>
                                    </TblRow>
                                    :
                                    null
                                }


                                </tbody>
                            </Tbl>
                        
                        </SectionBody>

                    </Section>


                    


                    {/* Cancellation Policy */}
                    {
                        !inThePast ?
                            <Section>

                                <SectionTitle>
                                    
                                    <SectionHeadline>
                                        Cancellation policy
                                    </SectionHeadline>

                                </SectionTitle>

                                <SectionBody>
                                {
                                    this.findCancellationPolicy(this.props.bookingData, isClient, chargedBy)
                                }
                                </SectionBody>

                            </Section>
                            :
                            null
                    }
                    

                    {/* Message */}
                    {
                        this.props.bookingData.message.length > 0 ?
                        
                            <Section>

                                <SectionTitle>
                                    
                                    <SectionHeadline>
                                        Messages
                                    </SectionHeadline>

                                </SectionTitle>

                                <SectionBody>

                                {
                                    this.props.bookingData.message.map((msg, index) => {

                                        return (

                                            msg.from === 0 ?
                                                <Newline key={index}>
                                                    <MessageHeader>
                                                        { 
                                                            isClient === true ? 
                                                                <>
                                                                    You, on {moment(msg.dt/1000*1000).format('LLL')}
                                                                </>
                                                            :
                                                                <>
                                                                    Client, on {moment(msg.dt/1000*1000).format('LLL')}
                                                                </>

                                                        }
                                                    </MessageHeader>
                                                    <MessageFromClient>
                                                        {msg.message}
                                                    </MessageFromClient>    
                                                </Newline>
                                            :
                                                <Newline key={index}>
                                                    <MessageHeaderRight>
                                                        { 
                                                            isClient === true ? 
                                                                <>
                                                                    Response, on {moment(msg.dt/1000*1000).format('LLL')}
                                                                </>
                                                            :
                                                                <>
                                                                    You, on {moment(msg.dt/1000*1000).format('LLL')}
                                                                </>

                                                        }
                                                    </MessageHeaderRight>
                                                    <MessageFromProvider>
                                                        {msg.message}
                                                    </MessageFromProvider>
                                                </Newline>

                                        )

                                    })

                                }

                                </SectionBody>


                            </Section>
                        :
                        null

                    }

                    {/* Write a message */}
                    { this.state.newMessagePopup ?

                        <div className='react-confirm-alert-overlay'>
                            <div className='react-confirm-alert-body-min80'>

                                <ModalTitle>

                                    <SectionTitle>
                                        <SectionHeadline>
                                            Send a message
                                        </SectionHeadline>
                                    </SectionTitle>

                                </ModalTitle>

                                <ModalTitle>

                                <TextareaStyled
                                    name={'messageField'}
                                    value={this.state.messageField}
                                    onChange={this.fldValueChange}
                                    rows={5}
                                    // cols={5}
                                />
                                </ModalTitle>
                    
                                <Section></Section>

                                <ModalTitle>
                                {/* For padding */}
                                    <ButtonArea>

                                        <BtnBook onClick={ () => { this.postMessage(isClient, bookingId); } }>
                                            Send
                                        </BtnBook>

                                        <BtnSecondary onClick={ () => { setTimeout(() => { this.setState({ newMessagePopup: false }) }, 50); }}>
                                            Cancel
                                        </BtnSecondary>

                                    </ButtonArea>
                                </ModalTitle>

                            </div>
                        </div>
                        :
                        null

                    }



                    {/* Review section */}
                    <Section>

                        <SectionTitle>
                            
                            <SectionHeadline>
                                Review
                            </SectionHeadline>

                        </SectionTitle>

                        <SectionBody>


                            {

                                (this.props.bookingData.review.length === 0) ?
                                    
                                        (status === 0 || status === 1) && (isClient) && (inThePast) ?
                                            <>
                                                
                                                {/* Show button for the client */}

                                                <SectionBody></SectionBody>
                                                <BtnBook onClick={ () => { this.newReview(0, '0'); } }>
                                                    Submit a review
                                                </BtnBook>
                                            </>
                                        :
                                        (status === 1) && (!isClient) && (inThePast) ?
                                            <>
                                                
                                                {/* Show button for the provider */}

                                                <SectionBody></SectionBody>
                                                <BtnBook onClick={ () => { this.newReview(0, '0'); } }>
                                                    Submit a review
                                                </BtnBook>
                                            </>
                                        : (this.props.bookingData.review.length === 0) ?
                                                
                                                /* NOBODY HAS LEFT A REVIEW YET */
                                                <>
                                                    You can submit a review once the photoshoot is over.
                                                </>
                                        :
                                        null
                                        

                                    
                                : (this.props.bookingData.review.length > 0) ?

                                    this.props.bookingData.review.map((review, index) => {

                                        const otherParty = withWhoLabel.toLowerCase()

                                        //isClient ? review.from === 0 : review.from === 1,
                                        if (this.props.bookingData.review.length === 1) {

                                            // ONE PARTY HAS LEFT REVIEW. Detecy WHO, and act accordingly

                                            if ((review.from === 1) && (isClient)) {
                                                
                                                // a review from provider (and text is seen by client)
                                                return (
                                                    <div key={index}>
                                                        {`The ${otherParty} has left a review. To see it, please submit yours.`}
                                                        <br/><br/>
                                                        <BtnBook onClick={ () => { this.newReview(0, '0'); } }>
                                                            Submit a review
                                                        </BtnBook>
                                                    </div> 
                                                )

                                            } else if ((review.from === 1) && (!isClient)) {

                                                // a review from provider (and text is seen by provider)
                                                return (
                                                    <div key={index}>
                                                        {`You have submitted the review. When the ${otherParty} leaves theirs, both reviews will be published.`}
                                                    </div> 
                                                )

                                            } else if ((review.from === 0) && (isClient)) {
                                            
                                                // a review from client (and text is seen by client)
                                                return (
                                                    <div key={index}>
                                                        {`You have submitted the review. When the ${otherParty} leaves theirs, both reviews will be published.`}
                                                    </div> 
                                                )
                                                
                                            } else {

                                                // a review is from client, and is seen by provider

                                                return (
                                                    <div key={index}>
                                                        {`The ${otherParty} has left a review. To see it, please submit yours.`}
                                                        <br/><br/>
                                                        <BtnBook onClick={ () => { this.newReview(0, '0'); } }>
                                                            Submit a review
                                                        </BtnBook>
                                                    </div> 
                                                )

                                            }

                                            

                                        } else {

                                            // BOTH HAVE LEFT REVIEW.

                                            let whoLeft = ''
                                            let myself = false;

                                            // Detect who's review it is, and wether it has response (or if not, show the button 'Respond')
                                            if ((review.from === 1) && (isClient)) {
                                                
                                                // a review from provider (and text is seen by client)
                                                whoLeft = withWhoLabel;

                                            } else if ((review.from === 1) && (!isClient)) {

                                                // a review from provider (and text is seen by provider)
                                                whoLeft = 'You';
                                                myself = true;

                                            } else if ((review.from === 0) && (isClient)) {
                                            
                                                // a review from client (and text is seen by client)
                                                whoLeft = 'You';
                                                myself = true;
                                                
                                            } else {

                                                // a review is from client, and is seen by provider
                                                whoLeft = withWhoLabel;

                                            }

                                            const fiveStarsReview = (review.rating/2);
                                            //console.log(fiveStarsReview)

                                            let numberOfStars = '';
                                            if (fiveStarsReview < 1.5) { numberOfStars = '⭐☆☆☆☆' }
                                            if (fiveStarsReview >= 1.5) { numberOfStars = '⭐⭐☆☆☆' }
                                            if (fiveStarsReview >= 2.5) { numberOfStars = '⭐⭐⭐☆☆' }
                                            if (fiveStarsReview >= 3.5) { numberOfStars = '⭐⭐⭐⭐☆' }
                                            if (fiveStarsReview >= 4.5) { numberOfStars = '⭐⭐⭐⭐⭐' }

                                            
                                            return (
                                                
                                                <div key={index}>

                                                    <br/>

                                                    <Newline>
                                                        <MessageHeader>
                                                            <>
                                                                {whoLeft}, on {moment(review.dt/1000*1000).format('LLL')}. 
                                                            </>
                                                        </MessageHeader>
                                                        <MessageFromClient>
                                                            {review.message}
                                                        </MessageFromClient>    
                                                        {numberOfStars} ({fiveStarsReview} out of 5)
                                                    </Newline>
                                                

                                                    {

                                                        review.response.length > 1 ?

                                                            <Newline>

                                                                <MessageHeaderRight>
                                                                    Response:
                                                                </MessageHeaderRight>
                                                                <MessageFromProvider>
                                                                    {review.response}
                                                                </MessageFromProvider> 
                                                                
                                                            </Newline>
                                                            :
                                                            myself ? 
                                                                null 
                                                                :
                                                                <RespondBtn onClick={ () => { this.newReview(1, review.id); } }>
                                                                    Respond
                                                                </RespondBtn>
                        
                                                    }
                                                    
                                                </div>

                                            )

                                        }

                                    })

                                :
                                    //this will never be reached
                                    null 

                            }

                            

                        </SectionBody>

                    </Section>
                        

                    <Section> <Separator/> </Section>


                    {/* { this.state.errorMsg ? ( <Section><IncreaseHeight/><MyError message = {this.state.errorMsg} /></Section>) : null } */}
                     



                    {/* Write a review - modal popup */}
                    { this.state.newReviewPopup ?

                        <div className='react-confirm-alert-overlay'>
                            <div className='react-confirm-alert-body-min80'>

                                <ModalTitle>

                                    <SectionTitle>
                                        
                                            {
                                                this.state.newReviewType === 0 ?
                                                    <SectionHeadline>
                                                        Submit a review
                                                    </SectionHeadline>
                                                    :
                                                    <SectionHeadline>
                                                        Respond to the review
                                                    </SectionHeadline>
                                                    

                                            }
                                        
                                    </SectionTitle>


                                    { this.state.newReviewType === 0 ? 
                                        <>

                                            <SectionTitle>
                                                <SectionBody>
                                                    {/* Based on your experience, how likely are you to recommend {bookingName} to a friend or colleague? */}
                                                    How would you rate your photoshoot experience with {bookingName}?
                                                </SectionBody>
                                            </SectionTitle>

                                            <SectionTitle>
                                        
                                                <StarRating 
                                                    ratingSet={this.state.ratingSet}
                                                    onRatingSet={
                                                        (rating) => {
                                                            setTimeout(() => { this.setState({ ratingSet: rating }) }, 50);
                                                        }
                                                    }
                                                />

                                            </SectionTitle>

                                            <SectionTitle>
                                                Your rating will be published in 5-star system, as {this.state.ratingSet/2} stars out of 5.
                                            </SectionTitle>


                                            <SectionTitle>
                                                What made you to give this rating?
                                            </SectionTitle>
                                        

                                        </>
                                    :
                                        <>

                                            <SectionTitle>
                                                <SectionBody>
                                                    What would you like to say to the reviewer?
                                                </SectionBody>
                                            </SectionTitle>

                                        </>
                                    }
                                    
                                
                                    
                                    
                                    <TextareaStyled
                                        name={'reviewField'}
                                        value={this.state.reviewField}
                                        onChange={this.fldValueChange}
                                        rows={5}
                                        // cols={5}
                                    />
                                    

                                    { this.state.newReviewType === 0 ? 
                                        <>
                                        <Section></Section>
                                        
                                        <SectionTitle>
                                            Your review will be published after the other party has also submitted their review.
                                        </SectionTitle>
                                        </>
                                    :
                                        <></>
                                    }


                                </ModalTitle>

                                <Section></Section>


                                <ModalTitle>
                                 
                                    <ButtonArea>

                                        <BtnBook onClick={ () => { this.postReview(isClient, bookingId); } }>
                                            Submit
                                        </BtnBook>

                                        <BtnSecondary onClick={ () => { setTimeout(() => { this.setState({ newReviewPopup: false }) }, 50); }}>
                                            Cancel
                                        </BtnSecondary>

                                    </ButtonArea>
                                </ModalTitle>

                            </div>
                        </div>
                        :
                        null

                    }


                    {/* CONFIRM:  */}
                    { this.state.confirmationPopup ?

                        <div className='react-confirm-alert-overlay'>
                            <div className='react-confirm-alert-body-min80'>

                                <ModalTitle>

                                    <SectionTitle>
                                        <SectionHeadline>
                                            If you would you like to send a message along with the booking confirmation, enter it below
                                        </SectionHeadline>
                                    </SectionTitle>

                                </ModalTitle>
                                <br/>

                                <ModalTitle>

                                <TextareaStyled
                                    name={'confirmationReason'}
                                    value={this.state.confirmationReason}
                                    onChange={this.fldValueChange}
                                    rows={5}
                                    // cols={5}
                                />
                                </ModalTitle>

                                
                                <Section></Section>
                                    
                                <ModalTitle>
                                {/* For padding */}
                                    <ButtonArea>

                                        <BtnBook onClick={ () => { this.postConfirmation(isClient, bookingId); }}>
                                            Confirm the booking
                                        </BtnBook>

                                        <BtnSecondary onClick={ () => { setTimeout(() => { this.setState({ confirmationPopup: false }) }, 50); }}>
                                            Cancel the confirmation process
                                        </BtnSecondary>

                                    </ButtonArea>
                                </ModalTitle>

                            </div>
                        </div>
                        :
                        null

                    }



                    {/* REJECT:  */}
                    { this.state.rejectionPopup ?

                        <div className='react-confirm-alert-overlay'>
                            <div className='react-confirm-alert-body-min80'>

                                <ModalTitle>

                                    <SectionTitle>
                                        <SectionHeadline>
                                            You're about to reject the booking request. Please enter why
                                        </SectionHeadline>
                                    </SectionTitle>

                                </ModalTitle>
                                <br/>

                                <ModalTitle>

                                <TextareaStyled
                                    name={'rejectionReason'}
                                    value={this.state.rejectionReason}
                                    onChange={this.fldValueChange}
                                    rows={5}
                                    // cols={5}
                                />
                                </ModalTitle>

                                
                                <Section></Section>
                                    
                                    { this.state.errorMsg ? ( <ToRight16><MyError message = {this.state.errorMsg} /><Section></Section></ToRight16>) : null }
                                
                                <ModalTitle>
                                {/* For padding */}
                                    <ButtonArea>

                                        <BtnBook onClick={ () => { 
                                                if (this.state.rejectionReason === '') {
                                                    setTimeout(() => { this.setState({ errorMsg: "Please enter the reason why you would like to reject the booking" }) }, 50);
                                                } else {
                                                    this.postRejection(isClient, bookingId); 
                                                }
                                            }}>
                                            Reject the booking
                                        </BtnBook>

                                        <BtnSecondary onClick={ () => { setTimeout(() => { this.setState({ rejectionPopup: false }) }, 50); }}>
                                            Cancel the rejection process
                                        </BtnSecondary>

                                    </ButtonArea>
                                </ModalTitle>

                            </div>
                        </div>
                        :
                        null

                    }

    
                    {/* Cancellation confirmation */}
                    { this.state.cancellationPopup ?

                        <div className='react-confirm-alert-overlay'>
                            <div className='react-confirm-alert-body-min80'>

                                <ModalTitle>

                                    <SectionTitle>
                                        <SectionHeadline>
                                            To cancel this booking, please enter the reason why
                                        </SectionHeadline>
                                    </SectionTitle>

                                </ModalTitle>
                                <br/>

                                <ModalTitle>

                                <TextareaStyled
                                    name={'cancellationReason'}
                                    value={this.state.cancellationReason}
                                    onChange={this.fldValueChange}
                                    rows={5}
                                    // cols={5}
                                />
                                </ModalTitle>

                                
                                <Section></Section>
                                    
                                    { this.state.errorMsg ? ( <ToRight16><MyError message = {this.state.errorMsg} /><Section></Section></ToRight16>) : null }
                                
                                <ModalTitle>
                                {/* For padding */}
                                    <ButtonArea>

                                        <BtnBook onClick={ () => { 
                                                if (this.state.cancellationReason === '') {
                                                    setTimeout(() => { this.setState({ errorMsg: "Please enter the reason why you would like to cancel the booking" }) }, 50);
                                                } else {
                                                    this.postCancellation(isClient, bookingId); 
                                                }
                                            }}>
                                            Cancel the booking
                                        </BtnBook>

                                        <BtnSecondary onClick={ () => { setTimeout(() => { this.setState({ cancellationPopup: false }) }, 50); }}>
                                            Let's keep this booking
                                        </BtnSecondary>

                                    </ButtonArea>
                                </ModalTitle>

                            </div>
                        </div>
                        :
                        null

                    }


                    {/* Refund confirmation */}
                    { this.state.refundPopup ?

                        <div className='react-confirm-alert-overlay'>
                            <div className='react-confirm-alert-body-min80'>

                                <ModalTitle>

                                    <SectionTitle>
                                        <SectionHeadline>
                                            Please enter the reason for the refund. This will be sent to the client. Also, please note that 100% of the paid amount will be returned to the client.
                                        </SectionHeadline>
                                    </SectionTitle>

                                </ModalTitle>
                                <br/>

                                <ModalTitle>

                                <TextareaStyled
                                    name={'refundReason'}
                                    value={this.state.refundReason}
                                    onChange={this.fldValueChange}
                                    rows={5}
                                    // cols={5}
                                />
                                </ModalTitle>

                                
                                <Section></Section>
                                    
                                    { this.state.errorMsg ? ( <ToRight16><MyError message = {this.state.errorMsg} /><Section></Section></ToRight16>) : null }
                                
                                <ModalTitle>
                                {/* For padding */}
                                    <ButtonArea>

                                        <BtnBook onClick={ () => { 
                                                if (this.state.refundReason === '') {
                                                    setTimeout(() => { this.setState({ errorMsg: "Please enter the reason for the refund" }) }, 50);
                                                } else {
                                                    this.postRefund(isClient, bookingId); 
                                                }
                                            }}>
                                            Refund
                                        </BtnBook>

                                        <BtnSecondary onClick={ () => { setTimeout(() => { this.setState({ refundPopup: false }) }, 50); }}>
                                            Cancel the refund process
                                        </BtnSecondary>

                                    </ButtonArea>
                                </ModalTitle>

                            </div>
                        </div>
                        :
                        null

                    }


                    
                    {/* Book button */}
                    {/* 0=awaiting      1=confirmed      2=delivered    3=rejected      4=cancelled      5=refunded    6=chargeback  9=payment_failed_on_confirmation*/}
                    <Section>
                        <ButtonArea>


                            {
                                (status === 0) && (!isClient) ?
                                    <>
                                        <BtnBook onClick={ () => { this.confirmBooking(); } }>
                                            Confirm this booking
                                        </BtnBook>

                                        <BtnSecondary onClick={ () => { this.newMessage(); } }>
                                            Message
                                        </BtnSecondary>

                                        <BtnSecondary onClick={ () => { this.rejectBooking(); } }>
                                            Reject this booking
                                        </BtnSecondary>
                                    </>
                                    :
                                    null
                            }

                            {
                                (status === 1) && (!isClient) && (!inThePast) ?
                                    <>

                                        <BtnBook onClick={ () => { this.newMessage(); } }>
                                            Message
                                        </BtnBook>

                                        <BtnSecondary onClick={ () => { this.cancelBooking(); } }>
                                            Cancel this booking
                                        </BtnSecondary>
                                    </>
                                    :
                                    null
                            }

                            {
                                (status === 9) && (!isClient) && (!inThePast) ?
                                    <>

                                        <BtnBook onClick={ () => { this.newMessage(); } }>
                                            Message
                                        </BtnBook>

                                        <BtnSecondary onClick={ () => { this.cancelBooking(); } }>
                                            Cancel this booking
                                        </BtnSecondary>
                                    </>
                                    :
                                    null
                            }

                            {
                                (status === 1) && (!isClient) && (inThePast) ?
                                    <>

                                        <BtnBook onClick={ () => { this.newMessage(); } }>
                                            Message
                                        </BtnBook>


                                        <BtnSecondary onClick={ () => { this.refundBooking(); } }>
                                            Refund this booking
                                        </BtnSecondary>

                                        {/* 

                                        //#TODO: client requested refunds are not yet covered....


                                        ???? 
                                        At first, let's not show this. Let the parties agree on it. Possibility for the provider to do partial refunds... 
                                        If not agreement is found, have a help section that will say they can turn to us for a resolution.

                                        .... then again... it makes things much more complicated. What if provider ghosted, or even the shoot happened but photos not delivered? 
                                        For this refund process (verified manually and asking proof or rejection from provider,
                                        we are middleman. Like a judge. This refund is not automatic. Only provider can make automatic refunds.)

                                        On the future, we can add "Delivery required in 30 days, for example". Ja pildid saab laadida siia portaali näiteks. 
                                        Kui pildid on olemas, refundi nõuda ei saa. kui pilte pole, saab nõuda refundi.


                                        AirBnb: 
                                            Resolution Centeris on kaks nuppu: 
                                                "Request Money" ja "Send Money":
                                                        
                                                        You don’t have any eligible reservations to request money from right now.
                                                        Requests must be made within 60 days after your reservation's checkout date.

                                                        You don’t have any eligible reservations to send money from right now.
                                                        Requests must be made within 60 days after your reservation's checkout date.

                                        */}

                                    </>
                                    :
                                    null
                            }
                            
                            {
                                (status === 0 || status === 1) && (isClient) && (!inThePast) ?
                                    <>
                                        
                                        <BtnBook onClick={ () => { this.newMessage(); } }>
                                            Message
                                        </BtnBook>

                                        <BtnSecondary onClick={ () => { this.cancelBooking(isClient); } }>
                                            Cancel this booking
                                        </BtnSecondary>
                                    </>
                                    :
                                    null

                            }
                            
                            {
                                (status === 0 || status === 1) && (isClient) && (inThePast) ?
                                    <>
                                        
                                        
                                        <BtnSecondary onClick={ () => { this.newMessage(); } }>
                                            Message
                                        </BtnSecondary>

                                    </>
                                    :
                                    null

                            }


                        </ButtonArea>
                    </Section>


                    {/* Back to bookings */}
                    <ModalTitle>
                        <BackButton onClick={ () => { this.props.hideModal() } } >
                            <FAIcon icon={faChevronLeft} color={'#000'} />
                        </BackButton>
                        <TitleHeadline>
                            Back to bookings
                        </TitleHeadline>
                    </ModalTitle>


                    <Section><IncreaseHeight32/><IncreaseHeight32/></Section>


                </BookingContainer>

                 

            </ExtraWrapper>
        
        );

    }


}

const personalInfoFields = [
    'fName', 'lName', 'bDay', 'email', 'phone', 'phoneDisplay', 'phoneCountry', 'avatar', 'isVerified', 'isPro', 'temperror', 'pwdUpdated',
    'chkReminders', 'chkRemindersP', 'chkMessages', 'chkMessagesP', 'chkRecomm', 'chkRecommP', 'chkInsp', 'chkInspP', 'chkLocal', 'chkLocalP', 
    'chkHow', 'chkHowP', 'chkInvite', 'chkInviteP', 'chkSurvey', 'chkSurveyP', 'chkPolicy', 'chkPolicyP', 'chkTips', 'chkTipsP', 'currency'
]


export const BookingDetails = withRouter(withStore(withApollo(C), personalInfoFields));


const ExtraWrapper = styled.div`


     width: calc(100% - 32px);

`;

const BookingContainer = styled.div`

    width: 100%;
    max-width: 760px;
    
    margin: auto;
    margin-top: 16px;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    
    align-items: center;
    justify-content: center;

    /* background-color: #ded; */

    z-index: 200;


`;

const Header = styled.div` 
    position: relative;
    top: 0; 
    max-height: 72px;
    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    background-color: #fdfdfd;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

`;

const BackButton = styled(Button)`
    && {
        
    /* margin: -4px -4px 0 0px; */

    height: 48px;
    min-width: 48px;
    max-width: 48px;
    border-radius: 24px;

    margin-right: 16px;
    /* margin-right: 4px;
    margin-left: 4px; */

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;

    
    font-weight: 400;
    font-size: 19px;

    background-color: #EBEBEB;
    opacity: 0.6;

    &:hover {
        background-color: #EBEBEB;
        opacity: 0.6;
        }
    
    &:disabled {
        background-color: #fff;
        color: #fff;
        }

    }
    
`;

const FAIcon = styled(FontAwesomeIcon)`
    && {
    font-size: 22px;
    font-weight: 400;
    }
`;


const ModalTitle = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: flex-start;
    justify-content: flex-start;

    /* background-color: #ebe; */

    width: 100%;

    margin-left: 20px;
    /* margin-top: -4px; */
    /* padding-left: 16px; */

    @media (max-width: 970px) {
        flex-direction: column;
    }



`;

const TitleHeadline = styled.div`
    
    padding: 12px 24px 24px 16px;
    padding-left: 4px;

    font-family: 'Fira Sans';
    font-size: 24px;
    font-weight: 500;

    color: #333333;

`;

const Section = styled.div`
    
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    margin-left: 16px;
    margin-right: 24px;
    width: calc(100% - 40px);

    align-items: flex-start;
    justify-content: flex-start;

    /* background-color: #fed;  */

    margin-bottom: 32px;

`;



const SectionTitle = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: center;
    justify-content: space-between;

    width: 100%;
    min-height: 40px;
/* 
    font-family: 'Fira Sans';
    font-weight: 300;
    font-size: 18px; */


    /* background-color: #ebe; */

`;

const ButtonArea = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: left;
    justify-content: flex-start;
    
    width: 100%;
    min-height: 40px;

    /* background-color: #ebe; */

`;




const SectionHeadline = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 500;
    font-size: 20px;

    /* color: #505050; */
    /* color: #383838; */
    color: #333333;

    /* background-color: #ded; */

`;




const SectionBody = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 300;
    font-size: 18px;

    line-height: 24px;

    color: ${(props) => props.error ? 'rgba(255, 0, 0, 0.9)' : '#505050' };

    white-space: pre-wrap;

    /* width: calc(100% - 112px); */
    width: 100%;
    

`;



const Separator = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: center;
    justify-content: space-between;

    width: 100%;
    
    height: 1px;
    background-color: #cdcdcd;


`;




const CardDiv = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;    

    padding-right: 32px;
    margin-top: 4px;

`;

const AvatarArea = styled.div`
    
    width: 64px; 
    margin: auto;

    margin-top: 0;

    
    /* float: left */

`;

const Avatar = styled.img`

    height: 64px;
    width: 64px;
    border-radius: 64px;
    
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.4);

`;

const MemberInfoCard = styled.div`
    
    padding-left: 16px;
    width: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
        
`;

const CardTitle = styled.div`
    
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 2px;
    /* @media (max-width: 790px) {
        display: none;
        } */

`;

const SubTitle = styled.div`
    
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5em;
    
    /* @media (max-width: 790px) {
        display: none;
        }
     */

`;


const StatusText = styled.div`
    
    display: absolute;
    position: relative;
    text-align: left;
    float: left; 

    margin-right: 0;
    width: 100%;

    padding: 16px;
    background-color: ${(props) => props.statusColor === '1' ? '#4c8c17' : props.statusColor === '2' ? '#FF6A6A' : '#FF6A6A' };
    border-radius: 4px;

    color: #fff;
    font-family: 'Fira Sans';
    font-size: 16px;
    font-weight: 400;
    
    white-space: pre-wrap;

    z-index: 4;

    &:hover {
        /* cursor: pointer; */
    }

`;

const SectionSubTitle = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 18px;

    /* color: #505050; */
    /* color: #383838; */
    color: #333333;

    /* background-color: #ded; */

    margin-bottom: 24px;
`;

const BtnBook = styled(Button)`
    && {
    min-height: 40px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    color: white;
    
    padding-left: 16px;
    padding-right: 16px;

    margin-right: 16px;
    margin-bottom: 16px;

    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    
    &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }

}
`;



const BtnSecondary = styled(Button)`
    && {
    
    display: flex;

    min-height: 40px;
    
    padding-left: 16px;
    padding-right: 16px;

    margin-right: 16px;
    margin-bottom: 16px;

    text-transform: none;
    text-decoration: underline;
    text-underline-offset: 2px;

    font-weight: 400;
    font-size: 17px;
    color: #333333;
    
    &:hover {
        opacity: 0.9;
        box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};
        text-decoration: underline;
        }
    }
    
    
`;


const ToRight16 = styled.div`
    
    margin-left: 16px;
    width: 100%;

`;


const Newline = styled.div`
    
    width: 100%;


`;




const MessageFromClient = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 18px;

    color: #333333;

    width: 80%;
    margin-bottom: 16px;

    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;

    border-radius: 4px;
    background-color: #f2f2f2;

`;



const MessageFromProvider = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 18px;

    color: #333333;

    width: 80%;
    margin-bottom: 16px;

    float: right;

    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;

    border-radius: 4px;
    background-color: #e5e5e5;

    text-align: right;
`;

const RespondBtn = styled(Button)`
    && {
    min-height: 40px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    color: white;

    float: right;
    
    padding-left: 16px;
    padding-right: 16px;

    margin-right: 0px;
    margin-bottom: 16px;

    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    
    &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }

}
`;

const MessageHeader = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 16px;

    color: #bdbdbd;

    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: flex-start;
    justify-content: flex-start;
    
`;

const MessageHeaderRight = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 16px;

    color: #bdbdbd;

    width: 100%;
    text-align: right;
    
`;

const TextareaStyled = styled.textarea`
    
    width: calc(100% - 32px);

    padding: 0px;
    margin: 0px;
    /* height: 24px; */
    padding: 8px;
    
    font-size: 18px;
    font-family: 'Fira Sans';
    font-weight: 300;

    color: #333333;
    
    border: 1px solid #cbcbcb;
    border-radius: 4px;  
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);    

    :focus {
        outline-color: #4C9AFF;
    }

    :disabled {
        background-color: #f7f7f7; 
    }

    @media (max-width: 690px) {
        font-size: 18px;
    }
`;




const Tbl = styled.table`
    
    width: 100%;

    border-left: 2px solid #cbcbcb;
    padding-left: 4px;

`;

const TblRow = styled.tr`
    
    padding-bottom: 8px;

`;

const TblColLeft = styled.td`
    
    /* background-color: #dede; */
    /* width: 70%; */
    float: left;

`;

const TblColRight = styled.td`
    
    text-align: right;

    /* background-color: #feb; */
    width: 35%;

`;

const IncreaseHeight32 = styled.div`
    
    display: absolute;
    position: relative;
    text-align: left;
    float: left; 

    width: 200px;
    height: 32px;  
        
`;