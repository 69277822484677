
import React, { PureComponent } from 'react'
import { Form as AntForm } from "antd";
import { withFormik, Field, Form } from "formik";
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';


import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Helmet } from "react-helmet";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'


import styled from 'styled-components';
import "./LoginView.css";

import { loginSchema } from "@psd/common";
// import { msg_invalidLogin } from "@psd/common";

import { MyInputField } from "../../components/MyInputField";
import { MyH1 } from "../../components/MyH1";
import { MyH3 } from "../../components/MyH3";
import { MyError } from "../../components/MyError";
import { MyButton } from "../../components/MyButton";
import { Divider } from "../../components/Divider";

import { LoginFacebookConnector } from '../loginFacebook/LoginFacebookConnector';

import ReactGA from 'react-ga4';
// import { logEvent, setUserId } from '@psd/web/src/utils/analytics/amplitude';

const FormItem = AntForm.Item;



class C extends PureComponent {
  
    state = {
        readyToMutate: false,
        googleLoginStart: false,
        thirdparty: "email", //default
        userID: "",
        name: "",
        accessToken: "",
    };


    componentDidMount() {

        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

        let GOOGLE_CLIENT_ID = '';
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            // console.log('development')
            GOOGLE_CLIENT_ID = '377900433114-1ppl7emq8t86dpk6iq0a7a0s4qkr9sr0.apps.googleusercontent.com';
        } else {
            // console.log('production')
            GOOGLE_CLIENT_ID = '377900433114-ehqng9q16qr293jpcp38o7sg6n39qd9m.apps.googleusercontent.com'
        }

        // console.log(window.location.host)


        if ((localStorage.getItem('refProfile') === null) 
        || (localStorage.getItem('refProfile') === 'null') 
        || (localStorage.getItem('refProfile') === '') 
        || (localStorage.getItem('refProfile') === 'undefined')) { 
            localStorage.setItem('refProfile', '-');
        } else {
            //refProfile was already set in LocalStorage
        }

        /* global google */
        google.accounts.id.initialize({
            client_id: GOOGLE_CLIENT_ID,
            callback: this.handleGoogle,
        });

        google.accounts.id.renderButton(document.getElementById("signUpDiv"), {
            // type: "standard",
            theme: "filled_blue",
            size: "large",
            text: "continue_with", //
            shape: "rectangular",
            width: 300,
            logo_alignment: "left",
        });

    }


    responseFacebook = async (response) => {

        this.setState({
            readyToMutate: true,
            userID: response.userID,
            email: response.email,
            accessToken: response.accessToken,
            thirdparty: "Facebook"
        });

    };


    handleGoogle = async (response) => {
        
        // console.log(response)

        //send to backend
        this.setState({
            googleLoginStart: true,
            userID: "response.userID,",
            email: "response.email,",
            token: response.credential,
            thirdparty: "Google",
        });

    };



    render() {

        const { loadingVisible } = this.props.state;
    
        let loginFacebookButton;

        if (this.state.readyToMutate) {

            loginFacebookButton = (

                <>

                    <StyledFacebook disabled>
                        <LocalImage src={require('./../../../assets/whiteLoader.gif')} />
                    </StyledFacebook>
                    
                    <LoginFacebookConnector
                        thirdparty='Facebook'
                        email={this.state.email}
                        token={this.state.accessToken}
                        userId={this.state.userID}
                        fName=''
                        lName=''
                        referralId={localStorage.getItem('refProfile')}
                        onFinish={this.props.onFinish}
                    />

                </>

            );
            
        } else {

            loginFacebookButton = (

                <FacebookLogin
                    appId="331812170862368"
                    fields="name,email"
                    //onClick={this.componentClicked}
                    callback={this.responseFacebook}
                    render={renderProps => (<StyledFacebook onClick={renderProps.onClick}><MyIcon icon={faFacebookSquare}/>Continue with Facebook</StyledFacebook>)}

                    autoLoad={false}
                    disableMobileRedirect={true}
                    
                />

            );
            
        }


        
    return (

        
        <div className="LoginWrapper">

            <Helmet>
                <title>PhotoSouls - Log in</title>
                <meta name="description" content="Access your PhotoSouls account to join our vibrant photography community, manage your portfolio, engage with fellow photographers." />
                <meta name="keywords" content="photosouls,photographers,models,workshops" />
            </Helmet>

            <div className="LoginLeftSide">
                <div className="LoginHero1">
                    <Carousel autoPlay swipeable infiniteLoop showThumbs={false} showStatus={false}
                        interval={7000} transitionTime={700}>
                        
                        <div className="LoginHero1">
                            <div className="LoginTestimonial">"I traveled to France a few months ago and was eager to have a photoshoot there. I found Adrien, and I couldn't be happier with both the photos and the session experience."</div>
                            <div className="LoginAuthor">Emily</div>
                            <img src="" alt=""/>
                        </div>
                        <div className="LoginHero2">
                            <div className="LoginTestimonial">"I can't thank you guys enough! The number of new clients I have found here is amazing!"</div>
                            <div className="LoginAuthor">Beata</div>
                            <img src="" alt=""/>
                        </div>

                        <div className="LoginHero3">
                            <div className="LoginTestimonial">"I was surprised that even landscape photographers could find something here. The last-minute photo tour to Scotland was something to remember for the rest of my life! Thanks!"</div>
                            <div className="LoginAuthor">Roman</div>
                            <img src="" alt=""/>
                        </div>
                        
                    </Carousel> 
                </div>
            </div>



            <div className="LoginRightSide">
                
                <div className="LoginRightSideWrapper">


                    <MyH1 headline="Log in" />
                    <MyH1 headline="-" />
                    

                    <Form>
                        {loginFacebookButton}
                    </Form>
                                
                    <div id="signUpDiv" data-text="signup_with"></div>
                    {
                        this.state.googleLoginStart ?
                            <LoginFacebookConnector
                                thirdparty='Google'
                                email={this.state.email}
                                token={this.state.token}
                                userId={this.state.userID}
                                currency={localStorage.getItem('ipCurrency')}
                                city={localStorage.getItem('ipCity')}
                                country={localStorage.getItem('ipCountry')}
                                fName=''
                                lName=''
                                referralId={localStorage.getItem('refProfile')}
                                onFinish={this.props.onFinish}
                            />
                            :
                            null
                    }
                    
                    <IncreaseHeight16 />

                    <MyH3 headline="- or -" />

                    <Form>
                        
                        <Field
                            name="email"
                            label="Email"
                            component={MyInputField}
                        />

                        <Divider />

                        <ForgotPasswordDiv>
                            <StyledLink to="/user/forgot-password" tabIndex="-1">Forgot?</StyledLink>
                        </ForgotPasswordDiv>

                        <Field
                            name="pwd"
                            label="Password"
                            type="password"
                            component={MyInputField}
                        />

                    

                        {
                            this.props.errors.eMessage ? (
                                <MyError
                                    message={this.props.errors.eMessage}
                                    />
                                ): null
                        }


                        <Divider />

                        <FormItem>
                            
                            <MyButton 
                                type='submit'
                                caption='Log in'
                                loading={loadingVisible}
                                />

                        </FormItem>
                        

                        
                    </Form>
                    

                    <Fineprint>
                        By loggin in or signing up, I agree to PhotoSouls.com's <StyledLink to="/terms/terms-of-service">Terms of Service</StyledLink> and <StyledLink to="/terms/privacy-policy">Privacy Policy</StyledLink>.
                    </Fineprint>

                    
                    
                    {/* <StyledLink to="/user/logout">Logout</StyledLink> */}

                </div>

            </div>

        </div>
    )
  }
}



export const LoginView = withFormik({
    
    validationSchema: loginSchema,
    validateOnChange: false,
    
    mapPropsToValues: () => ({ email: "", pwd: "", referralId: ""}),
    
    handleSubmit: async (values, { props, setErrors }) => {
        
        props.updateLoader(true);

        // console.log('props', props)
        // console.log('values', values)

        try {
        
            // console.log('#1 ')

            // NB !!!   NB !!!   NB !!!   NB !!!   NB !!!   NB !!!    STATE IS NOT AVAILABLE HERE 
            // console.log('state', this.state.thirdparty)
            // if ((this.state.thirdparty === 'Facebook') || (this.state.thirdparty === 'Google')) {
            //     console.log('#2 ')
            //     values.thirdparty = this.state.thirdparty; //'Facebook'
            // }

            // console.log('#3 ')
            values.email = values.email.toLowerCase();
            values.referralId = localStorage.getItem('refProfile')

            // console.log('before apiresponse  ')

            const apiResponse = await props.submit(values);
            
            // console.log('##### apiResponse', apiResponse)

            //setTimeout(() => {

                props.updateLoader(false);

                
                if (apiResponse.responseType === "data") {     

                    if (apiResponse.data.login.sessionId) {     

                        // logEvent('login', {'type': 'email'})
                        // setUserId(apiResponse.data.login.userId)

                        props.onFinish(apiResponse.data.login.userId);
    
                    } else {
                        
                        setErrors({ eMessage: "Those credentials don’t look right. Please try again, or sign up for a new account.", });
    
                    }

                } else if (apiResponse.responseType === "error") {
                    
                    setErrors({ eMessage: apiResponse.message, });

                } else {

                    setErrors({ eMessage: "Those credentials don’t look right. Please try again, or sign up for a new account.", });

                }
                
                
            //}, 4000 )
            

        } catch (e) {
                           
            props.updateLoader(false);
            
            
            // if (e.graphQLErrors[0].message === "APIErorr") {

            //     setErrors({ eMessage: e.graphQLErrors[0].data[0].message, });

            // } else {

                setErrors({ eMessage: "Unable to connect to PhotoSouls. Please try again later", });

            // }


        }


    }

})(C);
  


const IncreaseHeight16 = styled.div`
    height: 16px;
    min-height: 16px;
    width: 100%;
`;


const ForgotPasswordDiv = styled.div`
    position: absolute;
    right: 24px;
    font-size: 16px;
    @media (max-width: 400px) {
            right: 0;
        }
`;
const Fineprint = styled.div`
    margin-top: 16px;
    font-size: 12px;
    color: #333333;
`;
const StyledLink = styled(Link)`
    text-decoration: none;
    color: #3e6ca0;
    &:hover {
        text-decoration: underline
    }
`;

const StyledFacebook = styled(Button)`
    && {
    height: 48px;
    text-transform: none;
    margin-top: 16px;
    background: #4267b2;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.4);
    color: white;
    font-size: 16px;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 17px;
        &:hover {
        background: #4267b2;
        opacity: 0.9;
        color: white;
        }
        @media (max-width: 400px) {
            font-weight: 700;
        }
    }
 
`;

const MyIcon = styled(FontAwesomeIcon)`
    && {
    color: white;
    padding-right: 24px;
    font-size: 32px;
    }
`;

const LocalImage = styled.img`
    height: 40px;
    width: 40px;
    margin: -2px auto auto auto;
`;

