/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
// import { useTranslation } from 'react-i18next';

import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import "./Header.css";


export default function Header(props) {

    const [isNavVisible, setNavVisibility] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [isUserMenuVisible, setUserMenuVisibility] = useState(false);

    // const { t } = useTranslation();

    useEffect(() => {
    
        const mediaQuery = window.matchMedia("(max-width: 920px)");
    
        mediaQuery.addListener(handleMediaQueryChange);
        handleMediaQueryChange(mediaQuery);

        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };

    }, []);


    const handleMediaQueryChange = mediaQuery => {
        
        if (mediaQuery.matches) {
                setIsSmallScreen(true);
            } else {
                setIsSmallScreen(false);
            }

    };


    const toggleNav = () => {
        setNavVisibility(!isNavVisible);
    };

    const toggleUserMenu = () => {
        //collapseCheck();
        setUserMenuVisibility(!isUserMenuVisible);

    };

    const collapseCheck = () => {
        if (isNavVisible) {
            setNavVisibility(false);
        }
        if (isUserMenuVisible) {
            setUserMenuVisibility(false);
        }

    }


    //console.log('Header: ', props.avatar)

    let NavBar = (

        <nav className="Nav">
            <Link to="/photographers" onClick={collapseCheck}>Photographers</Link>
            <Link to="/models" onClick={collapseCheck}>Models</Link>
            <Link to="/muahs" onClick={collapseCheck}>Makeup & style</Link>
            <Link to="/studios" onClick={collapseCheck}>Studios</Link>
            <Link to="/events" onClick={collapseCheck}>Tours & workshops</Link>
        
            
            <img 
                src={
                    props.loggedinuser === "true" ? props.avatar 
                    : 
                    require("./../../../assets/noAvatar.png")
                    } 
                className="Avatar" 
                onClick={toggleUserMenu} 
                alt="Menu" 
            />



        </nav>

    )
    

    let NavBar_Mobile_NotLoggedIn = (

        <nav className="Nav">

            <Link to="/photographers" onClick={collapseCheck}>Photographers</Link>
            <Link to="/models" onClick={collapseCheck}>Models</Link>
            <Link to="/muahs" onClick={collapseCheck}>Makeup & style</Link>
            <Link to="/studios" onClick={collapseCheck}>Studios</Link>
            <Link to="/events" onClick={collapseCheck}>Tours & workshops</Link>


            <span className="avatarNavSeparator"></span>
            <img 
                src={
                    props.loggedinuser === "true" ? props.avatar 
                    : 
                    require("./../../../assets/noAvatar.png")
                } 
                className="Avatar" 
                // onClick={toggleUserMenu} 
                alt="Menu" 
            />
            <Link to="/login" onClick={collapseCheck}>Log in</Link>
            <Link to="/signup" onClick={collapseCheck}>Sign up</Link> 

            <span className="avatarNavSeparator"></span>
            <Link to="/find-work" onClick={collapseCheck}>Get extra clients</Link>
            <Link to="/become-an-affiliate" onClick={collapseCheck}>Become an affiliate</Link>
            {/* <Link to="/become-a-photographer" onClick={collapseCheck}>Become a photographer</Link>
            <Link to="/become-a-model" onClick={collapseCheck}>Become a model</Link>
            <Link to="/become-a-muah" onClick={collapseCheck}>Become a MUAH</Link>
            <Link to="/list-your-studio" onClick={collapseCheck}>List your studio</Link>
            <Link to="/list-your-event" onClick={collapseCheck}>List your event</Link> */}
            


        </nav>

    )


    let NavBar_Mobile_LoggedIn = (

        <nav className="Nav">

            <Link to="/photographers" onClick={collapseCheck}>Photographers</Link>
            <Link to="/models" onClick={collapseCheck}>Models</Link>
            <Link to="/muahs" onClick={collapseCheck}>Makeup & style</Link>
            <Link to="/studios" onClick={collapseCheck}>Studios</Link>
            <Link to="/events" onClick={collapseCheck}>Tours & workshops</Link>


            <span className="avatarNavSeparator"></span>
            <img 
                src={
                    props.loggedinuser === "true" ? props.avatar 
                    : 
                    require("./../../../assets/noAvatar.png")
                } 
                className="Avatar" 
                // onClick={toggleUserMenu} 
                disabled
                alt="Menu" 
            />
            <Link to="/inbox" onClick={collapseCheck}>Inbox</Link>
            <Link to="/bookings" onClick={collapseCheck}>Bookings</Link>
            <Link to="/favorites" onClick={collapseCheck}>Favorites</Link>

            <span className="avatarNavSeparator"></span>
            {/* <Link to="/user/profiles" onClick={collapseCheck}>Profiles & events</Link> */}
            <Link to="/user/settings" onClick={collapseCheck}>Account settings</Link>

            {/* #TODO: after launched
            <span className="avatarNavSeparator"></span>
            <Link to="/user/feedback" onClick={collapseCheck}>Give Us Feedback</Link>
            <Link to="/user/invite" onClick={collapseCheck}>Invite friends and earn</Link> 
            <Link to="/user/referral" onClick={collapseCheck}>Enter referral code</Link> 
            */}
            
            <span className="avatarNavSeparator"></span>
            <Link to="/become-an-affiliate" onClick={collapseCheck}>Become an affiliate</Link>
            <Link to="/user/logout" onClick={collapseCheck}>Log out</Link>
            


        </nav>

    )


    let RenderUserMenu
    if (isUserMenuVisible) {
    
        if (props.loggedinuser === "true") {
        
            RenderUserMenu = (
                
                <div className="avatarMenu">

                    <nav className="avatarNav">
                        
                        <Link to="/inbox" onClick={collapseCheck}>Inbox</Link>
                        <Link to="/bookings" onClick={collapseCheck}>Bookings</Link>
                        <Link to="/favorites" onClick={collapseCheck}>Favorites</Link>
                        
                        
                        <span className="avatarNavSeparator"></span>
                        
                        {/* <Link to="/user/profiles" onClick={collapseCheck}>Profiles & events</Link> */}
                        <Link to="/user/settings" onClick={collapseCheck}>Account settings</Link>
                        

                        {/* #TODO: after launched */}
                        {/* <span className="avatarNavSeparator"></span>
                        <Link to="/user/feedback" onClick={collapseCheck}>Give Us Feedback</Link>
                        <Link to="/user/invite" onClick={collapseCheck}>Invite friends</Link>  */}
                       
                        
                        <span className="avatarNavSeparator"></span>

                        <Link to="/become-an-affiliate" onClick={collapseCheck}>Become an affiliate</Link>
                        <Link to="/user/logout" onClick={collapseCheck}>Log out</Link>
                        
                        
                    </nav>

                </div>

            )

        } else {

            
            RenderUserMenu = (
                
                

                <div className="avatarMenu">

                    <nav className="avatarNav">
                        
                        <Link to="/login" onClick={collapseCheck}>Log in</Link>
                        <Link to="/signup" onClick={collapseCheck}>Sign up</Link> 

                        <span className="avatarNavSeparator"></span>
                        <Link to="/find-work" onClick={collapseCheck}>Get more bookings</Link>
                        <Link to="/become-an-affiliate" onClick={collapseCheck}>Become an affiliate</Link>
                        {/* <Link to="/become-a-photographer" onClick={collapseCheck}>Become a photographer</Link>
                        <Link to="/become-a-model" onClick={collapseCheck}>Become a model</Link>
                        <Link to="/become-a-muah" onClick={collapseCheck}>Become a MUAH</Link>
                        <Link to="/list-your-studio" onClick={collapseCheck}>List your studio</Link>
                        <Link to="/list-your-event" onClick={collapseCheck}>List your event</Link> */}
                        
                    </nav>

                </div>

            )

        }
    
    } else {
    
        RenderUserMenu = (
            <div></div>
        )
        
    }

    
    return (
    
        <header className="Header">

            <Link to="/">
                <img src={require("./../../../assets/PhotoSouls-logo-beta.svg")} className="Logo" alt="logo" />
            </Link>
            


            <CSSTransition
                in={!isSmallScreen || isNavVisible}
                timeout={350}
                classNames="NavAnimation"
                unmountOnExit
            >

                {
                    isSmallScreen ? 
                        (
                            props.loggedinuser === "true" ?
                                NavBar_Mobile_LoggedIn
                                :
                                NavBar_Mobile_NotLoggedIn
                        )
                        : 
                        NavBar
                    
                }

            </CSSTransition>


            {RenderUserMenu}

            
            <button onClick={toggleNav} className="Burger">
                <FontAwesomeIcon icon={faBars} color="#333333"/>
            </button>

        

        </header>

    );

}
