import * as React from "react";

export class CallLoginFacebook extends React.PureComponent {

    async componentDidMount() {
        
        // console.log(this.props)
        //console.log('this.props.email: ', this.props.email)
        //console.log('this.props.fbAccessToken: ', this.props.fbAccessToken)
        //console.log('this.props.fbUserId: ', this.props.fbUserId)
        
        const res = await this.props.loginThirdparty({ 
            email: this.props.email, 
            thirdparty: this.props.thirdparty,
            token: this.props.token, 
            userId: this.props.userId, 
            currency: this.props.currency, 
            city: this.props.city, 
            country: this.props.country, 
            fName: '',
            lName: '',
            referralId: this.props.referralId
        });

        this.props.onFinish(res.data.loginThirdparty.userId);
    
    }

    render() {
        return null;
    }
    
}
