import React, { PureComponent } from 'react'
import { withApollo } from "react-apollo";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';

// import { Field } from "formik";

import styled from 'styled-components';



class C extends PureComponent { 


    handleChange = event => {

        this.setState({
            [event.target.value]: event.target.checked,
        });
    
        this.props.values[event.target.value] = event.target.checked ? true : false

    };


    render() {


        return (

            <PageContent>


                <PageTitle>Categories</PageTitle>

                <PHeading>Select the categories you are working in</PHeading>


                    <ChkGrid>

                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catMuah}
                                        onChange={this.handleChange}
                                        value="catMuah"
                                    />
                                }
                            label={'Makeup & hair'}
                            />          
                        </ChkContainer>           
                        
                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catHair}
                                        onChange={this.handleChange}
                                        value="catHair"
                                    />
                                }
                            label={'Hair'}
                            />          
                        </ChkContainer>             


                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catMakeup}
                                        onChange={this.handleChange}
                                        value="catMakeup"
                                    />
                                }
                            label={'Makeup'}
                            />          
                        </ChkContainer> 

                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catBodypaint}
                                        onChange={this.handleChange}
                                        value="catBodypaint"
                                    />
                                }
                            label={'Bodypaint'}
                            />          
                        </ChkContainer> 

                        <ChkContainer>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.catStyle}
                                        onChange={this.handleChange}
                                        value="catStyle"
                                    />
                                }
                            label={'Style'}
                            />          
                        </ChkContainer> 

                    </ChkGrid>

                    <IncreaseHeight/>


                    <ChkGrid>

                        <ChkContainer100>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.topless}
                                        onChange={this.handleChange}
                                        value="topless"
                                    />
                                }
                            label={'Willing to work on topless photoshoots'}
                            />          
                        </ChkContainer100> 


                        <ChkContainer100>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.props.values.nude}
                                        onChange={this.handleChange}
                                        value="nude"
                                    />
                                }
                            label={'Willing to work on nude photoshoots'}
                            />          
                        </ChkContainer100> 
                        
                    </ChkGrid>

                    <IncreaseHeight/>
                
            </PageContent>

        )

    }
    
}




export const CategoriesPage = (withApollo(C));


const OrangeCheckBox = withStyles({
    root: {
      color: orange[400],
      '&$checked': {
        color: orange[600],
      },
    },
    checked: {},
    })(props => <Checkbox color="default" {...props} /> )
;


const ChkContainer = styled.div`
    
  padding-left: 16px;
  padding-top: 2px;
  padding-bottom: 2px;
  width: 155px;
  
`;

const ChkContainer100 = styled.div`
    
  padding-left: 16px;
  padding-top: 2px;
  padding-bottom: 2px;
  width: 100%;
  
`;


const ChkGrid = styled.div`
    
    display: flex;
    flex-wrap: wrap;    

`;

const IncreaseHeight = styled.div`
    
    height: 23px;  

`;

const PHeading = styled.div`
    
    font-size: 16px;  
    font-family: 'Fira Sans';
    font-weight: 300;

`;


const PageContent = styled.div`
    
    width: 100%;
    /* height: 100%; */
    /* min-height: 75vh; */
    font-family: 'Fira Sans';
    
`;


const PageTitle = styled.div`
    
    display: flex;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;

    display: none;
    @media (max-width: 790px) {
        display: block;
        }


`;

