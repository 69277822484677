import React, { PureComponent } from 'react'
import { withFormik } from "formik";
//import axios from 'axios';
import { withApollo } from "react-apollo";

import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

// import i18next from "i18next";
import { withStore } from '@spyna/react-store'

import "./../settings.css";
import './../../../../i18n'

// import moment from "moment"; 


class C extends PureComponent {

    state = {
        uploadState: "none",


    };

    componentDidMount() {

        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

        this.setState({
            uploadState: "none",
        });

    
    }


    componentDidUpdate(prevProps, prevState) {

        if (this.state.uploadState === "completed") {

            this.setState({
                uploadState: "finalized"
            });

        }


    }



    render() {
        
        // const email = this.props.store.get('email');

        // const now = moment(new Date()); //todays date
        // const lastUpdated = moment(this.props.store.get('pwdUpdated')); // another date
        // const daysBetween = now.diff(lastUpdated, 'days')
        // // var pwdUpdated = '';
        // if (daysBetween === 0) { pwdUpdated = 'Last changed today' }
        // else if (daysBetween === 1) { pwdUpdated = 'Last changed yesterday' }
        // else { pwdUpdated = `Last changed ${daysBetween} days ago` }
        
      
        // const isPro = this.props.store.get("isPro");

        const stripeExpressID = this.props.store.get("StripeExpressID");
        
        let showStripeMessage = false;
        if ( stripeExpressID === '' ) {
            showStripeMessage = true;
        }

        //console.log(stripeExpressID)

        return (

            
            <ExtraWrapper>

                <Helmet>
                    <title>Payments Settings</title>
                    <meta name="description" content="Payments" />
                    <meta name="keywords" content="photosouls,payments" />
                </Helmet>
            
            

                 
                <ModalTitle>

                    <BackButton onClick={ () => { this.props.history.push({ pathname: '../settings' }) } } >
                        <FAIcon icon={faChevronLeft} color={'#000'} />
                    </BackButton>

                    <TitleHeadline>
                        Payments & payouts
                    </TitleHeadline>

                </ModalTitle>



                        
                <ModalTitle>
                    
                    { showStripeMessage === true ? 
                    <TitleHeadline>
                        <StripeExpressCallout>
                            {/* <Em><span role="img" aria-label='warning'>⚠️</span></Em>
                            In order to begin processing payments for your bookings through our platform, we require some additional information from you. Along with your payment details, we also need your contact information. Please be aware that while we can still secure bookings for your photoshoots without this information, we will not be able to collect any payments on your behalf until these details are provided.
                            <br/><br/> */}
                            Currently, when clients book your services through PhotoSouls, they are charged only the PhotoSouls service fee. The remaining balance is to be paid directly to you by the client after the photo shoot. Please note that in this scenario, we are unable to enforce any cancellation policies on your behalf.
                            <br/><br/>
                            To enhance your profile's appeal and potentially increase your bookings, we recommend adding online payment options. We've partnered with Stripe, a reputable payment processor, to facilitate secure and efficient transactions.
                            <br/><br/>
                            By setting up a Stripe Express account and verifying your identity—a process that is quick and straightforward—you enable us to collect the full booking amount on your behalf. Once your Stripe account is active, we can start processing complete payments from clients at the time of booking and ensure that your earnings are transferred directly to your bank account.
                            <br/><br/>
                        </StripeExpressCallout>
                    </TitleHeadline>
                    :
                    null
                    }
                </ModalTitle>

                <ModalTitle>
                    <TitleHeadline>
                    Online payment options are now available to a select group of our Pro members. 
                    If you are interested in enabling this feature for your profile, please reach out to us to be added to our waitlist. 
                    We are selecting members from this list on an individual basis to provide personalized assistance with onboarding and to ensure a smooth transition to full payment handling.

                    </TitleHeadline>
                </ModalTitle>
                
            </ExtraWrapper>


        )
    }
}


const AccountSettingsPayments = withFormik({
    
    //validationSchema: validEmailField,
    //console.log(this);

    mapPropsToValues: () => ({}),
    
    handleSubmit: async (values, { props, setErrors }) => {
        //console.log('handlesubmit')
    }
  })(C);
  
  
  
const personalInfoFields = [
    'fName', 'lName', 'bDay', 'email', 'phone', 'phoneDisplay', 'phoneCountry', 'avatar', 'isVerified', 'isPro', 'temperror', 'pwdUpdated',
    'chkReminders', 'chkRemindersP', 'chkMessages', 'chkMessagesP', 'chkRecomm', 'chkRecommP', 'chkInsp', 'chkInspP', 'chkLocal', 'chkLocalP', 
    'chkHow', 'chkHowP', 'chkInvite', 'chkInviteP', 'chkSurvey', 'chkSurveyP', 'chkPolicy', 'chkPolicyP', 'chkTips', 'chkTipsP'
]

export default withStore(withApollo(AccountSettingsPayments), personalInfoFields);





const ExtraWrapper = styled.div`

    white-space: pre-line;
    width: calc(80% - 64px);
    max-width: 1200px;

    margin: 40px auto 32px auto;

    font-family: 'Fira Sans';

    @media (max-width: 790px) {
        width: calc(100% - 32px);
        margin: 0;
        overflow-x: hidden;
    }

`;




const ModalTitle = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;

    margin-left: 24px;

    @media (max-width: 970px) {
        flex-direction: column;
        margin-top: 16px;
    }


`;


const BackButton = styled(Button)`
    && {
        
    /* margin: -4px -4px 0 0px; */

    height: 48px;
    min-width: 48px;
    max-width: 48px;
    border-radius: 24px;

    margin-right: 16px;
    /* margin-right: 4px;
    margin-left: 4px; */

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;

    
    font-weight: 400;
    font-size: 19px;

    background-color: #EBEBEB;
    opacity: 0.6;

    &:hover {
        background-color: #EBEBEB;
        opacity: 0.6;
        }
    
    &:disabled {
        background-color: #fff;
        color: #fff;
        }

    }
    
`;

const FAIcon = styled(FontAwesomeIcon)`
    && {
    font-size: 22px;
    font-weight: 400;
    }
`;



const TitleHeadline = styled.div`
    
    padding: 12px 24px 24px 0px;

    font-family: 'Fira Sans';
    font-size: 22px;
    font-weight: 500;

    color: #333333;

`;



const StripeExpressCallout = styled.div`

    margin-top: 8px;
    margin-bottom: 16px;
    margin-right: 16px;

    padding: 24px;
    border-radius: 8px;

    font-family: 'Fira Sans';
    font-size: 16px;
    font-weight: 400;

    background-color: #ffe3b4;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.3);

    color: #333333;

`;
