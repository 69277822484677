import React from 'react';  


import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import i18next from "i18next";
import './../../../../i18n'

import gql from "graphql-tag"

import { Divider } from "../../../components/Divider";
import { MyError } from "../../../components/MyError";
import '../../../../styles/ModalPopup.css';    



const updateMutation = gql`
    mutation($fld: String!, $value: String!, $pwd: String!) {
        changeEmail(fld: $fld, value: $value, pwd: $pwd)
    }
`;


class ConfirmEmail extends React.Component {  

    state = {
        confirmEmail: "",
        confirmEmail2: "",
        confirmPwd: "",
        errorMsg: "",
    };


    handleEmailChange = (event) => {
        this.setState({confirmEmail: event.target.value})
    }

    handleEmailChange2 = (event) => {
        this.setState({confirmEmail2: event.target.value})
    }

    handlePwdChange = (event) => {
        this.setState({confirmPwd: event.target.value})
    }


    render() {  

        const mybordercolor = this.state.errorMsg ? "#ff4343" : "#cbcbcb";
        const mybordercolor2 = "#cbcbcb";

        return (  
            
            <div className='react-confirm-alert-overlay'>
            
                <div className='react-confirm-alert-body'>
                        
                            <h1>Change your e-mail</h1>

                            <Divider />                       
                            <LabelStyled>Enter your new e-mail address</LabelStyled> 
                            <InputStyled
                                theme={{ bordercolor: mybordercolor }}
                                autoCapitalize = 'none'
                                onChange={this.handleEmailChange}
                                value={this.state.confirmEmail}
                                name='confirmEmail'
                            />


                            <Divider />
                            <LabelStyled>Confirm your new e-mail address</LabelStyled> 
                            <InputStyled
                                theme={{ bordercolor: mybordercolor }}
                                autoCapitalize = 'none'
                                onChange={this.handleEmailChange2}
                                value={this.state.confirmEmail2}
                                name='confirmEmail2'
                            />

                            <Divider />
                            <LabelStyled>Enter your password to confirm</LabelStyled> 
                            <InputStyled
                                theme={{ bordercolor: mybordercolor2 }}
                                type='password'
                                autoCapitalize = 'none'
                                onChange={this.handlePwdChange}
                                value={this.state.confirmPwd}
                                name='confirmPwd'
                            />


                            <Divider />
                            {
                                this.state.errorMsg ? (
                                    <>
                                        <MyError
                                            message = {this.state.errorMsg}
                                        />
                                        <Divider />
                                    </>
                                    )
                                : null
                                
                            }

                            <ButtonArea>
                                <StyledSave 
                                    onClick={() => {


                                        if (this.state.confirmEmail !== this.state.confirmEmail2) {
                                            setTimeout(() => {
                                                this.setState({
                                                    errorMsg: i18next.t('msg.errEmailDoesntMatch'),
                                                });
                                            }, 50)

                                            return
                                        }


                                        this.props.apolloClient
                                            .mutate({
                                                mutation: updateMutation,
                                                variables: {
                                                    fld: 'email',
                                                    value: this.state.confirmEmail,
                                                    pwd: this.state.confirmPwd,
                                                },
                                            })
                                            .then(apiResponse => {

                                                // console.log('apiResponse: ', apiResponse)

                                                if (apiResponse.data.changeEmail === null) {

                                                    
                                                    if (apiResponse.errors[0].data[0].message.data[0].message.length > 5) {     

                                                        setTimeout(() => {
                                                            this.setState({
                                                                errorMsg: apiResponse.errors[0].data[0].message.data[0].message
                                                            });
                                                        }, 50)

                                                    } 


                                                } else if (apiResponse.data.changeEmail === true) {     

                                                    this.props.propStore.set('email', this.state.confirmEmail)
                                                    this.props.closePopup();

                                                } else {

                                                    setTimeout(() => {
                                                        this.setState({
                                                            errorMsg: i18next.t('msg.errProcessingReq'),
                                                        });
                                                    }, 50)

                                                }
                                


                                            })
                                            .catch(error => {
                                                
                                                //console.log("Error: ", error);
                                                
                                                setTimeout(() => {
                                                    this.setState({
                                                        errorMsg: error,
                                                    });
                                                }, 50)

                                            })
                                        
                                    }}

                                    >
                                    Change Now
                                </StyledSave>
                                
                                
                                <StyledCancel
                                    onClick={() => {
                                        this.props.closePopup();
                                    }}
                                    >
                                    Cancel
                                </StyledCancel>

                            </ButtonArea>
                            

                        </div>

                    

            </div>
        );  
    }  
    
}  

export default ConfirmEmail;




const ButtonArea = styled.div`

    display: flex;

    padding-bottom: 8px;
    padding-left: 8px;

    color: #333333;
    align-items: flex-end;

`;


const LabelStyled = styled.div`
  width: 100%;
  margin: 0px;
  padding: 0px;
  color: #333333;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 4px;
  margin-top: 0px;
`;

const InputStyled = styled.input`
    width: calc(100% - 18px);
    padding: 0px;
    margin: 0px;
    height: 24px;
    padding: 8px;
    font-size: 16px;
    font-family: 'Fira Sans';
    border: 1px solid ${props => props.theme.bordercolor};
    border-radius: 4px;  
    color: #333333;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);
    :disabled {
        background-color: #f7f7f7; 
    }
    :focus {
        outline-color: #4C9AFF;
    }
    @media (max-width: 400px) {
        font-family: 'Fira Sans';
        font-size: 17px;
    }
`;




const StyledSave = styled(Button)`
    && {
    height: 40px;
    top: 18px;
    
    background: #ff9500;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.4);
    color: white;
    width: 50%;
    margin-left: -8px;
    
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
        &:hover {
        background: #ff9500;
        opacity: 0.9;
        color: white;
        }
    }
    
`;

const StyledCancel = styled(Button)`
    && {
    height: 40px;
    top: 18px;
    margin-left: 8px;
    color: #333333;
    width: 50%;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    }
    
`;

