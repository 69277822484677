import React, { PureComponent } from 'react'
import { withFormik, Field, Form } from "formik";

import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'


//import axios from 'axios';
import { withApollo } from "react-apollo";

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

// import i18next from "i18next";
import { withStore } from '@spyna/react-store'

import { ConfirmField } from "../../../components/settings/ConfirmField";
import "./../settings.css";
import './../../../../i18n'

import ConfirmEmail from './ConfirmEmail';  
import ConfirmPwd from './ConfirmPwd';  
import ConfirmDeactivate from './ConfirmDeactivate'; 

import moment from "moment"; 

class C extends PureComponent {

    state = {
        uploadState: "none",
        showConfirmEmail: false,
        showConfirmPwd: false,
        showDeactivateConfirm: false,

    };

    componentDidMount() {

        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

        this.setState({
            uploadState: "none",
        });

    
    }


    componentDidUpdate(prevProps, prevState) {

        if (this.state.uploadState === "completed") {

            this.setState({
                uploadState: "finalized"
            });

        }

    }

    toggleConfirmEmail = () => {  

        this.setState({  
            showConfirmEmail: !this.state.showConfirmEmail  
        });  

    }  

    toggleConfirmPwd = () => {  

        this.setState({  
            showConfirmPwd: !this.state.showConfirmPwd 
        });  

    }  

    toggleDeactivateConfirm = () => {  

        this.setState({  
            showDeactivateConfirm: !this.state.showDeactivateConfirm 
        });  

    }  


    render() {
        
        const email = this.props.store.get('email');

        const now = moment(new Date()); //todays date
        const lastUpdated = moment(this.props.store.get('pwdUpdated')); // another date
        const daysBetween = now.diff(lastUpdated, 'days')
        var pwdUpdated = '';
        if (daysBetween === 0) { pwdUpdated = "Last changed today" }
        else if (daysBetween === 1) { pwdUpdated = "Last changed yesterday" }
        // eslint-disable-next-line no-template-curly-in-string
        else { pwdUpdated = "Last changed ${daysBetween} days ago".replace('${daysBetween}', daysBetween) }
        

        return (

            
            <ExtraWrapper>

                <Helmet>
                    <title>Login & Security</title>
                    <meta name="description" content="Account Login & Security" />
                    <meta name="keywords" content="photosouls,account security" />
                </Helmet>
            
                        
                <ModalTitle>

                    <BackButton onClick={ () => { this.props.history.push({ pathname: '../settings' }) } } >
                        <FAIcon icon={faChevronLeft} color={'#000'} />
                    </BackButton>

                    <TitleHeadline>
                        Login & Security
                    </TitleHeadline>

                </ModalTitle>
                        

               
                {
                    this.state.showConfirmEmail ?  
                        <ConfirmEmail  
                            apolloClient={this.props.client}
                            propStore={this.props.store}
                            closePopup={this.toggleConfirmEmail}  
                            
                        />
                        : null  
                }  

                {
                    this.state.showConfirmPwd ?  
                        <ConfirmPwd  
                            apolloClient={this.props.client}
                            propStore={this.props.store}
                            closePopup={this.toggleConfirmPwd}  
                            
                        />
                        : null  
                }

                {
                    this.state.showDeactivateConfirm ?  
                        <ConfirmDeactivate
                            apolloClient={this.props.client}
                            propStore={this.props.store}
                            closePopup={this.toggleDeactivateConfirm}  
                            
                        />
                        : null  
                }

                <IncreaseHeight40/>
                <Form> 
                

                        <div className='settingsFieldList'>

                            
                            <Field
                                name="email"
                                originalValue={email}
                                label="E-mail"
                                propStore={this.props.store}
                                component={withApollo(ConfirmField)}
                                midateha={this.toggleConfirmEmail}
                                allowedToChange={true} 
                                emailChange={true} 
                            />

                            <Field
                                name="pwd"
                                originalValue={pwdUpdated}
                                label="Password"
                                propStore={this.props.store}
                                component={withApollo(ConfirmField)}
                                allowedToChange={true} 
                                midateha={this.toggleConfirmPwd}
                                pwdChange={true} 
                            />


                            <StyledDivider></StyledDivider>

                            <StyledEdit type="submit" onClick={this.toggleDeactivateConfirm}>Deactivate Account</StyledEdit> 
                           
                        

                        </div>

                  

    
            </Form>
            </ExtraWrapper>


        )
    }
}


const AccountSettingsLogin = withFormik({
    
    //validationSchema: validEmailField,
    //console.log(this);

    mapPropsToValues: () => ({}),
    
    handleSubmit: async (values, { props, setErrors }) => {
        //console.log('handlesubmit')
    }
  })(C);
  

const personalInfoFields = [
    'fName', 'lName', 'bDay', 'email', 'phone', 'phoneDisplay', 'phoneCountry', 'avatar', 'isVerified', 'isPro', 'temperror', 'pwdUpdated',
    'chkReminders', 'chkRemindersP', 'chkMessages', 'chkMessagesP', 'chkRecomm', 'chkRecommP', 'chkInsp', 'chkInspP', 'chkLocal', 'chkLocalP', 
    'chkHow', 'chkHowP', 'chkInvite', 'chkInviteP', 'chkSurvey', 'chkSurveyP', 'chkPolicy', 'chkPolicyP', 'chkTips', 'chkTipsP'
]

export default withStore(withApollo(AccountSettingsLogin), personalInfoFields);


const StyledEdit = styled(Button)`
    && {
    height: 40px;
    color: #333333;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    margin-left: 24px;

    
    }
    
`;

const StyledDivider = styled.div`
  width: 100%;
  height: 24px;
`;



const ExtraWrapper = styled.div`

    white-space: pre-line;
    width: calc(80% - 64px);
    max-width: 1200px;

    margin: 40px auto 32px auto;

    font-family: 'Fira Sans';

    @media (max-width: 790px) {
        width: calc(100% - 32px);
        margin: 0;
        overflow-x: hidden;
    }

`;




const IncreaseHeight40 = styled.div`
    
    height: 40px;  
    min-height: 40px;

`;

const ModalTitle = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;

    margin-left: 24px;

    @media (max-width: 970px) {
        flex-direction: column;
        margin-top: 16px;
    }


`;


const BackButton = styled(Button)`
    && {
        
    /* margin: -4px -4px 0 0px; */

    height: 48px;
    min-width: 48px;
    max-width: 48px;
    border-radius: 24px;

    margin-right: 16px;
    /* margin-right: 4px;
    margin-left: 4px; */

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;

    
    font-weight: 400;
    font-size: 19px;

    background-color: #EBEBEB;
    opacity: 0.6;

    &:hover {
        background-color: #EBEBEB;
        opacity: 0.6;
        }
    
    &:disabled {
        background-color: #fff;
        color: #fff;
        }

    }
    
`;

const FAIcon = styled(FontAwesomeIcon)`
    && {
    font-size: 22px;
    font-weight: 400;
    }
`;



const TitleHeadline = styled.div`
    
    padding: 12px 24px 24px 0px;

    font-family: 'Fira Sans';
    font-size: 22px;
    font-weight: 500;

    color: #333333;

`;