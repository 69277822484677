//import logger from "./logger";


const checkTrafficSource = (profileId) => {

    // first character of the profileId indicates the profile type where the user landed:  0 = photog,   1 = model,  2 = muah,   3 = studio,     4 = event

    const referrer = document.referrer;

    const searchPhotosouls = /photosouls|PhotoSouls|localhost/; // Extend this list as needed
    const isFromPhotoSouls = searchPhotosouls.test(referrer);

    const searchEngines = /google\.|yahoo\.|bing\.|duckduckgo\.|baidu\.|yandex\.|ask\.|aol\./; // Extend this list as needed
    const isFromSearchEngine = searchEngines.test(referrer);

    //const isTypedURL = ((!referrer) || (referrer === ''));

    //logger('++++++++++  trafficSource - isFromPhotoSouls', 'info', {'isFromPhotoSouls': isFromPhotoSouls})
    //logger('++++++++++  trafficSource - isFromSearchEngine', 'info', {'isFromSearchEngine': isFromPhotoSouls})
    //logger('++++++++++  trafficSource - isTypedURL', 'info', {'isTypedURL': isTypedURL})
    
    // if refProfile is not set in LocalStorage
    // then we need to set the refProfile in localstorage, marking the first visit (of that visitor to the profileId profile). 
    // no matter if they arrived manually or though search.

    if ((localStorage.getItem('refProfile') === null) 
        || (localStorage.getItem('refProfile') === 'null') 
        || (localStorage.getItem('refProfile') === '') 
        || (localStorage.getItem('refProfile') === 'undefined')) { 
    
            if ((!isFromPhotoSouls) && (!isFromSearchEngine)) {

                // When landing not from inside PhotoSouls, and not from Search engine, it indicates the profile owner shared their profile link, 
                // and visitor is now using that link or manually typing in their profile address. Thus owner earns a referral.

                localStorage.setItem('refProfile', profileId);
                //logger('++++++++++  trafficSource - set localStorage.refProfile = ', 'info', {'refProfile.profileId': profileId})

            } else {
                
                // set localstorage, but set with '-' indicating this user did not arrive through shared profile link. 
                // meaning they either visited a profile through PhotoSouls search, or through Google search.
                
                localStorage.setItem('refProfile', '-');
                //logger('++++++++++  trafficSource - set localStorage.refProfile =', 'info', {'refProfile.profileId': '-'})

            }


        //do nothing, as visitor has already visited the site. original referral will stay in place, when they land on the site again.

    } else {
    
        //refProfile was already set in LocalStorage. We don't need to do anything.

    }

}


export default checkTrafficSource;
