
import React from 'react';  
import styled from 'styled-components';
import '../../../i18n'

import Button from '@material-ui/core/Button';

import { MyError } from "../../components/MyError";

import '../../../styles/ModalPopup.css';    

class PremiumURLGeneration extends React.Component {  

    state = {
        premiumURL: "",
        errorMsg: "",
    };

    handlePremiumURLChange = (event) => {
        this.setState({premiumURL: event.target.value})
    }

    render() {  

        const bordercolor = this.state.errorMsg ? "#ff4343" : "#cbcbcb";
        

        return (  
            
            <>
            
                <div className='react-confirm-alert-overlay'>
                
                    <div className='react-confirm-alert-body'>
                            
                        <StyledHeader>Plese enter the URL you would like to have for this profile.</StyledHeader>

                        <StyledHeader2>For example - if you enter Tony then direct link to your profile will be www.photosouls.com/tony</StyledHeader2>

                        <InputStyled
                            theme={{ bordercolor }}
                            autoCapitalize = 'none'
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            onChange={this.handlePremiumURLChange}
                            value={this.state.premiumURL}
                            name='premiumURL'
                        />

                        <SpaceBetween />

                            {
                                this.state.errorMsg ? (
                                    <>
                                        <MyError
                                            message = {this.state.errorMsg}
                                        />
                                        <SpaceBetween />
                                    </>
                                    )
                                : null
                                
                            }


                        <SelectionArea>
                            
                            <SelectionButtonOrange
                                    onClick={async () => {

                                        const isAvailable = await this.props.checkURL(this.state.premiumURL)

                                        if (isAvailable === 'notavailable') {

                                            setTimeout(() => {
                                                this.setState({
                                                    errorMsg: `www.photosouls.com/${this.state.premiumURL} is already in use. Please try something else.`,
                                                });
                                            }, 50)

                                        } else {
                                            this.props.applyURL(this.state.premiumURL);
                                        }

                                    }}
                                    >
                                    Save
                            </SelectionButtonOrange>

                            <Spacing/>

                            <SelectionButton
                                    onClick={() => {
                                    this.props.closePopup();
                                }}
                                >
                                Cancel
                            </SelectionButton>

                        </SelectionArea>


                    </div>

                        

                </div>

            </>

        );  
    }  
    
}  

export default PremiumURLGeneration;

const Spacing = styled.div`

    width: 24px;
    display: absolute;
    
`;

const SpaceBetween = styled.p`
    margin-top: 24px;
    height: 24px;
    font-size: 16px;
    color: #333333;
`;

const InputStyled = styled.input`
    width: calc(100% - 18px);
    padding: 0px;
    margin: 0px;
    height: 24px;
    padding: 8px;
    font-size: 16px;
    font-family: 'Fira Sans';

    text-transform: lowercase;

    border: 1px solid ${props => props.theme.bordercolor};
    border-radius: 4px;  
    color: #333333;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);
    :disabled {
        background-color: #f7f7f7; 
    }
    :focus {
        outline-color: #4C9AFF;
    }
    @media (max-width: 400px) {
        font-family: 'Fira Sans';
        font-size: 17px;
    }
`;



const SelectionArea = styled.div`

    width: 100%;
    margin-top: 16px; 
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;

    text-align: center;
    align-items: center;
    justify-content: center;
    
`;

const SelectionButton = styled(Button)`
    && {

        width: calc(50% - 24px);
        width: 50%;
        height: 40px;

        margin: 0px 0px 0px 0px;
        padding: 0px 16px 0px 16px;
        border-radius: 4px;

        color: #333333;
        background: #ffffff;
        box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.3);

        text-transform: none;
        font-weight: 400;
        font-size: 17px;

        &:hover {
            background: #f8f8f8;
            }
    }
    
`;

const SelectionButtonOrange = styled(Button)`
    && {

        width: calc(50% - 24px);
        height: 40px;

        margin: 0px 0px 0px 0px;
        padding: 0px 16px 0px 16px;
        border-radius: 4px;

        color: #ffffff;
        background: #ff9500;
        box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.3);

        text-transform: none;
        font-weight: 400;
        font-size: 17px;

        &:hover {
            background: #ff9500;
            opacity: 0.9;
            color: white;
            }
    }

`;




const StyledHeader = styled.div`
  width: 100%;
  
  margin: 0px 0px 16px 0px;
  padding: 0px;

  color: #333333;
  font-weight: 400;
  font-size: 21px;

  
`;

const StyledHeader2 = styled.div`
  width: 100%;
  
  margin: 0px 0px 16px 0px;
  padding: 0px;

  color: #333333;
  font-weight: 300;
  font-size: 17px;

  
`;