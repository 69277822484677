import * as React from "react";
import { LoginControllerThirdparty } from "@psd/controller";
import { CallLoginFacebook } from "./CallLoginFacebook";

// import { logEvent, setUserId } from '@psd/web/src/utils/analytics/amplitude';

export class LoginFacebookConnector extends React.PureComponent {
    
    state = {
        ready4next: false,
    };

    onFinish = (userId) => {

        // logEvent('login', {'type': 'facebook'})
        // setUserId(userId)

        // console.log('before onFinish done')

        //this.props.history.push("/");
        this.props.onFinish();

        // console.log('after onFinish done')
        //console.log('done')

    };

    async componentDidMount() {
        
        this.setState({
            ready4next: true,
        });

    }

    render() {

        // console.log('ready4next: ', this.state.ready4next)

        return (
        
            <div>

                { this.state.ready4next ?

                    <LoginControllerThirdparty>
                        {
                            ({ loginThirdparty }) => 

                                <CallLoginFacebook 
                                    loginThirdparty={loginThirdparty} 
                                    thirdparty={this.props.thirdparty}
                                    onFinish={this.onFinish} 
                                    email={this.props.email}
                                    token={this.props.token}
                                    currency={this.props.currency}
                                    city={this.props.city}
                                    country={this.props.country}

                                    userId={this.props.userId}
                                    referralId={this.props.referralId}
                                />
                            
                        }
                    </LoginControllerThirdparty>
                    :
                    null

                }

            </div>
            
    
        );
    }

}
