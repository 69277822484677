
import React, { Component } from 'react';
import "./index.css";
import styled from 'styled-components';

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faTwitter, faPinterestP } from '@fortawesome/free-brands-svg-icons';



export class FooterSection extends Component {

    render() {

        return (
            
            <>
                {/* Footer */}
                <Section5>

                    <footer className="FooterBanner">

                        <div className="FooterContentBanner">
                            
                            <div className="FooterLeftSideBanner">

                                <FooterLogo src={require("./../../assets/PhotoSouls-logo.svg")} alt="Collage of photos" />

                                <div className="FooterUSPBanner">PhotoSouls will connect you with the right people for better photos and amazing experiences.</div>

                                <div className="logoRow">
                                    
                                    
                                </div>

                                <div className="FooterCopyrightBanner">© {(new Date().getFullYear())} PhotoSouls, Inc. All rights reserved.</div>

                            </div>

                            <div className="FooterRightSideBanner">

                                <div className="FooterUpperPart">

                                    <div className="FooterCol1">
                                        <Link to="/help">Help Center</Link>
                                        <Link to="/about">About Us</Link>
                                        <Link to="/contact">Contact</Link>
                                    </div>

                                    <div className="FooterCol2">
                                        <Link to="/terms/privacy-policy">Privacy Policy</Link>
                                        <Link to="/terms/terms-of-service">Terms of Service</Link> 
                                        <Link to="/sitemap">Sitemap</Link> 
                                    </div>

                                    {/* <div className="FooterCol3">

                                        
                                        <MySelect
                                            disabled
                                            value={currentLanguage}
                                            onChange={changeLanguage}
                                            name="language"
                                            displayEmpty
                                            disableUnderline
                                            MenuProps={{
                                                style: {zIndex: 9999999}
                                            }}
                                            >
                                            <MenuItem value="en">English</MenuItem>
                                            <MenuItem value="es">Español</MenuItem>
                                            <MenuItem value="de">Deutsh</MenuItem>
                                            <MenuItem value="ru">Русский</MenuItem>
                                            <MenuItem value="et">Eesti</MenuItem>
                                        </MySelect> 
                                    


                                    </div> */}

                                </div>

                                <div className="FooterBottomPart">
                                    
                                    <div className="FooterLogoFB">
                                        <a href="https://www.facebook.com/photosouls/" target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faFacebookF} color="#ffffff"/>
                                        </a>
                                    </div>
                                    <div className="FooterLogoIG">
                                        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                                        {/* #TODO: */}
                                            <FontAwesomeIcon icon={faInstagram} color="#ffffff"/>
                                        </a>
                                    </div>
                                    <div className="FooterLogoTw">
                                        <a href="https://twitter.com/Photosouls" target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faTwitter} color="#ffffff"/>
                                        </a>
                                    </div>
                                    <div className="FooterLogoPin">
                                        <a href="https://www.pinterest.com" target="_blank" rel="noopener noreferrer">
                                        {/* #TODO: */}
                                            <FontAwesomeIcon icon={faPinterestP} color="#ffffff"/>
                                        </a>
                                    </div>
                                    

                                </div>

                            </div>
                        </div>
                    </footer> 


                </Section5>

            </>

        )
            
    }
}


const Section5 = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;

    background-color: #f3f2f0;
    background-image: linear-gradient(#f3f2f0, #fff);

    position: relative;
    z-index: 1;
    min-height: 260px;


`;


const FooterLogo = styled.img`

    height: 56px;
    padding-bottom: 24px;

`;

