import React, { PureComponent } from 'react'

import { Helmet } from "react-helmet";

import moment from "moment"; 
import i18next from "i18next";
import '../../../../i18n'

// import { validEmailField } from '@psd/common'

import ReactGA from 'react-ga4';
// import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';

import { Formik, Form } from "formik";

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import { withStore } from '@spyna/react-store'
import { withApollo } from "react-apollo";
import { withRouter } from 'react-router-dom';

import ConfirmDelete from '../ConfirmDelete'; 
import { ErrorModal } from "../../../components/ErrorModal";

import { DetailsPage } from "./DetailsPage";
import { DescriptionPage } from "./DescriptionPage";
import { HostPage } from "./HostPage";
import { AlbumsPage } from "./AlbumsPage";
import { ContactPage } from "./ContactPage";
//import TimeAgo from '../../../../utils/TimeAgo'

import gql from "graphql-tag"


export const defaultEventFormValues = {
  
    // name: "",
    // gender: 0,
    // experience: 0,
    // status: 0,

};


const deleteEventMutation = gql`
    mutation($profileId: String!) {
        deleteEvent(profileId: $profileId)
    }
`;



class C extends PureComponent {


    state = {
        pageToShow: 'details',
        isMobileMenuVisible: false,
        showConfirmDelete: false,
        showSaveButton: true,
        showImmediateSave: false,
        showTopBar: true,
        activationError: '',
        errorTab: '',
    };


    toggleConfirmDelete = () => {  

        this.setState({  
            showConfirmDelete: !this.state.showConfirmDelete 
        });  

    }  

    async componentDidMount() {
    
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

        //if coming directly with url entering (without profile type checking, go back...)
        // if (this.props.location.state === undefined) {
        //     this.props.history.push({ pathname: '/user/profiles' })
        // }
        //console.log(this.props.location.state.profileType)

        //console.log(this.props)
        

    }

    setPage = (pageName) => {
        this.setState({pageToShow: pageName, isMobileMenuVisible: false});
        this.showSaveButton(true);
    }

    showSaveButton = (visibility) => {
        this.setState( { showSaveButton: visibility } );
    }

    showImmediateSaveLabel = (visibility) => {
        this.setState( { showImmediateSave: visibility } );
    }



    deleteProfile = async () => {

        // console.log('jousdime siia')
        this.setState({  
            showConfirmDelete: false 
        });

        const { match: { params: { profileId } } } = this.props;

        // console.log('delete profile', profileId);

        await this.props.client
            .mutate({
                mutation: deleteEventMutation,
                variables: {
                    profileId: profileId
                },
            })
            .then(results => {
                //console.log('deleted', results)
            })
            .catch(error => {
                // console.log("Error: ", error);
            })

        this.props.history.push("/user/profiles");
        window.location.reload();  

    }


    validateBeforePublishing = (values) => {

        let errors = {};
        let allowedToSubmit = true;


        // Every time a profile is saved, and its status is set to be public, check this.
        //      and if conditions are not met anymore, change the visibility status to hidden and give a modal message about it.
        
        if (values.status !== 0) {

            // in in case they "ruined" the profile in terms of "valid" (like removed albums and photos and as those changes were immediatelly submitted, 
            //      and the profile was active before, then you must now set the profile as inactive (and save this change immediatelly))

            //SO: Make sure the following fields are filled in order to make the package public:
            
                // Profile name / Title for the tour
                if (values.title.length === 0) {
                    setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'details', activationError: `To set your event visible, please fill the title field.`, }); }, 50);
                    allowedToSubmit = false;
                }

                // console.log('values.address.length', values.address.length)

                // Profile name / Title for the tour
                if ((values.virtual !== true) && (values.address.length < 5)) {
                    setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'details', activationError: `Please select location for your event if not online.`, }); }, 50);
                    allowedToSubmit = false;
                }


                // Profile name / Title for the tour
                if (values.title.length !== 0 && (values.title[0].intro === '' || values.title[0].intro === '<p><br></p>')) {
                    setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'details', activationError: `To set your event visible, please fill the title field.`, }); }, 50);
                    allowedToSubmit = false;
                }

                if (!values.serviceProviderName) {
                    setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'contact', activationError: `To set your profile visible, please fill in the service provider info on the Contact page.`, }); }, 50);
                    allowedToSubmit = false;
                }

                if (!values.serviceProviderAddress) {
                    setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'contact', activationError: `To set your profile visible, please fill in the service provider info on the Contact page.`, }); }, 50);
                    allowedToSubmit = false;
                }

                if (!values.serviceProviderEmail) {
                    setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'contact', activationError: `To set your profile visible, please fill in the service provider info on the Contact page.`, }); }, 50);
                    allowedToSubmit = false;
                }

                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.serviceProviderEmail)) {
                    setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'contact', activationError: `Invalid service provider email address entered on the Contact page.`, }); }, 50);
                    allowedToSubmit = false;
                }
                

                // Intro (and at least one section for tours). 
                if (values.sections.length === 0) {
                    setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'description', activationError: `To set your event visible, please fill in the description.`, }); }, 50);
                    allowedToSubmit = false;
                }

                // Intro / section may not be blank. 
                if (values.sections.length !== 0 && (values.sections[0].desc[0].desc === '' || values.sections[0].desc[0].desc === '<p><br></p>')) {
                    setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'description', activationError: `To set your event visible, please fill in the description.`, }); }, 50);
                    allowedToSubmit = false;
                }
                
                // At least ONE album is required
                if (values.albums.length === 0) {
                    setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'albums', activationError: `To set your event visible, at least one public album is required`, }); }, 50);
                    allowedToSubmit = false;
                }

                if (values.hosts.length === 0) {
                    setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'host', activationError: `To set your event visible, at least one host is required`, }); }, 50);
                    allowedToSubmit = false;
                }

                //At least one album must be public & without NSFW 
                if (values.albums.length !== 0) {

                    let visibleAndSafe = false;
                    for (var i = values.albums.length-1; i >= 0; i--) {
                        if ((values.albums[i].visibility !== 0) && (values.albums[i].nsfw === false)) {
                            visibleAndSafe = true;
                        }
                    }

                    if (visibleAndSafe !== true) {
                        setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'albums', activationError: `To set your event visible, at least one album must be public and without NSFW content`, }); }, 50);
                        allowedToSubmit = false;
                    }

                }
                
                
                // At least one date is required!
                if (values.dates.length === 0) {
                    setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'details', activationError: `To set your event visible, please add the dates`, }); }, 50);
                    allowedToSubmit = false;
                }

                //all dates must use the same currency!
                if (values.dates.length > 0) {
                    let firstPriceCurrency = values.dates[0].currency;
                    for (var z = values.dates.length-1; z >= 0; z--) {
                        if (values.dates[z].currency !== firstPriceCurrency) {
                            setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'details', activationError: `All prices (for dates) must be in the same currency`, }); }, 50);
                            allowedToSubmit = false;
                        }
                    }

                }
                
                // Availability must be set.. actually not.. because if not set then no booking can be made, and instead, 
                //      there is a button for "request booking" for example... but othign bad happens when nobody can book them...
                //      the reason is that we try to avoid db queries here, as every state chage will trigger this function, so everything would become SOOOOO SLOW
                

            if (!allowedToSubmit) {
                errors.whatswrong = 'CANNOT SET ACTIVE -- RULES NOT MET';
                // values.status = 0;
            } 
       
        }

        if (allowedToSubmit) {
            setTimeout(() => { this.setState({ showErrorModal: false, activationError: ``, }); }, 50);
        }

        return errors;

    }



    toggleNav = () => {
        this.setState({isMobileMenuVisible: !this.state.isMobileMenuVisible});
        //console.log('toggle nav')
    };

    findFirstLngTitle = (profile) => {

        const firstLng = profile.languages[0]

        let foundIndex = 0;
        for (var i = profile.title.length-1; i >= 0; i--) {
            if (profile.title[i].lng === firstLng) {
                // console.log(profile.title[i].lng)
                foundIndex = i;
            }
        }

        if (profile.title[foundIndex]) { 
            return profile.title[foundIndex].title; 
        } else {
            return "-"
        }
        

    }
    
    findNextDates = (dates) => {
        
        if (dates === undefined) {
            return " "
        }

        // console.log(dates)
        moment.locale(i18next.language);

        if (dates.start !== undefined) {
            if (dates.start === dates.end) {

                //in one day (start date = end date) 
                return moment(dates.start).format('MMMM D, YYYY') + ' (' + dates.st + ' - ' + dates.et + ')';
        
            } else {

                if (moment(dates.start).format('MMMM') === moment(dates.end).format('MMMM')) {

                    //within same month
                    return moment(dates.start).format('MMMM D') + ' - ' + moment(dates.end).format('D, YYYY');

                } else {

                    if (moment(dates.start).format('YYYY') === moment(dates.end).format('YYYY')) {
                        
                        //different months (but same year)
                        return moment(dates.start).format('MMMM D') + ' - ' + moment(dates.end).format('MMMM D, YYYY');

                    } else {

                        //different years
                        return moment(dates.start).format('MMMM D, YYYY') + ' - ' + moment(dates.end).format('MMMM D, YYYY');

                    }

                }

            }
        } else {
         
            return " "

        }
    }


    render() {

        const { submit, initialValues = defaultEventFormValues } = this.props;
        //console.log(this.state);
        

        return (
            
            <ProfileExtraWrapper>

                <Helmet>
                    <title>Event profile</title>
                    <meta name="description" content="Your event profile on PhotoSouls" />
                    <meta name="keywords" content="photosouls,profiles,listings" />
                </Helmet>
                


                <div className="bodywrapper">
                    <GeneralWrapper>
                     
                    <Formik
                        validateOnChange={false} 
                        validateOnBlur={false}
                        initialValues={initialValues}
                        validate={this.validateBeforePublishing}
                        onSubmit={submit}
                        >
                        
                        {({ isSubmitting, values, dirty, setFieldValue, errors }) =>
                        (

                            <Form style={{ display: "flex" }}>

                                <PageContent>

                                    {this.state.showTopBar ? 

                                        <TopBar>

                                            <TopBarLeft>

                                                <CardDiv>
                                                
                                                    <AvatarArea>
                                                        { 
                                                            values.coverPhoto ? 
                                                            <Cover 
                                                                src={values.coverPhoto} 
                                                                alt=" " 
                                                                onLoad={(e) => {
                                                                    //e.target.src = values.coverPhoto --- causes huge CPU spike. but looks like not necessary anyway!
                                                                    e.target.style = 'display: flex' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                                                }}
                                                                onError={(e) => {
                                                                    //e.target.src = '' --- causes huge CPU spike. but looks like not necessary anyway!
                                                                    e.target.style = 'display: none' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                                                }}

                                                            /> : <></>
                                                        }
                                                    </AvatarArea>

                                                    <BurgerButton onClick={this.toggleNav}>
                                                        <FontAwesomeIcon icon={faBars} color="#333333"/>
                                                    </BurgerButton>

                                                    {this.state.isMobileMenuVisible ? 
                                                        <MobileMenu>
                                                            <MobileBurgerButton onClick={this.toggleNav}>
                                                                <FontAwesomeIcon icon={faTimes} color="#333333"/>
                                                            </MobileBurgerButton>
                                                            <MenuItemDetails onClick={()=> this.setPage('details')} activepage={this.state.pageToShow}>Details</MenuItemDetails>
                                                            <MenuItemDescription onClick={()=> this.setPage('description')} activepage={this.state.pageToShow}>Description</MenuItemDescription>
                                                            <MenuItemAlbums onClick={()=> this.setPage('albums')} activepage={this.state.pageToShow}>Albums</MenuItemAlbums>
                                                            <MenuItemHost onClick={()=> this.setPage('host')} activepage={this.state.pageToShow}>Host</MenuItemHost>
                                                            <MenuItemContact onClick={()=> this.setPage('contact')} activepage={this.state.pageToShow}>Contact</MenuItemContact>
                                                        </MobileMenu>
                                                        :
                                                        <></>
                                                    }


                                                    <MemberInfoCard>

                                                        <CardTitle>{this.findFirstLngTitle(values)}</CardTitle>
                                                        <SubTitle>{this.findNextDates(values.dates[0])}</SubTitle>
                                                        
                                                    </MemberInfoCard>
                                                

                                                </CardDiv>

                                            </TopBarLeft>


                                            <TopBarRight>

                                                <ButtonArea>

                                                    {
                                                        this.state.showImmediateSave ? 
                                                            <>
                                                            <StyledSave type="submit" disabled></StyledSave>
                                                            <StyledSave type="submit" disabled={isSubmitting ? true : !this.state.showSaveButton }>Exit</StyledSave> 
                                                            </>
                                                            :
                                                            <StyledSave type="submit" disabled={isSubmitting ? true : !this.state.showSaveButton }>Save & exit</StyledSave> 

                                                    }

                                                </ButtonArea>

                                            </TopBarRight>


                                        </TopBar>

                                        :
                                        
                                        <></>

                                    }
                                    
                                    <LeftMenu>
                                        <MenuItemDetails onClick={()=> this.setPage('details')} activepage={this.state.pageToShow}>Details</MenuItemDetails>
                                        <MenuItemDescription onClick={()=> this.setPage('description')} activepage={this.state.pageToShow}>Description</MenuItemDescription>
                                        <MenuItemAlbums onClick={()=> this.setPage('albums')} activepage={this.state.pageToShow}>Albums</MenuItemAlbums>
                                        <MenuItemHost onClick={()=> this.setPage('host')} activepage={this.state.pageToShow}>Host</MenuItemHost>
                                        <MenuItemContact onClick={()=> this.setPage('contact')} activepage={this.state.pageToShow}>Contact</MenuItemContact>
                                    </LeftMenu>
                                
                                    
                                    

                                    <RightArea>
                                                
                                        {/* { this.state.activationError ? ( <MyErrorWrapper><MyError message = {this.state.activationError} /> </MyErrorWrapper> ) : null } */}

                                        { this.state.activationError ? 
                                            <ErrorModal
                                                showErrorModal={this.state.showErrorModal}
                                                closePopup={() => { 
                                                    this.setState({ showErrorModal: false, activationError: '' }) 
                                                    this.setPage(this.state.errorTab);
                                                }}
                                                popTitle={"Your event profile is incomplete. Can't set it visible yet."}
                                                popDescription={this.state.activationError}
                                            />
                                        : null 
                                        }

                                        <div style={{ width: '100%', 'maxWidth': "1200px", top: "0px" }}>
                                        
                                            {
                                                this.state.pageToShow === "details" ? <DetailsPage 
                                                    // showImmediateSaveLabel={this.showImmediateSaveLabel} 
                                                    // showSaveButton={this.showSaveButton}  
                                                    values={values} 
                                                    setField={setFieldValue} />
                                                :
                                                this.state.pageToShow === "description" ? <DescriptionPage 
                                                    // showImmediateSaveLabel={this.showImmediateSaveLabel} 
                                                    // showSaveButton={this.showSaveButton}  
                                                    values={values} 
                                                    setField={setFieldValue}/>
                                                :
                                                this.state.pageToShow === "albums" ? <AlbumsPage 
                                                    // showImmediateSaveLabel={this.showImmediateSaveLabel} 
                                                    showSaveButton={this.showSaveButton}  
                                                    values={values} 
                                                    setField={setFieldValue} />
                                                :
                                                this.state.pageToShow === "host" ? <HostPage 
                                                    // showImmediateSaveLabel={this.showImmediateSaveLabel} 
                                                    // showSaveButton={this.showSaveButton}  
                                                    values={values} 
                                                    setField={setFieldValue} />
                                                :
                                                this.state.pageToShow === "contact" ? <ContactPage 
                                                    values={values} 
                                                    setField={setFieldValue} />
                                                :
                                                <DetailsPage 
                                                    values={values} 
                                                    setField={setFieldValue} />
                                            }
                                                
                                              
                                            
                                            
                                            {
                                                this.state.pageToShow === "details" ? 
                                                    (
                                                        <StyledCancel onClick={this.toggleConfirmDelete}>Delete this event</StyledCancel>
                                                    )
                                                :
                                                <></>
                                            }
                                            {
                                                this.state.showConfirmDelete ?  
                                                    <ConfirmDelete  
                                                        text='Warning! You cannot un-delete or restore the event after it is deleted. Are you sure you would like to delete this event? '
                                                        closePopup={this.toggleConfirmDelete}
                                                        onConfirm={this.deleteProfile}
                                                    />
                                                    : 
                                                    <></>  
                                            }  


                                            <MobileBottomNav>

                                                {
                                                    this.state.pageToShow === "details" ? 
                                                        (<>
                                                        <Button onClick={()=> this.setPage('description')} activepage={this.state.pageToShow}>
                                                            {'Next >>'}
                                                        </Button>
                                                        </>
                                                        )
                                                    :
                                                    this.state.pageToShow === "description" ? 
                                                        (<>
                                                        <Button onClick={()=> this.setPage('details')} activepage={this.state.pageToShow}>
                                                            {'<< Back'}
                                                        </Button>
                                                        <Button onClick={()=> this.setPage('albums')} activepage={this.state.pageToShow}>
                                                            {'Next >>'}
                                                        </Button>
                                                        </>)
                                                    :
                                                    this.state.pageToShow === "albums" ? 
                                                        (<>
                                                        <Button onClick={()=> this.setPage('description')} activepage={this.state.pageToShow}>
                                                            {'<< Back'}
                                                        </Button>
                                                        <Button onClick={()=> this.setPage('host')} activepage={this.state.pageToShow}>
                                                            {'Next >>'}
                                                        </Button>
                                                        </>)
                                                    :
                                                    this.state.pageToShow === "host" ? 
                                                        (<>
                                                        <Button onClick={()=> this.setPage('albums')} activepage={this.state.pageToShow}>
                                                            {'<< Back'}
                                                        </Button>
                                                        <Button onClick={()=> this.setPage('contact')} activepage={this.state.pageToShow}>
                                                            {'Next >>'}
                                                        </Button>
                                                        </>)
                                                    :
                                                    this.state.pageToShow === "contact" ? 
                                                        (<>
                                                        <Button onClick={()=> this.setPage('host')} activepage={this.state.pageToShow}>
                                                            {'<< Back'}
                                                        </Button>
                                                        </>)
                                                    :
                                                    null
                                                }
                                                                                                
                                            </MobileBottomNav>


                                        </div>

                                        

                                    </RightArea>

                                    
                                </PageContent>

                                
                                

                            </Form>

                            

                            
                        )
                        }
                    </Formik>

                    

                    </GeneralWrapper>

                
                    

                </div>

                

            </ProfileExtraWrapper>

        )
    }
}

const ProfileExtraWrapper = styled.div`

    @media (max-width: 1090px) {
        overflow-x: hidden;
    }

`;


const TopBar = styled.div`
    
    position: absolute;
    top: 0;
    right: 0;

    top: 0; 
    height: 96px;
    width: 100%;

    background-color: #fdfdfd;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);
    z-index: 1;

    display: flex;
    flex-direction: row;

    justify-content: space-between;

    @media (max-width: 790px) {
        width: calc(100% + 2px);
        }
`;

const TopBarLeft = styled.div`
    
    flex-grow: 0; 

`;


const TopBarRight = styled.div`
   
    flex-grow: 2;  

    display: flex;
    flex-direction: row;

    text-align: center;
    
    justify-content: center;
    align-items: center;

    min-width: 20%;

    
`;


const CardDiv = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;    

    /* padding-right: 32px; */
    
    margin-left: 16px;
    margin-top: 16px;

    @media (max-width: 790px) {
        flex-direction: start;
        }

`;

const CardTitle = styled.div`
    
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 2px;
    
    line-height: 1.3em;
    max-height: 1.3em;
    max-width: 18em;

    overflow: hidden !important; 
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    @media (max-width: 590px) {
        display: none;
    }

`;

const SubTitle = styled.div`
    
    font-size: 13px;
    font-weight: 300;
    line-height: 1.5em;
    
    @media (max-width: 590px) {
        display: none;
        }
    

`;

const Cover = styled.img`

    height: 64px;
    width: 96px;
    border-radius: 4px;

    object-fit: cover;
    
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.4);

`;


const AvatarArea = styled.div`
    
    display: flex;
    width: 96px; 
    

`;

const MemberInfoCard = styled.div`
    
    padding-left: 16px;
    width: calc(100% - 80px);
    
    
`;


const PageContent = styled.div`
    
    display: flex;

    width: 100%;
    
    padding-bottom: 32px;
    
    
    
`;

const LeftMenu = styled.div`
    
    display: flex;
    flex-direction: column;

    width: 176px; 
    /* height: 100%; */
    font-size: 17px;

    margin-left: 16px;
    margin-top: -16px;
    padding-top: 16px;
    
    background-color: #F9F9F9;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    border-left: 1px solid #D8D8D8;
    border-right: 1px solid #D8D8D8;

    @media (max-width: 790px) {
        display: none;
        border-left: 0px;
        border-right: 0px;
        
    }

`;

const MobileMenu = styled.div`
    
    display: flex;
    flex-direction: column;

    position: fixed;

    left: 64px;
    top: 16px;
    
    width: 184px; 
    font-size: 17px;

    margin-left: 8px;
    padding-top: 16px;
    
    background-color: #F9F9F9;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    border: 1px solid #D8D8D8;

    display: none;
    z-index: 90000000;

    @media (max-width: 790px) {
        display: block;
        left: 88px;
        z-index: 90000000;
    }

`;


const BurgerButton = styled.div`
    
    flex-direction: column;

    margin-top: 8px;
    height: 40px;

    font-size: 28px;

    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;

    /* background-color: #deb; */
    /* box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2); */
    /* border-left: 1px solid #D8D8D8; */

    display: none;
    @media (max-width: 790px) {
        display: block;
        border-left: 0px;
    }

`;

const MobileBurgerButton = styled.div`
    
    flex-direction: column;

    width: 40px; 
    font-size: 24px;

    margin-left: 0px;
    margin-top: -17px;

    padding-top: 24px;
    padding-left: 16px;
    padding-bottom: 16px;
    
    
`;


const MobileBottomNav = styled.div`
    
    justify-content: flex-start;
    
    padding-bottom: 16px;
    padding-top: 16px;

    display: none;

    @media (max-width: 790px) {
        display: flex;
        
    }
`;
  
const ButtonArea = styled.div`

    display: flex;
    align-items: center;

`;

const StyledSave = styled(Button)`
    && {
    min-height: 40px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    color: white;
    
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 16px;


    text-transform: none;
    font-weight: 400;
    font-size: 17px;
        &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }
    }
    
`;

const StyledCancel = styled(Button)`
    && {
    height: 40px;
    margin-top: 16px;

    padding-left: 16px;
    padding-right: 16px;

    margin-bottom: 32px;

    color: #333333;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;

    @media (max-width: 790px) {
        border: 1px solid #efefef;
        box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};
    }

    }
    
`;


const MenuItemDetails = styled.div`
    padding: 8px 16px 8px 16px;
    background-color: ${(props) => props.activepage === 'details' ? "white" : '#F9F9F9'};
    border-top: ${(props) => props.activepage === 'details' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    border-bottom: ${(props) => props.activepage === 'details' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    font-weight: ${(props) => props.activepage === 'details' ? 500 : 400};
    &:hover { text-decoration: underline; cursor: pointer}    
`;
const MenuItemDescription = styled.div`
    padding: 8px 16px 8px 16px;
    background-color: ${(props) => props.activepage === 'description' ? "white" : '#F9F9F9'};
    border-top: ${(props) => props.activepage === 'description' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    border-bottom: ${(props) => props.activepage === 'description' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    font-weight: ${(props) => props.activepage === 'description' ? 500 : 400};
    &:hover { text-decoration: underline; cursor: pointer}    
`;
const MenuItemAlbums = styled.div`
    padding: 8px 16px 8px 16px;
    background-color: ${(props) => props.activepage === 'albums' ? "white" : '#F9F9F9'};
    border-top: ${(props) => props.activepage === 'albums' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    border-bottom: ${(props) => props.activepage === 'albums' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    font-weight: ${(props) => props.activepage === 'albums' ? 500 : 400};
    &:hover { text-decoration: underline; cursor: pointer}    
`;
const MenuItemHost = styled.div`
    padding: 8px 16px 8px 16px;
    background-color: ${(props) => props.activepage === 'host' ? "white" : '#F9F9F9'};
    border-top: ${(props) => props.activepage === 'host' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    border-bottom: ${(props) => props.activepage === 'host' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    font-weight: ${(props) => props.activepage === 'host' ? 500 : 400};
    &:hover { text-decoration: underline; cursor: pointer}    
`;
const MenuItemContact = styled.div`
    padding: 8px 16px 8px 16px;
    background-color: ${(props) => props.activepage === 'contact' ? "white" : '#F9F9F9'};
    border-top: ${(props) => props.activepage === 'contact' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    border-bottom: ${(props) => props.activepage === 'contact' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    font-weight: ${(props) => props.activepage === 'contact' ? 500 : 400};
    &:hover { text-decoration: underline; cursor: pointer}    
`;




const RightArea = styled.div`
   
    flex-direction: column;
    display: flex;
    
    /* width: calc(80% - 128px); */
    width: 100%;
    max-width: 1200px;
   
    padding-left: 32px;
    padding-right: 32px;

    margin-top: -16px;
    margin-left: 16px;

    padding-top: 24px;

    /* border-right: 1px solid #D8D8D8; */
    /* box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2); */

    @media (max-width: 790px) {
        
        padding-left: 24px;
        padding-right: 32px;
        width: calc(100% - 72px);

        /* width: calc(100% - 144px); */
        /* border-right: 0px; */

        }
`;


const GeneralWrapper = styled.div`
    
    white-space: pre-line;
    width: 80%;
    max-width: 1200px;

    margin: 40px auto 32px auto;
    font-family: 'Fira Sans';

    @media (max-width: 790px) {
        width: calc(100% + 74px);
        margin-left: -40px;
    }

`;



const personalInfoFields = [
    'fName', 'lName', 'bDay', 'email', 'phone', 'phoneDisplay', 'phoneCountry', 'avatar', 'isVerified', 'isPro', 'temperror', 'pwdUpdated',
    'chkReminders', 'chkRemindersP', 'chkMessages', 'chkMessagesP', 'chkRecomm', 'chkRecommP', 'chkInsp', 'chkInspP', 'chkLocal', 'chkLocalP', 
    'chkHow', 'chkHowP', 'chkInvite', 'chkInviteP', 'chkSurvey', 'chkSurveyP', 'chkPolicy', 'chkPolicyP', 'chkTips', 'chkTipsP'
]

export const EventForm = withRouter(withStore(withApollo(C), personalInfoFields));
