import React, { PureComponent } from 'react'
import { WithViewModell } from "@psd/controller";

import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";

import ReactGA from 'react-ga4';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

// import { Query } from "react-apollo";
import Modal from 'react-modal';
import Footer from '../../modules/components/Footer/Footer'

import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Carousel from '@brainhubeu/react-carousel';
import './../../styles/carousel.css';
import ClampLines from 'react-clamp-lines';

import 'react-dates/initialize';
import { DayPickerSingleDateController, CalendarDay } from 'react-dates'

import "./../../styles/ReactDatesOverride.css"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import { withStore } from '@spyna/react-store'
import { withApollo } from "react-apollo";
import { withRouter } from 'react-router-dom';
import { ModellBooking } from "./ModellBooking";
import ConfirmDialog from '../components/ConfirmDialog';


import getIPInfo from '../../utils/getIPInfo'
import TimeAgo from './../../utils/TimeAgo'
import ISO6391 from 'iso-639-1';

// import { Mutation } from "react-apollo"
import { Query } from "react-apollo";
import gql from "graphql-tag"
import { CLOUDFRONT_SERVER } from '../../config';

import i18next from "i18next";
import moment from "moment"; 

import toast from 'toasted-notes' 
import 'toasted-notes/src/styles.css';

//UPDATE: For future pagination of reviews, use 
//  https://www.npmjs.com/package/mongoose-paginate-v2
//  https://www.bezkoder.com/node-js-mongodb-pagination/

//right now there is not many reviews, so pagination is not necessary for MVP. 

import { RadioButtonGroup } from 'react-rainbow-components';




const getModellReviews = gql`
    query ($modellId: String!) {
        getModellReviews(modellId: $modellId) {
            dt, from, rating, message, response, 
            bookingId, clientId, clientName, clientAvatar, bookingDt, catNumber, duration
        }
    }
`;


const getModellAlbumPhotos = gql`
    query ($albumId: String!) {
        getModellAlbumPhotos(albumId: $albumId) {
            photoId, albumId, ext, pw, ph, userId, parentId, title { lng, title }, nsfw, position
        }
    }
`;

const addFavorite = gql`
    mutation addFavorite($userId: String, $type: Int, $profileId: String) {
        addFavorite(userId: $userId, type: $type, profileId: $profileId)
    }
`;

const removeFavorite = gql`
    mutation($userId: String, $type: Int, $profileId: String) {
        removeFavorite(userId: $userId, type: $type, profileId: $profileId) 
    }
`;

const isFavorited = gql`
    query($userId: String, $type: Int, $profileId: String) {
        isFavorited(userId: $userId, type: $type, profileId: $profileId) 
    }
`;

const getModellAvailability = gql`
    query($modellId: String, $periodStart: String, $periodEnd: String, $availabilityId: String) {
        getModellAvailability(modellId: $modellId, periodStart: $periodStart, periodEnd: $periodEnd, availabilityId: $availabilityId) { 
            pId, dId, dt, st, et, travel, location { type, coordinates }, addr, addr_locality, addr_area2, addr_area1, country
            }
    }
`;

const getExchangeRate = gql`
    query($base: String, $to: String) {
        getExchangeRate(base: $base, to: $to,) { rate, baseToEUR }
    }
`;


const createMessageMutation = gql`
    mutation createMessage($toType: Int, $toProfileId: String, $message: String) {
        createMessage(toType: $toType, toProfileId: $toProfileId, message: $message) { 
            id, lastMessage, lastMessageDt
        }
    }
`;


const today = new Date();

class C extends PureComponent {

    // imgEl: HTMLImageElement;
    

    constructor(props) {
        
        super(props);

        this.state = {
            
            selectedAlbum: 0,
            selectedAlbumId: '',
            selectedAlbumPhotos: [],
            selectedPhoto: 0,
            selectedPhotoURL: '',
            openPhotoZoom: false,
            albumLoadingFinished: false,

            width: 0,
            height: 0,
            imgWidth: 0,
            imgHeight: 0,
            imgHeightMax: 0,
            imgWidthMax: 0,

            packageIndex: 0,
            showFloatBar: false,
            profileSaved: false,
            confirmLogin: false,
            confirmLoginText: '',
            availabilityRangeStart: moment().format("YYYY-MM-01"),
            availabilityLoadingFinished: false,
            availabilityArray: [],
            travelNotices: [],

            displayCurrency: 'EUR',
            displayCurrencyRate: 0,
            euroRate: 0,

            bookingModalVisible: false,
            messageModalVisible: false,
            messageField: '',
            messageToWhichModell: '',
            bookingPackageId: '',
            bookingPackageDuration: 60,
            bookingDate: '',
            
            metric: 0,
            sizeChart: 0,
            
        }

        this.handleScroll = this.handleScroll.bind(this)
        this.fldValueChange = this.fldValueChange.bind(this);

    }


    fldValueChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({ [name]: value, });
        
    }
    
    selectAlbum = async (index, id) => {

        let albumPhotos = []

        await this.props.client.query({
            query: getModellAlbumPhotos,
            variables: { albumId: id }, 
            fetchPolicy: 'no-cache',
            })
            .then(result => {


                if (result.data.getModellAlbumPhotos.length > 0) {

                    // console.log('----- album selection: result.data: ', result.data)

                    result.data.getModellAlbumPhotos.map((photo, index) => {

                        albumPhotos.push({ 
                            i: index,
                            u: photo.userId,
                            a: photo.albumId,
                            p: photo.photoId+photo.ext,
                            t: photo.title,
                        })

                        return null
                    })
                    
                }
                
            })
            .catch(error => {
                // console.log("--- Error: ", error);
            })



        this.setState({
            selectedAlbum: index,
            selectedAlbumId: id,
            selectedAlbumPhotos: albumPhotos,
            selectedPhoto: 0,
            selectedPhotoURL: albumPhotos.length > 0 ? CLOUDFRONT_SERVER + '/img/L/' + albumPhotos[0].u + '/' + albumPhotos[0].a + '/' + albumPhotos[0].p : '',
            albumLoadingFinished : true,
        }); 

        //const isMobileScreen = window.matchMedia('(max-width: 790px)').matches;
        //if ((isMobileScreen) && (this.props.history.action !== 'POP')) {
        //    window.scrollTo({top: 144, behavior: 'smooth'});
        //} else {
        //    window.scrollTo({top: 96, behavior: 'smooth'});
        //}
        if (this.props.history.action !== 'POP') {
            window.scrollTo({top: 144, behavior: 'smooth'});
        }
        else {
            window.scrollTo({top: 96, behavior: 'smooth'});
        }


    }


    async componentDidMount() {
    
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        // ReactGA.send("pageview");
        
        window.addEventListener('scroll', this.handleScroll);
        

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    
    handleScroll () {

        if (window.scrollY > 400) {

            this.setState({ 
                showFloatBar: true
            });

        } else {
            if (this.state.showFloatBar) {
                this.setState({ 
                    showFloatBar: false
                });
            }
        }

    }


    durationOptions = () => {
        return [
            { value: '15', code: '15', label: '15 min' },
            { value: '20', code: '20', label: '20 min' },
            { value: '30', code: '30', label: '30 min' },
            { value: '45', code: '45', label: '45 min' },
            { value: '60', code: '60', label: '1 hour' },
            { value: '90', code: '90', label: '1.5 hours' },
            { value: '120', code: '120', label: '2 hours' },
            { value: '150', code: '150', label: '2.5 hours' },
            { value: '180', code: '180', label: '3 hours' },
            { value: '210', code: '210', label: '3.5 hours' },
            { value: '240', code: '240', label: '4 hours' },
            { value: '300', code: '300', label: '5 hours' },
            { value: '360', code: '360', label: '6 hours' },
            { value: '420', code: '420', label: '7 hours' },
            { value: '480', code: '480', label: '8 hours' },
            { value: '540', code: '540', label: '9 hours' },
            { value: '600', code: '600', label: '10 hours' },
            { value: '660', code: '660', label: '11 hours' },
            { value: '720', code: '720', label: '12 hours' },
            { value: '1440', code: '1440', label: 'All day coverage' },
        ]
    }

    modellCategoryToString = () => {
        return [
            
            //value = category, code = position
            { value: '1', code: '14', label: 'Boudoir photoshoot' },
            { value: '2', code: '3', label: 'Couples photoshoot' },
            { value: '3', code: '2', label: 'Family photoshoot' },
            { value: '4', code: '13', label: 'Fashion photoshoot' },
            { value: '5', code: '10', label: 'Kids photoshoot' },
            { value: '6', code: '12', label: 'Lifestyle photoshoot' },
            { value: '7', code: '9', label: 'Newborn photoshoot' },
            { value: '11', code: '15', label: 'Nude photoshoot' },
            { value: '8', code: '1', label: 'Portrait photoshoot' },
            { value: '9', code: '8', label: 'Pregnancy photoshoot' },
            { value: '10', code: '5', label: 'Wedding photoshoot' },
            { value: '32', code: '16', label: 'Architecture photoshoot' },
            { value: '33', code: '4', label: 'Event photoshoot' },
            { value: '34', code: '17', label: 'Food photoshoot' },
            { value: '28', code: '18', label: 'Landscape photoshoot' },
            { value: '29', code: '19', label: 'Nature photoshoot' },
            { value: '30', code: '7', label: 'Pets photoshoot' },
            { value: '35', code: '11', label: 'Product photoshoot' },
            { value: '36', code: '6', label: 'Real estate photoshoot' },
            { value: '37', code: '20', label: 'Sport photoshoot' },
            { value: '38', code: '21', label: 'Street photoshoot' },
            { value: '31', code: '22', label: 'Wildlife photoshoot' },
            { value: '12', code: '23', label: 'Acting photoshoot' },
            { value: '13', code: '24', label: 'Art photoshoot' },
            { value: '14', code: '25', label: 'Bodypaint photoshoot' },
            { value: '15', code: '26', label: 'Cosplay photoshoot' },
            { value: '16', code: '27', label: 'Dance photoshoot' },
            { value: '17', code: '28', label: 'Editorial photoshoot' },
            { value: '18', code: '29', label: 'Fetish photoshoot' },
            { value: '19', code: '30', label: 'Fitness photoshoot' },
            { value: '20', code: '31', label: 'Lingerie photoshoot' },
            { value: '23', code: '33', label: 'Promotional photoshoot' },
            { value: '24', code: '34', label: 'Runway photoshoot' },
            { value: '25', code: '35', label: 'Swimwear photoshoot' },
            { value: '26', code: '36', label: 'Topless photoshoot' },
            { value: '27', code: '37', label: 'Underwater photoshoot' },
            
            
        ]
    }

    

    
    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    localeUses24HourTime = (locale) => {

        // if locale === undefined => current user's locale, otherwise uses "locale"

        //returns true, if 24H time is used in this locale/language
        return new Intl.DateTimeFormat(locale, { hour: 'numeric' })
            .formatToParts(new Date(2020, 0, 1, 13))
            .find(part => part.type === 'hour')
            .value.length === 2;

    }

    moveIndex = (index) => {
        
        this.setState({
            packageIndex: this.state.packageIndex + index,
        });   

    }

    generateArrow = (direction, disabled = false) => {
        
        const indexChange = direction === 'left' ? -1 : 1

        if (direction === 'left') {

            return (
                <GreyButtonCarousel 
                    onClick={() => this.moveIndex(indexChange) }
                    disabled={disabled} >
                    <FAIcon icon={faChevronLeft} color={ disabled ? '#fff' : '#000'} />
                </GreyButtonCarousel>
            )

        } else {

            return (
                <GreyButtonCarousel 
                    onClick={() => this.moveIndex(indexChange) }
                    disabled={disabled} >
                    <FAIcon icon={faChevronRight} color={ disabled ? '#fff' : '#000'} />
                </GreyButtonCarousel>
            )

        }

    }

    isDayBlocked = (day) => {

        // console.log('blocked.day', day)
        // console.log(' ----- ---  --- --- isDayBlocked.availabilityArray', this.state.availabilityArray)

        // example:
            // if (day.format('ddd') === 'Mon' && ['Jul', 'Aug'].includes(day.format('MMM')))  {
            //     return true
            // }            
            // return false
        
        if (this.state.availabilityArray.length > 0) {

            return !this.state.availabilityArray.some(date => day.isSame(date, 'day'));
            
        } else {

            return false

        }

            

    }

    isOutside = momentDate => {

        if (momentDate < moment(today).subtract(1, 'd'))
            return true
        
        return false
    }

    
    updateFavorites = async (modellId) => {

        if (this.props.store.get("userId") === undefined) {
            
            setTimeout(() => { 
                this.setState({ 
                    confirmLogin: true,
                    confirmLoginText: 'To save this model to your favorites you need to be logged in. Would you like to log in and return to this profile?'
                }) 
            }, 50);

        } else {

            const isSaved = this.state.profileSaved;
            
            if (isSaved) {
            
                await this.props.client.mutate({
                    mutation: removeFavorite,
                    variables: { userId: this.props.store.get("userId"), type: 2, profileId: modellId },
                }).then(results => {
                    // console.log('#mutation result ', results)
                }).catch(error => {
                    // console.log("Error: ", error);
                })

            } else {
                
                await this.props.client.mutate({
                    mutation: addFavorite,
                    variables: { userId: this.props.store.get("userId"), type: 2, profileId: modellId },
                }).then(results => {
                    // console.log('#mutation result ', results)
                }).catch(error => {
                    // console.log("Error: ", error);
                })
                
            }

            setTimeout(() => { 
                this.setState({ 
                    profileSaved: !this.state.profileSaved 
                }) 
            }, 50);

        }


    }

    startBooking = async (modellId) => {

        if (this.props.store.get("userId") === undefined) {
            
            setTimeout(() => { 
                this.setState({ 
                    confirmLogin: true,
                    confirmLoginText: 'To book a photoshoot you need to be logged in. Would you like to log in and return to this profile?'
                }) 
            }, 50);

        } else {

            setTimeout(() => { 
                this.setState({ 
                    bookingModalVisible: true
                }) 
            }, 50);

        }


    }

    startMessage = async (modellId) => {

        if (this.props.store.get("userId") === undefined) {
            
            setTimeout(() => { 
                this.setState({ 
                    confirmLogin: true,
                    confirmLoginText: 'To message a model you need to be logged in. Would you like to log in and return to this profile?'
                }) 
            }, 50);

        } else {

            setTimeout(() => { 
                this.setState({ 
                    messageModalVisible: true,
                    messageToWhichModell: modellId
                }) 
            }, 50);


        }


    }


    postMessage = async (modellId) => {

        let resultOK = false

        await this.props.client
            .mutate({
                mutation: createMessageMutation,
                variables: {
                    toType: 1, //0 = photog, 1=modell, 2 = muah,  3 = studio, 4 = event
                    toProfileId: modellId,
                    message: this.state.messageField,
                },
            })
            .then(results => {

                // console.log(results)
                
                if (JSON.stringify(results).length > 15) {

                    resultOK = true

                } else {
                    toast.notify(
                        <div style={{ "color": "red" }}>Error #11542. Unable to send a message. Please send us the error number so we could fix it.</div>, 
                        { duration: 10000 }
                        );
                }
                
            })
            .catch(error => {
                
                toast.notify(
                    <div style={{ "color": "red" }}>Error #1154. Unable to send a message. Please send us the error number so we could fix it.</div>, 
                    { duration: 10000 }
                    );
                
                // console.log("Error: ", error);
            })
    

        if (resultOK) { 

            setTimeout(() => { 
                this.setState({ 
                    messageModalVisible: false
                }) 
            }, 50);

            //window.location.reload();
            this.props.history.push({ pathname: '../inbox/' })
            // this.props.history.push({ pathname: '/' })
        }

    }


    loadAvailabilityData = async (profileId) => {

        setTimeout(() => { this.setState({ availabilityLoadingFinished: true, }); }, 15);

        let availabilityArray = []
        let travelNotices = []

        await this.props.client.query({ 
            query: getModellAvailability, 
            variables: { 
                modellId: profileId,  
                periodStart: moment(this.state.availabilityRangeStart).format('YYYY-MM-DD'),
                periodEnd: moment(this.state.availabilityRangeStart).add(2, 'M').format('YYYY-MM-DD'),   
            }, 
            fetchPolicy: 'no-cache',})
        .then(results => {

            if (JSON.stringify(results).length > 25) {

                const data = results.data.getModellAvailability;
                for (var q = data.length-1; q >= 0; q--) {
                    let isoDate = new Date(parseInt(data[q].dt))
                    availabilityArray.push(moment(isoDate).format('YYYY-MM-DD'));

                    if (data[q].travel) {
                        travelNotices.push(moment(isoDate).format('LL') + ': ' + data[q].addr);
                    }
                    
                } 
                
            }

        })
        .catch(error => { 
            
            // console.log(error) 

        })

        //console.log('travelNotices', travelNotices)
        setTimeout(() => { this.setState({ availabilityArray: availabilityArray, travelNotices: travelNotices}); }, 50);

        return availabilityArray;

    }


    loadPreferredCurrency = async () => {

        let displayCurrency = 'EUR'

        let userHasCurrencyPreference = false;

        // console.log('store.currency: ', this.props.store.get('currency'))

        if (this.props.store.get('currency')) {
            userHasCurrencyPreference = true;
        }

        if (userHasCurrencyPreference) {
            
            displayCurrency = this.props.store.get('currency')

        } else {

            if ((localStorage.getItem('ipCurrency') !== '') && (localStorage.getItem('ipCurrency') !== 'null')) {

                displayCurrency = localStorage.getItem('ipCurrency')

            } else {

                let ipData
                const today = new Date();

                ipData = await getIPInfo(); 
                
                let ipAddress
                if (ipData.city !== ipData.region) {
                    ipAddress = ipData.city + ', ' + ipData.region
                } else {
                    ipAddress = ipData.city
                }
                ipAddress = ipAddress + ', ' + ipData.country

                //save ip date to the local storage immediatelly after geolocated!
                localStorage.setItem('ipCity', ipData.city);
                localStorage.setItem('ipRegion', ipData.region);
                localStorage.setItem('ipCountry', ipData.country);
                localStorage.setItem('ipCurrency', ipData.currency);
                localStorage.setItem('ipLng', ipData.lng);
                localStorage.setItem('ipLat', ipData.lat);
                localStorage.setItem('ipAddress', ipAddress);
                localStorage.setItem('ipLocatedDate', moment(today).format('YYYY-MM-DD'));
                displayCurrency = ipData.currency
            
            }

        }

        this.setState({ 
            displayCurrency: displayCurrency,
        });

    }


    loadCurrencyRates = async (base, to) => {

        let displayCurrencyRate = 0;
        let euroRate = 0;
        
        // convert user package currency to visitor preferred currency
        await this.props.client.query({ 
            query: getExchangeRate, variables: { base, to }, fetchPolicy: 'no-cache',
        })
        .then(results => {

            if (JSON.stringify(results).length > 25) {
                displayCurrencyRate = results.data.getExchangeRate.rate;
                euroRate = results.data.getExchangeRate.baseToEUR
            }

        })
        .catch(error => { 
            // console.log(error) 
        })

        setTimeout(() => { this.setState({ 
            displayCurrencyRate: displayCurrencyRate, 
            euroRate: euroRate,
        }); }, 50);


    }

    mmToImperial = mm => {

        const tempArr = [
            { value: 12192, code: 12192, label: `4' 0"` },
            { value: 12446, code: 12446, label: `4' 1"` },
            { value: 12700, code: 12700, label: `4' 2"` },
            { value: 12954, code: 12954, label: `4' 3"` },
            { value: 13208, code: 13208, label: `4' 4"` },
            { value: 13462, code: 13462, label: `4' 5"` },
            { value: 13716, code: 13716, label: `4' 6"` },
            { value: 13970, code: 13970, label: `4' 7"` },
            { value: 14224, code: 14224, label: `4' 8"` },
            { value: 14478, code: 14478, label: `4' 9"` },
            { value: 14732, code: 14732, label: `4' 10"` },
            { value: 14986, code: 14986, label: `4' 11"` },

            { value: 15240, code: 15240, label: `5' 0"` },
            { value: 15494, code: 15494, label: `5' 1"` },
            { value: 15748, code: 15748, label: `5' 2"` },
            { value: 16002, code: 16002, label: `5' 3"` },
            { value: 16256, code: 16256, label: `5' 4"` },
            { value: 16510, code: 16510, label: `5' 5"` },
            { value: 16764, code: 16764, label: `5' 6"` },
            { value: 17018, code: 17018, label: `5' 7"` },
            { value: 17272, code: 17272, label: `5' 8"` },
            { value: 17526, code: 17526, label: `5' 9"` },
            { value: 17780, code: 17780, label: `5' 10"` },
            { value: 18034, code: 18034, label: `5' 11"` },
            
            { value: 18288, code: 18288, label: `6' 0"` },
            { value: 18542, code: 18542, label: `6' 1"` },
            { value: 18796, code: 18796, label: `6' 2"` },
            { value: 19050, code: 19050, label: `6' 3"` },
            { value: 19304, code: 19304, label: `6' 4"` },
            { value: 19558, code: 19558, label: `6' 5"` },
            { value: 19812, code: 19812, label: `6' 6"` },
            { value: 20066, code: 20066, label: `6' 7"` },
            { value: 20320, code: 20320, label: `6' 8"` },

        ];

        let imperialHeight = tempArr[0].label; //default = min length. assuming we will find actual match below...
        for (var i = tempArr.length-1; i >= 0; i--) {
            if (i < tempArr.length-1) {
                // console.log(heightInMm + ' ' + tempArr[i+1].value + ' ' + tempArr[i].value)
                if (mm <= tempArr[i+1].value && mm > tempArr[i].value) {
                    imperialHeight = tempArr[i+1].label
                }
            }
        }

        return imperialHeight

    }
    
    render() {

        // const availabilityArr = [
        //     { value: 0, code: 0, label: 'Not available at this moment' },
        //     { value: 1, code: 1, label: 'Available to everyone' },
        //     { value: 2, code: 2, label: 'Only for members with an active profile' },
        //     { value: 3, code: 3, label: 'Only for Pro members with an active profile' },
        //     { value: 4, code: 4, label: 'Only for Verified Pro members with an active profile' },
        // ];

        
        // const distanceOptions = [
        //     { value: '0', label: 'km', disabled: this.state.selectedPackageTravel === false },
        //     { value: '1', label: 'miles', disabled: this.state.selectedPackageTravel === false },
        // ];

        // const startOfWeek = moment().startOf('week').isoWeekday();  // const endOfWeek   = moment().endOf('week').isoWeekday();
        
        // let weekdayArr = []
        //             if (startOfWeek === 1) { 
        //                 weekdayArr = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        //             } else {
        //                 weekdayArr = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        //             }

        // const use24h = this.localeUses24HourTime(); 

        // const isMobileScreen = window.matchMedia('(max-width: 790px)').matches;
        
        
        moment.locale(i18next.language);

        let profileId = '';
        if (this.props.match.params.profileId) {
            profileId = this.props.match.params.profileId
        } else {
            profileId = this.props.profileId
        }
        
        const userId = this.props.store.get("userId");

        
        this.props.client.query({
            query: isFavorited,
            variables: { userId: userId, type: 2, profileId },
            fetchPolicy: 'no-cache',
        }).then(results => {
            
            // console.log('#mutation result ', results)
            if (results.data.isFavorited === true) {
                this.setState({ 
                    profileSaved: true
                })
            }

        }).catch(error => {
            // console.log("Error: ", error);
        })
  

        if (this.state.availabilityLoadingFinished === false) {
            this.loadAvailabilityData(profileId)
        }

       
        const bookingModalStyles = {
            overlay: { zIndex: 2000, },
            content: {

                top: `0`,
                left: '0',
                border: '0px',
                borderRadius: '0',
                
                height: `100vh`,
                width: `100%`,

                paddingTop: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                                                    
                
            },

            
        }

        const bookingModalStylesMobile = {
            overlay: { zIndex: 1000 },
            content: {
                
                margin: 'auto',
                bottom: 'auto',
                overflow : 'auto',

                top: `0`,
                left: '0',
                border: '0px',
                borderRadius: '0',
                
                height: `100vh`,
                width: `100%`,

                paddingTop: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                 

            }
        }


        const worksWith = [
            { value: '0', label: 'Photographers from any gender' },
            { value: '1', label: 'Women only' },
            { value: '2', label: 'Men only' },
        ];

        const unitOptions = [
            { value: '0', label: 'Metric', disabled: false },
            { value: '1', label: 'Imperial', disabled: false },
        ];

        const chartOptionsWomen = [
            { value: '0', label: 'US', disabled: false },
            { value: '1', label: 'UK', disabled: false },
            { value: '2', label: 'EU', disabled: false },
            { value: '3', label: 'JP', disabled: false },
            // { value: '3', label: 'FR', disabled: false },
            // { value: '4', label: 'DE', disabled: false },
            // { value: '5', label: 'IT', disabled: false },
        ];
        const chartOptionsMen = [
            { value: '0', label: 'US', disabled: false },
            { value: '1', label: 'UK', disabled: false },
            { value: '2', label: 'EU', disabled: false },
            { value: '3', label: 'JP', disabled: false },
        ];

        const physiqueOptions = [
            { value: '0', label: 'Very slim', disabled: false },
            { value: '1', label: 'Slim', disabled: false },
            { value: '2', label: 'Normal', disabled: false },
            { value: '3', label: 'Curvy', disabled: false },
            { value: '4', label: 'Very curvy', disabled: false },
        ];
        const cupOptions = [
            { value: '0', label: 'AA', disabled: false },
            { value: '1', label: 'A', disabled: false },
            { value: '2', label: 'B', disabled: false },
            { value: '3', label: 'C', disabled: false },
            { value: '4', label: 'D', disabled: false },
            { value: '5', label: 'E / DD', disabled: false },
            { value: '6', label: 'F / DDD', disabled: false },
            { value: '7', label: 'G / DDDD', disabled: false },
            { value: '8', label: 'H', disabled: false },
            { value: '9', label: 'I', disabled: false },
            { value: '10', label: 'J', disabled: false },
            { value: '11', label: 'K', disabled: false },
        ];
        const eyeColorOptions = [
            { value: '0', label: 'Black', disabled: false },
            { value: '1', label: 'Blue', disabled: false },
            { value: '2', label: 'Brown', disabled: false },
            { value: '3', label: 'Green', disabled: false },
            { value: '4', label: 'Grey', disabled: false },
            { value: '5', label: 'Hazel', disabled: false },
            { value: '6', label: 'Other', disabled: false },
        ];         
        const hairLengthOptions = [
            { value: '0', label: 'Bald', disabled: false },
            { value: '1', label: 'Short', disabled: false },
            { value: '2', label: 'Chin-length', disabled: false },
            { value: '3', label: 'Shoulder-length', disabled: false },
            { value: '4', label: 'Long', disabled: false },
            { value: '5', label: 'Very long', disabled: false },
        ];    
        const hairColorOptions = [
            { value: '0', label: 'Black', disabled: false },
            { value: '1', label: 'Blonde', disabled: false },
            { value: '2', label: 'Blonde (light)', disabled: false },
            { value: '3', label: 'Blue', disabled: false },
            { value: '4', label: 'Brown', disabled: false },
            { value: '5', label: 'Brown (dark)', disabled: false },
            { value: '6', label: 'Brown (light)', disabled: false },
            { value: '7', label: 'Green', disabled: false },
            { value: '8', label: 'Grey', disabled: false },
            { value: '9', label: 'Highlighted', disabled: false },
            { value: '10', label: 'Orange', disabled: false },
            { value: '11', label: 'Pink', disabled: false },
            { value: '12', label: 'Purple', disabled: false },
            { value: '13', label: 'Red', disabled: false },
            { value: '14', label: 'Salt and pepper', disabled: false },
            { value: '15', label: 'White', disabled: false },
            { value: '16', label: 'Yellow', disabled: false },
            { value: '17', label: 'Other', disabled: false },

        ];    
        const noneSomeManyOptions = [
            { value: '0', label: 'None', disabled: false },
            { value: '1', label: 'Some', disabled: false },
            { value: '2', label: 'Many', disabled: false },
        ];    
        const skinOptions = [
            { value: '0', label: 'Black', disabled: false },
            { value: '1', label: 'Brown', disabled: false },
            { value: '2', label: 'Olive', disabled: false },
            { value: '3', label: 'Other', disabled: false },
            { value: '4', label: 'Tanned', disabled: false },
            { value: '5', label: 'White', disabled: false },
            { value: '6', label: 'White (pale)', disabled: false },
        ]; 

        const dressEUw = [
            { value: 0, label: '-' },
            { value: 1, label: '30 (XXS)' },
            { value: 2, label: '32 (XS)' },
            { value: 3, label: '34 (XS)' },
            { value: 4, label: '36 (S)' },
            { value: 5, label: '38 (S)' },
            { value: 6, label: '40 (M)' },
            { value: 7, label: '42 (M)' },
            { value: 8, label: '44 (L)' },
            { value: 9, label: '46 (L)' },
            { value: 10, label: '48 (XL)' },
            { value: 11, label: '50 (XL)' },
            { value: 12, label: '52 (XXL)' },
            { value: 13, label: '54 (XXL)' },
        ];
        
        const dressUSw = [
            { value: 0, label: '-'},
            { value: 1, label: '0 (XX)'},
            { value: 2, label: '2 (XS)'},
            { value: 3, label: '4 (S)'},
            { value: 4, label: '6 (S)'},
            { value: 5, label: '8 (M)'},
            { value: 6, label: '10 (M)'},
            { value: 7, label: '12 (L)'},
            { value: 8, label: '14 (L)'},
            { value: 9, label: '16 (XL)'},
            { value: 10, label: '18 (XL)'},
            { value: 11, label: '20 (2X)'},
            { value: 12, label: '22 (3X)'},
            { value: 13, label: '24 (4X)'},
        ]
    
        const dressUKw  = [
            { value: 0, label: '-' },
            { value: 1, label: '2' },
            { value: 2, label: '4' },
            { value: 3, label: '6' },
            { value: 4, label: '8' },
            { value: 5, label: '10' },
            { value: 6, label: '12' },
            { value: 7, label: '14' },
            { value: 8, label: '16' },
            { value: 9, label: '18' },
            { value: 10, label: '20' },
            { value: 11, label: '22' },
            { value: 12, label: '24' },
            { value: 13, label: '26' },
        ];

        const dressUSm = [
            { value: 0, label: '-' },
            { value: 1, label: '30' },
            { value: 2, label: '32' },
            { value: 3, label: '34' },
            { value: 4, label: '36' },
            { value: 5, label: '38' },
            { value: 6, label: '40' },
            { value: 7, label: '42' },
            { value: 8, label: '44' },
            { value: 9, label: '46' },
            { value: 10, label: '48' },
            { value: 11, label: '50' },
            { value: 12, label: '52' },
            { value: 13, label: '54' },
            { value: 14, label: '58' },

        ];
        
    
        const dressJPw = [
            { value: 0, label: '-' },
            { value: 1, label: '5' },
            { value: 2, label: '7' },
            { value: 3, label: '9' },
            { value: 4, label: '11' },
            { value: 5, label: '13' },
            { value: 6, label: '15' },
            { value: 7, label: '17' },
            { value: 8, label: '19' },
            { value: 9, label: '21' },
            { value: 10, label: '23' },
            { value: 11, label: '25' },
            { value: 12, label: '27' },
            { value: 13, label: '29' },
        ];
    
        const dressUKm = [
            { value: 0, label: '-' },
            { value: 1, label: '30' },
            { value: 2, label: '32' },
            { value: 3, label: '34' },
            { value: 4, label: '36' },
            { value: 5, label: '38' },
            { value: 6, label: '40' },
            { value: 7, label: '42' },
            { value: 8, label: '44' },
            { value: 9, label: '46' },
            { value: 10, label: '48' },
            { value: 11, label: '50' },
            { value: 12, label: '52' },
            { value: 13, label: '54' },
            { value: 14, label: '58' },

        ];
    
        const dressEUm  = [
            { value: 0, label: '-' },
            { value: 1, label: '40 (XS)' },
            { value: 2, label: '42 (XS)' },
            { value: 3, label: '44 (S)' },
            { value: 4, label: '46 (S)' },
            { value: 5, label: '48 (M)' },
            { value: 6, label: '50 (M)' },
            { value: 7, label: '52 (L)' },
            { value: 8, label: '54 (L)' },
            { value: 9, label: '56 (XL)' },
            { value: 10, label: '58 (XL)' },
            { value: 11, label: '60 (2XL)' },
            { value: 12, label: '62 (2XL)' },
            { value: 13, label: '64 (3XL)' },
            { value: 14, label: '66 (3XL)' },

        ];
    
        const dressJPm  = [
            { value: 0, label: '-' },
            { value: 1, label: '32' },
            { value: 2, label: '34' },
            { value: 3, label: '36' },
            { value: 4, label: '38' },
            { value: 5, label: '40' },
            { value: 6, label: '42' },
            { value: 7, label: '44' },
            { value: 8, label: '46' },
            { value: 9, label: '48' },
            { value: 10, label: '50' },
            { value: 11, label: '52' },
            { value: 12, label: '54' },
            { value: 13, label: '56' },
            { value: 14, label: '58' },

        ];
        
    
        const shoesUSw = [
            { value: 0, label: '-' },
            { value: 1, label: '4' },
            { value: 2, label: '5' },
            { value: 3, label: '6' },
            { value: 4, label: '7' },
            { value: 5, label: '8' },
            { value: 6, label: '9' },
            { value: 7, label: '10' },
            { value: 8, label: '11' },
            { value: 9, label: '12' },
            { value: 10, label: '13' },
            { value: 11, label: '14' },
        ];
        
        const shoesUKw  = [
            { value: 0, label: '-' },
            { value: 1, label: '2' },
            { value: 2, label: '3' },
            { value: 3, label: '4' },
            { value: 4, label: '5' },
            { value: 5, label: '6' },
            { value: 6, label: '7' },
            { value: 7, label: '8' },
            { value: 8, label: '9' },
            { value: 9, label: '10' },
            { value: 10, label: '11' },
            { value: 11, label: '12' },
        ];

        const shoesEUw  = [
            { value: 0, label: '-' },
            { value: 1, label: '35' },
            { value: 2, label: '36' },
            { value: 3, label: '37' },
            { value: 4, label: '38' },
            { value: 5, label: '39' },
            { value: 6, label: '40' },
            { value: 7, label: '41' },
            { value: 8, label: '42' },
            { value: 9, label: '43' },
            { value: 10, label: '44' },
            { value: 11, label: '45' },
        ];
        
        const shoesJPw  = [
            { value: 0, label: '-' },
            { value: 1, label: '21' },
            { value: 2, label: '22' },
            { value: 3, label: '23' },
            { value: 4, label: '24' },
            { value: 5, label: '25' },
            { value: 6, label: '26' },
            { value: 7, label: '27' },
            { value: 8, label: '28' },
            { value: 9, label: '29' },
            { value: 10, label: '30' },
            { value: 11, label: '31' },
        ];
    
        const shoesUSm  = [
            { value: 0, label: '-' },
            { value: 1, label: '5' },
            { value: 2, label: '6' },
            { value: 3, label: '7' },
            { value: 4, label: '8' },
            { value: 5, label: '9' },
            { value: 6, label: '10' },
            { value: 7, label: '11' },
            { value: 8, label: '12' },
            { value: 9, label: '13' },
            { value: 10, label: '14' },
            { value: 11, label: '15' },
        ];
        
        const shoesUKm  = [
            { value: 0, label: '-' },
            { value: 1, label: '4' },
            { value: 2, label: '5' },
            { value: 3, label: '6' },
            { value: 4, label: '7' },
            { value: 5, label: '8' },
            { value: 6, label: '9' },
            { value: 7, label: '10' },
            { value: 8, label: '11' },
            { value: 9, label: '12' },
            { value: 10, label: '13' },
            { value: 11, label: '14' },
        ];
        
        const shoesEUm  = [
            { value: 0, label: '-' },
            { value: 1, label: '38' },
            { value: 2, label: '39' },
            { value: 3, label: '40' },
            { value: 4, label: '41' },
            { value: 5, label: '42' },
            { value: 6, label: '43' },
            { value: 7, label: '44' },
            { value: 8, label: '45' },
            { value: 9, label: '46' },
            { value: 10, label: '47' },
            { value: 11, label: '48' },
        ];
        
        const shoesJPm  = [
            { value: 0, label: '-' },
            { value: 1, label: '22' },
            { value: 2, label: '23' },
            { value: 3, label: '24' },
            { value: 4, label: '25' },
            { value: 5, label: '26' },
            { value: 6, label: '27' },
            { value: 7, label: '28' },
            { value: 8, label: '29' },
            { value: 9, label: '30' },
            { value: 10, label: '31' },
            { value: 11, label: '32' },
        ];
              


        return (
            
            <WithViewModell
            
                profileId={profileId}
                
            >

                {

                    data => {
                    
                        
                        if (data.modell.length === 0) {
                            return (
                                <div className="bodywrapper">
                                <div className="settingsWrapper">
                                    <div className='settingsHeader'>Loading ...</div>
                                </div>
                                </div>
                                )
                        }

                        if (data.modell.packages.length > 0) { 
                         
                            this.loadPreferredCurrency();
                            this.loadCurrencyRates(data.modell.packages[0].currency, this.state.displayCurrency);

                        }

                        if (this.state.selectedAlbumId === '' && data.modell.albums.length > 0) {

                            this.selectAlbum(0, data.modell.albums[0].id)
                            
                        } else {

                            setTimeout(() => { this.setState({ albumLoadingFinished: true }) }, 50);
                            
                        }

                        // console.log('ViewModell data', data)
                        // console.log('ViewModell this.state', this.state);

                        if (data.modell.status === 0) { 
                            //0 = Inactive & hidden
                            //this.props.history.push({ pathname: '../models' })
                            return (
                                <div className="bodywrapper">
                                <div className="settingsWrapper">
                                    <div className='settingsHeader'>Sorry, this profile is not available ...</div>
                                </div>
                                </div>
                                )
                        } 

                        //if (data.modell.status === 1) { 
                        //   //1 = Visible to everyone
                        //} 

                        if ((data.modell.status === 2) && (this.props.userId === undefined)) { 
                            //2 = Visible to registered users
                            //this.props.history.push({ pathname: '../models' })
                            return (
                                <div className="bodywrapper">
                                <div className="settingsWrapper">
                                    <div className='settingsHeader'>Sorry, this profile is not available ...</div>
                                </div>
                                </div>
                                )
                        } 

                        if ((data.modell.status === 3) && (this.props.store.get("isPro") !== true)) { 
                            //3 = Visible to Pro users
                            //this.props.history.push({ pathname: '../models' })
                            return (
                                <div className="bodywrapper">
                                <div className="settingsWrapper">
                                    <div className='settingsHeader'>Sorry, this profile is not available ...</div>
                                </div>
                                </div>
                                )
                        }

                        if ((data.modell.status === 4) && ((this.props.store.get("isPro") !== true) || (this.props.store.get("isVerified") !== true))) {  
                            //4 = Visible to Verified Pro users
                            //this.props.history.push({ pathname: '../models' })
                            return (
                                <div className="bodywrapper">
                                <div className="settingsWrapper">
                                    <div className='settingsHeader'>Sorry, this profile is not available ...</div>
                                </div>
                                </div>
                                )
                            
                        }

                        const cleanIntro = DOMPurify.sanitize(data.modell.intro[0].intro);
                        const introDisplay = parse(cleanIntro);

                        let numberOfStars = '⭐⭐⭐⭐⭐';
                        if (data.modell.avgRating <= 5) { numberOfStars = '⭐⭐⭐⭐⭐' }
                        if (data.modell.avgRating < 4.5) { numberOfStars = '⭐⭐⭐⭐☆' }
                        if (data.modell.avgRating < 3.5) { numberOfStars = '⭐⭐⭐☆☆' }
                        if (data.modell.avgRating < 2.5) { numberOfStars = '⭐⭐☆☆☆' }
                        if (data.modell.avgRating < 1.5) { numberOfStars = '⭐☆☆☆☆' }
                      
                        
                        const isVerified = data.modell.isVerified
                        const isTopRated = data.modell.isTopRated
                        const isPro = data.modell.isPro
                        const isTop2023 = data.modell.isTop2023
                        const isFoundingMember = data.modell.isFoundingMember

                        let profileHeadline = data.modell.intro[0].headline ? data.modell.intro[0].headline : "";
                        if (profileHeadline.length < 5) { profileHeadline = "Model | " + data.modell.name }
                        profileHeadline = "Model | " + data.modell.name

                        let metaDescription = data.modell.intro[0].summary ? data.modell.intro[0].summary : "";
                        if (metaDescription.length < 5) { metaDescription = "Model " + data.modell.name + ' from ' + data.modell.addr_locality + ', ' + data.modell.country }

                        let metaKeywords = 'photosouls, profile, model, ' + data.modell.name + ', ' + data.modell.addr_locality + ', ' + data.modell.country

                        return (

                            <ExtraWrapper>

                            <Helmet>
                                <title>{profileHeadline}</title>
                                <meta name="description" content={metaDescription} />
                                <meta name="keywords" content={metaKeywords} />
                            </Helmet>
                                
                                
                            {
                                this.props.history.action === 'POP' ?
                                    null
                                : 
                                <HistoryBackButton onClick={ () => { this.props.history.goBack();  } } >
                                    <FAIcon icon={faChevronLeft} color={'#000'} />
                                </HistoryBackButton>
                            }


                                <ProfileTopBar>

                                    <ProfileBarLeft>

                                        <CardDiv>
                                            
                                            <AvatarArea>
                                                { 
                                                    data.modell.avatar ? 
                                                        <Avatar 
                                                            src={data.modell.avatar} 
                                                            alt=" " 
                                                            onLoad={(e) => {
                                                                //e.target.src = values.avatar --- causes huge CPU spike. but looks like not necessary anyway!
                                                                e.target.style = 'display: flex' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                                            }}
                                                            onError={(e) => {
                                                                //e.target.src = '' /--- causes huge CPU spike. but looks like not necessary anyway!
                                                                e.target.style = 'display: none' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                                            }}

                                                        /> : <></>
                                                }
                                            </AvatarArea>

                                            <MemberInfoCard>

                                                <CardTitle>{data.modell.name}{ isVerified ? <VerifiedBadge src={require('./../../assets/badges/verified-48px.png')} alt="is verified user" /> : null }</CardTitle>
                                                <SubTitle>{TimeAgo(data.modell.createdAt)},</SubTitle>
                                                <SubTitle>
                                                    { data.modell.address }
                                                </SubTitle>
                                                
                                            </MemberInfoCard>

                                            <MemberBadgesRow>
                                                { isTopRated ? <BtnIconNoMargin src={require('./../../assets/badges/toprated-48px.png')} alt="" /> : null }
                                                { isPro ? <BtnIconNoMargin src={require('./../../assets/badges/pro-48px.png')} alt="" /> : null }
                                                { isTop2023 ? <BtnIconNoMargin src={require('./../../assets/badges/toppro2023-48px.png')} alt="" /> : null }
                                                { isFoundingMember ? <BtnIconNoMargin src={require('./../../assets/badges/founding-48px.png')} alt="" /> : null }                                                     
                                            </MemberBadgesRow>

                                       

                                        </CardDiv>

                                    </ProfileBarLeft>

                                    <ProfileBarCenter>
                                        { isTopRated ? <Badge src={require('./../../assets/badges/toprated-128px.png')} alt="" /> : null }
                                        { isPro ? <Badge src={require('./../../assets/badges/pro-128px.png')} alt="" /> : null }
                                        { isTop2023 ? <Badge src={require('./../../assets/badges/toppro2023-128px.png')} alt="" /> : null }
                                        { isFoundingMember ? <Badge src={require('./../../assets/badges/founding-128px.png')} alt="" /> : null }                                                                                                  
                                    </ProfileBarCenter>


                                    {/* Save, Message, Book */}
                                    <ProfileBarRight>

                                        <BtnSecondary onClick={() => this.updateFavorites(data.modell.id)} >
                                            { 
                                                this.state.profileSaved ? 
                                                    <>
                                                        <BtnIcon src={require('./../../assets/saved-true-64.png')} alt="" />     
                                                        Saved
                                                    </>
                                                    :
                                                    <>
                                                        <BtnIcon src={require('./../../assets/saved-false-64-doubleline.png')} alt="" />     
                                                        Save
                                                    </>
                                            }
                                        </BtnSecondary>

                                        <BtnSecondaryShort onClick={() => this.updateFavorites(data.modell.id)} >
                                            { 
                                                this.state.profileSaved ? 
                                                    <BtnIconNoMargin src={require('./../../assets/saved-true-64.png')} alt=" " />     
                                                    :
                                                    <BtnIconNoMargin src={require('./../../assets/saved-false-64-doubleline.png')} alt=" " />     
                                            }
                                        </BtnSecondaryShort>

                                        <BtnSecondary
                                            onClick={ () => this.startMessage(data.modell.id) }
                                            >
                                            Message
                                        </BtnSecondary>
                                        
                                        <BtnSecondaryShort
                                            onClick={ () => this.startMessage(data.modell.id) }
                                            >
                                            <BtnIconNoMargin src={require('./../../assets/chat.png')} alt=" " />
                                        </BtnSecondaryShort>
                                        
                                        { data.modell.packages.length > 0 ?
                                            <BtnBook
                                                movetoright='true'
                                                onClick={ () => this.startBooking(data.modell.id) }
                                                >
                                                Book
                                            </BtnBook>
                                            : null 
                                        }

                                    </ProfileBarRight>

                                </ProfileTopBar>


                                {
                                    this.state.showFloatBar ? 
                                        <FloatBarContainer>
                                            <FloatBar>

                                                <ProfileBarLeftFloat>

                                                    <CardDivFloat>
                                                        
                                                        <AvatarArea>
                                                            { 
                                                                data.modell.avatar ? 
                                                                    <AvatarFloat 
                                                                        src={data.modell.avatar} 
                                                                        alt=" " 
                                                                        onLoad={(e) => {
                                                                            //e.target.src = values.avatar --- causes huge CPU spike. but looks like not necessary anyway!
                                                                            e.target.style = 'display: flex' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                                                        }}
                                                                        onError={(e) => {
                                                                            //e.target.src = '' /--- causes huge CPU spike. but looks like not necessary anyway!
                                                                            e.target.style = 'display: none' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                                                        }}

                                                                    /> : <></>
                                                            }
                                                        </AvatarArea>

                                                        <MemberInfoCardFloat>

                                                            <CardTitleFloat>{data.modell.name}{isVerified ? <VerifiedBadge src={require('./../../assets/badges/verified-48px.png')} alt="is verified user" /> : null}</CardTitleFloat>
                                                            <SubTitleFloat>
                                                                { data.modell.address }
                                                            </SubTitleFloat>
                                                            
                                                        </MemberInfoCardFloat>

                                                        <MemberBadgesRowFloat>
                                                            { isTopRated ? <BtnIconNoMargin src={require('./../../assets/badges/toprated-48px.png')} alt="" /> : null }
                                                            { isPro ? <BtnIconNoMargin src={require('./../../assets/badges/pro-48px.png')} alt="" /> : null }
                                                            { isTop2023 ? <BtnIconNoMargin src={require('./../../assets/badges/toppro2023-48px.png')} alt="" /> : null }
                                                            { isFoundingMember ? <BtnIconNoMargin src={require('./../../assets/badges/founding-48px.png')} alt="" /> : null }                                                     
                                                        </MemberBadgesRowFloat>


                                                    </CardDivFloat>

                                                </ProfileBarLeftFloat>

                                                <ProfileBarCenterFloat>
                                                    { isTopRated ? <Badge2 src={require('./../../assets/badges/toprated-128px.png')} alt="" /> : null }
                                                    { isPro ? <Badge2 src={require('./../../assets/badges/pro-128px.png')} alt="" /> : null }
                                                    { isTop2023 ? <Badge2 src={require('./../../assets/badges/toppro2023-128px.png')} alt="" /> : null }
                                                    { isFoundingMember ? <Badge2 src={require('./../../assets/badges/founding-128px.png')} alt="" /> : null }                                                                                                  
                                                </ProfileBarCenterFloat>

                                                <ProfileBarRight>

                                                    <BtnSecondary onClick={() => this.setState({ profileSaved: !this.state.profileSaved }) } >
                                                        { 
                                                            this.state.profileSaved ? 
                                                                <>
                                                                    <BtnIcon src={require('./../../assets/saved-true-64.png')} alt="" />     
                                                                    Saved
                                                                </>
                                                                :
                                                                <>
                                                                    <BtnIcon src={require('./../../assets/saved-false-64-doubleline.png')} alt="" />     
                                                                    Save
                                                                </>
                                                        }
                                                    </BtnSecondary>

                                                    <BtnSecondaryShort onClick={() => this.setState({ profileSaved: !this.state.profileSaved }) } >
                                                        { 
                                                            this.state.profileSaved ? 
                                                                <BtnIconNoMargin src={require('./../../assets/saved-true-64.png')} alt=" " />     
                                                                :
                                                                <BtnIconNoMargin src={require('./../../assets/saved-false-64-doubleline.png')} alt=" " />     
                                                        }
                                                    </BtnSecondaryShort>

                                                    <BtnSecondary
                                                        onClick={ () => this.startMessage(data.modell.id) }
                                                    >
                                                        Message
                                                    </BtnSecondary>
                                                    
                                                    <BtnSecondaryShort>
                                                        <BtnIconNoMargin src={require('./../../assets/chat.png')} alt=" " />
                                                    </BtnSecondaryShort>
                                                    
                                                    { data.modell.packages.length > 0 ?
                                                        <BtnBook
                                                            onClick={ () => this.startBooking(data.modell.id) }
                                                        >
                                                            Book
                                                        </BtnBook>
                                                        : null
                                                    }

                                                </ProfileBarRight>
                                                        
                                            </FloatBar>
                                        </FloatBarContainer>
                                        :
                                        null
                                }


                                {
                                    this.state.confirmLogin ?  
                                        <ConfirmDialog  
                                            text={ this.state.confirmLoginText }
                                            confirmText={'Log in'}
                                            cancelText={'Cancel'}
                                            onConfirm={ () => { 
                                                this.setState({ confirmLogin: false }); 
                                                this.props.history.push(`/login?returnURL=${window.location.pathname}`) 
                                                // this.props.history.push(`/login?returnURL=${window.location.href}`) 
                                            }}
                                            closePopup={ () => 
                                                this.setState({ confirmLogin: false })  
                                            }
                                        />
                                        : 
                                        <></>  
                                }  
                                
                                
                                    {/* Albums */}
                                    { ((data.modell.albums.length > 0) && (this.state.selectedAlbumPhotos.length > 0)) ? 
                                        <AlbumSection>
                                    
                                            <SelectedAlbumSection>

                                                <SelectedPhotoPreview>

                                                    

                                                    <PhotoContainer >

                                                        <PhotoContainerContent>
                                                        
                                                            <GreyButtons>
                                                                { 
                                                                    this.state.selectedPhoto === 0 ? <>&nbsp;</> :
                                                                        <GreyButton 
                                                                            onClick={() =>  {
                                                                                const current = this.state.selectedPhoto;
                                                                                setTimeout(() => { this.setState({ 
                                                                                        selectedPhoto: current-1, 
                                                                                        selectedPhotoURL: CLOUDFRONT_SERVER + '/img/L/' + this.state.selectedAlbumPhotos[current-1].u + '/' + this.state.selectedAlbumPhotos[current-1].a + '/' + this.state.selectedAlbumPhotos[current-1].p
                                                                                        }); 
                                                                                    }, 50);
                                                                                }
                                                                            } 
                                                                            disabled={ this.state.selectedPhoto === 0 ? true : false} >
                                                                            <FAIcon icon={faChevronLeft} color={ this.state.selectedPhoto === 0 ? '#fff' : '#000'} />
                                                                        </GreyButton>
                                                                }
                                                                { 
                                                                    this.state.selectedPhoto === this.state.selectedAlbumPhotos.length-1 ? <></> :
                                                                        <GreyButton 
                                                                            onClick={() =>  {
                                                                                const current = this.state.selectedPhoto;
                                                                                setTimeout(() => { this.setState({ 
                                                                                        selectedPhoto: current+1, 
                                                                                        selectedPhotoURL: CLOUDFRONT_SERVER + '/img/L/' + this.state.selectedAlbumPhotos[current+1].u + '/' + this.state.selectedAlbumPhotos[current+1].a + '/' + this.state.selectedAlbumPhotos[current+1].p
                                                                                        }); 
                                                                                    }, 50);
                                                                                }
                                                                            } 
                                                                            disabled={ this.state.selectedPhoto === this.state.selectedAlbumPhotos.length-1 ? true : false} >
                                                                            <FAIcon icon={faChevronRight} color={ this.state.selectedPhoto === this.state.selectedAlbumPhotos.length-1 ? '#fff' : '#000'} />
                                                                        </GreyButton>
                                                                }
                                                            </GreyButtons>

                                                            <PhotoItself
                                                                src={this.state.selectedPhotoURL}
                                                                alt=" " 
                                                                isselected='true'
                                                                onLoad={(e) => { e.target.style = 'display: flex' }}
                                                                onError={(e) => { e.target.style = 'display: none' }}
                                                                onClick={() => this.setState({ openPhotoZoom: true }) }
                                                                
                                                            />

                                                            <SelectedPhotoTitle>
                                                                { this.state.selectedAlbumPhotos.length > 0 ?
                                                                    `${this.state.selectedPhoto+1}/${this.state.selectedAlbumPhotos.length}: ${this.state.selectedAlbumPhotos[this.state.selectedPhoto].t[0].title}`
                                                                    :
                                                                    null
                                                                }
                                                            </SelectedPhotoTitle>
                                                            
                                                            
                                                            
                                                        </PhotoContainerContent>

                                                    </PhotoContainer>



                                                </SelectedPhotoPreview>

                                                
                                                <Thumbs>
                                                    { 
                                                        this.state.selectedAlbumPhotos.length > 0 ?
                                                            this.state.selectedAlbumPhotos.map((photo, index) => {

                                                                const source = CLOUDFRONT_SERVER + '/img/XS/' + photo.u + '/' + photo.a + '/' + photo.p
                                                                return (
                                                                    <Thumb 
                                                                        key={index}
                                                                        src={source}
                                                                        alt=" " 
                                                                        isselected={this.state.selectedPhoto === index ? 'true' : 'false' }
                                                                        onLoad={(e) => { e.target.style = 'display: flex' }}
                                                                        onError={(e) => { e.target.style = 'display: none' }}
                                                                        onClick={() => {
                                                                            setTimeout(() => { this.setState({ 
                                                                                selectedPhoto: index, 
                                                                                selectedPhotoURL: CLOUDFRONT_SERVER + '/img/L/' + photo.u + '/' + photo.a + '/' + photo.p
                                                                                }); 
                                                                            }, 50);
                                                                        }}
                                                                    />
                                                                )
                                                            })
                                                            : 
                                                            null
                                                    }
                                                </Thumbs>
                                                

                                            </SelectedAlbumSection>


                                            { 
                                                
                                                data.modell.albums.length > 1 ? 
                                                    <AlbumListRow>
                                                        { 
                                                            data.modell.albums.map((album, index) => {

                                                                return (
                                                                    <AlbumTag2 
                                                                        key={index} 
                                                                        onClick={() => this.selectAlbum(index, album.id)}
                                                                        isselected={this.state.selectedAlbum === index ? 'true' : 'false' }
                                                                    >
                                                                        {album.name[0].name}
                                                                    </AlbumTag2>

                                                                )

                                                            })
                                                        }
                                                    </AlbumListRow>
                                                    :
                                                    <AlbumSpacer/>
                                                    
                                            }


                                        </AlbumSection>
                                        :
                                        <Section>
                                            <SectionTitle>Albums</SectionTitle>
                                            <SectionContent>
                                                {
                                                    this.state.albumLoadingFinished ? 
                                                        <>Sorry, the album has no photos that would match your content preferences.</>
                                                    :
                                                        <>Loading albums & photos, please wait...</>
                                                }
                                            </SectionContent>
                                        </Section>
                                    }

                                    {/* H1 */}
                                    { data.modell.intro[0].headline ? 
                                        <Section>
                                            <SectionH1>{data.modell.intro[0].headline}</SectionH1>
                                        </Section>
                                        :
                                        null
                                    }

                                    {/* Introduction */}
                                    <Section>
                                        <SectionTitle>Introduction</SectionTitle>
                                        <SectionContent><div className="contentoverride">{introDisplay}</div></SectionContent>
                                    </Section>


                                    {/* Categories & styles */}
                                    <Section>

                                        <SectionTitle>Categories & styles</SectionTitle>
                                        <SectionContent>

                                            <CategoryList>
                                                {/* 
                                                For models 
                                                */}
                                                <CategoryTag isselected={data.modell.catActing === true ? 'true' : 'false' }>Acting</CategoryTag>
                                                <CategoryTag isselected={data.modell.catArt === true ? 'true' : 'false' }>Art</CategoryTag>
                                                <CategoryTag isselected={data.modell.catBodypaint === true ? 'true' : 'false' }>Bodypaint</CategoryTag>
                                                <CategoryTag isselected={data.modell.catCosplay === true ? 'true' : 'false' }>Cosplay</CategoryTag>
                                                <CategoryTag isselected={data.modell.catDance === true ? 'true' : 'false' }>Dance</CategoryTag>
                                                <CategoryTag isselected={data.modell.catEditorial === true ? 'true' : 'false' }>Editorial</CategoryTag>
                                                <CategoryTag isselected={data.modell.catFashion === true ? 'true' : 'false' }>Fashion</CategoryTag>
                                                <CategoryTag isselected={data.modell.catFetish === true ? 'true' : 'false' }>Fetish</CategoryTag>
                                                <CategoryTag isselected={data.modell.catFitness === true ? 'true' : 'false' }>Fitness</CategoryTag>
                                                <CategoryTag isselected={data.modell.catLifestyle === true ? 'true' : 'false' }>Lifestyle</CategoryTag>
                                                <CategoryTag isselected={data.modell.catLingerie === true ? 'true' : 'false' }>Lingerie</CategoryTag>
                                                <CategoryTag isselected={data.modell.catNude === true ? 'true' : 'false' }>Nude</CategoryTag>
                                                <CategoryTag isselected={data.modell.catPortrait === true ? 'true' : 'false' }>Portrait</CategoryTag>
                                                <CategoryTag isselected={data.modell.catPregnancy === true ? 'true' : 'false' }>Pregnancy</CategoryTag>
                                                <CategoryTag isselected={data.modell.catPromotional === true ? 'true' : 'false' }>Promotional</CategoryTag>
                                                <CategoryTag isselected={data.modell.catRunway === true ? 'true' : 'false' }>Runway</CategoryTag>
                                                <CategoryTag isselected={data.modell.catSport === true ? 'true' : 'false' }>Sport</CategoryTag>
                                                <CategoryTag isselected={data.modell.catSwimwear === true ? 'true' : 'false' }>Swimwear</CategoryTag>
                                                <CategoryTag isselected={data.modell.catTopless === true ? 'true' : 'false' }>Topless</CategoryTag>
                                                <CategoryTag isselected={data.modell.catUnderwater === true ? 'true' : 'false' }>Underwater</CategoryTag>

                                            </CategoryList>
                                            
                                        </SectionContent>
                                    </Section>

                                    {/* Details */}
                                    <Section>

                                        <SectionTitle>Details</SectionTitle>
                                        <SectionContent>

                                            <DetailsContent>

                                            
                                            <OneRowItemsCol>
                                                {/* Height */}
                                                <ColItem3>

                                                    { this.state.metric === 0 ?
                                                        <>
                                                            {/* viewer expects cm */}
                                                            <DetailsLabel>Height</DetailsLabel>
                                                            { data.modell.metric === 0 ?
                                                                <><DetailsValue>{data.modell.height}</DetailsValue><DetailsUnit>cm</DetailsUnit></>
                                                                :
                                                                <><DetailsValue>{parseInt(data.modell.height/100)}</DetailsValue><DetailsUnit>cm</DetailsUnit></>
                                                            }
                                                            
                                                        </>
                                                        :
                                                        <>
                                                            {/* viewer expects inches */}
                                                            <DetailsLabel>Height</DetailsLabel>
                                                            { data.modell.metric === 0 ?
                                                                <DetailsValue>{this.mmToImperial(data.modell.height*100)}</DetailsValue>
                                                                :
                                                                <DetailsValue>{this.mmToImperial(data.modell.height)}</DetailsValue>
                                                            }
                                                        </>
                                                    }
                                                </ColItem3>
                                                
                                                {/* Weight */}
                                                <ColItem3>
                                                    { this.state.metric === 0 ?
                                                        <>
                                                            <DetailsLabel>Weight</DetailsLabel>
                                                            <DetailsValue>{data.modell.weightKg}</DetailsValue><DetailsUnit>kg</DetailsUnit>
                                                        </>
                                                        :
                                                        <>
                                                            <DetailsLabel>Weight</DetailsLabel>
                                                            <DetailsValue>{data.modell.weightLbs}</DetailsValue><DetailsUnit>lbs</DetailsUnit>
                                                        </>
                                                    }
                                                </ColItem3>
                                            </OneRowItemsCol>


                                            <OneRowItemsCol2>
                                                {/* Measurement system  */}
                                                <ColItem3>
                                                    <RadioButtonGroup
                                                        id="metric"
                                                        options={unitOptions}
                                                        value={this.state.metric.toString()}
                                                        onChange={ (event) => {
                                                            
                                                            const newModeValue = event.target.value;
                                                            if (newModeValue === '0') {
                                                                this.setState({ metric: parseInt(newModeValue), });
                                                                
                                                            } else {
                                                                //change from cm to inches
                                                                this.setState({ metric: parseInt(newModeValue), });
                                                            }
                                                        }}

                                                    />
                                                    
                                                </ColItem3>
                                            </OneRowItemsCol2>
                                            

                                            <ExtraEmptyRow></ExtraEmptyRow>

                                            {/* Physique */}
                                            <OneRowItems>
                                                <ColItem3>
                                                    <DetailsLabel>Physique</DetailsLabel>
                                                    <DetailsValue>{physiqueOptions[parseInt(data.modell.physique)].label}</DetailsValue>
                                                </ColItem3>
                                            </OneRowItems>

                                            {/* Bust */}
                                            <OneRowItems>
                                                <ColItem3>
                                                { this.state.metric === 0 ?
                                                    <>
                                                        <DetailsLabel>Bust</DetailsLabel>
                                                        <DetailsValue>{data.modell.bustCm}</DetailsValue><DetailsUnit>cm</DetailsUnit>
                                                    </>
                                                    :
                                                    <>
                                                        <DetailsLabel>Bust</DetailsLabel>
                                                        <DetailsValue>{data.modell.bustInch}</DetailsValue><DetailsUnit>"</DetailsUnit>
                                                    </>
                                                }
                                                </ColItem3>
                                            </OneRowItems>


                                            <IncreaseHeight></IncreaseHeight>

                                            {/* Waist */}
                                            <OneRowItems>
                                                <ColItem3>
                                                { this.state.metric === 0 ?
                                                    <>
                                                        <DetailsLabel>Waist</DetailsLabel>
                                                        <DetailsValue>{data.modell.waistCm}</DetailsValue><DetailsUnit>cm</DetailsUnit>
                                                    </>
                                                    :
                                                    <>
                                                        <DetailsLabel>Waist</DetailsLabel>
                                                        <DetailsValue>{data.modell.waistInch}</DetailsValue><DetailsUnit>"</DetailsUnit>
                                                    </>
                                                }
                                                </ColItem3>
                                            </OneRowItems>

                                            <IncreaseHeight></IncreaseHeight>

                                            {/* Hips */}
                                            <OneRowItems>
                                                <ColItem3>
                                                { this.state.metric === 0 ?
                                                    <>
                                                        <DetailsLabel>Hips</DetailsLabel>
                                                        <DetailsValue>{data.modell.hipsCm}</DetailsValue><DetailsUnit>cm</DetailsUnit>
                                                    </>
                                                    :
                                                    <>
                                                        <DetailsLabel>Hips</DetailsLabel>
                                                        <DetailsValue>{data.modell.hipsInch}</DetailsValue><DetailsUnit>"</DetailsUnit>
                                                    </>
                                                }
                                                </ColItem3>
                                            </OneRowItems>
                                            
                                            
                                            {/* Cup */}
                                            { 
                                                data.modell.gender === 2 ?
                                                    <OneRowItems>
                                                        <ColItem3>
                                                            <DetailsLabel>Cup</DetailsLabel>
                                                            <DetailsValue>{cupOptions[parseInt(data.modell.cup)].label}</DetailsValue>
                                                        </ColItem3>
                                                    </OneRowItems>
                                                :
                                                    null
                                            } 
                                            
                                            <ExtraEmptyRow></ExtraEmptyRow>


                                            <OneRowItemsCol>
                                                
                                                {/* Dress */}
                                                <ColItem3>

                                                <DetailsLabel>Dress size</DetailsLabel>
                                                    {
                                                        data.modell.gender !== 2 ? 
                                                            //NOT WOMEN (Men) 
                                                            this.state.sizeChart === 0 ? 
                                                                <DetailsValue>{dressUSm[parseInt(data.modell.dress)].label}</DetailsValue>
                                                            : this.state.sizeChart === 1 ? 
                                                                <DetailsValue>{dressUKm[parseInt(data.modell.dress)].label}</DetailsValue>
                                                            : this.state.sizeChart === 2 ? 
                                                                <DetailsValue>{dressEUm[parseInt(data.modell.dress)].label}</DetailsValue>
                                                            : this.state.sizeChart === 3 ? 
                                                                <DetailsValue>{dressJPm[parseInt(data.modell.dress)].label}</DetailsValue>
                                                            : 
                                                                <DetailsValue>{dressUSm[parseInt(data.modell.dress)].label}</DetailsValue>
                                                        : 
                                                            //WOMEN
                                                            this.state.sizeChart === 0 ? 
                                                                <DetailsValue>{dressUSw[parseInt(data.modell.dress)].label}</DetailsValue>      
                                                            : this.state.sizeChart === 1 ? 
                                                                <DetailsValue>{dressUKw[parseInt(data.modell.dress)].label}</DetailsValue>      
                                                            : this.state.sizeChart === 2 ? 
                                                                <DetailsValue>{dressEUw[parseInt(data.modell.dress)].label}</DetailsValue>      
                                                            : this.state.sizeChart === 3 ? 
                                                                <DetailsValue>{dressJPw[parseInt(data.modell.dress)].label}</DetailsValue>      
                                                            : 
                                                                <DetailsValue>{dressUSw[parseInt(data.modell.dress)].label}</DetailsValue>      
                                                    }
                                                </ColItem3>
                                                
                                                {/* Shoes */}
                                                <ColItem3>
                                                <DetailsLabel>Shoe size</DetailsLabel>
                                                    {
                                                        data.modell.gender !== 2 ? 
                                                            //NOT WOMEN (Men) 
                                                            this.state.sizeChart === 0 ? 
                                                                <DetailsValue>{shoesUSm[parseInt(data.modell.shoes)].label}</DetailsValue>      
                                                            : this.state.sizeChart === 1 ? 
                                                                <DetailsValue>{shoesUKm[parseInt(data.modell.shoes)].label}</DetailsValue>      
                                                            : this.state.sizeChart === 2 ? 
                                                                <DetailsValue>{shoesEUm[parseInt(data.modell.shoes)].label}</DetailsValue>      
                                                            : this.state.sizeChart === 3 ? 
                                                                <DetailsValue>{shoesJPm[parseInt(data.modell.shoes)].label}</DetailsValue>      
                                                            : 
                                                                <DetailsValue>{shoesUSm[parseInt(data.modell.shoes)].label}</DetailsValue>      
                                                        : 
                                                            //WOMEN
                                                            data.modell.sizeChart === 0 ? 
                                                                <DetailsValue>{shoesUSw[parseInt(data.modell.shoes)].label}</DetailsValue>      
                                                            : this.state.sizeChart === 1 ? 
                                                                <DetailsValue>{shoesUKw[parseInt(data.modell.shoes)].label}</DetailsValue>      
                                                            : this.state.sizeChart === 2 ? 
                                                                <DetailsValue>{shoesEUw[parseInt(data.modell.shoes)].label}</DetailsValue>      
                                                            : this.state.sizeChart === 3 ? 
                                                                <DetailsValue>{shoesJPw[parseInt(data.modell.shoes)].label}</DetailsValue>      
                                                            : 
                                                                <DetailsValue>{shoesUSw[parseInt(data.modell.shoes)].label}</DetailsValue>      

                                                    }
                                                </ColItem3>
                                            </OneRowItemsCol>

                                            {/* Size chart */}
                                            <OneRowItemsCol2>
                                                
                                                <ColItem3>
                                                <RadioButtonGroup
                                                        id="sizeChart"
                                                        options={data.modell.gender !== 2 ? chartOptionsMen : chartOptionsWomen}
                                                        value={this.state.sizeChart.toString()}
                                                        onChange={ (event) => { 
                                                            
                                                            this.setState({ sizeChart: parseInt(event.target.value), });
                                                            // this.props.setField('sizeChart', parseInt(event.target.value)) 
                                                            // this.props.setField('dress', data.modell.dress)

                                                            }}
                                                    />
                                                </ColItem3>
                                            </OneRowItemsCol2>


                                            <ExtraEmptyRow></ExtraEmptyRow>

                                            {/* Eyes */ }
                                            { data.modell.eyeColor === null ?
                                                null
                                                :
                                                <OneRowItems>
                                                    <ColItem3>
                                                        <DetailsLabel>Eyes</DetailsLabel>
                                                        <DetailsValue>{eyeColorOptions[parseInt(data.modell.eyeColor)].label}</DetailsValue>
                                                    </ColItem3>
                                                </OneRowItems> 
                                            }

                                            {/* Hair length */}
                                            { data.modell.hairLength === null ?
                                                null
                                                :
                                                <OneRowItems>
                                                    <ColItem3>
                                                        <DetailsLabel>Hair length</DetailsLabel>
                                                        <DetailsValue>{hairLengthOptions[parseInt(data.modell.hairLength)].label}</DetailsValue>
                                                    </ColItem3>
                                                </OneRowItems> 
                                            }

                                            {/* Hair color  */}
                                            {
                                                data.modell.hairColor === null ?
                                                <></>
                                                :
                                                    <OneRowItems>
                                                        <ColItem3>
                                                            <DetailsLabel>Hair color</DetailsLabel>
                                                            <DetailsValue>{hairColorOptions[parseInt(data.modell.hairColor)].label}</DetailsValue>
                                                        </ColItem3>
                                                    </OneRowItems> 
                                            }

                                            {/* Tattoos */}
                                            { data.modell.tattoos === null ?
                                                null
                                                :
                                                <OneRowItems>
                                                    <ColItem3>
                                                        <DetailsLabel>Tattoos</DetailsLabel>
                                                        <DetailsValue>{noneSomeManyOptions[parseInt(data.modell.tattoos)].label}</DetailsValue>
                                                    </ColItem3>
                                                </OneRowItems> 
                                            }

                                            {/* Piercings */}
                                            { data.modell.piercing === null ?
                                                null
                                                :
                                                <OneRowItems>
                                                    <ColItem3>
                                                        <DetailsLabel>Piercing</DetailsLabel>
                                                        <DetailsValue>{noneSomeManyOptions[parseInt(data.modell.piercing)].label}</DetailsValue>
                                                    </ColItem3>
                                                </OneRowItems> 
                                            }
                                            

                                            {/* Skin */}
                                            { data.modell.skin === null ?
                                                null
                                                :
                                                <OneRowItems>
                                                    <ColItem3>
                                                        <DetailsLabel>Skin</DetailsLabel>
                                                        <DetailsValue>{skinOptions[parseInt(data.modell.skin)].label}</DetailsValue>
                                                    </ColItem3>
                                                </OneRowItems> 
                                            }
                                                

                                            <ExtraEmptyRow></ExtraEmptyRow>

                                            {/* Age */}
                                            <OneRowItems>
                                                <ColItem3>
                                                    <DetailsLabel>Age</DetailsLabel>
                                                    <DetailsValue>{data.modell.age}</DetailsValue>
                                                </ColItem3>
                                            </OneRowItems> 
                                            

                                            {/* WorksWith */}
                                            <OneRowItems>
                                                <ColItem3>
                                                    <DetailsLabel>Works with</DetailsLabel>
                                                    <DetailsValue>{worksWith[parseInt(data.modell.worksWith)].label}</DetailsValue>
                                                </ColItem3>
                                            </OneRowItems> 
                                            

                                            </DetailsContent>
                                        </SectionContent>
                                    </Section>



                                    {/* Languages */}
                                    <Section>

                                        <SectionTitle>Languages</SectionTitle>
                                        <SectionContent>
                                            <ListRow>
                                            { 
                                                data.modell.languages.map((language, index) => {

                                                    return (
                                                        <LanguageTag key={index}>
                                                            {this.capitalize(ISO6391.getNativeName(language))}
                                                        </LanguageTag>
                                                    )
                                                })
                                            }
                                            </ListRow>
                                        </SectionContent>

                                    </Section>

                                    
                                    {/* REVIEWS */}
                                    { data.modell.reviewsTotal >= 5 ? 
                                            <>
                                                <Section>
                                                    <SectionTitle>
                                                        {data.modell.reviewsTotal} reviews<br/>
                                                        <SectionTitleSmaller><span role="img" aria-label="rating">{numberOfStars}</span> ({data.modell.avgRating})</SectionTitleSmaller>

                                                    </SectionTitle>
                                                    <SectionContent>


                                                    <Query
                                                        asyncMode
                                                        query={getModellReviews}
                                                        variables={{'modellId': profileId}}
                                                        fetchPolicy="network-only"
                                                    >
                                                    {
                                                        ({ data }) => {
                                                        
                                                            //console.log('reviews data: ', data)

                                                            if (data === undefined) {
                                                                
                                                                return(<>
                                                                    <SubTitle>Sorry, we're unable to get the reviews at this moment.</SubTitle>
                                                                </>)

                                                            } else
                                                            
                                                            if (JSON.stringify(data).length > 35) {
                                                                
                                                                //console.log('------------ data 2: ', data) 
                                                                
                                                                return (<>

                                                                    { 
                                                                        
                                                                        data.getModellReviews.map((review, index) => {

                                                                            //console.log('----- modell review:', review)


                                                                            //dt, from, rating, message, response, 
                                                                            //bookingId, clientID, clientName, clientAvatar, reviewDt, reviewCatNumber, reviewDuration
                                                                            let reviewStars = '⭐⭐⭐⭐⭐';
                                                                            if (review.rating/2 <= 5) { reviewStars = '⭐⭐⭐⭐⭐' }
                                                                            if (review.rating/2 < 4.5) { reviewStars = '⭐⭐⭐⭐☆' }
                                                                            if (review.rating/2 < 3.5) { reviewStars = '⭐⭐⭐☆☆' }
                                                                            if (review.rating/2 < 2.5) { reviewStars = '⭐⭐☆☆☆' }
                                                                            if (review.rating/2 < 1.5) { reviewStars = '⭐☆☆☆☆' }

                                                                            const reviewDate = moment(review.dt/1000*1000).format('MMMM YYYY');

                                                                            return (
                                                                                
                                                                                <ReviewCard
                                                                                    key={index}>
                                                        
                                                                                    <ReviewHeader>
                                                                                        <ReviewAvatarArea>
                                                                                            { 
                                                                                                review.clientAvatar ? 
                                                                                                    <ReviewAvatarFloat 
                                                                                                        src={review.clientAvatar} 
                                                                                                        alt=" " 
                                                                                                        onLoad={(e) => {
                                                                                                            //e.target.src = values.avatar --- causes huge CPU spike. but looks like not necessary anyway!
                                                                                                            e.target.style = 'display: flex' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                                                                                        }}
                                                                                                        onError={(e) => {
                                                                                                            //e.target.src = '' /--- causes huge CPU spike. but looks like not necessary anyway!
                                                                                                            e.target.style = 'display: none' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                                                                                        }}

                                                                                                    /> : <></>
                                                                                            }
                                                                                        </ReviewAvatarArea>



                                                                                        <ReviewTitle>

                                                                                            <CardTitleFloat>{ this.modellCategoryToString().find(op => { return op.value === review.catNumber.toString() }).label } ({ this.durationOptions().find(op => { return op.code === review.duration.toString() }).label }),  { reviewStars }</CardTitleFloat>
                                                                                            <SubTitleFloat>
                                                                                                {reviewDate}, {review.clientName}    
                                                                                            </SubTitleFloat>
                                                                                            <SubTitleFloat>
                                                                                            
                                                                                            </SubTitleFloat>
                                                                                            
                                                                                        </ReviewTitle>
                                                                                    
                                                                                    </ReviewHeader>

                                                                                    <ReviewMessage>
                                                                                        <ClampLines
                                                                                            text={review.message}
                                                                                            id={index}
                                                                                            lines={2}
                                                                                            ellipsis="..."
                                                                                            moreText="Read more"
                                                                                            lessText="Collapse"
                                                                                            className="clampLinesCustomClass"
                                                                                            innerElement="p"
                                                                                        />
                                                                                    </ReviewMessage>


                                                                                    {

                                                                                        review.response.length > 0 ? 
                                                                                            (
                                                                                                <>
                                                                                                    <ResponseHeader>
                                                                                                        Response:
                                                                                                    </ResponseHeader>          
                                                                                                    <ResponseMessage>

                                                                                                        <ClampLines
                                                                                                            text={review.response}
                                                                                                            id={1234567}
                                                                                                            lines={2}
                                                                                                            ellipsis="..."
                                                                                                            moreText="Read more"
                                                                                                            lessText="Collapse"
                                                                                                            className="clampLinesCustomClass"
                                                                                                            innerElement="p"
                                                                                                        />

                                                                                                    </ResponseMessage>
                                                                                                </>
                                                                                            )
                                                                                        :
                                                                                            null
                                                                                    }
                                                                                    
                                                                                    

                                                                                   
                                                                                </ReviewCard>
                                                        
                                                                            );

                                                                        })


                                                                    }

                                                                </>);

                                                            } else {
                                                                return(<>
                                                                    <SubTitle>Loading reviews...</SubTitle>
                                                                </>)
                                                            }
                                                            
                                                        
                                                        }

                                                    }
                                    
                                                    </Query>

                                                    </SectionContent>
                                                </Section>
                                            </>
                                            :
                                            null
                                    }
                                

                                    {/* Packages */}
                                    <Section>

                                        <SectionTitle>Packages</SectionTitle>

                                        { data.modell.packages.length > 0 ? 
                                            <SectionContent>
                                            
                                            Select the package you would like to book:
                                            <IncreaseHeight/>

                                            <GreyButtonsCarouselMobile>

                                                <GreyButton 
                                                    onClick={() => this.moveIndex(-1) }
                                                    disabled={this.state.packageIndex <= 0 } 
                                                    >
                                                    <FAIcon icon={faChevronLeft} color={ this.state.packageIndex <= 0 ? '#cdcdcd' : '#000'} />
                                                </GreyButton>

                                                <GreyButton 
                                                    onClick={() => this.moveIndex(1) }
                                                    disabled={this.state.packageIndex >= data.modell.packages.length-1} 
                                                    >
                                                    <FAIcon icon={faChevronRight} color={ this.state.packageIndex >= data.modell.packages.length-1 ? '#cdcdcd' : '#000'} />
                                                </GreyButton>

                                            </GreyButtonsCarouselMobile>

                                            <IncreaseHeight/>

                                            <Carousel 
                                                arrows
                                                dots
                                                slidesPerPage={window.innerWidth > 1050 ? 2 : 1}
                                                // draggable={false}
                                                value={this.state.packageIndex}
                                                // addArrowClickHandler
                                                // keepDirectionWhenDragging
                                                
                                                arrowLeft={this.generateArrow('left')}
                                                arrowLeftDisabled={this.generateArrow('left', true)}
                                                arrowRight={this.generateArrow('right')}
                                                arrowRightDisabled={this.generateArrow('right', true)}

                                                onChange={(index) => { this.setState({ packageIndex: index, }); }} //this is needed for dots to work.

                                            >

                                                { 
                                                    
                                                
                                                    data.modell.packages.map((pakett, index) => {

                                                        let packageSetter = {
                                                            
                                                            bookingPackageId: pakett.id, 
                                                            bookingPackageDuration: pakett.duration, 

                                                        }

                                                        
                                                    
                                                        return (

                                                            <PackageCard2 
                                                                key={index}
                                                                >

                                                                <PackageCardClickOverlay 
                                                                    onClick={ () => { this.setState(packageSetter); this.startBooking(data.modell.id) } } 
                                                                >
                                                                </PackageCardClickOverlay>

                                                                <PackageCardDuration 
                                                                    onClick={ () => { this.setState(packageSetter); this.startBooking(data.modell.id) } } 
                                                                >
                                                                    { this.durationOptions().find(op => { return op.code === pakett.duration.toString() }).label }
                                                                </PackageCardDuration> 

                                                                <PackageCardPrice 
                                                                    onClick={ () => { this.setState(packageSetter) } } 
                                                                >
                                                                    { 
                                                                        this.state.displayCurrencyRate === 0 ?
                                                                            parseFloat(pakett.price).toFixed(2) + ' ' + pakett.currency 
                                                                            :
                                                                            parseFloat(pakett.price * this.state.displayCurrencyRate).toFixed(2) + ' ' + this.state.displayCurrency 
                                                                    }

                                                                </PackageCardPrice> 

                                                                <PackageCardName 
                                                                    onClick={ () => { this.setState(packageSetter); this.startBooking(data.modell.id) } } 
                                                                >
                                                                    { pakett.name[0].name }
                                                                </PackageCardName>

                                                                <PackageCardDescription2 
                                                                    onClick={ () => { this.setState(packageSetter); this.startBooking(data.modell.id) } } 
                                                                >
                                                                    { pakett.description[0].description }
                                                                </PackageCardDescription2> 
                                                                
                                                                
                                                            </PackageCard2> 

                                                        );

                                                        
                                                        
                                                        
                                                    })

                                                }
                                                </Carousel>
    

                                            </SectionContent>
                                            :
                                            <SectionContent>
                                                This model has no packages available for booking. You can send a message to ask about prices and availability.
                                            </SectionContent>
                                        }

                                    </Section>




                                    {/* Availability */}
                                    {
                                        data.modell.packages.length > 0 ?
                                            <Section>

                                                <SectionTitle>Availability</SectionTitle>

                                                
                                                <SectionContent>
                                                
                                                Select when you would like to have a photoshoot:

                                                <IncreaseHeight/>


                                                {/* Travel notices */}
                                                { this.state.travelNotices.length > 0 ? 
                                                    <>
                                                        <TravelNoticeHeader>Travel notice!</TravelNoticeHeader>
                                                        <TravelNoticeHeader2>On the following dates, booking is available on these locations:</TravelNoticeHeader2>
                                                    </>
                                                    : null 
                                                }
                                                {
                                                    this.state.travelNotices.map((travelInfo, index) => {
                                                        return (<TravelNoticeDetail key={index}>{travelInfo}</TravelNoticeDetail>)
                                                    })                                            
                                                }
                                                { this.state.travelNotices.length > 0 ? <IncreaseHeight/> : null }



                                                {/* ~react-dates/css/styles.scss */}
                                                <DecreaseLeftMargin>

                                                    <DayPickerSingleDateController
                                                        // hideKeyboardShortcutsPanel
                                                        numberOfMonths={window.matchMedia("(max-width: 850px)").matches ? 1 : 2}
                                                        
                                                        focused={true}
                                                        //initialVisibleMonth={() => moment()} //moment() gives current date
                                                        initialVisibleMonth={() => moment(this.state.availabilityRangeStart) } //moment() gives current date
                                                        
                                                        
                                                        isOutsideRange={this.isOutside} //using to disable days that have already passed
                                                        isDayBlocked={(day) => this.isDayBlocked(day)}
                                                        // isDayHighlighted={this.isDayBlocked}

                                                        daySize={48}

                                                        renderCalendarDay={ props => {

                                                            // const { day, modifiers } = props
                                                            const { modifiers } = props
                                                            // if (this.state.selectedAvailabilityDates.includes(moment(day).format('YYYY-MM-DD'))) {
                                                            //     modifiers && modifiers.add('selected')
                                                            // } else {
                                                            //     modifiers && modifiers.delete('selected')
                                                            // }
                                                            
                                                            return ( <CalendarDay { ...props } modifiers={modifiers} /> )
                                                        }} 

                                                        onDateChange={(day) => {
                                                            this.setState({ 
                                                                bookingDate: day, 
                                                                
                                                            });

                                                            this.startBooking(data.modell.id);
                                                            
                                                        }}

                                                        firstDayOfWeek={1}

                                                        onNextMonthClick={(next) => {

                                                            setTimeout(() => { this.setState({ 
                                                                availabilityRangeStart: moment(next).format("YYYY-MM-01"), 
                                                                availabilityLoadingFinished: false, 
                                                            }); }, 50) 
                                                            
                                                        }}
                                                        
                                                        onPrevMonthClick={(prev) => {

                                                            setTimeout(() => { this.setState({ 
                                                                availabilityRangeStart: moment(prev).format("YYYY-MM-01"), 
                                                                availabilityLoadingFinished: false, 
                                                            }); }, 50) 

                                                        }}

                                                        noBorder={true}

                                                    />


                                                    
                                                </DecreaseLeftMargin>

                                        
                                                </SectionContent>
                                                    

                                            </Section>
                                            : 
                                            <IncreaseHeight/>
                                    }
                                    

                                    <IncreaseHeight/>
                                    <IncreaseHeight/>
                                    <IncreaseHeight/>
                                

                                <Footer showbutton="true"/>

                            


                            {/* Booking modal */}
                            <Modal 
                                isOpen={this.state.bookingModalVisible}
                                closeTimeoutMS={500}

                                onRequestClose={() => { this.setState({ bookingModalVisible: !this.state.bookingModalVisible, })  }}
                                ariaHideApp={false}
                                style={ window.innerWidth > 790 ? bookingModalStyles : bookingModalStylesMobile }
                                
                                onAfterOpen={() => {
                                    document.body.style.top = `0px`
                                    document.body.style.width = `100vw`
                                    document.body.style.position = 'fixed'
                                }}

                                onAfterClose={() => {
                                    const scrollY = document.body.style.top
                                    document.body.style.position = ''
                                    document.body.style.top = ''
                                    document.body.style.width = ''
                                    window.scrollTo(0, parseInt(scrollY || '0') * -1)
                                }}
                            >
                            
                                <ModellBooking 
                                    hideModal={ () => this.setState({ bookingModalVisible: false, }) }
                                    data={data.modell}
                                    bookingDate={this.state.bookingDate !== '' ? moment(this.state.bookingDate).format('YYYY-MM-DD') : null }
                                    bookingPackageId={this.state.bookingPackageId ? this.state.bookingPackageId : ''}
                                    bookingPackageDuration={this.state.bookingPackageDuration ? this.state.bookingPackageDuration : 60}
                                    displayCurrencyRate={ this.state.displayCurrencyRate }
                                    euroRate={ this.state.euroRate }
                                    displayCurrency={ this.state.displayCurrency }
                                    defaultAddress={data.modell.address}
                                />
                                

                            </Modal>


                            { this.state.messageModalVisible ?
                            
                                <div className='react-confirm-alert-overlay'>
                                    <div className='react-confirm-alert-body-min80'>

                                        <ModalTitle>

                                            <SectionTitle>
                                                <SectionHeadline>
                                                    Send a new message
                                                </SectionHeadline>
                                            </SectionTitle>

                                        </ModalTitle>

                                        <ModalTitle>

                                        <TextareaStyled
                                            name={'messageField'}
                                            value={this.state.messageField}
                                            onChange={this.fldValueChange}
                                            rows={5}
                                            // cols={5}
                                        />
                                        </ModalTitle>
                            
                                        <Section></Section>

                                        <ModalTitle>
                                        {/* For padding */}
                                            <ButtonArea>

                                                <BtnBook onClick={ () => { this.postMessage(this.state.messageToWhichModell); } }>
                                                    Send
                                                </BtnBook>

                                                <BtnSecondaryCancel onClick={ () => { setTimeout(() => { this.setState({ messageModalVisible: false }) }, 50); }}>
                                                    Cancel
                                                </BtnSecondaryCancel>

                                            </ButtonArea>
                                        </ModalTitle>

                                    </div>
                                </div>

                                :
                                null
                            }
  

                            </ExtraWrapper>

                        );
                    }
                }
                
            </WithViewModell>

        );
    }
}





const personalInfoFields = [
    'userId', 'fName', 'lName', 'bDay', 'email', 'phone', 'phoneDisplay', 'phoneCountry', 'avatar', 'isVerified', 'isPro', 'temperror', 'pwdUpdated',
    'chkReminders', 'chkRemindersP', 'chkMessages', 'chkMessagesP', 'chkRecomm', 'chkRecommP', 'chkInsp', 'chkInspP', 'chkLocal', 'chkLocalP', 
    'chkHow', 'chkHowP', 'chkInvite', 'chkInviteP', 'chkSurvey', 'chkSurveyP', 'chkPolicy', 'chkPolicyP', 'chkTips', 'chkTipsP', 'currency'
]


export const ViewModell = withRouter(withStore(withApollo(C), personalInfoFields));


const ExtraWrapper = styled.div`

    width: calc(100% - 32px);
    position: relative;
    overflow-x: hidden;

    @media (max-width: 1090px) {
        width: calc(100% - 16px);
    }

`;

const ProfileTopBar = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    top: 0;
    right: 0;
    top: 0; 
    /* height: 80px; */

    width: 80%;
    max-width: 1080px;

    padding-left: 8px;
    
    justify-content: flex-start;


    font-family: 'Fira Sans', sans-serif; 
    font-size: 17px;
    line-height: 25px;

    white-space: pre-line;
    font-weight: 300;
    color: #333333;

    padding-bottom: 8px;

    margin: 40px auto 0px auto;

    @media (max-width: 1350px) {
        width: calc(100% - 16px);
    }


`;

const FloatBarContainer = styled.div`

    position: fixed;
    
    top: 0;
    right: 0;

    /* height: 64px; */

    width: 100%;

    font-family: 'Fira Sans', sans-serif; 
    font-size: 17px;
    line-height: 25px;

    white-space: pre-line;
    font-weight: 300;
    color: #333333;

    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    padding-bottom: 8px;


    z-index: 999;


`;

const FloatBar = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;
  
    width: 80%;
    max-width: 1080px;

    justify-content: space-between;

    margin: 0px auto 0px auto;

    

    @media (max-width: 1350px) {

        width: 100%;
        padding-bottom: 8px;

    }
    
`;



const ProfileBarLeft = styled.div`
    
    flex-grow: 2;  

`;

const ProfileBarLeftFloat = styled.div`
    
    flex-grow: 2;  
    padding-top: 16px;

    justify-content: flex-start;
    align-items: left;

    @media (max-width: 1050px) {
        
        padding-top: 16px;
        margin-left: -16px;
    } 

`;

const ProfileBarCenter = styled.div`
    
    flex-grow: 1;  

    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;

    margin-right: 16px;
    margin-left: 16px;

    padding-top: 8px;
    padding-bottom: 8px;

    @media (max-width: 1050px) {
        display: none;
    } 


`;

const ProfileBarCenterFloat = styled.div`
    
    flex-grow: 1;  

    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;

    margin-right: 16px;
    margin-left: 16px;

    padding-top: 8px;
    padding-bottom: 8px;

    @media (max-width: 1050px) {
        display: none;
    } 
`;



const ProfileBarRight = styled.div`
    
    flex-grow: 2;  

    display: flex;
    flex-direction: row;

    /* text-align: right; */

    justify-content: flex-end;
    align-items: center;

    @media (max-width: 790px) {
        margin-right: 12px;
    }

`;


const AlbumSection = styled.div`
    
    width: 80%;
    max-width: 1080px;

    margin: auto;

    
    /* background-color: #ebe; */
    
    padding-left: 16px;
    margin-top: 8px;
    
    @media (max-width: 790px) {
        width: calc(100% - 16px)
    }

`;

const SelectedAlbumSection = styled.div`

    width: 100%;

    min-height: 60vh;
    height: calc(60vh + 72px);

    /* background-color: #deb; */

    @media (max-width: 790px) {
        min-height: auto;
        height: auto;
    }

`;


const SelectedPhotoPreview = styled.div`
    
    float: left;

    /* background-color: #ebe;  */

    /* width: calc(100% - 96px); */
    width: 100%;

    /* height: calc(100% - 32px);  */
    min-height: 60vh;
    height: 60vh;
    

    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 790px) {
        width: 100%;

        min-height: auto;
        height: auto;
    }
    
`;

const PhotoContainer = styled.div`
    
    /* width: ${(props) => props.width}; */
    /* height: ${(props) => props.height}; */

    float: left;
    width: 100%;
    height: 100%;
   
    text-align: center;

    margin: 0 auto;

`;

const PhotoContainerContent = styled.div`
   
    position: relative;
    height: 100%;

`;


const PhotoItself = styled.img`
   
    max-width: 100%;
    max-height: 100%;

    /* object-fit: cover; */
    object-fit: contain;
    

    margin: 0 auto;

    /* border: 2px solid red; */
    border-radius: 5px;
    box-shadow: 0px 2px 8px 0 rgba(170, 170, 170, 0.4);


    /* &:hover { cursor: pointer; opacity: 1; }    */

`;


const SelectedPhotoTitle = styled.div`

    width: 100%;
    height: 32px;

    /* margin-bottom: 4px; */

    /* background-color: rgb(0,0,200); */

    text-align: center;

    font-family: 'Fira Sans';
    font-size: 12px;
    line-height: 1.2;
    padding-top: 4px;

    @media (max-width: 790px) {
        width: 100%;
    }

`;

const Thumbs = styled.div`
   
    /* float: right;
    width: 80px;
    min-height: 70vh;
    height: 70vh;

    padding-top: 0px;
    padding-left: 4px;
    

    overflow: hidden; */

    float: left;
    width: 100%;
    height: auto;
    min-height: auto;

    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    
    padding-top: 32px;
    padding-left: 0px;
    padding-bottom: 8px;

    
    /* &:hover { overflow-y: auto; overflow-x: hidden; }     */
    &:hover { overflow-x: auto; overflow-y: hidden; }    

    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        box-shadow: inset 0 0 4px rgb(255, 149, 0, 0.9); 
    }

    @media (max-width: 790px) {
        /* float: left;
        width: 100%;
        height: auto;
        min-height: auto;

        display: flex;
        flex-wrap: wrap;    
        flex-direction: row; */

        padding-top: 8px;
        /* padding-left: 0px; */

        /* &:hover { overflow-x: auto; overflow-y: hidden; }     */
    } 

`;

const Thumb = styled.img`
   
    width: 64px;
    height: 64px;

    object-fit: cover;

    /* margin-bottom: 12px; */
    margin-right: 8px;
    margin-bottom: 8px;
    
    border-radius: 5px;

    box-shadow: 0px 2px 8px 0 rgba(170, 170, 170, 0.3);

    border: ${(props) => props.isselected === 'true' ? "1px solid #D8D8D8" : '0px'};
    
    outline: ${(props) => props.isselected === 'true' ? "2px solid #fff;" : 'none'};
    outline-offset: -5px;
    
    opacity: ${(props) => props.isselected === 'true' ? "1" : '0.5'};
    

    &:hover { cursor: pointer; opacity: 1; transform: scale(1.02); }    

    /* @media (max-width: 790px) {
        margin-right: 12px;
    } */

`;


const ListRow = styled.div`
   
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    top: 16px; 

`;

const AlbumListRow = styled.div`
   
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    margin-top: 0px;
    padding-top: 0px;

    @media (max-width: 790px) {
        margin-top: 8px;
        padding-top: 8px;
    }


`;



// const AlbumTag2 = styled.div`
   
//     display: flex;
//     flex-wrap: wrap;    

//     margin-right: 8px;
//     margin-bottom: 8px;

//     padding-top: 4px;
//     padding-bottom: 4px;
//     padding-left: 16px;
//     padding-right: 16px;
    
//     font-size: 16px;
//     font-weight: 400;
    
//     border-radius: 48px;

//     box-shadow: 0 2px 8px 0 rgba(125, 125, 125, 0.2);

//     border: ${(props) => props.isSelected === 'true' ? "1px solid #fF9500;" : '1px solid #ebebeb;'};
//     background: ${(props) => props.isSelected === 'true' ? "rgb(255, 149, 0, 0.2);" : '#fff;'};
    
//     &:hover {
//         background: ${(props) => props.isSelected === 'true' ? "rgb(255, 149, 0, 0.3);" : '##f8f8f8;'};
//         transform: scale(1.02);
//         cursor: pointer;
//         }


// `;


const AlbumTag2 = styled(Button)`
    && {
    height: 40px;

    padding: 16px;

    margin-right: 16px;
    margin-bottom: 8px;

    background: ${(props) => props.isselected === 'true' ? '#f5f5f5': "white"};
    border: ${(props) => props.isselected === 'true' ? '4px double #cbcbcb;' : "1px solid #cbcbcb;"};

    border-radius: 20px;  


    font-family: 'Fira Sans';
    color: #333333;
    
    text-transform: none;
    font-weight: 400;
    font-size: 16px;

    &:hover {
        border-color: #333333;
        }

    }
    
`;


const LanguageTag = styled.div`
   
    display: flex;
    flex-wrap: wrap;    

    font-family: 'Fira Sans';
    font-size: 17px;
    font-weight: 300;
    
    margin-right: 8px;
    margin-bottom: 8px;
    margin-top: -2px;

    padding-right: 16px;


`;

const CategoryList = styled.div`
   
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;
    padding-top: 4px;

    width: 100%;

`;

const CategoryTag = styled.div`
   
    display: flex;
    flex-wrap: wrap;    

    margin-right: 8px;
    margin-bottom: 6px;

    padding-left: 8px;
    padding-right: 8px;
    
    line-height: 1;
    padding-top: 4px;
    padding-bottom: 4px;



    font-size: 12px;
    font-weight: 400;
    
    border-radius: 48px;

    box-shadow: 0 2px 8px 0 rgba(125, 125, 125, 0.2);

    color: ${(props) => props.isselected === 'true' ? "#333333;" : '#cdcdcd;'};

    border: ${(props) => props.isselected === 'true' ? "1px solid #7ED321;" : '1px solid #ebebeb;'};
    text-decoration: ${(props) => props.isselected === 'true' ? "none;" : 'line-through;'};
    background: ${(props) => props.isselected === 'true' ? "rgb(126, 211, 33, 0.05);" : '#fff;'};
  

`;



const HistoryBackButton = styled(Button)`
    && {
        
    /* margin: -4px -4px 0 0px; */

    height: 48px;
    min-width: 48px;
    max-width: 48px;
    border-radius: 24px;

    margin-left: 26px;
    margin-top: 16px;
    /* margin-right: 4px;
    margin-left: 4px; */
    margin-bottom: -24px;

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;

    
    font-weight: 400;
    font-size: 19px;

    background-color: #EBEBEB;
    opacity: 0.6;

    &:hover {
        background-color: #EBEBEB;
        opacity: 0.6;
        }
    
    &:disabled {
        background-color: #fff;
        color: #fff;
        }

    @media (min-width: 790px) {
        display: none;
    }

    }
    
    
`;



const CardDiv = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;    

    padding-right: 0px;

`;

const CardDivFloat = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;    

    /* padding-left: 8px; */

`;

const ReviewCard = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;    

    padding-left: 0px;
    margin-left: 0px;

    margin-bottom: 32px;

`;

const ReviewHeader = styled.div`
    

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;    

    width: 100%;
    padding-left: 0px;

    margin-bottom: 8px;
    /* padding-left: 32px; */

`;

const ReviewMessage = styled.div`
    
    display: flex;
    flex-direction: row;

    font-family: 'Fira Sans';
    font-style: italic;
    font-size: 17px;
    font-weight: 300;

    border-left: 2px solid #ddd;
    padding-left: 8px;

    width: 100%;

`;

const ResponseHeader = styled.div`
    
    display: flex;
    flex-direction: row;

    font-family: 'Fira Sans';
    font-size: 17px;
    font-weight: 400;

    margin-top: 16px;
    margin-left: 64px; 
    width: 100%;

`;

const ResponseMessage = styled.div`
    
    display: flex;
    flex-direction: row;

    font-family: 'Fira Sans';
    font-style: italic;
    font-size: 17px;
    font-weight: 300;

    border-left: 2px solid #ddd;
    padding-left: 8px;

    margin-top: 16px;
    margin-left: 64px; 
    width: 100%;

`;


const AvatarArea = styled.div`
    
    width: 64px; 
    margin: auto;
    
`;

const ReviewAvatarArea = styled.div`
    
    width: 64px; 
    margin: 0px;

    
    /* float: left */

`;

const Avatar = styled.img`

    height: 64px;
    width: 64px;
    border-radius: 64px;
    
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.4);

`;



const AvatarFloat = styled.img`

    height: 64px;
    width: 64px;
    border-radius: 64px;

    margin-top: -6px;

    
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.4);

    @media (max-width: 1050px) {
        margin-left: 32px;
        
    } 

`;

const ReviewAvatarFloat = styled.img`

    height: 48px;
    width: 48px;
    border-radius: 48px;
    margin-left: 0px;
    
    
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.4);

    @media (max-width: 1050px) {
        margin-left: 8px;
        
    } 

`;


const MemberInfoCard = styled.div`
    
    padding-left: 8px;
    width: calc(100% - 90px);

`;

const MemberInfoCardFloat = styled.div`
    
    /* padding-left: 24px; */
    width: calc(100% - 100px);
    min-width: calc(100% - 100px);
    justify-content: flex-start;

    @media (max-width: 1050px) {
        margin-left: 32px;
        padding: 8px;
        width: calc(100% - 120px);
        min-width: calc(100% - 120px);
        
    } 
    

`;

const ReviewTitle = styled.div`
    
    width: calc(100% - 80px);
    min-width: calc(100% - 80px);
    justify-content: flex-start;

    

`;

const MemberBadgesRow = styled.div`
    
    display: none;
    
    width: calc(100% - 80px);
    margin-top: 12px;
    padding-left: 8px;

    @media (max-width: 1050px) {
        display: flex;
        flex-wrap: wrap;    
        flex-direction: row;
        

    } 
        
`;

const MemberBadgesRowFloat = styled.div`
    
    display: none;
    
    width: calc(100% - 80px);
    margin-left: 16px;
    margin-top: 8px;

    @media (max-width: 1050px) {
        display: flex;
        flex-wrap: wrap;    
        flex-direction: row;
        margin-left: 32px;

    } 
        
`;



const CardTitle = styled.div`
    
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 2px;
    /* @media (max-width: 790px) {
        display: none;
        } */

`;

const SubTitle = styled.div`
    
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5em;
    
    /* @media (max-width: 790px) {
        display: none;
        }
     */

`;

const CardTitleFloat = styled.div`
    
    font-size: 17px;
    font-weight: 400;

    margin-top: 2px;
    /* @media (max-width: 790px) {
        display: none;
        } */

`;

const SubTitleFloat = styled.div`
    
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5em;
    
    /* @media (max-width: 790px) {
        display: none;
        }
     */

`;

const GreyButtons = styled.div`

    display: flex;
    position: absolute;

    flex-direction: row;
    justify-content: space-between;

    width: calc(100% - 8px);
    min-width: calc(100% - 8px);

    margin-top: -48px;
    top: calc(50% + 24px);
    
    z-index: 800;

    /* background-color: #ebe; */
    padding-left: 4px;
    padding-right: 4px;
    
    @media (max-width: 790px) {
        top: calc(50% + 12px);
    }


`;


const GreyButtonsCarouselMobile = styled.div`

    display: flex;
    position: absolute;

    flex-direction: row;
    justify-content: space-between;

    width: calc(100% - 72px);
    min-width: calc(100% - 72px);

    z-index: 800;

    /* background-color: #ebe; */
    padding-left: 4px;
    padding-right: 4px;

    padding-bottom: 8px;
    
    @media (min-width: 790px) {
        
        display: none;

    }


`;


const GreyButton = styled(Button)`
    && {
        
    /* margin: -4px -4px 0 0px; */

    height: 48px;
    min-width: 48px;
    max-width: 48px;
    border-radius: 24px;

    /* margin-right: 4px;
    margin-left: 4px; */

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;
    font-weight: 400;
    font-size: 19px;

    background-color: #EBEBEB;
    opacity: 0.6;

    &:hover {
        background-color: #EBEBEB;
        opacity: 0.6;
        }
    
    &:disabled {
        background-color: #fff;
        color: #fff;
        }

    }
    
`;


const GreyButtonCarousel = styled(Button)`
    && {
        
    /* margin: -4px -4px 0 0px; */

    height: 48px;
    min-width: 48px;
    max-width: 48px;
    border-radius: 24px;

    /* margin-right: 4px;
    margin-left: 4px; */

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;
    font-weight: 400;
    font-size: 19px;

    background-color: #EBEBEB;
    opacity: 0.6;

    &:hover {
        background-color: #EBEBEB;
        opacity: 0.6;
        }
    
    &:disabled {
        background-color: #fff;
        color: #fff;
        }

    @media (max-width: 790px) {
        /* position: absolute; */
        display: none;
        /* margin-top: -50%; */
    }

    }
    
`;


const FAIcon = styled(FontAwesomeIcon)`
    && {
    font-size: 21px;
    font-weight: 400;
    }
`;


const Section = styled.div`
      
    /* background-color: #bee; */
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 80%;
    max-width: 1080px;
    
    padding-left: 16px;
    padding-right: 16px;
    
    justify-content: space-between;

    font-family: 'Fira Sans', sans-serif; 
    font-size: 17px;
    line-height: 25px;

    white-space: pre-line;
    font-weight: 300;
    color: #333333;

    margin: 32px auto 0px auto;


    @media (max-width: 1350px) {
        width: calc(100% - 16px);
        margin-right: 16px;
    }



`;


const SectionH1 = styled.h1`
    
    /* background-color: #fea; */
    width: 100%;

    font-family: 'Fira Sans';
    font-size: 19px;
    font-weight: 500;

     
    padding-left: 8px;

    @media (max-width: 1050px) {

        padding-left: 0px;
        width: 100%;

    }

`;


const SectionTitle = styled.div`
    
    width: 20%;

    font-family: 'Fira Sans';
    font-size: 19px;
    font-weight: 500;

    padding-top: 2px; 

    @media (max-width: 1050px) {
        width: 100%;
    }

`;

const SectionTitleSmaller = styled.div`
    
    font-family: 'Fira Sans';
    font-size: 19px;
    font-weight: 300;

    

    padding-top: 2px; 

`;

const SectionContent = styled.div`
    
    /* background-color: #eaf; */
    width: calc(80% - 32px);
    max-width: 1080px;

    font-family: 'Fira Sans';
    font-size: 17px;
    font-weight: 300;
    
    padding-top: 4px;

    margin-top: 0;
    
    display: absolute;

    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;

    @media (max-width: 1050px) {

        width: 100%;
        padding-left: 0px;
        padding-top: 16px;

        
    }

`;

const IncreaseHeight = styled.div`
    
    height: 16px;  

`;

const AlbumSpacer = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;

`;



const PackageCard2 = styled.div`
    
    
    width: 50%px;
    /* min-width: 200px; */
   
    margin: 16px 16px 8px 16px;
    margin-top: 2px;


    align-items: flex-start;
    border-radius: 5px;
    
    border: 1px solid #cbcbcb;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    z-index: 300;

    &:hover {
        transform: scale(1.01);
        cursor: pointer;
       
    }

    @media (max-width: 1050px) {
        width: calc(100%+32px);
        padding-top: 16px;
        padding-left: 0px;
        padding-right: 0px;

        margin-top: 48px;
        margin-left: 1px;
        margin-right: 16px;
    }
    
`;



const PackageCardDescription2 = styled.div`
    
    display: absolute;
    position: relative;
    text-align: left;
    float: left; 

    margin-right: 0;
    margin-left: 16px;
    margin-top: 8px;

    width: 90%;
    padding-bottom: 16px;

    color: #333333;
    font-size: 16px;
    line-height: 20px;

    white-space: pre-wrap;
    margin-bottom: 8px;


    &:hover {
        cursor: pointer;
    }

    z-index: 600;

`;







const PackageCardDuration = styled.div`
    
    display: absolute;
    position: relative;
    text-align: left;
    float: left; 
    margin-right: auto;
    margin-left: 0px;
    
    margin-top: 16px; 

    border-radius: 0px 4px 4px 0px;
    padding: 6px 12px 6px 16px;

    color: #333333;
    background-color: rgba(75, 75, 75, 0.1);
    
    font-family: 'Fira Sans';
    font-size: 16px;
    font-weight: 400;

    &:hover {
        cursor: pointer;
    }

    z-index: 600;

`;




const PackageCardPrice = styled.div`
    
    display: absolute;
    position: relative;

    text-align: right;
    float: right; 
    margin-left: auto;

    margin-top: 16px; 

    border-radius: 4px 0px 0px 4px;
    padding: 6px 16px 6px 12px;

    color: #333333;
    background-color: rgba(75, 75, 75, 0.1);
    
    font-family: 'Fira Sans';
    font-size: 16px;
    font-weight: 400;

    &:hover {
        cursor: pointer;
       
    }

    z-index: 700;

`;



const PackageCardName = styled.div`
    
    display: absolute;
    position: relative;
    text-align: left;
    float: left; 

    margin-right: 0;
    margin-left: 16px;
    margin-top: 16px;
    
    z-index: 600;

    width: 90%;

    color: #333333;
    font-family: 'Fira Sans';
    font-size: 18px;
    font-weight: 400;
    
    white-space: pre-wrap;
    margin-bottom: 8px;


    &:hover {
        cursor: pointer;
    }

`;



const PackageCardClickOverlay = styled.div`
    
    display: absolute;
    position: absolute;
    text-align: left;
    float: left; 

    margin-left: 0px;
    margin-top: 40px;

    width: 300px;
    height: 260px;

    &:hover {
        cursor: pointer;
    }

    z-index: 60;

`;


const BtnSecondary = styled(Button)`
    && {
    
    display: flex;

    min-height: 40px;
    
    padding-left: 16px;
    padding-right: 16px;

    margin-right: 16px;

    text-transform: none;
    text-decoration: underline;
    text-underline-offset: 2px;

    font-weight: 400;
    font-size: 17px;
    color: #333333;
    
    @media (max-width: 1200px) {
        display: none;
    }

    &:hover {
        opacity: 0.9;
        box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};
        text-decoration: underline;
        }
    }
    
    
`;

const BtnSecondaryCancel = styled(Button)`
    && {
    
    display: flex;

    min-height: 40px;
    
    padding-left: 16px;
    padding-right: 16px;

    margin-right: 16px;
    margin-left: 16px;

    text-transform: none;
    text-decoration: underline;
    text-underline-offset: 2px;

    font-weight: 400;
    font-size: 17px;
    color: #333333;
    

    &:hover {
        opacity: 0.9;
        box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};
        text-decoration: underline;
        }
    }
    
    
`;

const BtnSecondaryShort = styled(Button)`
    && {

    display: none;
    
    min-height: 40px;
    min-width: 40px;
    
    margin-right: 16px;

    text-transform: none;
    text-decoration: underline;
    text-underline-offset: 2px;

    font-weight: 400;
    font-size: 17px;
    color: #333333;
    
    @media (max-width: 1200px) {
        display: flex;
    }

    &:hover {
        opacity: 0.9;
        box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};
        text-decoration: underline;
        }
    }
    
`;


const BtnBook = styled(Button)`
    && {
    min-height: 40px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    color: white;
    
    padding-left: 16px;
    padding-right: 16px;

    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    
    &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }

    @media (max-width: 790px) {
        margin-right: ${(props) => props.movetoright === 'true' ? '-16px' : '0px'};
    }

}

    
    
`;

const BtnIcon = styled.img`

    width: 20px;
    height: 20px;
    margin-right: 8px;         

`;

const BtnIconNoMargin = styled.img`

    height: 24px;
    margin-right: 8px;        

`;

const Badge = styled.img`

    margin-right: 8px;
    height: 64px;
    margin-top: -2px;

    &:last-child {
        margin-right: 0px;
    }

`;

const VerifiedBadge = styled.img`

    margin-left: 8px;

    height: 20px;
    
`;


const Badge2 = styled.img`

    margin-right: 8px;
    height: 64px;
    margin-top: 2px;

    &:last-child {
        margin-right: 0px;
    }

`;


const DecreaseLeftMargin = styled.div`
    margin-left: -20px;
`;


const TravelNoticeHeader = styled.div`
    
    font-family: 'Fira Sans';
    font-size: 19px;
    font-weight: 500;

    margin-bottom: 2px;
    color: #ff9500;

`;

const TravelNoticeHeader2 = styled.div`
    
    font-family: 'Fira Sans';
    font-size: 17px;
    font-weight: 500;

    color: #ff9500;

    margin-bottom: 2px;

`;


const TravelNoticeDetail = styled.div`
    
    font-family: 'Fira Sans';
    font-size: 17px;
    font-weight: 400;

    color: #ff9500;

    padding-left: 32px;

`;

const ModalTitle = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: flex-start;
    justify-content: flex-start;

    /* background-color: #ebe; */

    width: 100%;

    margin-left: 16px;
    margin-bottom: 24px;
    /* margin-top: -4px; */
    /* padding-left: 16px; */

`;

const SectionHeadline = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 500;
    font-size: 20px;

    /* color: #505050; */
    /* color: #383838; */
    color: #333333;

    /* background-color: #ded; */

`;

const ButtonArea = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: left;
    justify-content: flex-start;
    
    width: 100%;
    min-height: 40px;

    /* background-color: #ebe; */

`;

const TextareaStyled = styled.textarea`
    
    width: 100%;

    padding: 0px;
    margin: 0px;
    /* height: 24px; */
    padding: 8px;
    
    font-size: 18px;
    font-family: 'Fira Sans';
    font-weight: 300;

    color: #333333;
    
    border: 1px solid #cbcbcb;
    border-radius: 4px;  
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);    

    :focus {
        outline-color: #4C9AFF;
    }

    :disabled {
        background-color: #f7f7f7; 
    }

    @media (max-width: 690px) {
        font-size: 18px;
    }
`;





const DetailsContent = styled.div`

    display: flex;
    flex-wrap: wrap; 
    
`;


const OneRowItems = styled.div`
        
    flex-direction: row;
    justify-content: flex-start;
    
    width: 100%;
    min-width: 100%;

    /* background-color: #1fa; */

`;

const OneRowItemsCol = styled.div`
        
    flex-direction: column;
    justify-content: flex-start;
    
    /* background-color: #1fa; */

`;

const OneRowItemsCol2 = styled.div`
        

    flex-direction: column;
    justify-content: flex-start;
    
    margin-left: 32px; 
    margin-top: 8px;
    /* background-color: #1fa; */

`;


const ExtraEmptyRow = styled.div`
        
    
    width: 100%;
    min-width: 100%;

    height: 16px;
    /* background-color: #1fa; */

`;

const ColItem3 = styled.div`
    
    /* background-color: #0fe; */

    display: flex;
    flex-wrap: wrap;

`;

const DetailsLabel = styled.div`
    
    color: #333333;
    
    font-family: 'Fira Sans';
    font-size: 17px;
    font-weight: 300;
    
    
    margin-bottom: 8px;
    margin-top: 0px;
    margin-left: 2px;

    width: 150px;

   

`;

const DetailsValue = styled.div`
    
    color: #333333;
    
    font-family: 'Fira Sans';
    font-size: 17px;
    font-weight: 400;
    
    margin-bottom: 8px;
    margin-top: 0px;
    margin-left: 2px;

`;


const DetailsUnit = styled.div`
    
    color: #333333;
    
    font-family: 'Fira Sans';
    font-size: 16px;
    font-weight: 300;
    
    margin-bottom: 8px;
    margin-top: 0px;

    margin-left: 4px;


`;

