
import React, { Component } from 'react';
import styled from 'styled-components';

// import i18next from "i18next";
import '../../i18n'


import Modal from 'react-modal';

import { UpgradeComparisonTable } from "./UpgradeComparisonTable";

export class UpgradeComparisonModal extends Component {
    
    state = {
        showPopup: false,
    };



    toggleCategorySelection = () => {  

        this.setState({  
            showPopup: !this.state.showPopup,
        });  

    }  

    upgradeURL = (url) => {  

       console.log('visit URL')

    }  

    


    render() {
      

        const filterStyles = {
            overlay: { zIndex: 1000, backgroundColor: 'rgba(0, 0, 0, 0.33)',},
            content: {

                top: '24px',

                left: '5%',
                margin: 'auto',

                bottom: 'auto',
                
                height: `90%`,
                width: '790px',

                maxWidth: '75%',

                boxShadow: '0 20px 50px rgba(0, 0, 0, 0.33)',
                borderRadius: '5px',
                
                paddingTop: '24px',
                paddingLeft: '24px',
                paddingRight: '24px',
                                                    
                overflow : 'auto',
                

            }
        }

        const filterStylesMobile = {
            overlay: { zIndex: 1000, backgroundColor: 'rgba(0, 0, 0, 0.33)',},
            content: {

                top: `80px`,

                left: '0%',
                margin: `auto`,
                
                bottom: 'auto',
                
                height: `85%`,
                width: `calc(100% - 48px)`,
                maxWidth: '790px',
                
                boxShadow: '0 20px 50px rgba(0, 0, 0, 0.33)',
                borderRadius: '5px',

                paddingTop: '24px',
                paddingLeft: '24px',
                paddingRight: '24px',
                                                    
                overflow : 'auto',

            }
        }

        if (this.props.showUpgradeComparisonModal && !this.state.showPopup) {
            this.toggleCategorySelection();
        }
        
        return (
        
            <Modal 
                isOpen={this.state.showPopup}
                closeTimeoutMS={500}

                onRequestClose={this.props.closePopup}
                ariaHideApp={false}
                style={window.innerWidth > 790 ? filterStyles : filterStylesMobile}
                
                onAfterOpen={() => {
                    document.body.style.top = `0px`
                    document.body.style.width = `100vw`
                    document.body.style.position = 'fixed'
                }}

                onAfterClose={() => {
                    const scrollY = document.body.style.top
                    document.body.style.position = ''
                    document.body.style.top = ''
                    document.body.style.width = ''
                    window.scrollTo(0, parseInt(scrollY || '0') * -1)
                }}
                >
            
                <Content>
                    <Title>What's the difference beween Hobby and Pro memberships?</Title>


                    <UpgradeComparisonTable 
                        paneKinni={this.props.closePopup}
                        hideCTA={this.props.hideCTA}
                    />

                    

                    { window.innerWidth > 790 ? <><br/></> : <><br/><br/></> }

                </Content>

            
            </Modal>

        
        );

    }
}


const Content = styled.div`
    
    /* overflow: hidden; */
    width: 100%;

`;


const Title = styled.div`
    
    padding: 12px 24px 24px 0px;

    font-family: 'Fira Sans';
    font-size: 22px;
    font-weight: 500;

    color: #333333;

`;
