import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { graphql, withApollo } from "react-apollo";
import gql from "graphql-tag";

class C extends React.PureComponent {
    
    
    renderRoute = (routeProps) => {
        
        const { data, component } = this.props;

        if (!data || data.loading) {
            // loading .. (and not yet logged in because of that.. dont decide  anything yet) 
            return null;
        }

        try {
            
            if (JSON.stringify(data.me).length > 15) {

                // console.log('controller data.me: ', data.me);
                // console.log('controller component: ', component);
                
                const Component = component;
                return <Component {...routeProps} />;

            }
            else {

                //console.log('length < 15. not logged in. controller routeProps: ', routeProps);
                return (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { next: routeProps.location.pathname }
                        }}
                    />
                );
            }
        }
        catch {

            const Component = component;
            return <Component {...routeProps} />;
            
        }
        
    };
    

    render() {

        const { data: _, component: __, ...rest } = this.props;

        return <Route {...rest} render={this.renderRoute} />;

    }

}

const meQuery = gql`
  query meQuery {
    me {
      email
    }
  }


`;

export const AuthRoute = graphql(meQuery)(withApollo(C));


// logEvent('login', {'type': type})
//     //console.log(' ----------------- ', userId)
//     setUserId(userId)