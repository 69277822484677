
import React, { useState, useEffect } from "react";
import { Form } from "antd";
import styled from 'styled-components';
import gql from "graphql-tag"

import PhoneInput from 'react-phone-input-2'
import './PhoneFieldDefaultStyle.css'

import { parsePhoneNumberFromString } from 'libphonenumber-js'
import './PhoneField.css'

const FormItem = Form.Item;

const updateMutation = gql`
    mutation($fld: String!, $value: String!) {
        updateField(fld: $fld, value: $value)
    }
`;


export const PhoneField = ({
    field: { onChange, ...field },
    form: { touched, errors }, 
    label, propStore, originalPhone, originalValue, displayField, countryField,
    allowedToChange, useNumberComponent = false,
    ...props
  }) =>  {

        const [phoneField, setPhoneField] = useState();
        const [hasChanged, setHasChanged] = useState(false);


        const errorMsg = touched[field.name] && errors[field.name];
        
        useEffect(() => {

            setPhoneField(originalPhone);
            
        }, [propStore, originalPhone, originalValue])
        

        const handleOnChange = (value) => {
            
            let correctPhoneValue;
            if (value.substring(0, 1) === '+') {
                correctPhoneValue = value;
            } else {
                correctPhoneValue = '+' + value;
            }

            setPhoneField(correctPhoneValue);
            setHasChanged(true);

            //console.log('handleChange ', correctPhoneValue)
            
        };



        const onBlur = () => {
            
            
            if (hasChanged) {

                const maskingRemoved = phoneField.replace(/[- )(]/g,'');
                
                propStore.set(field.name, maskingRemoved); //without spaces
                propStore.set(displayField, phoneField) //with spaces
                propStore.set(countryField, parsePhoneNumberFromString(phoneField).country.toLowerCase())

                setHasChanged(false);

                props.client
                    .mutate({
                        mutation: updateMutation,
                        variables: {
                            fld: field.name,
                            value: maskingRemoved
                        },
                    })
                    .then(results => {
                        // console.log(results)
                    })
                    .catch(error => {
                        // console.log("Error: ", error);
                    })

                props.client
                    .mutate({
                        mutation: updateMutation,
                        variables: {
                            fld: displayField,
                            value: phoneField
                        },
                    })
                    .then(results => {
                        // console.log(results)
                    })
                    .catch(error => {
                        // console.log("Error: ", error);
                    })

                props.client
                    .mutate({
                        mutation: updateMutation,
                        variables: {
                            fld: countryField,
                            value: parsePhoneNumberFromString(phoneField).country.toLowerCase()
                        },
                    })
                    .then(results => {
                        // console.log(results)
                    })
                    .catch(error => {
                        // console.log("Error: ", error);
                    })


            }
            
            
        };


        return (
            
            <SettingsFieldWrapper>

                { label ? <LabelStyled>{label}</LabelStyled> : null}
                
                <FormItem validateStatus={errorMsg ? "error" : undefined}>

                    <PhoneInput 
                        defaultCountry={'us'} 
                        value={phoneField} 
                        onChange={handleOnChange} 
                        inputExtraProps={{ autoFocus: true }}
                        onBlur={onBlur}
                    />

                </FormItem>

            </SettingsFieldWrapper>
        );
      };




const SettingsFieldWrapper = styled.div`

    margin-left: 24px;
    padding-bottom: 32px; 
    
    width: 100%;
    
`;

const LabelStyled = styled.div`

    width: 100%;
    margin: 0px;
    padding: 0px;
    color: #333333;
    font-size: 16px;
    margin-bottom: 4px;
    margin-top: 0px;
    
`;


