import React, { PureComponent } from 'react'

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

import "./index.css";
import { FooterSection } from "./_footerSection";
import { UpgradeComparisonModal } from "../components/UpgradeComparisonModal";
import { Link } from "react-router-dom";

import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export default class BecomeAMUAH extends PureComponent {

    constructor(props) {
        
        super(props);
        
        this.state = {
            selectedYearly: true,
            showUpgradeModal: false,
            showUpgradeComparisonModal: false,
            hideCTA: false,
        };
    
    }

    componentDidMount() {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        // ReactGA.send("pageview");
    }

    render() {

     

        
        return (
            
            

            <>

                <Helmet>
                    <title>Become a MUAH | Kickstart Your Makeup & Hairstyling Career at PhotoSouls</title>
                    <meta name="description" content="Aspire to shine in the world of makeup and hairstyling? PhotoSouls paves the way for you to become a professional MUAH." />
                    <meta name="keywords" content="PhotoSouls, makeup artist, hairdresser, stylist, booking, photographers, models, portfolio, career, bookings" />
                </Helmet>

                {/* 0. Ignite Your Career with PhotoSouls! */}
                <Section1img>

                    <RowCenter>
                        
                        <CenterTextBlock>

                            <H1>Ignite Your Career with PhotoSouls!</H1>

                            <H1DescBigger>Are you a makeup artist, hairdresser, or stylist seeking more bookings and exhilarating opportunities? </H1DescBigger>
                            
                            <H1DescSmaller>Your search ends here! Our platform, PhotoSouls, links talented professionals like you with photographers and models in need of your expertise.</H1DescSmaller>

                            <BtnPrimary onClick={ () => this.props.history.push({ pathname: 'signup/' }) }>CLICK HERE TO JOIN</BtnPrimary>

                        </CenterTextBlock>

                    </RowCenter>

                </Section1img>

              
                {/* 1. Access a Pool of Potential Clients and Bookings */}
                <Section1>

                    <Row>
                        
                        <Left>

                            <H1g>Access a Pool of Potential Clients and Bookings!</H1g>
                            <H1Desc>On PhotoSouls, you're visible to a multitude of potential clients, opening the door to increased bookings.</H1Desc>
                            <H1Desc>The platform serves as a marketplace where anyone in need of your services can easily find you and book your time.</H1Desc>
                            
                        </Left>

                        <RightPhoto>
                            <S1img src={'images/web/model1.png'} alt="Photographers" />
                        </RightPhoto>

                    </Row>

                </Section1>


                {/* 2. Showcase Your Talent to a Wider Audience */}
                <SectionBlack>

                    <Row>
                        
                        <Left2>
                            <S1img src={'images/web/muah2.jpg'} alt="Model in front of ring light" />
                        </Left2>

                        <Right2>
                            <H1gWhite>Showcase Your Talent to a Wider Audience</H1gWhite>
                            <H1DescWhite>With PhotoSouls, you get to flaunt your work to a broader audience.</H1DescWhite>
                            <H1DescWhite>Your profile serves as a creative stage to display your skills, attracting clients who appreciate your unique touch.</H1DescWhite>
                            
                        </Right2>

                    </Row>

                </SectionBlack>


                {/* Utilize Specialized Tools and Features */}
                <Section1>

                    <Row>
                        
                        <Left>

                            <H1g>Utilize Specialized Tools and Features</H1g>
                            <H1Desc>PhotoSouls offers a range of tools designed to help you succeed.</H1Desc>
                            <H1Desc>From building an impressive portfolio to managing your bookings seamlessly, we've got you covered. Our platform is designed to make your journey as effortless as possible.</H1Desc>
                            
                        </Left>

                        <RightToBottom>
                            <S1img src={'images/web/photog2b.png'} alt="Model holsding PhotoSouls" />
                        </RightToBottom>

                    </Row>

                </Section1>

                {/* Elevate Your Career */}
                <SectionBlack>

                    <Row>
                        
                        <Left2>
                            <S1img src={'images/web/muah3.png'} alt="Hairdresser and the client" />
                        </Left2>

                        <Right2>
                            <H1gWhite>Elevate Your Career</H1gWhite>
                            <H1DescWhite>Don't let this golden opportunity to boost your business and showcase your talent slip by.</H1DescWhite>
                            <H1DescWhite>Join PhotoSouls today and start uncovering exciting new work opportunities.</H1DescWhite>
                            
                        </Right2>

                    </Row>

                </SectionBlack>

                {/* How much does it cost? */}
                <Section1>

                    <Row>
                        
                        <Left>

                            <H1g>How much does it cost?</H1g>
                            {/* <H1Desc>PhotoSouls offers a free Hobby membership plan that gives you access to everything on our platform without any charges. It's completely free of cost!</H1Desc> */}
                            {/* <H1Desc>When you receive a booking and get paid, we only deduct a 20% commission fee. This fee is applicable only when you make a successful booking.</H1Desc> */}
                            {/* <H1Desc>As a Pro member, you enjoy additional benefits, including a reduced commission fee of 15%. To explore the other advantages of Pro membership, <StyledLink onClick={ () => { this.setState({ showUpgradeComparisonModal: true, hideCTA: false }) }}>click here.</StyledLink></H1Desc> */}
                            <H1Desc>At PhotoSouls, our Hobby membership plan is available at no charge, providing you with access to many of our platform's features. We only take a 20% commission when you get a booking and receive payment.</H1Desc>
                            <H1Desc>For a broader range of features and benefits, consider upgrading to our Pro membership. It also includes a reduced commission fee of just 15%. Learn more about the advantages of the Pro membership by <StyledLink onClick={ () => { this.setState({ showUpgradeComparisonModal: true, hideCTA: false }) }}>clicking here.</StyledLink></H1Desc>

                        </Left>

                        <Right>
                            <S1img src={'images/web/photog9.png'} alt="Open pricing" />
                        </Right>

                    </Row>

                </Section1>


                <Section1>

                    <RowCenter>
                        
                        <CenterTextBlock>

                            <H1>Your moment is now!</H1>

                            <H1Desc>Let PhotoSouls be your stepping stone to an even brighter future!</H1Desc>

                            <BtnPrimary onClick={ () => this.props.history.push({ pathname: 'signup/' }) }>Join Now!</BtnPrimary>

                        </CenterTextBlock>

                    </RowCenter>

                </Section1>

                <FooterSection/>

                { this.state.showUpgradeComparisonModal ? 
                    <UpgradeComparisonModal
                        showUpgradeComparisonModal={this.state.showUpgradeComparisonModal}
                        hideCTA={this.state.hideCTA}
                        closePopup={() => this.setState({ showUpgradeComparisonModal: false, hideCTA: false }) }
                    />
                : null
                }

            </>

        )
    }
}



const Left2 = styled.div`
    
    width: 45%;
    padding-top: 32px;
    padding-bottom: 32px;

    /* background-color: #aea; */

    @media (max-width: 980px) {
        width: 100%;
        padding-bottom: 0px;
        
    }
    

`;

const Right2 = styled.div`
    
    width: 45%;
    padding-top: 32px;
    padding-bottom: 64px;


    /* background-color: #ced; */

    @media (max-width: 980px) {
        
        width: 100%;
        padding-left: 0px;
        padding-top: 0px;

    }
    

`;




const Row = styled.div`
    
    width: 1170px;

    margin: 0 auto;
    
    padding-left: 48px;
    padding-right: 24px;
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    justify-content: center;
    align-items: center;
        
    /* background-color: #f3f; */

    @media (max-width: 980px) {
        
        /* margin-top: 0px; */
        /* text-align: center;
        justify-content: center;
        align-items: center; */

    }

`;


const Section1 = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    background-color: #f3f2f0;
    background-image: linear-gradient(#fff,#f3f2f0);

    position: relative;
    z-index: 1;
    min-height: 450px;


    @media (max-width: 980px) {
        
        min-height: 450px;

    }


`;

const SectionBlack = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    background-color: #000;

    position: relative;
    z-index: 1;
    min-height: 450px;


    @media (max-width: 980px) {
        
        min-height: 450px;

    }


`;


const Section1img = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    background-color: #f3f2f0;
    background-image: url('images/web/photog0.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    position: relative;
    z-index: 1;
    min-height: 450px;


    @media (max-width: 980px) {
        
        min-height: 450px;

    }


`;

const Left = styled.div`
    
    width: 45%;

    padding-bottom: 40px;
    padding-right: 24px;
    padding-top: 64px;
    padding-left: 32px;

    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 32px;
        padding-left: 0px;

    }
    

`;


const CenterTextBlock = styled.div`
    
    width: 75%;

    padding-bottom: 40px;
    padding-right: 24px;

    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 32px;

    }
    

`;


const RowCenter = styled.div`
    
    max-width: 1170px;

    margin: 0 auto;
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    text-align: center;
    justify-content: center;
    align-items: center;

    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;

    padding-top: 48px;

    @media (max-width: 980px) {
        
        /* margin-top: 0px; */

    }

`;



const H1g = styled.h1`

    margin-top: 20px; 

    color: #333333;
    font-family: 'Fira Sans';
    font-size: 42px;
    

    @media (max-width: 970px) {

        font-size: 32px;

    }

`;

const H1gWhite = styled.h1`

    margin-top: 20px; 

    color: #fff;
    font-family: 'Fira Sans';
    font-size: 42px;
    

    @media (max-width: 970px) {

        font-size: 32px;

    }

`;

const H1 = styled.h1`

    margin-top: 20px; 

    color: #ff9500;

    font-family: 'Fira Sans';
    font-size: 48px;
    

    @media (max-width: 970px) {

        font-size: 38px;

    }

`;


const H1Desc = styled.div`
    
    margin-top: 20px; 
    margin-bottom: 20px; 

    color: #333333;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 26px;
    
    @media (max-width: 970px) {

        font-size: 24px;

    }

`;

const H1DescBigger = styled.div`
    
    margin-top: 20px; 
    margin-bottom: 20px; 

    color: #333333;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 32px;
    
    @media (max-width: 970px) {

        font-size: 26px;

    }

`;


const H1DescWhite = styled.div`
    
    margin-top: 20px; 
    margin-bottom: 20px; 

    color: #fff;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 26px;
    
    @media (max-width: 970px) {

        font-size: 24px;

    }

`;



const H1DescSmaller = styled.div`
    
    margin-top: 32px; 
    margin-bottom: 20px; 

    color: #333333;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 28px;
    
    @media (max-width: 970px) {

        font-size: 24px;

    }

`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #3e6ca0;
    text-decoration: underline
    &:hover {
        color: #3e6ca0;
        text-decoration: underline
    }
`;

const BtnPrimary = styled(Button)`
    && {
    min-height: 64px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    
    color: white;
    
    padding-left: 32px;
    padding-right: 32px;
    margin-right: 32px;
    margin-top: 32px;

    text-transform: none;
    font-weight: 400;
    font-size: 22px;
    
    &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }

}     
`;



const S1img = styled.img`

    width: 100%;
    height: 100%;

    object-fit: cover;

    margin-left: auto;
    margin-right: auto;


`;


const Right = styled.div`
    
    width: 45%;
    padding-left: 48px;
    padding-bottom: 32px;

    /* background-color: #ced; */
    text-align: center;
    justify-content: center;
    align-items: center;


    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 0px;

    }
`;

const RightToBottom = styled.div`
    
    width: 45%;
    padding-left: 48px;
    margin-bottom: -64px;

    /* background-color: #ced; */
    text-align: center;
    justify-content: center;
    align-items: center;


    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 0px;
        margin-bottom: -4px;

    }
`;


const RightPhoto = styled.div`
    
    width: 45%;

    padding-left: 32px;
    /* padding-bottom: 32px; */
    padding-top: 64px;

    /* background-color: #f3f2f0;
    background-image: url('images/web/_1test1.jpg');
    background-repeat: no-repeat;
    background-size: cover; */

    @media (max-width: 980px) {
        
        width: 100%;
        padding-left: 0px;
        padding-top: 0px;
    }
`;

