
import React from 'react';  
import styled from 'styled-components';
import '../../../i18n'
import Button from '@material-ui/core/Button';

import { Divider } from "../../components/Divider";
import '../../../styles/ModalPopup.css';    


class ConfirmDelete extends React.Component {  

    render() {  

        return (  
            
            <>
            
                <div className='react-confirm-alert-overlay'>
                
                    <div className='react-confirm-alert-body'>
                            
                        <StyledHeader>{this.props.text}</StyledHeader>


                        <SelectionArea>
                            
                            <SelectionButtonRed
                                    onClick={() => {
                                        this.props.onConfirm();
                                    }}
                                    >
                                    Delete
                            </SelectionButtonRed>

                            <Spacing/>

                            <SelectionButton
                                    onClick={() => {
                                    this.props.closePopup();
                                }}
                                >
                                Cancel
                            </SelectionButton>

                        </SelectionArea>

                        <Divider />

                    </div>

                        

                </div>

            </>

        );  
    }  
    
}  

export default ConfirmDelete;

const Spacing = styled.div`

    width: 24px;
    display: absolute;
    
`;

const SelectionArea = styled.div`

    width: 100%;
    margin-top: 16px; 
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;

    text-align: center;
    align-items: center;
    justify-content: center;
    
`;

const SelectionButton = styled(Button)`
    && {

        width: calc(50% - 24px);
        height: 40px;

        margin: 0px 0px 0px 0px;
        padding: 0px 16px 0px 16px;
        border-radius: 4px;

        color: #333333;
        background: #ffffff;
        box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.3);

        text-transform: none;
        font-weight: 400;
        font-size: 17px;

        &:hover {
            background: #f8f8f8;
            }
    }
    
`;

const SelectionButtonRed = styled(Button)`
    && {

        width: calc(50% - 24px);
        height: 40px;

        margin: 0px 0px 0px 0px;
        padding: 0px 16px 0px 16px;
        border-radius: 4px;

        color: #ffffff;
        background: #FF6A6A;
        box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.3);

        text-transform: none;
        font-weight: 400;
        font-size: 17px;

        &:hover {
            background: #FF2800;
            }
    }

`;


const StyledHeader = styled.div`
  width: 100%;
  
  margin: 0px 0px 16px 0px;
  padding: 0px;

  color: #333333;
  font-weight: 400;
  font-size: 21px;

  
`;