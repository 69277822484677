//
// Thank you to Eric Murphy - Youtube video - How to make a star rating component in react
//
import React, { useState, useEffect } from "react";
import { FaStar } from 'react-icons/fa'
import styled from 'styled-components';



const StarRating = (ratingSet) => {

    const [rating, setRating] = useState(10)
    
    const [hover, setHover] = useState(null)

    useEffect(() => { 
        ratingSet.onRatingSet( rating ); }, 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ rating ]
    );

    return (
        <AllRatings>

            {[ ...Array(10)].map((star, i) => {
              
                const ratingValue = i+1;

                return (
                    <label key={i}>

                        <Raadio 
                            type="radio" 
                            name="rating" 
                            value={ratingValue} 
                            onClick={() => setRating(ratingValue)}
                            
                            
                        />

                        <RatingContainer>
                            
                            <FAIcon className="star"
                                size={32}
                                color={ratingValue <= (hover || rating) ? "#ff9500" : "#e4e5e9"}
                                onMouseEnter={() => setHover(ratingValue)}
                                onMouseLeave={() => setHover(null)}
                            /> 

                            {ratingValue}


                        </RatingContainer>

                       

                    </label>   


                );

            }) }
            
            
            <BottomRow>

                <LeftSide>
                    Not Good
                </LeftSide>

                <RightSide>
                    Extremely good
                </RightSide>

            </BottomRow>

            {/* Your rating will be recorded as {rating/2} points out of 5. */}
   
        </AllRatings>
    )

}

const BottomRow = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    width: 360px;
    /* background-color: #ebe; */

    font-family: 'Fira Sans';
    font-weight: 300;
    font-size: 16px;

    padding-left: 12px;
    padding-right: 12px;


`;

const LeftSide = styled.div`
    
    width: 50%;
    justify-content: left;
    text-align: left;

    
    
`;
const RightSide = styled.div`
    
    width: 50%;
    justify-content: right;
    text-align: right;
`;


const AllRatings = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    width: 360px;
    margin-top: 16px;
    margin-bottom: 32px;
    

`;

const RatingContainer = styled.div`
    
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    justify-content: center;
    text-align: center;

    font-family: 'Fira Sans';
    font-weight: 300;
    font-size: 18px;


    width: 32px;

    margin-right: 4px;
    

`;

const FAIcon = styled(FaStar)`
    && {
    cursor: pointer;
    transition: color 200ms;
    padding-bottom: 2px;
    }
`;

const Raadio = styled.input`
    && {
    display: none;
    }
`;



export default StarRating


