import * as React from "react";
import { Route } from "react-router-dom";
import { Redirect } from 'react-router-dom';

import { graphql, withApollo } from "react-apollo";
//import { withApollo } from "react-apollo";
import gql from "graphql-tag";

import { ViewPhotog } from '../modules/photogs/ViewPhotog';
import { ViewModell } from '../modules/models/ViewModell';
import { ViewMuah } from '../modules/muahs/ViewMuah';
import { ViewStudio } from '../modules/studios/ViewStudio';
import { ViewEvent } from '../modules/events/ViewEvent';


const findURL = gql`
    query($URL: String!) {
        findURL(URL: $URL) {
            profileType,
            profileId,
            avatar,
        }
    }
`;


class C extends React.PureComponent {
    
    
    renderRoute = (routeProps) => {
        
        const { data, component } = this.props;

        if (!data || data.loading) {
            // loading .. (and not yet logged in because of that.. dont decide  anything yet) 
            return null;
        }

        // console.log(data.findURL)

        try {
            
            if (JSON.stringify(data.findURL).length > 15) {

                // console.log(data)
                const profileType = data.findURL.profileType
                const profileId = data.findURL.profileId

                // console.log(profileType)
                // console.log(profileId)

                if (profileType === 'photog') 
                    return <ViewPhotog {...routeProps} profileId={profileId}/>
                else if (profileType === 'modell')
                    return <ViewModell {...routeProps} profileId={profileId}/>
                else if (profileType === 'muah')
                    return <ViewMuah {...routeProps} profileId={profileId}/>
                else if (profileType === 'studio')
                    return <ViewStudio {...routeProps} profileId={profileId}/>
                else if (profileType === 'event')
                    return <ViewEvent {...routeProps} profileId={profileId}/>
                
                
                else {

                    //type was not detected. Return main page, as probably there is no such profile
                    return <Redirect to="/" />

                }

            }
            else {
                //faulty result.. render main page
                const Component = component;
                return <Component {...routeProps} />;
            }
        }
        catch {

            const Component = component;
            return <Component {...routeProps} />;

        }
        
    };
    

    render() {
        const { data: _, component: __, ...rest } = this.props;
        return <Route {...rest} render={this.renderRoute}/>;
    }


}

export const ProfileRedirect = graphql(findURL, { options: (props) => ({ variables: { URL: props.location.pathname } }) })(withApollo(C));