import React, { PureComponent } from 'react'

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

import "./index.css";
import { FooterSection } from "./_footerSection";
import { Link } from "react-router-dom";

import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export default class BecomeAnAffiliate extends PureComponent {

    constructor(props) {
        
        super(props);
        
        this.state = {
            selectedYearly: true,
            hideCTA: false,
        };
    
    }

    componentDidMount() {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        // ReactGA.send("pageview");
    }

    render() {

     

        
        return (
            
            

            <>

                <Helmet>
                    <title>PhotoSouls Affiliate Program - Earn by Referring Friends</title>
                    <meta name="description" content="Join PhotoSouls' Affiliate Program and earn with each referral. Enjoy a 30% commission on Pro subscriptions and 10% on bookings and service fees." />
                    <meta name="keywords" content="PhotoSouls affiliate program, photography referrals, earn commissions, affiliate rewards, Pro account subscription, referral program, photo community earnings, affiliate marketing, earn with photography, monetize photography network, lifetime commissions, booking fees commission" />
                </Helmet>

                {/* 0. Elevate Your Photography Career with PhotoSouls! */}
                <Section1img>

                    <RowCenter>
                        
                        <CenterTextBlock>

                            <H1>📸 PhotoSouls Affiliate Program: Share & Earn! 💰</H1>

                            <H1DescBigger>Refer people to PhotoSouls & get paid!</H1DescBigger>
                            
                        </CenterTextBlock>

                    </RowCenter>

                </Section1img>

              
                {/* 1. Land More Photoshoots and Earn More! */}
                <Section1>

                    <Row>
                        
                        <LeftBig>

                            <H1g>Spread the magic of PhotoSouls!</H1g>
                            <H1Desc>Invite others to join and earn big!</H1Desc>
                            <H1Desc>Your referrals get a special gift: a 6-month Pro account (worth $60) for free! And for you? A thank-you package that keeps rewarding you: Earn a continuous 30% commission on their Pro subscription payments and an additional 10% from booking and service fees.</H1Desc>
                            <H1Desc>This is a lifetime deal!</H1Desc>
                            
                        </LeftBig>

                        <EmojiText>✨</EmojiText>

                    </Row>

                </Section1>


                {/* 2. Yearning for more clients but don't have the time to hunt them down? */}
                <Section1>

                    <Row>
                        
                        <Left2Small2>
                            <EmojiText>💡</EmojiText>
                        </Left2Small2>

                        <Right2>
                            <H1Desc>Imagine this: You refer 100 photographers or models from your network. When they opt for a Pro account, you could bag a whopping $10800 in three years! Plus, rake in 10% from all their booking commissions and service fees. Forever.</H1Desc>
                            
                        </Right2>

                        

                    </Row>

                </Section1>


                {/* Get Paid Fast and Securely */}
                <SectionOrange>

                    <Row>
                        
                        <LeftBig>

                            <H1gWhite>Easy Start: How It Works</H1gWhite>
                            <H1DescWhite>Getting started is a breeze! Just invite all you friends and followers to check out your PhotoSouls profile. First-time visitors to PhotoSouls via your profile link are automatically tagged as your referrals.</H1DescWhite>
                            <H1DescWhite>When your referrals create a profile, they get a 6-month Pro account for free. You start earning from their subscriptions, and from the booking fees we collect from their bookings, immediately - and it never stops.</H1DescWhite>
                            <H1DescWhite>Even if your referral books someone else through PhotoSouls, you still earn from their booking fees. They are your lifetime referral!</H1DescWhite>
                            <H1DescWhite>It’s that simple!</H1DescWhite>
                            
                        </LeftBig>

                        <EmojiText>🌟</EmojiText>

                    </Row>

                </SectionOrange>

                {/* Collaborate with Models */}
                <SectionBlack>

                    <Row>
                        
                        <Left2Small2>
                            <EmojiText>✅</EmojiText>
                        </Left2Small2>

                        <Right2>
                            <H1gWhite>Transparent Tracking</H1gWhite>
                            <H1DescWhite>Keep tabs on your referrals and earnings anytime!</H1DescWhite>
                            <H1DescWhite>Check out the 'Payments & Payouts' section in your Account Settings.</H1DescWhite>
                            
                        </Right2>

                    </Row>

                </SectionBlack>



                <Section1Last>

                    <RowCenter>
                        
                        <CenterTextBlock>

                            <H1>Join Us in Making PhotoSouls Extraordinary!</H1>

                            <H1Desc>We're immensely grateful for your support!</H1Desc>
                            <H1Desc>Now, let's grow together!</H1Desc>

                        </CenterTextBlock>

                    </RowCenter>

                </Section1Last>

                <FooterSection/>

            </>

        )
    }
}



const Left2 = styled.div`
    
    width: 45%;
    padding-top: 32px;
    padding-bottom: 32px;

    /* background-color: #aea; */

    @media (max-width: 980px) {
        width: 100%;
        padding-bottom: 0px;
        
    }
    

`;

const Left2Small = styled.div`
    
    width: 25%;
    padding-top: 32px;
    padding-bottom: 32px;

    /* background-color: #aea; */

    @media (max-width: 980px) {
        width: 100%;
        padding-bottom: 0px;
        
    }
    

`;

const Left2Small2 = styled.div`
    
    width: 15%;
    padding-top: 32px;
    padding-bottom: 32px;

    /* background-color: #aea; */

    @media (max-width: 980px) {
        width: 100%;
        padding-bottom: 0px;
        
    }
    

`;

const Right2 = styled.div`
    
    width: 60%;
    padding-top: 32px;
    padding-bottom: 32px;


    /* background-color: #ced; */

    @media (max-width: 980px) {
        
        width: 100%;
        padding-left: 0px;
        padding-top: 0px;

    }
    

`;


const Right2Big = styled.div`
    
    width: 65%;
    padding-top: 32px;
    padding-bottom: 32px;


    /* background-color: #ced; */

    @media (max-width: 980px) {
        
        width: 100%;
        padding-left: 0px;
        padding-top: 0px;

    }
    

`;


const Right2Max = styled.div`
    
    padding-top: 32px;
    padding-bottom: 32px;


    /* background-color: #ced; */

    @media (max-width: 980px) {
        
        padding-left: 0px;
        padding-top: 0px;

    }
    

`;

const Row = styled.div`
    
    width: 1170px;

    margin: 0 auto;
    
    padding-left: 48px;
    padding-right: 24px;
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    justify-content: center;
    align-items: center;
        
    /* background-color: #f3f; */

    @media (max-width: 980px) {
        
        /* margin-top: 0px; */
        /* text-align: center;
        justify-content: center;
        align-items: center; */

    }

`;



const Section1 = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    background-color: #f3f2f0;
    background-image: linear-gradient(#fff,#f3f2f0);

    position: relative;
    z-index: 1;
    min-height: 200px;


    @media (max-width: 980px) {
        
        min-height: 200px;

    }


`;

const Section1Last = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    background-color: #f3f2f0;
    background-image: url('images/web/photog0.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    position: relative;
    z-index: 1;
    min-height: 300px;


    @media (max-width: 980px) {
        
        min-height: 300px;

    }


`;



const SectionOrange = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    background-color: #ff9500;

    position: relative;
    z-index: 1;
    min-height: 400px;


    @media (max-width: 980px) {
        
        min-height: 400px;

    }


`;

const SectionBlack = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    background-color: #000;

    position: relative;
    z-index: 1;
    min-height: 300px;


    @media (max-width: 980px) {
        
        min-height: 300px;

    }


`;


const Section1img = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    background-color: #f3f2f0;
    background-image: url('images/web/photog0.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    position: relative;
    z-index: 1;
    min-height: 450px;


    @media (max-width: 980px) {
        
        min-height: 400px;

    }


`;

const Left = styled.div`
    
    width: 45%;

    padding-bottom: 40px;
    padding-right: 24px;
    padding-top: 64px;
    padding-left: 32px;

    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 32px;
        padding-left: 0px;

    }
    

`;


const LeftBig = styled.div`
    
    width: 70%;

    padding-bottom: 40px;
    padding-right: 24px;
    padding-top: 32px;
    padding-left: 32px;

    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 32px;
        padding-left: 0px;

    }
    

`;


const CenterTextBlock = styled.div`
    
    width: 75%;

    padding-bottom: 40px;
    padding-right: 24px;

    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 32px;

    }
    

`;


const RowCenter = styled.div`
    
    max-width: 1170px;

    margin: 0 auto;
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    text-align: center;
    justify-content: center;
    align-items: center;

    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;

    padding-top: 48px;

    @media (max-width: 980px) {
        
        /* margin-top: 0px; */

    }

`;


const H1g = styled.h1`

    margin-top: 20px; 

    color: #333333;
    font-family: 'Fira Sans';
    font-size: 42px;
    

    @media (max-width: 970px) {

        font-size: 32px;

    }

`;

const H1gWhite = styled.h1`

    margin-top: 20px; 

    color: #fff;
    font-family: 'Fira Sans';
    font-size: 42px;
    

    @media (max-width: 970px) {

        font-size: 32px;

    }

`;

const EmojiText = styled.h1`

    color: #fff;
    font-family: 'Fira Sans';
    font-size: 100px;

    @media (max-width: 970px) {

        font-size: 32px;

    }

`;

const H1 = styled.h1`

    margin-top: 20px; 

    color: #ff9500;

    font-family: 'Fira Sans';
    font-size: 48px;
    

    @media (max-width: 970px) {

        font-size: 38px;

    }

`;


const H1Desc = styled.div`
    
    margin-top: 20px; 
    margin-bottom: 20px; 

    color: #333333;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 26px;
    
    @media (max-width: 970px) {

        font-size: 24px;

    }

`;

const H1DescBigger = styled.div`
    
    margin-top: 20px; 
    margin-bottom: 20px; 

    color: #333333;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 32px;
    
    @media (max-width: 970px) {

        font-size: 26px;

    }

`;


const H1DescWhite = styled.div`
    
    margin-top: 20px; 
    margin-bottom: 20px; 

    color: #fff;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 26px;
    
    @media (max-width: 970px) {

        font-size: 24px;

    }

`;


const H1DescSmaller = styled.div`
    
    margin-top: 32px; 
    margin-bottom: 20px; 

    color: #333333;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 28px;
    
    @media (max-width: 970px) {

        font-size: 24px;

    }

`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #3e6ca0;
    text-decoration: underline
    &:hover {
        color: #3e6ca0;
        text-decoration: underline
    }
`;



const BtnPrimary = styled(Button)`
    && {
    min-height: 64px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    
    color: white;
    
    padding-left: 32px;
    padding-right: 32px;
    margin-right: 32px;
    margin-top: 32px;

    text-transform: none;
    font-weight: 400;
    font-size: 22px;
    
    &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }

}     
`;



const S1img = styled.img`

    width: 100%;
    height: 100%;

    object-fit: cover;

    margin-left: auto;
    margin-right: auto;


`;


const Right = styled.div`
    
    width: 45%;
    padding-left: 48px;
    padding-bottom: 32px;

    /* background-color: #ced; */
    text-align: center;
    justify-content: center;
    align-items: center;


    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 0px;

    }
`;

const RightToBottom = styled.div`
    
    width: 45%;
    padding-left: 48px;
    margin-bottom: -64px;

    /* background-color: #ced; */
    text-align: center;
    justify-content: center;
    align-items: center;


    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 0px;
        margin-bottom: -4px;

    }
`;


const RightPhotoSmall = styled.div`
    
    width: 20%;

    padding-left: 32px;
    /* padding-bottom: 32px; */
    padding-top: 64px;

    /* background-color: #f3f2f0;
    background-image: url('images/web/_1test1.jpg');
    background-repeat: no-repeat;
    background-size: cover; */

    @media (max-width: 980px) {
        
        width: 100%;
        padding-left: 0px;
        padding-top: 0px;
    }
`;



const RightPhoto = styled.div`
    
    width: 20%;

    padding-left: 32px;
    /* padding-bottom: 32px; */
    padding-top: 64px;

    /* background-color: #f3f2f0;
    background-image: url('images/web/_1test1.jpg');
    background-repeat: no-repeat;
    background-size: cover; */

    @media (max-width: 980px) {
        
        width: 100%;
        padding-left: 0px;
        padding-top: 0px;
    }
`;

