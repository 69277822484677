import React, { PureComponent } from 'react'

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';
import styled from 'styled-components';

import { faLock, faAddressCard, faCreditCard, faBell, faCog, faUserCircle } from '@fortawesome/free-solid-svg-icons'
// import i18next from "i18next";
import { withStore } from '@spyna/react-store'
import Footer from '../../../modules/components/Footer/Footer'

import { Card } from "../../components/settings/Card";
import "./settings.css";
import './../../../i18n'

class AccountSettings extends PureComponent {

    
    componentDidMount() {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

    }

    

    render() {

        //this.props.store.remove('a_key', 'a value')
        
        const fName = this.props.store.get('fName');
        const lName = this.props.store.get('lName');


        return (

            
            <>

                <Helmet>
                    <title>Account Settings</title>
                    <meta name="description" content="Account Settings" />
                    <meta name="keywords" content="photosouls,account" />
                </Helmet>


                <ExtraWrapper>
                    

                        <div className='settingsHeader'>
                            Account settings
                        </div>

                        <div className='settingsHeader2'>
                            {fName} {lName}
                        </div>

                        <div className='settingsCardGrid'>

                            <Card
                                icon={faAddressCard}
                                title="Personal information"
                                desc="Provide personal details and how we can reach you"
                                link="settings/personal"
                            />

                            <Card
                                icon={faBell}
                                title="Notifications"
                                desc="Choose notification preferences and how you want to be contacted"
                                link="settings/notifications"
                            />

                            <Card
                                icon={faCog}
                                title="Global Preferences"
                                desc="Set content filtering, currency and time zone."
                                link="settings/global"
                            />

                            <Card
                                icon={faLock}
                                title="Login & Security"
                                desc="Update your password and secure your account"
                                link="settings/login"
                            />

                            <Card
                                icon={faCreditCard}
                                title="Payments & Payouts"
                                desc="Review payments, referral earnings, and payouts"
                                link="settings/payments"
                            />

                            <Card
                                icon={faUserCircle}
                                title="Talent Profiles"
                                desc="Manage your talent profiles and events"
                                link="profiles"
                            />
                            

                        
                        </div>


                        <IncreaseHeight64/>
                
                    <Footer showbutton="true"/>

                    

                
                </ExtraWrapper>
    

            </>

        )
    }
}

const personalInfoFields = [
    'fName', 'lName', 'bDay', 'email', 'phone', 'phoneDisplay', 'phoneCountry', 'avatar', 'isVerified', 'isPro', 'temperror', 'pwdUpdated',
    'chkReminders', 'chkRemindersP', 'chkMessages', 'chkMessagesP', 'chkRecomm', 'chkRecommP', 'chkInsp', 'chkInspP', 'chkLocal', 'chkLocalP', 
    'chkHow', 'chkHowP', 'chkInvite', 'chkInviteP', 'chkSurvey', 'chkSurveyP', 'chkPolicy', 'chkPolicyP', 'chkTips', 'chkTipsP'
]

export default withStore(AccountSettings, personalInfoFields);



const ExtraWrapper = styled.div`

    white-space: pre-line;
    width: calc(80% - 64px);
    max-width: 1200px;

    margin: 40px auto 32px auto;

    font-family: 'Fira Sans';

    @media (max-width: 790px) {
        width: calc(100% - 32px);
        margin: 0;
        overflow-x: hidden;
    }

`;

const IncreaseHeight64 = styled.div`
    
    height: 64px;  
    min-height: 64px;

`;