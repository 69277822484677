
import React, { Component } from 'react';
import styled from 'styled-components';

const StyledH3 = styled.h3`
  color: #ff9500;
  font-size: 18px;
  line-height: 18px;
  padding: 0px;
  margin: 0px;
  margin-bottom: 16px;
`;


export class MyH3 extends Component {
    
  render() {
      
    const { headline } = this.props;
    
    return (
      
      <StyledH3>{headline}</StyledH3>
    
    );

  }
}
