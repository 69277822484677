
import React, { Component } from 'react';
import styled from 'styled-components';
import TimeAgo from './../../../utils/TimeAgo'


export class ProfileButton extends Component {
    

    
  render() {
      
    
    // const { name, experience, city, country, travel, type, avatar, onClick} = this.props;

    // console.log(this.props.avatar)
    // name={profile.name}
    // experience={profile.experience}
    // city={profile.city}
    // country={profile.country}
    // travel={profile.travel}
    // type={'photog'}
    let experienceWord = '';
    let fullDesc = '';
    let cardAddress = '';
    let statusWord = '';

    // if (this.props.experience === 0) { experienceWord = "Beginner" }
    // else if (this.props.experience === 1) { experienceWord = "Hobbyist" }
    // else if (this.props.experience === 2) { experienceWord = "Experienced" }
    // else if (this.props.experience === 3) { experienceWord = "Very experienced" }
    // else  { experienceWord = "" }

    experienceWord = TimeAgo(this.props.createdAt)


    if (this.props.status === 0) { statusWord = "Inactive & hidden" } /* orange */ 
    else if (this.props.status === 1) { statusWord = "Visible to everyone" } /* green */
    else if (this.props.status === 2) { statusWord = "Visible to registered users only" } /* green */
    else if (this.props.status === 3) { statusWord = "Visible to Pro users only" } /* blue */ 
    else if (this.props.status === 4) { statusWord = "Visible to Verified Pro users" } /* purple */
    else  { statusWord = "" }

    if (this.props.type === 'photog') { fullDesc = 'Photographer, ' + experienceWord }
    else if (this.props.type === 'modell') { fullDesc = 'Model, ' + experienceWord}
    else if (this.props.type === 'muah') { fullDesc = 'Makeup / Hair / Stylist, ' + experienceWord }
    else if (this.props.type === 'studio') { fullDesc = 'Photo studio, ' + experienceWord }
    else if (this.props.type === 'event') { fullDesc = "September 18 - September 20, 2500 EUR" }

    if (this.props.address != null) { cardAddress = this.props.address }
    else { cardAddress = this.props.country }

    
    return (
      
        <>
            
            <CardDiv
                onClick={this.props.onClick}>

                <LeftArea>
                
                    { 
                        this.props.avatar ? 
                            <Avatar 
                                src={this.props.avatar} 
                                alt="Profile photo" 
                                onLoad={(e) => {
                                    //e.target.src = this.props.avatar --- causes huge CPU spike. but looks like not necessary anyway!
                                    e.target.style = 'display: flex' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                }}
                                onError={(e) => {
                                    //e.target.src = '' --- causes huge CPU spike. but looks like not necessary anyway!
                                    e.target.style = 'display: none' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                }}
                                
                            /> 
                            : 
                            <></>
                    }
                
                </LeftArea>

                <RightArea>

                    <CardTitle>{this.props.name}</CardTitle>
                    <SubTitle>{fullDesc}</SubTitle>
                    <SubTitle>{cardAddress}</SubTitle>

                    { this.props.status === 0 ? <HiddenTag>{statusWord}</HiddenTag> : <VisibleTag>{statusWord}</VisibleTag>} 
                    
                </RightArea>


            </CardDiv>

            

        </>
    
    );

  }
}

const CardDiv = styled.div`
    
    width: 250px;

    padding: 16px 24px 16px 24px;
    border-radius: 8px;
    margin: 16px;
    margin-left: 24px;

    background: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.3);
    
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;

    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    
    color: #333333;
    cursor: pointer;

    &:hover {
        background: #f8f8f8; 
        /* background: #F1F8E9; */
        }

    
    
`;

const CardTitle = styled.div`
    
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 4px;
`;

const SubTitle = styled.div`
    
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5em;
    margin-bottom: 4px;

`;

const Avatar = styled.img`

    height: 64px;
    width: 64px;
    border-radius: 40px;
    
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.4);

`;

const LeftArea = styled.div`
    
    width: 64px; 
    /* float: left */

`;

const RightArea = styled.div`
    
    padding-left: 16px;
    max-width: 170px;

`;


const HiddenTag = styled.div`
    
    text-align: left;
    float: left; 
    
    margin-top: 8px;
    padding: 4px;

    border-radius: 2px;
    

    color: #fff;
    background-color: rgba(255, 0, 0, 0.6);

    font-size: 16px;
    font-weight: 400;

    line-height: 1.2em;

`;

const VisibleTag = styled.div`
    
    text-align: left;
    float: left; 
    
    margin-top: 8px;

    color: #4c8c17;

    font-size: 16px;
    font-weight: 400;
    font-style: italic;

`;