
import React, { PureComponent } from 'react'
import { Form as AntForm } from "antd";
import { withFormik, Field, Form } from "formik";

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

import "./ForgotPassword.css";

import toast from 'toasted-notes' 
import 'toasted-notes/src/styles.css';

import { validEmailField } from "@psd/common";

import { MyInputField } from "../../components/MyInputField";
import { MyH1 } from "../../components/MyH1";
import { MyP } from "../../components/MyP";
import { MyError } from "../../components/MyError";
import { MyButton } from "../../components/MyButton";



const FormItem = AntForm.Item;



class C extends PureComponent {

    componentDidMount() {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }

    render() {

    const { loadingVisible } = this.props.state;

    return (
        
        
        <div className="ForgotWrapper">

      
            <Helmet>
                <title>Forgot your PhotoSouls password?</title>
                <meta name="description" content="Reset your PhotoSouls password with ease. Our secure password recovery process helps you quickly regain access to your account." />
                <meta name="keywords" content="photosouls,photographers,models,workshops" />
            </Helmet>
        

            <div className="ForgotRightSideWrapper">


                <MyH1 headline="Forgot your password?" />
                <MyH1 headline="-" />

                <MyP
                    p="To reset your password, type the email address you used to sign up for PhotoSouls.com and we'll send you an e-mail to walk you through resetting your password."
                />



                <Form>
                    
                    <Field
                        name="email"
                        label="Email"
                        component={MyInputField}
                    />
                        
                    {
                        this.props.errors.eMessage ? (
                            <MyError
                                message={this.props.errors.eMessage}
                                />
                            ): null
                    }

                    <FormItem>

                        <MyButton
                            type="submit"
                            caption='Send email'
                            loading={loadingVisible}
                            />

                    </FormItem>

                    
                    {/* <button onClick={this.openPopupbox}>Click me</button> */}

                </Form>


            </div>

        </div>


    )
  }
}



export const ForgotPasswordView = withFormik({
    
    validationSchema: validEmailField,
    
    mapPropsToValues: () => ({ email: ""}),
    
    handleSubmit: async (values, { props, setErrors }) => {
    
            
        props.updateLoader(true);

        try {

            values.email = values.email.toLowerCase();
            const apiResponse = await props.submit(values);
    
            props.updateLoader(false);
            
            if (apiResponse.responseType === "error") {

                setErrors({ eMessage: apiResponse.message, });
                
            }
            else if (apiResponse.data.sendForgotPasswordEmail) {          


                toast.notify(
                    <div>We have sent you an email with instructions how to reset your password</div>, 
                    { duration: 7000 }
                    );

                props.onFinish();

            }

        } catch (e) {
                           

            props.updateLoader(false);
            
            // if (e.graphQLErrors[0].message === "APIErorr") {

            //     setErrors({ eMessage: e.graphQLErrors[0].data[0].message, });

            // } else {

                setErrors({ eMessage: "Unable to connect to PhotoSouls. Please try again later", });

            // }


        }


    }
  })(C);
  