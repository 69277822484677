import * as React from "react";
import { LogoutController } from "@psd/controller";
import { CallLogout } from "./CallLogout";

// import ReactGA from 'react-ga4';
// import { logEvent } from '@psd/web/src/utils/analytics/amplitude';

export class Logout extends React.PureComponent {
    
    onFinish = () => {
        
        //ReactGA.event({ category: "User", action: "Logout", });
        // logEvent('logout')
        
        this.props.history.push("/");
        window.location.reload(); 
        
    };

    render() {
 
        return (
            
            <LogoutController>
                {({ logout }) => (
                    <CallLogout 
                        logout={logout} 
                        onFinish={this.onFinish} 
                            
                        />
                )}
            </LogoutController>
    
        );
    }

}
