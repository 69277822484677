import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history }) {

	useEffect(() => {
		
		const unlisten = history.listen(() => {
			window.scrollTo(0, 0);
		});
		
		return () => {
			unlisten();
		}
	
	}, [history]);

  return (null);
}

export default withRouter(ScrollToTop);



/* 

////  with Router version 6 upgrade 
/// FIXME:

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop({ children }) {
  const { pathname } = useLocation();
  
  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }

    window.scrollTo(0, 0);
  }, [pathname]);
  
  return children;
}



<Router>
  <ScrollToTop />
  <App />
</Router>


*/
