import React, { PureComponent } from 'react'
import { withApollo } from "react-apollo";
import { withStore } from '@spyna/react-store'

// import { Field } from "formik";

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'


// import moment from "moment"; 
import { v4 as uuidv4 } from 'uuid';

import Button from '@material-ui/core/Button';
import Modal from 'react-modal';

import { HostInfoCard } from "../../../components/profiles/HostInfoCard";
import { MyError } from "../../../components/MyError";
import ConfirmDelete from '../ConfirmDelete';

import ISO6391 from 'iso-639-1';
// import i18next from "i18next";
import '../../../../i18n'

import ReactQuill from 'react-quill';
import "./../../../../styles/quill.css";

import { ASSET_SERVER } from '../../../../config';

import gql from "graphql-tag"

export const editEvent = gql`
    mutation( $profileId: String! $input: EventInput! ) {
        editEvent( profileId: $profileId, input: $input ) { id }
    }
`;

const findURL = gql`
    query($URL: String!) {
        findURL(URL: $URL) {
            profileType,
            profileId,
            avatar,
        }
    }
`;


class C extends PureComponent { 

    constructor(props) {
        
        super(props);
        
        this.state = {
            showHostDialog: false,
        };
    
        this.fldValueChange = this.fldValueChange.bind(this);

          
    }

    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    findLngIndex = (lng) => {

        let foundIndex = 0;
        for (var i = this.props.values.intro.length-1; i >= 0; i--) {
            if (this.props.values.intro[i].lng === lng) {
                foundIndex = i;
            }
        }

        return foundIndex

    }

    fldValueChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({ [name]: value, });
        
    }


    

    toggleHostDialog = () => {  

        this.setState({ 
            showHostDialog: !this.state.showHostDialog 
        });  

    }  

    addHost = async () => {

        this.setState({
            hostId: '---#new#---',
            memberId: '',
            coverPhoto: '',
            hostConfirmDelete: false,
        }); 

        //set title field values to '' for each language
        this.props.values.languages.map((language, index) => {
            const fldHostTitleLng = 'fldHostTitle_' + language;
            const fldHostIntroLng = 'fldHostIntro_' + language;
            this.setState({ [fldHostTitleLng]: '', [fldHostIntroLng]: '', fldName: '', memberId: '', coverPhoto: '', })
            return null
        })

        this.toggleHostDialog();

    }

    findHostTitleLngIndex = (host, lng) => {

        let foundIndex = 0;
        for (var i = host.title.length-1; i >= 0; i--) {
            if (host.title[i].lng === lng) {
                foundIndex = i;
            }
        }

        if (host.title[foundIndex]) { 
            return host.title[foundIndex].title; 
        } else {
            return ""
        }
        

    }

    findHostIntroLngIndex = (host, lng) => {

        let foundIndex = 0;
        for (var i = host.intro.length-1; i >= 0; i--) {
            if (host.intro[i].lng === lng) {
                foundIndex = i;
            }
        }

        if (host.intro[foundIndex]) { 
            return host.intro[foundIndex].intro; 
        } else {
            return ""
       }

    }


    editHost = (host) => {

        this.setState({
            hostId: host.id,
            hostConfirmDelete: false,
        }); 

        // SEE ANNAB WARNINGU (kuigi töötab): no-loop-func
        // Selle asemel on all forEach, mis ei anna warningut

        // for (var i = this.props.values.hosts.length-1; i >= 0; i--) {
        //     if (this.props.values.hosts[i].id === host.id) { 
        //         this.props.values.languages.map((language, index) => {
        //             const fldHostTitleLng = 'fldHostTitle_' + language;
        //             const fldHostIntroLng = 'fldHostIntro_' + language;
        //             const titleValue = this.findHostTitleLngIndex(this.props.values.hosts[i], language);
        //             const introValue = this.findHostIntroLngIndex(this.props.values.hosts[i], language);
        //             this.setState({ 
        //                 [fldHostTitleLng]: titleValue, 
        //                 [fldHostIntroLng]: introValue, 
        //             })
        //             return null
        //         })
        //     } 
        // }

        this.props.values.hosts.forEach((dbhost) => {
            if (dbhost.id === host.id) { 
                this.props.values.languages.map((language, index) => {

                    const fldHostTitleLng = 'fldHostTitle_' + language;
                    const fldHostIntroLng = 'fldHostIntro_' + language;
                    const titleValue = this.findHostTitleLngIndex(dbhost, language);
                    const introValue = this.findHostIntroLngIndex(dbhost, language);

                    this.setState({ 
                        [fldHostTitleLng]: titleValue, 
                        [fldHostIntroLng]: introValue, 
                        fldName: dbhost.name,
                        memberId: dbhost.memberId,
                        coverPhoto: dbhost.coverPhoto,
                    })

                    return null

                })
            } 
        });


        this.toggleHostDialog();

    }


    saveHost = async () => {

        let allowedtoSave = true;

        this.props.values.languages.map((language, index) => {
            const fldHostTitleLng = 'fldHostTitle_' + language;
            const fldHostIntroLng = 'fldHostIntro_' + language;
            if ((this.state[fldHostTitleLng] === '') || (this.state[fldHostIntroLng] === '') || (this.state['fldName'] === '')) {
                setTimeout(() => { this.setState({ errorMsg: `Name, title, and intro fields cannot be left empty`, }); }, 50);
                allowedtoSave = false;
            }
            return null
        })


        if (allowedtoSave) {

            var tempuuid = uuidv4();

            var lngTitles = []
            var lngIntros = []
            await this.props.values.languages.map((language, index) => {
                const fldHostTitleLng = 'fldHostTitle_' + language;
                const fldHostIntroLng = 'fldHostIntro_' + language;
                lngTitles.push({ 
                    lng: language,
                    title: this.state[fldHostTitleLng]
                })
                lngIntros.push({ 
                    lng: language,
                    intro: this.state[fldHostIntroLng]
                })
                return null
            })


            if (this.state.hostId === '---#new#---') {
                
                var arr = this.props.values.hosts;
                
                await arr.push({
                    id: tempuuid, // generate our own id (as db id is generated on save, but we might not save the album yet), until the album is saved to the database.
                    name: this.state['fldName'],
                    memberId: this.state['memberId'],
                    title: lngTitles,
                    intro: lngIntros,
                    coverPhoto: this.state['coverPhoto'],
                })

                this.props.values.hosts = arr;
                
            } else {

                for (var i = this.props.values.hosts.length-1; i >= 0; i--) {

                    if (this.props.values.hosts[i].id === this.state.hostId) { 

                        tempuuid = this.props.values.hosts[i].id;
                        this.props.values.hosts[i].name = this.state['fldName'];
                        this.props.values.hosts[i].memberId = this.state['memberId'];
                        this.props.values.hosts[i].title = lngTitles;
                        this.props.values.hosts[i].intro = lngIntros;
                        this.props.values.hosts[i].coverPhoto = this.state['coverPhoto'];
                        
                    } 
                }

            }

           
            this.setState({
                host: '',
                hostId: '',
                errorMsg: '',
                memberId: '',
                coverPhoto: '',
                memberIdMissing: '',
            
            });

            this.forceUpdate();
            this.toggleHostDialog();
            this.updateHosts();

        }


    }

    cancelHost = () => {

        this.setState({
            host: '',
            hostId: '',
            errorMsg: '',
            memberId: '',
            coverPhoto: '',
            memberIdMissing: '',
            
        });

        this.forceUpdate();
        this.toggleHostDialog();

    }

    removeHost = (hostId) => {

        var hostsArray = this.props.values.hosts;
        var hostRemovedArray = hostsArray.filter(function(host) { return host.id !== hostId });
        this.props.values.hosts = hostRemovedArray;
        
        this.setState({  
            hostConfirmDelete: false,
            host: '',
            hostId: '',
            memberId: '',
            coverPhoto: '',
            memberIdMissing: '',
            
        });

        this.forceUpdate();
        this.toggleHostDialog();
        this.updateHosts();

    };


    updateHosts = () => {

        // some cleanup needed for Graphql to prevent model match errors
        var tempArray = this.props.values.hosts;
        for (var z = tempArray.length-1; z >= 0; z--) {
            delete tempArray[z].__typename
            for (var x = tempArray[z].title.length-1; x >= 0; x--) {
                delete tempArray[z].title[x].__typename
            }  
            for (var y = tempArray[z].intro.length-1; y >= 0; y--) {
                delete tempArray[z].intro[y].__typename
            }                                                
        }

        const inputValue = {
            hosts: tempArray
        }

        this.props.client.mutate({
            mutation: editEvent,
            variables: { profileId: this.props.values.id, input: inputValue },
        }).then(results => {
            //console.log('# updatePackages result: ', results)
        }).catch(error => {
            // console.log("Error: ", error);
        })


    } 

    moveHostUp = (index) => {

        let arr = this.props.values.hosts;
        [arr[index],arr[index-1]] = [arr[index-1],arr[index]]; 
        this.props.values.hosts = arr

        this.forceUpdate();
        this.updateHosts();

    };

    moveHostDown = (index) => {

        let arr = this.props.values.hosts;
        [arr[index],arr[index+1]] = [arr[index+1],arr[index]]; 
        this.props.values.hosts = arr

        this.forceUpdate();
        this.updateHosts();

    };

    toggleHostConfirmDelete = () => {  

        this.setState({  
            hostConfirmDelete: !this.state.hostConfirmDelete 
        });  

    }  


    render() {


        const modules = {

            toolbar: [

                //[{ 'header': [1, 2, false] }],
                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                //[{ 'size': ['16px', '18px', '20px', '22px'] }],
                //['bold', 'italic', 'underline','strike', 'blockquote'],
                ['bold', 'italic'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                [
                    {'color': 
                        // eslint-disable-next-line no-sparse-arrays
                        [
                        '#000000','#424242','#656565','#999999','#b7b7b7','#cbcbcb','#d9d9d9','#efefef','#f3f3f3','#ffffff',
                        '#8b1910','#ea3223','#f29d38','#fffd54','#74f84b','#73fbfd','#5686e1','#0023f5','#8a2ef5','#ea3ff7',
                        '#deb9b0','#edcdcc','#f8e5cf','#fdf1d0','#dbe9d4','#d2dfe2','#cbdaf5','#d2e1f1','#d7d2e7','#e6d1db',
                        '#d0816f','#de9c9a','#f1cba1','#fbe4a2','#bcd5ac','#a8c3c7','#a8c2ef','#a6c4e5','#b1a8d2','#cda8bb',
                        '#bd4a31','#d16d69','#ecb376','#f9d977','#9dc184','#7fa4ad','#769ee5','#7aa7d7','#8a7ebd','#b87e9e',
                        '#992a14','#bb2619','#da934b','#e9c251','#78a559','#537f8c','#4879d1','#4e84c0','#6251a1','#9b5377',
                        '#7a2816','#8c1a10','#a96224','#b8902f','#48732c','#254e5a','#2357c4','#22538f','#302070','#6a2345',
                        '#531606','#5d0d08','#704115','#7a601c','#304c1b','#16333b','#254582','#153760','#1d144a','#45162e',
                        ]
                    }, 
                    { 'background': 
                        [
                        '#000000','#424242','#656565','#999999','#b7b7b7','#cbcbcb','#d9d9d9','#efefef','#f3f3f3','#ffffff',
                        '#8b1910','#ea3223','#f29d38','#fffd54','#74f84b','#73fbfd','#5686e1','#0023f5','#8a2ef5','#ea3ff7',
                        '#deb9b0','#edcdcc','#f8e5cf','#fdf1d0','#dbe9d4','#d2dfe2','#cbdaf5','#d2e1f1','#d7d2e7','#e6d1db',
                        '#d0816f','#de9c9a','#f1cba1','#fbe4a2','#bcd5ac','#a8c3c7','#a8c2ef','#a6c4e5','#b1a8d2','#cda8bb',
                        '#bd4a31','#d16d69','#ecb376','#f9d977','#9dc184','#7fa4ad','#769ee5','#7aa7d7','#8a7ebd','#b87e9e',
                        '#992a14','#bb2619','#da934b','#e9c251','#78a559','#537f8c','#4879d1','#4e84c0','#6251a1','#9b5377',
                        '#7a2816','#8c1a10','#a96224','#b8902f','#48732c','#254e5a','#2357c4','#22538f','#302070','#6a2345',
                        '#531606','#5d0d08','#704115','#7a601c','#304c1b','#16333b','#254582','#153760','#1d144a','#45162e',
                        ] 
                    }
                ],
                [{ 'align': [] }],
                //['link', 'image'],
                ['clean'],

            ],

        }
        
        const formats = [

            'header', 'font', 'background', 'color', 'code', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent', 'script', 'align', 'direction',
            'link', 'image', 'code-block', 'formula', 'video'

        ]

        
        return (

           <PageContent>
                
                <PageTitle>Hosted by</PageTitle>
                

                <LinkButton onClick={this.addHost} >
                    Add host
                </LinkButton>

                <IncreaseHeight/>
                <DateCards>
                
                
                { // generate Photo title fields for each language
                    this.props.values.hosts.map((host, index) => {
                        
                        return (
                            <>

                                <HostCard
                                    key={index}
                                    >

                                    <GreyButtons>
                                        { 
                                            index === 0 ? <>&nbsp;</> :
                                                <GreyButton onClick={() => this.moveHostUp(index)} disabled={ index === 0 ? true : false} >
                                                    <FAIcon icon={faChevronUp} color={ index === 0 ? '#fff' : '#cbcbcb'} />
                                                </GreyButton>
                                        }
                                        { 
                                            index === this.props.values.hosts.length-1 ? <></> :
                                                <GreyButton onClick={() => this.moveHostDown(index)} disabled={ index === this.props.values.hosts.length-1 ? true : false} >
                                                    <FAIcon icon={faChevronDown} color={ index === this.props.values.hosts.length-1 ? '#fff' : '#cbcbcb'} />
                                                </GreyButton>
                                        }
                                    </GreyButtons>


                                    <CardClickOverlay onClick={ () => { this.editHost(host); } } ></CardClickOverlay>
                                    
                                    <HostInfoCard
                                        key={index}
                                        id={host}
                                        memberId={host.memberId}
                                        hostName={host.name}
                                        coverPhoto={host.coverPhoto}
                                        hostTitle={this.findHostTitleLngIndex(host, this.props.values.languages[0])}
                                        onClick={() => this.editHost(host)}
                                    />
                                        

                                </HostCard> 
                            </>
                        );
                    })
                }




                </DateCards>

                <Modal
                    isOpen={this.state.showHostDialog}
                    onRequestClose={this.toggleHostDialog}
                    shouldCloseOnOverlayClick={false}
                    style={customStyles2}
                    onAfterOpen={() => {
                            document.body.style.top = `0px`
                            document.body.style.width = `100vw`
                            document.body.style.position = 'fixed'
                        }}

                        onAfterClose={() => {
                            const scrollY = document.body.style.top
                            document.body.style.position = ''
                            document.body.style.top = ''
                            document.body.style.width = ''
                            window.scrollTo(0, parseInt(scrollY || '0') * -1)
                        }}
                >

                    { this.state.hostId === '---#new#---' ? 
                        <DialogTitle showAlways='true'>Add new host</DialogTitle> 
                        : 
                        <DialogTitle showAlways='true'>Edit host</DialogTitle> 
                    }
                    

                    <IncreaseHeight/>
                    <LabelStyled>Name</LabelStyled>
                    <InputStyled
                        name='fldName'
                        autoCapitalize = 'none'
                        autoComplete = 'off'
                        defaultValue={this.state['fldName']}
                        onChange={this.fldValueChange}
                    />
                    <IncreaseHeight/>
                    <LabelStyled>PhotoSouls ID (if exists)</LabelStyled>
                    <InputStyled
                        name='memberId'
                        autoCapitalize = 'none'
                        autoComplete = 'off'
                        defaultValue={this.state['memberId']}
                        onChange={this.fldValueChange}
                        onBlur={async () => {

                            let wasFound = false;
                            let avatarImage = '';
                            let memberProfileId = '';

                            await this.props.client
                                .query({ query: findURL, variables: { URL: '/' + this.state.memberId }, fetchPolicy: 'no-cache',})
                                .then(results => {

                                    if (JSON.stringify(results).length > 15) {
                                        if (results.data.findURL.profileId !== '') {
                                            wasFound = true;
                                            avatarImage = results.data.findURL.avatar;
                                            memberProfileId = results.data.findURL.profileId;
                                            //console.log(results.data.findURL)
                                        }
                                    }
                                })
                                .catch(error => { 
                                    // console.log(error) 
                                    })


                            if (wasFound) {

                                setTimeout(() => { 
                                    
                                    this.setState({ 
                                        memberIdMissing: '', 
                                        coverPhoto: ASSET_SERVER + '/profileavatar/256/' + memberProfileId + '/' + avatarImage 
                                    }); 

                                }, 10)
                                // console.log(this.statecoverPhoto)
                            }
                            else {

                                setTimeout(() => { 
                                    
                                    this.setState({ 
                                        coverPhoto: '' ,
                                        memberIdMissing: 'Member with this ID does not exists',  
                                    }); 

                                }, 10)
                            }


                        }}
                    />

                    { this.state.memberIdMissing ? ( <><IncreaseHeight/> <MyError message = {this.state.memberIdMissing} /></>) : <IncreaseHeight/> }
                    
                    <Avatar 
                        src={ this.state.coverPhoto } 
                        alt=" " 
                        onLoad={(e) => {
                            //e.target.src = this.props.avatar --- causes huge CPU spike. but looks like not necessary anyway!
                            e.target.style = 'display: flex' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                        }}
                        onError={(e) => {
                            //e.target.src = '' --- causes huge CPU spike. but looks like not necessary anyway!
                            e.target.style = 'display: none' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                        }}
                        
                    /> 

                    <IncreaseHeight/>

                    { // generate Album Name fields for each language
                        this.props.values.languages.map((language, index) => {
                            
                            const fldHostTitleLng = 'fldHostTitle_' + language;
                            const fldHostIntroLng = 'fldHostIntro_' + language;

                            return (
                                <div key={index}>
                                


                                    <IncreaseHeight/>
                                    <LabelStyled>Title {this.props.values.languages.length > 1 ? '(' + this.capitalize(ISO6391.getNativeName(language))+')' : '' }</LabelStyled>
                                    <InputStyled
                                        name={fldHostTitleLng}
                                        autoCapitalize = 'none'
                                        autoComplete = 'off'
                                        defaultValue={this.state[fldHostTitleLng]}
                                        onChange={this.fldValueChange}
                                    />
                                    
                                    <IncreaseHeight/>
                                    <LabelStyled>Intro {this.props.values.languages.length > 1 ? '(' + this.capitalize(ISO6391.getNativeName(language))+')' : '' }</LabelStyled>
                                    <ReactQuill
                                        key={index}
                                        onChange={(html) => {
                                            
                                            setTimeout(() => { 
                                                this.setState({ [fldHostIntroLng]: html }); 
                                            }, 10)

                                        }} 
                                        modules={modules}
                                        formats={formats}
                                        defaultValue={this.state[fldHostIntroLng]}
                                        theme={"snow"}
                                    />   
{/* 
                                    <InputStyled
                                        name={fldHostIntroLng}
                                        autoCapitalize = 'none'
                                        autoComplete = 'off'
                                        defaultValue={this.state[fldHostIntroLng]}
                                        onChange={this.fldValueChange}
                                    /> */}


                                    <IncreaseHeight/>

                                </div> 
                            );
                        })
                    }

                    { this.state.errorMsg ? ( <><IncreaseHeight/> <MyError message = {this.state.errorMsg} /> <IncreaseHeight/> </>) : null }
                    

                    <BottomButtonArea>
                            {
                                this.state.hostId !== "---#new#---" ? 
                                    (
                                        <ButtonAreaLeft>
                                            <StyledCancel onClick={this.toggleHostConfirmDelete}>Remove this host</StyledCancel>
                                        </ButtonAreaLeft>
                                    )
                                :
                                <ButtonAreaLeft></ButtonAreaLeft>
                            }
                        <ButtonAreaRight>

                            <StyledSave onClick={ () => { this.saveHost();} }>
                            Save</StyledSave>
                            
                            <StyledCancel onClick={ () => { this.cancelHost();} }>
                            Cancel</StyledCancel>

                        </ButtonAreaRight>
                        
                    </BottomButtonArea>

                </Modal>


                {
                    this.state.hostConfirmDelete ?  
                        <ConfirmDelete  
                            text='Are you sure you would like to remove this host?'
                            closePopup={this.toggleHostConfirmDelete}
                            onConfirm={() => this.removeHost(this.state.hostId)}
                        />
                        : 
                        <></>  
                }  

                
                <IncreaseHeight/>


            </PageContent>
            

        )

    }
    
}

export const HostPage = withStore(withApollo(C), ['']);


const LinkButton = styled.div`
    
    color: #4C9AFF;

    margin: 0 8px 0 0; 

    padding-top: 4px;
    font-weight: 400;
    font-size: 16px;

    &:hover {
        text-decoration: underline;
        cursor: pointer
        }

`;


const IncreaseHeight = styled.div`
    
    height: 24px;  

`;

const PageContent = styled.div`
    
    width: 100%;
    /* height: 100%; */
    /* min-height: 75vh; */
    font-family: 'Fira Sans';
    
`;

const PageTitle = styled.div`
    
    display: flex;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;

    display: none;
    @media (max-width: 790px) {
        display: block;
        }


`;

const LabelStyled = styled.div`
    width: 100%;
    margin: 0px;
    padding: 0px;
    color: #333333;
    font-size: 16px;
    
    margin-bottom: 4px;
    margin-top: 0px;
    margin-left: 2px;
`;

const DateCards = styled.div`

    width: 100%;
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    
    
    align-items: flex-start;
    justify-content: space-evenly;
   
    z-index: 200;

`;



const HostCard = styled.div`
    
    width: 220px;
    height: 200px;
    
    border-radius: 8px;

    margin-top: 0px;
    margin-bottom: 32px;

    border: 1px solid #ebebeb;
    box-shadow: 0 2px 8px 0 rgba(125, 125, 125, 0.2);
    
    cursor: pointer;
    position: relative;

    color: #333333;
    z-index: 300;

    &:hover {
        background: #f8f8f8; 
        transform: scale(1.02);
        }

    @media (max-width: 1000px) {
        width: 100%;
        min-width: 100%;
        margin-top: 12px;
    }

    z-index: 300;
    
`;

const CardClickOverlay = styled.div`

    float:left;
    position: absolute;

    margin-left: 0px;
    margin-top: 40px;

    width: 100%;
    height: calc(100% - 40px);

    &:hover {
        cursor: pointer;
    }

    z-index: 400;

`;



  const customStyles2 = {
    content : {
        boxShadow: '0 20px 50px rgba(0, 0, 0, 0.13)',
        borderRadius: '5px',
        height: '85%',
        overflow : 'auto',
        width: '80%',
        maxWidth: '600px',
        padding: '32px',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '999999'
    },
    overlay: {zIndex: 1000}

  };


const DialogTitle = styled.div`
    
    display: flex;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;

    display: block;

`;



const BottomButtonArea = styled.div`
    width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const ButtonAreaLeft = styled.div`
    width: 50%;
    min-width: 50%;
    justify-content: space-between;
`;


const ButtonAreaRight = styled.div`
    justify-content: space-between;
`;


const StyledCancel = styled(Button)`
    && {

    padding-left: 16px;
    padding-right: 16px;
    
    margin-bottom: 32px;

    height: 40px;
    top: 18px;
    color: #333333;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    }
    
`;

const StyledSave = styled(Button)`
    && {
    height: 40px;
    top: 18px;
    
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 16px;
    margin-bottom: 32px;

    background: #ff9500;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.4);
    color: white;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
        &:hover {
        background: #ff9500;
        opacity: 0.9;
        color: white;
        }
    }
    
`;


const InputStyled = styled.input`
    
    width: calc(100% - 18px);
    padding: 0px;
    margin: 0px;
    height: 24px;
    padding: 8px;
    font-size: 16px;
    font-family: 'Fira Sans';
    font-weight: 400;

    color: #333333;
    
    border: 1px solid #cbcbcb;
    border-radius: 4px;  
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    :focus {
        outline-color: #4C9AFF;
    }

    :disabled {
        background-color: #f7f7f7; 
    }


    @media (max-width: 690px) {
        font-size: 17px;
    }

`;


const GreyButtons = styled.div`

    display: flex;

    position: relative;

    margin-top: -32px;

    top: 40px;
    
    z-index: 800;

    width: calc(100%+8px);
    min-width: calc(100%+8px);

    flex-direction: row;
    justify-content: space-between;

`;

const GreyButton = styled(Button)`
    && {
        
    margin-top: -8px;

    height: 40px;
    min-width: 40px;
    max-width: 40px;

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;
    font-weight: 400;
    font-size: 17px;

    &:hover {
        background-color: #EBEBEB;
        opacity: 0.6;
        }
    
    &:disabled {
        background-color: #fff;
        color: #fff;
        }

    }

    z-index: 500;
    
`;

const FAIcon = styled(FontAwesomeIcon)`
    && {
    font-size: 16px;
    font-weight: 300;
    }
`;



const Avatar = styled.img`

    height: 80px;
    width: 80px;
    border-radius: 40px;
    
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.4);

`;
