import React, { PureComponent } from 'react'

import { Helmet } from "react-helmet";

import ReactGA from 'react-ga4';
import { withApollo } from "react-apollo";

import moment from "moment"; 

import Button from '@material-ui/core/Button';
import styled from 'styled-components';

import Modal from 'react-modal';

import { EditTerms } from "./EditTerms";
import { MyError } from "../components/MyError";

import { Query } from "react-apollo";
import gql from "graphql-tag";

const haldusQuery = gql` { me { id, isHaldaja, } } `;

const proUpgrade = gql`
    mutation proUpgrade($email: String, $interval: Int, $expires: String) {
        proUpgrade(email: $email, interval: $interval, expires: $expires)
        }
`;

const proDowngrade = gql`
    mutation proDowngrade($email: String) {
        proDowngrade(email: $email)
        }
`;


class C extends PureComponent {

    constructor(props) {
        
        super(props)
        
        const proExpires = moment(new Date()).add(1, 'y').format('YYYY-MM-DD');

        this.state = { 

            policyModalOpen: false,
            policyModalType: "terms",
            
            upDownModalOpen: false,
            upDownModalType: "up",

            errorMsg: "",
            proExpires: proExpires,
            proInterval: 12,

            actionStarted: false,

        } 

        this.fldValueChange = this.fldValueChange.bind(this);

    }

    componentDidMount() {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }


    fldValueChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({ [name]: value, });
        
    }


    proUpgrade = async () => {

        let resultOK = false;
        this.setState({ actionStarted: true })

        await this.props.client
            .mutate({
                mutation: proUpgrade,
                variables: {
                    email: this.state.proEmail, 
                    interval: this.state.proInterval, 
                    expires: this.state.proExpires,
                },
            })
            .then(results => {

                // console.log('proUpgrade result: ', results)

                if (results.data.proUpgrade === false) {
                    this.setState({ errorMsg: "proUpgrade returned false. Pro status was not set", actionStarted: false })
                } else {
                    resultOK = true;
                    this.setState({ errorMsg: "", actionStarted: false })
                }
                    

            })
            .catch(error => {
                console.log("Error: ", error);
                this.setState({ errorMsg: error, actionStarted: false })
            })

        if (resultOK) {
            this.setState({ upDownModalOpen: !this.state.upDownModalOpen })
        }
             
    }

    proDowngrade = async (email) => {

        let resultOK = false;
        this.setState({ actionStarted: true })

        await this.props.client
            .mutate({
                mutation: proDowngrade,
                variables: {
                    email: this.state.proEmail, 
                },
            })
            .then(results => {

                // console.log('proDowngrade result: ', results)

                if (results.data.proDowngrade === false) {
                    this.setState({ errorMsg: "proDowngrade returned false. Pro status was not changed", actionStarted: false })
                } else {
                    resultOK = true;
                    this.setState({ errorMsg: "", actionStarted: false })
                }
                    

            })
            .catch(error => {
                console.log("Error: ", error);
                this.setState({ errorMsg: error, actionStarted: false, })
            })

        if (resultOK) {
            this.setState({ upDownModalOpen: !this.state.upDownModalOpen })
        }
             
    }



    render() {

        const modalStyles = {
            overlay: { zIndex: 2000 },
            content: {

                top: `0`,
                left: '0',
                border: '0px',
                borderRadius: '0',
                
                height: `100vh`,
                width: `100%`,

                paddingTop: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                                                    

            }
        }
        
        return (
            
            <>

                <Helmet>
                    <title>Halduskekskond</title>
                </Helmet>

                <Query 
                    asyncMode 
                    query={haldusQuery} 
                >
    
                    {({ loading, error, data }) => {

                        if (loading) return (<></>);;
                        if (error) return (<></>);;

                        //console.log(data)

                        if (data === undefined) {

                            return (
                                <div>Sorry, we're having technical problems at this moment. Please try again later.</div> 
                            )    

                        } else

                        if (JSON.stringify(data).length > 15) {
                            
                            if (data.me.isHaldaja === true) {

                                return (
                                    
                                    <>

                                        {/* Admin title */}
                                        <div className="bodywrapper">
                                            <OneRow>
                                                <H2>Admin</H2>
                                            </OneRow>
                                        </div>

                                        {/* Terms & Policies */}
                                        <div className="bodywrapper">

                                            <OneRow>
                                                <H3>Terms & Policies</H3>
                                            </OneRow>
                                            <OneRow>

                                                <SectionBtn onClick={ () => this.setState({ policyModalOpen: true, policyModalType: "terms-of-service" }) }>Terms of Service</SectionBtn>
                                                <Separator>|</Separator>
                                                <SectionBtn onClick={ () => this.setState({ policyModalOpen: true, policyModalType: "privacy-policy" }) }>Privacy Policy</SectionBtn>
                                                <Separator>|</Separator>
                                                <SectionBtn onClick={ () => this.setState({ policyModalOpen: true, policyModalType: "cookie-policy" }) }>Cookie Policy</SectionBtn>
                                                
                                                <Separator> </Separator><Separator>|</Separator><Separator> </Separator>
                                                <SectionBtn onClick={ () => this.setState({ policyModalOpen: true, policyModalType: "payment-terms" }) }>Peyments Terms</SectionBtn>
                                                <Separator>|</Separator>
                                                <SectionBtn onClick={ () => this.setState({ policyModalOpen: true, policyModalType: "refund-policy" }) }>Refund Policy</SectionBtn>
                                                <Separator> </Separator><Separator>|</Separator><Separator> </Separator>

                                                <SectionBtn onClick={ () => this.setState({ policyModalOpen: true, policyModalType: "content-policy" }) }>Content Policy</SectionBtn>
                                                <Separator>|</Separator>
                                                <SectionBtn onClick={ () => this.setState({ policyModalOpen: true, policyModalType: "reviews-policy" }) }>Reviews Policy</SectionBtn>
                                                <Separator>|</Separator>
                                                <SectionBtn onClick={ () => this.setState({ policyModalOpen: true, policyModalType: "copyright-policy" }) }>Copyright Policy</SectionBtn>

                                            </OneRow>

                                            <Modal 
                                                isOpen={this.state.policyModalOpen}
                                                closeTimeoutMS={500}
                                                onRequestClose={() => { this.setState({ policyModalOpen: !this.state.policyModalOpen, })  }}
                                                ariaHideApp={false}
                                                style={modalStyles}
                                                
                                                onAfterOpen={() => {
                                                    document.body.style.top = `0px`
                                                    document.body.style.width = `100vw`
                                                    document.body.style.position = 'fixed'
                                                }}

                                                onAfterClose={() => {
                                                    const scrollY = document.body.style.top
                                                    document.body.style.position = ''
                                                    document.body.style.top = ''
                                                    document.body.style.width = ''
                                                    window.scrollTo(0, parseInt(scrollY || '0') * -1)
                                                }}
                                            >
                                            
                                                <EditTerms 
                                                    type={this.state.policyModalType}
                                                    hideModal={ () => this.setState({ policyModalOpen: false }) }
                                                />
                                                

                                            </Modal>
                                            
                                        </div>

                                        {/* Upgrade & Downgrade */}
                                        <div className="bodywrapper">

                                            <OneRow>
                                                <H3>Upgrade & downgrade</H3>
                                            </OneRow>
                                            <OneRow>

                                                <SectionBtn onClick={ () => this.setState({ upDownModalOpen: true, upDownModalType: "up" }) }>Upgrade</SectionBtn>
                                                <Separator></Separator>
                                                <SectionBtn onClick={ () => this.setState({ upDownModalOpen: true, upDownModalType: "down" }) }>Downgrade</SectionBtn>

                                            </OneRow>

                                            <Modal 
                                                isOpen={this.state.upDownModalOpen}
                                                closeTimeoutMS={500}
                                                onRequestClose={() => { this.setState({ upDownModalOpen: !this.state.upDownModalOpen, })  }}
                                                ariaHideApp={false}
                                                style={modalStyles}
                                                
                                                onAfterOpen={() => {
                                                    document.body.style.top = `0px`
                                                    document.body.style.width = `100vw`
                                                    document.body.style.position = 'fixed'
                                                }}

                                                onAfterClose={() => {
                                                    const scrollY = document.body.style.top
                                                    document.body.style.position = ''
                                                    document.body.style.top = ''
                                                    document.body.style.width = ''
                                                    window.scrollTo(0, parseInt(scrollY || '0') * -1)
                                                }}
                                            >
                                            
                                                <div className="bodywrapper">
                                                    
                                                    <OneRow>
                                                        <H3>{this.state.upDownModalType === "up" ? <>Upgrade an account in PhotoSouls</> : <>Downgrade the PhotoSouls account</> }</H3>
                                                    </OneRow>

                                                    <OneRow>
                                                    
                                                        <Label>Email:</Label>
                                                        <Separator></Separator>
                                                        <InputStyled
                                                            name={"proEmail"}
                                                            autoCapitalize = 'none'
                                                            autoComplete = 'off'
                                                            defaultValue={this.state.proEmail}
                                                            onChange={this.fldValueChange}
                                                        />
                                                    </OneRow>

                                                    {this.state.upDownModalType === "up" ? 
                                                        <>
                                                            <OneRow>
                                                                <Label>Expires:</Label>
                                                                <Separator></Separator>
                                                                <InputStyled
                                                                    name={"proExpires"}
                                                                    autoCapitalize = 'none'
                                                                    autoComplete = 'off'
                                                                    defaultValue={this.state.proExpires}
                                                                    onChange={this.fldValueChange}
                                                                />
                                                            </OneRow>

                                                            <OneRow>
                                                                <Label>Interval (months):</Label>
                                                                <Separator></Separator>
                                                                <InputStyled
                                                                    name={"proInterval"}
                                                                    autoCapitalize = 'none'
                                                                    autoComplete = 'off'
                                                                    defaultValue={this.state.proInterval}
                                                                    onChange={this.fldValueChange}
                                                                />
                                                            </OneRow>
                                                           
                                                        </>
                                                        : 
                                                        null
                                                    }

                                                    { this.state.errorMsg ? (<OneRow><MyError message = {this.state.errorMsg} /></OneRow>) : null }

                                                    <ButtonArea>
                                                        {this.state.upDownModalType === "up" ? 
                                                            <StyledSave 
                                                                onClick={ () => { this.proUpgrade(); }}
                                                                disabled={this.state.actionStarted}
                                                            >Upgrade</StyledSave> 
                                                            : 
                                                            <StyledSave 
                                                                onClick={ () => { this.proDowngrade(); }}
                                                                disabled={this.state.actionStarted}
                                                            >Downgrade</StyledSave> 
                                                        }
                                                        <BackButtonWithText onClick={() => { this.setState({ upDownModalOpen: !this.state.upDownModalOpen, }) } }>Cancel</BackButtonWithText>
                                                    </ButtonArea>

                                                    
                                                
                                                </div>
                                                

                                            </Modal>
                                            
                                        </div>

                                    </>
                                )

                            } else {

                                return ( <div className="bodywrapper"><div>TODO. Logged in</div></div>)

                            }

                        } else {

                            return ( <div className="bodywrapper"><div>TODO. Not logged in</div></div>)

                        } 
                        
                    }}
                </Query> 


               

            </>

        )
    }
}

const Haldus = (C);
export default withApollo(Haldus);


const SectionBtn = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 18px;

    color: #4C9AFF;

    text-decoration: underline;
    text-underline-offset: 2px;

    &:hover {
        cursor: pointer; 
        opacity: 1;
        font-weight: 500;
        }

    /* background-color: #ded; */

`;


const OneRow = styled.div`
    
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    flex-wrap: wrap;
    flex: 1;

    align-items: center;

`;

const Separator = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 18px;

    margin-left: 8px;
    margin-right: 8px;

    &:hover {
        cursor: pointer; 
        opacity: 1;
        font-weight: 500;
        }

    /* background-color: #ded; */

`;


const Label = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 18px;

    margin-top: 8px;

    width: 100px;

    &:hover {
        cursor: pointer; 
        opacity: 1;
        font-weight: 500;
        }

    /* background-color: #ded; */

`;


const H3 = styled.h2`

    color: #ff9500;
    font-family: 'Fira Sans';
    font-size: 22px;

`;

const H2 = styled.h2`

    color: #ff9500;
    font-family: 'Fira Sans';
    font-size: 28px;

`;


const InputStyled = styled.input`
    
    width: 300px;
    padding: 0px;
    margin: 0px;
    height: 24px;
    padding: 8px;
    font-size: 16px;
    font-family: 'Fira Sans';
    font-weight: 400;

    margin-top: 8px;

    color: #333333;
    
    border: 1px solid #cbcbcb;
    border-radius: 4px;  
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    :focus {
        outline-color: #4C9AFF;
    }

    :disabled {
        background-color: #f7f7f7; 
    }


    @media (max-width: 690px) {
        font-size: 17px;
    }

`;


const BackButtonWithText = styled(Button)`
    && {
    height: 40px;

    margin-left: 16px;

    margin-top: 8px;

    padding-left: 16px;
    padding-right: 16px;


    color: #333333;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    }
    
`;



const StyledSave = styled(Button)`
    && {
    height: 40px;

    background: #ff9500;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.4);
    color: white;
    
    margin-left: 16px;

    margin-top: 8px;

    padding-left: 16px;
    padding-right: 16px;
    
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
        &:hover {
            background: #ff9500;
            opacity: 0.9;
            color: white;
        }

        &:disabled {
            opacity: 0.5;
        }
        
    }
    
    
`;

const ButtonArea = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;


    width: 424px;


    margin-top: 24px;

    padding-bottom: 8px;
    padding-left: 8px;

    color: #333333;
   

`;


