import React, { PureComponent } from 'react'

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

import "./index.css";
import { FooterSection } from "./_footerSection";
import { UpgradeComparisonModal } from "../components/UpgradeComparisonModal";
import { Link } from "react-router-dom";

import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export default class BecomeAModel extends PureComponent {

    constructor(props) {
        
        super(props);
        
        this.state = {
            selectedYearly: true,
            showUpgradeModal: false,
            showUpgradeComparisonModal: false,
            hideCTA: false,
        };
    
    }

    componentDidMount() {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        // ReactGA.send("pageview");
    }

    render() {

     

        
        return (
            
            

            <>

                <Helmet>
                    <title>Become a Model | Launch Your Modeling Career with PhotoSouls</title>
                    <meta name="description" content="Aspiring to be a model? PhotoSouls offers you the platform to showcase your talent, connect with photographers, and step into the spotlight." />
                    <meta name="keywords" content="PhotoSouls, modeling, photographers, models, photoshoots, media companies, portfolio, modeling marketplace, professional photography, modeling community" />
                </Helmet>

                {/* 0. Let Your Modeling Dreams Soar with PhotoSouls! */}
                <Section1img>

                    <RowCenter>
                        
                        <CenterTextBlock>

                            <H1>Let Your Modeling Dreams Soar with PhotoSouls!</H1>

                            <H1DescBigger>Your story is unique, full of life, and just waiting to be told. At PhotoSouls, we're all about helping you bring that story to life. More than a place for stunning art, PhotoSouls paves your way to new discoveries and earning opportunities.</H1DescBigger>
                            
                            <H1DescSmaller>Whether you're a seasoned model seeking new adventures, or a newbie aiming to build a striking portfolio, we've got you covered.</H1DescSmaller>

                            <BtnPrimary onClick={ () => this.props.history.push({ pathname: 'signup/' }) }>CLICK HERE TO JOIN</BtnPrimary>

                        </CenterTextBlock>

                    </RowCenter>

                </Section1img>

              
                {/* The Right Connections Await */}
                <Section1>

                    <Row>
                        
                        <Left>

                            <H1g>The Right Connections Await</H1g>
                            <H1Desc>PhotoSouls is a vibrant marketplace that bridges the gap between models, photographers, and media companies. We're here to help you land more photoshoots, earn money, and connect with the perfect projects for you.</H1Desc>
                            <H1Desc>With a free membership, jumping on board is a breeze. Forget about endless searches for local photographers - we've streamlined that process for you.</H1Desc>
                            
                        </Left>

                        <RightPhoto>
                            <S1img src={'images/web/model1.png'} alt="Two photographers" />
                        </RightPhoto>

                    </Row>

                </Section1>


                {/* 2. Your Perfect Photographer is Just a Click Away */}
                <Section1>

                    <Row>
                        
                        <Left2>
                            <S1img src={'images/web/photog2.png'} alt="PhotoSouls on the laptop screen" />
                        </Left2>

                        <Right2>
                            <H1g>Your Perfect Photographer is Just a Click Away</H1g>
                            <H1Desc>Picture this - finding a photographer who can turn your vision into reality is as simple as clicking a button. From glamour shoots to fashion spreads, our platform is teeming with professionals eager to help you shine.</H1Desc>
                            <H1Desc>Our user-friendly search tool lets you zero in on exactly what you need. Whether you're after a particular location or a specific specialty, finding a photographer with the right skills and availability is now a walk in the park.</H1Desc>
                            
                        </Right2>

                    </Row>

                </Section1>


                {/* Boost Your Exposure and Hone Your Skills */}
                <SectionBlack>

                    <Row>
                        
                        <Left>

                            <H1gWhite>Boost Your Exposure and Hone Your Skills</H1gWhite>
                            <H1DescWhite>When you join PhotoSouls, you're stepping onto a bigger stage. Our photographers have a strong social media presence, which means your work gets to be seen by more people.</H1DescWhite>
                            <H1DescWhite>But that's not all - our photographers are also ready to give you valuable tips on how to strike a pose and make a statement in front of the camera, helping you boost your skills and confidence.</H1DescWhite>
                            
                        </Left>

                        <RightToBottom>
                            <S1img src={'images/web/photog4.jpg'} alt="Fast and secure payments" />
                        </RightToBottom>

                    </Row>

                </SectionBlack>

                {/* Get Paid for Doing What You Love */}
                <SectionOrange>

                    <Row>
                        
                        <Left2>
                            <S1img src={'images/web/photog3.png'} alt="Model exposure" />
                        </Left2>

                        <Right2>
                            <H1gWhite>Get Paid for Doing What You Love</H1gWhite>
                            <H1DescWhite>Reap the rewards of your passion with ease. When it's time to get paid for your work, we ensure a smooth and swift process. </H1DescWhite>
                            <H1DescWhite>Thanks to our secure payment systems, you can count on timely and accurate payments every time.</H1DescWhite>
                            
                        </Right2>

                    </Row>

                </SectionOrange>

                {/* Safety First */}
                <Section1>

                    <Row>
                        
                        <Left>

                            <H1g>Safety First</H1g>
                            <H1Desc>Our platform is designed to provide a safe environment, free from unwanted attention and dodgy photographers.</H1Desc>
                            <H1Desc>This way, you can concentrate on creating amazing photos without any worries.</H1Desc>
                            
                        </Left>

                        <Right>
                            <S1img src={'images/web/model6.png'} alt="Safe marketplace" />
                        </Right>

                    </Row>

                </Section1>

                {/* Stay on Top of Everything with Ease */}
                <Section1>

                    <Row>
                        
                        <Left2>
                            <S1img src={'images/web/photog6.png'} alt="Organizing is easy" />
                        </Left2>

                        <Right2>
                            <H1g>Stay on Top of Everything with Ease</H1g>
                            <H1Desc>With PhotoSouls, managing your schedule is a piece of cake.</H1Desc>
                            <H1Desc>You can see all your upcoming and past photoshoots in one place, and we'll even send you reminders for your upcoming shoots.</H1Desc>
                            
                        </Right2>

                    </Row>

                </Section1>

                {/* Thrive in a Lively Community */}
                <Section1>

                    <Row>
                        
                        <Left>

                            <H1g>Thrive in a Lively Community</H1g>
                            <H1Desc>Joining PhotoSouls means becoming part of an exciting, supportive network of models and photographers. </H1Desc>
                            <H1Desc>Connect, learn, and grow with people who share your passion, enriching your experience and speeding up your journey to success in the modeling industry.</H1Desc>
                            
                        </Left>

                        <Right>
                            <S1img src={'images/web/photog8.png'} alt="Community" />
                        </Right>

                    </Row>

                </Section1>

                {/* How much does it cost? */}
                <Section1>

                    <Row>
                        
                        <Left2>
                            <S1img src={'images/web/model9.png'} alt="Cost" />
                        </Left2>

                        <Right2>
                            <H1g>How much does it cost?</H1g>
                            <H1Desc>At PhotoSouls, our Hobby membership plan is available at no charge, providing you with access to many of our platform's features. We only take a 20% commission when you get a booking and receive payment.</H1Desc>
                            <H1Desc>For a broader range of features and benefits, consider upgrading to our Pro membership. It also includes a reduced commission fee of just 15%. Learn more about the advantages of the Pro membership by <StyledLink onClick={ () => { this.setState({ showUpgradeComparisonModal: true, hideCTA: false }) }}>clicking here.</StyledLink></H1Desc>
                            
                        </Right2>

                    </Row>

                </Section1>

                {/* The time is now! */}
                <Section1>

                    <RowCenter>
                        
                        <CenterTextBlock>

                            <H1>Your time is now!</H1>

                            <H1Desc>Boost your career and portfolio with PhotoSouls!</H1Desc>

                            <BtnPrimary onClick={ () => this.props.history.push({ pathname: 'signup/' }) }>Join Now!</BtnPrimary>

                        </CenterTextBlock>

                    </RowCenter>

                </Section1>

                <FooterSection/>

                { this.state.showUpgradeComparisonModal ? 
                    <UpgradeComparisonModal
                        showUpgradeComparisonModal={this.state.showUpgradeComparisonModal}
                        hideCTA={this.state.hideCTA}
                        closePopup={() => this.setState({ showUpgradeComparisonModal: false, hideCTA: false }) }
                    />
                : null
                }

            </>

        )
    }
}


const Left2 = styled.div`
    
    width: 45%;
    padding-top: 32px;
    padding-bottom: 32px;

    /* background-color: #aea; */

    @media (max-width: 980px) {
        width: 100%;
        padding-bottom: 0px;
        
    }
    

`;

const Right2 = styled.div`
    
    width: 45%;
    padding-top: 32px;
    padding-bottom: 64px;


    /* background-color: #ced; */

    @media (max-width: 980px) {
        
        width: 100%;
        padding-left: 0px;
        padding-top: 0px;

    }
    

`;




const Row = styled.div`
    
    width: 1170px;

    margin: 0 auto;
    
    padding-left: 48px;
    padding-right: 24px;
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    justify-content: center;
    align-items: center;
        
    /* background-color: #f3f; */

    @media (max-width: 980px) {
        
        /* margin-top: 0px; */
        /* text-align: center;
        justify-content: center;
        align-items: center; */

    }

`;




const Section1 = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    background-color: #f3f2f0;
    background-image: linear-gradient(#fff,#f3f2f0);

    position: relative;
    z-index: 1;
    min-height: 450px;


    @media (max-width: 980px) {
        
        min-height: 450px;

    }


`;

const SectionOrange = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    background-color: #ff9500;

    position: relative;
    z-index: 1;
    min-height: 450px;


    @media (max-width: 980px) {
        
        min-height: 450px;

    }


`;

const SectionBlack = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    background-color: #000;

    position: relative;
    z-index: 1;
    min-height: 450px;


    @media (max-width: 980px) {
        
        min-height: 450px;

    }


`;


const Section1img = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    background-color: #f3f2f0;
    background-image: url('images/web/photog0.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    position: relative;
    z-index: 1;
    min-height: 450px;


    @media (max-width: 980px) {
        
        min-height: 450px;

    }


`;

const Left = styled.div`
    
    width: 45%;

    padding-bottom: 40px;
    padding-right: 24px;
    padding-top: 64px;
    padding-left: 32px;

    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 32px;
        padding-left: 0px;

    }
    

`;


const CenterTextBlock = styled.div`
    
    width: 75%;

    padding-bottom: 40px;
    padding-right: 24px;

    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 32px;

    }
    

`;


const RowCenter = styled.div`
    
    max-width: 1170px;

    margin: 0 auto;
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    text-align: center;
    justify-content: center;
    align-items: center;

    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;

    padding-top: 48px;

    @media (max-width: 980px) {
        
        /* margin-top: 0px; */

    }

`;


const H1g = styled.h1`

    margin-top: 20px; 

    color: #333333;
    font-family: 'Fira Sans';
    font-size: 42px;
    

    @media (max-width: 970px) {

        font-size: 32px;

    }

`;

const H1gWhite = styled.h1`

    margin-top: 20px; 

    color: #fff;
    font-family: 'Fira Sans';
    font-size: 42px;
    

    @media (max-width: 970px) {

        font-size: 32px;

    }

`;

const H1 = styled.h1`

    margin-top: 20px; 

    color: #ff9500;

    font-family: 'Fira Sans';
    font-size: 48px;
    

    @media (max-width: 970px) {

        font-size: 38px;

    }

`;


const H1Desc = styled.div`
    
    margin-top: 20px; 
    margin-bottom: 20px; 

    color: #333333;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 26px;
    
    @media (max-width: 970px) {

        font-size: 24px;

    }

`;

const H1DescBigger = styled.div`
    
    margin-top: 20px; 
    margin-bottom: 20px; 

    color: #333333;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 32px;
    
    @media (max-width: 970px) {

        font-size: 26px;

    }

`;


const H1DescWhite = styled.div`
    
    margin-top: 20px; 
    margin-bottom: 20px; 

    color: #fff;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 26px;
    
    @media (max-width: 970px) {

        font-size: 24px;

    }

`;


const H1DescSmaller = styled.div`
    
    margin-top: 32px; 
    margin-bottom: 20px; 

    color: #333333;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 28px;
    
    @media (max-width: 970px) {

        font-size: 24px;

    }

`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #3e6ca0;
    text-decoration: underline
    &:hover {
        color: #3e6ca0;
        text-decoration: underline
    }
`;



const BtnPrimary = styled(Button)`
    && {
    min-height: 64px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    
    color: white;
    
    padding-left: 32px;
    padding-right: 32px;
    margin-right: 32px;
    margin-top: 32px;

    text-transform: none;
    font-weight: 400;
    font-size: 22px;
    
    &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }

}     
`;


const S1img = styled.img`

    width: 100%;
    height: 100%;

    object-fit: cover;

    margin-left: auto;
    margin-right: auto;


`;


const Right = styled.div`
    
    width: 45%;
    padding-left: 48px;
    padding-bottom: 32px;

    /* background-color: #ced; */
    text-align: center;
    justify-content: center;
    align-items: center;


    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 0px;

    }
`;

const RightToBottom = styled.div`
    
    width: 45%;
    padding-left: 48px;
    margin-bottom: -64px;

    /* background-color: #ced; */
    text-align: center;
    justify-content: center;
    align-items: center;


    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 0px;
        margin-bottom: -4px;

    }
`;


const RightPhoto = styled.div`
    
    width: 45%;

    padding-left: 32px;
    /* padding-bottom: 32px; */
    padding-top: 64px;

    /* background-color: #f3f2f0;
    background-image: url('images/web/_1test1.jpg');
    background-repeat: no-repeat;
    background-size: cover; */

    @media (max-width: 980px) {
        
        width: 100%;
        padding-left: 0px;
        padding-top: 0px;
    }
`;

