
import React, { Component } from 'react';
import styled from 'styled-components';



export class MyCategoryTag extends Component {
    
    render() {

        const catNumber = this.props.category;

        let catName = "";

        if (catNumber === 1) { catName = "Boudoir" }
        else if (catNumber === 2) { catName = "Couples" }
        else if (catNumber === 3) { catName = "Family" }
        else if (catNumber === 4) { catName = "Fashion" }
        else if (catNumber === 5) { catName = "Kids" }
        else if (catNumber === 6) { catName = "Lifestyle" }
        else if (catNumber === 7) { catName = "Newborn" }
        else if (catNumber === 11) { catName = "Nude" } // status visible on search filters and in profile detail page only if chkNSFW is set. On profile settings, option is allowed to be set.
        else if (catNumber === 8) { catName = "Portrait" }
        else if (catNumber === 9) { catName = "Pregnancy" }
        else if (catNumber === 10) { catName = "Wedding" }
        //
        else if (catNumber === 12) { catName = "Acting" }
        else if (catNumber === 13) { catName = "Art" }
        else if (catNumber === 14) { catName = "Bodypaint" }
        else if (catNumber === 15) { catName = "Cosplay" }
        else if (catNumber === 16) { catName = "Dance" }
        else if (catNumber === 17) { catName = "Editorial" }
        else if (catNumber === 18) { catName = "Fetish" } // status visible on search filters and in profile detail page only if chkNSFW is set. On profile settings, option is allowed to be set.
        else if (catNumber === 19) { catName = "Fitness" }
        else if (catNumber === 20) { catName = "Lingerie" }
        // else if (catNumber === 21) { catName = "Pinup" }
        //22 was reserved
        else if (catNumber === 23) { catName = "Promotional" }
        else if (catNumber === 24) { catName = "Runway" }
        else if (catNumber === 25) { catName = "Swimwear" }
        else if (catNumber === 26) { catName = "Topless" } // status visible on search filters and in profile detail page only if chkNSFW is set. On profile settings, option is allowed to be set.
        else if (catNumber === 27) { catName = "Underwater" }
        //
        else if (catNumber === 28) { catName = "Landscape" }
        else if (catNumber === 29) { catName = "Nature" }
        else if (catNumber === 30) { catName = "Pets" }
        else if (catNumber === 31) { catName = "Wildlife" }
        //
        else if (catNumber === 32) { catName = "Architecture" }
        else if (catNumber === 33) { catName = "Events" }
        else if (catNumber === 34) { catName = "Food" }
        else if (catNumber === 35) { catName = "Product" }
        else if (catNumber === 36) { catName = "Realestate" }
        else if (catNumber === 37) { catName = "Sport" }
        else if (catNumber === 38) { catName = "Street" }
        //
        else if (catNumber === 43) { catName = "Makeup & hair" }
        else if (catNumber === 40) { catName = "Makeup" }
        else if (catNumber === 39) { catName = "Hair" }
        else if (catNumber === 14) { catName = "Bodypaint" }
        else if (catNumber === 42) { catName = "Style" }

        return (
        
            <CategoryTag onClick={this.props.onClick}>
                {catName}
            </CategoryTag>
        
        );

    }
}

const CategoryTag = styled.div`
    
    /* display: flex; */

    margin-top: 1px;
    margin-right: 8px;
    margin-bottom: 8px;

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
    
    font-size: 16px;
    font-weight: 400;
    
    border-radius: 48px;

    border: 1px solid #ebebeb;
    box-shadow: 0 2px 8px 0 rgba(125, 125, 125, 0.2);

    
    /* box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2); */
    &:hover {
        background: #f8f8f8; 
        transform: scale(1.02);
        cursor: pointer;
        }
`;
