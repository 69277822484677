import React, { PureComponent } from 'react'

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

import "./index.css";
import { FooterSection } from "./_footerSection";
import { UpgradeComparisonModal } from "../components/UpgradeComparisonModal";
import { Link } from "react-router-dom";

import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export default class BecomeAPhotographer extends PureComponent {

    constructor(props) {
        
        super(props);
        
        this.state = {
            selectedYearly: true,
            showUpgradeModal: false,
            showUpgradeComparisonModal: false,
            hideCTA: false,
        };
    
    }

    componentDidMount() {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        // ReactGA.send("pageview");
    }

    render() {

     

        
        return (
            
            

            <>

                <Helmet>
                    <title>Become a Photographer | Start Your Photography Journey with PhotoSouls</title>
                    <meta name="description" content="Embark on a creative adventure with PhotoSouls and turn your passion for photography into a profession." />
                    <meta name="keywords" content="photography, PhotoSouls, photoshoots, models, portfolio, workshops, network, photographers" />
                </Helmet>

                {/* 0. Elevate Your Photography Career with PhotoSouls! */}
                <Section1img>

                    <RowCenter>
                        
                        <CenterTextBlock>

                            <H1>Elevate Your Photography Career with PhotoSouls!</H1>

                            <H1DescBigger>Are you a passionate photographer looking to give your career a powerful boost? Keen to earn more, partner with exciting models for creative ventures, and level up your skills through insightful workshops?</H1DescBigger>
                            
                            <H1DescSmaller>Your search ends with PhotoSouls, the one-stop marketplace <br/>for all photography services!</H1DescSmaller>

                            <BtnPrimary onClick={ () => this.props.history.push({ pathname: 'signup/' }) }>CLICK HERE TO JOIN</BtnPrimary>

                        </CenterTextBlock>

                    </RowCenter>

                </Section1img>

              
                {/* 1. Land More Photoshoots and Earn More! */}
                <Section1>

                    <Row>
                        
                        <Left>

                            <H1g>Land More Photoshoots and Earn More!</H1g>
                            <H1Desc>PhotoSouls is a platform built for photographers like you to flaunt your skills and connect with potential clients.</H1Desc>
                            <H1Desc>By being part of our community, you'll get the chance to earn more by offering your photography services and expanding your client base.</H1Desc>
                            
                        </Left>

                        <RightPhoto>
                            <S1img src={'images/web/photog1.png'} alt="Hands with cameras" />
                        </RightPhoto>

                    </Row>

                </Section1>


                {/* 2. Yearning for more clients but don't have the time to hunt them down? */}
                <Section1>

                    <Row>
                        
                        <Left2>
                            <S1img src={'images/web/photog2.png'} alt="PhotoSouls on the laptop screen" />
                        </Left2>

                        <Right2>
                            <H1g>Yearning for more clients but don't have the time to hunt them down?</H1g>
                            <H1Desc>PhotoSouls is a marketplace where anyone seeking a photoshoot can find you easily. All you need to do is to set up an account and complete your profile.</H1Desc>
                            <H1Desc>When clients search for a photographer in your area, they'll see your style, photos, pricing, and can book you on the spot!</H1Desc>
                            
                        </Right2>

                    </Row>

                </Section1>


                {/* Get Paid Fast and Securely */}
                <SectionOrange>

                    <Row>
                        
                        <Left>

                            <H1gWhite>Get Paid Fast and Securely</H1gWhite>
                            <H1DescWhite>We know how vital it is to get paid promptly and without any hitches.</H1DescWhite>
                            <H1DescWhite>That's why we've put secure payment systems in place to ensure fast and accurate payments for every photoshoot you complete.</H1DescWhite>
                            
                        </Left>

                        <RightToBottom>
                            <S1img src={'images/web/photog3.png'} alt="Fast and secure payments" />
                        </RightToBottom>

                    </Row>

                </SectionOrange>

                {/* Collaborate with Models */}
                <SectionBlack>

                    <Row>
                        
                        <Left2>
                            <S1img src={'images/web/photog4.jpg'} alt="Model collab" />
                        </Left2>

                        <Right2>
                            <H1gWhite>Collaborate with Models</H1gWhite>
                            <H1DescWhite>Finding the perfect model for your creative projects can be tricky, but PhotoSouls makes it a breeze.</H1DescWhite>
                            <H1DescWhite>We have a pool of models eager to team up with photographers, helping you find the ideal partner for your next shoot.</H1DescWhite>
                            
                        </Right2>

                    </Row>

                </SectionBlack>

                {/* Build and Showcase Your Portfolio */}
                <Section1>

                    <Row>
                        
                        <Left>

                            <H1g>Build and Showcase Your Portfolio</H1g>
                            <H1Desc>Whether you're a newbie or a seasoned photographer, PhotoSouls offers an incredible opportunity to build your portfolio.</H1Desc>
                            <H1Desc>Through collaborations with models on artistic projects, you'll get to showcase your work and attract more clients in the future.</H1Desc>
                            
                        </Left>

                        <Right>
                            <S1img src={'images/web/photog5.png'} alt="Photo portfolio" />
                        </Right>

                    </Row>

                </Section1>

                {/* Stay Organized with Ease */}
                <Section1>

                    <Row>
                        
                        <Left2>
                            <S1img src={'images/web/photog6.png'} alt="Collage of photos" />
                        </Left2>

                        <Right2>
                            <H1g>Stay Organized with Ease</H1g>
                            <H1Desc>Keep track of all your activities in one convenient place. No more frantic scrambles trying to remember when your next shoot is. Just log into your dashboard, and all the info you need is there at your fingertips.</H1Desc>
                            <H1Desc>Plus, PhotoSouls will send you reminders about upcoming photoshoots. With all client communication, including the delivery module, housed in one spot, PhotoSouls makes your life easier.</H1Desc>
                            
                        </Right2>

                    </Row>

                </Section1>

                {/* Learn from Workshops */}
                <Section1>

                    <Row>
                        
                        <Left>

                            <H1g>Learn from Workshops</H1g>
                            <H1Desc>PhotoSouls is your gateway to workshops that'll help you elevate your skills.</H1Desc>
                            <H1Desc>Learn from seasoned photographers who'll guide you on everything from location selection and lighting to composition and post-processing.</H1Desc>
                            
                        </Left>

                        <Right>
                            <S1img src={'images/web/photog7.png'} alt="Learn from Workshops" />
                        </Right>

                    </Row>

                </Section1>

                {/* <H1g>Expand Your Network</H1g> */}
                <Section1>

                    <Row>
                        
                        <Left2>
                            <S1img src={'images/web/photog8.png'} alt="Network" />
                        </Left2>

                        <Right2>
                            <H1g>Expand Your Network</H1g>
                            <H1Desc>Networking is a crucial part of success in the photography world. With PhotoSouls, you can create a profile that highlights your best work. It's an excellent opportunity to meet other photographers, models, makeup artists, and more - all of whom can help you propel your career to new heights.</H1Desc>
                            
                        </Right2>

                    </Row>

                </Section1>

                <Section1>

                    <Row>
                        
                        <Left>

                            <H1g>How much does it cost?</H1g>
                            {/* <H1Desc>PhotoSouls offers a free membership plan that grants you access to a wide range of tools and functionalities. When you secure a booking and receive payment, we take a 20% commission fee.</H1Desc> */}
                            {/* <H1Desc>Additionally, we offer a Pro membership plan with enhanced perks, including a reduced commission fee of 15%. For more details on the Pro membership benefits, <StyledLink onClick={ () => { this.setState({ showUpgradeComparisonModal: true, hideCTA: false }) }}>click here.</StyledLink></H1Desc> */}
                            <H1Desc>At PhotoSouls, our Hobby membership plan is available at no charge, providing you with access to many of our platform's features. We only take a 20% commission when you get a booking and receive payment.</H1Desc>
                            <H1Desc>For a broader range of features and benefits, consider upgrading to our Pro membership. It also includes a reduced commission fee of just 15%. Learn more about the advantages of the Pro membership by <StyledLink onClick={ () => { this.setState({ showUpgradeComparisonModal: true, hideCTA: false }) }}>clicking here.</StyledLink></H1Desc>
                            
                            
                        </Left>

                        <Right>
                            <S1img src={'images/web/photog9.png'} alt="How much does it cost" />
                        </Right>

                    </Row>

                </Section1>


                <Section1>

                    <RowCenter>
                        
                        <CenterTextBlock>

                            <H1>Ready to take the leap?</H1>

                            <H1Desc>Join PhotoSouls today and let your photography career soar!</H1Desc>

                            <BtnPrimary onClick={ () => this.props.history.push({ pathname: 'signup/' }) }>Join Now!</BtnPrimary>

                        </CenterTextBlock>

                    </RowCenter>

                </Section1>

                <FooterSection/>

                { this.state.showUpgradeComparisonModal ? 
                    <UpgradeComparisonModal
                        showUpgradeComparisonModal={this.state.showUpgradeComparisonModal}
                        hideCTA={this.state.hideCTA}
                        closePopup={() => this.setState({ showUpgradeComparisonModal: false, hideCTA: false }) }
                    />
                : null
                }

            </>

        )
    }
}



const Left2 = styled.div`
    
    width: 45%;
    padding-top: 32px;
    padding-bottom: 32px;

    /* background-color: #aea; */

    @media (max-width: 980px) {
        width: 100%;
        padding-bottom: 0px;
        
    }
    

`;

const Right2 = styled.div`
    
    width: 45%;
    padding-top: 32px;
    padding-bottom: 64px;


    /* background-color: #ced; */

    @media (max-width: 980px) {
        
        width: 100%;
        padding-left: 0px;
        padding-top: 0px;

    }
    

`;




const Row = styled.div`
    
    width: 1170px;

    margin: 0 auto;
    
    padding-left: 48px;
    padding-right: 24px;
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    justify-content: center;
    align-items: center;
        
    /* background-color: #f3f; */

    @media (max-width: 980px) {
        
        /* margin-top: 0px; */
        /* text-align: center;
        justify-content: center;
        align-items: center; */

    }

`;



const Section1 = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    background-color: #f3f2f0;
    background-image: linear-gradient(#fff,#f3f2f0);

    position: relative;
    z-index: 1;
    min-height: 450px;


    @media (max-width: 980px) {
        
        min-height: 450px;

    }


`;

const SectionOrange = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    background-color: #ff9500;

    position: relative;
    z-index: 1;
    min-height: 450px;


    @media (max-width: 980px) {
        
        min-height: 450px;

    }


`;

const SectionBlack = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    background-color: #000;

    position: relative;
    z-index: 1;
    min-height: 450px;


    @media (max-width: 980px) {
        
        min-height: 450px;

    }


`;


const Section1img = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    background-color: #f3f2f0;
    background-image: url('images/web/photog0.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    position: relative;
    z-index: 1;
    min-height: 450px;


    @media (max-width: 980px) {
        
        min-height: 450px;

    }


`;

const Left = styled.div`
    
    width: 45%;

    padding-bottom: 40px;
    padding-right: 24px;
    padding-top: 64px;
    padding-left: 32px;

    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 32px;
        padding-left: 0px;

    }
    

`;


const CenterTextBlock = styled.div`
    
    width: 75%;

    padding-bottom: 40px;
    padding-right: 24px;

    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 32px;

    }
    

`;


const RowCenter = styled.div`
    
    max-width: 1170px;

    margin: 0 auto;
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    text-align: center;
    justify-content: center;
    align-items: center;

    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;

    padding-top: 48px;

    @media (max-width: 980px) {
        
        /* margin-top: 0px; */

    }

`;


const H1g = styled.h1`

    margin-top: 20px; 

    color: #333333;
    font-family: 'Fira Sans';
    font-size: 42px;
    

    @media (max-width: 970px) {

        font-size: 32px;

    }

`;

const H1gWhite = styled.h1`

    margin-top: 20px; 

    color: #fff;
    font-family: 'Fira Sans';
    font-size: 42px;
    

    @media (max-width: 970px) {

        font-size: 32px;

    }

`;

const H1 = styled.h1`

    margin-top: 20px; 

    color: #ff9500;

    font-family: 'Fira Sans';
    font-size: 48px;
    

    @media (max-width: 970px) {

        font-size: 38px;

    }

`;


const H1Desc = styled.div`
    
    margin-top: 20px; 
    margin-bottom: 20px; 

    color: #333333;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 26px;
    
    @media (max-width: 970px) {

        font-size: 24px;

    }

`;

const H1DescBigger = styled.div`
    
    margin-top: 20px; 
    margin-bottom: 20px; 

    color: #333333;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 32px;
    
    @media (max-width: 970px) {

        font-size: 26px;

    }

`;


const H1DescWhite = styled.div`
    
    margin-top: 20px; 
    margin-bottom: 20px; 

    color: #fff;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 26px;
    
    @media (max-width: 970px) {

        font-size: 24px;

    }

`;


const H1DescSmaller = styled.div`
    
    margin-top: 32px; 
    margin-bottom: 20px; 

    color: #333333;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 28px;
    
    @media (max-width: 970px) {

        font-size: 24px;

    }

`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #3e6ca0;
    text-decoration: underline
    &:hover {
        color: #3e6ca0;
        text-decoration: underline
    }
`;



const BtnPrimary = styled(Button)`
    && {
    min-height: 64px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    
    color: white;
    
    padding-left: 32px;
    padding-right: 32px;
    margin-right: 32px;
    margin-top: 32px;

    text-transform: none;
    font-weight: 400;
    font-size: 22px;
    
    &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }

}     
`;



const S1img = styled.img`

    width: 100%;
    height: 100%;

    object-fit: cover;

    margin-left: auto;
    margin-right: auto;


`;


const Right = styled.div`
    
    width: 45%;
    padding-left: 48px;
    padding-bottom: 32px;

    /* background-color: #ced; */
    text-align: center;
    justify-content: center;
    align-items: center;


    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 0px;

    }
`;

const RightToBottom = styled.div`
    
    width: 45%;
    padding-left: 48px;
    margin-bottom: -64px;

    /* background-color: #ced; */
    text-align: center;
    justify-content: center;
    align-items: center;


    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 0px;
        margin-bottom: -4px;

    }
`;


const RightPhoto = styled.div`
    
    width: 45%;

    padding-left: 32px;
    /* padding-bottom: 32px; */
    padding-top: 64px;

    /* background-color: #f3f2f0;
    background-image: url('images/web/_1test1.jpg');
    background-repeat: no-repeat;
    background-size: cover; */

    @media (max-width: 980px) {
        
        width: 100%;
        padding-left: 0px;
        padding-top: 0px;
    }
`;

