
import React, { Component } from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



export class ProfileAddButton extends Component {
    
  render() {

    const { title, desc, icon, onClick} = this.props;

      
    return (
      
        <>
            
            <CardDiv
                onClick={onClick}>

                <LeftArea>
                
                    <MyIcon icon={icon}/>

                </LeftArea>

                <RightArea>

                    <CardTitle>{title}</CardTitle>
                    <SubTitle>{desc}</SubTitle>
                    
                </RightArea>


            </CardDiv>

            

        </>
    
    );

  }
}


const CardDiv = styled.div`
    
    width: 250px;

    padding: 16px 24px 16px 24px;
    border-radius: 8px;
    margin: 16px;
    margin-left: 24px;

    background: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.3);
    
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;

    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    
    color: #333333;

    &:hover {
        background: #f8f8f8; 
        /* background: #F1F8E9; */
        }

    
    
`;

const CardTitle = styled.div`
    
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 4px;
`;

const SubTitle = styled.div`
    
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5em;
    margin-bottom: 4px;

`;


const LeftArea = styled.div`
    
    width: 64px; 
    margin: auto;

    text-align: center;
    
    justify-content: center;
    align-items: center;
    
    /* float: left */

`;

const RightArea = styled.div`
    
    padding-left: 16px;
    width: calc(100% - 80px);
    
    /* float: right */

`;

const MyIcon = styled(FontAwesomeIcon)`
    && {
    color: #cbcbcb;
    padding-top: 8px;
    font-size: 32px;
    font-weight: 300;
    }
`;