import React, { PureComponent } from 'react'
import { withApollo } from "react-apollo";
import { v4 as uuidv4 } from 'uuid';

import Select from 'react-select'

import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import ConfirmDelete from './../ConfirmDelete';
import { MyError } from "./../../../components/MyError";
//import toast from 'toasted-notes' 

import ISO6391 from 'iso-639-1';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components';
import { UpgradeModal } from "../../../components/UpgradeModal";

import toast from 'toasted-notes' 
import 'toasted-notes/src/styles.css';


import { Mutation } from "react-apollo"
import { Query } from "react-apollo";
import gql from "graphql-tag"

import { ASSET_SERVER } from '../../../../config';


const UPLOAD_FILE_STREAM = gql`
    mutation uploadAlbumPhoto($file: Upload!, $albumId: String, $photoId: String) {
        uploadAlbumPhoto(file: $file, albumId: $albumId, photoId: $photoId) {
            filename, mimetype, encoding
        }
    }
`;

const addEditModellPhotoDetails = gql`
    mutation addEditModellPhotoDetails($mode: String!, $input: ModellPhotoInput!) {
        addEditModellPhotoDetails(mode: $mode, input: $input) { 
            id 
        }
    }
`;

const updatePhotosWithAlbumValues = gql`
    mutation($mudel: String!, $albumId: String, $category: Int, $visibility: Int, $nsfw: Boolean,) {
        updatePhotosWithAlbumValues(mudel: $mudel, albumId: $albumId, category: $category, visibility: $visibility, nsfw: $nsfw) 
    }
`;

const deleteAlbumPhoto = gql`
    mutation($photoId: String, $mudel: String, $albumId: String, $ext: String) {
        deleteAlbumPhoto(photoId: $photoId, mudel: $mudel, albumId: $albumId, ext: $ext) 
    }
`;

const deleteAlbumPhotos = gql`
    mutation($mudel: String, $albumId: String) {
        deleteAlbumPhotos(mudel: $mudel, albumId: $albumId) 
    }
`;

const changePhotoPosition = gql`
    mutation($photoId: String, $albumId: String, $mudel: String, $current: Int, $direction: String) {
        changePhotoPosition(photoId: $photoId, albumId: $albumId, mudel: $mudel, current: $current, direction: $direction) 
    }
`;

const getModellAlbumPhotos = gql`
    query ($albumId: String!) {
        getModellAlbumPhotos(albumId: $albumId) {
            photoId, albumId, ext, pw, ph, userId, parentId, title { lng, title }, nsfw, position
        }
    }
`;

export const editModell = gql`
    mutation( $profileId: String! $input: ModellInput! ) {
        editModell( profileId: $profileId, input: $input ) { name }
    }
`;


class C extends PureComponent { 


    constructor(props) {
        
        super(props);
        
        this.state = {
            activeView: 'albumList',
            selectedAlbumId: '',
            selectedAlbumCategory: 0,
            selectedAlbumNsfw: false,
            selectedAlbumVisibility: 0,
            selectedAlbumConfirmDelete: false,
            selectedPhotoConfirmDelete: false,
            uploadState: "none",
            uploadProgress: 'no progress',
            inputDialog: '--',
            selectionDialogFileName: '',
            photoCountInThisAlbum: 0,
            showUpgradeModal: false,
            showUpgradeModalNSFW: false,
            showUpgradeModalPhotoLimit: false,
            showUpgradeModalAlbumLimit: false,
        };
    
        this.fldNameValueChange = this.fldNameValueChange.bind(this);
        
          
    }
  

    async componentDidMount() {

        //ReactGA.pageview(window.location.pathname + window.location.search);

        this.setState({ uploadState: "none", });

        var isFileDialogOpened = false;
        var input = document.querySelector('input');
        var self=this;

        input.addEventListener('click', function () {
            //console.log('clicked')
            setTimeout(() => { self.setState({ inputDialog: 'opened', }); }, 50);
            isFileDialogOpened = true
        })

        input.addEventListener('blur', function () {
            // console.log('input blur')
            isFileDialogOpened = true
        })

        window.addEventListener('focus', function (e) {
            if (isFileDialogOpened) {
                //console.log('closed (window got focus)')
                setTimeout(() => { self.setState({ inputDialog: 'finished', }); }, 50);
                isFileDialogOpened = false
            }
        })

        this.props.showImmediateSaveLabel(true);
    
    }

    async componentWillUnmount() {

        this.props.showImmediateSaveLabel(false);
    
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.uploadState === "completed") {

            this.setState({ uploadState: "finalized" });

            //console.log('didupdate : ', this.state.uploadState);

        }

        

    }



    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    fldNameValueChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }
    
    
    addAlbum = async () => {


        let allowedToContinue = true;

        if (this.props.values.isPro === true) {
            // nothign extra needs to be restricted because of the Pro status
        } else {

            //user is not pro. So it means they cannot store more than 30 photos!
            if (this.props.values.albums.length >= 3) {

                this.setState({ showUpgradeModalAlbumLimit: true });
                allowedToContinue = false;

            } 

        }
        
        if (allowedToContinue) {


            this.setState({
                activeView: 'albumAddEdit',
                selectedAlbumId: '%%-----## -!new!- ##------%%',
                selectedAlbumCategory: 0,
                selectedAlbumNsfw: false,
                selectedAlbumVisibility: 0,
                selectedAlbumConfirmDelete: false,
            }); 

            //set title field values to '' for each language
            this.props.values.languages.map((language, index) => {
                const fldNameLng = 'fldName_' + language;
                this.setState({ [fldNameLng]: '' })
                return null
            })

            this.props.showSaveButton(false);
        
        }

    }

    addPhoto = async () => {


        let allowedToContinue = true;
        
        if (this.props.values.isPro === true) {
            // nothign extra needs to be restricted because of the Pro status
        } else {

            //user is not pro. So it means they cannot store more than 30 photos!
            if (this.props.values.photoCount >= 30) {

                this.setState({ showUpgradeModalPhotoLimit: true });
                allowedToContinue = false;

            } 

        }

        if (allowedToContinue) {


            let albumIsNSFW = false;
            for (var o = this.props.values.albums.length-1; o >= 0; o--) {
                if (this.props.values.albums[o].id === this.state.selectedAlbumId) { 
                    albumIsNSFW = this.props.values.albums[o].nsfw;
                } 
            }

            this.setState({
                activeView: 'photoAddEdit',
                selectedPhoto: '%%-----## -!new-photo!- ##------%%',
                selectedPhotoId: uuidv4(),
                selectedPhotoNsfw: albumIsNSFW,
                selectedPhotoCategory: this.state.selectedAlbumCategory,
                selectedPhotoVisibility: this.state.selectedAlbumVisibility,
                selectedPhotoConfirmDelete: false, 
            }); 

            //set title field values to '' for each language
            this.props.values.languages.map((language, index) => {
                const selectedPhotoName = 'selectedPhotoName_' + language;
                this.setState({ [selectedPhotoName]: '' })
                return null
            })

            await this.uploadPhoto.click()

            this.setState({ uploadState: "started", });
            
            this.props.showSaveButton(false);

        }

    }


    editAlbumDetails = (params) => {

        this.setState({
            activeView: 'albumAddEdit',
            selectedAlbumId: params.id,
            selectedAlbumCategory: params.category, 
            selectedAlbumNsfw: params.nsfw,
            selectedAlbumVisibility: params.visibility,
            selectedAlbumConfirmDelete: false, 
        }); 

        //set title field values to '' for each language
        this.props.values.languages.map((language, index) => {
            const fldNameLng = 'fldName_' + language;
            
            let mitmesElement = 0;
            for (var i = params.name.length-1; i >= 0; i--) {
                if (params.name[i].lng === language) { 
                    mitmesElement = i
                } 
            }

            this.setState({ [fldNameLng]: params.name[mitmesElement].name }) 
            return null
        })

        

            
        this.props.showSaveButton(false);

    }

    editPhotoDetails = (params) => {
        
        this.setState({
            
            activeView: 'photoAddEdit',
            selectedPhotoId: params.id,
            selectedPhotoExt: params.ext,
            selectedPhotoW: params.pw,
            selectedPhotoH: params.ph,
            selectedPhotoNsfw: params.nsfw,
            selectedPhotoCategory: params.category,
            selectedPhotoVisibility: params.visibility,
            selectedPhotoConfirmDelete: false, 

        }); 

        //set title field values to '' for each language
        this.props.values.languages.map((language, index) => {
            const selectedPhotoName = 'selectedPhotoName_' + language;
            
            let mitmesElement = 0;
            for (var i = params.title.length-1; i >= 0; i--) {
                if (params.title[i].lng === language) { 
                    mitmesElement = i
                } 
            }

            this.setState({ [selectedPhotoName]: params.title[mitmesElement].title }) 
            return null
        })

        this.props.showSaveButton(false);

        window.scrollTo({top: 0, behavior: 'smooth'});
    }


    saveAlbum = async () => {

        let allowedtoSave = true;

        //make sure no empty name fields!
        
        this.props.values.languages.map((language, index) => {
            const fldNameLng = 'fldName_' + language;
            if (this.state[fldNameLng] === '') {
                setTimeout(() => { this.setState({ errorMsg: `Album name field cannot be left empty`, }); }, 50);
                allowedtoSave = false;
            }
            return null
        })

        if (this.state.selectedAlbumCategory === 0) {
            setTimeout(() => { this.setState({ errorMsg: `Please select album category`, }); }, 50);
            allowedtoSave = false;
        }
        

        //check if any image in the album has NSFW set, then do not allow to uncheck it...
        let containsNSFWphotos = false;
        await this.props.client.query({ 
            query: getModellAlbumPhotos, 
            variables: { albumId: this.state.selectedAlbumId, }, 
            fetchPolicy: 'no-cache',}
        )
        .then(results => {
            
            if (JSON.stringify(results).length > 25) {

                const data = results.data.getModellAlbumPhotos;
                for (var q = data.length-1; q >= 0; q--) {
                    if (data[q].nsfw === true) { 
                        containsNSFWphotos = true;
                    }
                } 
            }
        })
        .catch(error => {
            console.log("Error: ", error);
        })


        if ((containsNSFWphotos) && (this.state.selectedAlbumNsfw === false )) {
            setTimeout(() => { this.setState({ errorMsg: `Some images in this album are marked as NSFW content. Please check the NSFW content setting for this album.`, }); }, 50);
            allowedtoSave = false;
        }



        var oldVisibility = '';
        var oldCategory = '';
        var oldNsfw = '';

        for (var o = this.props.values.albums.length-1; o >= 0; o--) {
            if (this.props.values.albums[o].id === this.state.selectedAlbumId) { 
                oldVisibility = this.props.values.albums[o].visibility;
                oldCategory = this.props.values.albums[o].category;
                oldNsfw = this.props.values.albums[o].nsfw;
            } 
        }


        if (this.props.values.isPro === true) {
            // nothign extra needs to be restricted because of the Pro status
        } else {

            //user is not pro. So it means they cannot use specific visibility settings
            if ((this.state.selectedAlbumVisibility === 0) ||  (this.state.selectedAlbumVisibility === 1)) {
                //no extra restrictions, as free users can use visibility settings 0 and 1
            } else {
                this.setState({ showUpgradeModal: true });
                allowedtoSave = false;
            } 


            if (this.state.selectedAlbumNsfw === true) {
                this.setState({ showUpgradeModalNSFW: true });
                allowedtoSave = false;
            } 


        }


        if (allowedtoSave) {

            var tempuuid = uuidv4();
            
            var lngNames = []
            await this.props.values.languages.map((language, index) => {
                const fldNameLng = 'fldName_' + language;
                lngNames.push({ 
                    lng: language,
                    name: this.state[fldNameLng]
                })
                return null
            })

            if (this.state.selectedAlbumId === '%%-----## -!new!- ##------%%') {
                
                var arr = this.props.values.albums;

                await arr.push({
                    id: tempuuid, // generate our own id (as db id is generated on save, but we might not save the album yet), until the album is saved to the database.
                    name: lngNames,
                    category: this.state.selectedAlbumCategory,
                    nsfw: this.state.selectedAlbumNsfw,
                    visibility: this.state.selectedAlbumVisibility,
                    photoCount: 0,
                })

                this.props.values.albums = arr;
                
            } else {

                for (var i = this.props.values.albums.length-1; i >= 0; i--) {
                    if (this.props.values.albums[i].id === this.state.selectedAlbumId) { 
                        tempuuid = this.props.values.albums[i].id;
                        this.props.values.albums[i].name = lngNames;
                        this.props.values.albums[i].category = this.state.selectedAlbumCategory;
                        this.props.values.albums[i].nsfw = this.state.selectedAlbumNsfw;
                        this.props.values.albums[i].visibility = this.state.selectedAlbumVisibility;
                        this.props.values.albums[i].photoCount = this.state.photoCountInThisAlbum;

                    } 
                }

            }

            // update all photos with this albumId, to have new values on the photo docs
            if (oldVisibility !== this.state.selectedAlbumVisibility
                || oldCategory !== this.state.selectedAlbumCategory
                || (oldNsfw !== this.state.selectedAlbumNsfw && this.state.selectedAlbumNsfw === true)
                ) {

                    this.props.client.mutate({
                        mutation: updatePhotosWithAlbumValues,
                        variables: { mudel: 'modell', albumId: this.state.selectedAlbumId, category: this.state.selectedAlbumCategory, visibility: this.state.selectedAlbumVisibility, nsfw: this.state.selectedAlbumNsfw },
                        refetchQueries: [{ query: getModellAlbumPhotos, variables: { 'albumId': this.state.selectedAlbumId } }],
                    }).then(results => {
                        // console.log('#result: ', results)
                    }).catch(error => {
                        // console.log("Error: ", error);
                    })
            
                }   

            this.setState({
                activeView: 'albumView',
                selectedAlbumId: tempuuid,
                selectedAlbumName: lngNames[0].name,
                errorMsg: '',
            }); 

            
            this.props.showSaveButton(true);

            this.forceUpdate();
            this.updateAlbums()

            // toast.notify(
            //     <div style={{ "color": "orange" }}>Once you are done editing your albums and photos, don't forget to press "Save & Exit" top right. Until then, your changes are not saved and refreshing the page will cancel your changes.</div>, 
            //     { duration: 5000 }
            //     );

        }

        

    }
    
    savePhoto = async () => {

        let allowedtoSave = true;

        //Requirement removed on 01-02-2023 by several beta user reuqests... 
        // this.props.values.languages.map((language, index) => {
        //     const selectedPhotoName = 'selectedPhotoName_' + language;
        //     if (this.state[selectedPhotoName] === '') {
        //         setTimeout(() => { this.setState({ errorMsg: `Photo title field cannot be left empty`, }); }, 50);
        //         allowedtoSave = false;
        //     }
        //     return null
        // })

        if (this.state.uploadState === 'started') {
            setTimeout(() => { this.setState({ errorMsg: `Photo upload not completed. Cannot save`, }); }, 50);
            allowedtoSave = false;
        }

        var photoCountChange = 0;
       



        if (this.props.values.isPro === true) {
            // no NSFW needs to be restriced with pro accounts
        } else {

            //user is not pro. restrict NSFW content
            if (this.state.selectedPhotoNsfw === true) {
                
                this.setState({ showUpgradeModalNSFW: true });
                allowedtoSave = false;
                
            } else {
               //no extra restrictions needed as content is not NSFW
            } 

        }


        if (allowedtoSave) {

            var lngTitles = []
            await this.props.values.languages.map((language, index) => {
                const selectedPhotoName = 'selectedPhotoName_' + language;
                lngTitles.push({ 
                    lng: language,
                    title: this.state[selectedPhotoName]
                })
                return null
            })

            if (this.state.selectedPhoto === '%%-----## -!new-photo!- ##------%%') {
                
                await this.props.client.mutate({
                    mutation: addEditModellPhotoDetails,
                    variables: { mode: 'add', input: {
                        'photoId': this.state.selectedPhotoId,
                        'ext': this.state.selectedPhotoExt,
                        'pw': this.state.selectedPhotoW,
                        'ph': this.state.selectedPhotoH,
                        'albumId': this.state.selectedAlbumId,
                        'parentId': this.props.values.id,
                        'title': lngTitles,
                        'nsfw': this.state.selectedPhotoNsfw,
                        'category': this.state.selectedPhotoCategory,
                        'visibility': this.state.selectedPhotoVisibility,
                        
                    }},
                    refetchQueries: ["getModellAlbumPhotos"],
                    // awaitRefetchQueries: true,

                }).then(results => {
                    // console.log('#mutation result ', results)
                }).catch(error => {
                    // console.log("Error: ", error);
                })

                photoCountChange = 1;

                
            } else {

                await this.props.client.mutate({
                    mutation: addEditModellPhotoDetails,
                    variables: { mode: 'edit', input: {
                        'photoId': this.state.selectedPhotoId,
                        'ext': this.state.selectedPhotoExt,
                        'pw': this.state.selectedPhotoW,
                        'ph': this.state.selectedPhotoH,
                        'albumId': this.state.selectedAlbumId,
                        'parentId': this.props.values.id,
                        'title': lngTitles,
                        'nsfw': this.state.selectedPhotoNsfw,
                        'category': this.state.selectedPhotoCategory,
                        'visibility': this.state.selectedPhotoVisibility,
                        
                    }},
                    refetchQueries: ["getModellAlbumPhotos"],
                    // awaitRefetchQueries: true,
                }).then(results => {
                    // console.log('#mutation result ', results)
                }).catch(error => {
                    // console.log("Error: ", error);
                })

                photoCountChange = 0;

            }

            this.uploadPhoto.files = null;


            //update album PhotoCount ...
            for (var i = this.props.values.albums.length-1; i >= 0; i--) {
                if (this.props.values.albums[i].id === this.state.selectedAlbumId) { 
                    
                    //if it's a first photo in album, set it as album cover also
                    if (this.state.photoCountInThisAlbum === 0) {
                        this.props.values.albums[i].coverPhotoURL = this.props.values.userId.id + '/' + this.state.selectedAlbumId + '/' + this.state.selectedPhotoId + this.state.selectedPhotoExt;
                        this.props.values.albums[i].cpw = this.state.selectedPhotoW;
                        this.props.values.albums[i].cph = this.state.selectedPhotoH;
                    }

                    this.props.values.albums[i].photoCount = this.state.photoCountInThisAlbum + photoCountChange;

                    //set nsfw option, if the photo was nsfw (but not the other way around: that without nsfw photo will remove nsfw option)
                    if (this.state.selectedPhotoNsfw) {
                        this.props.values.albums[i].nsfw = true;
                    }

                } 
            }

            setTimeout(() => { 
                this.setState({
                    activeView: 'albumView',
                    selectedPhoto: '',
                    selectedPhotoId: '',
                    selectedPhotoURL: '',
                    uploadState: "none",
                    selectionDialogFileName: '',
                    inputDialog: '--',
                    uploadProgress: 'no progress',
                    photoCountInThisAlbum:  this.state.photoCountInThisAlbum + photoCountChange,
                    errorMsg: '',
                }); 
            }, 50);
           

            this.props.showSaveButton(true);
            
            this.forceUpdate();
            this.updateAlbums()

            window.scrollTo({top: 0, behavior: 'smooth'});

        }
        

    }
   

    cancelAlbumEdit = () => {

        if (this.state.selectedAlbumId === '%%-----## -!new!- ##------%%') {

            this.setState({
                activeView: 'albumList',
                selectedAlbumId: '',
            }); 
        } else {

            this.setState({
                activeView: 'albumView',
                selectedAlbumId: this.state.selectedAlbumId,
                selectedAlbumName: this.state.selectedAlbumName,
                errorMsg: '',
            }); 
        }

        this.props.showSaveButton(true);
        this.forceUpdate();
    }

    cancelPhotoEdit = async () => {
        
        //make sure that when adding photo and it was selected (and thus already uploaded to aws), then in case cancelled, no residue is left in AWS
        if (this.state.selectedPhoto === '%%-----## -!new-photo!- ##------%%'
            && this.state.selectionDialogFileName !== '') {

                await this.props.client.mutate({
                    mutation: deleteAlbumPhoto,
                    variables: { photoId: this.state.selectedPhotoId, mudel: 'modell', albumId: this.state.selectedAlbumId, ext: this.state.selectedPhotoExt },
                    // awaitRefetchQueries: true,
                }).then(results => {
                    // console.log('#mutation result ', results)
                }).catch(error => {
                    // console.log("Error: ", error);
                })

        }

        this.setState({
            activeView: 'albumView',
            selectedPhoto: '',
            selectedPhotoId: '',
            selectedPhotoURL: '',
            uploadState: "none",
            selectionDialogFileName: '',
            inputDialog: '--',
            uploadProgress: 'no progress',
            errorMsg: '',
        }); 

        this.uploadPhoto.files = null;

        this.props.showSaveButton(true);
        this.forceUpdate();
        window.scrollTo({top: 0, behavior: 'smooth'});

    }


    deleteAlbum = (deleteAlbumId) => {

        var albumsArray = this.props.values.albums;
        var albumRemovedArray = albumsArray.filter(function(album) { return album.id !== deleteAlbumId });
        this.props.values.albums = albumRemovedArray;


        this.props.client.mutate({
            mutation: deleteAlbumPhotos,
            variables: { mudel: 'modell', albumId: deleteAlbumId, },
        }).then(results => {
            // console.log('#mutation result ', results)
        }).catch(error => {
            // console.log("Error: ", error);
        })


        this.setState({  
            selectedAlbumConfirmDelete: false,
            activeView: 'albumList',
            selectedAlbumId: '',
        });

        this.props.showSaveButton(true);

        this.forceUpdate();
        this.updateAlbums()

    };

    deletePhoto = async (deletePhotoId) => {

        await this.props.client.mutate({
            mutation: deleteAlbumPhoto,
            variables: { photoId: deletePhotoId, mudel: 'modell' },
            refetchQueries: ["getModellAlbumPhotos"],
            // awaitRefetchQueries: true,
        }).then(results => {
            // console.log('#mutation result ', results)

        }).catch(error => {
            // console.log("Error: ", error);
        })



        //Let's find the photo with smallest position number (to be set as cover photo)
        let firstPhotoURL = '';
        let firstPhotoPW = 0;
        let firstPhotoPH = 0;

        await this.props.client.query({ 
            query: getModellAlbumPhotos, 
            variables: { 
                albumId: this.state.selectedAlbumId,  
            }, 
            fetchPolicy: 'no-cache',}
        )
        .then(results => {
            
            if (JSON.stringify(results).length > 25) {

                let smallestPositionPoint = 99999;
                const data = results.data.getModellAlbumPhotos;

                for (var q = data.length-1; q >= 0; q--) {

                    if (data[q].position < smallestPositionPoint) { 

                        smallestPositionPoint = data[q].position;
                        firstPhotoURL = data[q].userId + '/' + data[q].albumId + '/' + data[q].photoId + data[q].ext;
                        firstPhotoPW = data[q].pw;
                        firstPhotoPH = data[q].ph;

                    }

                } 

            }

        })
        .catch(error => {
            console.log("Error: ", error);
        })

        

        //update album PhotoCount & cover photo, when needed...
        for (var i = this.props.values.albums.length-1; i >= 0; i--) {
            if (this.props.values.albums[i].id === this.state.selectedAlbumId) { 

                this.props.values.albums[i].photoCount = this.props.values.albums[i].photoCount - 1;
                this.props.values.albums[i].coverPhotoURL = firstPhotoURL;
                this.props.values.albums[i].cpw = firstPhotoPW;
                this.props.values.albums[i].cph = firstPhotoPH;

                
            } 
        }

        setTimeout(() => { 
            this.setState({
                activeView: 'albumView',
                selectedPhotoId: '',
                selectedPhotoURL: '',
                photoCountInThisAlbum: this.state.photoCountInThisAlbum - 1,
            }); 
        }, 50);

        this.props.showSaveButton(true);
        this.forceUpdate();
        this.updateAlbums()

        window.scrollTo({top: 0, behavior: 'smooth'});

    };

    updateAlbums = () => {

        // some cleanup needed for Graphql to prevent model match errors
        var tempArray = this.props.values.albums;
        for (var z = tempArray.length-1; z >= 0; z--) {
            delete tempArray[z].__typename
            for (var x = tempArray[z].name.length-1; x >= 0; x--) {
                delete tempArray[z].name[x].__typename
            }                                                
        }

        const inputValue = {
            albums: tempArray
        }

        this.props.client.mutate({
            mutation: editModell,
            variables: { profileId: this.props.values.id, input: inputValue },
        }).then(results => {
        }).catch(error => {
            // console.log("Error: ", error);
        })


    } 



    changePhotoPosition = async (photoId, ext, pw, ph, albumId, mudel, current, direction, index) => {

        await this.props.client.mutate({
            mutation: changePhotoPosition,
            variables: { photoId, albumId, mudel, current, direction },
            refetchQueries: [{ query: getModellAlbumPhotos, variables: { 'albumId': this.state.selectedAlbumId} }],
        }).then(results => {
            // console.log('# changePhotoPosition mutation result: ', results)
        }).catch(error => {
            // console.log("Error: ", error);
        })

        this.forceUpdate();

        //window.scrollTo({top: 0, behavior: 'smooth'});
        
        //if a photo becomes the first one, update album coverUrl.
        if (index === 1 && direction === 'up') {
            for (var i = this.props.values.albums.length-1; i >= 0; i--) {
                if (this.props.values.albums[i].id === albumId) { 
                    this.props.values.albums[i].coverPhotoURL = this.props.values.userId.id + '/' + albumId + '/' + photoId + ext;
                    this.props.values.albums[i].cpw = pw;
                    this.props.values.albums[i].cph = ph;
                    this.updateAlbums()
                } 
            }
        }


    } 


    moveAlbumUp = (index) => {

        let arr = this.props.values.albums;
        [arr[index],arr[index-1]] = [arr[index-1],arr[index]]; 
        this.props.values.albums = arr

        this.forceUpdate();
        this.updateAlbums()

    };

    moveAlbumDown = (index) => {

        let arr = this.props.values.albums;
        [arr[index],arr[index+1]] = [arr[index+1],arr[index]]; 
        this.props.values.albums = arr

        this.forceUpdate();
        this.updateAlbums()

    };

        
    toggleAlbumConfirmDelete = () => {  

        this.setState({  
            selectedAlbumConfirmDelete: !this.state.selectedAlbumConfirmDelete 
        });  

    }  

    togglePhotoConfirmDelete = () => {  

        this.setState({  
            selectedPhotoConfirmDelete: !this.state.selectedPhotoConfirmDelete 
        });  

    }  

    categoriesOptions = () => {
        return [
            { value: 12, code: 12, label: 'Acting' },
            { value: 32, code: 32, label: 'Architecture' },
            { value: 13, code: 13, label: 'Art' },
            { value: 14, code: 14, label: 'Bodypaint' },
            { value: 1, code: 1, label: 'Boudoir' },
            { value: 2, code: 2, label: 'Couples' },
            { value: 15, code: 15, label: 'Cosplay' },
            { value: 16, code: 16, label: 'Dance' },
            { value: 17, code: 17, label: 'Editorial' },
            { value: 33, code: 33, label: 'Event' },
            { value: 3, code: 3, label: 'Family' },
            { value: 18, code: 18, label: 'Fetish' },
            { value: 19, code: 19, label: 'Fitness' },
            { value: 34, code: 34, label: 'Food' },
            { value: 4, code: 4, label: 'Fashion' },
            { value: 5, code: 5, label: 'Kids' },
            { value: 28, code: 28, label: 'Landscape' },
            { value: 6, code: 6, label: 'Lifestyle' },
            { value: 20, code: 20, label: 'Lingerie' },
            { value: 29, code: 29, label: 'Nature' },
            { value: 7, code: 7, label: 'Newborn' },
            { value: 11, code: 11, label: 'Nude' },
            { value: 30, code: 30, label: 'Pets' },
            // { value: 21, code: 21, label: 'Pinup' },
            { value: 8, code: 8, label: 'Portrait' },
            { value: 9, code: 9, label: 'Pregnancy' },
            { value: 35, code: 35, label: 'Product' },
            { value: 23, code: 23, label: 'Promotional' },
            { value: 36, code: 36, label: 'Realestate' },
            { value: 24, code: 24, label: 'Runway' },
            { value: 37, code: 37, label: 'Sport' },
            { value: 38, code: 38, label: 'Street' },
            { value: 25, code: 25, label: 'Swimwear' },
            { value: 26, code: 26, label: 'Topless' },
            { value: 27, code: 27, label: 'Underwater' },
            { value: 10, code: 10, label: 'Wedding' },
            { value: 31, code: 31, label: 'Wildlife' },
        ]
    }

    categoryOptionLabel = (value) => {
        
        const allCategories = this.categoriesOptions();

        let label = '';
        for (var i = allCategories.length-1; i >= 0; i--) {
            if (allCategories[i].code === value) { 
                label = allCategories[i].label
            } 
        }

        return label
    }


    render() {

        const visibilityArr = [
            { value: 0, code: 0, label: 'Nobody. Keep it hidden' },
            { value: 1, code: 1, label: 'Everyone' },
            { value: 2, code: 2, label: 'Registered users only' },
            { value: 3, code: 3, label: 'Registered Pro members only' },
            { value: 4, code: 4, label: 'Verified Pro members only' },
        ];

        const visibilityArr4View = [
            { value: 0, code: 0, label: 'Hidden album' },
            { value: 1, code: 1, label: 'Visible to everyone' },
            { value: 2, code: 2, label: 'Visible to registered users only' },
            { value: 3, code: 3, label: 'Visible to registered Pro members only' },
            { value: 4, code: 4, label: 'Visible to verified Pro members only' },
        ];

        // const masonryOptions = {
        //     transitionDuration: 0
        // };
        
        // const imagesLoadedOptions = { 
        //     background: '#ebe'
        // }

        
        return (

            <PageContent>

                
                {
                    this.state.activeView === "albumList" ? 
                        (<>
                            <PageTitle>Albums</PageTitle>

                            <LinkButton onClick={this.addAlbum} >
                                Add new album 
                            </LinkButton>

                            <IncreaseHeight/>

                            <AlbumCards>
                                { 
                                    this.props.values.albums.map((album, index) => {

                                        const albumViewDetails = {
                                            activeView: 'albumView',
                                            selectedAlbumId: album.id,
                                            selectedAlbumName: album.name[0].name,
                                            selectedAlbumCategory: album.category, 
                                            selectedAlbumNsfw: album.nsfw,
                                            selectedAlbumVisibility: album.visibility,
                                            selectedAlbumConfirmDelete: false, 
                                        }

                                        //console.log(album.photoCount)

                                        return (

                                            <AlbumCard 
                                                key={index}
                                                >

                                                <GreyButtons>
                                                    { 
                                                        index === 0 ? <>&nbsp;</> :
                                                            <GreyButton onClick={() => this.moveAlbumUp(index)} disabled={ index === 0 ? true : false} >
                                                                <FAIcon icon={faChevronUp} color={ index === 0 ? '#fff' : '#cbcbcb'} />
                                                            </GreyButton>
                                                    }
                                                    { 
                                                        index === this.props.values.albums.length-1 ? <></> :
                                                            <GreyButton onClick={() => this.moveAlbumDown(index)} disabled={ index === this.props.values.albums.length-1 ? true : false} >
                                                                <FAIcon icon={faChevronDown} color={ index === this.props.values.albums.length-1 ? '#fff' : '#cbcbcb'} />
                                                            </GreyButton>
                                                    }
                                                </GreyButtons>

                                                <AlbumThumbCover onClick={ () => { this.setState(albumViewDetails); } }>
                                                    <AlbumThumb 
                                                        src={ASSET_SERVER + '/img/S/' + album.coverPhotoURL} 
                                                        alt=" " 
                                                        onLoad={(e) => {
                                                            //e.target.src = ASSET_SERVER + '/img/S/' + album.coverPhotoURL --- causes huge CPU spike. but looks like not necessary anyway!
                                                            e.target.style = 'display: flex' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                                        }}
                                                        onError={(e) => {
                                                            //e.target.src = '' --- causes huge CPU spike. but looks like not necessary anyway!
                                                            e.target.style = 'display: none' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                                        }}
                                                    />
                                                </AlbumThumbCover>

                                                <IncreaseHeight/>
                                                
                                                <AlbumCategoryLabel onClick={ () => { this.setState(albumViewDetails); } }>
                                                    { this.categoryOptionLabel(album.category) + ' (' + album.photoCount + ')'}
                                                </AlbumCategoryLabel>

                                                { album.nsfw ? <AlbumNsfwMark onClick={ () => { this.setState(albumViewDetails); } }>NSFW</AlbumNsfwMark> : null} 

                                                <AlbumDescLabel onClick={ () => { this.setState(albumViewDetails); } }>
                                                    { album.name[0].name }
                                                </AlbumDescLabel>
                                                
                                                <AlbumDescLabel 
                                                    style={{ clear: 'both', marginTop: '2px'}} 
                                                    onClick={ () => { this.setState(albumViewDetails); } }
                                                >
                                       
                                                    <AlbumVisibilityColor
                                                        availabilityCode={visibilityArr4View[album.visibility].code}
                                                    >
                                                    
                                                        { visibilityArr4View[album.visibility].label }
                                                        
                                                    </AlbumVisibilityColor>

                                                </AlbumDescLabel>

                                            </AlbumCard> 
                                        );
                                        
                                    })
                                }
                            </AlbumCards>

                            <IncreaseHeight/>
                            <IncreaseHeight/>

                            Tip: at top corners, albums and photos have buttons that you can use to change their order. On light images they might not be too apparent, but they are there ;)
                            <br/>
                            <br/>
                            To make it more enjoyable for visitors, all album cover photos will be shown using the 4:5 aspect ratio. These photos are also used on the search results for your profile card. Inside the album, all photos retain their original ratio.

                            <IncreaseHeight/>
                            <IncreaseHeight/>
                            
                            {/* CallToAction for Pro membership */}
                            { this.state.showUpgradeModalAlbumLimit ? 
                                <UpgradeModal
                                    showUpgradeModal={this.state.showUpgradeModalAlbumLimit}
                                    closePopup={() => this.setState({ showUpgradeModalAlbumLimit: false }) }
                                    popTitle="Your free Hobby account is limited to 3 albums."
                                    popDescription="To add more albums, please upgrade to the Pro account."
                                />
                            : null
                            }

                        </>)
                    :
                    this.state.activeView === "albumAddEdit" ? 
                        (<>
                           
                            { this.state.selectedAlbumId === '%%-----## -!new!- ##------%%' ? 
                                <PageTitle showAlways='true'>Add new album</PageTitle> 
                                : 
                                <PageTitle showAlways='true'>Edit album details</PageTitle> 
                            }
                            
                            { // generate Album Name fields for each language
                                this.props.values.languages.map((language, index) => {
                                    
                                    const fldNameLng = 'fldName_' + language;

                                    return (
                                        <div key={index}>
                                        
                                        <LabelStyled>Album name {this.props.values.languages.length > 1 ? '(' + this.capitalize(ISO6391.getNativeName(language))+')' : '' }</LabelStyled>
                                        <InputStyled
                                            name={fldNameLng}
                                            autoCapitalize = 'none'
                                            autoComplete = 'off'
                                            defaultValue={this.state[fldNameLng]}
                                            onChange={this.fldNameValueChange}
                                        />
                                       
                                        <IncreaseHeight/>
                                        </div> 
                                    );
                                })
                            }

                            <LabelStyled>Category</LabelStyled>
                            <Select 
                                name='albumCategory'
                                classNamePrefix="select"
                                styles={colourStyles}
                                options={this.categoriesOptions()} 
                                value={this.categoriesOptions().find(op => { return op.value === this.state.selectedAlbumCategory })} 
                                isSearchable={true}
                                onChange={(e) => { setTimeout(() => { this.setState({ selectedAlbumCategory: e.code, }); }, 50) }}
                            />

                            <IncreaseHeight/>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.state.selectedAlbumNsfw}
                                        onChange={(e) => { 
                                            const staatus = e.target.checked
                                            setTimeout(() => { this.setState({ selectedAlbumNsfw: staatus }); }, 50) 
                                            }}
                                        value="nsfw"
                                    />
                                }
                            label={'This album contains NSFW content'}
                            />       
                            
                            <IncreaseHeight/>
                            <LabelStyled>Who can see this album?</LabelStyled>
                            <Select 
                                name='albumVisibility'
                                classNamePrefix="select"
                                styles={colourStyles}
                                options={visibilityArr}
                                value={{value: this.state.selectedAlbumVisibility, label: visibilityArr[this.state.selectedAlbumVisibility].label}} 
                                isSearchable={true}
                                onChange={(e) => { setTimeout(() => { this.setState({ selectedAlbumVisibility: visibilityArr[e.value].code, }); }, 50) }}
                            />



                            <IncreaseHeight/>
                            { this.state.errorMsg ? ( <><IncreaseHeight/> <MyError message = {this.state.errorMsg} /> </>) : null }
                            <IncreaseHeight/>

                            <BottomButtonArea>
                                {
                                    this.state.selectedAlbumId !== "%%-----## -!new!- ##------%%" ? 
                                        (
                                            <ButtonAreaLeft>
                                                <StyledCancel onClick={this.toggleAlbumConfirmDelete}>Delete this album</StyledCancel>
                                            </ButtonAreaLeft>
                                        )
                                    :
                                    <ButtonAreaLeft></ButtonAreaLeft>
                                }

                                <ButtonAreaRight>
                                    <StyledSave onClick={this.saveAlbum}>Save</StyledSave> 
                                    <StyledCancel onClick={this.cancelAlbumEdit}>Cancel</StyledCancel>
                                </ButtonAreaRight>

                                {
                                    this.state.selectedAlbumConfirmDelete ?  
                                        <ConfirmDelete  
                                            text='Deleting the album will also delete all the photos you have in this album. This cannot be undone. Are you sure you would like to delete this album?'
                                            closePopup={this.toggleAlbumConfirmDelete}
                                            onConfirm={() => this.deleteAlbum(this.state.selectedAlbumId)}
                                        />
                                        : 
                                        <></>  
                                }  
                            </BottomButtonArea>

                            { this.state.showUpgradeModal ? 
                                <UpgradeModal
                                    showUpgradeModal={this.state.showUpgradeModal}
                                    closePopup={() => this.setState({ showUpgradeModal: false }) }
                                    popTitle="Your free Hobby accounts cannot set visibility restrictions."
                                    popDescription="You can either hide your album or make it visible to everyone. To restrict visibility to specific members, please upgrade to the Pro account."
                                />
                            : null
                            }

                            { this.state.showUpgradeModalNSFW ? 
                                <UpgradeModal
                                    showUpgradeModal={this.state.showUpgradeModalNSFW}
                                    closePopup={() => this.setState({ showUpgradeModalNSFW: false }) }
                                    popTitle="NSFW content is not allowed on free Hobby accounts"
                                    popDescription="For NSFW content, please upgrade to the Pro account."
                                />
                            : null
                            }


                        </>)
                    :
                    this.state.activeView === "albumView" ? 
                        (<>
                           
                            <AlbumViewHeader>

                                <StyledCancel 
                                    onClick={
                                        () => {
                                            this.setState({
                                                activeView: 'albumList',
                                                selectedAlbumId: '',
                                                selectedAlbumCategory: 0,
                                                selectedAlbumNsfw: false,
                                                selectedAlbumVisibility: 0,
                                                selectedAlbumConfirmDelete: false,
                                            });
                                            
                                        }
                                    }
                                >Back</StyledCancel> 

                                <HeaderInputContainer>
                                    <InputStyled
                                        name='albumView_titleField'
                                        disabled={true}
                                        defaultValue={this.categoryOptionLabel(this.state.selectedAlbumCategory) + ' / ' + this.state.selectedAlbumName}
                                    />
                                </HeaderInputContainer>

                                <div></div>

                                <StyledCancel 
                                    onClick={() => {
                                        
                                        let lngNames = '';
                                        for (var i = this.props.values.albums.length-1; i >= 0; i--) {
                                            if (this.props.values.albums[i].id === this.state.selectedAlbumId) { 
                                                lngNames = this.props.values.albums[i].name
                                            } 
                                        }

                                        this.editAlbumDetails({ 
                                            id: this.state.selectedAlbumId,
                                            name: lngNames,
                                            category: this.state.selectedAlbumCategory,
                                            nsfw: this.state.selectedAlbumNsfw,
                                            visibility: this.state.selectedAlbumVisibility,
                                        })

                                    }
                                    }
                                >Edit</StyledCancel> 

                            </AlbumViewHeader>

                            <LinkButton onClick={this.addPhoto} >
                                Add new photo 
                            </LinkButton>


                            <IncreaseHeight/>

                            <PhotoCards>
                                <Query 
                                    query={getModellAlbumPhotos}
                                    variables={{'albumId': this.state.selectedAlbumId}}
                                    fetchPolicy="network-only"
                                >
                                {
                                    
                                    ({ data }) => {
                                    
                                        //console.log('data 1: ', data)

                                        if (data === undefined) {
                                            return(<>
                                                <PageTitle>Sorry, we're having technical problems at this moment. Please try again later.</PageTitle>
                                            </>)
                                        } else
                                        
                                        if (JSON.stringify(data).length > 35) {
                                            
                                            // console.log('data 2: ', data) 
                                            setTimeout(() => { 
                                                this.setState({ photoCountInThisAlbum: data.getModellAlbumPhotos.length }); 
                                                for (var i = this.props.values.albums.length-1; i >= 0; i--) {
                                                    if (this.props.values.albums[i].id === this.state.selectedAlbumId) { 
                                                            this.props.values.albums[i].photoCount = this.state.photoCountInThisAlbum;
                                                            
                                                        } 
                                                }
                                            }, 50);

                                            return (<>
           
                                                { 
                                                    
                                                    data.getModellAlbumPhotos.map((photo, index) => {

                                                        const editDetails = { 
                                                            id: photo.photoId,
                                                            ext: photo.ext,
                                                            pw: photo.pw,
                                                            ph: photo.ph,
                                                            title: photo.title,
                                                            nsfw: photo.nsfw,
                                                            category: photo.category,
                                                            visibility: photo.visibility,
                                                        }

                                                        return (
                                                            
                                                            <PhotoCard 
                                                                key={index}
                                                                
                                                                >

                                                                <GreyButtons>
                                                                    { 
                                                                        index === 0 ? <>&nbsp;</> :
                                                                            <GreyButton onClick={() => this.changePhotoPosition(photo.photoId, photo.ext, photo.pw, photo.ph, this.state.selectedAlbumId, 'modell', photo.position, 'up', index)} disabled={ index === 0 ? true : false} >
                                                                                <FAIcon icon={faChevronUp} color={ index === 0 ? '#fff' : '#cbcbcb'} />
                                                                            </GreyButton>
                                                                    }
                                                                    { 
                                                                        index === data.getModellAlbumPhotos.length-1 ? <></> :
                                                                            <GreyButton onClick={() => this.changePhotoPosition(photo.photoId, photo.ext, photo.pw, photo.ph, this.state.selectedAlbumId, 'modell', photo.position, 'down', index)} disabled={ index === data.getModellAlbumPhotos.length-1 ? true : false} >
                                                                                <FAIcon icon={faChevronDown} color={ index === data.getModellAlbumPhotos.length-1 ? '#fff' : '#cbcbcb'} />
                                                                            </GreyButton>
                                                                    }
                                                                </GreyButtons>

                                                                <PhotoInAlbumView
                                                                    onClick={() => this.editPhotoDetails(editDetails)}
                                                                    src={ASSET_SERVER + '/img/S/' + photo.userId + '/' + photo.albumId + '/' + photo.photoId + photo.ext} 
                                                                    alt=" " 
                                                                    
                                                                />

                                                                <PhotoLabel onClick={() => this.editPhotoDetails(editDetails)}>
                                                                    { index+1 + ': ' + photo.title[0].title }
                                                                </PhotoLabel>

                                                                { photo.nsfw ? <PhotoNsfwMark onClick={() => this.editPhotoDetails(editDetails)}>NSFW</PhotoNsfwMark> : null} 

                                                            </PhotoCard> 
                                                        
                                                        );
                                                    })
                                                
                                                }

                                            </>);

                                        }
                                        
                                        return null; //<div>Loading ...</div>   -->> see pole õige, sest ei pruugi olla loading, võib olla et lihtsalt poelgi veel ühtegi albumit...
                                    
                                    }

                                }
                                </Query>
                            </PhotoCards>

                            <IncreaseHeight/>
                            <IncreaseHeight/>
                            {/* CallToAction for Pro membership */}
                            { this.state.showUpgradeModalPhotoLimit ? 
                                <UpgradeModal
                                    showUpgradeModal={this.state.showUpgradeModalPhotoLimit}
                                    closePopup={() => this.setState({ showUpgradeModalPhotoLimit: false }) }
                                    popTitle="Your free Hobby account is limited to 30 photos."
                                    popDescription="To add more photos, please upgrade to the Pro account."
                                />
                            : null
                            }
                         
                        </>)
                    :
                    this.state.activeView === "photoAddEdit" ? 
                        (<>
                            
                            { this.state.selectedPhoto === '%%-----## -!new-photo!- ##------%%' ? 
                                <>
                                    <PageTitle showAlways='true'>Add new photo</PageTitle> 
                                    {
                                        this.state.selectedPhotoURL ?
                                                <PhotoInAddEditView 
                                                    src={this.state.selectedPhotoURL} 
                                                    alt=" " 

                                                />
                                            :
                                            <>
                                                <IncreaseHeight/>
                                                {
                                                    this.state.uploadState === 'started' ? (
                                                        <UploadSpinner>
                                                            <UploadSpinnerImage src={require('./../../../../assets/orangeLoader.gif')} />
                                                            <div style={{ textAlign: "center" }}>Uploading photo, please wait...</div>
                                                        </UploadSpinner>
                                                    )
                                                    :
                                                    null
                                                }
                                                <IncreaseHeight/>
                                            </>
                                    }
                                </>
                                :
                                <> 
                                    <PageTitle showAlways='true'>Edit photo details</PageTitle> 
                                    
                                    <PhotoInAddEditView 
                                            src={ASSET_SERVER + '/img/L/' + this.props.values.userId.id + '/' + this.state.selectedAlbumId + '/' + this.state.selectedPhotoId + this.state.selectedPhotoExt} 
                                            alt=" " 
                                        />

                                </>
                            }
                            
                            <IncreaseHeight/>

                            { // generate Photo title fields for each language
                                this.props.values.languages.map((language, index) => {
                                    
                                    //console.log(ASSET_SERVER + '/img/L/' + this.props.values.userId.id + '/' + this.state.selectedAlbumId + '/' + this.state.selectedPhotoId + this.state.selectedPhotoExt)
                                    
                                    const fldTitleLng = 'selectedPhotoName_' + language;
                                    
                                    setTimeout(() => { 

                                        if (this.state.selectionDialogFileName === ''
                                            && this.state.uploadState === 'started'
                                            && this.state.uploadProgress === 'no progress'
                                            && this.uploadPhoto.files.length === 0
                                            && this.state.inputDialog === 'finished') {
                                                this.cancelPhotoEdit();
                                        }   

                                    }, 500) 


                                    return (
                                        <div key={index}>
                                        
                                        <LabelStyled>Photo title {this.props.values.languages.length > 1 ? '(' + this.capitalize(ISO6391.getNativeName(language))+')' : '' }</LabelStyled>
                                        <InputStyled
                                            name={fldTitleLng}
                                            autoCapitalize = 'none'
                                            autoComplete = 'off'
                                            defaultValue={this.state[fldTitleLng]}
                                            onChange={this.fldNameValueChange}
                                        />
                                       
                                        <IncreaseHeight/>
                                        </div> 
                                    );
                                })
                            }

                            <IncreaseHeight/>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        checked={this.state.selectedPhotoNsfw}
                                        onChange={(e) => { 
                                            const staatus = e.target.checked
                                            setTimeout(() => { this.setState({ selectedPhotoNsfw: staatus }); }, 50) 
                                            }}
                                        value="nsfw"
                                    />
                                }
                            label={'NSFW content'}
                            />       


                            <IncreaseHeight/>
                                { this.state.errorMsg ? ( <><IncreaseHeight/> <MyError message = {this.state.errorMsg} /> </>) : null }
                            <IncreaseHeight/>


                            <BottomButtonArea>
                                {
                                    this.state.selectedPhoto !== '%%-----## -!new-photo!- ##------%%' ? 
                                        (
                                            <ButtonAreaLeft>
                                                <StyledCancel onClick={this.togglePhotoConfirmDelete}>Delete this photo</StyledCancel>
                                            </ButtonAreaLeft>
                                        )
                                    :
                                    <ButtonAreaLeft></ButtonAreaLeft>
                                }
                                
                                <ButtonAreaRight>
                                    <StyledSave onClick={this.savePhoto} disabled={this.state.uploadState === 'started'}>Save</StyledSave> 
                                    <StyledCancel onClick={this.cancelPhotoEdit}>Cancel</StyledCancel>
                                </ButtonAreaRight>

                                {
                                    this.state.selectedPhotoConfirmDelete ?  
                                        <ConfirmDelete  
                                            text='Are you sure you would like to delete this photo?'
                                            closePopup={this.togglePhotoConfirmDelete}
                                            onConfirm={() => this.deletePhoto(this.state.selectedPhotoId)}
                                        />
                                        : 
                                        <></>  
                                }  
                            </BottomButtonArea>

                            { this.state.showUpgradeModalNSFW ? 
                                <UpgradeModal
                                    showUpgradeModal={this.state.showUpgradeModalNSFW}
                                    closePopup={() => this.setState({ showUpgradeModalNSFW: false }) }
                                    popTitle="NSFW content is not allowed on free Hobby accounts"
                                    popDescription="For NSFW content, please upgrade to the Pro account."
                                />
                            : null
                            }
                            
                        </>)
                    :
                    <></>
                    
                } 

                <Mutation 
                    mutation={ UPLOAD_FILE_STREAM } 
                    fetchPolicy='no-cache' 
                    // onCompleted={() => console.log('mutation onCompleted ') }
                >
                    {
                        
                        (uploadAlbumPhoto, { data, loading }) => {

                            //console.log('<Mutation {data}: ', data)
                            //console.log('state.uploadState: ', this.state.uploadState)
                            //console.log('state.selectionDialogFileName: ', this.state.selectionDialogFileName)

                            if (data
                                && (loading !== true)
                                && (this.state.uploadState === "started") 
                                && (this.state.selectionDialogFileName !== '') ) {
                                
                                    setTimeout(async () => {

                                        const fileExtension = this.uploadPhoto.files[0].name.substr(this.uploadPhoto.files[0].name.lastIndexOf('.'));
                                        const photoPath = ASSET_SERVER + '/img/L/' + this.props.values.userId.id + '/' + this.state.selectedAlbumId + '/' + this.state.selectedPhotoId + fileExtension
                                        
                                        let img = new Image(); 
                                        img.src = photoPath;
                                        await img.decode();
                                        
                                        this.setState({ 
                                            uploadState: "completed", 
                                            uploadProgress: "completed", 
                                            selectedPhotoURL: photoPath, 
                                            selectedPhotoExt: fileExtension, 
                                            selectedPhotoW: img.width, 
                                            selectedPhotoH: img.height, 
                                        });
                                        
                                        this.forceUpdate();

                                    }, 50 )

                            }
                            
                            return (
                                <>
                                    <input 
                                        name={'document'} 
                                        type={'file'} 
                                        accept={'image/*'}
                                        ref={(ref) => this.uploadPhoto = ref}
                                        style={{display: "none"}}
                                        
                                        // onClick={e => console.log('onClick e.value: ', e.value)}
                                        // onBlur={e => console.log('onBlur e.value: ', e.value)}
                                        
                                        onChange={ ( {target: { files }} ) => {

                                            

                                            if (files[0].size > 25000000) {

                                                toast.notify(
                                                    <div style={{ "color": "red" }}>Unable to upload files larger than 25MB. Please upload a smaller edition of the selected file.</div>, 
                                                    { duration: 10000 }
                                                );
                                                this.cancelPhotoEdit();

                                            }
                                            else {

                                                if (files.length > 0) {

                                                    // console.log('input onChange: ', files)

                                                    setTimeout(() => { this.setState({ selectionDialogFileName: this.uploadPhoto.value, }); }, 50);

                                                    const file = files[0]
                                                    file && uploadAlbumPhoto({ variables: { file: file, albumId: this.state.selectedAlbumId, photoId: this.state.selectedPhotoId } })

                                                } 
                                                else {

                                                    // unfortunately, this cancel is only reached when the user is actually selecting a file in dialog bu tthen presses cancel on the dialog. When file is not selected and they click cancel, this is not fired.
                                                    this.cancelPhotoEdit();

                                                }

                                            }

                                            
                                        }
                                        }
                                    />
                                </>
                            )
                        }
                    }
                </Mutation>

                
            </PageContent>

        )

    }
    
}




export const AlbumsPage = (withApollo(C));

const AlbumCards = styled.div`

    width: 100%;
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    align-items: flex-start;
    justify-content: center;
   
    z-index: 200;

    @media (max-width: 790px) {
        z-index: unset;
    }

`;

const PhotoCards = styled.div`

    width: 100%;
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    align-items: flex-start;
    justify-content: center;
   
    z-index: 200;

    @media (max-width: 790px) {
        z-index: unset;
    }


`;



const AlbumCard = styled.div`
    
    width: 240px;
    height: 355px;
   
    margin: 16px 16px 8px 16px;
    align-items: flex-start;
    border-radius: 5px;
    
    border: none;
    z-index: 300;

    &:hover {
        transform: scale(1.02);
        cursor: pointer;
       
    }
    
    @media (max-width: 970px) {
        width: 100%;
        height: auto;
        z-index: unset;
        margin-left: 0;
        margin-right: 8px;
    }
    
`;


const PhotoCard = styled.div`

    width: 25%;
    
    margin: 16px 16px 8px 16px;
    align-items: flex-start;
    border-radius: 5px;
    
    border: none;
    z-index: 300;

    
    @media (max-width: 1000px) {
        width: 45vh;
        height: auto;
        z-index: unset;
        margin-left: 0px;
        margin-right: 8px;
    }

    @media (max-width: 500px) {
        width: 100%;
        height: auto;
        z-index: unset;
        margin-left: 0px;
        margin-right: 8px;
    }
    
`;

const UploadSpinner = styled.div`

    width: 100%;
    height: 500px; /*16:9 aspect ratio*/
    max-width: calc(40vh * 1.5);
    max-height: 40vh;
    
    border: 2px solid #EBEBEB;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    border-radius: 4px;

    text-align: center;

    font-size: 16px;

    @media (max-width: 790px) {
        width: 100%;
        height: 45vw;
    }

`;


const UploadSpinnerImage = styled.img`

    margin-top: 32px;

    display: inline-block;

    @media (max-width: 790px) {
        width: 100px;
        height: 100px;
    }

`;

const PhotoInAlbumView = styled.img`

    width: 100%;
    height: auto;

    align-items: flex-start;

    object-fit: contain;

    border-radius: 5px;
    z-index: 400;

    border: 2px solid #EBEBEB;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);
    
    &:hover {
        transform: scale(1.02);
        cursor: pointer;
       
    }

`;

const PhotoLabel = styled.div`
    
    display: absolute;
    position: relative;

    text-align: left;
    float: left; 
    margin-right: auto;
    left: 4px;

    margin-top: -31px; 
    z-index: 600;

    width: calc(100%-4px);
    max-width: calc(100%-8px);

    border-radius: 2px;
    padding: 4px;
    margin-right: 4px;

    overflow: hidden;
    text-overflow: ellipsis;

    color: #333333;
    background-color: rgba(255, 255, 255, 0.7);
    
    line-height: 14px;
    font-size: 14px;;
    height: 1em;

    &:hover {
        cursor: pointer;
       
    }
    
`;

const AlbumCategoryLabel = styled.div`
    
    display: absolute;
    position: relative;

    text-align: left;
    float: left; 
    margin-right: auto;
    left: 4px;

    margin-top: -39px; 
    z-index: 600;

    width: calc(100%-4px);
    max-width: calc(100%-8px);

    border-radius: 2px;
    padding: 4px;
    margin-right: 4px;

    overflow: hidden;
    text-overflow: ellipsis;

    color: #333333;
    background-color: rgba(255, 255, 255, 0.7);
    
    line-height: 14px;
    font-size: 14px;;
    height: 1em;

    &:hover {
        cursor: pointer;
       
    }
    
`;


const AlbumDescLabel = styled.div`
    
    display: absolute;
    position: relative;

    text-align: left;
    float: left; 
    margin-right: auto;

    margin-top: -8px; 
    padding-top: 0px;
    padding-bottom: 4px;
    
    margin-left: 8px;

    z-index: 600;

    width: calc(100%-4px);
    max-width: calc(100%-8px);


    color: #333333;
    font-size: 14px;;
    line-height: 14px;

    &:hover {
        cursor: pointer;
    }

`;

const AlbumVisibilityColor = styled.div`
    color: ${
        (props) => 
            props.availabilityCode === 0 ? 'rgb(178,34,34);'
            : 
            props.availabilityCode === 1 ? 'rgb(34,139,34);' 
            : 
            'rgb(0,0,205)'
        
    };  
`;


const PhotoNsfwMark = styled.div`
    
    display: absolute;
    position: relative;

    text-align: right;
    float: right; 
    margin-left: auto;

    margin-top: -31px; 
    z-index: 700;

    border-radius: 2px;
    padding: 4px;

    color: #fff;
    background-color: rgba(255, 0, 0, 0.6);
    
    line-height: 14px;
    font-size: 14px;;
    
    &:hover {
        cursor: pointer;
       
    }
`;

const AlbumNsfwMark = styled.div`
    
    display: absolute;
    position: relative;

    text-align: right;
    float: right; 
    margin-left: auto;

    margin-top: -39px; 
    z-index: 700;

    border-radius: 2px;
    padding: 4px;

    color: #fff;
    background-color: rgba(255, 0, 0, 0.6);
    
    line-height: 14px;
    font-size: 14px;;
    
    &:hover {
        cursor: pointer;
       
    }
`;

const GreyButtons = styled.div`

    display: flex;

    position: relative;

    margin-top: -32px;

    top: 40px;
    
    z-index: 800;

    width: calc(100%+8px);
    min-width: calc(100%+8px);

    flex-direction: row;
    justify-content: space-between;

`;


const GreyButton = styled(Button)`
    && {
        
    margin: -4px -4px 0 0px;

    height: 40px;
    min-width: 40px;
    max-width: 40px;

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;
    font-weight: 400;
    font-size: 17px;

    &:hover {
        background-color: #EBEBEB;
        opacity: 0.6;
        }
    
    &:disabled {
        background-color: #fff;
        color: #fff;
        }

    }
    
`;



const PhotoInAddEditView = styled.img`

    max-width: 100%;
    max-height: 600px;

    border: 2px solid #EBEBEB;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    border-radius: 4px;
    
`;


const OrangeCheckBox = withStyles({
    root: {
      color: orange[400],
      '&$checked': {
        color: orange[600],
      },
    },
    checked: {},
    })(props => <Checkbox color="default" {...props} /> )
;

const StyledSave = styled(Button)`
    && {
    height: 40px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    color: white;
    
    
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 16px;
    margin-bottom: 32px;
    margin-top: 16px;

    text-transform: none;
    font-weight: 400;
    font-size: 17px;
        &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }
    }
    
`;

const StyledCancel = styled(Button)`
    && {
    height: 40px;
    margin-top: 16px;

    padding-left: 16px;
    padding-right: 16px;

    margin-bottom: 32px;
    margin-right: 16px;

    color: #333333;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;

    @media (max-width: 790px) {
        border: 1px solid #efefef;
        box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};
    }

    }
    
`;

const BottomButtonArea = styled.div`
    width:100%;
    min-width: 100%;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;    
    justify-content: space-between;

`;

const AlbumViewHeader = styled.div`
    width:100%;
    min-width: 100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
`;

const HeaderInputContainer = styled.div`

    width:100%;
    
    margin-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 32px;
`;


const ButtonAreaLeft = styled.div`

    justify-content: space-between;

`;

const ButtonAreaRight = styled.div`
    justify-content: space-between;

`;





const AlbumThumbCover = styled.div`

    width: 240px;
    height: 300px;

    align-items: flex-start;

    border-radius: 5px;
    z-index: 400;

    border: 2px solid #EBEBEB;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);


    &:hover {
        transform: scale(1.02);
        cursor: pointer;
       
    }
    
    @media (max-width: 970px) {
        width: 100%;
        height: calc(100vw/4*5);
    }
    

`;

const AlbumThumb = styled.img`

    width: 100%;
    height: 100%;
    
    object-fit: cover;

    border-radius: 5px;

`;




const PageContent = styled.div`
    
    width: 100%;
    height: 100%;
    min-height: 75vh;
    font-family: 'Fira Sans';
    
`;

const PageTitle = styled.div`
    
    display: flex;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;

    display: ${(props) => props.showAlways === 'true' ? "block" : 'none'};

    @media (max-width: 790px) {
        display: block;
    }


`;

const LinkButton = styled.div`
    
    color: #4C9AFF;

    margin: 0 8px 0 0; 

    font-weight: 400;
    font-size: 16px;

    &:hover {
        text-decoration: underline;
        cursor: pointer
        }

`;

const IncreaseHeight = styled.div`
    
    height: 16px;  

`;

const InputStyled = styled.input`
    
    width: calc(100% - 18px);
    padding: 0px;
    margin: 0px;
    height: 24px;
    padding: 8px;
    font-size: 16px;
    font-family: 'Fira Sans';

    color: #333333;
    
    border: 1px solid #cbcbcb;
    border-radius: 4px;  
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    :focus {
        outline-color: #4C9AFF;
    }

    :disabled {
        background-color: #f7f7f7; 
    }


    @media (max-width: 690px) {
        font-size: 17px;
    }
`;

const LabelStyled = styled.div`
    width: 100%;
    margin: 0px;
    padding: 0px;
    color: #333333;
    font-size: 16px;
    
    margin-bottom: 4px;
    margin-top: 0px;
    margin-left: 2px;
`;








const FAIcon = styled(FontAwesomeIcon)`
    && {
    font-size: 16px;
    font-weight: 300;
    }
`;

const colourStyles = {

    control: (styles, {isDisabled, isFocused}) => ({ ...styles, 
        
        margin: 0,
        height: 42,
        paddingTop: 2,
        
        fontSize: 16,
        fontFamily: 'Fira Sans',
        fontWeight: '400',
        
        
        backgroundColor: isDisabled ? '#f7f7f7' : null,
        color: '#333333',
        
        borderRadius: 4,
        border: isFocused ? '2px solid #4C9AFF' : '1px solid #cbcbcb',
        boxShadow: '0 2px 8px 0 rgba(170, 170, 170, 0.2);',
        //boxShadow: isFocused ? '0 2px 8px 0 rgba(76, 154, 255, 0.8)' : '0 2px 8px 0 rgba(170, 170, 170, 0.2)',

        }),
    
    singleValue: base => ({
        ...base,
        paddingTop: 2,
        color: '#333333',
        marginLeft: 0,
        }),
    
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        
        return {
            ...styles,

            cursor: isDisabled ? 'not-allowed' : 'default',
        
            fontSize: 16,
            fontFamily: 'Fira Sans',
            fontWeight: '400',
            height: 42,

            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',


            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && ('#4C9AFF'),

            },


        };
    },
    menu: base => ({
        ...base,
        // color: '#333333',
        
        borderRadius: 4,
        border: '2px solid #4C9AFF',
        boxShadow: '0 2px 8px 0 rgba(170, 170, 170, 0.2);',

        marginLeft: '-1px',

        width: 'calc(100% + 2px);',


    }),

}
