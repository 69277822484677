import React, { PureComponent } from 'react'

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

import "./index.css";
import { FooterSection } from "./_footerSection";
import { UpgradeComparisonModal } from "../components/UpgradeComparisonModal";
import { Link } from "react-router-dom";

import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export default class ListYourEvent extends PureComponent {

    constructor(props) {
        
        super(props);
        
        this.state = {
            selectedYearly: true,
            showUpgradeModal: false,
            showUpgradeComparisonModal: false,
            hideCTA: false,
        };
    
    }

    componentDidMount() {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        // ReactGA.send("pageview");
    }

    render() {

     

        
        return (
            
            

            <>

                <Helmet>
                    <title>List Your Event | Showcase Photography Events on PhotoSouls</title>
                    <meta name="description" content="Host a photography workshop, exhibition, or meetup? List your event on PhotoSouls and connect with our vibrant community of photography enthusiasts." />
                    <meta name="keywords" content="photography, workshops, photo tours, PhotoSouls, global community, bookings, showcase, secure transactions, photographers" />
                </Helmet>

                {/* Maximize Your Workshop's Reach with PhotoSouls! */}
                <Section1img>

                    <RowCenter>
                        
                        <CenterTextBlock>

                            <H1>Maximize Your Workshop's Reach with PhotoSouls!</H1>

                            <H1DescBigger>Are you a seasoned photographer with a passion for sharing your skills and experiences through workshops and photo tours? </H1DescBigger>
                            
                            <H1DescSmaller>Imagine being able to effortlessly reach a global community of aspiring photographers eager to learn from you. PhotoSouls is your gateway to this exciting opportunity.</H1DescSmaller>

                            <BtnPrimary onClick={ () => this.props.history.push({ pathname: 'signup/' }) }>CLICK HERE TO JOIN</BtnPrimary>

                        </CenterTextBlock>

                    </RowCenter>

                </Section1img>

              
                {/* Fill Your Workshops Fast */}
                <Section1>

                    <Row>
                        
                        <Left>

                            <H1g>Fill Your Workshops Fast</H1g>
                            <H1Desc>With PhotoSouls, your workshops are visible to an extensive network of photography enthusiasts.</H1Desc>
                            <H1Desc>Our platform significantly increases your chances of filling workshop spots quickly, ensuring a successful event every time.</H1Desc>
                            
                        </Left>

                        <RightPhoto>
                            <S1img src={'images/web/workshop1.png'} alt="Hands with cameras" />
                        </RightPhoto>

                    </Row>

                </Section1>


                {/* Last-Minute Filling? No Problem! */}
                <Section1>

                    <Row>
                        
                        <Left2>
                            <S1img src={'images/web/model1b.png'} alt="PhotoSouls on the laptop screen" />
                        </Left2>

                        <Right2>
                            <H1g>Last-Minute Filling? No Problem!</H1g>
                            <H1Desc>Have a few open spots left?</H1Desc>
                            <H1Desc>We've got you covered. PhotoSouls is the perfect platform for attracting last-minute participants to your workshops or tours.</H1Desc>
                            
                        </Right2>

                    </Row>

                </Section1>


                {/* Showcase Your Unique Experiences */}
                <SectionBlack>

                    <Row>
                        
                        <Left>

                            <H1gWhite>Showcase Your Unique Experiences</H1gWhite>
                            <H1DescWhite>Your workshops are more than just lessons; they are unique experiences.</H1DescWhite>
                            <H1DescWhite>Our platform allows you to display these one-of-a-kind offerings to a broad audience, highlighting the exclusive opportunities you provide.</H1DescWhite>
                            
                        </Left>

                        <RightToBottom>
                            <S1img src={'images/web/workshop2.png'} alt="Fast and secure payments" />
                        </RightToBottom>

                    </Row>

                </SectionBlack>

                {/* Elevate Your Earnings */}
                <Section1>

                    <Row>
                        
                        <Left2>
                            <S1img src={'images/web/workshop3.png'} alt="Workshop services" />
                        </Left2>

                        <Right2>
                            <H1g>Elevate Your Earnings</H1g>
                            <H1Desc>Expand your income by reaching out to our vast network of photographers. </H1Desc>
                            <H1Desc>With PhotoSouls, your workshop services are exposed to a wider market, leading to increased sign-ups and improved revenue.</H1Desc>
                            
                        </Right2>

                    </Row>

                </Section1>

                {/* Convenient Booking Management */}
                <Section1>

                    <Row>
                        
                        <Left>

                            <H1g>Convenient Booking Management</H1g>
                            <H1Desc>Managing your workshops should be as enjoyable as conducting them.</H1Desc>
                            <H1Desc>Our user-friendly interface enables you to effortlessly manage bookings and keep track of participants. You can focus on delivering great content while we handle the details.</H1Desc>
                            
                        </Left>

                        <Right>
                            <S1img src={'images/web/photog6.png'} alt="Photo portfolio" />
                        </Right>

                    </Row>

                </Section1>

                {/* Join a Thriving Community */}
                <Section1>

                    <Row>
                        
                        <Left2>
                            <S1img src={'images/web/photog8.png'} alt="Collage of photos" />
                        </Left2>

                        <Right2>
                            <H1g>Join a Thriving Community</H1g>
                            <H1Desc>By listing your workshops with PhotoSouls, you become part of an exciting and supportive community of industry professionals. </H1Desc>
                            <H1Desc>Connect, learn, and grow with like-minded individuals, enhancing your experience and success in the industry.</H1Desc>
                            
                        </Right2>

                    </Row>

                </Section1>

                {/* Safe and Secure Transactions */}
                <Section1>

                    <Row>
                        
                        <Left>

                            <H1g>Safe and Secure Transactions</H1g>
                            <H1Desc>Your peace of mind is our priority.</H1Desc>
                            <H1Desc>Our secure payment system ensures all transactions are handled safely, with prompt and accurate payments for every booking.</H1Desc>
                            
                        </Left>

                        <Right>
                            <S1img src={'images/web/photog3.png'} alt="Learn from Workshops" />
                        </Right>

                    </Row>

                </Section1>

                {/* How much does it cost? */}
                <Section1>

                    <Row>
                        
                        <Left>

                            <H1g>How much does it cost?</H1g>
                            {/* <H1Desc>PhotoSouls offers a free Hobby membership plan that gives you access to everything on our platform without any charges. It's completely free of cost!</H1Desc>
                            <H1Desc>When you receive a booking and get paid, we only deduct a 20% commission fee. This fee is applicable only when you make a successful booking.</H1Desc>
                            <H1Desc>As a Pro member, you enjoy additional benefits, including a reduced commission fee of 15%. To explore the other advantages of Pro membership, <StyledLink onClick={ () => { this.setState({ showUpgradeComparisonModal: true, hideCTA: false }) }}>click here.</StyledLink></H1Desc> */}

                            <H1Desc>At PhotoSouls, our Hobby membership plan is available at no charge, providing you with access to many of our platform's features. We only take a 20% commission when you get a booking and receive payment.</H1Desc>
                            <H1Desc>For a broader range of features and benefits, consider upgrading to our Pro membership. It also includes a reduced commission fee of just 15%. Learn more about the advantages of the Pro membership by <StyledLink onClick={ () => { this.setState({ showUpgradeComparisonModal: true, hideCTA: false }) }}>clicking here.</StyledLink></H1Desc>

                        </Left>

                        <Right>
                            <S1img src={'images/web/photog9.png'} alt="How much does it cost" />
                        </Right>

                    </Row>

                </Section1>


                <Section1>

                    <RowCenter>
                        
                        <CenterTextBlock>

                            <H1>So, what are you waiting for?</H1>

                            <H1Desc>Join PhotoSouls today and let your workshops and photo tours shine in the spotlight. Boost your bookings, elevate your visibility, and take your photography career to new heights!</H1Desc>

                            <BtnPrimary onClick={ () => this.props.history.push({ pathname: 'signup/' }) }>Join Now!</BtnPrimary>

                        </CenterTextBlock>

                    </RowCenter>

                </Section1>

                <FooterSection/>

                { this.state.showUpgradeComparisonModal ? 
                    <UpgradeComparisonModal
                        showUpgradeComparisonModal={this.state.showUpgradeComparisonModal}
                        hideCTA={this.state.hideCTA}
                        closePopup={() => this.setState({ showUpgradeComparisonModal: false, hideCTA: false }) }
                    />
                : null
                }

            </>

        )
    }
}



const Left2 = styled.div`
    
    width: 45%;
    padding-top: 32px;
    padding-bottom: 32px;

    /* background-color: #aea; */

    @media (max-width: 980px) {
        width: 100%;
        padding-bottom: 0px;
        
    }
    

`;

const Right2 = styled.div`
    
    width: 45%;
    padding-top: 32px;
    padding-bottom: 64px;


    /* background-color: #ced; */

    @media (max-width: 980px) {
        
        width: 100%;
        padding-left: 0px;
        padding-top: 0px;

    }
    

`;




const Row = styled.div`
    
    width: 1170px;

    margin: 0 auto;
    
    padding-left: 48px;
    padding-right: 24px;
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    justify-content: center;
    align-items: center;
        
    /* background-color: #f3f; */

    @media (max-width: 980px) {
        
        /* margin-top: 0px; */
        /* text-align: center;
        justify-content: center;
        align-items: center; */

    }

`;


const Section1 = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    background-color: #f3f2f0;
    background-image: linear-gradient(#fff,#f3f2f0);

    position: relative;
    z-index: 1;
    min-height: 450px;


    @media (max-width: 980px) {
        
        min-height: 450px;

    }


`;

const SectionBlack = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    background-color: #000;

    position: relative;
    z-index: 1;
    min-height: 450px;


    @media (max-width: 980px) {
        
        min-height: 450px;

    }


`;


const Section1img = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    background-color: #f3f2f0;
    background-image: url('images/web/photog0.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    position: relative;
    z-index: 1;
    min-height: 450px;


    @media (max-width: 980px) {
        
        min-height: 450px;

    }


`;

const Left = styled.div`
    
    width: 45%;

    padding-bottom: 40px;
    padding-right: 24px;
    padding-top: 64px;
    padding-left: 32px;

    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 32px;
        padding-left: 0px;

    }
    

`;


const CenterTextBlock = styled.div`
    
    width: 75%;

    padding-bottom: 40px;
    padding-right: 24px;

    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 32px;

    }
    

`;


const RowCenter = styled.div`
    
    max-width: 1170px;

    margin: 0 auto;
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    text-align: center;
    justify-content: center;
    align-items: center;

    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;

    padding-top: 48px;

    @media (max-width: 980px) {
        
        /* margin-top: 0px; */

    }

`;


const H1g = styled.h1`

    margin-top: 20px; 

    color: #333333;
    font-family: 'Fira Sans';
    font-size: 42px;
    

    @media (max-width: 970px) {

        font-size: 32px;

    }

`;

const H1gWhite = styled.h1`

    margin-top: 20px; 

    color: #fff;
    font-family: 'Fira Sans';
    font-size: 42px;
    

    @media (max-width: 970px) {

        font-size: 32px;

    }

`;

const H1 = styled.h1`

    margin-top: 20px; 

    color: #ff9500;

    font-family: 'Fira Sans';
    font-size: 48px;
    

    @media (max-width: 970px) {

        font-size: 38px;

    }

`;


const H1Desc = styled.div`
    
    margin-top: 20px; 
    margin-bottom: 20px; 

    color: #333333;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 26px;
    
    @media (max-width: 970px) {

        font-size: 24px;

    }

`;

const H1DescBigger = styled.div`
    
    margin-top: 20px; 
    margin-bottom: 20px; 

    color: #333333;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 32px;
    
    @media (max-width: 970px) {

        font-size: 26px;

    }

`;


const H1DescWhite = styled.div`
    
    margin-top: 20px; 
    margin-bottom: 20px; 

    color: #fff;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 26px;
    
    @media (max-width: 970px) {

        font-size: 24px;

    }

`;



const H1DescSmaller = styled.div`
    
    margin-top: 32px; 
    margin-bottom: 20px; 

    color: #333333;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 28px;
    
    @media (max-width: 970px) {

        font-size: 24px;

    }

`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #3e6ca0;
    text-decoration: underline
    &:hover {
        color: #3e6ca0;
        text-decoration: underline
    }
`;







const BtnPrimary = styled(Button)`
    && {
    min-height: 64px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    
    color: white;
    
    padding-left: 32px;
    padding-right: 32px;
    margin-right: 32px;
    margin-top: 32px;

    text-transform: none;
    font-weight: 400;
    font-size: 22px;
    
    &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }

}     
`;



const S1img = styled.img`

    width: 100%;
    height: 100%;

    object-fit: cover;

    margin-left: auto;
    margin-right: auto;


`;


const Right = styled.div`
    
    width: 45%;
    padding-left: 48px;
    padding-bottom: 32px;

    /* background-color: #ced; */
    text-align: center;
    justify-content: center;
    align-items: center;


    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 0px;

    }
`;

const RightToBottom = styled.div`
    
    width: 45%;
    padding-left: 48px;
    margin-bottom: -64px;

    /* background-color: #ced; */
    text-align: center;
    justify-content: center;
    align-items: center;


    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 0px;
        margin-bottom: -4px;

    }
`;


const RightPhoto = styled.div`
    
    width: 45%;

    padding-left: 32px;
    /* padding-bottom: 32px; */
    padding-top: 64px;

    /* background-color: #f3f2f0;
    background-image: url('images/web/_1test1.jpg');
    background-repeat: no-repeat;
    background-size: cover; */

    @media (max-width: 980px) {
        
        width: 100%;
        padding-left: 0px;
        padding-top: 0px;
    }
`;

