import React, { PureComponent } from 'react'

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import Footer from '../components/Footer/Footer'

import "./index.css";

import { Query } from "react-apollo";
import gql from "graphql-tag";

const termsQuery = gql`{ getKey(key:"terms") {value, key} }`;
const privacyPolicyQuery = gql`{ getKey(key:"privacy") {value, key} }`;
const cookiePolicyQuery = gql`{ getKey(key:"cookiePolicy") {value, key} }`;

const paymentTermsQuery = gql`{ getKey(key:"paymentTerms") {value, key} }`;
const refundPolicyQuery = gql`{ getKey(key:"refundPolicy") {value, key} }`;

const contentPolicyQuery = gql`{ getKey(key:"contentPolicy") {value, key} }`;
const reviewsPolicyQuery = gql`{ getKey(key:"reviewsPolicy") {value, key} }`;
const copyrightPolicyQuery = gql`{ getKey(key:"copyrightPolicy") {value, key} }`;


export default class Terms extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            showMenu: false,
        }

    }

    componentDidMount() {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }




    render() {


        let param = '';
        if (this.props.match.params.termOrPolicy) {
            param = this.props.match.params.termOrPolicy
        } else {
            param = this.props.termOrPolicy
        }

        let metaTitle = '';
        let metaDesc = '';
        let metaKw = '';
        let keyQuery = termsQuery;

        if (param === 'terms-of-service') { 
            keyQuery = termsQuery;
            metaTitle = 'Terms of Service | PhotoSouls';
            metaDesc = 'PhotoSouls Terms of Service: Understand your rights and obligations while using our platform.';
            metaKw = 'terms of service';
        } else if (param === 'privacy-policy') {
            keyQuery = privacyPolicyQuery;
            metaTitle = 'Privacy Policy | PhotoSouls';
            metaDesc = 'PhotoSouls Privacy Policy: Learn how we prioritize your privacy, the data we collect, and our commitment to its protection.';
            metaKw = 'privacy policy';
        } else if (param === 'cookie-policy') {
            keyQuery = cookiePolicyQuery;
            metaTitle = 'Cookie Policy | PhotoSouls';
            metaDesc = 'PhotoSouls Cookie Policy: Discover how we use cookies to enhance your browsing experience. ';
            metaKw = 'cookie policy';
        } else if (param === 'payment-terms') {
            keyQuery = paymentTermsQuery;
            metaTitle = 'Payments Terms of Service | PhotoSouls';
            metaDesc = 'PhotoSouls Payment Terms: Navigate our transparent payment guidelines, understand our billing processes, and ensure a smooth transaction experience every time you engage with PhotoSouls';
            metaKw = 'payments terms of service';
        } else if (param === 'refund-policy') {
            keyQuery = refundPolicyQuery;
        } else if (param === 'content-policy') {
            keyQuery = contentPolicyQuery;
            metaTitle = 'Content Policy | PhotoSouls';
            metaDesc = 'Content Policy | PhotoSouls';
            metaKw = 'content policy';
        } else if (param === 'reviews-policy') {
            keyQuery = reviewsPolicyQuery;
            metaTitle = 'Reviews Policy | PhotoSouls';
            metaDesc = 'Reviews Policy | PhotoSouls';
            metaKw = 'reviews policy';
        } else if (param === 'copyright-policy') {
            keyQuery = copyrightPolicyQuery;
            metaTitle = 'Copyright Policy | PhotoSouls';
            metaDesc = 'Copyright Policy | PhotoSouls';
            metaKw = 'Copyright policy';
        } else {
            keyQuery = termsQuery;
            metaTitle = 'Terms of Service | PhotoSouls';
            metaDesc = 'Terms of Service | PhotoSouls';
            metaKw = 'terms of service';
        }

        return (
            
            <>

                <Helmet>
                    <title>{metaTitle}</title>
                    <meta name="description" content={metaDesc} />
                    <meta name="keywords" content={metaKw} />
                </Helmet>

                <div className="bodywrapper">
                    

                    <PolicyButton onClick={ () => this.setState({ showMenu: true }) }>Other Terms & Policies</PolicyButton> 

                    { this.state.showMenu ? 
                        <div className='react-confirm-alert-overlay'>
            
                            <div className='react-confirm-alert-body'>

                            <BackButton onClick={() => { this.setState({ showMenu: false }) }} >
                                <FAIcon icon={faChevronLeft} color={'#000'} />
                            </BackButton>
                            <BackButtonWithText onClick={() => { this.setState({ showMenu: false }) }}>
                                Back
                            </BackButtonWithText>
                            <OneRow><br/></OneRow>



                            <SectionBtn onClick={ () => window.location.href = '/terms/terms-of-service' }>Terms of Service</SectionBtn> 
                            <OneRow><br/></OneRow>

                            <SectionBtn onClick={ () => window.location.href = '/terms/privacy-policy' }>Privacy Policy</SectionBtn>
                            <OneRow><br/></OneRow>
                            
                            <SectionBtn onClick={ () => window.location.href = '/terms/cookie-policy' }>Cookie Policy</SectionBtn>
                            <OneRow><br/></OneRow>



                            <OneRow><br/></OneRow>
                            
                            <SectionBtn onClick={ () => window.location.href = '/terms/payment-terms' }>Payments Terms</SectionBtn> 
                            <OneRow><br/></OneRow>
                            
                            <SectionBtn onClick={ () => window.location.href = '/terms/refund-policy' }>Refund Policy</SectionBtn>
                            <OneRow><br/></OneRow>
                            


                            <OneRow><br/></OneRow>

                            <SectionBtn onClick={ () => window.location.href = '/terms/content-policy' }>Content Policy</SectionBtn>
                            <OneRow><br/></OneRow>
                            
                            <SectionBtn onClick={ () => window.location.href = '/terms/reviews-policy' }>Reviews Policy</SectionBtn>
                            <OneRow><br/></OneRow>
                            
                            <SectionBtn onClick={ () => window.location.href = '/terms/copyright-policy' }>Copyright Policy</SectionBtn>
                            <OneRow><br/></OneRow>
                            
                            </div>

                        </div>
                        :
                        null
                    }
                    
                    <Query query={keyQuery} >
                        {({ data }) => {

                            if (data === undefined) {
                                return (<div>Sorry, we're having technical problems at this moment. Please try again later.</div>)    
                            } else
                            
                            if (JSON.stringify(data).length > 15) {
                                const cleanKey = DOMPurify.sanitize(data.getKey.value);
                                const displayKey = parse(cleanKey);
                                return (
                                    <div>{displayKey}</div>
                                );
                            }
                            
                            return <div>Loading ...</div>
                        }}
                    </Query>


                <Footer showbutton="true"/>

                </div>
                <br/><br/><br/><br/>


            </>

        )
    }
}


const SectionBtn = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 18px;

    color: #4C9AFF;

    text-decoration: underline;
    text-underline-offset: 2px;

    &:hover {
        cursor: pointer; 
        opacity: 1;
        font-weight: 500;
        }

    /* background-color: #ded; */

`;

const PolicyButton = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 16px;

    color: #4C9AFF;

    text-decoration: underline;
    text-underline-offset: 2px;

    position: relative;
    float: right;
    text-align: right;

    @media (max-width: 970px) {
       max-width: 40%;
       font-size: 16px;
    }
    
    &:hover {
        cursor: pointer; 
        opacity: 1;
        font-weight: 500;
        }

    /* background-color: #ded; */

`;

const OneRow = styled.div`
    
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    flex-wrap: wrap;
    flex: 1;

    align-items: center;

`;


const BackButton = styled(Button)`
    && {
        
    /* margin: -4px -4px 0 0px; */

    height: 48px;
    min-width: 48px;
    max-width: 48px;
    border-radius: 24px;

    margin-right: 16px;
    margin-top: 8px;
    /* margin-right: 4px;
    margin-left: 4px; */

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;

    
    font-weight: 400;
    font-size: 19px;

    background-color: #EBEBEB;
    opacity: 0.6;

    &:hover {
        background-color: #EBEBEB;
        opacity: 0.6;
        }
    
    &:disabled {
        background-color: #fff;
        color: #fff;
        }

    }
    
`;

const FAIcon = styled(FontAwesomeIcon)`
    && {
    font-size: 22px;
    font-weight: 400;
    }
`;

const BackButtonWithText = styled(Button)`
    && {
    height: 40px;

    margin-top: 8px;

    padding-left: 16px;
    padding-right: 16px;


    color: #333333;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    }
    
`;
