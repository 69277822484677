
import React, { Component } from 'react';
import styled from 'styled-components';


export class MyError extends Component {
    
  render() {
      
    const {
        message
        } = this.props;
    
    return (
      
      <ErrorArea>{message}</ErrorArea>
    
    );

  }
}

const ErrorArea = styled.div`
    
    margin-bottom: 8px;
    padding: 8px;
    
    font-size: 16px;
    font-weight: 400;
    
    color: #ffffff;
    background-color: #FF4343;
    border: 1px solid #f60000;
    box-shadow: 0 2px 8px 0 rgba(197, 12, 12, 0.2);
    
`;
