
import React, { Component } from 'react';
import styled from 'styled-components';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

// import i18next from "i18next";
import '../../i18n'

import Button from '@material-ui/core/Button';
import Modal from 'react-modal';


export class UpgradeModal extends Component {
    
    state = {
        showPopup: false,
    };


    toggleModal = () => {  

        this.setState({  
            showPopup: !this.state.showPopup,
        });  

    }  



    render() {
      

        const filterStyles = {
            overlay: { zIndex: 1000, backgroundColor: 'rgba(0, 0, 0, 0.33)',},
            content: {

                top: '24px',

                left: '5%',
                margin: 'auto',
                
                bottom: 'auto',
                                
                width: '500px',

                maxWidth: '74%',

                boxShadow: '0 20px 50px rgba(0, 0, 0, 0.33)',
                borderRadius: '5px',
                paddingTop: '16px',
                paddingLeft: '32px',
                paddingRight: '32px',
                                                    
                overflow : 'auto',
                

            }
        }

        const filterStylesMobile = {
            overlay: { zIndex: 1000, backgroundColor: 'rgba(0, 0, 0, 0.33)',},
            content: {

                top: `80px`,

                left: '5%',
                margin: `auto`,
                
                bottom: 'auto',
                
                
                width: `73%`,

                boxShadow: '0 20px 50px rgba(0, 0, 0, 0.33)',
                borderRadius: '5px',
                paddingTop: '16px',
                paddingLeft: '32px',
                paddingRight: '32px',
                                                    
                overflow : 'auto',
                

            }
        }

        if (this.props.showUpgradeModal && !this.state.showPopup) {
            this.toggleModal();
        }
        
        return (
        
            <Modal 
                isOpen={this.state.showPopup}
                closeTimeoutMS={500}

                onRequestClose={this.props.closePopup}
                ariaHideApp={false}
                style={window.innerWidth > 790 ? filterStyles : filterStylesMobile}
                
                onAfterOpen={() => {
                    document.body.style.top = `0px`
                    document.body.style.width = `100vw`
                    document.body.style.position = 'fixed'
                }}

                onAfterClose={() => {
                    const scrollY = document.body.style.top
                    document.body.style.position = ''
                    document.body.style.top = ''
                    document.body.style.width = ''
                    window.scrollTo(0, parseInt(scrollY || '0') * -1)
                }}
                >
            
                <SubTitle>{this.props.popTitle}</SubTitle>
                
                <SectionSubTitle>{this.props.popDescription}</SectionSubTitle>
                
                <ButtonArea>

                    <StyledSave 
                        onClick={() => { this.props.readMore(); }}
                        >
                        Read more and upgrade
                    </StyledSave>
                    
                    <StyledCancel
                        onClick={() => { this.props.closePopup(); }}
                        >
                        Cancel
                    </StyledCancel>

                </ButtonArea>

                { window.innerWidth > 790 ? <><br/></> : <><br/><br/></> }
            
            </Modal>

        
        );

    }
}





const ButtonArea = styled.div`

    width: 100%;

    margin-top: 32px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    color: #333333;

`;



const StyledSave = styled(Button)`
    && {
    min-height: 40px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    color: white;
    
    padding-left: 16px;
    padding-right: 16px;
    
    margin-right: 24px;
    margin-bottom: 24px;

    text-transform: none;
    font-weight: 400;
    font-size: 17px;
        &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }
    }
    
`;


const SubTitle = styled.div`
    
    font-size: 20px;
    font-weight: 400;
    padding: 16px 24px 16px 0px;


    font-family: 'Fira Sans';
    color: #333333;

`;

const SectionSubTitle = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 300;
    font-size: 17px;

    /* color: #505050; */
    /* color: #383838; */
    color: #333333;

    /* background-color: #ded; */

    margin-bottom: 8px;
`;

const StyledCancel = styled(Button)`
    && {
    height: 40px;
    
    padding-left: 16px;
    padding-right: 16px;

    color: #333333;
    
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    }
    
`;