
import React, { PureComponent } from 'react'
import { ForgotPasswordView } from './ForgotPasswordView'
import { ForgotPasswordController } from '@psd/controller'

//import ReactGA from 'react-ga4';
// import { logEvent } from '@psd/web/src/utils/analytics/amplitude';


export class ForgotPasswordConnector extends PureComponent {

    state = {
      loadingVisible: false
    };
    
    onFinish = () => {

      //ReactGA.event({ category: "User", action: "Forgot password", });
      // logEvent('password reset')

      this.props.history.push("/login");
    
    };

    updateLoader = ( staatus ) => {
      this.setState({ loadingVisible: staatus });
    }

  render() {

    return (

      
      <ForgotPasswordController>
        {({ submit }) => (
          <ForgotPasswordView 
            state={this.state}
            updateLoader={this.updateLoader}
            onFinish={this.onFinish} 
            submit={submit} 
          />)}
      </ForgotPasswordController>

    );
  }
}
