import React from 'react';  

//import { Redirect } from 'react-router-dom'

import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import i18next from "i18next";
import './../../../../i18n'

import gql from "graphql-tag"

import { Divider } from "../../../components/Divider";
import { MyError } from "../../../components/MyError";
import { MyP } from "../../../components/MyP";
import '../../../../styles/ModalPopup.css';  


const updateMutation = gql`
    mutation($reason: String!, $pwd: String!) {
        deactivateAccount(reason: $reason, pwd: $pwd)
    }
`;

const logoutMutation = gql`
  mutation {
    logout
  }
`;



class ConfirmDeactivate extends React.Component {  

    state = {
        confirmReason: "",
        confirmPwd: "",
        errorMsg: "",
    };


    handleReasonChange = (event) => {
        this.setState({confirmReason: event.target.value})
    }

    handlePwdChange = (event) => {
        this.setState({confirmPwd: event.target.value})
    }


    render() {  

        const mybordercolor = this.state.errorMsg ? "#ff4343" : "#cbcbcb";
        const mybordercolor2 = "#cbcbcb";

        return (  
            
            <div className='react-confirm-alert-overlay'>
            
                <div className='react-confirm-alert-body'>
                        
                            <h1>Please confirm your account deactivation</h1>


                            <Divider />                       
                            <LabelStyled>Could you please share the reason you're deactivating the account?</LabelStyled> 
                            <InputStyled
                                theme={{ bordercolor: mybordercolor }}
                                autoCapitalize = 'none'
                                onChange={this.handleReasonChange}
                                value={this.state.confirmReason}
                                name='confirmReason'
                            />


                            <Divider />
                            <LabelStyled>Enter your password to confirm</LabelStyled> 
                            <InputStyled
                                theme={{ bordercolor: mybordercolor2 }}
                                type='password'
                                autoCapitalize = 'none'
                                onChange={this.handlePwdChange}
                                value={this.state.confirmPwd}
                                name='confirmPwd'
                            />


                            <Divider />
                            {
                                this.state.errorMsg ? (
                                    <>
                                        <MyError
                                            message = {this.state.errorMsg}
                                        />
                                        <Divider />
                                    </>
                                    )
                                : null
                                
                            }


                            <Divider />
                            <Divider />
                            <MyP
                                p="When you deactivate your account, any bookings you currently have will automatically be canceled. Your profile will be hidden. Some information, such as your reviews, may remain visible to others."
                            />
                            <MyP
                                
                                //p={i18next.t('lbl.deactivateInfo2')}
                                p="We’ll retain your account for the next 60 days in case you deactivated by error. Please contact us to re-activate it. If you don't do that, your account will be permanently deleted automatically after 60 days."
                            />
                            <Divider />
                            <Divider />


                            <ButtonArea>
                                <StyledSave 
                                    onClick={() => {

                                        this.props.apolloClient
                                            .mutate({
                                                mutation: updateMutation,
                                                variables: {
                                                    reason: this.state.confirmReason,
                                                    pwd: this.state.confirmPwd,
                                                },
                                            })
                                            .then(apiResponse => {

                                                // console.log('apiResponse: ', apiResponse)

                                                if (apiResponse.data.deactivateAccount === null) {

                                                    
                                                    if (apiResponse.errors[0].data[0].message.data[0].message.length > 5) {     

                                                        setTimeout(() => {
                                                            this.setState({
                                                                errorMsg: apiResponse.errors[0].data[0].message.data[0].message
                                                            });
                                                        }, 50)

                                                    } 


                                                } else if (apiResponse.data.deactivateAccount === true) {     

                                                    
                                                    //log out
                                                    this.props.apolloClient
                                                        .mutate({
                                                            mutation: logoutMutation,
                                                        })

                                                    //redirect to main page
                                                    window.location.href = "/";

                                                    

                                                } else {

                                                    setTimeout(() => {
                                                        this.setState({
                                                            errorMsg: i18next.t('msg.errProcessingReq'),
                                                        });
                                                    }, 50)

                                                }
                                


                                                //
                                                //this.props.client.resetStore();


                                            })
                                            .catch(error => {
                                                
                                                //console.log("Error: ", error);
                                                
                                                setTimeout(() => {
                                                    this.setState({
                                                        errorMsg: error,
                                                    });
                                                }, 50)

                                            })
                                        
                                    }}

                                    >
                                    Deactivate Now
                                </StyledSave>
                                
                                
                                <StyledCancel
                                    onClick={() => {
                                        this.props.closePopup();
                                    }}
                                    >
                                    Cancel
                                </StyledCancel>

                            </ButtonArea>
                            

                        </div>

                    

            </div>
        );  
    }  
    
}  

export default ConfirmDeactivate;




const ButtonArea = styled.div`

    display: flex;

    padding-bottom: 8px;
    padding-left: 8px;

    color: #333333;
    align-items: flex-end;

`;


const LabelStyled = styled.div`
  width: 100%;
  margin: 0px;
  padding: 0px;
  color: #333333;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 4px;
  margin-top: 0px;
`;

const InputStyled = styled.input`
    width: calc(100% - 18px);
    padding: 0px;
    margin: 0px;
    height: 24px;
    padding: 8px;
    font-size: 16px;
    font-family: 'Fira Sans';
    
    border: 1px solid ${props => props.theme.bordercolor};
    border-radius: 4px;  
    color: #333333;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);
    :disabled {
        background-color: #f7f7f7; 
    }
    :focus {
        outline-color: #4C9AFF;
    }
    @media (max-width: 400px) {
        font-family: 'Fira Sans';
        font-size: 17px;
    }
`;




const StyledSave = styled(Button)`
    && {
    height: 40px;
    top: 18px;
    
    background: #ff9500;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.4);
    color: white;
    width: 50%;
    margin-left: -8px;

    text-transform: none;
    font-weight: 400;
    font-size: 17px;
        &:hover {
        background: #ff9500;
        opacity: 0.9;
        color: white;
        }
    }
    
`;

const StyledCancel = styled(Button)`
    && {
    height: 40px;
    top: 18px;
    margin-left: 8px;
    color: #333333;
    width: 50%;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    }
    
`;

