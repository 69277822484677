import React, { PureComponent } from 'react'
import { withFormik, Field, Form } from "formik";
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
//import axios from 'axios';
import { withApollo } from "react-apollo";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-modal';

import moment from "moment"; import 'moment/locale/et'; import 'moment/locale/de'; import 'moment/locale/ru'; import 'moment/locale/es';

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

import i18next from "i18next";
import { ASSET_SERVER } from './../../../../config';

import { withStore } from '@spyna/react-store'

import 'react-dates/initialize';
import { DayPickerSingleDateController } from 'react-dates';

import './../../../../styles/ReactDatesOverride.css';

//import { SelectField } from "../../../components/settings/SelectField"; used for gender
import { AvatarImg } from "../../../components/AvatarImg";
// import { MyButton } from "../../../components/MyButton";
import "./../settings.css";
import './../../../../i18n'
import { PhoneField } from '../../../components/settings/PhoneField';


import { Mutation } from "react-apollo"
import gql from "graphql-tag"

  

  const UPLOAD_FILE_STREAM = gql`
    mutation uploadAvatar($file: Upload!, $oldFile: String) {
      uploadAvatar(file: $file, oldFile: $oldFile) {
        filename
        mimetype
        encoding
      }
    }
  `;


const updateMutation = gql`
    mutation($fld: String!, $value: String!) {
        updateField(fld: $fld, value: $value)
    }
`;

class C extends PureComponent {

    // constructor(props) {
    //     super(props);
    //     this.state = { selectedFile: null };
    // }
    
    state = {
        uploadState: "none",
        
        verificationModal: false,
        birtdaySelection: false,
        birtdayDate: moment(new Date()).format('YYYY-MM-DD'),
        fName: '',
    };


    componentDidMount() {

        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

        this.setState({
            uploadState: "none",
        });
    
    }


    componentDidUpdate(prevProps, prevState) {

        //console.log(prevProps)
        //console.log(prevState)
        if (this.state.uploadState === "completed") {

            this.setState({
                uploadState: "finalized"
            });

            //console.log('didupdate : ', this.state.uploadState);

        }


    }

    onEditAvatar = () => {
            
        this.upload.click()

        this.setState({
            uploadState: "started",
        });

    
    };


    handleFieldChange = (event) => {

        this.props.store.set([event.target.name], event.target.value)

    }


    handleFieldBlur = (event) => {

        this.props.client
            .mutate({
                mutation: updateMutation,
                variables: {
                    fld: event.target.name,
                    value: event.target.value
                },
            })
            .then(results => {
                // console.log(results)
            })
            .catch(error => {
                // console.log("Error: ", error);
            })

    }
    
   
    
    render() {


        let avatar = this.props.store.get('avatar');

        const filterStyles = {
            overlay: { zIndex: 1000 },
            content: {

                top: '24px',

                left: '5%',
                margin: 'auto',
                
                bottom: 'auto',
                                
                width: '500px',

                maxWidth: '74%',

                boxShadow: '0 20px 50px rgba(0, 0, 0, 0.33)',
                borderRadius: '5px',
                paddingTop: '16px',
                paddingLeft: '32px',
                paddingRight: '32px',
                                                    
                overflow : 'auto',
                

            }
        }

        const filterStylesMobile = {
            overlay: { zIndex: 1000 },
            content: {

                top: `80px`,

                left: '5%',
                margin: `auto`,
                
                bottom: 'auto',
                
                
                width: `73%`,

                boxShadow: '0 20px 50px rgba(0, 0, 0, 0.33)',
                borderRadius: '5px',
                paddingTop: '16px',
                paddingLeft: '32px',
                paddingRight: '32px',
                                                    
                overflow : 'auto',
                

            }
        }

        const returnYears = () => {
            let years = []
            for(let i = moment().year() - 100; i <= moment().year()+5; i++) {
                years.push(<option value={i} key={i}>{i}</option>);
            }
            return years;
        }
        
        const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => 
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="headerBetween">
                    <select
                        className="headerSelection"
                        value={month.month()}
                        onChange={(e) => onMonthSelect(month, parseInt(e.target.value))}
                    >
                        {moment.months().map((label, i) => (
                            <option value={i} key={i}>{label}</option>
                        ))}
                    </select>
                </div>
                <div className="headerBetween">
                    <select 
                        className="headerSelection"
                        value={month.year()} 
                        onChange={(e) => onYearSelect(month, e.target.value)}
                    >
                        {returnYears()}
                    </select>
                </div>
            </div>

        

        return (

            
            <ExtraWrapper>

                <Helmet>
                    <title>Personal Info</title>
                    <meta name="description" content="Account Settings" />
                    <meta name="keywords" content="photosouls,account" />
                </Helmet>

            
            
                <ModalTitle>

                    <BackButton onClick={ () => { this.props.history.push({ pathname: '../settings' }) } } >
                        <FAIcon icon={faChevronLeft} color={'#000'} />
                    </BackButton>

                    <TitleHeadline>
                        Personal information
                    </TitleHeadline>

                </ModalTitle>
                    
                        <div className='settingsFieldList'>

                            <div className="F1avatar">
                                <div className="F1">
                                    
                                    <AvatarImg
                                        image={avatar ? avatar.replace('128', '256') : null} //for the bigger image element, load a bit bigger one from aws than the default 128. Most people are not coming here every day, but everyone will see the header. Thus when loading smaller one to header, we save a bit in traffic
                                        //image={avatar}
                                        size={128}
                                        onLoad={(e) => {
                                            //e.target.src = avatar.replace('128', '256')  --- causes huge CPU spike. but looks like not necessary anyway!
                                            e.target.style = 'display: flex' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                        }}
                                        onError={(e) => {
                                            //e.target.src = '' // --- causes huge CPU spike. but looks like not necessary anyway!
                                            e.target.style = 'display: none' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                        }}
                                    />
                                    
                                    <Mutation 
                                        mutation={ UPLOAD_FILE_STREAM }
                                    >


                                        {
                                            (uploadAvatar, { data, loading }) => {
                                                

                                                if (loading !== true && data && this.state.uploadState === "started") {
                                                    
                                                    //!NB! timeout is needed as otherwise there is a render conflict. But with timeout, the render completes, and only after that the new values are loaded
                                                    setTimeout(async () => {
                                                        
                                                        // console.log('now finally submitted?')

                                                        this.setState({
                                                            uploadState: "completed",
                                                        });

                                                        const storageUserId = this.props.store.get('userId') ? '/avatar/256/' + this.props.store.get('userId') : 'latinized';
                                                        const avatarFilePath = ASSET_SERVER + storageUserId + '/' + this.upload.files[0].name

                                                        await this.props.store.set('avatar', avatarFilePath) 

                                                        //how to update menu bar avatar image?
                                                        // right now i have no other ideas than to re-load a page, 
                                                        // as i dont know how to update an element inside a satateless functional component, 
                                                        // or re-render the functional component itself
                                                        
                                                        window.location.reload(); //this works, but page flickers during refresh...
                                                        //this.forceUpdate(); //this works fine, except does not update the menubar image :(

                                                    }, 100 )

                                                    

                                                }

                                                return (
                                                    <form
                                                        onSubmit={ () => { 
                                                            //console.log('was submitted: ')
                                                        }}
                                                        encType={'multipart/form-data'}
                                                    >

                                                        <input 
                                                            name={'document'} 
                                                            type={'file'} 
                                                            accept={'image/*'}
                                                            ref={(ref) => this.upload = ref}
                                                            style={{display: "none"}}
                                                            onChange={({target: { files }}) => {
                                                                const file = files[0]
                                                                file && uploadAvatar({ variables: { file: file, oldFile: avatar } })
                                                                }
                                                            }
                                                        />
                                                        <div className="AvatarEditBtn">
                                                            { 
                                                                loading ? 
                                                                <StyledEdit disabled>Loading...</StyledEdit>
                                                                : 
                                                                <StyledEdit onClick={this.onEditAvatar}>Change Avatar</StyledEdit>
                                                            }
                                                        </div>
                                                        
                                                    </form>
                                                )
                                            }
                                        }
                                    </Mutation>


                                </div>
                            </div>
                            
                        </div>

                    
                        
                
                        
            <IncreaseHeight40/>
                <Form>      

                    <div className='settingsFieldList'>
                            
                        
                        <LabelStyled>First name</LabelStyled>
                        <InputStyled
                            name='fName'
                            value={this.props.store.get('fName')}
                            theme={{ bordercolor: "#cbcbcb" }}
                            autoCapitalize = 'none'
                            onChange={(e) => this.handleFieldChange(e)}
                            onBlur={(e) => this.handleFieldBlur(e)}
                        />



                        <LabelStyled>Last name</LabelStyled>
                        <InputStyled
                            name='lName'
                            value={this.props.store.get('lName')}
                            theme={{ bordercolor: "#cbcbcb" }}
                            autoCapitalize = 'none'
                            onChange={(e) => this.handleFieldChange(e)}
                            onBlur={(e) => this.handleFieldBlur(e)}
                        />



                        <LabelStyled>Birthday</LabelStyled>
                        <InputStyled
                            name='bDay'
                            value={ moment(this.props.store.get('bDay')).locale(i18next.language).format("LL") }
                            theme={{ bordercolor: "#cbcbcb" }}
                            autoCapitalize = 'none'
                            readOnly={true}
                            enabled={false}
                            onKeyDown={() => { this.setState({ birtdaySelection: !this.state.birtdaySelection, birtdayDate: this.props.store.get('bDay') }) } }
                            onClick={() => { this.setState({ birtdaySelection: !this.state.birtdaySelection, birtdayDate: this.props.store.get('bDay') }) } }
                        />

                        <StyledDivider></StyledDivider>


                        <Field
                            name="phone"
                            displayField="phoneDisplay"
                            countryField="phoneCountry"
                            
                            originalPhone={this.props.store.get('phone')}
                            originalValue={this.props.store.get('phoneDisplay')}
                            originalPhoneCountry={this.props.store.get('phoneCountry')}

                            label="Phone"
                            propStore={this.props.store}
                            component={withApollo(PhoneField)}
                            allowedToChange={true} 
                        />


                        <StyledDivider></StyledDivider>


                        <WidthContainer>
                            <InputStyled80
                                name='verified'
                                value="Account is not verified"
                                theme={{ bordercolor: "#cbcbcb" }}
                                autoCapitalize = 'none'
                                disabled={true}
                            />
                            <ClearFilter onClick={() => { this.setState({ verificationModal: !this.state.verificationModal }) }}>
                                Verify
                            </ClearFilter> 
                        </WidthContainer>
                        
                        <StyledDivider></StyledDivider>

                        {/* Verification modal */}
                        <Modal 
                            isOpen={this.state.verificationModal}
                            closeTimeoutMS={500}

                            onRequestClose={this.props.closePopup}
                            ariaHideApp={false}
                            style={window.innerWidth > 790 ? filterStyles : filterStylesMobile}
                            
                            onAfterOpen={() => {
                                document.body.style.top = `0px`
                                document.body.style.width = `100vw`
                                document.body.style.position = 'fixed'
                            }}

                            onAfterClose={() => {
                                const scrollY = document.body.style.top
                                document.body.style.position = ''
                                document.body.style.top = ''
                                document.body.style.width = ''
                                window.scrollTo(0, parseInt(scrollY || '0') * -1)
                            }}
                            >

                            <div className='react-confirm-alert-overlay'>
                                <div className='react-confirm-alert-body'>

                                <ModalTitle>
                                    
                                    <SectionTitle>
                                        <SectionHeadline>
                                            Verifying your identity. 
                                            <br/>
                                            <br/>
                                        </SectionHeadline>
                                    </SectionTitle>

                                    <SectionText>
                                        
                                        At PhotoSouls, the privacy and security of our community is a top priority for us. We’re always identifying ways to help make our community as secure as possible for everyone. 
                                        <br/>
                                        <br/>
                                        {/* Our aim is for everyone to feel confident that talents who provide services on PhotoSouls are who they say they are. 
                                        <br/>
                                        <br/> */}
                                        As many clients (and also service providers) prefer working with people who are verified, account owners can verify their identity. 
                                        <br/>
                                        <br/>
                                        With verified accounts anyone can be confident that the person they work with is at least 18 years of age, and their legal name is associated with their account. 
                                        <br/>
                                        <br/>
                                        Also, should you ever lose your access to the account, for example due to malicious actor who hacks into your system or e-mail account, we can give you back the access, as we can match you to the account owner identity.
                                        <br/>
                                        <br/>
                                        Your identification information (legal name, address, photo of your government ID, and selfie) is handled according to our Privacy Policy and isn’t shared with clients or service providers. We only share the verification status.
                                        <br/>
                                        <br/>
                                        
                                    </SectionText>

                                    <SectionHeadline>
                                        --
                                        <br/>
                                        <br/>
                                        Unfortunately, the identity verification is not yet available. We'll let you know once you can verify your account. 
                                        <br/>
                                        <br/>
                                    </SectionHeadline>

                                </ModalTitle>


                                <BtnOK onClick={() => { this.setState({ verificationModal: !this.state.verificationModal }) }}>
                                    OK
                                </BtnOK>

                            

                                { window.innerWidth > 790 ? <></> : <><IncreaseHeight64/><IncreaseHeight64/></> }

                                

                                </div>
                            </div>

                            
                        
                        </Modal>

                        {/* Birthday selection modal */}
                        <Modal 
                            isOpen={this.state.birtdaySelection}
                            closeTimeoutMS={500}

                            onRequestClose={this.props.closePopup}
                            ariaHideApp={false}
                            style={window.innerWidth > 790 ? filterStyles : filterStylesMobile}
                            
                            onAfterOpen={() => {
                                document.body.style.top = `0px`
                                document.body.style.width = `100vw`
                                document.body.style.position = 'fixed'
                            }}

                            onAfterClose={() => {
                                const scrollY = document.body.style.top
                                document.body.style.position = ''
                                document.body.style.top = ''
                                document.body.style.width = ''
                                window.scrollTo(0, parseInt(scrollY || '0') * -1)
                            }}
                            >

                            <div className='react-confirm-alert-overlay'>
                                <div className='react-confirm-alert-body-350'>

                                <ModalTitle>
                                    
                                    <SectionTitle>
                                        <SectionHeadline>
                                            Please select your birtday 
                                            <br/>
                                            <br/>
                                        </SectionHeadline>
                                    </SectionTitle>

                                    <DayPickerSingleDateController
                                        numberOfMonths={1}
                                        renderMonthElement={renderMonthElement}
                                        initialVisibleMonth={() => moment(this.state.birtdayDate)}
                                        onDateChange={(date) => { 
                                            this.setState({ birtdayDate: date })
                                        }}
                                        focused={true}
                                        date={moment(this.state.birtdayDate)}
                                    />


                                </ModalTitle>


                                <ButtonArea>

                                    <BtnSelect onClick={() => { 
                                        this.setState({ birtdaySelection: !this.state.birtdaySelection });
                                        this.props.store.set('bDay', moment(this.state.birtdayDate).format('YYYY-MM-DD'));
                                        this.handleFieldBlur( { target: { name: "bDay", value: moment(this.state.birtdayDate).format('YYYY-MM-DD') } } );
                                    }}>
                                        Select
                                    </BtnSelect>

                                    <StyledCancel
                                        onClick={() => { this.setState({ birtdaySelection: !this.state.birtdaySelection })  }}
                                        >
                                        Cancel
                                    </StyledCancel>

                                </ButtonArea>

                                

                            

                                { window.innerWidth > 790 ? <></> : <><IncreaseHeight64/><IncreaseHeight64/></> }

                                

                                </div>
                            </div>

                            
                        
                        </Modal>
                            
                    </div>
                    

        
                </Form>

            </ExtraWrapper>


        )
    }
}


const AccountSettingsPersonal = withFormik({
    
    //validationSchema: validEmailField,
    //console.log(this);

    mapPropsToValues: () => ({}),
    
    handleSubmit: async (values, { props, setErrors }) => {
        //console.log('handlesubmit')
    }
  })(C);
  

const personalInfoFields = [
    'fName', 'lName', 'bDay', 'email', 'phone', 'phoneDisplay', 'phoneCountry', 'avatar', 'isVerified', 'isPro', 'temperror', 'pwdUpdated',
    'chkReminders', 'chkRemindersP', 'chkMessages', 'chkMessagesP', 'chkRecomm', 'chkRecommP', 'chkInsp', 'chkInspP', 'chkLocal', 'chkLocalP', 
    'chkHow', 'chkHowP', 'chkInvite', 'chkInviteP', 'chkSurvey', 'chkSurveyP', 'chkPolicy', 'chkPolicyP', 'chkTips', 'chkTipsP'
]

export default withApollo(withStore(AccountSettingsPersonal, personalInfoFields));


const IncreaseHeight64 = styled.div`
    
    display: flex;
    width: 100%;
    height: 64px;  
    min-height: 64px;

`;


const ExtraWrapper = styled.div`

    white-space: pre-line;
    width: calc(80% - 64px);
    max-width: 1200px;

    margin: 40px auto 32px auto;

    font-family: 'Fira Sans';

    @media (max-width: 790px) {
        width: calc(100% - 32px);
        margin: 0;
        overflow-x: hidden;
    }

`;

const StyledEdit = styled(Button)`
    && {
    height: 40px;
    top: 8px;
    color: #333333;
    padding-left: 8px;
    padding-right: 8px; 
    /* width: 50%; */
    text-transform: none;
    font-weight: 400;
    font-size: 17px;

    
    }
    
`;

const StyledDivider = styled.div`
  width: 100%;
  height: 24px;
`;


const IncreaseHeight40 = styled.div`
    
    height: 40px;  
    min-height: 40px;

`;

const ModalTitle = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: flex-start;
    justify-content: flex-start;

    width: calc(100% - 24px);

    margin-left: 24px;
    padding-right: 24px;

    @media (max-width: 970px) {
        flex-direction: column;
        margin-top: 16px;
        padding-right: 24px;
    }


`;


const BackButton = styled(Button)`
    && {
        
    /* margin: -4px -4px 0 0px; */

    height: 48px;
    min-width: 48px;
    max-width: 48px;
    border-radius: 24px;

    margin-right: 16px;
    /* margin-right: 4px;
    margin-left: 4px; */

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;

    
    font-weight: 400;
    font-size: 19px;

    background-color: #EBEBEB;
    opacity: 0.6;

    &:hover {
        background-color: #EBEBEB;
        opacity: 0.6;
        }
    
    &:disabled {
        background-color: #fff;
        color: #fff;
        }

    }
    
`;

const FAIcon = styled(FontAwesomeIcon)`
    && {
    font-size: 22px;
    font-weight: 400;
    }
`;



const TitleHeadline = styled.div`
    
    padding: 12px 24px 24px 0px;

    font-family: 'Fira Sans';
    font-size: 22px;
    font-weight: 500;

    color: #333333;

`;


const ClearFilter = styled(Button)`
    && {

    height: 40px;

    padding-left: 16px;
    padding-right: 16px;

    color: #333333;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    }
    
`;

const SectionTitle = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: center;
    justify-content: space-between;

    width: 100%;
    min-height: 40px;

    /* background-color: #ebe; */

`;



const SectionHeadline = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 500;
    font-size: 20px;

    /* color: #505050; */
    /* color: #383838; */
    color: #333333;

    /* background-color: #ded; */

`;

const SectionText = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 18px;

    /* color: #505050; */
    /* color: #383838; */
    color: #333333;

    /* background-color: #ded; */

`;






const BtnOK = styled(Button)`
   && {
    min-height: 40px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    color: white;
    
    padding-left: 16px;
    padding-right: 16px;
    
    margin-right: 24px;
    margin-bottom: 24px;
    margin-left: 24px;

    text-transform: none;
    font-weight: 400;
    font-size: 17px;
        &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }
    }
    
`;

const BtnSelect = styled(Button)`
   && {
    min-height: 40px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    color: white;
    
    padding-left: 16px;
    padding-right: 16px;
    
    margin-right: 24px;
    margin-bottom: 24px;

    text-transform: none;
    font-weight: 400;
    font-size: 17px;
        &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }
    }
    
`;


const InputStyled = styled.input`
    
    width: 100%;
    max-width: 600px;
    
    height: 24px;
    
    
    margin: 0px;
    margin-left: 24px;
    margin-bottom: 24px;

    
    padding: 8px;

    font-size: 16px;
    font-family: 'Fira Sans';
    font-weight: '400';
    color: #333333;

    border: 1px solid ${props => props.theme.bordercolor};
    border-radius: 4px;  
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    :disabled {
        background-color: #f7f7f7; 
    }

    :focus {
        outline-color: #4C9AFF;
        width: calc(100% - 44px);
    }

    @media (max-width: 400px) {
        font-family: 'Fira Sans';
        font-size: 16px;
    }
`;

const InputStyled80 = styled.input`
    
    width: calc(100% - 150px);
    max-width: 600px;
    
    height: 24px;
    
    
    
    margin: 0px;
    margin-left: 24px;
    margin-bottom: 24px;
    margin-right: 24px;

    
    padding: 8px;

    font-size: 16px;
    font-family: 'Fira Sans';
    font-weight: '400';
    color: #333333;

    border: 1px solid ${props => props.theme.bordercolor};
    border-radius: 4px;  
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    :disabled {
        background-color: #f7f7f7; 
    }

    :focus {
        outline-color: #4C9AFF;
        width: calc(100% - 44px);
    }

    @media (max-width: 400px) {
        font-family: 'Fira Sans';
        font-size: 16px;
    }
`;

const WidthContainer = styled.div`
    width: 100%;
`;

const LabelStyled = styled.div`

    width: 100%;
    margin: 0px;
    padding: 0px;
    color: #333333;
    font-size: 16px;

    margin-bottom: 4px;
    margin-top: 0px;
    margin-left: 24px;

`;


const ButtonArea = styled.div`

    width: 100%;

    margin-left: 24px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    color: #333333;

`;


const StyledCancel = styled(Button)`
    && {
    height: 40px;
    
    padding-left: 16px;
    padding-right: 16px;

    color: #333333;
    
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    }
    
`;
