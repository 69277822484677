
import React from "react";
import { Form } from "antd";
import styled from 'styled-components';

const FormItem = Form.Item;


export const MyTextAreaField = ({
    field: { onChange, ...field },
    form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label, useNumberComponent = false, ...props
    }) => {

        const errorMsg = touched[field.name] && errors[field.name];
        
        const mybordercolor = errorMsg ? "#ff4343" : "#cbcbcb";

        

        return (

            <>
            
                { label ? <LabelStyled>{label}</LabelStyled> : null}
                
                <FormItem validateStatus={errorMsg ? "error" : undefined}>


                    <TextareaStyled
                        
                        theme={{ bordercolor: mybordercolor }}

                        rows={5}

                        {...field}
                        
                        {...props}
                        
                        onChange={
                            useNumberComponent
                                ? (newValue) => setFieldValue(field.name, newValue)
                                : onChange
                        }
                    />
                    

                </FormItem>

                { errorMsg ? <ErrorStyled>{errorMsg}</ErrorStyled> : <></>}

            </>

        );
    };


const LabelStyled = styled.div`
    width: 100%;
    margin: 0px;
    padding: 0px;
    color: #333333;
    font-size: 16px;
    
    margin-bottom: 4px;
    margin-top: 0px;
`;


const ErrorStyled = styled.div`
    width: 100%;
    margin: 0px;
    padding: 0px;
    height: 22px;
    color: #FF4343; 
    font-size: 13px;
    margin-top: 4px;
  
`;

const TextareaStyled = styled.textarea`
    
    width: calc(100% - 18px);
    padding: 0px;
    margin: 0px;
    /* height: 24px; */
    padding: 8px;
    font-size: 16px;
    font-family: 'Fira Sans';

    color: #333333;
    
    border: 1px solid #cbcbcb;
    border-radius: 4px;  
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    :focus {
        outline-color: #4C9AFF;
    }

    :disabled {
        background-color: #f7f7f7; 
    }

    @media (max-width: 690px) {
        font-size: 17px;
    }
`;