import React, { PureComponent } from 'react'
import { withApollo } from "react-apollo";

import { Field } from "formik";

import { MySelectField } from "../../../components/MySelectField";
import { RadioButtonGroup } from 'react-rainbow-components';

import Select from 'react-select'


import styled from 'styled-components';



class C extends PureComponent { 


    handleChange = event => {

        this.setState({
            [event.target.value]: event.target.checked,
        });
    
        this.props.values[event.target.value] = event.target.checked ? true : false

    };



    heightOptions = () => {
        return [
            { value: 12192, code: 12192, label: `4' 0"` },
            { value: 12446, code: 12446, label: `4' 1"` },
            { value: 12700, code: 12700, label: `4' 2"` },
            { value: 12954, code: 12954, label: `4' 3"` },
            { value: 13208, code: 13208, label: `4' 4"` },
            { value: 13462, code: 13462, label: `4' 5"` },
            { value: 13716, code: 13716, label: `4' 6"` },
            { value: 13970, code: 13970, label: `4' 7"` },
            { value: 14224, code: 14224, label: `4' 8"` },
            { value: 14478, code: 14478, label: `4' 9"` },
            { value: 14732, code: 14732, label: `4' 10"` },
            { value: 14986, code: 14986, label: `4' 11"` },

            { value: 15240, code: 15240, label: `5' 0"` },
            { value: 15494, code: 15494, label: `5' 1"` },
            { value: 15748, code: 15748, label: `5' 2"` },
            { value: 16002, code: 16002, label: `5' 3"` },
            { value: 16256, code: 16256, label: `5' 4"` },
            { value: 16510, code: 16510, label: `5' 5"` },
            { value: 16764, code: 16764, label: `5' 6"` },
            { value: 17018, code: 17018, label: `5' 7"` },
            { value: 17272, code: 17272, label: `5' 8"` },
            { value: 17526, code: 17526, label: `5' 9"` },
            { value: 17780, code: 17780, label: `5' 10"` },
            { value: 18034, code: 18034, label: `5' 11"` },
            
            { value: 18288, code: 18288, label: `6' 0"` },
            { value: 18542, code: 18542, label: `6' 1"` },
            { value: 18796, code: 18796, label: `6' 2"` },
            { value: 19050, code: 19050, label: `6' 3"` },
            { value: 19304, code: 19304, label: `6' 4"` },
            { value: 19558, code: 19558, label: `6' 5"` },
            { value: 19812, code: 19812, label: `6' 6"` },
            { value: 20066, code: 20066, label: `6' 7"` },
            { value: 20320, code: 20320, label: `6' 8"` },

        ]
    }

    dressUSw = () => {
        return [
            { value: 0, label: '-'},
            { value: 1, label: '0 (XX)'},
            { value: 2, label: '2 (XS)'},
            { value: 3, label: '4 (S)'},
            { value: 4, label: '6 (S)'},
            { value: 5, label: '8 (M)'},
            { value: 6, label: '10 (M)'},
            { value: 7, label: '12 (L)'},
            { value: 8, label: '14 (L)'},
            { value: 9, label: '16 (XL)'},
            { value: 10, label: '18 (XL)'},
            { value: 11, label: '20 (2X)'},
            { value: 12, label: '22 (3X)'},
            { value: 13, label: '24 (4X)'},
        ]
    }

    dressUKw  = () => {
        return [
            { value: 0, label: '-' },
            { value: 1, label: '2' },
            { value: 2, label: '4' },
            { value: 3, label: '6' },
            { value: 4, label: '8' },
            { value: 5, label: '10' },
            { value: 6, label: '12' },
            { value: 7, label: '14' },
            { value: 8, label: '16' },
            { value: 9, label: '18' },
            { value: 10, label: '20' },
            { value: 11, label: '22' },
            { value: 12, label: '24' },
            { value: 13, label: '26' },
        ];
    }

    dressEUw = () => {
        return [
            { value: 0, label: '-' },
            { value: 1, label: '30 (XXS)' },
            { value: 2, label: '32 (XS)' },
            { value: 3, label: '34 (XS)' },
            { value: 4, label: '36 (S)' },
            { value: 5, label: '38 (S)' },
            { value: 6, label: '40 (M)' },
            { value: 7, label: '42 (M)' },
            { value: 8, label: '44 (L)' },
            { value: 9, label: '46 (L)' },
            { value: 10, label: '48 (XL)' },
            { value: 11, label: '50 (XL)' },
            { value: 12, label: '52 (XXL)' },
            { value: 13, label: '54 (XXL)' },
        ];
    }

    dressJPw = () => {
        return [
            { value: 0, label: '-' },
            { value: 1, label: '5' },
            { value: 2, label: '7' },
            { value: 3, label: '9' },
            { value: 4, label: '11' },
            { value: 5, label: '13' },
            { value: 6, label: '15' },
            { value: 7, label: '17' },
            { value: 8, label: '19' },
            { value: 9, label: '21' },
            { value: 10, label: '23' },
            { value: 11, label: '25' },
            { value: 12, label: '27' },
            { value: 13, label: '29' },
        ];
    }

    dressUSm = () => {
        return [
            { value: 0, label: '-' },
            { value: 1, label: '30' },
            { value: 2, label: '32' },
            { value: 3, label: '34' },
            { value: 4, label: '36' },
            { value: 5, label: '38' },
            { value: 6, label: '40' },
            { value: 7, label: '42' },
            { value: 8, label: '44' },
            { value: 9, label: '46' },
            { value: 10, label: '48' },
            { value: 11, label: '50' },
            { value: 12, label: '52' },
            { value: 13, label: '54' },
            { value: 14, label: '58' },

        ];
    }

    dressUKm = () => {
        return [
            { value: 0, label: '-' },
            { value: 1, label: '30' },
            { value: 2, label: '32' },
            { value: 3, label: '34' },
            { value: 4, label: '36' },
            { value: 5, label: '38' },
            { value: 6, label: '40' },
            { value: 7, label: '42' },
            { value: 8, label: '44' },
            { value: 9, label: '46' },
            { value: 10, label: '48' },
            { value: 11, label: '50' },
            { value: 12, label: '52' },
            { value: 13, label: '54' },
            { value: 14, label: '58' },

        ];
    }

    dressEUm  = () => {
        return [
            { value: 0, label: '-' },
            { value: 1, label: '40 (XS)' },
            { value: 2, label: '42 (XS)' },
            { value: 3, label: '44 (S)' },
            { value: 4, label: '46 (S)' },
            { value: 5, label: '48 (M)' },
            { value: 6, label: '50 (M)' },
            { value: 7, label: '52 (L)' },
            { value: 8, label: '54 (L)' },
            { value: 9, label: '56 (XL)' },
            { value: 10, label: '58 (XL)' },
            { value: 11, label: '60 (2XL)' },
            { value: 12, label: '62 (2XL)' },
            { value: 13, label: '64 (3XL)' },
            { value: 14, label: '66 (3XL)' },

        ];
    }

    dressJPm  = () => {
        return [
            { value: 0, label: '-' },
            { value: 1, label: '32' },
            { value: 2, label: '34' },
            { value: 3, label: '36' },
            { value: 4, label: '38' },
            { value: 5, label: '40' },
            { value: 6, label: '42' },
            { value: 7, label: '44' },
            { value: 8, label: '46' },
            { value: 9, label: '48' },
            { value: 10, label: '50' },
            { value: 11, label: '52' },
            { value: 12, label: '54' },
            { value: 13, label: '56' },
            { value: 14, label: '58' },

        ];
    }

    shoesUSw = () => {
        return [
            { value: 0, label: '-' },
            { value: 1, label: '4' },
            { value: 2, label: '5' },
            { value: 3, label: '6' },
            { value: 4, label: '7' },
            { value: 5, label: '8' },
            { value: 6, label: '9' },
            { value: 7, label: '10' },
            { value: 8, label: '11' },
            { value: 9, label: '12' },
            { value: 10, label: '13' },
            { value: 11, label: '14' },
        ];
    }
    shoesUKw  = () => {
        return [
            { value: 0, label: '-' },
            { value: 1, label: '2' },
            { value: 2, label: '3' },
            { value: 3, label: '4' },
            { value: 4, label: '5' },
            { value: 5, label: '6' },
            { value: 6, label: '7' },
            { value: 7, label: '8' },
            { value: 8, label: '9' },
            { value: 9, label: '10' },
            { value: 10, label: '11' },
            { value: 11, label: '12' },
        ];
    }
    shoesEUw  = () => {
        return [
            { value: 0, label: '-' },
            { value: 1, label: '35' },
            { value: 2, label: '36' },
            { value: 3, label: '37' },
            { value: 4, label: '38' },
            { value: 5, label: '39' },
            { value: 6, label: '40' },
            { value: 7, label: '41' },
            { value: 8, label: '42' },
            { value: 9, label: '43' },
            { value: 10, label: '44' },
            { value: 11, label: '45' },
        ];
    }
    
    shoesJPw  = () => {
        return [
            { value: 0, label: '-' },
            { value: 1, label: '21' },
            { value: 2, label: '22' },
            { value: 3, label: '23' },
            { value: 4, label: '24' },
            { value: 5, label: '25' },
            { value: 6, label: '26' },
            { value: 7, label: '27' },
            { value: 8, label: '28' },
            { value: 9, label: '29' },
            { value: 10, label: '30' },
            { value: 11, label: '31' },
        ];
    }

    shoesUSm  = () => {
        return [
            { value: 0, label: '-' },
            { value: 1, label: '5' },
            { value: 2, label: '6' },
            { value: 3, label: '7' },
            { value: 4, label: '8' },
            { value: 5, label: '9' },
            { value: 6, label: '10' },
            { value: 7, label: '11' },
            { value: 8, label: '12' },
            { value: 9, label: '13' },
            { value: 10, label: '14' },
            { value: 11, label: '15' },
        ];
    }
    shoesUKm  = () => {
        return [
            { value: 0, label: '-' },
            { value: 1, label: '4' },
            { value: 2, label: '5' },
            { value: 3, label: '6' },
            { value: 4, label: '7' },
            { value: 5, label: '8' },
            { value: 6, label: '9' },
            { value: 7, label: '10' },
            { value: 8, label: '11' },
            { value: 9, label: '12' },
            { value: 10, label: '13' },
            { value: 11, label: '14' },
        ];
    }
    shoesEUm  = () => {
        return [
            { value: 0, label: '-' },
            { value: 1, label: '38' },
            { value: 2, label: '39' },
            { value: 3, label: '40' },
            { value: 4, label: '41' },
            { value: 5, label: '42' },
            { value: 6, label: '43' },
            { value: 7, label: '44' },
            { value: 8, label: '45' },
            { value: 9, label: '46' },
            { value: 10, label: '47' },
            { value: 11, label: '48' },
        ];
    }
    shoesJPm  = () => {
        return [
            { value: 0, label: '-' },
            { value: 1, label: '22' },
            { value: 2, label: '23' },
            { value: 3, label: '24' },
            { value: 4, label: '25' },
            { value: 5, label: '26' },
            { value: 6, label: '27' },
            { value: 7, label: '28' },
            { value: 8, label: '29' },
            { value: 9, label: '30' },
            { value: 10, label: '31' },
            { value: 11, label: '32' },
        ];
    }


    render() {

        const worksWith = [
            { value: '0', label: 'Photographers from any gender' },
            { value: '1', label: 'Women only' },
            { value: '2', label: 'Men only' },
        ];

        const unitOptions = [
            { value: '0', label: 'Metric', disabled: false },
            { value: '1', label: 'Imperial', disabled: false },
        ];

        const chartOptionsWomen = [
            { value: '0', label: 'US', disabled: false },
            { value: '1', label: 'UK', disabled: false },
            { value: '2', label: 'EU', disabled: false },
            { value: '3', label: 'JP', disabled: false },
            // { value: '3', label: 'FR', disabled: false },
            // { value: '4', label: 'DE', disabled: false },
            // { value: '5', label: 'IT', disabled: false },
        ];
        const chartOptionsMen = [
            { value: '0', label: 'US', disabled: false },
            { value: '1', label: 'UK', disabled: false },
            { value: '2', label: 'EU', disabled: false },
            { value: '3', label: 'JP', disabled: false },
        ];

        const physiqueOptions = [
            { value: '0', label: 'Very slim', disabled: false },
            { value: '1', label: 'Slim', disabled: false },
            { value: '2', label: 'Normal', disabled: false },
            { value: '3', label: 'Curvy', disabled: false },
            { value: '4', label: 'Very curvy', disabled: false },
        ];

        const cupOptions = [
            { value: '0', label: 'AA', disabled: false },
            { value: '1', label: 'A', disabled: false },
            { value: '2', label: 'B', disabled: false },
            { value: '3', label: 'C', disabled: false },
            { value: '4', label: 'D', disabled: false },
            { value: '5', label: 'E / DD', disabled: false },
            { value: '6', label: 'F / DDD', disabled: false },
            { value: '7', label: 'G / DDDD', disabled: false },
            { value: '8', label: 'H', disabled: false },
            { value: '9', label: 'I', disabled: false },
            { value: '10', label: 'J', disabled: false },
            { value: '11', label: 'K', disabled: false },
        ];
        const eyeColorOptions = [
            { value: '0', label: 'Black', disabled: false },
            { value: '1', label: 'Blue', disabled: false },
            { value: '2', label: 'Brown', disabled: false },
            { value: '3', label: 'Green', disabled: false },
            { value: '4', label: 'Grey', disabled: false },
            { value: '5', label: 'Hazel', disabled: false },
            { value: '6', label: 'Other', disabled: false },
        ];         
        const hairLengthOptions = [
            { value: '0', label: 'Bald', disabled: false },
            { value: '1', label: 'Short', disabled: false },
            { value: '2', label: 'Chin-length', disabled: false },
            { value: '3', label: 'Shoulder-length', disabled: false },
            { value: '4', label: 'Long', disabled: false },
            { value: '5', label: 'Very long', disabled: false },
        ];    
        const hairColorOptions = [
            { value: '0', label: 'Black', disabled: false },
            { value: '1', label: 'Blonde', disabled: false },
            { value: '2', label: 'Blonde (light)', disabled: false },
            { value: '3', label: 'Blue', disabled: false },
            { value: '4', label: 'Brown', disabled: false },
            { value: '5', label: 'Brown (dark)', disabled: false },
            { value: '6', label: 'Brown (light)', disabled: false },
            { value: '7', label: 'Green', disabled: false },
            { value: '8', label: 'Grey', disabled: false },
            { value: '9', label: 'Highlighted', disabled: false },
            { value: '10', label: 'Orange', disabled: false },
            { value: '11', label: 'Pink', disabled: false },
            { value: '12', label: 'Purple', disabled: false },
            { value: '13', label: 'Red', disabled: false },
            { value: '14', label: 'Salt and pepper', disabled: false },
            { value: '15', label: 'White', disabled: false },
            { value: '16', label: 'Yellow', disabled: false },
            { value: '17', label: 'Other', disabled: false },
        ];    
        const noneSomeManyOptions = [
            { value: '0', label: 'None', disabled: false },
            { value: '1', label: 'Some', disabled: false },
            { value: '2', label: 'Many', disabled: false },
        ];    
        const skinOptions = [
            { value: '0', label: 'Black', disabled: false },
            { value: '1', label: 'Brown', disabled: false },
            { value: '2', label: 'Olive', disabled: false },
            { value: '3', label: 'Other', disabled: false },
            { value: '4', label: 'Tanned', disabled: false },
            { value: '5', label: 'White', disabled: false },
            { value: '6', label: 'White (pale)', disabled: false },
        ]; 

        return (

            <PageContent>


                <PageTitle>Details</PageTitle>

               

                <OneRowItems>
                    
                    <ColItem1>

                        
                        { this.props.values.metric === 0 ?
                            <>
                                <LabelStyled>Height (cm):</LabelStyled>
                                <InputStyled 
                                    name='height'
                                    autoCapitalize = 'none'
                                    autoComplete = 'off'
                                    type="number" 
                                    min="0" 
                                    step="1"
                                    // oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    // oninput="validity.valid||(value='');"
                                    defaultValue={this.props.values.height}
                                    onChange={ (e) => {
                                        this.props.setField('height', parseInt(e.target.value));
                                        this.props.setField('heightMm', parseInt(e.target.value)) 
                                        }
                                    }
                                />
                            </>
                            :
                            <>
                                <LabelStyled>Height</LabelStyled>
                                <Select 
                                    name='height'
                                    classNamePrefix="select"
                                    styles={colourStyles}
                                    options={this.heightOptions()} 
                                    value={this.heightOptions().find(op => { return op.value === this.props.values.height })} 
                                    isSearchable={true}
                                    onChange={(e) => { 
                                        this.props.setField('height', parseInt(e.code)) 
                                        this.props.setField('heightMm', parseInt(e.code)) 
                                    }}
                                />

                            </>
                        }
                    </ColItem1>
                    <ColItem2>
                        <LabelStyled>&nbsp;</LabelStyled>
                        <RadioButtonGroup
                            id="metric"
                            options={unitOptions}
                            value={this.props.values.metric.toString()}
                            onChange={ (event) => {
                                const newModeValue = event.target.value;
                                // const distanceMilesToKm = (this.state.selectedPackageTravelDistance * 1.609)
                                if (newModeValue === '0') {

                                    //change from inches to cm
                                    
                                    this.props.setField('metric', parseInt(newModeValue)) 

                                    this.props.setField('height', parseInt(this.props.values.height/100)) 
                                    this.props.setField('heightMm', parseInt(this.props.values.height/100)) 

                                } else {

                                    //change from cm to inches
                                    
                                    const heightInMm = this.props.values.height*100;

                                    const tempArr = this.heightOptions();
                                    let exactMM = tempArr[0].value; //default = min length. assuming we will find actual match below...
                                    for (var i = tempArr.length-1; i >= 0; i--) {
                                        if (i < tempArr.length-1) {
                                            // console.log(heightInMm + ' ' + tempArr[i+1].value + ' ' + tempArr[i].value)
                                            if (heightInMm <= tempArr[i+1].value && heightInMm > tempArr[i].value) {
                                                exactMM = tempArr[i+1].value
                                            }
                                        }
                                    }

                                    this.props.setField('metric', parseInt(newModeValue)) 

                                    this.props.setField('height', parseInt(exactMM)) 
                                    this.props.setField('heightMm', parseInt(exactMM)) 

                                }
                            }}
                        />
                        <IncreaseHeight32/>
                    </ColItem2>
                

                </OneRowItems>

                <OneRowItems>
                    
                    <ColItem1>
                        
                        { this.props.values.metric === 0 ?
                            <>
                                <LabelStyled>Weight (kg):</LabelStyled>
                                <InputStyled 
                                    name='weightKg'
                                    autoCapitalize = 'none'
                                    autoComplete = 'off'
                                    type="number" 
                                    min="0" 
                                    step="1"
                                    // oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    // oninput="validity.valid||(value='');"
                                    defaultValue={this.props.values.weightKg}
                                    value={this.props.values.weightKg}
                                    onChange={ (e) => {
                                        this.props.setField('weightLbs', parseInt(e.target.value*2.20462));
                                        this.props.setField('weightKg', parseInt(e.target.value)) 
                                        // console.log('entered:', e.target.value)
                                        // console.log('lbs ', this.props.values.weightLbs)
                                        // console.log('kg ', this.props.values.weightKg)
                                        }
                                    }
                                />
                            </>
                            :
                            <>
                                <LabelStyled>Weight (lbs)</LabelStyled>
                                <InputStyled 
                                    name='weightLbs'
                                    autoCapitalize = 'none'
                                    autoComplete = 'off'
                                    type="number" 
                                    min="0" 
                                    step="1"
                                    // oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    // oninput="validity.valid||(value='');"
                                    defaultValue={this.props.values.weightLbs}
                                    value={this.props.values.weightLbs}

                                    onChange={ (e) => {
                                        this.props.setField('weightLbs', parseInt(e.target.value));
                                        this.props.setField('weightKg', parseInt(e.target.value/2.20462)) 
                                        // console.log('entered:', e.target.value)
                                        // console.log('lbs ', this.props.values.weightLbs)
                                        // console.log('kg ', this.props.values.weightKg)
                                        }
                                    }
                                />
                            </>
                        }
                    </ColItem1>
                
                </OneRowItems>

                <IncreaseHeight></IncreaseHeight>
                

                


                <OneRowItems>
                    <FieldContainer>

                        <Field
                            name="physique"
                            label='Physique'
                            component={MySelectField}

                            originalOptions={physiqueOptions}
                            originalValue={this.props.values.physique}
                            onChange={ (e) => this.props.setField('physique', parseInt(e.value)) }
                        />

                    </FieldContainer>
                </OneRowItems>


                <OneRowItems>
                    
                    <ColItem1>
                        
                        { this.props.values.metric === 0 ?
                            <>
                                <LabelStyled>Bust (cm):</LabelStyled>
                                <InputStyled 
                                    name='bustCm'
                                    autoCapitalize = 'none'
                                    autoComplete = 'off'
                                    type="number" 
                                    min="0" 
                                    step="1"
                                    // oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    // oninput="validity.valid||(value='');"
                                    defaultValue={this.props.values.bustCm}
                                    value={this.props.values.bustCm}
                                    onChange={ (e) => {
                                        this.props.setField('bustInch', parseInt(e.target.value/2.54));
                                        this.props.setField('bustCm', parseInt(e.target.value)) 
                                        // console.log('entered:', e.target.value)
                                        // console.log('lbs ', this.props.values.bustInch)
                                        // console.log('kg ', this.props.values.bustCm)
                                        }
                                    }
                                />
                            </>
                            :
                            <>
                                <LabelStyled>Bust (")</LabelStyled>
                                <InputStyled 
                                    name='bustInch'
                                    autoCapitalize = 'none'
                                    autoComplete = 'off'
                                    type="number" 
                                    min="0" 
                                    step="1"
                                    // oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    // oninput="validity.valid||(value='');"
                                    defaultValue={this.props.values.bustInch}
                                    value={this.props.values.bustInch}

                                    onChange={ (e) => {
                                        this.props.setField('bustInch', parseInt(e.target.value));
                                        this.props.setField('bustCm', parseInt(e.target.value*2.54)) 
                                        // console.log('entered:', e.target.value)
                                        // console.log('lbs ', this.props.values.bustInch)
                                        // console.log('kg ', this.props.values.bustKg)
                                        }
                                    }
                                />
                            </>
                        }
                    </ColItem1>
                    <ColItem2>
                        
                    </ColItem2>
                
                </OneRowItems>

                <IncreaseHeight></IncreaseHeight>

                <OneRowItems>
                    
                    <ColItem1>
                        
                        { this.props.values.metric === 0 ?
                            <>
                                <LabelStyled>Waist (cm):</LabelStyled>
                                <InputStyled 
                                    name='waistCm'
                                    autoCapitalize = 'none'
                                    autoComplete = 'off'
                                    type="number" 
                                    min="0" 
                                    step="1"
                                    // oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    // oninput="validity.valid||(value='');"
                                    defaultValue={this.props.values.waistCm}
                                    value={this.props.values.waistCm}
                                    onChange={ (e) => {
                                        this.props.setField('waistInch', parseInt(e.target.value/2.54));
                                        this.props.setField('waistCm', parseInt(e.target.value)) 
                                        // console.log('entered:', e.target.value)
                                        // console.log('lbs ', this.props.values.waistInch)
                                        // console.log('kg ', this.props.values.waistCm)
                                        }
                                    }
                                />
                            </>
                            :
                            <>
                                <LabelStyled>Waist (")</LabelStyled>
                                <InputStyled 
                                    name='waistInch'
                                    autoCapitalize = 'none'
                                    autoComplete = 'off'
                                    type="number" 
                                    min="0" 
                                    step="1"
                                    // oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    // oninput="validity.valid||(value='');"
                                    defaultValue={this.props.values.waistInch}
                                    value={this.props.values.waistInch}

                                    onChange={ (e) => {
                                        this.props.setField('waistInch', parseInt(e.target.value));
                                        this.props.setField('waistCm', parseInt(e.target.value*2.54)) 
                                        // console.log('entered:', e.target.value)
                                        // console.log('lbs ', this.props.values.waistInch)
                                        // console.log('kg ', this.props.values.waistKg)
                                        }
                                    }
                                />
                            </>
                        }
                    </ColItem1>
                    <ColItem2>
                        
                    </ColItem2>
                
                </OneRowItems>

                <IncreaseHeight></IncreaseHeight>

                <OneRowItems>
                    
                    <ColItem1>
                        
                    { this.props.values.metric === 0 ?
                            <>
                                <LabelStyled>Hips (cm):</LabelStyled>
                                <InputStyled 
                                    name='hipsCm'
                                    autoCapitalize = 'none'
                                    autoComplete = 'off'
                                    type="number" 
                                    min="0" 
                                    step="1"
                                    // oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    // oninput="validity.valid||(value='');"
                                    defaultValue={this.props.values.hipsCm}
                                    value={this.props.values.hipsCm}
                                    onChange={ (e) => {
                                        this.props.setField('hipsInch', parseInt(e.target.value/2.54));
                                        this.props.setField('hipsCm', parseInt(e.target.value)) 
                                        // console.log('entered:', e.target.value)
                                        // console.log('lbs ', this.props.values.hipsInch)
                                        // console.log('kg ', this.props.values.hipsCm)
                                        }
                                    }
                                />
                            </>
                            :
                            <>
                                <LabelStyled>Hips (")</LabelStyled>
                                <InputStyled 
                                    name='hipsInch'
                                    autoCapitalize = 'none'
                                    autoComplete = 'off'
                                    type="number" 
                                    min="0" 
                                    step="1"
                                    // oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    // oninput="validity.valid||(value='');"
                                    defaultValue={this.props.values.hipsInch}
                                    value={this.props.values.hipsInch}

                                    onChange={ (e) => {
                                        this.props.setField('hipsInch', parseInt(e.target.value));
                                        this.props.setField('hipsCm', parseInt(e.target.value*2.54)) 
                                        // console.log('entered:', e.target.value)
                                        // console.log('lbs ', this.props.values.hipsInch)
                                        // console.log('kg ', this.props.values.hipsKg)
                                        }
                                    }
                                />
                            </>
                        }

                    </ColItem1>
                    <ColItem2>
                    </ColItem2>
                
                </OneRowItems>

                

                { this.props.values.gender === 2 ?
                    <>
                        <IncreaseHeight></IncreaseHeight>
                        <OneRowItems>
                            <FieldContainer>

                                <Field
                                    name="cup"
                                    label='Cup'
                                    component={MySelectField}

                                    originalOptions={cupOptions}
                                    originalValue={this.props.values.cup}
                                    onChange={ (e) => this.props.setField('cup', parseInt(e.value)) }
                                />

                            </FieldContainer>
                        </OneRowItems>
                    </>
                    :
                    <IncreaseHeight></IncreaseHeight>
                }
                

               

                <OneRowItems>
                    
                    <ColItem1>
                        
                        <LabelStyled>Dress size</LabelStyled>
                        {
                            this.props.values.gender !== 2 ? 
                                //NOT WOMEN (Men) 
                                this.props.values.sizeChart === 0 ? 
                                    <Select 
                                        name='dress'
                                        classNamePrefix="select"
                                        styles={colourStyles}
                                        options={this.dressUSm()} 
                                        value={this.dressUSm().find(op => { return op.value === this.props.values.dress })} 
                                        isSearchable={true}
                                        onChange={(e) => { this.props.setField('dress', parseInt(e.value)) }}
                                    />
                                : this.props.values.sizeChart === 1 ? 
                                    <Select 
                                        name='dress'
                                        classNamePrefix="select"
                                        styles={colourStyles}
                                        options={this.dressUKm()} 
                                        value={this.dressUKm().find(op => { return op.value === this.props.values.dress })} 
                                        isSearchable={true}
                                        onChange={(e) => { this.props.setField('dress', parseInt(e.value)) }}
                                    />
                                : this.props.values.sizeChart === 2 ? 
                                    <Select 
                                        name='dress'
                                        classNamePrefix="select"
                                        styles={colourStyles}
                                        options={this.dressEUm()} 
                                        value={this.dressEUm().find(op => { return op.value === this.props.values.dress })} 
                                        isSearchable={true}
                                        onChange={(e) => { this.props.setField('dress', parseInt(e.value)) }}
                                    />
                                : this.props.values.sizeChart === 3 ? 
                                    <Select 
                                        name='dress'
                                        classNamePrefix="select"
                                        styles={colourStyles}
                                        options={this.dressJPm()} 
                                        value={this.dressJPm().find(op => { return op.value === this.props.values.dress })} 
                                        isSearchable={true}
                                        onChange={(e) => { this.props.setField('dress', parseInt(e.value)) }}
                                    />
                                : <Select 
                                        name='dress'
                                        classNamePrefix="select"
                                        styles={colourStyles}
                                        options={this.dressUSm()} 
                                        value={this.dressUSm().find(op => { return op.value === this.props.values.dress })} 
                                        isSearchable={true}
                                        onChange={(e) => { this.props.setField('dress', parseInt(e.value)) }}
                                    />

                                : 
                                
                                //WOMEN
                                this.props.values.sizeChart === 0 ? 
                                
                                    <Select 
                                        name='dress'
                                        classNamePrefix="select"
                                        styles={colourStyles}
                                        options={this.dressUSw()} 
                                        value={this.dressUSw().find(op => { return op.value === this.props.values.dress })} 
                                        isSearchable={true}
                                        onChange={(e) => { this.props.setField('dress', parseInt(e.value)) }}
                                    />

                                : this.props.values.sizeChart === 1 ? 
                                    <Select 
                                        name='dress'
                                        classNamePrefix="select"
                                        styles={colourStyles}
                                        options={this.dressUKw()} 
                                        value={this.dressUKw().find(op => { return op.value === this.props.values.dress })} 
                                        isSearchable={true}
                                        onChange={(e) => { this.props.setField('dress', parseInt(e.value)) }}
                                    />
                                : this.props.values.sizeChart === 2 ? 
                                    <Select 
                                        name='dress'
                                        classNamePrefix="select"
                                        styles={colourStyles}
                                        options={this.dressEUw()} 
                                        value={this.dressEUw().find(op => { return op.value === this.props.values.dress })} 
                                        isSearchable={true}
                                        onChange={(e) => { this.props.setField('dress', parseInt(e.value)) }}
                                    />
                                : this.props.values.sizeChart === 3 ? 
                                    <Select 
                                        name='dress'
                                        classNamePrefix="select"
                                        styles={colourStyles}
                                        options={this.dressJPw()} 
                                        value={this.dressJPw().find(op => { return op.value === this.props.values.dress })} 
                                        isSearchable={true}
                                        onChange={(e) => { this.props.setField('dress', parseInt(e.value)) }}
                                    />
                                : <Select 
                                        name='dress'
                                        classNamePrefix="select"
                                        styles={colourStyles}
                                        options={this.dressUSw()} 
                                        value={this.dressUSw().find(op => { return op.value === this.props.values.dress })} 
                                        isSearchable={true}
                                        onChange={(e) => { this.props.setField('dress', parseInt(e.value)) }}
                                    />

                        }

                    </ColItem1>

                    <ColItem2>
                        <LabelStyled>&nbsp;</LabelStyled>
                        <RadioButtonGroup
                            id="sizeChart"
                            options={this.props.values.gender !== 2 ? chartOptionsMen : chartOptionsWomen}
                            value={this.props.values.sizeChart.toString()}
                            onChange={ (event) => { 
                                
                                this.props.setField('sizeChart', parseInt(event.target.value)) 
                                this.props.setField('dress', this.props.values.dress)

                                }}
                        />
                        <IncreaseHeight32/>
                    </ColItem2>
                

                </OneRowItems>


                <OneRowItems>
                    
                    <ColItem1>
                        
                        <LabelStyled>Shoe size</LabelStyled>
                        {
                            this.props.values.gender !== 2 ? 
                                //NOT WOMEN (Men) 
                                this.props.values.sizeChart === 0 ? 
                                    <Select 
                                        name='shoes'
                                        classNamePrefix="select"
                                        styles={colourStyles}
                                        options={this.shoesUSm()} 
                                        value={this.shoesUSm().find(op => { return op.value === this.props.values.shoes })} 
                                        isSearchable={true}
                                        onChange={(e) => { this.props.setField('shoes', parseInt(e.value)) }}
                                    />
                                : this.props.values.sizeChart === 1 ? 
                                    <Select 
                                        name='shoes'
                                        classNamePrefix="select"
                                        styles={colourStyles}
                                        options={this.shoesUKm()} 
                                        value={this.shoesUKm().find(op => { return op.value === this.props.values.shoes })} 
                                        isSearchable={true}
                                        onChange={(e) => { this.props.setField('shoes', parseInt(e.value)) }}
                                    />
                                : this.props.values.sizeChart === 2 ? 
                                    <Select 
                                        name='shoes'
                                        classNamePrefix="select"
                                        styles={colourStyles}
                                        options={this.shoesEUm()} 
                                        value={this.shoesEUm().find(op => { return op.value === this.props.values.shoes })} 
                                        isSearchable={true}
                                        onChange={(e) => { this.props.setField('shoes', parseInt(e.value)) }}
                                    />
                                : this.props.values.sizeChart === 3 ? 
                                    <Select 
                                        name='shoes'
                                        classNamePrefix="select"
                                        styles={colourStyles}
                                        options={this.shoesJPm()} 
                                        value={this.shoesJPm().find(op => { return op.value === this.props.values.shoes })} 
                                        isSearchable={true}
                                        onChange={(e) => { this.props.setField('shoes', parseInt(e.value)) }}
                                    />
                                : <Select 
                                        name='shoes'
                                        classNamePrefix="select"
                                        styles={colourStyles}
                                        options={this.shoesUSm()} 
                                        value={this.shoesUSm().find(op => { return op.value === this.props.values.shoes })} 
                                        isSearchable={true}
                                        onChange={(e) => { this.props.setField('shoes', parseInt(e.value)) }}
                                    />

                                : 
                                
                                //WOMEN
                                this.props.values.sizeChart === 0 ? 
                                
                                    <Select 
                                        name='shoes'
                                        classNamePrefix="select"
                                        styles={colourStyles}
                                        options={this.shoesUSw()} 
                                        value={this.shoesUSw().find(op => { return op.value === this.props.values.shoes })} 
                                        isSearchable={true}
                                        onChange={(e) => { this.props.setField('shoes', parseInt(e.value)) }}
                                    />

                                : this.props.values.sizeChart === 1 ? 
                                    <Select 
                                        name='shoes'
                                        classNamePrefix="select"
                                        styles={colourStyles}
                                        options={this.shoesUKw()} 
                                        value={this.shoesUKw().find(op => { return op.value === this.props.values.shoes })} 
                                        isSearchable={true}
                                        onChange={(e) => { this.props.setField('shoes', parseInt(e.value)) }}
                                    />
                                : this.props.values.sizeChart === 2 ? 
                                    <Select 
                                        name='shoes'
                                        classNamePrefix="select"
                                        styles={colourStyles}
                                        options={this.shoesEUw()} 
                                        value={this.shoesEUw().find(op => { return op.value === this.props.values.shoes })} 
                                        isSearchable={true}
                                        onChange={(e) => { this.props.setField('shoes', parseInt(e.value)) }}
                                    />
                                : this.props.values.sizeChart === 3 ? 
                                    <Select 
                                        name='shoes'
                                        classNamePrefix="select"
                                        styles={colourStyles}
                                        options={this.shoesJPw()} 
                                        value={this.shoesJPw().find(op => { return op.value === this.props.values.shoes })} 
                                        isSearchable={true}
                                        onChange={(e) => { this.props.setField('shoes', parseInt(e.value)) }}
                                    />
                                : <Select 
                                        name='shoes'
                                        classNamePrefix="select"
                                        styles={colourStyles}
                                        options={this.shoesUSw()} 
                                        value={this.shoesUSw().find(op => { return op.value === this.props.values.shoes })} 
                                        isSearchable={true}
                                        onChange={(e) => { this.props.setField('shoes', parseInt(e.value)) }}
                                    />

                        }

                    </ColItem1>

                </OneRowItems>

                <IncreaseHeight></IncreaseHeight>

                <FieldContainer>
                    <Field
                        name="eyeColor"
                        label='Eye color'
                        component={MySelectField}

                        originalOptions={eyeColorOptions}
                        originalValue={this.props.values.eyeColor}
                        onChange={ (e) => this.props.setField('eyeColor', parseInt(e.value)) }
                    />
                </FieldContainer>


                <FieldContainer>
                    <Field
                        name="hairLength"
                        label='Hair length'
                        component={MySelectField}

                        originalOptions={hairLengthOptions}
                        originalValue={this.props.values.hairLength}
                        onChange={ (e) => this.props.setField('hairLength', parseInt(e.value)) }
                    />
                </FieldContainer>


                <FieldContainer>
                    <Field
                        name="hairColor"
                        label='Hair color'
                        component={MySelectField}

                        originalOptions={hairColorOptions}
                        originalValue={this.props.values.hairColor}
                        onChange={ (e) => this.props.setField('hairColor', parseInt(e.value)) }
                    />
                </FieldContainer>


                <FieldContainer>
                    <Field
                        name="tattoos"
                        label='Tattoos'
                        component={MySelectField}

                        originalOptions={noneSomeManyOptions}
                        originalValue={this.props.values.tattoos}
                        onChange={ (e) => this.props.setField('tattoos', parseInt(e.value)) }
                    />
                </FieldContainer>


                <FieldContainer>
                    <Field
                        name="piercing"
                        label='Piercing'
                        component={MySelectField}

                        originalOptions={noneSomeManyOptions}
                        originalValue={this.props.values.piercing}
                        onChange={ (e) => this.props.setField('piercing', parseInt(e.value)) }
                    />
                </FieldContainer>


                <FieldContainer>
                    <Field
                        name="skin"
                        label='Skin'
                        component={MySelectField}

                        originalOptions={skinOptions}
                        originalValue={this.props.values.skin}
                        onChange={ (e) => this.props.setField('skin', parseInt(e.value)) }
                    />
                </FieldContainer>


                <OneRowItems>
                <ColItem1>
                    <FieldContainer>

                        <LabelStyled>Age:</LabelStyled>
                        <InputStyled 
                            name='age'
                            autoCapitalize = 'none'
                            autoComplete = 'off'
                            type="number" 
                            min="0" 
                            step="1"
                            defaultValue={this.props.values.age}
                            onChange={ (e) => {
                                this.props.setField('age', parseInt(e.target.value)) 
                            }}
                        />


                    </FieldContainer>
                    </ColItem1>
                </OneRowItems>

                <OneRowItems>
                    <FieldContainer>

                        <Field
                            name="worksWith"
                            label='Willing to work with'
                            component={MySelectField}

                            originalOptions={worksWith}
                            originalValue={this.props.values.worksWith}
                            onChange={ (e) => this.props.setField('worksWith', parseInt(e.value)) }
                        />

                    </FieldContainer>
                </OneRowItems>




                {/* <LinkButton onClick={ () => { 
                    // console.log('state: ', this.state); 
                    // console.log('props: ', this.props) 
                    } } > Check state 
                </LinkButton> */}



            </PageContent>

        )

    }
    
}




export const DetailsPage = (withApollo(C));


const IncreaseHeight = styled.div`
    
    height: 24px;  

`;

const IncreaseHeight32 = styled.div`
    
    height: 32px;  
    min-height: 32px;

`;

const PageContent = styled.div`
    
    width: 100%;
    height: 100%;
    min-height: 75vh;
    font-family: 'Fira Sans';

    display: flex;
    flex-direction: column;
    
`;

const PageTitle = styled.div`
    
    display: flex;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;

    display: none;
    @media (max-width: 790px) {
        display: block;
        }


`;


const FieldContainer = styled.div`
    
    margin: 0px auto 32px auto;
    width: 100%;
    
`;

const OneRowItems = styled.div`
    width: 100%;
    min-width: 100%;
    

`;

const ColItem1 = styled.div`
    width: 40%;
    min-width: 40%;
    display: relative;
    float:left;
    margin-right: 32px;

    @media (max-width: 890px) {
        width: 100%;
        min-width: 100%;
    }

`;

const ColItem2 = styled.div`
    
    width: 40%;
    min-width: 40%;
    display: relative;
    float:left;

    @media (max-width: 890px) {
        width: 100%;
        min-width: 100%;
        margin-top: 12px;
    }

`;

const InputStyled = styled.input`
    
    width: calc(100% - 18px);
    padding: 0px;
    margin: 0px;
    height: 24px;
    padding: 8px;
    font-size: 16px;
    font-family: 'Fira Sans';
    font-weight: 400;

    color: #333333;
    
    border: 1px solid #cbcbcb;
    border-radius: 4px;  
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    :focus {
        outline-color: #4C9AFF;
    }

    :disabled {
        background-color: #f7f7f7; 
    }


    @media (max-width: 690px) {
        font-size: 17px;
    }

`;


const LabelStyled = styled.div`
    width: 100%;
    margin: 0px;
    padding: 0px;
    color: #333333;
    font-size: 16px;
    
    margin-bottom: 4px;
    margin-top: 0px;
    margin-left: 2px;
`;


const colourStyles = {

    control: (styles, {isDisabled, isFocused}) => ({ ...styles, 
        
        margin: 0,
        height: 42,
        paddingTop: 2,
        
        fontSize: 16,
        fontFamily: 'Fira Sans',
        fontWeight: '400',
        
        
        backgroundColor: isDisabled ? '#f7f7f7' : null,
        color: '#333333',
        
        borderRadius: 4,
        border: isFocused ? '2px solid #4C9AFF' : '1px solid #cbcbcb',
        boxShadow: '0 2px 8px 0 rgba(170, 170, 170, 0.2);',
        //boxShadow: isFocused ? '0 2px 8px 0 rgba(76, 154, 255, 0.8)' : '0 2px 8px 0 rgba(170, 170, 170, 0.2)',

        }),
    
    singleValue: base => ({
        ...base,
        paddingTop: 2,
        color: '#333333',
        marginLeft: 0,
        }),
    
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        
        return {
            ...styles,

            cursor: isDisabled ? 'not-allowed' : 'default',
        
            fontSize: 16,
            fontFamily: 'Fira Sans',
            fontWeight: '400',
            height: 42,

            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',


            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && ('#4C9AFF'),

            },


        };
    },
    menu: base => ({
        ...base,
        // color: '#333333',
        
        borderRadius: 4,
        border: '2px solid #4C9AFF',
        boxShadow: '0 2px 8px 0 rgba(170, 170, 170, 0.2);',

        marginLeft: '-1px',

        width: 'calc(100% + 2px);',


    }),

}

