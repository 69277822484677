
import React, { PureComponent } from 'react'
import { SignupView } from './SignupView'
import { SignupController } from '@psd/controller'

//import ReactGA from 'react-ga4';
//import { logEvent, setUserId } from '@psd/web/src/utils/analytics/amplitude';

export class SignupConnector extends PureComponent {
  
  state = {
    loadingVisible: false
  };

  onFinish = (userId) => {

    //#Question: decide - where next, after signed up and logged in?  will open the profile details, as other info is empty? Or will redirect to main explore page?
    //ReactGA.event({ category: "User", action: "Signup - Email", });
    //logEvent('signup with email')
    //setUserId(userId)

    // this.props.history.push("/");
    this.props.history.push("/user/settings");

  };

  updateLoader = ( staatus ) => {
    this.setState({ loadingVisible: staatus });
  }


  render() {

    return (

      <SignupController>
        {({ submit }) => (
          <SignupView 
            
            onFinish={this.onFinish} 
            submit={submit} 
            accessclient={this.props.client}
            state={this.state}
            updateLoader={this.updateLoader}              
            />
        )}
      </SignupController>


    );


  }

}
