import React, { PureComponent } from 'react'
import { withApollo } from "react-apollo";

import { RadioButtonGroup } from 'react-rainbow-components';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';


import styled from 'styled-components';



class C extends PureComponent { 


    handleChange = event => {

        this.setState({
            [event.target.value]: event.target.checked,
        });
    
        this.props.values[event.target.value] = event.target.checked ? true : false

    };


    render() {

        const unitOptions = [
            { value: '0', label: 'Metric', disabled: false },
            { value: '1', label: 'Imperial', disabled: false },
        ];

        

        return (

            <PageContent>


                <PageTitle>Details</PageTitle>


                <OneRowItems>
                    
                    <ColItem1>
                        
                        <LabelStyled>Number of rooms:</LabelStyled>
                        <InputStyled 
                            name='rooms'
                            autoCapitalize = 'none'
                            autoComplete = 'off'
                            type="number" 
                            min="0" 
                            step="1"
                            defaultValue={this.props.values.rooms}
                            onChange={ (e) => { this.props.setField('rooms', parseInt(e.target.value)); } }
                        />
                            
                        
                    </ColItem1>

            </OneRowItems>
            <IncreaseHeight32/>
               
                <OneRowItems>
                    
                    <ColItem1>

                        
                        { this.props.values.metric === 0 ?
                            <>
                                <LabelStyled>Ceiling height (cm):</LabelStyled>
                                <InputStyled 
                                    name='ceilingHeightCm'
                                    autoCapitalize = 'none'
                                    autoComplete = 'off'
                                    type="number" 
                                    min="0" 
                                    step="1"
                                    defaultValue={this.props.values.ceilingHeightCm}
                                    value={this.props.values.ceilingHeightCm}
                                    onChange={ (e) => {
                                        this.props.setField('ceilingHeight', parseInt(e.target.value/30.48));
                                        this.props.setField('ceilingHeightCm', parseInt(e.target.value)) 
                                        // console.log('entered:', e.target.value)
                                        // console.log('lbs ', this.props.values.weightLbs)
                                        // console.log('kg ', this.props.values.weightKg)
                                        }
                                    }
                                />
                            </>
                            :
                            <>
                                <LabelStyled>Ceiling height (ft):</LabelStyled>
                                <InputStyled 
                                    name='ceilingHeight'
                                    autoCapitalize = 'none'
                                    autoComplete = 'off'
                                    type="number" 
                                    min="0" 
                                    step="1"
                                    // oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    // oninput="validity.valid||(value='');"
                                    defaultValue={this.props.values.ceilingHeight}
                                    value={this.props.values.ceilingHeight}

                                    onChange={ (e) => {
                                        this.props.setField('ceilingHeight', parseInt(e.target.value));
                                        this.props.setField('ceilingHeightCm', parseInt(e.target.value*30.48)) 
                                        // console.log('entered:', e.target.value)
                                        // console.log('lbs ', this.props.values.weightLbs)
                                        // console.log('kg ', this.props.values.weightKg)
                                        }
                                    }
                                />
                            </>
                        }
                    </ColItem1>
                    <ColItem2>
                        <LabelStyled>&nbsp;</LabelStyled>
                        <RadioButtonGroup
                            id="metric"
                            options={unitOptions}
                            value={this.props.values.metric.toString()}
                            onChange={ (event) => {
                                const newModeValue = event.target.value;
                                // const distanceMilesToKm = (this.state.selectedPackageTravelDistance * 1.609)
                                if (newModeValue === '0') {

                                    //cm=>feet: divide the length value by 30,48


                                    this.props.setField('metric', parseInt(newModeValue)) 

                                    // this.props.setField('height', parseInt(this.props.values.height/100)) 
                                    // this.props.setField('heightMm', parseInt(this.props.values.height/100)) 

                                } else {

                                    // const heightInMm = this.props.values.height*100;
                                    // const tempArr = this.heightOptions();
                                    // let exactMM = tempArr[0].value; //default = min length. assuming we will find actual match below...
                                    // for (var i = tempArr.length-1; i >= 0; i--) {
                                    //     if (i < tempArr.length-1) {
                                    //         // console.log(heightInMm + ' ' + tempArr[i+1].value + ' ' + tempArr[i].value)
                                    //         if (heightInMm <= tempArr[i+1].value && heightInMm > tempArr[i].value) {
                                    //             exactMM = tempArr[i+1].value
                                    //         }
                                    //     }
                                    // }

                                    this.props.setField('metric', parseInt(newModeValue)) 

                                    // this.props.setField('height', parseInt(exactMM)) 
                                    // this.props.setField('heightMm', parseInt(exactMM)) 

                                }
                            }}
                        />
                        <IncreaseHeight32/>
                    </ColItem2>
                

                </OneRowItems>

                <OneRowItems>

                    <ColItem1>

                        
                        { this.props.values.metric === 0 ?
                            <>
                                <LabelStyled>Area (m2):</LabelStyled>
                                <InputStyled 
                                    name='areaSm'
                                    autoCapitalize = 'none'
                                    autoComplete = 'off'
                                    type="number" 
                                    min="0" 
                                    step="1"
                                    defaultValue={this.props.values.areaSm}
                                    value={this.props.values.areaSm}
                                    onChange={ (e) => {
                                        this.props.setField('area', parseInt(e.target.value*10.764));
                                        this.props.setField('areaSm', parseInt(e.target.value)) 
                                        // console.log('entered:', e.target.value)
                                        // console.log('lbs ', this.props.values.weightLbs)
                                        // console.log('kg ', this.props.values.weightKg)
                                        }
                                    }
                                />
                            </>
                            :
                            <>
                                <LabelStyled>Area (ft2)</LabelStyled>
                                <InputStyled 
                                    name='area'
                                    autoCapitalize = 'none'
                                    autoComplete = 'off'
                                    type="number" 
                                    min="0" 
                                    step="1"
                                    // oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    // oninput="validity.valid||(value='');"
                                    defaultValue={this.props.values.area}
                                    value={this.props.values.area}

                                    onChange={ (e) => {
                                        this.props.setField('area', parseInt(e.target.value));
                                        this.props.setField('areaSm', parseInt(e.target.value/10.764)) 
                                        // console.log('entered:', e.target.value)
                                        // console.log('lbs ', this.props.values.weightLbs)
                                        // console.log('kg ', this.props.values.weightKg)
                                        }
                                    }
                                />
                            </>
                        }
                    </ColItem1>

                </OneRowItems>


                <IncreaseHeight></IncreaseHeight>

                <OneRowItems>
                    <ChkContainer100>
                        <FormControlLabel 
                            control={
                                <OrangeCheckBox
                                    checked={this.props.values.bigObjects}
                                    onChange={this.handleChange}
                                    value="bigObjects"
                                />
                            }
                        label={'Suitable for big objects (cars, etc.)'}
                        />          
                    </ChkContainer100> 
                </OneRowItems>

                <OneRowItems>
                    <ChkContainer100>
                        <FormControlLabel 
                            control={
                                <OrangeCheckBox
                                    checked={this.props.values.catNude}
                                    onChange={this.handleChange}
                                    value="catNude"
                                />
                            }
                        label={'Nude photoshoots allowed'}
                        />          
                    </ChkContainer100> 
                </OneRowItems>


                <IncreaseHeight32/>


                <OneRowItems>
                    <ChkContainer100>
                        <FormControlLabel 
                            control={
                                <OrangeCheckBox
                                    checked={this.props.values.lightning}
                                    onChange={this.handleChange}
                                    value="lightning"
                                />
                            }
                        label={'Lightning equipment available'}
                        />          
                    </ChkContainer100> 
                </OneRowItems>

                <OneRowItems>
                    <ChkContainer100>
                        <FormControlLabel 
                            control={
                                <OrangeCheckBox
                                    checked={this.props.values.gear}
                                    onChange={this.handleChange}
                                    value="gear"
                                />
                            }
                        label={'Possibility to rent gear (cameras and/or lenses)'}
                        />          
                    </ChkContainer100> 
                </OneRowItems>


                <OneRowItems>
                    <ChkContainer100>
                        <FormControlLabel 
                            control={
                                <OrangeCheckBox
                                    checked={this.props.values.accessories}
                                    onChange={this.handleChange}
                                    value="accessories"
                                />
                            }
                        label={'Studio accessories available'}
                        />          
                    </ChkContainer100> 
                </OneRowItems>


                <IncreaseHeight></IncreaseHeight>




            </PageContent>

        )

    }
    
}




export const DetailsPage = (withApollo(C));


const IncreaseHeight = styled.div`
    
    height: 24px;  

`;

const IncreaseHeight32 = styled.div`
    
    height: 32px;  
    min-height: 32px;

`;

const PageContent = styled.div`
    
    width: 100%;
    height: 100%;
    min-height: 75vh;
    font-family: 'Fira Sans';

    display: flex;
    flex-direction: column;
    
`;

const PageTitle = styled.div`
    
    display: flex;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;

    display: none;
    @media (max-width: 790px) {
        display: block;
        }


`;



const OneRowItems = styled.div`
    width: 100%;
    min-width: 100%;
    

`;

const ColItem1 = styled.div`
    width: 40%;
    min-width: 40%;
    display: relative;
    float:left;
    margin-right: 32px;

    @media (max-width: 890px) {
        width: 100%;
        min-width: 100%;
    }

`;

const ColItem2 = styled.div`
    
    width: 40%;
    min-width: 40%;
    display: relative;
    float:left;

    @media (max-width: 890px) {
        width: 100%;
        min-width: 100%;
        margin-top: 12px;
    }

`;

const InputStyled = styled.input`
    
    width: calc(100% - 18px);
    padding: 0px;
    margin: 0px;
    height: 24px;
    padding: 8px;
    font-size: 16px;
    font-family: 'Fira Sans';
    font-weight: 400;

    color: #333333;
    
    border: 1px solid #cbcbcb;
    border-radius: 4px;  
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    :focus {
        outline-color: #4C9AFF;
    }

    :disabled {
        background-color: #f7f7f7; 
    }


    @media (max-width: 690px) {
        font-size: 17px;
    }

`;


const LabelStyled = styled.div`
    width: 100%;
    margin: 0px;
    padding: 0px;
    color: #333333;
    font-size: 16px;
    
    margin-bottom: 4px;
    margin-top: 0px;
    margin-left: 2px;
`;


const ChkContainer100 = styled.div`
    
  padding-left: 0px;
  padding-top: 2px;
  padding-bottom: 2px;
  width: 100%;
  
`;


const OrangeCheckBox = withStyles({
    root: {
      color: orange[400],
      '&$checked': {
        color: orange[600],
      },
    },
    checked: {},
    })(props => <Checkbox color="default" {...props} /> )
;

