
import React, { Component } from 'react';

import styled from 'styled-components';
import gql from "graphql-tag"
import { Query } from "react-apollo";

import moment from "moment"; 
import i18next from "i18next";
import '../../i18n'

const getMessageDetails = gql`
    query ( $messageId: String ) {
        getMessageDetails( messageId: $messageId ) { 
                
            messageId, myUserId,
            dt,

            fromName,
            fromUserId, fromModellId, fromMuahId, fromPhotogId, fromStudioId,
            fromIsPro,
            fromIsUnread, fromAvatar,

            toName,
            toUserId, toModellId, toMuahId, toPhotogId, toStudioId, 
            toIsPro,
            toIsUnread, toAvatar, 

            messages { dt, from, message, },

        }
    }
`;


export class MessageDetails extends Component {
    

    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {
      
        moment.locale(i18next.language);

        // console.log('this.props.messageId', this.props.messageId)

        return (
        
            <>

                <MessageRow>

                    <Query 
                        asyncMode 
                        query={getMessageDetails} 
                        variables={{ messageId: this.props.messageId }}
                        fetchPolicy="network-only"
                    >
                        {({ data, loading }) => {

                            // test here, why length is not working.because if backend is stopped (or server unreachable), front will give TypeError: Cannot read property 'length' of undefined

                            if ((data === undefined) || (data === null) || (loading === true)) {

                                return (
                                    <SubTitle>Loading... Please wait...</SubTitle> 
                                )    

                            } else if ((JSON.stringify(data).length > 35) && (loading === false)) {

                                    

                                    let otherPartyName = ''
                                    let threadSentByMe = false;

                                    if (data.getMessageDetails[0].fromUserId === data.getMessageDetails[0].myUserId) {
                                        threadSentByMe = true;
                                        otherPartyName = data.getMessageDetails[0].toName
                                    } else {
                                        threadSentByMe = false;
                                        otherPartyName = data.getMessageDetails[0].fromName
                                    }

                                    //console.log('threadSentByMe: ', threadSentByMe)
                                    //console.log('otherPartyName: ', otherPartyName)
                                    //console.log('-- data', data)
                                    //console.log('-- data.getMessageDetails[0].messages', data.getMessageDetails[0].messages)

                                    return (
                                        <>
                                            
                                            <MessagesResult>

                                                {
                                                    //data.getMessageDetails.map((message, index) => {
                                                    data.getMessageDetails[0].messages.map((message, index) => {
                                            
                                                           

                                                            let messageByCreator = false;
                                                            if (message.from === 0) {
                                                                messageByCreator = true;
                                                            } else {
                                                                messageByCreator = false;
                                                            }

                                                            let myMessage = false;
                                                            if ((threadSentByMe) && (messageByCreator)) {
                                                                myMessage = true;
                                                            } if ((threadSentByMe === false) && (messageByCreator === false)) {
                                                                myMessage = true;
                                                            }


                                                            let messageCreatorName = ''
                                                            if (myMessage) {
                                                                messageCreatorName = 'You'
                                                            } else {
                                                                messageCreatorName = otherPartyName
                                                            }


                                                            //console.log('messageByCreator: ', messageByCreator)
                                                            //console.log('messageCreatorName: ', messageCreatorName)

                                                            const messageDateISO = new Date(parseInt(message.dt))
                                                            const messageTimeAgo = moment(messageDateISO).fromNow()

                                                            //const exactDateTime = moment(message.messages[i].dt/1000*1000).format('LLL')

                                                            //console.log('------ message.message: ', message.message)
                                                            //console.log('------ bubbleLocation: ', bubbleLocation)
                                                            //console.log('------ index: ', index)

                                                            return (


                                                                myMessage === true ?

                                                                    <NewLine key={index}>
                                                                            
                                                                        <MessageHeaderRight>
                                                                            {messageCreatorName}, {messageTimeAgo}
                                                                        </MessageHeaderRight>
                                                                        
                                                                        <MessageRight>
                                                                            {message.message}
                                                                        </MessageRight>

                                                                    </NewLine>
                                                                        
                                                                    :
                                                                    
                                                                    <NewLine key={index}>

                                                                        <MessageHeaderLeft>
                                                                            {messageCreatorName}, {messageTimeAgo}
                                                                        </MessageHeaderLeft>

                                                                        <MessageLeft>
                                                                            {message.message}
                                                                        </MessageLeft>    

                                                                    </NewLine>

                                                                    
                                                            );                                                        
                

                                                    })

                                                }

                                            </MessagesResult>

                                        </>
                                    )

                            
                            } 
                                
                            else {

                                return (

                                    <SubTitle>No message details available.</SubTitle> 
                                    
                                )

                            } 

                        }}
                    </Query>    

                    
                </MessageRow> 

            </>
        
        );

    }


}


const MessageRow = styled.div`
    
    height: 100%;
    width: calc(100% - 32px);

    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 8px;
    padding-top: 8px;

    align-items: flex-start;
    
    /* background-color: #ebe; */

    display: flex;

    @media (max-width: 790px) {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;

    }

`;



const SubTitle = styled.div`
    
    font-size: 20px;
    font-weight: 400;
    padding: 16px 24px 16px 0px;


    font-family: 'Fira Sans';
    color: #333333;

`;



const MessagesResult = styled.div`

    

    /* background-color: #cebe; */
    /* border-top: 1px solid rgba(170, 170, 170, 0.3); */
    /* border-right: 1px solid rgba(170, 170, 170, 0.3); */

    width: 100%;
    
    overflow-x: hidden;
  
`;


const NewLine = styled.div`
    
    width: 100%;


`;


const MessageHeaderLeft = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 16px;

    color: #bdbdbd;

    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: flex-start;
    justify-content: flex-start;
    
`;

const MessageLeft = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 18px;

    color: #333333;

    width: 80%;
    margin-bottom: 16px;

    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;

    border-radius: 4px;
    background-color: #f2f2f2;

`;


const MessageHeaderRight = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 16px;

    color: #bdbdbd;

    width: 80%;

    float: right;

    padding-top: 8px;
    padding-left: 12px;
    padding-right: 12px;

`;

const MessageRight = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 18px;

    color: #333333;

    width: 80%;
    margin-bottom: 24px;
    /* margin-right: 8px; */

    float: right;

    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    /* padding-right: 12px; */

    border-radius: 4px;
    background-color: #e5e5e5;


`;
