import React, { PureComponent } from 'react'

import { Field } from "formik";
import styled from 'styled-components';

import { withApollo } from "react-apollo";
import Button from '@material-ui/core/Button';
//import ReactGA from 'react-ga4';
//import { Formik, Form } from "formik";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';

import { ASSET_SERVER } from '../../../../config';

import { MyInputField } from "../../../components/MyInputField";
import { MySelectField } from "../../../components/MySelectField";
import MyPlacesAutoComplete from "../../../components/MyPlacesAutoComplete";
import MyLanguageSelection from "../../../components/MyLanguageSelection";
import PremiumURLGeneration from './../PremiumURLGeneration'; 

import { AvatarImg } from "../../../components/AvatarImg";
import { UpgradeModal } from "../../../components/UpgradeModal";

// import i18next from "i18next";
import '../../../../i18n'

import { Mutation } from "react-apollo"
import gql from "graphql-tag"



const UPLOAD_FILE_STREAM = gql`
    mutation uploadProfileAvatar($file: Upload!, $profiletype: String, $profileId: String, $oldFile: String) {
        uploadProfileAvatar(file: $file, profiletype: $profiletype, profileId: $profileId, oldFile: $oldFile) {
            filename
            mimetype
            encoding
        }
    }
`;

const findURL = gql`
    query($URL: String!) {
        findURL(URL: $URL) {
            profileType,
            profileId,
            avatar,
        }
    }
`;


class C extends PureComponent { 

    state = {
        uploadState: "none",
        showPremiumURLGeneration: false,
        showUpgradeModal: false,
    };

    togglePremiumURLGeneration = () => {  

        if (this.props.values.isPro === true) {

            this.setState({  
                showPremiumURLGeneration: !this.state.showPremiumURLGeneration 
            });  

        } else {

            this.setState({ showUpgradeModal: true });

        }

    }  

    componentDidMount() {

        //ReactGA.pageview(window.location.pathname + window.location.search);

        this.setState({
            uploadState: "none",
        });
    
    }

    componentDidUpdate(prevProps, prevState) {

        //console.log(prevProps)
        //console.log(prevState)
        if (this.state.uploadState === "completed") {

            this.setState({
                uploadState: "finalized"
            });

            //console.log('didupdate : ', this.state.uploadState);

        }


    }


    onEditAvatar = () => {
            
        this.upload.click()

        this.setState({
            uploadState: "started",
        });

    
    };

    handleChange = event => {

        this.setState({
            [event.target.value]: event.target.checked,
        });
    
        this.props.values[event.target.value] = event.target.checked ? true : false

    };
    
    render() {

        let avatar = this.props.values.avatar;
        
        // console.log(this.props)
                
        const genders = [
            { value: '0', label: "Not specified" },
            { value: '1', label: "Male" },
            { value: '2', label: "Female" },
            { value: '3', label: "Other" },
        ];

        const experiences = [
            { value: '0', label: 'Beginner (less than 1 year)' },
            { value: '1', label: 'Hobbyist (1-2 years)' },
            { value: '2', label: 'Experienced, (3-5 years)' },
            { value: '3', label: 'Very experienced (5+ years)' },
        ];

        const statuses = [
            { value: '0', label: 'Inactive & hidden' },
            { value: '1', label: 'Visible to everyone' },
            { value: '2', label: 'Visible to registered users only' },
            { value: '3', label: 'Visible to registered Pro members only' },
            { value: '4', label: 'Visible to Verified Pro members only' },
        ];
        
        return (

            <PageContent>
            
                <PageTitle>About you</PageTitle>

                <AvatarContainer>


                    <AvatarImg
                        image={avatar ? avatar.replace('128', '256') : null} //for the bigger image element, load a bit bigger one from aws than the default 128. Most people are not coming here every day, but everyone will see the header. Thus when loading smaller one to header, we save a bit in traffic
                        size={128}
                        alt=" "
                        onLoad={(e) => {
                            //e.target.src = avatar.replace('128', '256') --- causes huge CPU spike. but looks like not necessary anyway!
                            e.target.style = 'display: flex' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                        }}
                        onError={(e) => {
                            //e.target.src = '' --- causes huge CPU spike. but looks like not necessary anyway!
                            e.target.style = 'display: none' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                        }}
                    />
                    
                    
                        <Mutation mutation={ UPLOAD_FILE_STREAM } >
                            {
                                (uploadProfileAvatar, { data, loading }) => {

                                    if (loading !== true && data && this.state.uploadState === "started") {
                                        
                                        //!NB! timeout is needed as otherwise there is a render conflict. But with timeout, the render completes, and only after that the new values are loaded
                                        setTimeout(async () => {
                                            
                                            // console.log('now finally submitted?')

                                            this.setState({ uploadState: "completed", });

                                            const avatarFilePath = ASSET_SERVER + '/profileavatar/256/' + this.props.values.id + '/' + this.upload.files[0].name

                                            this.props.setField('avatar', avatarFilePath)

                                            this.forceUpdate();

                                        }, 100 )


                                    }
                                    
                                    return (
                                        <>
                                            <input 
                                                name={'document'} 
                                                type={'file'} 
                                                accept={'image/*'}
                                                ref={(ref) => this.upload = ref}
                                                style={{display: "none"}}
                                                onChange={({target: { files }}) => {
                                                    const file = files[0]
                                                    file && uploadProfileAvatar({ variables: { file: file, profiletype: 'muah', profileId: this.props.values.id, oldFile: avatar } })
                                                    }
                                                }
                                            />
                                            <AvatarChangeButton>
                                                { 
                                                    loading ? 
                                                    <StyledEdit disabled>Loading...</StyledEdit>
                                                    : 
                                                    <StyledEdit onClick={this.onEditAvatar}>Select avatar</StyledEdit>
                                                }
                                            </AvatarChangeButton>
                                               
                                        </>
                                    )
                                }
                            }
                        </Mutation>
                    

                </AvatarContainer>


                <FieldContainer>

                    <Field
                        name="name"
                        label='Name, or the artist alias'
                        component={MyInputField}
                        
                        // defaultValue={name}
                        enabled={true} //once verified, set False
                    />

                </FieldContainer>
                
                <FieldContainer>


                    <Field
                        name="gender"
                        label='Gender'
                        component={MySelectField}

                        originalOptions={genders}
                        originalValue={this.props.values.gender}
                        onChange={ (e) => this.props.setField('gender', parseInt(e.value)) }
                        
                    />

                </FieldContainer>

                <FieldContainer>

                    <Field
                        name="experience"
                        label='Experience'
                        component={MySelectField}

                        originalOptions={experiences}
                        originalValue={this.props.values.experience}
                        onChange={ (e) => this.props.setField('experience', parseInt(e.value)) }
                            
                    />

                </FieldContainer>

                <FieldContainer>

                    <Field
                        name="address"
                        label='Your primary location (city & country)'
                        component={MyPlacesAutoComplete}

                        value={this.props.values.address}
                                
                    />


                </FieldContainer>


                <FieldContainer>

                    <Field
                        name="languages"
                        label='What languages do you speak, can read and write?'
                        component={MyLanguageSelection}

                        originalValue={this.props.values.languages}
                        applyLanguages={ (languages) => {
                            this.props.setField('languages', languages);
                            }
                        }
                            
                    />


                </FieldContainer>

                
                <FieldContainer>


                    <Field
                        name="publicURL"
                        label='Direct link to your profile page:'
                        disabled={true} //once verified, set False
                        value={'photosouls.com/' + this.props.values.publicURL}
                        component={MyInputField}
                        
                    />

                </FieldContainer>

                <FieldContainer>


                    <Field
                        name="premiumURL"
                        label='Premium direct link to your profile (available for Pro members)'
                        component={MyInputField}
                        disabled={true} //once verified, set False
                        value={this.props.values.premiumURL ? 'photosouls.com/' + this.props.values.premiumURL : 'photosouls.com/ ????'}
                        
                    />

                    <LinkButton onClick={this.togglePremiumURLGeneration} >
                        Click here to select your premium URL
                    </LinkButton>
                    {
                        this.state.showPremiumURLGeneration ?  
                            <PremiumURLGeneration
                                closePopup={this.togglePremiumURLGeneration}
                                applyURL={(URL) => {
                                    this.props.setField('premiumURL', URL);
                                    this.togglePremiumURLGeneration();
                                }}
                                checkURL={async (URL) => {
                                    
                                    let isAvailable = false;

                                    await this.props.client
                                        .query({ query: findURL, variables: { URL: '/' + URL }, fetchPolicy: 'no-cache',})
                                        .then(results => {
                                            if (JSON.stringify(results).length > 15) {
                                                if (results.data.findURL.profileId === '') {
                                                    isAvailable = true;
                                                    }
                                            }
                                        })
                                        .catch(error => { console.log(error) })

                                    if (isAvailable) {
                                        return "available" }
                                    else {
                                        return "notavailable"
                                    }

                                }}
                            />
                            : null  
                    }  
                    { this.state.showUpgradeModal ? 
                        <UpgradeModal
                            showUpgradeModal={this.state.showUpgradeModal}
                            closePopup={() => this.setState({ showUpgradeModal: false }) }
                            popTitle="Sorry, you cannot pick your own URL address for Hobby accounts"
                            popDescription="To have your own custom and easy to remember address for your PhotoSouls profile, please upgrade to the Pro account."
                        />
                    : null
                    }



                </FieldContainer>


                <FieldContainer>

                    <Field
                        name="status"
                        label='Profile visibility'
                        component={MySelectField}

                        originalOptions={statuses}
                        originalValue={this.props.values.status}
                        onChange={ (e) => this.props.setField('status', parseInt(e.value)) }
                            
                    />

                </FieldContainer>

                <FieldContainer>
                    <FormControlLabel 
                        control={
                            <OrangeCheckBox
                                checked={this.props.values.chkPromotions}
                                onChange={this.handleChange}
                                value="chkPromotions"
                            />
                        }
                    label={"I'm granting PhotoSouls the right to promote my talent profile on external platforms (such as Instagram, Pinterest, etc.), and to use images from my public albums for this purpose."}
                    />          
                </FieldContainer>           
                

            </PageContent>
                    
        )

    }

}


export const AboutPage = (withApollo(C));

const LinkButton = styled.div`
    
    color: #4C9AFF;

    margin: 0 8px 0 0; 

    padding-top: 4px;
    font-weight: 400;
    font-size: 16px;

    &:hover {
        text-decoration: underline;
        cursor: pointer
        }

`;


const PageContent = styled.div`
    
    width: 100%;
    font-family: 'Fira Sans';
    
`;

const PageTitle = styled.div`
    
    display: flex;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;

    display: none;
    @media (max-width: 790px) {
        display: block;
        }


`;


const StyledEdit = styled(Button)`
    && {
    height: 40px;
    top: 8px;
    color: #333333;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;


    @media (max-width: 790px) {
        border: 1px solid #efefef;
        box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};
    }

    
    }
    
`;

const AvatarContainer = styled.div`
        
    display: flex;
    
`;

const AvatarChangeButton = styled.div`
    
    margin-top: 36px;
    padding-left: 16px;

    color: #333333;
    align-items: center;  
    
`;


const FieldContainer = styled.div`
    
    margin: 32px auto 32px auto;
    
`;

const OrangeCheckBox = withStyles({
    root: {
      color: orange[400],
      '&$checked': {
        color: orange[600],
      },
    },
    checked: {},
    })(props => <Checkbox color="default" {...props} /> )
;


