import React from 'react';  


import moment from "moment"; 
import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import i18next from "i18next";
import './../../../../i18n'

import gql from "graphql-tag"

import { Divider } from "../../../components/Divider";
import { MyError } from "../../../components/MyError";
import '../../../../styles/ModalPopup.css';   


const updateMutation = gql`
    mutation($currentPwd: String!, $newPwd: String!) {
        changePwd(currentPwd: $currentPwd, newPwd: $newPwd)
    }
`;


class ConfirmPwd extends React.Component {  

    state = {
        currentPwd: "",
        newPwd: "",
        newPwd2: "",
        errorMsg: "",
        errorMsg2: "",
    };


    handlePwdChange = (event) => {
        this.setState({currentPwd: event.target.value})
    }

    handleNewPwdChange = (event) => {
        this.setState({newPwd: event.target.value})
    }

    handleNewPwdChange2 = (event) => {
        this.setState({newPwd2: event.target.value})
    }


    render() {  

        const mybordercolor = this.state.errorMsg ? "#ff4343" : "#cbcbcb";
        const mybordercolor2 = this.state.errorMsg2 ? "#ff4343" : "#cbcbcb";

        return (  
            
            <div className='react-confirm-alert-overlay'>
            
                <div className='react-confirm-alert-body'>
                        
                            <h1>Change your password</h1>

                            <Divider />                       
                            <LabelStyled>Enter your current password</LabelStyled> 
                            <InputStyled
                                theme={{ bordercolor: mybordercolor }}
                                type='password'
                                autoCapitalize = 'none'
                                onChange={this.handlePwdChange}
                                value={this.state.currentPwd}
                                name='currentPwd'
                            />


                            <Divider />
                            <LabelStyled>Enter your new password</LabelStyled> 
                            <InputStyled
                                theme={{ bordercolor: mybordercolor2 }}
                                type='password'
                                autoCapitalize = 'none'
                                onChange={this.handleNewPwdChange}
                                value={this.state.newPwd}
                                name='newPwd'
                            />

                            <Divider />
                            <LabelStyled>Confirm your new password</LabelStyled> 
                            <InputStyled
                                theme={{ bordercolor: mybordercolor2 }}
                                type='password'
                                autoCapitalize = 'none'
                                onChange={this.handleNewPwdChange2}
                                value={this.state.newPwd2}
                                name='newPwd2'
                            />


                            <Divider />
                            {
                                this.state.errorMsg ? (
                                    <>
                                        <MyError
                                            message = {this.state.errorMsg}
                                        />
                                        <Divider />
                                    </>
                                    )
                                : null
                                
                            }
                            {
                                this.state.errorMsg2 ? (
                                    <>
                                        <MyError
                                            message = {this.state.errorMsg2}
                                        />
                                        <Divider />
                                    </>
                                    )
                                : null
                                
                            }
                            
                            <ButtonArea>
                                <StyledSave 
                                    onClick={() => {

                                        if (this.state.newPwd !== this.state.newPwd2) {
                                            setTimeout(() => {
                                                this.setState({
                                                    errorMsg2: i18next.t('msg.errPasswordDoesntMatch'),
                                                });
                                            }, 50)

                                            return
                                        }


                                        this.props.apolloClient
                                            .mutate({
                                                mutation: updateMutation,
                                                variables: {
                                                    currentPwd: this.state.currentPwd,
                                                    newPwd: this.state.newPwd,
                                                },
                                            })
                                            .then(apiResponse => {

                                                // console.log('apiResponse: ', apiResponse)

                                                if (apiResponse.data.changePwd === null) {

                                                    
                                                    if (apiResponse.errors[0].data[0].message.data[0].message.length > 5) {     

                                                        setTimeout(() => {
                                                            this.setState({
                                                                errorMsg2: apiResponse.errors[0].data[0].message.data[0].message
                                                            });
                                                        }, 50)

                                                    } 


                                                } else if (apiResponse.data.changePwd === true) {     

                                                    const today = new Date();
                                                    this.props.propStore.set('pwdUpdated', moment(today).format('YYYY-MM-DD'))
                                                    this.props.closePopup();

                                                } else {

                                                    setTimeout(() => {
                                                        this.setState({
                                                            errorMsg2: i18next.t('msg.errProcessingReq'),
                                                        });
                                                    }, 50)

                                                }
                                


                                            })
                                            .catch(error => {
                                                
                                                console.log("Error: ", error);
                                                
                                                setTimeout(() => {
                                                    this.setState({
                                                        errorMsg2: error,
                                                    });
                                                }, 50)

                                            })
                                        
                                    }}

                                    >
                                    Change Now
                                </StyledSave>
                                
                                
                                <StyledCancel
                                    onClick={() => {
                                        this.props.closePopup();
                                    }}
                                    >
                                    Cancel
                                </StyledCancel>

                            </ButtonArea>
                            

                        </div>

                    

            </div>
        );  
    }  
    
}  

export default ConfirmPwd;




const ButtonArea = styled.div`

    display: flex;

    padding-bottom: 8px;
    padding-left: 8px;

    color: #333333;
    align-items: flex-end;

`;


const LabelStyled = styled.div`
  width: 100%;
  margin: 0px;
  padding: 0px;
  color: #333333;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 4px;
  margin-top: 0px;
`;

const InputStyled = styled.input`
    width: calc(100% - 18px);
    padding: 0px;
    margin: 0px;
    height: 24px;
    padding: 8px;
    font-size: 16px;
    font-family: 'Fira Sans';
    
    border: 1px solid ${props => props.theme.bordercolor};
    border-radius: 4px;  
    color: #333333;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);
    :disabled {
        background-color: #f7f7f7; 
    }
    :focus {
        outline-color: #4C9AFF;
    }
    @media (max-width: 400px) {
        font-family: 'Fira Sans';
        font-size: 17px;
    }
`;




const StyledSave = styled(Button)`
    && {
    height: 40px;
    top: 18px;
    
    background: #ff9500;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.4);
    color: white;
    width: 50%;
    margin-left: -8px;
    
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
        &:hover {
        background: #ff9500;
        opacity: 0.9;
        color: white;
        }
    }
    
`;

const StyledCancel = styled(Button)`
    && {
    height: 40px;
    top: 18px;
    margin-left: 8px;
    color: #333333;
    width: 50%;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    }
    
`;

