import React, { PureComponent } from 'react'

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

import "./index.css";
import { FooterSection } from "./_footerSection";

import styled from 'styled-components';


export default class Contact extends PureComponent {

    componentDidMount() {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }

    render() {

        return (
            
            <>

                <Helmet>
                    <title>Contact PhotoSouls</title>
                    <meta name="description" content="Reach out to PhotoSouls for inquiries, support, or collaboration." />
                    <meta name="keywords" content="photosouls, support, contact" />
                </Helmet>


               
                <Section8>

                    <RowCenter>
                        <H1>Contact Us</H1>

                        {/* <H2Desc>Please fill in the form below, or send us an email to support@photosouls.com</H2Desc> */}
                        <H2Desc>We haven't yet set up the ticketing system as PhotoSouls is not yet publicly launched. Please send all your questions, suggestions and requests directly to the email support@photosouls.com</H2Desc>



                    </RowCenter>

                </Section8>



                {/* Footer */}
                <FooterSection/>
                    
            </>

        )
    }
}


const Section8 = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;

    background-color: #fff;

    position: relative;
    z-index: 1;
    min-height: 400px;

    @media (max-width: 980px) {
        
        min-height: 600px;

    }

`;


const RowCenter = styled.div`
    
    width: 1170px;

    margin: 0 auto;
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    text-align: center;
    justify-content: center;
    align-items: center;

    padding-top: 24px;
    padding-bottom: 32px;
    padding-left: 24px;
    padding-right: 24px;

    @media (max-width: 980px) {
        
        /* margin-top: 0px; */

    }

`;

const H1 = styled.h1`

    color: #ff9500;
    font-family: 'Fira Sans';
    font-size: 36px;
`;

const H2Desc = styled.div`
    
    width: 100%;

    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    padding-top: 0px;
    padding-bottom: 32px;

    text-align: center;
    justify-content: center;
    align-items: flex-start;

    /* background-color: #ded; */

    font-size: 22px;
    font-family: 'Fira Sans';
    font-weight: 400;


    @media (max-width: 980px) {
        
        /* margin-top: 0px; */

    }

`;

