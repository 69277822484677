import i18next from "i18next";
import moment from "moment"; 
import '../i18n';


function DateRange(startDate, endDate, today) {

    
    i18next.changeLanguage(i18next.language)
    moment.locale(i18next.language);
    
    // console.log('-- Daterange locale: ', moment.locale());
    // console.log('-- Daterange format: ', moment(startDate).format('MMMM'))
    // console.log('-- Daterange format: ', moment(endDate).format('MMMM'))
    
    let dateString = ''

    
    if (moment(startDate).format('YYYY-MM-DD') === moment(endDate).format('YYYY-MM-DD') || endDate === null) {

        //in one day (start date = end date) 
        if ( moment(startDate).format('YYYY') === moment(today).format('YYYY') ) {
            dateString = moment(startDate).format('MMM D');    
        } else {
            dateString = moment(startDate).format('MMM D, YYYY');
        }

    } else {

        if (moment(startDate).format('MMMM') === moment(endDate).format('MMMM')) {

            //within same month
            dateString = moment(startDate).format('MMM D') + ' - ' + moment(endDate).format('D');
            

        } else {

            if (moment(startDate).format('YYYY') === moment(endDate).format('YYYY')) {
                
                //different months (but same year)
                if ( moment(startDate).format('YYYY') === moment(today).format('YYYY') ) {
                    //within same year, this year
                    dateString = moment(startDate).format('MMM D') + ' - ' + moment(endDate).format('MMM D');
                } else {
                    //within same year but other than current
                    dateString = moment(startDate).format('MMM D') + ' - ' + moment(endDate).format('MMM D, YYYY');
                }

            } else {

                //different years
                dateString = moment(startDate).format('MMM D, YYYY') + ' - ' + moment(endDate).format('MMM D, YYYY');

            }

        }

    }

    return dateString
    
}


export default DateRange;


