import React, { PureComponent } from 'react'

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

import "./index.css";
import { FooterSection } from "./_footerSection";
import { UpgradeComparisonModal } from "../components/UpgradeComparisonModal";
import { Link } from "react-router-dom";

import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export default class ListYourStudio extends PureComponent {

    constructor(props) {
        
        super(props);
        
        this.state = {
            selectedYearly: true,
            showUpgradeModal: false,
            showUpgradeComparisonModal: false,
            hideCTA: false,
        };
    
    }

    componentDidMount() {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        // ReactGA.send("pageview");
    }

    render() {

     

        
        return (
            
            

            <>

                <Helmet>
                    <title>List Your Studio | Connect with Photographers on PhotoSouls</title>
                    <meta name="description" content="Offer your studio a spotlight on PhotoSouls. List it and open your doors to a thriving network of professional photographers looking for the perfect space." />
                    <meta name="keywords" content="studio booking, photography platform, studio listing, photoshoot, photographers, models, makeup artists, stylists, PhotoSouls, studio revenue, photography community" />
                </Helmet>

                {/* Elevate Your Studio’s Potential with PhotoSouls! */}
                <Section1img>

                    <RowCenter>
                        
                        <CenterTextBlock>

                            <H1>Elevate Your Studio’s Potential with PhotoSouls!</H1>

                            <H1DescBigger>Are you a studio owner aiming to maximize your bookings and gain greater visibility in the photography community? </H1DescBigger>
                            
                            <H1DescSmaller>Your journey to success begins here with PhotoSouls, a vibrant platform that connects your studio with photographers, models, makeup artists, and stylists.</H1DescSmaller>

                            <BtnPrimary onClick={ () => this.props.history.push({ pathname: 'signup/' }) }>CLICK HERE TO JOIN</BtnPrimary>

                        </CenterTextBlock>

                    </RowCenter>

                </Section1img>

              
                {/* Get More Exposure and Bookings */}
                <Section1>

                    <Row>
                        
                        <Left>

                            <H1g>Get More Exposure and Bookings</H1g>
                            <H1Desc>Your studio is more than a space; it's the canvas where artists create.</H1Desc>
                            <H1Desc>Listing on PhotoSouls provides your studio with visibility to a wide range of potential clients, increasing your chances of scoring more bookings.</H1Desc>
                            
                        </Left>

                        <RightPhoto>
                            <S1img src={'images/web/model1.png'} alt="Photographers with cameras" />
                        </RightPhoto>

                    </Row>

                </Section1>


                {/* Showcase Your Studio to a Wider Audience */}
                <Section1>

                    <Row>
                        
                        <Left2>
                            <S1img src={'images/web/photog2.png'} alt="PhotoSouls on the laptop screen" />
                        </Left2>

                        <Right2>
                            <H1g>Showcase Your Studio to a Wider Audience</H1g>
                            <H1Desc>PhotoSouls enables you to display your studio in all its glory to a broad audience. Our platform acts as a spotlight, highlighting the unique features of your studio that artists seek.</H1Desc>
                            <H1Desc>Your profile will serve as a virtual tour for potential clients, giving them a peek into what you offer.</H1Desc>
                            
                        </Right2>

                    </Row>

                </Section1>


                {/* Manage Bookings with Ease */}
                <Section1>

                    <Row>
                        
                        <Left>

                            <H1g>Manage Bookings with Ease</H1g>
                            <H1Desc>Efficiency is key to a successful business. Our user-friendly interface allows you to manage your bookings effortlessly, keeping track of available slots and upcoming photoshoots. </H1Desc>
                            <H1Desc>You can focus on maintaining your studio’s ambiance while we handle the nitty-gritty of scheduling.</H1Desc>
                            
                        </Left>

                        <RightToBottom>
                            <S1img src={'images/web/photog6.png'} alt="Studio owner managing bookings on the phone" />
                        </RightToBottom>

                    </Row>

                </Section1>

                {/* Boost Your Revenue */}
                <SectionBlack>

                    <Row>
                        
                        <Left2>
                            <S1img src={'images/web/studio1.png'} alt="Studio owner standing" />
                        </Left2>

                        <Right2>
                            <H1gWhite>Boost Your Revenue</H1gWhite>
                            <H1DescWhite>Increase your studio's earning potential by tapping into our vast network of professional photographers, models, and artists.</H1DescWhite>
                            <H1DescWhite>With PhotoSouls, your studio is open to a wider market, leading to higher occupancy and improved revenue.</H1DescWhite>
                            
                        </Right2>

                    </Row>

                </SectionBlack>

                {/* Join a Thriving Community */}
                <Section1>

                    <Row>
                        
                        <Left>

                            <H1g>Join a Thriving Community</H1g>
                            <H1Desc>By listing your studio with PhotoSouls, you become part of an exciting and supportive community of industry professionals. </H1Desc>
                            <H1Desc>Connect, learn, and grow with like-minded individuals, enhancing your experience and success in the industry.</H1Desc>
                            
                        </Left>

                        <Right>
                            <S1img src={'images/web/photog8.png'} alt="Community" />
                        </Right>

                    </Row>

                </Section1>

                {/* Safe and Secure Transactions */}
                <Section1>

                    <Row>
                        
                        <Left2>
                            <S1img src={'images/web/photog3.png'} alt="Credit card payments" />
                        </Left2>

                        <Right2>
                            <H1g>Safe and Secure Transactions</H1g>
                            <H1Desc>Your peace of mind is our priority.</H1Desc>
                            <H1Desc>Our secure payment system ensures that transactions are handled safely and that you receive prompt and accurate payments for every booking.</H1Desc>
                            
                        </Right2>

                    </Row>

                </Section1>


                {/* How much does it cost? */}
                <Section1>

                    <Row>
                        
                        <Left>

                            <H1g>How much does it cost?</H1g>
                            {/* <H1Desc>PhotoSouls offers a free Hobby membership plan that gives you access to everything on our platform without any charges. It's completely free of cost!</H1Desc> */}
                            {/* <H1Desc>When you receive a booking and get paid, we only deduct a 20% commission fee. This fee is applicable only when you make a successful booking.</H1Desc> */}
                            {/* <H1Desc>As a Pro member, you enjoy additional benefits, including a reduced commission fee of 15%. To explore the other advantages of Pro membership, <StyledLink onClick={ () => { this.setState({ showUpgradeComparisonModal: true, hideCTA: false }) }}>click here.</StyledLink></H1Desc> */}
                            <H1Desc>At PhotoSouls, our Hobby membership plan is available at no charge, providing you with access to many of our platform's features. We only take a 20% commission when you get a booking and receive payment.</H1Desc>
                            <H1Desc>For a broader range of features and benefits, consider upgrading to our Pro membership. It also includes a reduced commission fee of just 15%. Learn more about the advantages of the Pro membership by <StyledLink onClick={ () => { this.setState({ showUpgradeComparisonModal: true, hideCTA: false }) }}>clicking here.</StyledLink></H1Desc>

                        </Left>

                        <Right>
                            <S1img src={'images/web/photog9.png'} alt="Prices" />
                        </Right>

                    </Row>

                </Section1>


                <Section1>

                    <RowCenter>
                        
                        <CenterTextBlock>

                            <H1>So, what are you waiting for?</H1>

                            <H1Desc>Join PhotoSouls today and let your studio be the stage where creative dreams come to life. Boost your bookings, elevate your visibility, and take your studio business to new heights!</H1Desc>

                            <BtnPrimary onClick={ () => this.props.history.push({ pathname: 'signup/' }) }>Join Now!</BtnPrimary>

                        </CenterTextBlock>

                    </RowCenter>

                </Section1>

                <FooterSection/>

                { this.state.showUpgradeComparisonModal ? 
                    <UpgradeComparisonModal
                        showUpgradeComparisonModal={this.state.showUpgradeComparisonModal}
                        hideCTA={this.state.hideCTA}
                        closePopup={() => this.setState({ showUpgradeComparisonModal: false, hideCTA: false }) }
                    />
                : null
                }

            </>

        )
    }
}



const Left2 = styled.div`
    
    width: 45%;
    padding-top: 32px;
    padding-bottom: 32px;

    /* background-color: #aea; */

    @media (max-width: 980px) {
        width: 100%;
        padding-bottom: 0px;
        
    }
    

`;

const Right2 = styled.div`
    
    width: 45%;
    padding-top: 32px;
    padding-bottom: 64px;


    /* background-color: #ced; */

    @media (max-width: 980px) {
        
        width: 100%;
        padding-left: 0px;
        padding-top: 0px;

    }
    

`;




const Row = styled.div`
    
    width: 1170px;

    margin: 0 auto;
    
    padding-left: 48px;
    padding-right: 24px;
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    justify-content: center;
    align-items: center;
        
    /* background-color: #f3f; */

    @media (max-width: 980px) {
        
        /* margin-top: 0px; */
        /* text-align: center;
        justify-content: center;
        align-items: center; */

    }

`;



const Section1 = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    background-color: #f3f2f0;
    background-image: linear-gradient(#fff,#f3f2f0);

    position: relative;
    z-index: 1;
    min-height: 450px;


    @media (max-width: 980px) {
        
        min-height: 450px;

    }


`;

const SectionBlack = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    background-color: #000;

    position: relative;
    z-index: 1;
    min-height: 450px;


    @media (max-width: 980px) {
        
        min-height: 450px;

    }


`;


const Section1img = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    background-color: #f3f2f0;
    background-image: url('images/web/photog0.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    position: relative;
    z-index: 1;
    min-height: 450px;


    @media (max-width: 980px) {
        
        min-height: 450px;

    }


`;

const Left = styled.div`
    
    width: 45%;

    padding-bottom: 40px;
    padding-right: 24px;
    padding-top: 64px;
    padding-left: 32px;

    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 32px;
        padding-left: 0px;

    }
    

`;


const CenterTextBlock = styled.div`
    
    width: 75%;

    padding-bottom: 40px;
    padding-right: 24px;

    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 32px;

    }
    

`;


const RowCenter = styled.div`
    
    max-width: 1170px;

    margin: 0 auto;
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    text-align: center;
    justify-content: center;
    align-items: center;

    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;

    padding-top: 48px;

    @media (max-width: 980px) {
        
        /* margin-top: 0px; */

    }

`;


const H1g = styled.h1`

    margin-top: 20px; 

    color: #333333;
    font-family: 'Fira Sans';
    font-size: 42px;
    

    @media (max-width: 970px) {

        font-size: 32px;

    }

`;

const H1gWhite = styled.h1`

    margin-top: 20px; 

    color: #fff;
    font-family: 'Fira Sans';
    font-size: 42px;
    

    @media (max-width: 970px) {

        font-size: 32px;

    }

`;

const H1 = styled.h1`

    margin-top: 20px; 

    color: #ff9500;

    font-family: 'Fira Sans';
    font-size: 48px;
    

    @media (max-width: 970px) {

        font-size: 38px;

    }

`;


const H1Desc = styled.div`
    
    margin-top: 20px; 
    margin-bottom: 20px; 

    color: #333333;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 26px;
    
    @media (max-width: 970px) {

        font-size: 24px;

    }

`;

const H1DescBigger = styled.div`
    
    margin-top: 20px; 
    margin-bottom: 20px; 

    color: #333333;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 32px;
    
    @media (max-width: 970px) {

        font-size: 26px;

    }

`;


const H1DescWhite = styled.div`
    
    margin-top: 20px; 
    margin-bottom: 20px; 

    color: #fff;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 26px;
    
    @media (max-width: 970px) {

        font-size: 24px;

    }

`;



const H1DescSmaller = styled.div`
    
    margin-top: 32px; 
    margin-bottom: 20px; 

    color: #333333;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 28px;
    
    @media (max-width: 970px) {

        font-size: 24px;

    }

`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #3e6ca0;
    text-decoration: underline
    &:hover {
        color: #3e6ca0;
        text-decoration: underline
    }
`;




const BtnPrimary = styled(Button)`
    && {
    min-height: 64px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    
    color: white;
    
    padding-left: 32px;
    padding-right: 32px;
    margin-right: 32px;
    margin-top: 32px;

    text-transform: none;
    font-weight: 400;
    font-size: 22px;
    
    &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }

}     
`;



const S1img = styled.img`

    width: 100%;
    height: 100%;

    object-fit: cover;

    margin-left: auto;
    margin-right: auto;


`;


const Right = styled.div`
    
    width: 45%;
    padding-left: 48px;
    padding-bottom: 32px;

    /* background-color: #ced; */
    text-align: center;
    justify-content: center;
    align-items: center;


    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 0px;

    }
`;

const RightToBottom = styled.div`
    
    width: 45%;
    padding-left: 48px;
    margin-bottom: -64px;

    /* background-color: #ced; */
    text-align: center;
    justify-content: center;
    align-items: center;


    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 0px;
        margin-bottom: -4px;

    }
`;


const RightPhoto = styled.div`
    
    width: 45%;

    padding-left: 32px;
    /* padding-bottom: 32px; */
    padding-top: 64px;

    /* background-color: #f3f2f0;
    background-image: url('images/web/_1test1.jpg');
    background-repeat: no-repeat;
    background-size: cover; */

    @media (max-width: 980px) {
        
        width: 100%;
        padding-left: 0px;
        padding-top: 0px;
    }
`;

