import axios from 'axios';

// IPDATA IS FIRST
// ABSTRACT IS SECOND (fallback)

// ABSTRACT tundub aeglane. IPDATA on palju kiirem. Seega default ipdata. 
// kui läheb laivi, siis paneb ipdata whitelisti kasutusse, ja pole ohtu ka et keegi exploidib api keyd



let ipInfo = {}
let fallback = {
    'ip': '157.131.97.172',
    'city': 'San Francisco',
    'region': 'California',
    'country': 'United States',
    'country_code': 'US',
    'currency': 'USD',
    'currency_symbol': '$',
    'lat': '37.7833',
    'lng': '-122.4952',    
    'src': 'ERROR'
};

async function ipdata() {

    await axios.get('https://api.ipdata.co?api-key=f3521e5dc6c5a94f95b211b4ec7769855616662e2ef2874d4af86b80')
    .then(res => {
        
        // console.log('--- ipdata res.data: ', res.data)

        ipInfo = {
            'ip': res.data.ip,
            'city': res.data.city,
            'region': res.data.region,
            'country': res.data.country_name,
            'country_code': res.data.country_code,
            'currency': res.data.currency.code,
            'currency_symbol': res.data.currency.symbol,
            'lng': res.data.longitude,
            'lat': res.data.latitude,
            'src': 'ipdata'
        };

    }).catch(err => {
        
        // console.log('ipdata erorr. Do failover using abstract', err)
        
        return 'ERROR'
        
    })

    // console.log('ipInfo: ', ipInfo)
    return ipInfo

}

async function abstractapi() {

    let ipInfo = {}
    await axios.get('https://ipgeolocation.abstractapi.com/v1/?api_key=20ce8867840c47f0b15194f279b74850')
    .then(res => {
        
        // console.log('--- abstract res.data: ', res.data)
        
        ipInfo = {
            'ip': res.data.ip_address,
            'city': res.data.city,
            'region': res.data.region,
            'country': res.data.country,
            'country_code': res.data.country_code,
            'currency': res.data.currency.currency_code,
            'currency_symbol': res.data.currency.currency_code, //NO SYMBOL in ABSTRACTAPI
            'lng': res.data.longitude,
            'lat': res.data.latitude,
            'src': 'abstract'
        };
    }).catch(err => {
        //console.log('abstract geolocation error. ')
        return 'ERROR'
    })

    //console.log('ipInfo: ', ipInfo)
    return ipInfo


}

async function getIPInfo() {
  
    
    let resultInfo = {}
    let resultInfo2 = {}


    resultInfo = await ipdata()
    
    // console.log('---------------------ipdata: ', resultInfo)
    
    if (resultInfo === 'ERROR' || !resultInfo.city ) {

        // console.log('failed')
        resultInfo2 = await abstractapi()

        if (resultInfo2 === 'ERROR') {
            return fallback
        } else {
            return resultInfo2
        }

    } else {
        return resultInfo
    }



    // below is good when "rotating" service providers and making sure that if one of them fails, the second one will be used.. 
    // but as it turns out, abstract is slow (at least in free version)
    // so let's prefer ipdata service. and when that fails, ask abstract.

    // const rndProvider = Math.floor(Math.random() * 10) + 1;
    // if (rndProvider > 5) {

    //     //default to Provider 1, fallback to Provider 2

    //     resultInfo = await ipdata()
    //     if (resultInfo === 'ERROR') {
            
    //         resultInfo2 = await abstractapi()
    //         if (resultInfo2 === 'ERROR') {
    //             return fallback
    //         } else {
    //             return resultInfo2
    //         }

    //     } else {

    //         return resultInfo

    //     }


    // } else {

    //     //default to Provider 2, fallback to Provider 1

    //     resultInfo = await abstractapi()
    //     if (resultInfo === 'ERROR') {
            
    //         resultInfo2 = await ipdata()
    //         if (resultInfo2 === 'ERROR') {
    //             return fallback
    //         } else {
    //             return resultInfo2
    //         }

    //     } else {

    //         return resultInfo

    //     }

    // }


    
}


export default getIPInfo;


