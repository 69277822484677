"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  }, _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validUserSchema = exports.validPasswordField = exports.validEmailField = exports.registerPwdValidation = exports.loginSchema = exports.changePasswordSchema = void 0;

var yup = _interopRequireWildcard(require("yup"));

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
} // import { msg_pwdNotLongEnough, msg_fieldTooLong, msg_fieldTooShort, msg_invalidEmail, msg_fieldRequired } from './../lng/messages';


var registerPwdValidation = yup.string().min(6, "Password must be at least 6 characters long").max(200, "Field value too long").required("This field is required");
exports.registerPwdValidation = registerPwdValidation;
var validUserSchema = yup.object().shape({
  email: yup.string().min(3, "Field value too short").max(200, "Field value too long").email("Invalid e-mail address").required("This field is required"),
  pwd: registerPwdValidation
});
exports.validUserSchema = validUserSchema;
var loginSchema = yup.object().shape({
  email: yup.string().min(3, "Field value too short").max(255, "Field value too long").email("Invalid e-mail address").required("This field is required"),
  pwd: registerPwdValidation
});
exports.loginSchema = loginSchema;
var validEmailField = yup.object().shape({
  email: yup.string().min(3).max(200, "Field value too long").email("Invalid e-mail address").required("This field is required")
});
exports.validEmailField = validEmailField;
var validPasswordField = yup.object().shape({
  pwd: registerPwdValidation
});
exports.validPasswordField = validPasswordField;
var changePasswordSchema = yup.object().shape({
  newPassword: registerPwdValidation
});
exports.changePasswordSchema = changePasswordSchema;