
import React, { PureComponent } from 'react'
import styled from 'styled-components';

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

import gql from "graphql-tag"

import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import { faPlus } from '@fortawesome/free-solid-svg-icons'
// import i18next from "i18next";
import Footer from '../../../modules/components/Footer/Footer'
import { UpgradeModal } from "../../components/UpgradeModal";
import { UpgradeComparisonModal } from "../../components/UpgradeComparisonModal";


import { withUserProfiles } from "@psd/controller";
import { withStore } from '@spyna/react-store'
import { withApollo } from "react-apollo";
import { withRouter } from 'react-router-dom';

import { ProfileAddButton } from "../../components/settings/ProfileAddButton";
import { ProfileButton } from "../../components/settings/ProfileButton";
import { EventCard } from "../../components/settings/EventCard";

import ProfileSelectionMenu from './ProfileSelectionMenu'; 

import "./profiles.css";
import '../../../i18n'

import toast from 'toasted-notes' 
import 'toasted-notes/src/styles.css';

const generatepublicURL = gql`
    query($type: String!) {
        generatepublicURL(type: $type)
    }
`;
const portalSession = gql`
    query($id: String) {
        portalSession(id: $id)
    }
`;

const createPhotogMutation = gql`
    mutation(
        $input: PhotogInput!
    ) {
        createPhotog(
            input: $input
        ) { id }
    }
`;

const createModellMutation = gql`
    mutation(
        $input: ModellInput!
    ) {
        createModell(
            input: $input
        ) { id }
    }
`;

const createMuahMutation = gql`
    mutation(
        $input: MuahInput!
    ) {
        createMuah(
            input: $input
        ) { id }
    }
`;

const createStudioMutation = gql`
    mutation(
        $input: StudioInput!
    ) {
        createStudio(
            input: $input
        ) { id }
    }
`;

const createEventMutation = gql`
    mutation(
        $input: EventInput!
    ) {
        createEvent(
            input: $input
        ) { id }
    }
`;




class C extends PureComponent {

    state = {
        meResult: '',
        showProfileSelectionMenu: false,
        showUpgradeModal: false,
        showUpgradeComparisonModal: false,
        showStripeExpressLong: false,
        makePayoutsAvailable: true,
        hideCTA: false,

    };

    
    async componentDidMount() {
    
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        
        this.props.data.refetch();

    }

    toggleProfileSelectionMenu = () => {  

        this.setState({  
            showProfileSelectionMenu: !this.state.showProfileSelectionMenu 
        });  

    }  

    editProfile = (type, id) => {  

        if (type === 'photog') {
        
            this.props.history.push({ pathname: '../photographer/' + id + '/edit', state: { profileType: "photog" } })

        } else if (type === 'modell') {

            this.props.history.push({ pathname: '../model/' + id + '/edit', state: { profileType: "modell" } })

        } else if (type === 'muah') {

            this.props.history.push({ pathname: '../muah/' + id + '/edit', state: { profileType: "muah" } })

        } else if (type === 'studio') {

            this.props.history.push({ pathname: '../studio/' + id + '/edit', state: { profileType: "studio" } })

        } else if (type === 'event') {

            this.props.history.push({ pathname: '../event/' + id + '/edit', state: { profileType: "event" } })

        }

    }  
    
    createNewProfile = async (type) => {  

        this.setState({  
            showProfileSelectionMenu: false 
        });  

        if (type === 'photog') {

            let createdProfileId = ''
            let publicURL = '0000-0000'
            let premiumURL = ''
            let secondStageOK = false;
            let resultOK = false

            //console.log(this.props)
            await this.props.client
                .query({ query: generatepublicURL, variables: { type: 'photog', }, })
                .then(results => {
                    
                    if (JSON.stringify(results).length > 15) {

                        publicURL = results.data.generatepublicURL;
                        secondStageOK = true;

                    } else {
                        toast.notify(
                            <div style={{ "color": "red" }}>Error #765. Unable to create a profile. Please send us the error number, so we could fix it.</div>, 
                            { duration: 10000 }
                            );
                    }
                    
                })
                .catch(error => {
                    
                    toast.notify(
                        <div style={{ "color": "red" }}>Error #764. Unable to create a profile. Please send us the error number, so we could fix it.</div>, 
                        { duration: 10000 }
                        );
                    
                    // console.log("Error: ", error);
                })

            if (secondStageOK) {
                await this.props.client
                    .mutate({
                        mutation: createPhotogMutation,
                        variables: {
                            input: {
                                status: 0,
                                name: this.props.store.get("fName") + ' ' + this.props.store.get("lName"),
                                gender: 0,
                                experience: 0,
                                address: "", addr_locality: "", addr_area2: "", addr_area1: "", country: "", location: { type: "Point", coordinates: [ 0, 0 ] },

                                avatar: this.props.store.get("avatar"),
                                languages: ['en'],
                                publicURL: publicURL, //in the form of ####-### //(1 is type) 187-665-477 
                                premiumURL: premiumURL, 
                                catBoudoir: false, catCouples: false, catFamily: false, catFashion: false, catKids: false, catLifestyle: false,
                                catNewborn: false, catPregnancy: false, catPortrait: false, catWedding: false, 
                                catNude: false, catActing: false, catArt: false, catBodypaint: false, catCosplay: false, catDance: false,
                                catEditorial: false, catFetish: false, catFitness: false, catLingerie: false, catPromotional: false,
                                catRunway: false, catSwimwear: false, catTopless: false, catUnderwater: false,     
                                catLandscape: false, catNature: false, catPets: false, catWildlife: false, catArchitecture: false, catEvent: false,
                                catFood: false, catProduct: false, catRealestate: false, catSport: false, catStreet: false,
                                travel: 0,
                                

                                isPro: false, // coming from User model, duplicated to this model
                                isVerified: false, //coming from User model, duplicated to this model
                            }
                        },
                    })
                    .then(results => {
                        
                        if (JSON.stringify(results).length > 15) {
                            resultOK = true
                            createdProfileId = results.data.createPhotog.id;
                        } else {
                            toast.notify(
                                <div style={{ "color": "red" }}>Error #785. Unable to create a profile. Please send us the error number, so we could fix it.</div>, 
                                { duration: 10000 }
                                );
                        }
                        
                        //console.log(results.data.createPhotog.id)
                    })
                    .catch(error => {
                        
                        toast.notify(
                            <div style={{ "color": "red" }}>Error #742. Unable to create a profile. Please send us the error number, so we could fix it.</div>, 
                            { duration: 10000 }
                            );
                        
                        // console.log("Error: ", error);
                    })
            }
        
            if (resultOK) { 
                this.props.history.push({ pathname: '../photographer/' + createdProfileId + '/edit', state: { profileType: "photog" } })
            }

        } else if (type === 'modell') {

            let createdProfileId = ''
            let publicURL = '0000-0000'
            let premiumURL = ''
            let secondStageOK = false;
            let resultOK = false

            //console.log(this.props)
            await this.props.client
                .query({ query: generatepublicURL, variables: { type: 'modell', }, })
                .then(results => {
                    
                    if (JSON.stringify(results).length > 15) {

                        publicURL = results.data.generatepublicURL;
                        secondStageOK = true;

                    } else {
                        toast.notify(
                            <div style={{ "color": "red" }}>Error #765. Unable to create a profile. Please send us the error number, so we could fix it.</div>, 
                            { duration: 10000 }
                            );
                    }
                    
                })
                .catch(error => {
                    
                    toast.notify(
                        <div style={{ "color": "red" }}>Error #764. Unable to create a profile. Please send us the error number, so we could fix it.</div>, 
                        { duration: 10000 }
                        );
                    
                    // console.log("Error: ", error);
                })

            if (secondStageOK) {
                await this.props.client
                    .mutate({
                        mutation: createModellMutation,
                        variables: {
                            input: {
                                status: 0,
                                name: this.props.store.get("fName") + ' ' + this.props.store.get("lName"),
                                gender: 0,
                                experience: 0,
                                address: "", addr_locality: "", addr_area2: "", addr_area1: "", country: "", location: { type: "Point", coordinates: [ 0, 0 ] },

                                avatar: this.props.store.get("avatar"),
                                languages: ['en'],
                                publicURL: publicURL, //in the form of ####-### //(1 is type) 187-665-477 
                                premiumURL: premiumURL, 
                                
                                catActing: false, catArt: false, catBodypaint: false, catBoudoir: false, catCosplay: false, catDance: false, 
                                catEditorial: false, catFashion: false, catFetish: false, catFitness: false, catLifestyle: false, catLingerie: false, 
                                catNude: false, catPortrait: false, catPregnancy: false, catPromotional: false, catRunway: false, 
                                catSport: false, catSwimwear: false, catTopless: false, catUnderwater: false, 

                                worksWith: 0, 
                                metric: 0,
                                travel: 0,
                                physique: 2,
                                cup: 2,
                                sizeChart: 0,
                                dress: 0, 
                                shoes: 0, 

                                isPro: false, // coming from User model, duplicated to this model
                                isVerified: false, //coming from User model, duplicated to this model
                            }
                        },
                    })
                    .then(results => {
                        
                        if (JSON.stringify(results).length > 15) {
                            resultOK = true
                            createdProfileId = results.data.createModell.id;
                        } else {
                            toast.notify(
                                <div style={{ "color": "red" }}>Error #785. Unable to create a profile. Please send us the error number, so we could fix it.</div>, 
                                { duration: 10000 }
                                );
                        }
                        
                        //console.log(results.data.createModell.id)
                    })
                    .catch(error => {
                        
                        toast.notify(
                            <div style={{ "color": "red" }}>Error #742. Unable to create a profile. Please send us the error number, so we could fix it.</div>, 
                            { duration: 10000 }
                            );
                        
                        // console.log("Error: ", error);
                    })
            }
        
            if (resultOK) { 
                this.props.history.push({ pathname: '../model/' + createdProfileId + '/edit', state: { profileType: "modell" } })
            }

        } else if (type === 'muah') {

           
            let createdProfileId = ''
            let publicURL = '0000-0000'
            let premiumURL = ''
            let secondStageOK = false;
            let resultOK = false

            //console.log(this.props)
            await this.props.client
                .query({ query: generatepublicURL, variables: { type: 'muah', }, })
                .then(results => {
                    
                    if (JSON.stringify(results).length > 15) {

                        publicURL = results.data.generatepublicURL;
                        secondStageOK = true;

                    } else {
                        toast.notify(
                            <div style={{ "color": "red" }}>Error #765. Unable to create a profile. Please send us the error number, so we could fix it.</div>, 
                            { duration: 10000 }
                            );
                    }
                    
                })
                .catch(error => {
                    
                    toast.notify(
                        <div style={{ "color": "red" }}>Error #764. Unable to create a profile. Please send us the error number, so we could fix it.</div>, 
                        { duration: 10000 }
                        );
                    
                    // console.log("Error: ", error);
                })

            if (secondStageOK) {
                await this.props.client
                    .mutate({
                        mutation: createMuahMutation,
                        variables: {
                            input: {
                                status: 0,
                                name: this.props.store.get("fName") + ' ' + this.props.store.get("lName"),
                                gender: 0,
                                experience: 0,
                                address: "", addr_locality: "", addr_area2: "", addr_area1: "", country: "", location: { type: "Point", coordinates: [ 0, 0 ] },

                                avatar: this.props.store.get("avatar"),
                                languages: ['en'],
                                publicURL: publicURL, //in the form of ####-### //(1 is type) 187-665-477 
                                premiumURL: premiumURL, 
                                
                                catMuah: false, catHair: false, catMakeup: false, catBodypaint: false, catStyle: false, topless: false, nude: false, 

                                isPro: false, // coming from User model, duplicated to this model
                                isVerified: false, //coming from User model, duplicated to this model
                            }
                        },
                    })
                    .then(results => {
                        
                        if (JSON.stringify(results).length > 15) {
                            resultOK = true
                            createdProfileId = results.data.createMuah.id;
                        } else {
                            toast.notify(
                                <div style={{ "color": "red" }}>Error #785. Unable to create a profile. Please send us the error number, so we could fix it.</div>, 
                                { duration: 10000 }
                                );
                        }
                        
                    })
                    .catch(error => {
                        
                        toast.notify(
                            <div style={{ "color": "red" }}>Error #742. Unable to create a profile. Please send us the error number, so we could fix it.</div>, 
                            { duration: 10000 }
                            );
                        
                        // console.log("Error: ", error);
                    })
            }
        
            if (resultOK) { 
                this.props.history.push({ pathname: '../muah/' + createdProfileId + '/edit', state: { profileType: "muah" } })
            }

        } else if (type === 'studio') {

            let createdProfileId = ''
            let publicURL = '0000-0000'
            let premiumURL = ''
            let secondStageOK = false;
            let resultOK = false

            //console.log(this.props)
            await this.props.client
                .query({ query: generatepublicURL, variables: { type: 'studio', }, })
                .then(results => {
                    
                    if (JSON.stringify(results).length > 15) {

                        publicURL = results.data.generatepublicURL;
                        secondStageOK = true;

                    } else {
                        toast.notify(
                            <div style={{ "color": "red" }}>Error #765. Unable to create a profile. Please send us the error number, so we could fix it.</div>, 
                            { duration: 10000 }
                            );
                    }
                    
                })
                .catch(error => {
                    
                    toast.notify(
                        <div style={{ "color": "red" }}>Error #764. Unable to create a profile. Please send us the error number, so we could fix it.</div>, 
                        { duration: 10000 }
                        );
                    
                    // console.log("Error: ", error);
                })

            if (secondStageOK) {
                await this.props.client
                    .mutate({
                        mutation: createStudioMutation,
                        variables: {
                            input: {
                                status: 0,
                                name: this.props.store.get("fName") + ' ' + this.props.store.get("lName"),
                                address: "", addr_locality: "", addr_area2: "", addr_area1: "", country: "", location: { type: "Point", coordinates: [ 0, 0 ] },

                                avatar: this.props.store.get("avatar"),
                                languages: ['en'],
                                publicURL: publicURL, //in the form of ####-### //(1 is type) 187-665-477 
                                premiumURL: premiumURL, 
                                
                                rooms: 1,
                                metric: 0,
                                area: 0, areaSm: 0,
                                ceilingHeight: 0, ceilingHeightCm: 0,
                            
                                bigObjects: false, catNude: false,
                                lightning: false, gear: false, accessories: false,
   
                                isPro: false, // coming from User model, duplicated to this model
                                isVerified: false, //coming from User model, duplicated to this model
                            }
                        },
                    })
                    .then(results => {
                        
                        if (JSON.stringify(results).length > 15) {
                            resultOK = true
                            createdProfileId = results.data.createStudio.id;
                        } else {
                            toast.notify(
                                <div style={{ "color": "red" }}>Error #785. Unable to create a profile. Please send us the error number, so we could fix it.</div>, 
                                { duration: 10000 }
                                );
                        }
                        
                    })
                    .catch(error => {
                        
                        toast.notify(
                            <div style={{ "color": "red" }}>Error #742. Unable to create a profile. Please send us the error number, so we could fix it.</div>, 
                            { duration: 10000 }
                            );
                        
                        // console.log("Error: ", error);
                    })
            }
        
            if (resultOK) { 
                this.props.history.push({ pathname: '../studio/' + createdProfileId + '/edit', state: { profileType: "studio" } })
            }

        } else if (type === 'event') {

            let createdProfileId = ''
            let publicURL = '0000-0000'
            let premiumURL = ''
            let secondStageOK = false;
            let resultOK = false

            //console.log(this.props)
            await this.props.client
                .query({ query: generatepublicURL, variables: { type: 'event', }, })
                .then(results => {
                    
                    if (JSON.stringify(results).length > 15) {

                        publicURL = results.data.generatepublicURL;
                        secondStageOK = true;

                    } else {
                        toast.notify(
                            <div style={{ "color": "red" }}>Error #765. Unable to create a profile. Please send us the error number, so we could fix it.</div>, 
                            { duration: 10000 }
                            );
                    }
                    
                })
                .catch(error => {
                    
                    toast.notify(
                        <div style={{ "color": "red" }}>Error #764. Unable to create a profile. Please send us the error number, so we could fix it.</div>, 
                        { duration: 10000 }
                        );
                    
                    // console.log("Error: ", error);
                })

            if (secondStageOK) {
                await this.props.client
                    .mutate({
                        mutation: createEventMutation,
                        variables: {
                            input: {
                                status: 0,
                                address: "", addr_locality: "", addr_area2: "", addr_area1: "", country: "", location: { type: "Point", coordinates: [ 0, 0 ] },
                                languages: ['en'],
                                virtual: false,
                                description: "",
                                publicURL: publicURL, //in the form of ####-### //(1 is type) 187-665-477 
                                premiumURL: premiumURL, 
                                isPro: false, // coming from User model, duplicated to this model
                                isVerified: false, //coming from User model, duplicated to this model
                                coverPhoto: "",
                                cpw: 0,
                                cph: 0,
                                
                            }

                        },
                    })
                    .then(results => {
                        
                        if (JSON.stringify(results).length > 15) {
                            resultOK = true
                            createdProfileId = results.data.createEvent.id;
                        } else {
                            toast.notify(
                                <div style={{ "color": "red" }}>Error #785. Unable to create a profile. Please send us the error number, so we could fix it.</div>, 
                                { duration: 10000 }
                                );
                        }
                        
                    })
                    .catch(error => {
                        
                        toast.notify(
                            <div style={{ "color": "red" }}>Error #742. Unable to create a profile. Please send us the error number, so we could fix it.</div>, 
                            { duration: 10000 }
                            );
                        
                        // console.log("Error: ", error);
                    })
            }
        
            if (resultOK) { 
                this.props.history.push({ pathname: '../event/' + createdProfileId + '/edit', state: { profileType: "event" } })
            }

        }
        
    }  


    findFirstLngTitle = (profile) => {

        const firstLng = profile.languages[0]

        let foundIndex = 0;
        for (var i = profile.title.length-1; i >= 0; i--) {
            if (profile.title[i].lng === firstLng) {
                // console.log(profile.title[i].lng)
                foundIndex = i;
            }
        }

        if (profile.title[foundIndex]) { 
            return profile.title[foundIndex].title; 
        } else {
            return "-"
        }
        

    }

    visitStripeCustomerPortal = async () => {

        let visitURL = ''
        await this.props.client
            .query({ query: portalSession, variables: { id: this.props.store.get('email') }, fetchPolicy: 'no-cache',})
            .then(results => {

                // console.log(results)
                if (JSON.stringify(results).length > 15) {
                   visitURL = results.data.portalSession;
                }

            })
            .catch(error => { 
                console.log(error) 
            })

        window.location.href = visitURL;

    }



    render() {

        const { profiles, loading } = this.props;
        // console.log('nomida')
        // console.log(this.props.loading)
        // if (this.props.data["me"] !== undefined) {
        //     console.log('this.props.data.me.profilePhotog[0].avatar', this.props.data.me.profilePhotog[0].avatar)        
        // }

        let photogProfiles = 0;
        let modellProfiles = 0;
        let muahProfiles = 0;
        let studioProfiles = 0;
        let eventProfiles = 0;
        if (profiles.profilePhotog) { photogProfiles = profiles.profilePhotog.length }
        if (profiles.profileModell) { modellProfiles = profiles.profileModell.length }
        if (profiles.profileMuah) { muahProfiles = profiles.profileMuah.length }
        if (profiles.profileStudio) { studioProfiles = profiles.profileStudio.length }
        
        let totalProfiles = photogProfiles + modellProfiles + muahProfiles + studioProfiles;
        if (profiles.profileEvent) { eventProfiles = profiles.profileEvent.length }        

        const isPro = this.props.store.get("isPro");
        const stripeExpressID = this.props.store.get("StripeExpressID");

        let showStripeMessage = false;
        if ( ((totalProfiles >= 1) || (eventProfiles >= 1)) && (stripeExpressID === '') ) {
            showStripeMessage = true;
        }
        // console.log('showStripeMessage', showStripeMessage)
        // console.log('totalProfiles', totalProfiles)
        // console.log('eventProfiles', eventProfiles)
        // console.log('stripeExpressID', stripeExpressID)


        //console.log('this.props.loading', this.props.loading, this.props.profiles)
        if (this.props.loading === true) { 
            return null
        } else 
        return (
            
            <ExtraWrapper>

                <Helmet>
                    <title>Profiles & listings</title>
                    <meta name="description" content="Your profiles and listings in PhotoSouls" />
                    <meta name="keywords" content="photosouls,profiles,listings" />
                </Helmet>

                

                        
                        { loading ?
                            <div className='settingsHeader'>Loading profiles ...</div>
                            :
                            <>
                                

                            {
                                this.state.showProfileSelectionMenu ?  
                                    <ProfileSelectionMenu  
                                        closePopup={this.toggleProfileSelectionMenu}
                                        createNewProfile={this.createNewProfile}
                                    />
                                    : null  
                            }  

                            <ModalTitle>

                                <BackButton onClick={ () => { this.props.history.push({ pathname: '../user/settings' }) } } >
                                    <FAIcon icon={faChevronLeft} color={'#000'} />
                                </BackButton>

                                <TitleHeadline>
                                    My Profiles
                                </TitleHeadline>

                                    
                                    { this.state.showStripeExpressLong !== true && showStripeMessage && this.state.makePayoutsAvailable === true?
                                        <TitleHeadline>
                                            <StripeExpressCallout>
                                                <Em><span role="img" aria-label='warning'>⚠️</span></Em>
                                                In order to begin processing payments for your bookings through our platform, we require some additional information from you. Along with your payment details, we also need your contact information. Please be aware that while we can still secure bookings for your photoshoots without this information, we will not be able to collect any payments on your behalf until these details are provided.
                                                <br/><br/>
                                                <ReadMoreLink onClick={() => { 
                                                    this.props.history.push({ pathname: '../user/settings/payments' })
                                                }}>Read more on Payments & Payouts</ReadMoreLink>
                                            </StripeExpressCallout>
                                        </TitleHeadline>
                                        : 
                                        null
                                    }


                            </ModalTitle>

                            
                                <div className='settingsCardGrid'>

                                    {
                                        profiles.profilePhotog.map((profile, index) => (     
                                            <ProfileButton
                                                key={index}
                                                name={profile.name}
                                                experience={profile.experience}
                                                //address={profile.address}
                                                //country={profile.country}
                                                travel={profile.travel}
                                                status={profile.status}
                                                createdAt={profile.createdAt}
                                                type='photog'
                                                avatar={profile.avatar}
                                                onClick={() => this.editProfile('photog', profile.id)}
                                            />
                                        ))

                                    }

                                    {
                                        profiles.profileModell.map((profile, index) => (
                                            <ProfileButton
                                                key={index}
                                                name={profile.name}
                                                experience={profile.experience}
                                                //address={profile.address}
                                                //country={profile.country}
                                                travel={profile.travel}
                                                status={profile.status}
                                                createdAt={profile.createdAt}
                                                type='modell'
                                                avatar={profile.avatar}
                                                onClick={() => this.editProfile('modell', profile.id)}
                                            />
                                        ))

                                    }

                                    {
                                        profiles.profileMuah.map((profile, index) => (
                                            <ProfileButton
                                                key={index}
                                                name={profile.name}
                                                experience={profile.experience}
                                                //address={profile.address}
                                                //country={profile.country}
                                                travel={profile.travel}
                                                status={profile.status}
                                                createdAt={profile.createdAt}
                                                type='muah'
                                                avatar={profile.avatar}
                                                onClick={() => this.editProfile('muah', profile.id)}
                                            />
                                        ))

                                    }

                                    {
                                        profiles.profileStudio.map((profile, index) => (
                                            <ProfileButton
                                                key={index}
                                                name={profile.name}
                                                experience={profile.experience}
                                                //address={profile.address}
                                                //country={profile.country}
                                                travel={profile.travel}
                                                status={profile.status}
                                                createdAt={profile.createdAt}
                                                type='studio'
                                                avatar={profile.avatar}
                                                onClick={() => this.editProfile('studio', profile.id)}
                                            />
                                        ))

                                    }

                                    
                                    <ProfileAddButton
                                        icon={faPlus}
                                        title={"Create a new profile"}
                                        desc={"Start offering your services on PhotoSouls"}
                                        onClick={() => {
                                            
                                                if (totalProfiles >= 1) {

                                                    if (isPro === true) {

                                                        this.toggleProfileSelectionMenu();

                                                    } else {

                                                        this.setState({ showUpgradeModal: true });

                                                    }

                                                } else {
                                                    this.toggleProfileSelectionMenu();
                                                }
                                            }
                                        }
                                    />


                                    {
                                        isPro ? 
                                            <>
                                                <ProMembershipCard onClick={ () => { this.visitStripeCustomerPortal() } } >
                                                    <ProTitle>Manage Your Pro Membership Billing Details.</ProTitle>
                                                </ProMembershipCard>

                                                <ProMembershipCard onClick={ () => { this.setState({ showUpgradeComparisonModal: true, hideCTA: true }) }} >
                                                    <ProTitle>Check your Pro membership benefits</ProTitle>
                                                </ProMembershipCard>

                                            </>
                                        :
                                            <MembershipCard onClick={ () => { this.setState({ showUpgradeComparisonModal: true }) }} >
                                                <UpgradeTitle>Your account is on the free Hobby membership plan.</UpgradeTitle>
                                                <UpgradeTitle>To enjoy extra benefits, upgrade to the Pro membership!</UpgradeTitle>
                                                <UpgradeCTA>Click here to read more!</UpgradeCTA>
                                            </MembershipCard>
                                    }
                                   
                                                
                                    

                                { this.state.showUpgradeModal ? 
                                    <UpgradeModal
                                        showUpgradeModal={this.state.showUpgradeModal}
                                        closePopup={() => this.setState({ showUpgradeModal: false }) }
                                        readMore={() => this.setState({ showUpgradeModal: false, showUpgradeComparisonModal: true }) }
                                        popTitle="Your free Hobby account can have only one profile and list one event"
                                        popDescription="To have unlimited profiles, and list unlimited events, please upgrade to the Pro account."
                                    />
                                : null
                                }

                                { this.state.showUpgradeComparisonModal ? 
                                    <UpgradeComparisonModal
                                        showUpgradeComparisonModal={this.state.showUpgradeComparisonModal}
                                        hideCTA={this.state.hideCTA}
                                        closePopup={() => this.setState({ showUpgradeComparisonModal: false, hideCTA: false }) }
                                    />
                                : null
                                }


                                <NewLine32/>

                                <div className='settingsHeader'>
                                    My Events
                                </div>

                                {/* #UPDATE: Profile card: If no active upcoming dates, say so with a tag (like it is "Inactive & Hidden" but say "No upcoming dates set"). List all upcoming dates under the card? */}

                                <NewLine/>

                                {
                                    profiles.profileEvent.map((profile, index) => (
                                        
                                        <EventCard
                                            key={index}
                                            
                                            coverPhoto={ profile.coverPhoto } 
                                            title={ this.findFirstLngTitle(profile) }
                                            dates={ profile.dates ? profile.dates : '' } 
                                            virtual={ profile.virtual }
                                            location={ profile.address } 
                                            status={ profile.status } 
                                            type='event'
                                            onClick={() => this.editProfile('event', profile.id)}
                                        />
                                        

                                    ))

                                }
                                
                                <ProfileAddButton
                                    icon={faPlus}
                                    title={"Create a new event"}
                                    desc={"Find participants to your workshop, tour, and/or class"}
                                    onClick={() => {
                                            
                                            if (eventProfiles >= 1) {

                                                if (this.props.store.get("isPro") === true) {

                                                    this.createNewProfile("event");

                                                } else {

                                                    this.setState({ showUpgradeModal: true });

                                                }

                                            } else {
                                                this.createNewProfile("event");
                                            }
                                        }
                                    }

                                />

                                </div> 

                            </>
                        }


                    
                
                    <Footer showbutton="true"/>
                    
                

                
                <IncreaseHeight64/>

            </ExtraWrapper>

        )
    }
}


const IncreaseHeight64 = styled.div`
    
    height: 64px;  
    min-height: 64px;

`;

const ExtraWrapper = styled.div`

    white-space: pre-line;
    width: calc(80% - 64px);
    max-width: 1200px;

    margin: 40px auto 32px auto;

    font-family: 'Fira Sans';

    @media (max-width: 790px) {
        width: calc(100% - 32px);
        margin: 0;
    }

`;



const NewLine32 = styled.div`
    width: 100%;
    min-width: 100%;
    display:flex;
    height: 32px;
`;

const NewLine = styled.div`
    width: 100%;
    min-width: 100%;
    display:flex;
`;


const ModalTitle = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;

    margin-left: 24px;

    @media (max-width: 970px) {
        flex-direction: column;
        margin-top: 16px;
    }


`;


const BackButton = styled(Button)`
    && {
        
    /* margin: -4px -4px 0 0px; */

    height: 48px;
    min-width: 48px;
    max-width: 48px;
    border-radius: 24px;

    margin-right: 16px;
    /* margin-right: 4px;
    margin-left: 4px; */

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;

    
    font-weight: 400;
    font-size: 19px;

    background-color: #EBEBEB;
    opacity: 0.6;

    &:hover {
        background-color: #EBEBEB;
        opacity: 0.6;
        }
    
    &:disabled {
        background-color: #fff;
        color: #fff;
        }

    }
    
`;

const FAIcon = styled(FontAwesomeIcon)`
    && {
    font-size: 22px;
    font-weight: 400;
    }
`;



const TitleHeadline = styled.div`
    
    padding: 12px 24px 24px 0px;

    font-family: 'Fira Sans';
    font-size: 22px;
    font-weight: 500;

    color: #333333;

`;

const StripeExpressCallout = styled.div`

    margin-top: 8px;
    margin-bottom: 16px;
    margin-right: 16px;

    padding: 24px;
    border-radius: 8px;

    font-family: 'Fira Sans';
    font-size: 16px;
    font-weight: 400;

    background-color: #ffe3b4;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.3);

    color: #333333;

`;

const ReadMoreLink = styled.div`
    
    text-decoration: underline;
    cursor: pointer;

`;

const Em = styled.span`
    padding-right: 16px;
`;



// export const Profiles = withUserProfiles(C);
export const Profiles = withRouter(withStore(withApollo(withUserProfiles(C))));




const MembershipCard = styled.div`
    
    width: 250px;

    padding: 16px 24px 16px 24px;
    border-radius: 8px;
    margin: 16px;
    margin-left: 24px;

    background: #ffa309;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.3);
    
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;

    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    
    color: #fff;

    &:hover {
        transform: scale(1.02);
        /* background: #ffa309; */
        background: #ffac23;
        cursor: ${(props) => props.newline ? "cursor": "pointer"};
        }

    
    
`;

const UpgradeTitle = styled.div`
    
    font-size: 19px;
    font-weight: 400;
    margin-bottom: 8px;
    margin-top: 8px;

`;


const UpgradeCTA = styled.div`
    
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 8px;
    text-decoration: underline;
    text-underline-offset: 4px;

`;

const ProMembershipCard = styled.div`
    
    width: 250px;

    padding: 16px 24px 16px 24px;
    border-radius: 8px;
    margin: 16px;
    margin-left: 24px;

    background: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.3);
    
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;

    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    
    color: #333333;

    &:hover {
        /* transform: scale(1.02); */
        background: #f8f8f8; 
        cursor: ${(props) => props.newline ? "cursor": "pointer"};
        }

    
    
`;

const ProTitle = styled.div`
    
    font-family: 'Fira Sans';
    font-size: 19px;
    font-weight: 500;
    line-height: 1.5em;
    margin-bottom: 8px;

`;
