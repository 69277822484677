import React, { PureComponent } from 'react'

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

export class Invite extends PureComponent {

    componentDidMount() {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }

    render() {
        return (
            
            <>

                <Helmet>
                    <title>Invite</title>
                    <meta name="description" content="invite" />
                    <meta name="keywords" content="photosouls,invite" />
                </Helmet>

                <div className="bodywrapper">
                    <div>User Invite page</div>

                </div>

            </>

        )
    }
}
