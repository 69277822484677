
import React, { PureComponent } from 'react'
import { LoginView } from './LoginView'
import { LoginController } from '@psd/controller'

//import ReactGA from 'react-ga4';


import { withApollo } from "react-apollo";

class C extends PureComponent {
  
    state = {
        loadingVisible: false,
        returnUrlParam: '',
    };

    onFinish = (userId) => {

        let returnURL = '';
        let search = window.location.search;
        let params = new URLSearchParams(search);
        returnURL = params.get('returnURL');
        
        //ReactGA.event({ category: "User", action: "Login - Email", });
        
        const { location: { state } } = this.props;

        //if accessing url where login is required:
        if (state && state.next) { 
            return this.props.history.push(state.next);
            }
    

        if (returnURL) {

            //user requested to be redirected to specific page (and the login was not necessaryly required, to be used route based redirects)
            this.props.history.push(returnURL);  
            
        } else {

            //an "usual" login:
            this.props.history.push("/user/settings");

        }



        
    
    };

    updateLoader = ( staatus ) => {
        this.setState({ loadingVisible: staatus });
    }

  render() {

    return (

      
      <LoginController>
        {({ submit }) => (
            <LoginView 
              state={this.state}
              updateLoader={this.updateLoader}
              onFinish={this.onFinish} 
              submit={submit} 

              />)}
      </LoginController>

    );
  }

}

export const LoginConnector = (withApollo(C));
