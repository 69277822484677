
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

const StyledButton = styled(Button)`
    && {
    height: 48px;
    background: #ff9500;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.4);
    color: white;
    width: 100%;
    text-transform: none;
    margin-top: 8px;
    font-weight: 500;
    font-size: 17px;
        &:hover {
        background: #ff9500;
        opacity: 0.9;
        color: white;
        }
        &:disabled {
          opacity: 0.9;
        }
        @media (max-width: 400px) {
            font-weight: 700;
        }
    }
    
`;

const LocalImage = styled.img`
    height: 40px;
    width: 40px;
    margin: -2px auto auto auto;
`;

export class MyButton extends Component {
    
  render() {
      
    const {
        caption, 
        type,
        loading,
        
        } = this.props;
    

    return (
      
      <StyledButton 
        type={ type }
        disabled = { loading ? true : false }
        onClick={this.props.onClick}
        >
        
        { loading ?
          <LocalImage 
            src={require('./../../assets/whiteLoader.gif')} 
            />
          : null 
        }

        { loading ? null : caption }
      </StyledButton>
    
    );

  }
}
