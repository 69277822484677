import React from 'react';  
import '../../../styles/ModalPopup.css';  

import styled from 'styled-components';

import Button from '@material-ui/core/Button';
// import i18next from "i18next";
import './../../../i18n'

import gql from "graphql-tag"

import { Divider } from "../../components/Divider";
import { MyP } from "../../components/MyP";


const updateMutation = gql`
    mutation($type: String!, $status: String!) {
        updatePermissions(type: $type, status: $status)
    }
`;


class AskNotificationsPermission extends React.Component {  

    state = {
        confirmReason: "",
        confirmPwd: "",
        errorMsg: "",
    };

    render() {  

        return (  
            
            <div className='react-confirm-alert-overlay'>
            
                <div className='react-confirm-alert-body-400'>
                        
                    <ModalHeaderIcon
                            src={require('./../../../assets/icon-bell-512.png')}
                            alt="Notifications permission"
                    />                            

                    <h1>Turn on notifications?</h1>

                    <Divider />
                    <MyP
                        p="We can let you know when someone confirms the booking, messages you, or notify you about other important account activity."
                    />
                    <Divider />
                    <Divider />


                    <ButtonArea>
                        <StyledSave 
                            onClick={() => {

                                this.props.apolloClient
                                    .mutate({
                                        mutation: updateMutation,
                                        variables: {
                                            type: 'notifications',
                                            status: 'true'
                                        },
                                    })
                                    .then(apiResponse => {
                                        // console.log(apiResponse)
                                    })
                                    .catch(error => {
                                        // console.log("Error: ", error);
                                    })

                                this.props.propStore.set('chkRemindersP', 'true')
                                this.props.closePopup();

                            }}

                            >
                            Yes
                        </StyledSave>
                        
                        
                        <StyledCancel
                            onClick={() => {

                                this.props.apolloClient
                                    .mutate({
                                        mutation: updateMutation,
                                        variables: {
                                            type: 'notifications',
                                            status: 'false'
                                        },
                                    })
                                    .then(apiResponse => {
                                        // console.log(apiResponse)
                                    })
                                    .catch(error => {
                                        // console.log("Error: ", error);
                                    })

                                this.props.propStore.set('chkRemindersP', 'false')
                                this.props.closePopup();
                            }}
                            >
                            No
                        </StyledCancel>

                    </ButtonArea>
                            

                </div>
                    

            </div>
        );  
    }  
    
}  

export default AskNotificationsPermission;



const ButtonArea = styled.div`

    display: flex;

    padding-bottom: 8px;
    padding-left: 8px;

    color: #333333;
    align-items: flex-end;

`;



const StyledSave = styled(Button)`
    && {
    height: 40px;
    top: 18px;
    
    background: #ff9500;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.4);
    color: white;
    width: 50%;
    margin-left: -8px;

    text-transform: none;
    font-weight: 400;
    font-size: 17px;
        &:hover {
        background: #ff9500;
        opacity: 0.9;
        color: white;
        }
    }
    
`;

const StyledCancel = styled(Button)`
    && {
    height: 40px;
    top: 18px;
    margin-left: 8px;
    color: #333333;
    width: 50%;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    }
    
`;

const ModalHeaderIcon = styled.img`

    width: 75px;
    height: 75px;
    margin-top: 0;
    margin-bottom: 16px;
        
`;