import React, { PureComponent } from 'react'

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

import { withStore } from '@spyna/react-store'
import { withApollo } from "react-apollo";
import { withRouter } from 'react-router-dom';
import { MessageListItem } from "./MessageListItem";
import { MessageDetails } from "./MessageDetails";
import Footer from '../../modules/components/Footer/Footer'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
// import { faReply } from '@fortawesome/free-solid-svg-icons'

//import Button from '@material-ui/core/Button';
// import moment from "moment"; 

import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import gql from "graphql-tag"
import { Query } from "react-apollo";

import toast from 'toasted-notes' 
import 'toasted-notes/src/styles.css';


const getMessages = gql`
    query ( $queryFilter: String ) {
        getMessages( queryFilter: $queryFilter ) { 
            
            messageId, myUserId,
            dt,

            fromName,
            fromUserId, fromModellId, fromMuahId, fromPhotogId, fromStudioId,
            fromIsPro,
            fromIsUnread, fromAvatar,

            toName,
            toUserId, toModellId, toMuahId, toPhotogId, toStudioId, 
            toIsPro,
            toIsUnread, toAvatar, 
            
            lastMessage,
            lastMessageDt,

        }
    }
`;


const replyToMessageMutation = gql`
    mutation replyToMessage($messageId: String, $isFrom: Int, $messageText: String) {
        replyToMessage(messageId: $messageId, isFrom: $isFrom, messageText: $messageText) { 
            messageId 
        }
    }
`;


class C extends PureComponent {

    constructor(props) {
        
        super(props);

        this.state = {
        
            // awaitingConfirmation: [],
            // upcomingBookings: [],
            // awaitingDelivery: [],
            selectedMessageId: '',
            lastLoadedMessageId: '',
            replyFrom: 0,
            messagePopup: false,
    
        }

        this.fldValueChange = this.fldValueChange.bind(this);

    }

    

    async componentDidMount() {

        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

        // DEFAULT activePanel: 'awaitingConfirmation'

    }


    fldValueChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({ [name]: value, });
        
    }

    replyToMessage = async (messageId, isFrom, messageText) => {

        let resultOK = false
        // console.log('messageText', messageText)

        await this.props.client
            .mutate({
                mutation: replyToMessageMutation,
                variables: {
                    
                    messageId: messageId,
                    isFrom: isFrom,
                    messageText: messageText,
                    
                },
            })
            .then(results => {

                // console.log(results)
                
                if (JSON.stringify(results).length > 15) {

                    resultOK = true

                } else {
                    toast.notify(
                        <div style={{ "color": "red" }}>Error #11735. Unable to post a reply. Please send us the error number so we could fix it.</div>, 
                        { duration: 10000 }
                        );
                }
                
            })
            .catch(error => {
                
                toast.notify(
                    <div style={{ "color": "red" }}>Error #11738. Unable to post a reply. Please send us the error number so we could fix it.</div>, 
                    { duration: 10000 }
                    );
                
                //console.log("Error: ", error);
            })
    

        if (resultOK) { 

            window.location.reload();
            // this.props.history.push({ pathname: '/' })
        }

    }


    

    render() {


        // console.log('--- state', this.state)
        // const now = moment(new Date()); //todays date
        let isMobileScreen = '0';
        if (window.matchMedia('(max-width: 790px)').matches) {
            isMobileScreen = '1';
        }

        // console.log(isMobileScreen);

        return (
            
            <>
                <Helmet>
                    <title>Messages in PhotoSouls</title>
                    <meta name="description" content="Messages" />
                    <meta name="keywords" content="photosouls,messages" />
                </Helmet>

                <div className="bodywrapper">
                    

                    <MainTitle>
                        Inbox
                    </MainTitle> 

                    
                    
                        <Query 
                            asyncMode 
                            query={getMessages} 
                            fetchPolicy="network-only"
                        >
                            {({ data, loading }) => {

                                // test here, why length is not working.because if backend is stopped (or server unreachable), front will give TypeError: Cannot read property 'length' of undefined
                            
                                if ((data === undefined) || (data === null) || (loading === true)) {

                                    return (
                                        <MainTitle>Loading messages. Please wait...</MainTitle> 
                                    )    

                                } else
                                
                                if ((JSON.stringify(data).length > 35) && (loading === false)) {

                                    //console.log('-- data', data)
                                    //this.loadFavData(data); //fills this.state.favPhotog and other state arrays
                                    
                                    // console.log('this.state.favPhotog', this.state.favPhotog) 

                                    // console.log('this.state.selectedMessageId', this.state.selectedMessageId); 

                                    return (
                                        
                                        <MainArea>

                                        
                                            <MessageList>
                                                {
                                                    data.getMessages.map((message, index) => {
                                            
                                                        if ((this.state.selectedMessageId === '') && (index === 0)) {

                                                            this.setState({ selectedMessageId: message.messageId});

                                                            if (message.fromUserId === message.myUserId) {
                                                                this.setState({ replyFrom: 0});
                                                            } else {
                                                                this.setState({ replyFrom: 1});
                                                            }

                                                        }

                                                        return (
                                                            <MessageListItem
                                                                key={index}
                                                                index={index}
                                                                message={message}
                                                                isMobile={isMobileScreen}
                                                                selectedMessageId={this.state.selectedMessageId}
                                                                onClick={() => { 
                                                                    
                                                                    if (isMobileScreen === '1') {

                                                                        this.setState({ 
                                                                            selectedMessageId: message.messageId, 
                                                                            messagePopup: true
                                                                        }); 

                                                                    } else {

                                                                        this.setState({ 
                                                                            selectedMessageId: message.messageId
                                                                        }); 

                                                                    }

                                                                }
                                                                } 
                                                            />
                                                        );
                                                    })
                                                }

                                            </MessageList>

                                            {
                                                (isMobileScreen === '1') ?
                                                    <>
                                                    
                                                    { this.state.messagePopup ?

                                                        <div className='react-confirm-alert-overlay'>
                                                            <div className='react-confirm-alert-body-min80'>


                                                            <BackButton onClick={ () => this.setState({ messagePopup: false, }) } >
                                                                <FAIcon icon={faChevronLeft} color={'#000'} />
                                                            </BackButton>

                                                                <MessageContent>
                                                                    
                                                                    <MessageDetails
                                                                        key={this.state.selectedMessageId}
                                                                        messageId={this.state.selectedMessageId}
                                                                    />

                                                                </MessageContent>

                                                                
                                                                <MessageReply>

                                                                    <TextareaStyled
                                                                        name={'messageField'}
                                                                        value={this.state.messageField}
                                                                        onChange={this.fldValueChange}
                                                                        placeholder={"Type a message..."}
                                                                        rows={5}
                                                                        // cols={5}
                                                                    />

                                                                    
                                                                    { isMobileScreen === '1' ? 
                                                                        <>
                                                                        <BtnSendMobile onClick={ () => { this.replyToMessage(this.state.selectedMessageId, this.state.replyFrom, this.state.messageField); } } >
                                                                            Send
                                                                        </BtnSendMobile>
                                                                        </>
                                                                        :
                                                                        <BtnSend onClick={ () => { this.replyToMessage(this.state.selectedMessageId, this.state.replyFrom, this.state.messageField); } } >
                                                                            Send
                                                                        </BtnSend>
                                                                    }
                                                                

                                                                </MessageReply>


                                                                <IncreaseHeight128/>
                                                                <IncreaseHeight16/>
                                                                <IncreaseHeight16/>

                                                                <BackButton onClick={ () => this.setState({ messagePopup: false, }) } >
                                                                    <FAIcon icon={faChevronLeft} color={'#000'} />
                                                                </BackButton>

                                                                <IncreaseHeight128/>
                                                                <IncreaseHeight16/>
                                                                

                                                            </div>
                                                        </div>
                                                        :
                                                        null


                                                    }

                                                    </>
                                                :
                                                <RightSide>
                                                        
                                                    { 
                                                        (this.state.selectedMessageId !== '') 
                                                        //&& (this.state.selectedMessageId !== this.state.lastLoadedMessageId) 
                                                        ?

                                                            <>

                                                                <MessageContent>
                                                                    
                                                                    <MessageDetails
                                                                        key={this.state.selectedMessageId}
                                                                        messageId={this.state.selectedMessageId}
                                                                    />

                                                                </MessageContent>

                                                                <MessageReply>

                                                                    <TextareaStyled
                                                                        name={'messageField'}
                                                                        value={this.state.messageField}
                                                                        onChange={this.fldValueChange}
                                                                        placeholder={"Type a message..."}
                                                                        rows={3}
                                                                        // cols={5}
                                                                    />

                                                                    <BtnSend onClick={ () => { this.replyToMessage(this.state.selectedMessageId, this.state.replyFrom, this.state.messageField); } }>
                                                                        Send
                                                                    </BtnSend>

                                                                </MessageReply>

                                                            </>
                                                        :
                                                            null
                                                    
                                                    }
                                    

                                                </RightSide>

                                            }

                                        </MainArea>
                                        
                                    )

                                } else {

                                    return (

                                        <SubTitle>You have no messages</SubTitle> 
                                        
                                    )

                                } 

                        }}
                        </Query>    


                        

                        <Footer showbutton="true"/>
                </div>

            </>

        )
    }
}



const personalInfoFields = [
    'fName', 'lName', 'bDay', 'email', 'phone', 'phoneDisplay', 'phoneCountry', 'avatar', 'isVerified', 'isPro', 'temperror', 'pwdUpdated',
    'chkReminders', 'chkRemindersP', 'chkMessages', 'chkMessagesP', 'chkRecomm', 'chkRecommP', 'chkInsp', 'chkInspP', 'chkLocal', 'chkLocalP', 
    'chkHow', 'chkHowP', 'chkInvite', 'chkInviteP', 'chkSurvey', 'chkSurveyP', 'chkPolicy', 'chkPolicyP', 'chkTips', 'chkTipsP'
]


export const Inbox = withRouter(withStore(withApollo(C), personalInfoFields));




const MainArea = styled.div`
    
    width: 100%;
    display: flex;

    border-top: 1px solid rgba(170, 170, 170, 0.3);
    border-right: 1px solid rgba(170, 170, 170, 0.3);

    /* background-color: #ebe; */
    justify-content: space-between;

`;


const MessageList = styled.div`


    width: 30%;
    overflow: auto;

    /* background-color: #ffa; */

    @media (max-width: 790px) {
        width: 100%;
    }

`;

const RightSide = styled.div`

    width: 70%;
    height: 70vh;
    
    border-left: 1px solid rgba(170, 170, 170, 0.3);

    /* background-color: #0f6; */
    overflow: auto;

    @media (max-width: 790px) {
        display: none;
    }

    

`;

const MessageContent = styled.div`

   
    width: 100%;
    margin-right: 0px;

    /* background-color: #bebe; */
    /* border-bottom: 1px solid rgba(170, 170, 170, 0.3); */

    padding-top: 8px;

    flex-direction: right;

    @media (max-width: 790px) {
        width: calc(100% + 16px);
    }
    



`;

const MessageReply = styled.div`

   
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 18px;

    color: #333333;

    /* background-color: #ebe; */

    width: calc(80% + 2px);
    float: right;

    /* height: 96px;  */

    border: 0px;
    border-radius: 4px;

    padding-top: 8px;
    margin-bottom: 16px;

    @media (max-width: 790px) {
        width: calc(80% + 24px);
        margin-right: -16px;
    }
    
`;






const SubTitle = styled.div`
    
    font-size: 20px;
    font-weight: 400;
    padding: 16px 24px 16px 0px;


    font-family: 'Fira Sans';
    color: #333333;

`;




const MainTitle = styled.div`
    
    font-size: 22px;
    font-weight: 500;
    padding: 0px 24px 24px 0px;


    font-family: 'Fira Sans';
    color: #333333;

`;


const TextareaStyled = styled.textarea`
    
    resize: none;

    width: calc(100% - 128px);

    /* background-color: #ceb; */
    padding: 0px;
    margin: 0px;
    /* height: 24px; */
    padding: 8px;
    
    
    font-size: 18px;
    font-family: 'Fira Sans';
    font-weight: 300;

    color: #333333;
    
    border: 1px solid #cbcbcb;
    border-radius: 4px;  
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);    

    :focus {
        outline-color: #4C9AFF;
    }

    :disabled {
        background-color: #f7f7f7; 
    }

    @media (max-width: 790px) {
        font-size: 18px;
        width: calc(100% - 104px);
    }
`;



const BtnSend = styled(Button)`
    && {
    min-height: 40px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    color: white;
    
    padding-left: 16px;
    padding-right: 16px;

    margin-left: 16px;
    /* margin-right: 16px; */
    margin-bottom: 16px;

    text-transform: none;
    font-weight: 400;
    font-size: 17px;

    top: -50px;
    
    &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }

}
`;

const BtnSendMobile = styled(Button)`
    && {
    min-height: 40px;
    width: 32px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    color: white;
    
    padding-left: 4px;
    padding-right: 4px;

    margin-left: 16px;

    text-transform: none;
    font-weight: 400;
    font-size: 17px;

    top: -102px;
    
    &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }

}
`;


const BackButton = styled(Button)`
    && {
        
    /* margin: -4px -4px 0 0px; */

    height: 48px;
    min-width: 48px;
    max-width: 48px;
    border-radius: 24px;

    /* margin-right: 4px;
    margin-left: 4px; */

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;

    
    font-weight: 400;
    font-size: 19px;

    background-color: #EBEBEB;
    opacity: 0.6;

    &:hover {
        background-color: #EBEBEB;
        opacity: 0.6;
        }
    
    &:disabled {
        background-color: #fff;
        color: #fff;
        }

    }
    
`;

const FAIcon = styled(FontAwesomeIcon)`
    && {
    font-size: 22px;
    font-weight: 400;
    }
`;

const IncreaseHeight16 = styled.div`
    
    height: 16px;  

`;

// const IncreaseHeight64 = styled.div`
    
//     height: 64px;  

// `;

const IncreaseHeight128 = styled.div`
    
    height: 128px;  
    min-height: 128px;  

`;