
import React, { Component } from 'react';
import styled from 'styled-components';

const StyledH1 = styled.h1`
  color: #ff9500;
  font-size: 36px;
  line-height: 36px;
  padding: 0px;
  margin: 0px;
`;


export class MyH1 extends Component {
    
  render() {
      
    const { headline } = this.props;
    
    return (
      
      <StyledH1>{headline}</StyledH1>
    
    );

  }
}
