
import React, { PureComponent } from 'react'
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import Carousel from '@brainhubeu/react-carousel';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';
// import { PaymentElement } from '@stripe/react-stripe-js';
//import { PaymentElement, LinkAuthenticationElement, useStripe, useElements, ElementsConsumer, CardElement, } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import { DayPickerSingleDateController, CalendarDay } from 'react-dates'

import { MyError } from "../components/MyError";

import moment from "moment"; 
import i18next from "i18next";
import '../../i18n'

import TimeAgo from '../../utils/TimeAgo'

import { withStore } from '@spyna/react-store'
import { withApollo } from "react-apollo";
import { withRouter } from 'react-router-dom';

import { Query } from "react-apollo";
import gql from "graphql-tag";

import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

import toast from 'toasted-notes' 
import 'toasted-notes/src/styles.css';


const stripePromise = window.location.hostname === 'localhost' ? 
    loadStripe('pk_test_51MiJY8BYeQzxnFZwx8q9NVIEDGUsG0CLOAAXbHXbMvwA6uSBAVvh2CXE4NbdvwLWLwhxKUzywDKJP4nVVDtu74ls00P83XzWrm') 
    : 
    loadStripe('pk_live_51MiJY8BYeQzxnFZwrVxG6Yb3qfOel2uHqEDeEOd1NGELSxP0V3HKdYDlPXEOA5XpjDCudPMaBaFKoci9r3ZlbTR200PAG4XByF');
const stripeIntent = gql`
    query($type: String, $chargeAmount: Float, $currency: String) {
        stripeIntent(type: $type, chargeAmount: $chargeAmount, currency: $currency)
    }
`;

const refundPolicyQuery = gql`{ getKey(key:"refundPolicy") {value, key} }`;
const termsQuery = gql`{ getKey(key:"terms") {value, key} }`;
const paymentTermsQuery = gql`{ getKey(key:"paymentTerms") {value, key} }`;
const privacyQuery = gql`{ getKey(key:"privacy") {value, key} }`;


const startTimes = [
    { value: '0', code: '00:00', usCode: '12:00 AM', minutesPast: 0, },
    { value: '1', code: '00:30', usCode: '12:30 AM', minutesPast: 30, },
    { value: '2', code: '01:00', usCode: '01:00 AM', minutesPast: 60, },
    { value: '3', code: '01:30', usCode: '01:30 AM', minutesPast: 90, },
    { value: '4', code: '02:00', usCode: '02:00 AM', minutesPast: 120, },
    { value: '5', code: '02:30', usCode: '02:30 AM', minutesPast: 150, },
    { value: '6', code: '03:00', usCode: '03:00 AM', minutesPast: 180, },
    { value: '7', code: '03:30', usCode: '03:30 AM', minutesPast: 210, },
    { value: '8', code: '04:00', usCode: '04:00 AM', minutesPast: 240, },
    { value: '9', code: '04:30', usCode: '04:30 AM', minutesPast: 270, },
    { value: '10', code: '05:00', usCode: '05:00 AM', minutesPast: 300, },
    { value: '11', code: '05:30', usCode: '05:30 AM', minutesPast: 330, },
    { value: '12', code: '06:00', usCode: '06:00 AM', minutesPast: 360, },
    { value: '13', code: '06:30', usCode: '06:30 AM', minutesPast: 390, },
    { value: '14', code: '07:00', usCode: '07:00 AM', minutesPast: 420, },
    { value: '15', code: '07:30', usCode: '07:30 AM', minutesPast: 450, },
    { value: '16', code: '08:00', usCode: '08:00 AM', minutesPast: 480, },
    { value: '17', code: '08:30', usCode: '08:30 AM', minutesPast: 510, },
    { value: '18', code: '09:00', usCode: '09:00 AM', minutesPast: 540, },
    { value: '19', code: '09:30', usCode: '09:30 AM', minutesPast: 570, },
    { value: '20', code: '10:00', usCode: '10:00 AM', minutesPast: 600, },
    { value: '21', code: '10:30', usCode: '10:30 AM', minutesPast: 630, },
    { value: '22', code: '11:00', usCode: '11:00 AM', minutesPast: 660, },
    { value: '23', code: '11:30', usCode: '11:30 AM', minutesPast: 690, },
    { value: '24', code: '12:00', usCode: '12:00 PM', minutesPast: 720, },
    { value: '25', code: '12:30', usCode: '12:30 PM', minutesPast: 750, },
    { value: '26', code: '13:00', usCode: '01:00 PM', minutesPast: 780, },
    { value: '27', code: '13:30', usCode: '01:30 PM', minutesPast: 810, },
    { value: '28', code: '14:00', usCode: '02:00 PM', minutesPast: 840, },
    { value: '29', code: '14:30', usCode: '02:30 PM', minutesPast: 870, },
    { value: '30', code: '15:00', usCode: '03:00 PM', minutesPast: 900, },
    { value: '31', code: '15:30', usCode: '03:30 PM', minutesPast: 930, },
    { value: '32', code: '16:00', usCode: '04:00 PM', minutesPast: 960, },
    { value: '33', code: '16:30', usCode: '04:30 PM', minutesPast: 990, },
    { value: '34', code: '17:00', usCode: '05:00 PM', minutesPast: 1020, },
    { value: '35', code: '17:30', usCode: '05:30 PM', minutesPast: 1050, },
    { value: '36', code: '18:00', usCode: '06:00 PM', minutesPast: 1080, },
    { value: '37', code: '18:30', usCode: '06:30 PM', minutesPast: 1110, },
    { value: '38', code: '19:00', usCode: '07:00 PM', minutesPast: 1140, },
    { value: '39', code: '19:30', usCode: '07:30 PM', minutesPast: 1170, },
    { value: '40', code: '20:00', usCode: '08:00 PM', minutesPast: 1200, },
    { value: '41', code: '20:30', usCode: '08:30 PM', minutesPast: 1230, },
    { value: '42', code: '21:00', usCode: '09:00 PM', minutesPast: 1260, },
    { value: '43', code: '21:30', usCode: '09:30 PM', minutesPast: 1290, },
    { value: '44', code: '22:00', usCode: '10:00 PM', minutesPast: 1320, },
    { value: '45', code: '22:30', usCode: '10:30 PM', minutesPast: 1350, },
    { value: '46', code: '23:00', usCode: '11:00 PM', minutesPast: 1380, },
    { value: '47', code: '23:30', usCode: '11:30 PM', minutesPast: 1410, },
];

const today = new Date();

const getMuahAvailability = gql`
    query($muahId: String, $periodStart: String, $periodEnd: String, $availabilityId: String) {
        getMuahAvailability(muahId: $muahId, periodStart: $periodStart, periodEnd: $periodEnd, availabilityId: $availabilityId) { 
            pId, dId, dt, st, et, travel, location { type, coordinates }, addr, addr_locality, addr_area2, addr_area1, country
            }
    }
`;

const createBookingMutation = gql`
    mutation(
        $input: BookingInput!
    ) {
        createBooking(
            input: $input
        ) { id }
    }
`;



class C extends PureComponent {
    
    constructor(props) {
        super(props);
        this.state = {
            showCategoryModal: false,
            showTimeModal: false,
            showTermsPayment: false,
            showTermsRelease: false,
            showTermsRefund: false,
            availableTimesFinished: false,
            calendarAvailabilityConfirmed: false,
            lastBookingDate: '',
            lastPricingUpdate: '',
            
            packageIndex: 0,
            availabilityRangeStart: moment().format("YYYY-MM-01"),
            availabilityLoadingFinished: true,
            availabilityArray: [],
            
            availableTimes: [],
            availabilitySlotStart: '00:00',
            availabilitySlotEnd: '23:30',

            travelNotices: [],
            
            startTime: '-',
            startTimeStart: '',
            startTimeEnd: '',

            bookingDate: '',
            bookingDateString: 'Select when you would like to have a photoshoot',
            bookingLocation: 'To confirm the photoshoot location, please select the date.',

            totalAmountString: '',
            paidNowString: '',
            paidLaterString: '',
            messageField: '',

            currency: '',
            packagePrice: 0,
            bookingFee: 0,
            totalAmount: 0,
            paidNow: 0,
            paidLater: 0,
            amountSentToStripe: 0,
            stripeIntentCreatedWith: 0,
            
            displayPrice: 0,
            displayBookingFee: 0,
            displayTotalAmount: 0,
            errorMsg: '',

            showStripeModal: false,
            chargeMinServiceFee: false,
            proceedWithStripe: false,

        }

        this.fldValueChange = this.fldValueChange.bind(this);

    }

    fldValueChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({ [name]: value, });
        
    }
    


    moveIndex = (index) => {
        
        this.setState({
            packageIndex: this.state.packageIndex + index,
        });   

    }


    durationOptions = () => {
        return [
            { value: '15', code: '15', label: '15 min' },
            { value: '20', code: '20', label: '20 min' },
            { value: '30', code: '30', label: '30 min' },
            { value: '45', code: '45', label: '45 min' },
            { value: '60', code: '60', label: '1 hour' },
            { value: '90', code: '90', label: '1.5 hours' },
            { value: '120', code: '120', label: '2 hours' },
            { value: '150', code: '150', label: '2.5 hours' },
            { value: '180', code: '180', label: '3 hours' },
            { value: '210', code: '210', label: '3.5 hours' },
            { value: '240', code: '240', label: '4 hours' },
            { value: '300', code: '300', label: '5 hours' },
            { value: '360', code: '360', label: '6 hours' },
            { value: '420', code: '420', label: '7 hours' },
            { value: '480', code: '480', label: '8 hours' },
            { value: '540', code: '540', label: '9 hours' },
            { value: '600', code: '600', label: '10 hours' },
            { value: '660', code: '660', label: '11 hours' },
            { value: '720', code: '720', label: '12 hours' },
            { value: '1440', code: '1440', label: 'All day coverage' },
        ]
    }

    
    

    generateArrow = (direction, disabled = false) => {
        
        const indexChange = direction === 'left' ? -1 : 1

        if (direction === 'left') {

            return (
                <GreyButton 
                    key={11111111}
                    onClick={() => this.moveIndex(indexChange) }
                    disabled={disabled} >
                    <FAIcon icon={faChevronLeft} color={ disabled ? '#fff' : '#000'} />
                </GreyButton>
            )

        } else {

            return (
                <GreyButton
                    key={222222} 
                    onClick={() => this.moveIndex(indexChange) }
                    disabled={disabled} >
                    <FAIcon icon={faChevronRight} color={ disabled ? '#fff' : '#000'} />
                </GreyButton>
            )

        }

    }

    isDayBlocked = (day) => {

        // console.log('blocked.day', day)
        // console.log(' ----- ---  --- --- isDayBlocked.availabilityArray', this.state.availabilityArray)

        // example:
            // if (day.format('ddd') === 'Mon' && ['Jul', 'Aug'].includes(day.format('MMM')))  {
            //     return true
            // }            
            // return false
        
        if (this.state.availabilityArray.length > 0) {

            return !this.state.availabilityArray.some(date => day.isSame(date, 'day'));
            
        } else {

            return false

        }

            

    }

    isOutside = momentDate => {

        if (momentDate < moment(today).subtract(1, 'd'))
            return true
        
        return false
    }



    loadAvailabilityData = async (profileId) => {

        // console.log('loadAvailabilityData')

        setTimeout(() => { this.setState({ availabilityLoadingFinished: true, }); }, 15);

        let availabilityArray = []
        let travelNotices = []

        await this.props.client.query({ 
            query: getMuahAvailability, 
            variables: { 
                muahId: profileId,  
                periodStart: moment(this.state.availabilityRangeStart).format('YYYY-MM-DD'),
                periodEnd: moment(this.state.availabilityRangeStart).add('M', 2).format('YYYY-MM-DD'),   
            }, 
            fetchPolicy: 'no-cache',})
        .then(results => {

            if (JSON.stringify(results).length > 25) {

                const data = results.data.getMuahAvailability;
                for (var q = data.length-1; q >= 0; q--) {
                    let isoDate = new Date(parseInt(data[q].dt))
                    availabilityArray.push(moment(isoDate).format('YYYY-MM-DD'));

                    if (data[q].travel) {
                        travelNotices.push(moment(isoDate).format('LL') + ': ' + data[q].addr);
                    }

                } 
                
            }

        })
        .catch(error => { 
            
            // console.log(error) 

        })


        setTimeout(() => { this.setState({ availabilityArray: availabilityArray, travelNotices: travelNotices}); }, 50);

        return availabilityArray;

    }

    findPackageDescription = (id, packages) => {
        
        
        let description = 'Select your photoshoot package'
        
        for (var q = packages.length-1; q >= 0; q--) {
            if (packages[q].id === id) {
                description = packages[q].description[0].description
            }
        }
        
        return description


    }


    findPackageName = (id, packages) => {
        
        
        let name = 'Package name'
        
        for (var q = packages.length-1; q >= 0; q--) {
            if (packages[q].id === id) {
                name = packages[q].name[0].name
            }
        }
        
        return name


    }


    formatBookingDate = async (bookingdate, profileId) => {
        
        
        let formattedBookingDate = moment(bookingdate).format('LL');

        if ((bookingdate !== '') && (this.state.lastBookingDate !== bookingdate)) {

            // console.log('formatbookingDate')
            await this.props.client.query({ 
                query: getMuahAvailability, 
                variables: { 
                    muahId: profileId,  
                    periodStart: moment(bookingdate).format('YYYY-MM-DD'),
                    periodEnd: moment(bookingdate).format('YYYY-MM-DD'),   
                }, 
                fetchPolicy: 'no-cache',})
            .then(results => {

                const data = results.data.getMuahAvailability;
                if (JSON.stringify(data).length < 60) {

                    setTimeout(() => { this.setState({ 
                    
                        bookingDateString: formattedBookingDate + '\n\nPlease note that the calendar of this makeup and style artist contains no information about availability for this day. Your booking request will be sent for this date, and it will be confirmed or declined within the next 24 hours.', 
                        bookingLocation: this.props.defaultAddress,
                        availabilitySlotStart: '00:00',
                        availabilitySlotEnd: '23:30',
                        lastBookingDate: bookingdate,
                        availableTimesFinished: false,
                        calendarAvailabilityConfirmed: false,

                    }); }, 50);


                } else {

                    // console.log('!!! results.data.getMuahAvailability[0]', results.data.getMuahAvailability[0])

                    this.setState({ 

                        bookingDateString: formattedBookingDate, 
                        bookingLocation: results.data.getMuahAvailability[0].addr,
                        availabilitySlotStart: results.data.getMuahAvailability[0].st,
                        availabilitySlotEnd: results.data.getMuahAvailability[0].et,
                        lastBookingDate: bookingdate,
                        availableTimesFinished: false,
                        calendarAvailabilityConfirmed: true,
                    
                    });

                    
                }

                this.findAvailableTimes();


            })
            .catch(error => { 
                // console.log(error) 
            })
    
        

        } 

        

    }

    

    findAvailableTimes = async () => {
        
        let availableTimes = [];
       

        // if ((this.state.bookingDate !== '') && (this.state.bookingPackageId !== '')) {

            // console.log('find available times')
            let duration = this.state.bookingPackageDuration; //in minutes
            let nextPossibleStart = 0;
            let endAtMinutes = 0;

            for (var i = 0; i < startTimes.length; i++) {
                
                if (startTimes[i].code === this.state.availabilitySlotStart) { 
                    nextPossibleStart = startTimes[i].minutesPast;
                }

                if (startTimes[i].code === this.state.availabilitySlotEnd) { 
                    endAtMinutes = startTimes[i].minutesPast;
                }

            }

            // console.log('nextPossibleStart', nextPossibleStart)
            // console.log('endAtMinutes', endAtMinutes)

            let nextEndTime = nextPossibleStart + duration
            
            for (var x = 0; x < startTimes.length; x++) {
                
                // if ((startTimes[x].minutesPast === nextPossibleStart) && ((nextEndTime <= endAtMinutes) || (duration === 1440))) { 
                if (((startTimes[x].minutesPast === nextPossibleStart) && (nextEndTime <= endAtMinutes)) || (duration === 1440)) { 

                    // console.log('-----------------------')
                    // console.log('                          nextPossibleStart ', nextPossibleStart)
                    // console.log('                          nextEndTime ', nextEndTime)

                    let endCode = '00:00'
                    for (var u = 0; u < startTimes.length; u++) {
                        if (startTimes[u].minutesPast === nextEndTime) { 
                            endCode = startTimes[u].code;
                        }
                    } 

                    availableTimes.push({
                            st: startTimes[x].code,
                            et: endCode,
                            duration,
                        })

                    nextPossibleStart = startTimes[x].minutesPast + 30;
                    nextEndTime = nextPossibleStart + duration;

                }


            }

            setTimeout(() => { this.setState({ 
                    
                availableTimesFinished: true,
                availableTimes: availableTimes,

            }); }, 50);

        // } 

    }

    
    

    findPricing = (id, packages, isPro) => {
               
        for (var q = packages.length-1; q >= 0; q--) {
            if ((packages[q].id === id) && (this.state.lastPricingUpdate !== id)) {
                
                const commissionMin = 10;
                const proCommissionMin = 10;
                // const maxCommission = 100;

                let commissionRate = 0.15;
                if (!isPro) {
                    commissionRate = 0.20
                }

                let packagePrice = packages[q].price;
                let currency = packages[q].currency;

                // console.log('price info changed')
                // Make sure the totalAmount cannot be smaller than minimum. Free profile owners: 10€, subscribed profile owners: 5€
                // if total amount is smaller, serve it in the "Booking fee" line...
                
                let displayPrice = parseFloat(packagePrice * this.state.displayCurrencyRate).toFixed(2);
                
                let displayBookingFee = 0;
                let bookingFee = 0;
                let nonProMin = commissionMin / this.state.euroRate * this.state.displayCurrencyRate; // minimum fee we charge (matching the display currency). If package price is less, it's paid by client who books, as a booking fee
                let proMin = proCommissionMin / this.state.euroRate * this.state.displayCurrencyRate; // minimum fee we charge (matching the display currency). If package price is less, it's paid by client who books, as a booking fee
                
                let chargeMinServiceFee = false;
                // console.log(displayPrice)
                // console.log(proMin)
                // console.log(nonProMin)

                if (isPro) {
                    if (displayPrice < proMin) {
                        bookingFee = parseFloat(proMin) - parseFloat(displayPrice);
                        displayBookingFee = parseFloat(proMin) - parseFloat(displayPrice)
                        chargeMinServiceFee = true;
                    }
                } else {
                    if (displayPrice < nonProMin) {
                        bookingFee = parseFloat(nonProMin) - parseFloat(displayPrice);
                        displayBookingFee = parseFloat(nonProMin) - parseFloat(displayPrice)
                        chargeMinServiceFee = true;
                    }
                }

                // console.log(displayBookingFee)
                

                let totalAmount = parseFloat(parseFloat(packagePrice) + parseFloat(bookingFee)).toFixed(2) * 1;
                let displayTotalAmount = parseFloat(parseFloat(displayPrice) + parseFloat(displayBookingFee)).toFixed(2) * 1;

                let parsitudDisplayPriceForAnomalyRemoval = parseFloat(displayPrice).toFixed(2) * 1;

                let paidNow = parseFloat(parsitudDisplayPriceForAnomalyRemoval * commissionRate).toFixed(2) * 1;
                if (paidNow < nonProMin) {
                    paidNow = parseFloat(nonProMin).toFixed(2) * 1;;
                }
                
                //if (paidNow > maxCommission) {
                //    paidNow = maxCommission;
                //}
                
                let paidLater = parseFloat(parseFloat(parsitudDisplayPriceForAnomalyRemoval) - parseFloat(paidNow)).toFixed(2) * 1;
                if (paidLater < 0) {
                    paidLater = 0;
                }


                // console.log('totalAmount', totalAmount)
                // console.log('paidNow', paidNow)
                // console.log('paidLater', paidLater)

                // eslint-disable-next-line no-loop-func
                setTimeout(() => { this.setState({ 
                    
                    packagePrice: packagePrice,
                    currency: currency,
                    bookingFee: bookingFee,
                    totalAmount: totalAmount,
                    paidNow: paidNow,
                    paidLater: paidLater,
                    
                    displayPrice: parsitudDisplayPriceForAnomalyRemoval,
                    displayBookingFee: displayBookingFee,
                    displayTotalAmount: displayTotalAmount,
                    
                    packagePriceString: parseFloat(displayPrice).toFixed(2) + ' ' + this.state.displayCurrency,
                    bookingFeeString: parseFloat(displayBookingFee).toFixed(2) + ' ' + this.state.displayCurrency,
                    totalAmountString: parseFloat(displayTotalAmount).toFixed(2) + ' ' + this.state.displayCurrency,
                    paidNowString: parseFloat(paidNow).toFixed(2) + ' ' + this.state.displayCurrency,
                    paidLaterString: parseFloat(paidLater).toFixed(2) + ' ' + this.state.displayCurrency,

                    lastPricingUpdate: id,
                    availableTimesFinished: false,
                    chargeMinServiceFee: chargeMinServiceFee,

                }); }, 50);

                



            }
        }

    }


    findCancellationPolicy = (id, packages, bookingDate) => {
        
        let description = 'Cancellation policy depends on the package. Please select the package to see its cancellation policy.'
        
        for (var q = packages.length-1; q >= 0; q--) {
            if (packages[q].id === id) {

                let cancelAfterWord = '24 hours'
                if (packages[q].cancelAfter === 1) { cancelAfterWord = '24 hours' }
                else if (packages[q].cancelAfter === 2) { cancelAfterWord = '2 days' }
                else if (packages[q].cancelAfter === 3) { cancelAfterWord = '3 days' }
                else if (packages[q].cancelAfter === 5) { cancelAfterWord = '5 days' }
                else if (packages[q].cancelAfter === 7) { cancelAfterWord = '7 days' }
                else { cancelAfterWord = '7 days' }

                description = `Cancelling your photoshoot is free and you will get full refund if you cancel within ${cancelAfterWord} after booking, or at least ${packages[q].cancelBefore} days before the photoshoot date.`

                if (bookingDate !== '') {

                    const now = moment(new Date()); //todays date
                    const bDate = moment(bookingDate)
                    const finalFreeRefundDate = moment(bookingDate).subtract(packages[q].cancelBefore, 'd');
                    const hoursBetween = bDate.diff(now, 'hours')
                    const finalRefundDateString = moment(finalFreeRefundDate).format('LL');

                    if (finalFreeRefundDate < now) {

                        //Too close already (free cancellation in the past)
                        description = `We can refund you ${(100-packages[q].lateCancellationKeeps)}% of the total paid amount if you make the request at least ${packages[q].noCancellationsAfter} hours before your scheduled booking. If you cancel with less than ${packages[q].noCancellationsAfter} hours notice, we can refund you 50% of the total paid amount.` 

                        if (hoursBetween < packages[q].noCancellationsAfter) {
                            //description = `Since your photoshoot is scheduled to begin in less than ${packages[q].noCancellationsAfter} hours, the booking amount is non-refundable.`
                            description = `Since your photoshoot is scheduled to begin in less than ${packages[q].noCancellationsAfter} hours, if you need to cancel, we can offer a refund of 50% of the total paid amount.`
                        }

                    } else {

                        description = description + `\n\nIf you request a cancellation after ${finalRefundDateString} but at least ${packages[q].noCancellationsAfter} hours prior to your scheduled booking, we will refund you ${(100-packages[q].lateCancellationKeeps)}% of the total paid amount.\n\nIf you cancel less than ${packages[q].noCancellationsAfter} hours before your scheduled booking, we will refund you 50% of the total paid amount.` 

                    }

                } else {

                    description = description + `\n\nIf you request a cancellation less than ${packages[q].cancelBefore} days before, we will refund you ${(100-packages[q].lateCancellationKeeps)}% of the total paid amount.\n\nIf you cancel less than ${packages[q].noCancellationsAfter} hours before your scheduled booking, we will refund you 50% of the total paid amount.` 
                    
                }

                description = description + `\n\nBefore cancelling your booking, we suggest reaching out to your service provider to see if it's possible to reschedule for a different date and time, which would eliminate the need to cancel altogether.`


            }
        }
        
        return description


    }


    findPaymentInstructions = () => {

        let paymentInstructions = '';

        if (this.state.chargeMinServiceFee === true) {

            if ((this.state.instantBooking === true) && (this.state.calendarAvailabilityConfirmed === true)) {
                //nothing needs to be said, as instant booking is active. and they see the price. this is what we charge, immediatelly.
                //paymentInstructions = `Currently, only a booking fee of ${this.state.paidNowString} will be charged.\n\nThe remaining balance of your total, which amounts to ${this.state.paidLaterString}, is to be paid directly to the photographer after the photoshoot, and will not be charged by PhotoSouls.`
            } else {
                paymentInstructions = "You will only be charged after the service provider confirms your booking."
            }

        } else {

            //total is bigger than the service fee.

            //First let's see if full payment lifecycle is handled by Stripe Express or not.
            if (this.props.store.get("StripeExpressID") === '') {

                //no Stripe express id. 'Charge just service fee, and the rest is paid directly, off-site
                // console.log('this.state.paidLater', this.state.paidLater);
                // console.log('this.state.paidLaterString', this.state.paidLaterString);

                if ((this.state.instantBooking === true) && (this.state.calendarAvailabilityConfirmed === true)) {
                    paymentInstructions = `Currently, only a booking fee of ${this.state.paidNowString} will be charged.`
                    if (this.state.paidLater > 0) {
                        paymentInstructions = paymentInstructions + `\n\nThe remaining balance of your total, which amounts to ${this.state.paidLaterString}, is to be paid directly to the service provider after the photoshoot, and will not be charged by PhotoSouls.`
                    }
                } else {
                    paymentInstructions = `After the service provider confirms your booking, a booking fee of ${this.state.paidNowString} will be charged.`
                    if (this.state.paidLater > 0) {
                        paymentInstructions = paymentInstructions + `\n\nThe remaining balance of your total, which amounts to ${this.state.paidLaterString}, is to be paid directly to the service provider after the photoshoot, and will not be charged by PhotoSouls.`
                    }
                }

                
            } else {

                //stripe express id exists. we can handle the entire payment flow.

                if ((this.state.instantBooking === true) && (this.state.calendarAvailabilityConfirmed === true)) {
                    paymentInstructions = '' // nothing needs to be told. customer is charged.
                } else {
                    paymentInstructions = "The total booking amount will only be charged after the service provider confirms your booking."
                }


            }
        }

        return paymentInstructions;

    }


    validateBooking = async () => {

        // console.log('TIME FOR BOOKING. Current State for backend: ', this.state)

        let hasErrors = false;

        if (this.state.bookingPackageId === '') {

            hasErrors = true;
            setTimeout(() => { this.setState({ errorMsg: `Please select the photoshoot package`, }); }, 50);

        } else if (this.state.bookingDate === '') {

            hasErrors = true;
            setTimeout(() => { this.setState({ errorMsg: `Please select the date when you would like to have your photoshoot`, }); }, 50);

        } else if (this.state.startTime === '-') {

            hasErrors = true;
            setTimeout(() => { this.setState({ errorMsg: `Please select the time to have your photoshoot`, }); }, 50);

        } 

    
        if (hasErrors === false) {
         
            // Ready to contienue with Stripe for next, 

            let stripeIntentType = 'payment'; //default;
            if ((this.state.instantBooking === true) && (this.state.calendarAvailabilityConfirmed === true)) {
                stripeIntentType = 'payment' // immediate charge
            } else {
                stripeIntentType = 'setup' // for later chargin when confirmed
            }

            //create Stripe intent ... but it's only needed when the amount to be paid has changed since the last intent creation (which includes the first time)
            //if (this.state.stripeIntentCreatedWith !== this.state.paidNow) { DON't use this restriction! Client can go back, change the date, and from imemdiate paymentintent, we should now use setupintent instead in some cases, when setting up stripe on backend!!!!

                await this.props.client.query({ 
                    query: stripeIntent, 
                    variables: { 
                        type: stripeIntentType,
                        chargeAmount: (this.state.paidNow*100),
                        currency: this.state.displayCurrency
                    }, 
                    fetchPolicy: 'no-cache',})
                .then(results => {

                    // console.log('---  results from backend: ', results)
                    
                    if (JSON.stringify(results).length > 15) {
                        
                        this.setState({ 
                            stripeClientSecret: results.data.stripeIntent,
                            stripeIntentCreatedWith: (this.state.paidNow*100),
                            proceedWithStripe: true,
                        });

                    }

                })
                .catch(error => { 
                    setTimeout(() => { this.setState({ errorMsg: `Unable to connect with Stripe for payment information. Please try again later.`, }); }, 50);
                    console.log(error) 
                })

            //}

        }


    }


    postBooking = async (stripeIntent) => {

        let resultOK = false;
        let variablesForInput = {};

        let instantBooking = false; //default
        if ((this.state.instantBooking === true) && (this.state.calendarAvailabilityConfirmed === true)) {
            instantBooking = true;
        } else {
            instantBooking = false;
        }


        if (this.state.messageField.length > 0) {

            variablesForInput = {

                status: 0,  //  STATUS=0 (AWAITING) AS DEFAULT, ALWAYS, until BACKEND DECIDES OTHERWISE.  0=awaiting      1=confirmed      2=delivered    3=rejected      4=cancelled      5=refunded    6=chargeback
                
                dt: this.state.bookingDate,
                st: this.state.startTimeStart,
                et: this.state.startTimeEnd,
                
                provider: 2, //    0=photog      1=model      2=muah      3=studio      4=event
                muahId: this.props.data.id,
                
                packageId: this.state.bookingPackageId,
                duration: this.state.bookingPackageDuration,
            
                //catNumber: parseInt(this.state.categoryNumber),
            
                location: this.state.bookingLocation, 
            
                currency: this.state.currency,
                price: this.state.packagePrice,
                bookingFee: this.state.bookingFee,
                total: this.state.totalAmount,
                
                // commission: this.state.paidNow,
                // payout: this.state.paidLater,

                instantBooking: instantBooking,
                stripePaymentIntent: instantBooking ? stripeIntent : '',
                stripeSetupIntent: instantBooking ? '' : stripeIntent,
                
                userCurrency: this.state.displayCurrency,
                exchangeRate: this.state.displayCurrencyRate,
                userCurrencyPrice: this.state.displayPrice,
                userCurrencyBookingFee: this.state.displayBookingFee,
                userCurrencyTotal: this.state.displayTotalAmount,
                userCurrencyPaidNow: this.state.paidNow,
                userCurrencyPaidLater: this.state.paidLater,
            
                euroRate: this.state.euroRate,

                message: {
                    dt: new Date(), 
                    from: 0, //0=client, 1=provider 
                    message: this.state.messageField,
                }, 

            }

        } else {

            variablesForInput = {

                status: 0,  //  STATUS=0 (AWAITING) AS DEFAULT, ALWAYS, until BACKEND DECIDES OTHERWISE.  0=awaiting      1=confirmed      2=delivered    3=rejected      4=cancelled      5=refunded    6=chargeback
                
                dt: this.state.bookingDate,
                st: this.state.startTimeStart,
                et: this.state.startTimeEnd,
                
                provider: 2, //    0=photog      1=model      2=muah      3=studio      4=event
                muahId: this.props.data.id,
                
                packageId: this.state.bookingPackageId,
                duration: this.state.bookingPackageDuration,
            
                //catNumber: parseInt(this.state.categoryNumber),
            
                location: this.state.bookingLocation, 
            
                currency: this.state.currency,
                price: this.state.packagePrice,
                bookingFee: this.state.bookingFee,
                total: this.state.totalAmount,
                
                //commission: this.state.paidNow,
                //payout: this.state.paidLater,

                instantBooking: instantBooking,
                stripePaymentIntent: instantBooking ? stripeIntent : '',
                stripeSetupIntent: instantBooking ? '' : stripeIntent,

                userCurrency: this.state.displayCurrency,
                exchangeRate: this.state.displayCurrencyRate,
                userCurrencyPrice: this.state.displayPrice,
                userCurrencyBookingFee: this.state.displayBookingFee,
                userCurrencyTotal: this.state.displayTotalAmount,
                userCurrencyPaidNow: this.state.paidNow,
                userCurrencyPaidLater: this.state.paidLater,

                euroRate: this.state.euroRate,

            }
        }


       
        // console.log('variablesForInput', variablesForInput)
        
        await this.props.client
        .mutate({
            mutation: createBookingMutation,
            variables: {
                input: variablesForInput
            },
        })
        .then(results => {
            
            if (JSON.stringify(results).length > 15) {

                resultOK = true
                //createdProfileId = results.data.createMuah.id;

            } else {
                toast.notify(
                    <div style={{ "color": "red" }}>Error #194u. Unable to create a booking. Please send us the error number so we could fix it.</div>, 
                    { duration: 10000 }
                    );
            }
            
            //console.log(results.data.createMuah.id)
        })
        .catch(error => {
            
            toast.notify(
                <div style={{ "color": "red" }}>Error #193u. Unable to create a booking. Please send us the error number so we could fix it.</div>, 
                { duration: 10000 }
                );
            
            // console.log("Error: ", error);
        })


        if (resultOK) { 
            this.props.history.push({ pathname: '../bookings/' })
        }

        
        

    }


    async componentDidMount() {
   
        let notSet = false;
        if (localStorage.getItem('catFilterCategory') === 'undefined' || localStorage.getItem('catFilterCategory') === 'null' || localStorage.getItem('catFilterCategory') === '') {
            notSet = true
        }

        let bookingDateInStorage = '';
        if (JSON.parse(localStorage.getItem('setDatesFilter')) === true) {

            const startDate = localStorage.getItem('startDate') === 'null' || localStorage.getItem('startDate') === 'Invalid date' ? null : moment(localStorage.getItem('startDate'));
            const endDate = localStorage.getItem('endDate') === 'null' || localStorage.getItem('endDate') === 'Invalid date' ? null : moment(localStorage.getItem('endDate'));
        
            if ( (startDate === endDate) || ( (startDate !== null) && (endDate === null) ) ) {
                //we can set it only if we know the exact (one) day
                bookingDateInStorage = localStorage.getItem('startDate') === 'null' || localStorage.getItem('startDate') === 'Invalid date' ? '' : moment(localStorage.getItem('startDate')).format('YYYY-MM-DD');
            }
        }
     

        this.setState({ 
            categoryNumber: notSet ? "-1" : localStorage.getItem('catFilterCategory'),
            categoryName: notSet ? 'Select your photoshoot category' : localStorage.getItem('catFilterName'),
            bookingPackageId: this.props.bookingPackageId ? this.props.bookingPackageId : '',
            instantBooking: this.props.instantBooking ? this.props.instantBooking : false,
            bookingPackageDuration: this.props.bookingPackageDuration ? this.props.bookingPackageDuration : 60,
            bookingDate: this.props.bookingDate ? this.props.bookingDate : bookingDateInStorage,
            displayCurrencyRate: this.props.displayCurrencyRate ? this.props.displayCurrencyRate : 0,
            euroRate: this.props.euroRate ? this.props.euroRate : 0,
            displayCurrency: this.props.displayCurrency,
            errorMsg: '',
        });

    }    



    render() {
      
        moment.locale(i18next.language);

        const avatarPhoto = this.props.data.avatar;
        const name = this.props.data.name;

        if (this.state.availabilityLoadingFinished === false) {
            this.loadAvailabilityData(this.props.data.id)
        }

        this.formatBookingDate(this.state.bookingDate, this.props.data.id) 
        this.findPricing(this.state.bookingPackageId, this.props.data.packages, this.props.data.isPro) 

        


        // console.log(' ------- STATE on booking modal: ', this.state)
        // console.log(' ------- PROPS on booking modal: ', this.props)
        let starRating = '';
        if (this.props.data.avgRating < 1.5) { starRating = '⭐☆☆☆☆' }
        if (this.props.data.avgRating >= 1.5) { starRating = '⭐⭐☆☆☆' }
        if (this.props.data.avgRating >= 2.5) { starRating = '⭐⭐⭐☆☆' }
        if (this.props.data.avgRating >= 3.5) { starRating = '⭐⭐⭐⭐☆' }
        if (this.props.data.avgRating >= 4.5) { starRating = '⭐⭐⭐⭐⭐' }

        let renderPriceInfo = (
            <Section>

                <SectionTitle>
                    
                    <SectionHeadline>
                        Price & payment
                    </SectionHeadline>

                </SectionTitle>

                <SectionBody error={this.state.bookingPackageId === '' ? true : false}>
                    
                    {
                        this.state.bookingPackageId === '' ?
                        <>Please select the package for pricing info</>
                        :
                        <>
                        
                        <Tbl>
                            <tbody>
                            { this.state.displayBookingFee !== 0 ? 
                            

                                
                                <TblRow>
                                    <TblColLeft>
                                        Package price:<br/>
                                        Minimum booking and service fee:<br/>
                                        <br/>
                                    </TblColLeft>
                                    <TblColRight>
                                        {this.state.packagePriceString}<br/>
                                        {this.state.bookingFeeString}<br/>
                                        <br/>
                                    </TblColRight>
                                </TblRow>
                                : null 
                            }

                            <TblRow>
                                <TblColLeft>
                                    Paid now (booking and service fee):<br/>
                                </TblColLeft>
                                <TblColRight>
                                    {this.state.paidNowString}
                                </TblColRight>
                            </TblRow>
                            
                            { this.state.paidLater === 0 ? 
                                null
                                :
                                <TblRow>
                                    <TblColLeft>
                                        To be paid later:
                                        {/* Paid later (after the photoshoot is over) */}
                                        {/* Paid later (after photos are delivered) */}
                                    </TblColLeft>
                                    <TblColRight>
                                        {this.state.paidLaterString}
                                    </TblColRight>
                                </TblRow>
                            }

                            <TblRow><td></td><td></td></TblRow>
                            <TblRow><td></td><td></td></TblRow>

                            <TblRow>
                                <TblColLeft>
                                    <strong>Total:</strong>
                                </TblColLeft>
                                <TblColRight>
                                    <strong>{this.state.totalAmountString}</strong>
                                </TblColRight>
                            </TblRow>
                            </tbody>
                        </Tbl>
                    
                        <IncreaseHeight> </IncreaseHeight>

                        <PricingRow>
                            
                            { this.findPaymentInstructions() }

                        </PricingRow>

                        {/* Offer options: */}
                        {/*     * Pay in full.  */}
                        {/*     * Pay the total now and you're all set */}
                        {/*     * Pay €XX.XX now, and the rest (€YY.YY) will be automatically charged to the same payment method on Apr 11, 2022. No extra fees. */}
                        
                        </>

                    }
                    
                    
                </SectionBody>

            </Section>
        )

        let renderCancellationAndTerms = (
            <>
                {/* Cancellation Policy */}
                <Section>

                    <SectionTitle>
                        
                        <SectionHeadline>
                            Cancellation policy
                        </SectionHeadline>

                    </SectionTitle>

                    <SectionBody>
                        {
                            this.findCancellationPolicy(this.state.bookingPackageId, this.props.data.packages, this.state.bookingDate)
                        }
                    </SectionBody>
        
                </Section>
        
                    <Section> <Separator/> </Section>

                    {/* Terms */}
                    <Section>

                        <TermsText>
                            By confirming with the button below, I agree to the Cancellation Policy and 
                            <TermsLink term="refundPolicy" onClick={() => setTimeout(() => { this.setState({ showTermsRefund: true, termsType: 'refundPolicy' }); }, 50) }>Refund Policy</TermsLink>. 
                            I also agree to the  
                            <TermsLink term="terms" onClick={() => setTimeout(() => { this.setState({ showTermsPayment: true, termsType: 'terms' }); }, 50) }>Terms of Service</TermsLink>,
                            <TermsLink term="paymentTerms" onClick={() => setTimeout(() => { this.setState({ showTermsPayment: true, termsType: 'paymentTerms' }); }, 50) }>Payments Terms of Service</TermsLink>, and I acknowledge the
                            <TermsLink term="privacy" onClick={() => setTimeout(() => { this.setState({ showTermsRefund: true, termsType: 'privacy' }); }, 50) }>Privacy Policy</TermsLink>. 


                            {/* {
                                this.state.totalAmountString !== '' ?
                                    `I also agree to pay the total amount of ${this.state.totalAmountString}, which includes the services described in the selected package, and all the fees to book this package.`
                                    :
                                    null
                            } */}
                        </TermsText>
                        {/* <br/>
                        <TermsText>
                            {
                                this.state.totalAmountString !== '' ?
                                    `You will be charged only after the photographer has confirmed the booking. If your booking is declined or not confirmed within 24 hours, your will not be charged. We will send you e-mail updates about your booking status.`
                                    :
                                    null
                            }
                        </TermsText> */}


                        { this.state.showTermsPayment || this.state.showTermsRelease || this.state.showTermsRefund ?
                            <div className='react-confirm-alert-overlay'> 
                                <div className='react-confirm-alert-body-min80'>
                                    <div className="bodywrapper">

                                    <BackButton onClick={ () => setTimeout(() => { this.setState({ showTermsPayment: false, showTermsRelease: false, showTermsRefund: false }); }, 50) } >
                                        <FAIcon icon={faTimes} color={'#000'} />
                                    </BackButton>
                                    <br/>

                                        <Query query={ 
                                            this.state.termsType === 'refundPolicy' ? refundPolicyQuery
                                            : this.state.termsType === 'terms' ? termsQuery
                                            : this.state.termsType === 'paymentTerms' ? paymentTermsQuery
                                            : this.state.termsType === 'privcy' ? privacyQuery
                                            : termsQuery
                                        }>
                                            {({ data }) => {
                                                
                                                console.log(this.state.termsType)
                                                if (data === undefined) {
                                                    return (<div>Sorry, we're having technical problems at this moment. Please try again later.</div>)    
                                                } else
                                                
                                                    if (JSON.stringify(data).length > 15) {
                                                        const cleanKey = DOMPurify.sanitize(data.getKey.value);
                                                        const displayKey = parse(cleanKey);
                                                        return (
                                                            <div>{displayKey}</div>
                                                        );
                                                    }
                                                
                                                return <div>Loading ...</div>
                                            }}
                                        </Query>

                                    <br/><br/>
                                    <BackButton onClick={ () => setTimeout(() => { this.setState({ showTermsPayment: false, showTermsRelease: false, showTermsRefund: false }); }, 50) } >
                                        <FAIcon icon={faTimes} color={'#000'} />
                                    </BackButton>

                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }


                    </Section>
            </>                        
        )

        if (this.state.proceedWithStripe === true) {

            const appearance = { theme: 'stripe', };

            const options = {
                clientSecret: this.state.stripeClientSecret,
                appearance,
            };

            return (
            
                <ExtraWrapper>


                    <Header>
                        <Link to="/">
                            <img src={require("./../../assets/PhotoSouls-logo.svg")} className="Logo" alt="logo" />
                        </Link>
                    </Header>

                    <BookingContainer>

                        <ModalTitle>

                            <BackButton onClick={ () => { setTimeout(() => { this.setState({ proceedWithStripe: false, stripeIntentCreatedWith: 0, }); }, 50) } } >
                                <FAIcon icon={faChevronLeft} color={'#000'} />
                            </BackButton>

                            <TitleHeadline>
                                Confirm your photoshoot booking
                                {/* If no availability info is set for the selected day, have a title:  */}
                                {/* Request to book a photoshoot */}
                            </TitleHeadline>

                        </ModalTitle>

                        <Elements options={options} stripe={stripePromise}>

                            <CheckoutForm 
                                postBooking={this.postBooking}
                                renderPriceInfo={renderPriceInfo}
                                renderCancellationAndTerms={renderCancellationAndTerms}
                                instantBooking={((this.state.instantBooking === true) && (this.state.calendarAvailabilityConfirmed === true)) ? true : false}
                            />

                        </Elements>
                                
                    </BookingContainer>

                </ExtraWrapper>
            
            
            );

        } else {

            return (
            
                <ExtraWrapper>


                    <Header>
                        <Link to="/">
                            <img src={require("./../../assets/PhotoSouls-logo.svg")} className="Logo" alt="logo" />
                        </Link>


                    </Header>

                    
                    <BookingContainer>

                        
                    {/* Title */}
                    <ModalTitle>

                        <BackButton onClick={ () => { this.props.hideModal() } } >
                            <FAIcon icon={faChevronLeft} color={'#000'} />
                        </BackButton>

                        <TitleHeadline>
                            Book your photoshoot
                            {/* If no availability info is set for the selected day, have a title:  */}
                            {/* Request to book a photoshoot */}
                        </TitleHeadline>

                    </ModalTitle>

                        {/* Muah & avatar */}
                        <Section>

                            <SectionTitle>
                                
                                <SectionHeadline>
                                    Makeup & style
                                </SectionHeadline>

                            </SectionTitle>


                            <CardDiv>
                                
                                <AvatarArea>
                                    { 
                                        avatarPhoto ? 
                                            <Avatar 
                                                src={avatarPhoto} 
                                                alt=" " 
                                                onLoad={(e) => {
                                                    //e.target.src = values.avatar --- causes huge CPU spike. but looks like not necessary anyway!
                                                    e.target.style = 'display: flex' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                                }}
                                                onError={(e) => {
                                                    //e.target.src = '' /--- causes huge CPU spike. but looks like not necessary anyway!
                                                    e.target.style = 'display: none' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                                }}

                                            /> : <></>
                                    }
                                </AvatarArea>

                                <MemberInfoCard>

                                    <CardTitle>{name}</CardTitle>
                                    
                                    {

                                        this.props.data.reviewsTotal >= 5 ?
                                            <SubTitle><span role="img" aria-label="rating">{starRating}</span> ({this.props.data.avgRating}), {this.props.data.reviewsTotal} reviews</SubTitle>
                                            :
                                            <SubTitle>{TimeAgo(this.props.data.createdAt)}</SubTitle>
                                        
                                    }

                                    
                                </MemberInfoCard>


                            

                            </CardDiv>


                        </Section>


                        {/* Packages */}
                        <Section>


                            <SectionTitle>
                                
                                <SectionHeadline>
                                    Package
                                </SectionHeadline>

                                { 
                                    this.state.showPackageModal ?
                                        null
                                        :
                                        <SectionBtn onClick={() => setTimeout(() => { this.setState({ showPackageModal: true,  }); }, 50) }>
                                            {
                                                this.state.bookingPackageId === '' ? "Select package" : "Edit" 
                                            }
                                        </SectionBtn>

                                }


                            </SectionTitle>
                            
                            
                            <SectionBody error={this.state.bookingPackageId === '' ? true : false}>

                                { this.state.showPackageModal ?

                                    <div className='react-confirm-alert-overlay'>
                                    <div className='react-confirm-alert-body-min80'>

                                    <ModalTitle>

                                        {/* <BackButton onClick={ () => { this.setState({ showPackageModal: false, }) } } >
                                            <FAIcon icon={faTimes} color={'#000'} />
                                        </BackButton> */}

                                        <SectionTitle>
                                            <SectionHeadline>
                                                Select the package you would like to book:
                                            </SectionHeadline>
                                        </SectionTitle>

                                    </ModalTitle>
                                    <br/>

                                    <Section>
                                        <Carousel 
                                            arrows
                                            dots
                                            slidesPerPage={window.innerWidth > 1050 ? 2 : 1}
                                            // draggable={false}
                                            value={this.state.packageIndex}
                                            // addArrowClickHandler
                                            // keepDirectionWhenDragging
                                            arrowLeft={this.generateArrow('left')}
                                            arrowLeftDisabled={this.generateArrow('left', true)}
                                            arrowRight={this.generateArrow('right')}
                                            arrowRightDisabled={this.generateArrow('right', true)}
                                            onChange={(index) => { this.setState({ packageIndex: index, }); }} //this is needed for dots to work.
                                        >

                                            { 
                                            
                                                this.props.data.packages.map((pakett, index) => {

                                                    let packageSetter = { 
                                                        bookingPackageId: pakett.id, 
                                                        instantBooking: pakett.instantBooking,
                                                        bookingPackageDuration: pakett.duration, 
                                                        showPackageModal: false, 
                                                        startTime: '-', 
                                                        lastBookingDate: '',  
                                                        errorMsg: '',
                                                    }

                                                    return (
                                                        
                                                        <PackageCard2 key={index} onClick={ () => { this.setState(packageSetter) } } >

                                                            <PackageCardClickOverlay onClick={ () => { this.setState(packageSetter) } } ></PackageCardClickOverlay>

                                                            <PackageCardDuration onClick={ () => { this.setState(packageSetter) } } >
                                                                { this.durationOptions().find(op => { return op.code === pakett.duration.toString() }).label }
                                                            </PackageCardDuration> 

                                                            <PackageCardPrice onClick={ () => { this.setState(packageSetter) } } >
                                                                { 
                                                                    this.state.displayCurrencyRate === 0 ?
                                                                        parseFloat(pakett.price).toFixed(2) + ' ' + pakett.currency 
                                                                        :
                                                                        parseFloat(pakett.price * this.state.displayCurrencyRate).toFixed(2) + ' ' + this.state.displayCurrency 
                                                                }
                                                            </PackageCardPrice> 

                                                            <PackageCardName onClick={ () => { this.setState(packageSetter) } }>
                                                                { pakett.name[0].name }
                                                            </PackageCardName>

                                                            <PackageCardDescription2 onClick={ () => { this.setState(packageSetter) } }>
                                                                { pakett.description[0].description }
                                                            </PackageCardDescription2> 
                                                            
                                                        </PackageCard2> 
                                                    );
                                                    
                                                })

                                            }
                                        </Carousel>
                                    </Section>          
                                    
                                    </div>
                                    </div>
                                    :
                                    <>
                                        {
                                            this.state.bookingPackageId !== '' ? 
                                                <SectionSubTitle>{this.findPackageName(this.state.bookingPackageId, this.props.data.packages)}</SectionSubTitle>
                                                :
                                                null

                                        }
                                        { 
                                            this.findPackageDescription(this.state.bookingPackageId, this.props.data.packages) 
                                        }

                                    </>
                                
                                }
                                
                            </SectionBody>

                        </Section>


                        {/* Date  */}
                        <Section>

                            <SectionTitle>
                            
                                <SectionHeadline>
                                    Date
                                </SectionHeadline>

                                { 
                                    this.state.showDateModal ?
                                        null
                                        :
                                        <SectionBtn onClick={() => setTimeout(() => { this.setState({ showDateModal: true, availabilityLoadingFinished: false }); }, 50) }>
                                            {
                                                this.state.bookingDate === '' ? "Select date" : "Edit" 
                                            }
                                        </SectionBtn>

                                }

                            </SectionTitle>

                            <SectionBody error={this.state.bookingDate === '' ? true : false}>

                                { this.state.showDateModal ?

                                    <div className='react-confirm-alert-overlay'>
                                    <div className='react-confirm-alert-body'>

                                    <ModalTitle>

                                        {/* <BackButton onClick={ () => { this.setState({ showPackageModal: false, }) } } >
                                            <FAIcon icon={faTimes} color={'#000'} />
                                        </BackButton> */}

                                        <SectionTitle>
                                            <SectionHeadline>
                                                Select when you would like to have a photoshoot:
                                            </SectionHeadline>

                                            
                                        </SectionTitle>

                                    </ModalTitle>


                                    <br/>

                                    <DayPickerSingleDateController
                                        numberOfMonths={window.matchMedia("(max-width: 850px)").matches ? 1 : 2}
                                        focused={true}
                                        initialVisibleMonth={() => moment(this.state.availabilityRangeStart) } //moment() gives current date
                                        isOutsideRange={this.isOutside} //using to disable days that have already passed
                                        isDayBlocked={(day) => this.isDayBlocked(day)}
                                        daySize={48}
                                        renderCalendarDay={ props => {
                                            const { modifiers } = props
                                            return ( <CalendarDay { ...props } modifiers={modifiers} /> )
                                        }} 
                                        onDateChange={(day) => { 
                                            this.setState({ 
                                                bookingDate: moment(day).format('YYYY-MM-DD'), 
                                                errorMsg: '',
                                                showDateModal: false, 
                                            }) 
                                        }}

                                        firstDayOfWeek={1}
                                        onNextMonthClick={(next) => {
                                            setTimeout(() => { this.setState({ 
                                                availabilityRangeStart: moment(next).format("YYYY-MM-01"), 
                                                availabilityLoadingFinished: false, 
                                            }); }, 50) 
                                        }}
                                        onPrevMonthClick={(prev) => {
                                            setTimeout(() => { this.setState({ 
                                                availabilityRangeStart: moment(prev).format("YYYY-MM-01"), 
                                                availabilityLoadingFinished: false, 
                                            }); }, 50) 
                                        }}
                                        noBorder={true}
                                    />

                                    { this.state.travelNotices.length > 0 ? 
                                    <>
                                        <TravelNoticeHeader2>Travel notice! On the following dates, booking is available on these locations:</TravelNoticeHeader2>
                                    </>
                                    : null 
                                    }
                                    {
                                        this.state.travelNotices.map((travelInfo, index) => {
                                            return (<TravelNoticeDetail key={index}>{travelInfo}</TravelNoticeDetail>)
                                        })                                            
                                    }


                                    </div>
                                    </div>
                                    :
                                    <>
                                    {                                    
                                        this.state.bookingDateString
                                    }
                                    </>

                                }
                                
                            </SectionBody>

                        </Section>


                        {/* Time */}
                        {

                            this.state.bookingPackageId !== '' && this.state.bookingDate !== '' ? 
                                <Section>
                                    
                                    <SectionTitle>
                                        
                                        <SectionHeadline>
                                            Time
                                        </SectionHeadline>

                                        { 
                                            this.state.showTimeModal ?
                                                null
                                                :
                                                <SectionBtn onClick={() => setTimeout(() => { this.setState({ showTimeModal: true,  }); }, 50) }>
                                                    {
                                                        this.state.startTime === '-' ? "Select time" : "Edit" 
                                                    }
                                                </SectionBtn>

                                        }

                                    </SectionTitle>


                                    <SectionBody error={this.state.startTime === '-' ? true : false }>
                                        
                                    { this.state.showTimeModal ?

                                        <div className='react-confirm-alert-overlay'>
                                        <div className='react-confirm-alert-body'>

                                        <ModalTitle>

                                            <SectionTitle>
                                                <SectionHeadline>
                                                    Select the time you would like to have your photoshoot
                                                </SectionHeadline>
                                            </SectionTitle>

                                        </ModalTitle>

                                        <br/>

                                        <Section>
                                            <ElementRow>

                                            
                                                {/* 
                                                this.state.availableTimes.length > 0 ? 
                                                */}
                                                {
                                                    this.state.availableTimes.map((availableTime, index) => {

                                                        // Prepare time box 

                                                        return(                                

                                                            <TimeButton 
                                                                key={index} 
                                                                winLen={window.innerWidth-184}
                                                                onClick={() => {
                                                                    
                                                                    setTimeout(() => { 
                                                                    
                                                                        this.setState({ 
                                                                            startTime: availableTime.st + ' - ' + availableTime.et,
                                                                            startTimeStart: availableTime.st,
                                                                            startTimeEnd: availableTime.et,
                                                                            errorMsg: '',
                                                                            showTimeModal: false,
                                                                        });

                                                                        }, 
                                                                    50)

                                                                    }
                                                                } 
                                                            >

                                                            { availableTime.st } - { availableTime.et }

                                                            </TimeButton>
                                                        
                                                        )


                                                    })
                                                }

                                                {/* 
                                                :
                                                <>
                                                    <Section2>
                                                        <SectionBody>Unfortunately, there is no time available on this date to fit the selected photoshoot package. At which time you would like to start the photoshoot?</SectionBody>
                                                        <IncreaseHeight/>
                                                        <SectionBody>Please select another date, or message the model and ask about their availability on that date.</SectionBody>
                                                        <IncreaseHeight/>
                                                        <IncreaseHeight/>
                                                        <BtnBook onClick={ () => { this.setState({ 
                                                            startTime: availableTime.st + ' - ' + availableTime.et,
                                                            startTimeStart: availableTime.st,
                                                            startTimeEnd: availableTime.et,
                                                            errorMsg: '',
                                                            showTimeModal: false, 
                                                            }); } } > OK </BtnBook>
                                                    </Section2>
                                                </> */}

                                            

                                            </ElementRow>

                                        </Section>
                                        </div>
                                        </div>
                                        :
                                        <>
                                            {
                                                this.state.startTime === '-' ? "Select the time you would like to have your photoshoot" : this.state.startTime
                                            }
                                        </>

                                    }
                                        

                                    </SectionBody>

                                </Section>
                                :
                                null

                        }


                        {/* Category */}
                        {/* <Section>

                            <SectionTitle>
                                
                                <SectionHeadline>
                                    Category
                                </SectionHeadline>

                                { 
                                    this.state.showCategoryModal ?
                                        null
                                        :
                                        <SectionBtn onClick={() => setTimeout(() => { this.setState({ showCategoryModal: true,  }); }, 50) }>
                                            {
                                                this.state.categoryNumber === '-1' ? "Select category" : "Edit" 
                                            }
                                        </SectionBtn>

                                }

                            </SectionTitle>

                            <SectionBody error={this.state.categoryNumber === '-1' ? true : false } >

                                { this.state.showCategoryModal ?

                                    <div className='react-confirm-alert-overlay'>
                                    <div className='react-confirm-alert-body'>

                                    <SectionTitle>
                                        <SectionHeadline>
                                            What kind of photoshoot are you looking for?
                                        </SectionHeadline>
                                    </SectionTitle>
                                    <br/>

                                    <ElementRow>
                                    {
                                        
                                        muahCategories.map((category, index) => {

                                            let allCategoryButtons = []

                                            let supportedCategory = false;
                                            if (this.props.data[category.catField] === true) {
                                                supportedCategory = true
                                            }
                                                                                
                                            if (supportedCategory) {

                                                allCategoryButtons.push(
                                            
                                                    <CategoryButton 
                                                        key={index} 
                                                        newline={category.name === '--------------------' ? true : false}
                                                        winLen={window.innerWidth-184}
                                                        onClick={() => {
                                                            
                                                            setTimeout(() => { 
                                                            
                                                                this.setState({ 
                                                                    categoryName: category.name,
                                                                    categoryNumber: category.catNumber,
                                                                    showCategoryModal: false,
                                                                    errorMsg: '',
                                                                    
                                                                });

                                                                }, 
                                                            50)

                                                            }
                                                        } 
                                                    >

                                                    { category.name !== '--------------------' ?
                                                        <>
                                                            <CategoryThumb src={category.thumb} alt=" " />
                                                            { category.name } 
                                                        </>
                                                        :
                                                        <></>
                                                    }

                                                    </CategoryButton>
                                                )   

                                            }
                                            

                                            return (
                                                <div key={index}>
                                                    { allCategoryButtons }
                                                </div>
                                            )

                                        })


                                    }
                                    </ElementRow>
                                    </div>
                                    </div>
                                :
                                    this.state.categoryName
                                }

                            </SectionBody>

                            

                        </Section> */}



                        {/* Location */}
                        <Section>

                            <SectionTitle>

                                <SectionHeadline>
                                    Location
                                </SectionHeadline>
                            </SectionTitle>

                            <SectionBody error={ this.state.bookingDate === '' ? true : false }>
                                {
                                    this.state.bookingLocation
                                }
                            </SectionBody>

                        </Section>



                        {/* Price & payment */}
                        {/* { renderPriceInfo } */}


                        {/* Message */}
                        <Section>

                            <SectionTitle>
                                
                                <SectionHeadline>
                                    Message to the makeup & hair professional
                                </SectionHeadline>

                            </SectionTitle>

                            <SectionBody>
                                Describe here a vision or the idea, exact address for the photoshoot or any questions.
                                
                                <TextareaStyled
                                    name={'messageField'}
                                    value={this.state.messageField}
                                    onChange={this.fldValueChange}
                                    rows={5}
                                />
                            </SectionBody>

                        </Section>

                        
                        { this.state.errorMsg ? ( <Section><IncreaseHeight/><MyError message = {this.state.errorMsg} /></Section>) : null }
                        

                        {/* Book button */}
                        <Section>

                            <BtnBook onClick={ () => { 
                                window.scrollTo({top: 96, behavior: 'smooth'});
                                this.validateBooking(); 
                            }}>
                                Next
                            </BtnBook>

                        </Section>


                        <Section> <IncreaseHeight32/></Section>
                        
                        <IncreaseHeight32/>



                    </BookingContainer>

                    

                </ExtraWrapper>
            
            );
        
        }

    }


}

const personalInfoFields = [
    'fName', 'lName', 'bDay', 'email', 'phone', 'phoneDisplay', 'phoneCountry', 'avatar', 'isVerified', 'isPro', 'temperror', 'pwdUpdated',
    'chkReminders', 'chkRemindersP', 'chkMessages', 'chkMessagesP', 'chkRecomm', 'chkRecommP', 'chkInsp', 'chkInspP', 'chkLocal', 'chkLocalP', 
    'chkHow', 'chkHowP', 'chkInvite', 'chkInviteP', 'chkSurvey', 'chkSurveyP', 'chkPolicy', 'chkPolicyP', 'chkTips', 'chkTipsP', 'currency'
]


export const MuahBooking = withRouter(withStore(withApollo(C), personalInfoFields));

const ExtraWrapper = styled.div`

    width: calc(100% - 32px);

`;


const BookingContainer = styled.div`

    width: 100%;
    max-width: 760px;
    
    margin: auto;
    margin-top: 96px;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    
    align-items: center;
    justify-content: center;

    /* background-color: #ded; */

    z-index: 200;


`;

const Header = styled.div` 

    position: absolute;
    top: 0; 
    max-height: 72px;
    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    background-color: #fdfdfd;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

`;

const BackButton = styled(Button)`
    && {
        
    /* margin: -4px -4px 0 0px; */

    height: 48px;
    min-width: 48px;
    max-width: 48px;
    border-radius: 24px;

    margin-right: 16px;
    /* margin-right: 4px;
    margin-left: 4px; */

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;

    
    font-weight: 400;
    font-size: 19px;

    background-color: #EBEBEB;
    opacity: 0.6;

    &:hover {
        background-color: #EBEBEB;
        opacity: 0.6;
        }
    
    &:disabled {
        background-color: #fff;
        color: #fff;
        }

    }
    
`;

const FAIcon = styled(FontAwesomeIcon)`
    && {
    font-size: 22px;
    font-weight: 400;
    }
`;


const ModalTitle = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: flex-start;
    justify-content: flex-start;

    /* background-color: #ebe; */

    width: 100%;

    margin-left: 24px;
    /* margin-top: -4px; */
    /* padding-left: 16px; */

    @media (max-width: 970px) {
        flex-direction: column;
    }


`;

const TitleHeadline = styled.div`
    
    padding: 12px 24px 24px 0px;

    font-family: 'Fira Sans';
    font-size: 24px;
    font-weight: 500;

    color: #333333;

`;

const Section = styled.div`
    
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    margin-left: 16px;
    margin-right: 0px;
    padding-right: 8px;
    
    width: calc(100% - 16px);

    align-items: flex-start;
    justify-content: flex-start;

    /* background-color: #fed;  */

    margin-bottom: 32px;
`;



const SectionTitle = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: center;
    justify-content: space-between;

    width: 100%;
    min-height: 40px;

    /* background-color: #ebe; */

`;




const SectionHeadline = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 500;
    font-size: 20px;

    /* color: #505050; */
    /* color: #383838; */
    color: #333333;

    /* background-color: #ded; */

`;


const SectionSubTitle = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 18px;

    /* color: #505050; */
    /* color: #383838; */
    color: #333333;

    /* background-color: #ded; */

    margin-bottom: 24px;
`;

const SectionBody = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 300;
    font-size: 18px;

    line-height: 24px;

    color: ${(props) => props.error ? 'rgba(255, 0, 0, 0.9)' : '#505050' };

    white-space: pre-wrap;

    /* width: calc(100% - 112px); */
    width: 100%;
    

`;


const TermsText = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 14px;

    color: #333333;

`;

const TermsLink = styled.span`
    
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 14px;

    margin-left: 4px;
    color: #333333;

    text-decoration: underline;
    text-underline-offset: 2px;

    &:hover {
        cursor: pointer; 
        opacity: 1;
        
        font-weight: 400;
        }

    /* background-color: #ded; */

`;


const SectionBtn = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 16px;

    color: #333333;

    text-decoration: underline;
    text-underline-offset: 2px;

    &:hover {
        cursor: pointer; 
        opacity: 1;
        font-weight: 500;
        }

    /* background-color: #ded; */

`;


const Separator = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: center;
    justify-content: space-between;

    width: 100%;
    
    height: 1px;
    background-color: #cdcdcd;


`;



const BtnBook = styled(Button)`
    && {
    min-height: 40px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    color: white;
    
    padding-left: 16px;
    padding-right: 16px;

    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    
    &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }

}
`;


const CardDiv = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;    

    padding-right: 32px;
    margin-top: 4px;

`;

const AvatarArea = styled.div`
    
    width: 64px; 
    margin: auto;

    
    /* float: left */

`;

const Avatar = styled.img`

    height: 64px;
    width: 64px;
    border-radius: 64px;
    
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.4);

`;

const MemberInfoCard = styled.div`
    
    padding-left: 16px;
    width: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
        
`;

const CardTitle = styled.div`
    
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 2px;
    /* @media (max-width: 790px) {
        display: none;
        } */

`;

const SubTitle = styled.div`
    
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5em;
    
    /* @media (max-width: 790px) {
        display: none;
        }
     */

`;



const ElementRow = styled.div`
    
    display: flex;
    flex-wrap: wrap;

`;


const TimeButton = styled.div`
        
    width: "150px";
    min-width: "150px";
    overflow: hidden;

    height: "120px";
    min-height: "120px";

    align-items: flex-start;

    font-size: 15px;
    font-weight: 400;
    font-family: 'Fira Sans';
    color: #333333;

    margin-right: 16px;
    margin-bottom: 24px;
    padding: 24px;


    border: 1px solid #cbcbcb;
    border-radius: 5px;

    /* box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2); */
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.3); 
    background-color: #fdfdfd;

    

    &:hover {
        transform: scale(1.02);
        cursor: ${(props) => props.newline ? "cursor": "pointer"};
    }
    
`;



const PackageCard2 = styled.div`
    
    
    width: 50%px;
    /* min-width: 200px; */
   
    margin: 16px 16px 8px 16px;
    align-items: flex-start;
    border-radius: 5px;
    
    border: 1px solid #cbcbcb;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    z-index: 300;

    &:hover {
        transform: scale(1.01);
        cursor: pointer;
       
    }

    @media (max-width: 1050px) {
        width: calc(100%+48px);
        padding-top: 16px;
        padding-left: 0px;
        padding-right: 0px;

        margin-top: 48px;
        margin-left: 1px;
        margin-right: 16px;
    }
    
`;

const PackageCardClickOverlay = styled.div`
    
    display: absolute;
    position: absolute;
    text-align: left;
    float: left; 

    margin-left: 0px;
    margin-top: 40px;

    width: 300px;
    height: 260px;

    &:hover {
        cursor: pointer;
    }

    z-index: 60;

`;

const PackageCardDescription2 = styled.div`
    
    display: absolute;
    position: relative;
    text-align: left;
    float: left; 

    margin-right: 0;
    margin-left: 16px;
    margin-top: 8px;

    width: 90%;
    padding-bottom: 16px;

    color: #333333;
    font-size: 16px;
    line-height: 20px;

    white-space: pre-wrap;
    margin-bottom: 8px;



    &:hover {
        cursor: pointer;
    }

    z-index: 600;

`;







const PackageCardDuration = styled.div`
    
    display: absolute;
    position: relative;
    text-align: left;
    float: left; 
    margin-right: auto;
    margin-left: 0px;
    
    margin-top: 16px; 

    border-radius: 0px 4px 4px 0px;
    padding: 6px 12px 6px 16px;

    color: #333333;
    background-color: rgba(75, 75, 75, 0.1);
    
    font-family: 'Fira Sans';
    font-size: 16px;
    font-weight: 400;

    &:hover {
        cursor: pointer;
    }

    z-index: 600;

`;




const PackageCardPrice = styled.div`
    
    display: absolute;
    position: relative;

    text-align: right;
    float: right; 
    margin-left: auto;

    margin-top: 16px; 

    border-radius: 4px 0px 0px 4px;
    padding: 6px 16px 6px 12px;

    color: #333333;
    background-color: rgba(75, 75, 75, 0.1);
    
    font-family: 'Fira Sans';
    font-size: 16px;
    font-weight: 400;

    &:hover {
        cursor: pointer;
       
    }

    z-index: 700;

`;



const PackageCardName = styled.div`
    
    display: absolute;
    position: relative;
    text-align: left;
    float: left; 

    margin-right: 0;
    margin-left: 16px;
    margin-top: 16px;
    
    z-index: 600;

    width: 90%;

    color: #333333;
    font-family: 'Fira Sans';
    font-size: 18px;
    font-weight: 400;

    white-space: pre-wrap;
    margin-bottom: 8px;

    &:hover {
        cursor: pointer;
    }

`;


const GreyButton = styled(Button)`
    && {
        
    /* margin: -4px -4px 0 0px; */

    height: 48px;
    min-width: 48px;
    max-width: 48px;
    border-radius: 24px;

    /* margin-right: 4px;
    margin-left: 4px; */

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;
    font-weight: 400;
    font-size: 19px;

    background-color: #EBEBEB;
    opacity: 0.6;

    &:hover {
        background-color: #EBEBEB;
        opacity: 0.6;
        }
    
    &:disabled {
        background-color: #fff;
        color: #fff;
        }

    @media (max-width: 790px) {
        position: absolute;
        top: 100px;
    }


    }
    
`;


const TextareaStyled = styled.textarea`
    
    width: calc(100% - 16px);

    padding: 0px;
    margin: 0px;
    /* margin-right: 16px; */
    /* height: 24px; */
    padding: 8px;
    
    font-size: 18px;
    font-family: 'Fira Sans';
    font-weight: 300;

    color: #333333;
    
    border: 1px solid #cbcbcb;
    border-radius: 4px;  
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    margin-top: 16px; 

    :focus {
        outline-color: #4C9AFF;
    }

    :disabled {
        background-color: #f7f7f7; 
    }

    @media (max-width: 690px) {
        font-size: 18px;
    }

`;



const Tbl = styled.table`
    
    width: 100%;

    border-left: 2px solid #cbcbcb;
    padding-left: 4px;

`;

const TblRow = styled.tr`
    
    padding-bottom: 8px;

`;

const TblColLeft = styled.td`
    
    /* background-color: #dede; */
    /* width: 70%; */
    float: left;

`;

const TblColRight = styled.td`
    
    text-align: right;

    /* background-color: #feb; */
    width: 35%;

`;



const PricingRow = styled.div`
    
    display: float;
    flex-direction: column;
    width: 100%;

    /* border-left: 2px solid #cbcbcb; */
    padding-left: 10px;
    
    /* background-color: #ebe; */

`;



const TravelNoticeHeader2 = styled.div`
    
    font-family: 'Fira Sans';
    font-size: 17px;
    font-weight: 500;

    color: #ff9500;

    margin-bottom: 2px;
    margin-left: 16px;

`;


const TravelNoticeDetail = styled.div`
    
    font-family: 'Fira Sans';
    font-size: 17px;
    font-weight: 400;

    color: #ff9500;

    padding-left: 48px;


`;

const IncreaseHeight32 = styled.div`
    
    height: 32px;  
    min-height: 32px;

`;

const IncreaseHeight = styled.div`
    
    height: 16px;  
    min-height: 16px;

`;