import React, { PureComponent } from 'react'
import { withApollo } from "react-apollo";
import { withStore } from '@spyna/react-store'

import Button from '@material-ui/core/Button';
import ConfirmDelete from '../ConfirmDelete';
import { MyError } from "../../../components/MyError";

import i18next from "i18next";
import moment from "moment"; 

import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select'
import ISO6391 from 'iso-639-1';

// import ToggleButton from '@material-ui/lab/ToggleButton';
// import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import InputNumber from 'rc-input-number';

import 'react-dates/initialize';
import './../../../../styles/ReactDatesOverride.css'

//import logger from '../../../../utils/logger'

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { orange, grey } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'


import { RadioButtonGroup } from 'react-rainbow-components';


import styled from 'styled-components';

import gql from "graphql-tag"

export const editPhotog = gql`
    mutation( $profileId: String! $input: PhotogInput! ) {
        editPhotog( profileId: $profileId, input: $input ) { name }
    }
`;


class C extends PureComponent { 


    constructor(props) {
        
        super(props);
        
        this.state = {
            activeView: 'packageList',
            selectedPackage: '',
            selectedPackageId: '',
        };
    
        this.fldValueChange = this.fldValueChange.bind(this);
          
    }

    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    fldValueChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({ [name]: value, });
        
    }
    
    numberFieldChange(name, value) {

    
        if (name === 'selectedPackagePrice') {

            let showInfoAbout0Price = true;
            if ((parseFloat(value).toFixed(2) === '0.00') || (parseFloat(value).toFixed(2) === '0,00')) { 
                showInfoAbout0Price = true 
            } else { 
                showInfoAbout0Price = false
            }

            if ((value === null) || (value === '')) { //happens when "e" is entered to the field
                value = 0
                showInfoAbout0Price = true 
            } 
            
            this.setState({ [name]: parseFloat(value).toFixed(2), infoAbout0Price: showInfoAbout0Price });

        } else {
            this.setState({ [name]: parseFloat(value).toFixed(2), });
        }

    }

    async componentDidMount() {

        this.props.showImmediateSaveLabel(true);
    
    }

    async componentWillUnmount() {

        this.props.showImmediateSaveLabel(false);
    
    }


    addPackage = async () => {

        const today = new Date();

        this.setState({
            activeView: 'packageAddEdit',

            selectedPackageId: '%%-----## -!new-package!- ##------%%',
            selectedPackagePrice: 0,
            infoAbout0Price: true,
            selectedPackageCurrency: this.props.store.get('currency'),
            selectedPackageDuration: '60',
            selectedPackageCancelAfter: '1',
            selectedPackageCancelBefore: 7, 
            lateCancellationKeeps: 30,
            noCancellationsAfter: 48,

            selectedPackageTravel: true,
            selectedPackageTravelDistance: 50, //km
            selectedPackageTravelDistanceMode: '0', //0 = km, 1 = miles
            selectedPackageTravelDistanceKm: 50, //km
            selectedPackageInstantBooking: true,
            selectedPackageLimitToDays: false,
            selectedPackageLimitToMon: false,
            selectedPackageLimitToTue: false,
            selectedPackageLimitToWed: false,
            selectedPackageLimitToThu: false,
            selectedPackageLimitToFri: false,
            selectedPackageLimitToSat: true,
            selectedPackageLimitToSun: true,
            selectedPackageLimitToDaysSelectedArr: ['Sat', 'Sun'],
            selectedPackageLimitToTimeframe: false, 
            selectedPackageLimitToTimeFrom: '10:00',
            selectedPackageLimitToTimeTo: '17:00',
            selectedPackageLimitToDates: false,
            selectedPackagelimitToDateFrom: moment(today).format('YYYY-MM-DD'), //no empty string! error. better null
            selectedPackagelimitToDateTo: moment(today).format('YYYY-MM-DD'),  //no empty string! error. better null
            selectedPackageAvailability: 0,

            selectedPackageConfirmDelete: false,
        }); 

        //set title field values to '' for each language
        this.props.values.languages.map((language, index) => {
            const fldPackageNameLng = 'fldPackageName_' + language;
            const fldPackageDescriptionLng = 'fldPackageDescription_' + language;
            this.setState({ [fldPackageNameLng]: '', [fldPackageDescriptionLng]: '', })
            return null
        })

        this.props.showSaveButton(false);

    }

    editPackageDetails = (params) => {

        const daysArr = []; 
        if (params.limitToMon) daysArr.push("Mon");
        if (params.limitToTue) daysArr.push("Tue")
        if (params.limitToWed) daysArr.push("Wed")
        if (params.limitToThu) daysArr.push("Thu")
        if (params.limitToFri) daysArr.push("Fri")
        if (params.limitToSat) daysArr.push("Sat")
        if (params.limitToSun) daysArr.push("Sun")

        this.setState({
            activeView: 'packageAddEdit',

            selectedPackageId: params.id,
            selectedPackagePrice: params.price,
            selectedPackageCurrency: params.currency,
            selectedPackageDuration: params.duration,
            selectedPackageCancelAfter: params.cancelAfter === null ? 1 : params.cancelAfter,
            selectedPackageCancelBefore: params.cancelBefore === null ? 7 : params.cancelBefore,
            lateCancellationKeeps: params.lateCancellationKeeps == null ? 30 : params.lateCancellationKeeps,
            noCancellationsAfter: params.noCancellationsAfter === null ? 48 : params.noCancellationsAfter,
            selectedPackageTravel: params.travel,
            selectedPackageTravelDistance: params.travelDistance,
            selectedPackageTravelDistanceMode: params.travelDistanceMode,
            selectedPackageTravelDistanceKm: params.travelDistanceKm,
            selectedPackageInstantBooking: params.instantBooking,
            selectedPackageLimitToDays: params.limitToDays,
            selectedPackageLimitToMon: params.limitToMon,
            selectedPackageLimitToTue: params.limitToTue,
            selectedPackageLimitToWed: params.limitToWed,
            selectedPackageLimitToThu: params.limitToThu,
            selectedPackageLimitToFri: params.limitToFri,
            selectedPackageLimitToSat: params.limitToSat,
            selectedPackageLimitToSun: params.limitToSun,
            selectedPackageLimitToDaysSelectedArr: daysArr,
            selectedPackageLimitToTimeframe: params.limitToTimeframe,
            selectedPackageLimitToTimeFrom: params.limitToTimeFrom,
            selectedPackageLimitToTimeTo: params.limitToTimeTo,
            selectedPackageLimitToDates: params.limitToDate,
            selectedPackagelimitToDateFrom: params.limitToDateFrom,
            selectedPackagelimitToDateTo: params.limitToDateTo,
            selectedPackageAvailability: params.availability,

            selectedPackageConfirmDelete: false,

        }); 

        //set title field values to '' for each language
        this.props.values.languages.map((language, index) => {
            const fldPackageNameLng = 'fldPackageName_' + language;
            const fldPackageDescriptionLng = 'fldPackageDescription_' + language;
            
            let mitmesName = 0;
            for (var i = params.name.length-1; i >= 0; i--) {
                if (params.name[i].lng === language) { 
                    mitmesName = i
                } 
            }

            let mitmesDesc = 0;
            for (var u = params.description.length-1; u >= 0; u--) {
                if (params.description[u].lng === language) { 
                    mitmesDesc = u
                } 
            }

            this.setState({ 
                [fldPackageNameLng]: params.name[mitmesName].name,
                [fldPackageDescriptionLng]: params.description[mitmesDesc].description,
            }) 

            return null
        })

        this.props.showSaveButton(false);

    }


    savePackage = async () => {

 
        // logger('savePackage executed', 'info', { email: this.props.store.get('email') })


        let allowedtoSave = true;

        // //make sure no empty name and desc fields!
        this.props.values.languages.map((language, index) => {
            const fldPackageNameLng = 'fldPackageName_' + language;
            const fldPackageDescriptionLng = 'fldPackageDescription_' + language;
            if ((this.state[fldPackageNameLng] === '') || (this.state[fldPackageDescriptionLng] === '')) {
                setTimeout(() => { this.setState({ errorMsg: `Package name and description fields cannot be left empty`, }); }, 50);
                allowedtoSave = false;
            }
            return null
        })


        // Iterate through all packages, and make sure all packages can have only one currency used. 
        // Tell in the message that for the end user it can be converted, but prices can be set in one currency only.
        for (var i = this.props.values.packages.length-1; i >= 0; i--) {
            if (this.props.values.packages[i].currency !== this.state.selectedPackageCurrency) { 
                setTimeout(() => { this.setState({ errorMsg: `Please use only one currency for all your package prices. We will convert your price to the preferred currency of the user automatically.`, }); }, 50);
                allowedtoSave = false;
            } 
        }


        if (allowedtoSave) {

            var tempuuid = uuidv4();
            
            var lngNames = []
            var lngDescriptions = []
            await this.props.values.languages.map((language, index) => {
                const fldPackageNameLng = 'fldPackageName_' + language;
                const fldPackageDescriptionLng = 'fldPackageDescription_' + language;
                lngNames.push({ 
                    lng: language,
                    name: this.state[fldPackageNameLng]
                })
                lngDescriptions.push({ 
                    lng: language,
                    description: this.state[fldPackageDescriptionLng]
                })
                return null

            })

            if (this.state.selectedPackageId === '%%-----## -!new-package!- ##------%%') {
                
                var arr = this.props.values.packages;
                
                await arr.push({
                    id: tempuuid, // generate our own id (as db id is generated on save, but we might not save the album yet), until the album is saved to the database.
                    name: lngNames,
                    price: parseFloat(this.state.selectedPackagePrice),
                    currency: this.state.selectedPackageCurrency,
                    duration: parseInt(this.state.selectedPackageDuration),
                    cancelAfter: parseInt(this.state.selectedPackageCancelAfter),
                    cancelBefore: parseInt(this.state.selectedPackageCancelBefore),
                    lateCancellationKeeps: parseInt(this.state.lateCancellationKeeps),
                    noCancellationsAfter: parseInt(this.state.noCancellationsAfter),
                    travel: this.state.selectedPackageTravel,
                    travelDistance: parseInt(this.state.selectedPackageTravelDistance),
                    travelDistanceMode: this.state.selectedPackageTravelDistanceMode,
                    travelDistanceKm: this.state.selectedPackageTravelDistanceKm,
                    instantBooking: this.state.selectedPackageInstantBooking,
                    limitToDays: this.state.selectedPackageLimitToDays,
                    limitToMon: this.state.selectedPackageLimitToMon,
                    limitToTue: this.state.selectedPackageLimitToTue,
                    limitToWed: this.state.selectedPackageLimitToWed, 
                    limitToThu: this.state.selectedPackageLimitToThu,
                    limitToFri: this.state.selectedPackageLimitToFri,
                    limitToSat: this.state.selectedPackageLimitToSat,
                    limitToSun: this.state.selectedPackageLimitToSun, 
                    limitToTimeframe: this.state.selectedPackageLimitToTimeframe,
                    limitToTimeFrom: this.state.selectedPackageLimitToTimeFrom,
                    limitToTimeTo: this.state.selectedPackageLimitToTimeTo,
                    limitToDate:this.state.selectedPackageLimitToDates,
                    limitToDateFrom: this.state.selectedPackagelimitToDateFrom,
                    limitToDateTo: this.state.selectedPackagelimitToDateTo,
                    description: lngDescriptions,
                    availability: this.state.selectedPackageAvailability,
                })

                this.props.values.packages = arr;
                
            } else {

                for (var o = this.props.values.packages.length-1; o >= 0; o--) {
                    if (this.props.values.packages[o].id === this.state.selectedPackageId) { 
                        tempuuid = this.props.values.packages[o].id;
                        this.props.values.packages[o].name = lngNames;
                        this.props.values.packages[o].price = parseFloat(this.state.selectedPackagePrice);
                        this.props.values.packages[o].currency = this.state.selectedPackageCurrency;
                        this.props.values.packages[o].duration = parseInt(this.state.selectedPackageDuration);
                        this.props.values.packages[o].cancelAfter = parseInt(this.state.selectedPackageCancelAfter);
                        this.props.values.packages[o].cancelBefore = parseInt(this.state.selectedPackageCancelBefore);
                        this.props.values.packages[o].lateCancellationKeeps = parseInt(this.state.lateCancellationKeeps);
                        this.props.values.packages[o].noCancellationsAfter = parseInt(this.state.noCancellationsAfter);
                        this.props.values.packages[o].travel = this.state.selectedPackageTravel;
                        this.props.values.packages[o].travelDistance = parseInt(this.state.selectedPackageTravelDistance);
                        this.props.values.packages[o].travelDistanceMode = this.state.selectedPackageTravelDistanceMode;
                        this.props.values.packages[o].travelDistanceKm = this.state.selectedPackageTravelDistanceKm;
                        this.props.values.packages[o].instantBooking = this.state.selectedPackageInstantBooking;
                        this.props.values.packages[o].limitToDays = this.state.selectedPackageLimitToDays;
                        this.props.values.packages[o].limitToMon = this.state.selectedPackageLimitToMon;
                        this.props.values.packages[o].limitToTue = this.state.selectedPackageLimitToTue;
                        this.props.values.packages[o].limitToWed = this.state.selectedPackageLimitToWed; 
                        this.props.values.packages[o].limitToThu = this.state.selectedPackageLimitToThu;
                        this.props.values.packages[o].limitToFri = this.state.selectedPackageLimitToFri;
                        this.props.values.packages[o].limitToSat = this.state.selectedPackageLimitToSat;
                        this.props.values.packages[o].limitToSun = this.state.selectedPackageLimitToSun;
                        this.props.values.packages[o].limitToTimeframe = this.state.selectedPackageLimitToTimeframe;
                        this.props.values.packages[o].limitToTimeFrom = this.state.selectedPackageLimitToTimeFrom;
                        this.props.values.packages[o].limitToTimeTo = this.state.selectedPackageLimitToTimeTo;
                        this.props.values.packages[o].limitToDate =this.state.selectedPackageLimitToDates;
                        this.props.values.packages[o].limitToDateFrom = this.state.selectedPackagelimitToDateFrom;
                        this.props.values.packages[o].limitToDateTo = this.state.selectedPackagelimitToDateTo;
                        this.props.values.packages[o].description = lngDescriptions;
                        this.props.values.packages[o].availability = this.state.selectedPackageAvailability;

                    } 
                }

            }

            this.setState({
                activeView: 'packageList',
                selectedPackage: '',
                selectedPackageId: '',
                errorMsg: '',
            });

            
            this.props.showSaveButton(true);

            this.forceUpdate();
            this.updatePackages();

        }


    }

    cancelPackageEdit = () => {

        this.setState({
            activeView: 'packageList',
            selectedPackage: '',
            selectedPackageId: '',
            errorMsg: '',
        });

        this.props.showSaveButton(true);
        this.forceUpdate();

    }

    deletePackage = (deletePackageId) => {

        var packagesArray = this.props.values.packages;

        var packageRemovedArray = packagesArray.filter(function(pakett) { return pakett.id !== deletePackageId });

        this.props.values.packages = packageRemovedArray;

        this.setState({  
            selectedPackageConfirmDelete: false,
            activeView: 'packageList',
            selectedPackage: '',
            selectedPackageId: '',
        });

        this.props.showSaveButton(true);

        this.forceUpdate();
        this.updatePackages();

    };


    updatePackages = () => {

        // some cleanup needed for Graphql to prevent model match errors
        var tempArray = this.props.values.packages;
        for (var z = tempArray.length-1; z >= 0; z--) {
            delete tempArray[z].__typename
            for (var x = tempArray[z].name.length-1; x >= 0; x--) {
                delete tempArray[z].name[x].__typename
            }  
            for (var y = tempArray[z].description.length-1; y >= 0; y--) {
                delete tempArray[z].description[y].__typename
            }                                                
        }

        const inputValue = {
            packages: tempArray
        }

        this.props.client.mutate({
            mutation: editPhotog,
            variables: { profileId: this.props.values.id, input: inputValue },
        }).then(results => {
            //console.log('# updatePackages result: ', results)
        }).catch(error => {
            console.log("Error: ", error);
        })


    } 

    movePackageUp = (index) => {

        let arr = this.props.values.packages;
        [arr[index],arr[index-1]] = [arr[index-1],arr[index]]; 
        this.props.values.packages = arr

        this.forceUpdate();
        this.updatePackages();

    };

    movePackageDown = (index) => {

        let arr = this.props.values.packages;
        [arr[index],arr[index+1]] = [arr[index+1],arr[index]]; 
        this.props.values.packages = arr

        this.forceUpdate();
        this.updatePackages();

    };

    togglePackageConfirmDelete = () => {  

        this.setState({  
            selectedPackageConfirmDelete: !this.state.selectedPackageConfirmDelete 
        });  

    }  


    currencyOptions = () => {
        return [
            { value: '0', code: 'AUD', label: 'Australian dollar' },
            { value: '1', code: 'BRL', label: 'Brazilian real' },
            { value: '2', code: 'BGN', label: 'Bulgarian lev' },
            { value: '3', code: 'CAD', label: 'Canadian dollar' },
            { value: '4', code: 'CLP', label: 'Chilean peso' },
            { value: '5', code: 'CNY', label: 'Chinese yuan' },
            { value: '6', code: 'COP', label: 'Colombian peso' },
            { value: '7', code: 'CRC', label: 'Costa Rican colon' },
            { value: '8', code: 'HRK', label: 'Croatian kuna' },
            { value: '9', code: 'CZK', label: 'Czech koruna' },
            { value: '10', code: 'DKK', label: 'Danish krone' },
            { value: '11', code: 'AED', label: 'Emirati dirham' },
            { value: '12', code: 'EUR', label: 'Euro' },
            { value: '13', code: 'HKD', label: 'Hong Kong dollar' },
            { value: '14', code: 'HUF', label: 'Hungarian forint' },
            { value: '15', code: 'INR', label: 'Indian rupee' },
            { value: '16', code: 'ILS', label: 'Israeli new shekel' },
            { value: '17', code: 'JPY', label: 'Japanese yen' },
            { value: '18', code: 'MYR', label: 'Malaysian ringgit' },
            { value: '19', code: 'MXN', label: 'Mexican peso' },
            { value: '20', code: 'MAD', label: 'Moroccan dirham' },
            { value: '21', code: 'TWD', label: 'New Taiwan dollar' },
            { value: '22', code: 'NZD', label: 'New Zealand dollar' },
            { value: '23', code: 'NOK', label: 'Norwegian krone' },
            { value: '24', code: 'PEN', label: 'Peruvian sol' },
            { value: '25', code: 'PHP', label: 'Philippine peso' },
            { value: '26', code: 'PLN', label: 'Polish zloty' },
            { value: '27', code: 'GBP', label: 'Pound sterling' },
            { value: '28', code: 'RON', label: 'Romanian leu' },
            { value: '29', code: 'RUB', label: 'Russian ruble' },
            { value: '30', code: 'SAR', label: 'Saudi Arabian riyal' },
            { value: '31', code: 'SGD', label: 'Singapore dollar' },
            { value: '32', code: 'ZAR', label: 'South African rand' },
            { value: '33', code: 'KRW', label: 'South Korean won' },
            { value: '34', code: 'SEK', label: 'Swedish krona' },
            { value: '35', code: 'CHF', label: 'Swiss franc' },
            { value: '36', code: 'THB', label: 'Thai baht' },
            { value: '37', code: 'TRY', label: 'Turkish lira' },
            { value: '38', code: 'USD', label: 'United States US dollar' },
            { value: '39', code: 'UYU', label: 'Uruguayan peso' },
        ]
    }

    durationOptions = () => {
        return [
            { value: '15', code: '15', label: '15 min' },
            { value: '20', code: '20', label: '20 min' },
            { value: '30', code: '30', label: '30 min' },
            { value: '45', code: '45', label: '45 min' },
            { value: '60', code: '60', label: '1 hour' },
            { value: '90', code: '90', label: '1.5 hours' },
            { value: '120', code: '120', label: '2 hours' },
            { value: '150', code: '150', label: '2.5 hours' },
            { value: '180', code: '180', label: '3 hours' },
            { value: '210', code: '210', label: '3.5 hours' },
            { value: '240', code: '240', label: '4 hours' },
            { value: '300', code: '300', label: '5 hours' },
            { value: '360', code: '360', label: '6 hours' },
            { value: '420', code: '420', label: '7 hours' },
            { value: '480', code: '480', label: '8 hours' },
            { value: '540', code: '540', label: '9 hours' },
            { value: '600', code: '600', label: '10 hours' },
            { value: '660', code: '660', label: '11 hours' },
            { value: '720', code: '720', label: '12 hours' },
            { value: '1440', code: '1440', label: 'All day coverage' },
        ]
    }

    cancelOptions = () => {
        return [
            { value: '1', code: '1', label: '24 hours' },
            { value: '2', code: '2', label: '2 days' },
            { value: '3', code: '3', label: '3 days' },
            { value: '5', code: '5', label: '5 days' },
            { value: '7', code: '7', label: '7 days' },
        ]
    }

    cancelOptions2 = () => {
        return [
            { value: '3', code: '3', label: '3 days' },
            { value: '5', code: '5', label: '5 days' },
            { value: '7', code: '7', label: '7 days' },
            { value: '14', code: '14', label: '2 weeks' },
            { value: '28', code: '28', label: '4 weeks' },
            { value: '60', code: '60', label: '2 months' },
            { value: '90', code: '90', label: '3 months' },
        ]
    }

    cancelOptions3 = () => {
        return [
            { value: '10', code: '10', label: '10%' },
            { value: '20', code: '20', label: '20%' },
            { value: '25', code: '25', label: '25%' },
            { value: '30', code: '30', label: '30%' },
            { value: '35', code: '35', label: '35%' },
            { value: '40', code: '40', label: '40%' },
        ]
    }

    cancelOptions4 = () => {
        return [
            { value: '24', code: '24', label: '24 hours' },
            { value: '48', code: '48', label: '48 hours' },
            { value: '72', code: '72', label: '72 hours' },
        ]
    }


    timeOptions = (use24h) => {

        if (use24h) {
            return [
                { value: '0', code: '00:00', label: '00:00' },
                { value: '1', code: '00:30', label: '00:30' },
                { value: '2', code: '01:00', label: '01:00' },
                { value: '3', code: '01:30', label: '01:30' },
                { value: '4', code: '02:00', label: '02:00' },
                { value: '5', code: '02:30', label: '02:30' },
                { value: '6', code: '03:00', label: '03:00' },
                { value: '7', code: '03:30', label: '03:30' },
                { value: '8', code: '04:00', label: '04:00' },
                { value: '9', code: '04:30', label: '04:30' },
                { value: '10', code: '05:00', label: '05:00' },
                { value: '11', code: '05:30', label: '05:30' },
                { value: '12', code: '06:00', label: '06:00' },
                { value: '13', code: '06:30', label: '06:30' },
                { value: '14', code: '07:00', label: '07:00' },
                { value: '15', code: '07:30', label: '07:30' },
                { value: '16', code: '08:00', label: '08:00' },
                { value: '17', code: '08:30', label: '08:30' },
                { value: '18', code: '09:00', label: '09:00' },
                { value: '19', code: '09:30', label: '09:30' },
                { value: '20', code: '10:00', label: '10:00' },
                { value: '21', code: '10:30', label: '10:30' },
                { value: '22', code: '11:00', label: '11:00' },
                { value: '23', code: '11:30', label: '11:30' },
                { value: '24', code: '12:00', label: '12:00' },
                { value: '25', code: '12:30', label: '12:30' },
                { value: '26', code: '13:00', label: '13:00' },
                { value: '27', code: '13:30', label: '13:30' },
                { value: '28', code: '14:00', label: '14:00' },
                { value: '29', code: '14:30', label: '14:30' },
                { value: '30', code: '15:00', label: '15:00' },
                { value: '31', code: '15:30', label: '15:30' },
                { value: '32', code: '16:00', label: '16:00' },
                { value: '33', code: '16:30', label: '16:30' },
                { value: '34', code: '17:00', label: '17:00' },
                { value: '35', code: '17:30', label: '17:30' },
                { value: '36', code: '18:00', label: '18:00' },
                { value: '37', code: '18:30', label: '18:30' },
                { value: '38', code: '19:00', label: '19:00' },
                { value: '39', code: '19:30', label: '19:30' },
                { value: '40', code: '20:00', label: '20:00' },
                { value: '41', code: '20:30', label: '20:30' },
                { value: '42', code: '21:00', label: '21:00' },
                { value: '43', code: '21:30', label: '21:30' },
                { value: '44', code: '22:00', label: '22:00' },
                { value: '45', code: '22:30', label: '22:30' },
                { value: '46', code: '23:00', label: '23:00' },
                { value: '47', code: '23:30', label: '23:30' },
            ]
        } else {
            return [
                { value: '0', code: '00:00', label: '12:00 AM' },
                { value: '1', code: '00:30', label: '12:30 AM' },
                { value: '2', code: '01:00', label: '01:00 AM' },
                { value: '3', code: '01:30', label: '01:30 AM' },
                { value: '4', code: '02:00', label: '02:00 AM' },
                { value: '5', code: '02:30', label: '02:30 AM' },
                { value: '6', code: '03:00', label: '03:00 AM' },
                { value: '7', code: '03:30', label: '03:30 AM' },
                { value: '8', code: '04:00', label: '04:00 AM' },
                { value: '9', code: '04:30', label: '04:30 AM' },
                { value: '10', code: '05:00', label: '05:00 AM' },
                { value: '11', code: '05:30', label: '05:30 AM' },
                { value: '12', code: '06:00', label: '06:00 AM' },
                { value: '13', code: '06:30', label: '06:30 AM' },
                { value: '14', code: '07:00', label: '07:00 AM' },
                { value: '15', code: '07:30', label: '07:30 AM' },
                { value: '16', code: '08:00', label: '08:00 AM' },
                { value: '17', code: '08:30', label: '08:30 AM' },
                { value: '18', code: '09:00', label: '09:00 AM' },
                { value: '19', code: '09:30', label: '09:30 AM' },
                { value: '20', code: '10:00', label: '10:00 AM' },
                { value: '21', code: '10:30', label: '10:30 AM' },
                { value: '22', code: '11:00', label: '11:00 AM' },
                { value: '23', code: '11:30', label: '11:30 AM' },
                { value: '24', code: '12:00', label: '12:00 PM' },
                { value: '25', code: '12:30', label: '12:30 PM' },
                { value: '26', code: '13:00', label: '01:00 PM' },
                { value: '27', code: '13:30', label: '01:30 PM' },
                { value: '28', code: '14:00', label: '02:00 PM' },
                { value: '29', code: '14:30', label: '02:30 PM' },
                { value: '30', code: '15:00', label: '03:00 PM' },
                { value: '31', code: '15:30', label: '03:30 PM' },
                { value: '32', code: '16:00', label: '04:00 PM' },
                { value: '33', code: '16:30', label: '04:30 PM' },
                { value: '34', code: '17:00', label: '05:00 PM' },
                { value: '35', code: '17:30', label: '05:30 PM' },
                { value: '36', code: '18:00', label: '06:00 PM' },
                { value: '37', code: '18:30', label: '06:30 PM' },
                { value: '38', code: '19:00', label: '07:00 PM' },
                { value: '39', code: '19:30', label: '07:30 PM' },
                { value: '40', code: '20:00', label: '08:00 PM' },
                { value: '41', code: '20:30', label: '08:30 PM' },
                { value: '42', code: '21:00', label: '09:00 PM' },
                { value: '43', code: '21:30', label: '09:30 PM' },
                { value: '44', code: '22:00', label: '10:00 PM' },
                { value: '45', code: '22:30', label: '10:30 PM' },
                { value: '46', code: '23:00', label: '11:00 PM' },
                { value: '47', code: '23:30', label: '11:30 PM' },
            ]
        }
    }
    

    handleWeekdayLimits = (event, newLimits) => {

        setTimeout(() => {
            this.setState({  
                selectedPackageLimitToDaysSelectedArr: newLimits,
                selectedPackageLimitToMon: false,
                selectedPackageLimitToTue: false,
                selectedPackageLimitToWed: false,
                selectedPackageLimitToThu: false,
                selectedPackageLimitToFri: false,
                selectedPackageLimitToSat: false,
                selectedPackageLimitToSun: false,
            }); 

            for (var i = this.state.selectedPackageLimitToDaysSelectedArr.length-1; i >= 0; i--) {
                const fldName = 'selectedPackageLimitTo' + this.state.selectedPackageLimitToDaysSelectedArr[i]
                this.setState({ [fldName]: true });
            }

        }, 50);

    };

    localeUses24HourTime = (locale) => {

        // if locale === undefined => current user's locale, otherwise uses "locale"

        //returns true, if 24H time is used in this locale/language
        return new Intl.DateTimeFormat(locale, { hour: 'numeric' })
            .formatToParts(new Date(2020, 0, 1, 13))
            .find(part => part.type === 'hour')
            .value.length === 2;

    }
      


    render() {

        const availabilityArr = [
            { value: 0, code: 0, label: 'Not available at this moment' },
            { value: 1, code: 1, label: 'Available to everyone' },
            { value: 2, code: 2, label: 'Only for members with an active profile' },
            { value: 3, code: 3, label: 'Only for Pro members with an active profile' },
            { value: 4, code: 4, label: 'Only for Verified Pro members with an active profile' },
        ];

        
        const distanceOptions = [
            { value: '0', label: 'km', disabled: this.state.selectedPackageTravel === false },
            { value: '1', label: 'miles', disabled: this.state.selectedPackageTravel === false },
        ];

        moment.locale(i18next.language);
        const startOfWeek = moment().startOf('week').isoWeekday();  // const endOfWeek   = moment().endOf('week').isoWeekday();
        // let weekdayArr = []
        //             if (startOfWeek === 1) { 
        //                 weekdayArr = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        //             } else {
        //                 weekdayArr = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        //             }

        // const use24h = this.localeUses24HourTime(); 

        

        return (

            <PageContent>

                
                {
                    this.state.activeView === "packageList" ? 
                        (
                            <>
                            <PageTitle>Packages</PageTitle>

                            <LinkButton onClick={this.addPackage} >
                                Add new package 
                            </LinkButton>

                            <IncreaseHeight/>


                            <PackageCards>
                            { 
                                this.props.values.packages.map((pakett, index) => {

                                    let lngNames = '';
                                    let lngDescriptions = '';
                                    for (var i = this.props.values.packages.length-1; i >= 0; i--) {
                                        if (this.props.values.packages[i].id === pakett.id) { 
                                            lngNames = this.props.values.packages[i].name
                                            lngDescriptions = this.props.values.packages[i].description
                                        } 
                                    }

                                    const packageDetails = {
                                        id: pakett.id,
                                        name: lngNames,
                                        price: pakett.price,
                                        currency: pakett.currency,
                                        duration: pakett.duration,
                                        cancelAfter: pakett.cancelAfter,
                                        cancelBefore: pakett.cancelBefore,
                                        lateCancellationKeeps: pakett.lateCancellationKeeps,
                                        noCancellationsAfter: pakett.noCancellationsAfter,
                                        travel: pakett.travel,
                                        travelDistance: pakett.travelDistance,
                                        travelDistanceMode: pakett.travelDistanceMode,
                                        travelDistanceKm: pakett.travelDistanceKm,
                                        instantBooking: pakett.instantBooking,
                                        limitToDays: pakett.limitToDays,
                                        limitToMon: pakett.limitToMon,
                                        limitToTue: pakett.limitToTue,
                                        limitToWed: pakett.limitToWed,
                                        limitToThu: pakett.limitToThu,
                                        limitToFri: pakett.limitToFri,
                                        limitToSat: pakett.limitToSat,
                                        limitToSun: pakett.limitToSun,
                                        limitToTimeframe: pakett.limitToTimeframe,
                                        limitToTimeFrom: pakett.limitToTimeFrom,
                                        limitToTimeTo: pakett.limitToTimeTo,
                                        limitToDate: pakett.limitToDate,
                                        limitToDateFrom: pakett.limitToDateFrom,
                                        limitToDateTo: pakett.limitToDateTo,
                                        description: lngDescriptions,
                                        availability: pakett.availability,

                                    }

                                    let weekdayLimitString = ''
                                    if (pakett.limitToDays) {
                                        if (startOfWeek === 1) {
                                            if (pakett.limitToMon) { weekdayLimitString = weekdayLimitString+ 'Mon ' }
                                            if (pakett.limitToTue) { weekdayLimitString = weekdayLimitString + 'Tue ' }
                                            if (pakett.limitToWed) { weekdayLimitString = weekdayLimitString + 'Wed ' }
                                            if (pakett.limitToThu) { weekdayLimitString = weekdayLimitString + 'Thu ' }
                                            if (pakett.limitToFri) { weekdayLimitString = weekdayLimitString + 'Fri ' }
                                            if (pakett.limitToSat) { weekdayLimitString = weekdayLimitString + 'Sat ' }
                                            if (pakett.limitToSun) { weekdayLimitString = weekdayLimitString + 'Sun ' }
                                        } else {
                                            if (pakett.limitToSun) { weekdayLimitString = weekdayLimitString + 'Sun ' }
                                            if (pakett.limitToMon) { weekdayLimitString = weekdayLimitString + 'Mon ' }
                                            if (pakett.limitToTue) { weekdayLimitString = weekdayLimitString + 'Tue ' }
                                            if (pakett.limitToWed) { weekdayLimitString = weekdayLimitString + 'Wed ' }
                                            if (pakett.limitToThu) { weekdayLimitString = weekdayLimitString + 'Thu ' }
                                            if (pakett.limitToFri) { weekdayLimitString = weekdayLimitString + 'Fri ' }
                                            if (pakett.limitToSat) { weekdayLimitString = weekdayLimitString + 'Sat ' }
                                        }

                                    }


                                    return (

                                        <PackageCard 
                                            key={index}
                                            >

                                            <GreyButtons>
                                                { 
                                                    index === 0 ? <>&nbsp;</> :
                                                        <GreyButton onClick={() => this.movePackageUp(index)} disabled={ index === 0 ? true : false} >
                                                            <FAIcon icon={faChevronUp} color={ index === 0 ? '#fff' : '#cbcbcb'} />
                                                        </GreyButton>
                                                }
                                                { 
                                                    index === this.props.values.packages.length-1 ? <></> :
                                                        <GreyButton onClick={() => this.movePackageDown(index)} disabled={ index === this.props.values.packages.length-1 ? true : false} >
                                                            <FAIcon icon={faChevronDown} color={ index === this.props.values.packages.length-1 ? '#fff' : '#cbcbcb'} />
                                                        </GreyButton>
                                                }
                                            </GreyButtons>

                                            <PackageCardClickOverlay onClick={ () => { this.editPackageDetails(packageDetails); } } ></PackageCardClickOverlay>

                                            <PackageCardDuration onClick={ () => { this.editPackageDetails(packageDetails); } } >
                                                { this.durationOptions().find(op => { return op.code === pakett.duration.toString() }).label }
                                            </PackageCardDuration> 

                                            <PackageCardPrice onClick={ () => { this.editPackageDetails(packageDetails); } } >
                                                { parseFloat(pakett.price).toFixed(2) + ' ' + pakett.currency }
                                            </PackageCardPrice> 

                                            <PackageCardName onClick={ () => { this.editPackageDetails(packageDetails); } } >
                                                { pakett.name[0].name }
                                            </PackageCardName>

                                            <PackageCardDescription onClick={ () => { this.editPackageDetails(packageDetails); } } >
                                                { pakett.description[0].description }
                                            </PackageCardDescription> 

                                            { 
                                                pakett.limitToDays || pakett.limitToTimeframe ? 
                                                    <PackageCardRestrictions onClick={ () => { this.editPackageDetails(packageDetails); } } >
                                                        { 
                                                            pakett.limitToDays ? 
                                                                weekdayLimitString
                                                            : null 
                                                        }
                                                        { 
                                                            pakett.limitToTimeframe ? 

                                                                pakett.limitToDays ?
                                                                    '(' + pakett.limitToTimeFrom + ' - ' + pakett.limitToTimeTo + ')'
                                                                :
                                                                    pakett.limitToTimeFrom + ' - ' + pakett.limitToTimeTo

                                                            : null 
                                                        }
                                                    </PackageCardRestrictions> 
                                                    : null
                                            } 

                                            { 
                                                pakett.limitToDate ? 
                                                    <PackageCardRestrictions2 onClick={ () => { this.editPackageDetails(packageDetails); } } >
                                                        { 
                                                            pakett.limitToDateFrom === pakett.limitToDateTo ? 
                                                                moment(pakett.limitToDateFrom).locale(i18next.language).format("LL") 
                                                                : null
                                                        }
                                                        { 
                                                            pakett.limitToDateFrom !== pakett.limitToDateTo ?
                                                                moment(pakett.limitToDateFrom).locale(i18next.language).format("LL") + ' - ' + moment(pakett.limitToDateTo).locale(i18next.language).format("LL")
                                                                : null 
                                                        }
                                                    </PackageCardRestrictions2> 
                                                    : null
                                            
                                            } 

                                            <PackageCardAvailability 
                                                onClick={ () => { this.editPackageDetails(packageDetails); } } 
                                                availabilityCode={availabilityArr[pakett.availability].code}
                                                >
                                                { availabilityArr[pakett.availability].label }
                                            </PackageCardAvailability> 


                                        </PackageCard> 
                                        
                                    );
                                    
                                })
                            }
                            </PackageCards>

                            
                            <IncreaseHeight/>
                            <IncreaseHeight/>
                            
                            </>
                        )
                    :
                    this.state.activeView === "packageAddEdit" ? 
                        (
                            <>
                           
                            { this.state.selectedPackageId === '%%-----## -!new-package!- ##------%%' ? 
                                <PageTitle showAlways='true'>Add new package</PageTitle> 
                                : 
                                <PageTitle showAlways='true'>Edit package details</PageTitle> 
                            }
                            
                            
                            { // generate Album Name fields for each language
                                this.props.values.languages.map((language, index) => {
                                    
                                    const fldPackageNameLng = 'fldPackageName_' + language;

                                    return (
                                        <div key={index}>
                                        
                                        <LabelStyled>Package name {this.props.values.languages.length > 1 ? '(' + this.capitalize(ISO6391.getNativeName(language))+')' : '' }</LabelStyled>
                                        <InputStyled
                                            name={fldPackageNameLng}
                                            autoCapitalize = 'none'
                                            autoComplete = 'off'
                                            defaultValue={this.state[fldPackageNameLng]}
                                            onChange={this.fldValueChange}
                                        />
                                       
                                        <IncreaseHeight/>
                                        </div> 
                                    );
                                })
                            }


                            <ColumnWrapper>
                                <ColItem1>
                                    <IncreaseHeight/>
                                    <LabelStyled>Price:</LabelStyled>
                                    <InputNumberStyled 
                                        name='selectedPackagePrice'
                                        defaultValue={this.state.selectedPackagePrice} 
                                        //style={{ border: '1px solid #EBE' }}
                                        onChange={(value) => this.numberFieldChange('selectedPackagePrice', value)}
                                        precision={2}
                                        type="number"
                                    />
                                   
                                </ColItem1>
                                
                                <ColItem2>
                                    <IncreaseHeight/>
                                    <LabelStyled>Currency:</LabelStyled>
                                    <Select 
                                        name='selectedPackageCurrency'
                                        classNamePrefix="select"
                                        styles={colourStyles}
                                        options={this.currencyOptions()}
                                        value={this.currencyOptions().find(op => { return op.code === this.state.selectedPackageCurrency })}
                                        defaultValue={this.currencyOptions().find(op => { return op.code === this.props.store.get('currency') })}
                                        isSearchable={true}
                                        onChange={(e) => { setTimeout(() => { this.setState({ selectedPackageCurrency: e.code, }); }, 50) }}
                                    />
                                </ColItem2>
                            </ColumnWrapper>

                            { this.state.infoAbout0Price ? 
                                ( <>
                                    <IncreaseHeight/> 
                                    <PriceInfo>
                                        When the price is set to 0, this package qualifies to accept free photoshoot requests (known as TFP, time for photos, etc). You can set, however, who can see this package.
                                    </PriceInfo>
                                </>
                                ) : null 
                            }

                            <IncreaseHeight/>
                            <IncreaseHeight/>
                            <ColumnWrapper>
                                <ColItem1>
                                    <LabelStyled>Duration:</LabelStyled>
                                    <Select 
                                        name='selectedPackageDuration'
                                        classNamePrefix="select"
                                        styles={colourStyles}
                                        options={this.durationOptions()}
                                        value={this.durationOptions().find(op => { return op.code === this.state.selectedPackageDuration.toString() })}
                                        defaultValue={this.durationOptions().find(op => { return op.code === this.state.selectedPackageDuration.toString() })}
                                        isSearchable={true}
                                        onChange={(e) => { setTimeout(() => { this.setState({ selectedPackageDuration: e.code, }); }, 50) }}
                                    />
                                </ColItem1>
                                <>
                                    
                                </>
                            </ColumnWrapper>


                            <IncreaseHeight/>
                            <IncreaseHeight/>
                            { 
                                this.props.values.languages.map((language, index) => {
                                    
                                    const fldPackageDescriptionLng = 'fldPackageDescription_' + language;

                                    return (
                                        <div key={index}>
                                        
                                        <LabelStyled>Description and delivery info {this.props.values.languages.length > 1 ? '(' + this.capitalize(ISO6391.getNativeName(language))+')' : '' }</LabelStyled>
                                        <TextareaStyled
                                            name={fldPackageDescriptionLng}
                                            value={this.state[fldPackageDescriptionLng]}
                                            onChange={this.fldValueChange}
                                            rows={5}
                                            // cols={5}
                                        />
                                        
                                        <IncreaseHeight/>
                                        </div> 
                                    );
                                })
                            }


                            
                            <IncreaseHeight/>
                            <FormControlLabel 
                                control={
                                    <OrangeCheckBox
                                        name="packageTravel"
                                        checked={this.state.selectedPackageTravel}
                                        onChange={(e) => { 
                                            const staatus = e.target.checked
                                            setTimeout(() => { this.setState({ selectedPackageTravel: staatus }); }, 50) 
                                            }}
                                        value="travel"
                                    />
                                }
                            label={'Willing to travel (up to)'}
                            />     

                            <IndentRows>
                                <ColItem1b>
                                
                                    <InputStyled 
                                        name='selectedPackageTravelDistance'
                                        autoCapitalize = 'none'
                                        autoComplete = 'off'
                                        disabled={this.state.selectedPackageTravel === false}
                                        type="number" 
                                        min="0" 
                                        step="1"
                                        // oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                        // oninput="validity.valid||(value='');"
                                        defaultValue={this.state.selectedPackageTravelDistance}
                                        onChange={this.fldValueChange}
                                    />

                                </ColItem1b>
                                <ColItem2>
                                    <RadioButtonGroup
                                        id="selectedPackageTravelDistanceMode"
                                        options={distanceOptions}
                                        value={this.state.selectedPackageTravelDistanceMode}
                                        onChange={ (event) => {
                                            const newModeValue = event.target.value;
                                            const distanceMilesToKm = (this.state.selectedPackageTravelDistance * 1.609)
                                            if (newModeValue === '1') {
                                                setTimeout(() => { 
                                                    this.setState({ 
                                                        selectedPackageTravelDistanceMode: newModeValue,
                                                        selectedPackageTravelDistanceKm: distanceMilesToKm,
                                                    }); 
                                                }, 50) 
                                            } else {
                                                setTimeout(() => { 
                                                    this.setState({ 
                                                        selectedPackageTravelDistanceMode: newModeValue,
                                                        selectedPackageTravelDistanceKm: this.state.selectedPackageTravelDistance,
                                                    }); 
                                                }, 50) 
                                            }
                                        }}
                                        //size='small'
                                    />
                                </ColItem2>
                            </IndentRows>
                            

                            <IncreaseHeight/>
                            <IncreaseHeight/>
                            <IncreaseHeight/>
                            
                            <TopMargin16>
                                <FormControlLabel 
                                    control={
                                        <OrangeCheckBox
                                            name='instantBooking'
                                            checked={this.state.selectedPackageInstantBooking}
                                            onChange={(e) => { 
                                                const staatus = e.target.checked
                                                setTimeout(() => { this.setState({ selectedPackageInstantBooking: staatus }); }, 50) 
                                                }}
                                            value="booking"
                                        />
                                    }
                                label={'Instant booking possible (if available time is found based on your calendar)'}
                                />     
                            </TopMargin16>



                            {/* <IndentRows>

                                <FormControlLabel 
                                    control={
                                        <OrangeCheckBox
                                            name='limitToDays'
                                            disabled={this.state.selectedPackageInstantBooking === false}
                                            checked={this.state.selectedPackageLimitToDays}
                                            onChange={(e) => { 
                                                const staatus = e.target.checked
                                                setTimeout(() => { this.setState({ selectedPackageLimitToDays: staatus }); }, 50) 
                                                }}
                                            value="booking"
                                        />
                                    }
                                label={'Make the package availabile only on specific days:'}
                                />     

                                <IndentRows>
                                    <ColumnWrapper>
                                        <OrangeButtonGroup
                                        value={this.state.selectedPackageLimitToDaysSelectedArr}
                                        onChange={this.handleWeekdayLimits}
                                        disabled={ this.state.selectedPackageInstantBooking === false || this.state.selectedPackageLimitToDays === false }
                                        aria-label="Days"
                                    >
                                    { 
                                        weekdayArr.map((day, index) => {
                                            return (
                                                <OrangeToggleButton 
                                                    size="small"
                                                    key={index}
                                                    value={day} 
                                                    aria-label={day}
                                                    disabled={ this.state.selectedPackageInstantBooking === false || this.state.selectedPackageLimitToDays === false }
                                                >
                                                    {day}
                                                </OrangeToggleButton>
                                            );
                                        })
                                    }
                                    </OrangeButtonGroup>
                                    </ColumnWrapper>
                                </IndentRows>


                                <IncreaseHeight/>
                                <FormControlLabel 
                                    control={
                                        <OrangeCheckBox
                                            name='limitToTimeframe'
                                            disabled={this.state.selectedPackageInstantBooking === false}
                                            checked={this.state.selectedPackageLimitToTimeframe}
                                            onChange={(e) => { 
                                                const staatus = e.target.checked
                                                setTimeout(() => { this.setState({ selectedPackageLimitToTimeframe: staatus }); }, 50) 
                                                }}
                                            value="timeframe"
                                        />
                                    }
                                label={'Make the package availabile only for this time:'}
                                />     
                                    <IndentRows>

                                        <Container>
                                        <ColumnWrapper>
                                            <ColItem2c>
                                                <LabelStyled>From:</LabelStyled>
                                                <Select 
                                                    name='selectedPackageLimitToTimeFrom'
                                                    isDisabled={ this.state.selectedPackageInstantBooking === false || this.state.selectedPackageLimitToTimeframe === false }
                                                    classNamePrefix="select"
                                                    styles={colourStyles}
                                                    options={this.timeOptions(use24h)}
                                                    value={this.timeOptions(use24h).find(op => { return op.code === this.state.selectedPackageLimitToTimeFrom })}
                                                    defaultValue={this.timeOptions(use24h).find(op => { return op.code === this.state.selectedPackageLimitToTimeFrom })}
                                                    isSearchable={true}
                                                    onChange={(e) => { 
                                                        const startValue = e.value;
                                                        const endValue = this.timeOptions(use24h).find(op => { return op.code === this.state.selectedPackageLimitToTimeTo }).value
                                                        if (parseInt(startValue) > parseInt(endValue)) {
                                                            setTimeout(() => { 
                                                                this.setState({ 
                                                                    selectedPackageLimitToTimeFrom: e.code,
                                                                    selectedPackageLimitToTimeTo: e.code
                                                                    }); 
                                                                }, 50)
                                                        } else {
                                                            setTimeout(() => { this.setState({ selectedPackageLimitToTimeFrom: e.code, }); }, 50) 
                                                        }
                                                    }}
                                                />

                                            </ColItem2c>

                                            <ColItem2d>
                                                <LabelStyled>To:</LabelStyled>
                                                <Select 
                                                    name='selectedPackageLimitToTimeTo'
                                                    isDisabled={ this.state.selectedPackageInstantBooking === false || this.state.selectedPackageLimitToTimeframe === false }
                                                    classNamePrefix="select"
                                                    styles={colourStyles}
                                                    options={this.timeOptions(use24h)}
                                                    value={this.timeOptions(use24h).find(op => { return op.code === this.state.selectedPackageLimitToTimeTo })}
                                                    defaultValue={this.timeOptions(use24h).find(op => { return op.code === this.state.selectedPackageLimitToTimeTo })}
                                                    isSearchable={true}
                                                    onChange={(e) => { 
                                                        const startValue = this.timeOptions(use24h).find(op => { return op.code === this.state.selectedPackageLimitToTimeFrom }).value
                                                        const endValue = e.value;
                                                        if (parseInt(startValue) > parseInt(endValue)) {
                                                            setTimeout(() => { 
                                                                this.setState({ 
                                                                    selectedPackageLimitToTimeFrom: e.code,
                                                                    selectedPackageLimitToTimeTo: e.code
                                                                    }); 
                                                                }, 50)
                                                        } else {
                                                            setTimeout(() => { this.setState({ selectedPackageLimitToTimeTo: e.code, }); }, 50) 
                                                        }
                                                    }}
                                                />
                                               
                                            </ColItem2d>
                                            <></>
                                        </ColumnWrapper>
                                        </Container>
                                        
                                    </IndentRows>


                                <IncreaseHeight/>
                                <FormControlLabel 
                                    control={
                                        <OrangeCheckBox
                                            name='limitToDates'
                                            disabled={this.state.selectedPackageInstantBooking === false}
                                            checked={this.state.selectedPackageLimitToDates}
                                            onChange={(e) => { 
                                                const staatus = e.target.checked
                                                setTimeout(() => { this.setState({ selectedPackageLimitToDates: staatus }); }, 50) 
                                                }}
                                            value="dates"
                                        />
                                    }
                                label={'Make the package available only on following dates:'}
                                />     
                                    <IndentRows>
                                        
                                        <Container>
                                        <ColumnWrapper>
                                            <ColItem2e>
                                                <LabelStyled>From:</LabelStyled>
                                                <SingleDatePicker
                                                    date={moment(this.state.selectedPackagelimitToDateFrom)} // momentPropTypes.momentObj or null
                                                    disabled={ this.state.selectedPackageInstantBooking === false || this.state.selectedPackageLimitToDates === false }
                                                    onDateChange={date => {
                                                        
                                                        const newValue = moment(date);
                                                        const endValue = moment(this.state.selectedPackagelimitToDateTo)
                                                        if (newValue > endValue) {
                                                            setTimeout(() => { 
                                                                this.setState({ 
                                                                    selectedPackagelimitToDateFrom: newValue.format('YYYY-MM-DD'),
                                                                    selectedPackagelimitToDateTo: newValue.format('YYYY-MM-DD')
                                                                    }); 
                                                                }, 50)
                                                        } else {
                                                            setTimeout(() => { this.setState({ selectedPackagelimitToDateFrom: newValue.format('YYYY-MM-DD') }); }, 50) 
                                                        }

                                                    }} // PropTypes.func.isRequired
                                                    focused={this.state.selectedPackagelimitToDateFromFocused} // PropTypes.bool
                                                    onFocusChange={({ focused }) => this.setState({ selectedPackagelimitToDateFromFocused: focused })} // PropTypes.func.isRequired
                                                    id="packagelimitToDateFrom" // PropTypes.string.isRequired,
                                                    numberOfMonths={1}
                                                    anchorDirection="left"
                                                    displayFormat="L" //LL //ll
                                                    isOutsideRange={() => false}
                                                />
                                            </ColItem2e>

                                            <ColItem2f>
                                                <LabelStyled>To:</LabelStyled>
                                                <SingleDatePicker
                                                    date={moment(this.state.selectedPackagelimitToDateTo)} // momentPropTypes.momentObj or null
                                                    disabled={ this.state.selectedPackageInstantBooking === false || this.state.selectedPackageLimitToDates === false }
                                                    onDateChange={date => setTimeout(() => { this.setState({ selectedPackagelimitToDateTo: moment(date).format('YYYY-MM-DD') }); }, 50)  } // PropTypes.func.isRequired
                                                    focused={this.state.selectedPackagelimitToDateToFocused} // PropTypes.bool
                                                    onFocusChange={({ focused }) => this.setState({ selectedPackagelimitToDateToFocused: focused })} // PropTypes.func.isRequired
                                                    id="packagelimitToDateTo" // PropTypes.string.isRequired,
                                                    numberOfMonths={1}
                                                    anchorDirection="left"
                                                    displayFormat="L" //LL //ll
                                                    isOutsideRange={(day) => day < moment(this.state.selectedPackagelimitToDateFrom)}
                                                />
                                            </ColItem2f>
                                            <></>
                                        </ColumnWrapper>
                                        </Container>
                                       
                                    </IndentRows>

                            </IndentRows>
                            <IncreaseHeight/>
 */}

                            

                            
                            <IncreaseHeight/>
                            <LabelStyled>Availability for booking:</LabelStyled>
                            <Select 
                                name='packageAvailability'
                                classNamePrefix="select"
                                styles={colourStyles}
                                options={availabilityArr}
                                value={{value: this.state.selectedPackageAvailability, label: availabilityArr[this.state.selectedPackageAvailability].label}} 
                                isSearchable={true}
                                onChange={(e) => { setTimeout(() => { this.setState({ selectedPackageAvailability: availabilityArr[e.value].code, }); }, 50) }}
                            />

                            <IncreaseHeight/>    
                            <IncreaseHeight/>   
                            <IncreaseHeight/>
                            <LabelHeading>Cancellation Policy for the Client</LabelHeading>
                            <IncreaseHeight/>
                            
                            <OneRow>
                            
                                <LabelStyled2>1) Free cancellation within</LabelStyled2>
                                    <OneRowCellMinWidth2>
                                        <Select 
                                            name='selectedPackageCancelAfter'
                                            classNamePrefix="select"
                                            styles={colourStyles}
                                            options={this.cancelOptions()}
                                            value={this.cancelOptions().find(op => { return op.code === this.state.selectedPackageCancelAfter.toString() })}
                                            defaultValue={this.cancelOptions().find(op => { return op.code === this.state.selectedPackageCancelAfter.toString() })}
                                            isSearchable={true}
                                            onChange={(e) => { setTimeout(() => { this.setState({ selectedPackageCancelAfter: e.code, }); }, 50) }}
                                        />
                                    </OneRowCellMinWidth2>
                                <LabelStyled2>after the booking is confirmed.</LabelStyled2>

                            </OneRow>

                            <OneRow>
                            
                                <LabelStyled2>2) Free cancellation if cancelled at least</LabelStyled2>
                                
                                    <OneRowCellMinWidth2>
                                        <Select 
                                            name='selectedPackageCancelBefore'
                                            classNamePrefix="select"
                                            styles={colourStyles}
                                            options={this.cancelOptions2()}
                                            value={this.cancelOptions2().find(op => { return op.code === this.state.selectedPackageCancelBefore.toString() })}
                                            defaultValue={this.cancelOptions2().find(op => { return op.code === this.state.selectedPackageCancelBefore.toString() })}
                                            isSearchable={true}
                                            onChange={(e) => { setTimeout(() => { this.setState({ selectedPackageCancelBefore: parseInt(e.code), }); }, 50) }}
                                        />
                                    </OneRowCellMinWidth2>
                                
                                
                                <LabelStyled2>before the photoshoot.</LabelStyled2>

                            </OneRow>

                            <OneRow>
                                <LabelStyled2>3) You'll keep</LabelStyled2>
                                <OneRowCellMinWidth2>
                                    <Select 
                                        name='lateCancellationKeeps'
                                        classNamePrefix="select"
                                        styles={colourStyles}
                                        options={this.cancelOptions3()}
                                        value={this.cancelOptions3().find(op => { return op.code === this.state.lateCancellationKeeps.toString() })}
                                        defaultValue={this.cancelOptions3().find(op => { return op.code === this.state.lateCancellationKeeps.toString() })}
                                        isSearchable={true}
                                        onChange={(e) => { setTimeout(() => { this.setState({ lateCancellationKeeps: parseInt(e.code), }); }, 50) }}
                                    />
                                </OneRowCellMinWidth2>
                                <LabelStyled2>if cancelled</LabelStyled2>
                                <LabelStyled2>less than</LabelStyled2>
                                <LabelStyled2>{this.state.selectedPackageCancelBefore} days</LabelStyled2>
                                <LabelStyled2>before.</LabelStyled2>
                            </OneRow>

                            <OneRow>
                                <LabelStyled2>4) You'll keep 50%</LabelStyled2>
                                <LabelStyled2>if cancelled</LabelStyled2>
                                <LabelStyled2>less than</LabelStyled2>
                                <OneRowCellMinWidth2>
                                    <Select 
                                        name='noCancellationsAfter'
                                        classNamePrefix="select"
                                        styles={colourStyles}
                                        options={this.cancelOptions4()}
                                        value={this.cancelOptions4().find(op => { return op.code === this.state.noCancellationsAfter.toString() })}
                                        defaultValue={this.cancelOptions4().find(op => { return op.code === this.state.noCancellationsAfter.toString() })}
                                        isSearchable={true}
                                        onChange={(e) => { setTimeout(() => { this.setState({ noCancellationsAfter: parseInt(e.code), }); }, 50) }}
                                    />
                                </OneRowCellMinWidth2>
                                <LabelStyled2>before.</LabelStyled2>
                            </OneRow>

                            

                            <IncreaseHeight/>
                            { this.state.errorMsg ? ( <><IncreaseHeight/> <MyError message = {this.state.errorMsg} /> </>) : null }
                            <IncreaseHeight/>



                            <BottomButtonArea>
                                {
                                    this.state.selectedPackageId !== "%%-----## -!new-package!- ##------%%" ? 
                                        (
                                            <ButtonAreaLeft>
                                                <StyledCancel onClick={this.togglePackageConfirmDelete}>Delete this package</StyledCancel>
                                            </ButtonAreaLeft>
                                        )
                                    :
                                    <ButtonAreaLeft></ButtonAreaLeft>
                                }

                                <ButtonAreaRight>
                                    <StyledSave onClick={this.savePackage}>Save</StyledSave> 
                                    <StyledCancel onClick={this.cancelPackageEdit}>Cancel</StyledCancel>
                                </ButtonAreaRight>

                                {
                                    this.state.selectedPackageConfirmDelete ?  
                                        <ConfirmDelete  
                                            text='Are you sure you would like to delete this package?'
                                            closePopup={this.togglePackageConfirmDelete}
                                            onConfirm={() => this.deletePackage(this.state.selectedPackageId)}
                                        />
                                        : 
                                        <></>  
                                }  
                            </BottomButtonArea>


                        </>
                        )
                    
                    :                    
                    <></>
                    
                } 

            </PageContent>

        )

    }
    
}


export const PackagesPage = withStore(withApollo(C), ['']);


const InputNumberStyled = styled(InputNumber)`
    
    width: calc(100% - 18px);
    padding: 0px;
    margin: 0px;
    
    && {
        input {
            
            
            font-size: 16px;
            font-family: 'Fira Sans';
            font-weight: 400;

            width: 100%;
            padding: 0px;
            margin: 0px;
            height: 24px;
            padding: 8px;
            
            color: #333333;

            border: 1px solid #cbcbcb;
            border-radius: 4px;  
            box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

            :focus {
                outline-color: #4C9AFF;
            }

            :disabled {
                background-color: #f7f7f7; 
            }

        }
    }
    
`;

const PriceInfo = styled.div`

    margin-bottom: 8px;
    padding: 12px;
    border-radius: 2px;
    
    font-size: 16px;
    font-weight: 400;
    
    color: #333300;
    background-color: #FFFACD;
    border: 1px solid #FFE4B5;
    box-shadow: 0 2px 8px 0 rgba(189,183,107, 0.2);

`;

const GreyButtons = styled.div`

    display: flex;

    position: relative;

    margin-top: -32px;

    top: 40px;
    
    z-index: 800;

    width: calc(100%+8px);
    min-width: calc(100%+8px);

    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 790px) {
        z-index: unset;
    }


`;

const GreyButton = styled(Button)`
    && {
        
    margin-top: -8px;

    height: 40px;
    min-width: 40px;
    max-width: 40px;

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;
    font-weight: 400;
    font-size: 17px;

    &:hover {
        background-color: #EBEBEB;
        opacity: 0.6;
        }
    
    &:disabled {
        background-color: #fff;
        color: #fff;
        }

    }
    
`;

const FAIcon = styled(FontAwesomeIcon)`
    && {
    font-size: 16px;
    font-weight: 300;
    }
`;

const PackageCards = styled.div`

    width: 100%;
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    align-items: flex-start;
    justify-content: center;
   
    z-index: 200;

    @media (max-width: 790px) {
        z-index: unset;
    }

`;

const PackageCard = styled.div`
    
    
    width: 300px;
    height: 300px;
   
    margin: 16px 16px 8px 16px;
    align-items: flex-start;
    border-radius: 5px;
    
    border: 1px solid #cbcbcb;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    z-index: 300;



    &:hover {
        transform: scale(1.02);
        cursor: pointer;

    }

    @media (max-width: 790px) {
        z-index: unset;
    }

`;


const PackageCardDuration = styled.div`
    
    display: absolute;
    position: relative;
    text-align: left;
    float: left; 
    margin-right: auto;
    margin-left: 0px;
    
    margin-top: 40px; 

    border-radius: 0px 4px 4px 0px;
    padding: 6px 12px 6px 16px;

    color: #333333;
    background-color: rgba(75, 75, 75, 0.1);
    
    font-family: 'Fira Sans';
    font-size: 16px;
    font-weight: 400;

    &:hover {
        cursor: pointer;
    }

    z-index: 600;

    @media (max-width: 790px) {
        z-index: unset;
    }

`;




const PackageCardPrice = styled.div`
    
    display: absolute;
    position: relative;

    text-align: right;
    float: right; 
    margin-left: auto;

    margin-top: 40px; 

    border-radius: 4px 0px 0px 4px;
    padding: 6px 16px 6px 12px;

    color: #333333;
    background-color: rgba(75, 75, 75, 0.1);
    
    font-family: 'Fira Sans';
    font-size: 16px;
    font-weight: 400;

    &:hover {
        cursor: pointer;
       
    }

    z-index: 700;

    @media (max-width: 790px) {
        z-index: unset;
    }


`;



const PackageCardName = styled.div`
    
    display: absolute;
    position: relative;
    text-align: left;
    float: left; 

    margin-right: 0;
    margin-left: 16px;
    margin-top: 10px;
    
    z-index: 600;

    width: 90%;

    color: #333333;
    font-family: 'Fira Sans';
    font-size: 18px;
    font-weight: 400;
    
    white-space: pre-wrap;
    margin-bottom: 8px;

    &:hover {
        cursor: pointer;
    }

    @media (max-width: 790px) {
        z-index: unset;
    }


`;

const PackageCardDescription = styled.div`
    
    display: absolute;
    position: relative;
    text-align: left;
    float: left; 

    margin-right: 0;
    margin-left: 16px;
    margin-top: 8px;


    width: 90%;
    height: 120px;
    max-height: 120px;
    
    color: #333333;
    font-size: 16px;
    line-height: 20px;

    overflow: hidden;
    text-overflow: ellipsis;


    &:hover {
        cursor: pointer;
    }

    z-index: 600;

    @media (max-width: 790px) {
        z-index: unset;
    }

`;

const PackageCardClickOverlay = styled.div`
    
    display: absolute;
    position: absolute;
    text-align: left;
    float: left; 

    margin-left: 0px;
    margin-top: 40px;

    width: 300px;
    height: 260px;

    &:hover {
        cursor: pointer;
    }

    z-index: 60;

    @media (max-width: 790px) {
        z-index: unset;
    }


`;

const PackageCardRestrictions = styled.div`
    
    display: absolute;
    position: absolute;
    text-align: left;
    float: left; 

    margin-right: auto;
    margin-left: 16px;

    margin-top: 244px;
    padding: 0px 0px 4px 0px;

    width: 280px;

    color: #333333;
    font-family: 'Fira Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 1.2em;

    overflow: hidden;
    text-overflow: ellipsis;


    color: #ff9500;
    /* background-color: rgba(75, 75, 75, 0.1); */

    &:hover {
        cursor: pointer;
    }

    z-index: 600;

    @media (max-width: 790px) {
        z-index: unset;
    }

`;

const PackageCardRestrictions2 = styled.div`
    
    display: absolute;
    position: absolute;
    text-align: left;
    float: left; 

    margin-right: auto;
    margin-left: 16px;

    margin-top: 260px;
    padding: 0px 0px 4px 0px;

    width: 280px;

    color: #333333;
    font-family: 'Fira Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 1.2em;

    overflow: hidden;
    text-overflow: ellipsis;


    color: #ff9500;
    /* background-color: rgba(75, 75, 75, 0.1); */

    

    &:hover {
        cursor: pointer;
    }

    z-index: 600;

    @media (max-width: 790px) {
        z-index: unset;
    }


`;


const PackageCardAvailability = styled.div`
    
    display: absolute;
    position: absolute;
    text-align: left;
    float: left; 

    margin-top: 278px;

    margin-left: 0px;

    border-radius: 0px 0px 4px 4px;
    padding: 4px 4px 4px 16px;

    color: ${ (props) => 
        props.availabilityCode === 0 ? 'rgba(255, 255, 255, 1)'
        : 
        props.availabilityCode === 1 ? 'rgba(0, 0, 0, 1);' 
        : 
        'rgba(255, 255, 255, 1)'
    }; 
    
    

    background-color: ${
        (props) => 
            props.availabilityCode === 0 ? 'rgba(255, 0, 0, 0.6);'
            : 
            props.availabilityCode === 1 ? 'rgba(0, 255, 0, 0.6);' 
            : 
            'rgba(0, 0, 255, 0.6)'
        
    };
    


    /* 
        { value: 0, code: 0, label: 'Not available at this moment' },
        { value: 1, code: 1, label: 'Available to everyone' },
        { value: 2, code: 2, label: 'Only for members with an active profile' },
        { value: 3, code: 3, label: 'Only for Pro members with an active profile' },
        { value: 4, code: 4, label: 'Only for Verified Pro members with an active profile' },
    */

    z-index: 600;

    width: 280px;

    font-size: 14px;;
    line-height: 14px;

    &:hover {
        cursor: pointer;
    }

    @media (max-width: 790px) {
        z-index: unset;
    }


`;



const OrangeCheckBox = withStyles({
    root: {
      color: orange[400],
      '&$checked': { color: orange[600], },
      '&$disabled': { color: grey[100], },
    },
    checked: {},
    disabled: {color: grey[100],}
    })(props => <Checkbox color="default" {...props} /> )
;


const IndentRows = styled.div`
    width: 100%;
    min-width: 100%;
    margin-left: 32px;
    margin-bottom: 16px;
`;


const ColumnWrapper = styled.div`
    width: 100%;
    min-width: 100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 890px) {
        flex-direction: column;
        justify-content: flex-start;
    }
`;

const OneRow = styled.div`
    
    margin-left: 24px; 
    margin-bottom: 24px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    flex-wrap: wrap;
    flex: 1;

    align-items: center;

`;

const ColItem1 = styled.div`
    width: calc(50% - 32px);
    min-width: calc(50% - 32px);
    display: relative;
    float:left;
    margin-right: 32px;

    @media (max-width: 890px) {
        width: 100%;
        min-width: 100%;
    }

`;

const ColItem1b = styled.div`
    width: calc(50% - 64px);
    min-width: calc(50% - 64px);
    display: relative;
    float:left;
    margin-right: 32px;

    @media (max-width: 890px) {
        width: calc(100% - 32px);
    }

`;

const ColItem2 = styled.div`

    
    width: 50%;
    min-width: 50%;
    display: relative;
    float:left;

    @media (max-width: 890px) {
        width: 100%;
        min-width: 100%;
        margin-top: 12px;
        margin-bottom: 32px;
    }

`;



const StyledSave = styled(Button)`
    && {
        height: 40px;
        
        background: ${(props) => props.disabled ? "white": '#ff9500'};
        box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

        color: white;
        
        
        padding-left: 16px;
        padding-right: 16px;
        margin-right: 16px;
        margin-bottom: 32px;
        margin-top: 16px;

        text-transform: none;
        font-weight: 400;
        font-size: 17px;
            
        &:hover {
            background: ${(props) => props.disabled ? "white" : '#ff9500'};
            opacity: 0.9;
            color: white;
        }
    }
    
`;

const StyledCancel = styled(Button)`
    && {
    height: 40px;
    margin-top: 16px;

    padding-left: 16px;
    padding-right: 16px;

    margin-bottom: 32px;
    margin-right: 16px;

    color: #333333;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;

    @media (max-width: 790px) {
        border: 1px solid #efefef;
        box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};
    }

    }
    
`;

const BottomButtonArea = styled.div`
    width:100%;
    min-width: 100%;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;    

    justify-content: space-between;

`;

const ButtonAreaLeft = styled.div`

    justify-content: space-between;

`;

const ButtonAreaRight = styled.div`
    justify-content: space-between;

`;


const PageContent = styled.div`
    
    width: 100%;
    /* height: 100%; */
    /* min-height: 75vh; */
    font-family: 'Fira Sans';
    
`;

const PageTitle = styled.div`
    
    display: flex;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;

    display: ${(props) => props.showAlways === 'true' ? "block" : 'none'};

    @media (max-width: 790px) {
        display: block;
    }


`;

const LinkButton = styled.div`
    
    color: #4C9AFF;

    margin: 0 8px 0 0; 

    font-weight: 400;
    font-size: 16px;

    &:hover {
        text-decoration: underline;
        cursor: pointer
        }

`;

const IncreaseHeight = styled.div`
    
    height: 16px; 
    min-height: 16px; 

`;

const TopMargin16 = styled.div`
    
    @media (max-width: 890px) {
        margin-top: 16px;
        margin-bottom: 24px;
    }
    

`;

const LabelStyled = styled.div`
    width: 100%;
    margin: 0px;
    padding: 0px;
    color: #333333;
    font-size: 16px;
    
    margin-bottom: 4px;
    margin-top: 0px;
    margin-left: 2px;
`;

const LabelStyled2 = styled.div`
    
    margin-left: 0px;
    margin-right: 4px;
    margin-bottom: 4px;
    margin-top: 6px;
    padding: 0px;
    
    color: #333333;
    font-size: 16px;


`;



const OneRowCellMinWidth2 = styled.div`
    
    margin-right: 8px;

`;


const colourStyles = {

    control: (styles, {isDisabled, isFocused}) => ({ ...styles, 
        
        margin: 0,
        height: 42,
        paddingTop: 0,
        
        fontSize: 16,
        fontFamily: 'Fira Sans',
        fontWeight: '400',
        
        backgroundColor: isDisabled ? '#f7f7f7' : null,
        color: '#333333',
        
        borderRadius: 4,
        border: isFocused ? '2px solid #4C9AFF' : '1px solid #cbcbcb',
        boxShadow: '0 2px 8px 0 rgba(170, 170, 170, 0.2);',
        //boxShadow: isFocused ? '0 2px 8px 0 rgba(76, 154, 255, 0.8)' : '0 2px 8px 0 rgba(170, 170, 170, 0.2)',

        minWidth: '125px'
        }),
    
    singleValue: base => ({
        ...base,
        paddingTop: 2,
        color: '#333333',
        marginLeft: 0,
        }),
    
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        
        return {
            ...styles,

            cursor: isDisabled ? 'not-allowed' : 'default',
        
            fontSize: 16,
            fontFamily: 'Fira Sans',
            fontWeight: '400',
            height: 42,

            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',


            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && ('#4C9AFF'),

            },


        };
    },
    menu: base => ({
        ...base,
        // color: '#333333',
        
        borderRadius: 4,
        border: '2px solid #4C9AFF',
        boxShadow: '0 2px 8px 0 rgba(170, 170, 170, 0.2);',

        marginLeft: '-1px',

        width: 'calc(100% + 2px);',


    }),

}

const InputStyled = styled.input`
    
    width: calc(100% - 18px);
    padding: 0px;
    margin: 0px;
    height: 24px;
    padding: 8px;
    font-size: 16px;
    font-family: 'Fira Sans';
    font-weight: 400;

    
    

    color: #333333;
    
    border: 1px solid #cbcbcb;
    border-radius: 4px;  
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    
    :focus {
        outline-color: #4C9AFF;
        -webkit-appearance: none;
    }

    :disabled {
        background-color: #f7f7f7; 
    }


    @media (max-width: 690px) {
        font-size: 17px;
    }

`;

const TextareaStyled = styled.textarea`
    
    width: calc(100% - 18px);
    padding: 0px;
    margin: 0px;
    /* height: 24px; */
    padding: 8px;
    font-size: 16px;
    font-family: 'Fira Sans';
    line-height: 1.2em;

    color: #333333;
    
    border: 1px solid #cbcbcb;
    border-radius: 4px;  
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    :focus {
        outline-color: #4C9AFF;
    }

    :disabled {
        background-color: #f7f7f7; 
    }

    @media (max-width: 690px) {
        font-size: 17px;
    }
`;

const LabelHeading = styled.div`
    width: 100%;
    margin: 0px;
    padding: 0px;
    color: #333333;
    font-size: 18px;
    font-weight: 500;
    
    margin-bottom: 8px;
    margin-top: 0px;
    margin-left: 2px;
`;