import React, { PureComponent } from 'react'
import { WithViewEvent } from "@psd/controller";

import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";

import ReactGA from 'react-ga4';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import Footer from '../../modules/components/Footer/Footer'

// import { Query } from "react-apollo";
import Modal from 'react-modal';

import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import './../../styles/carousel.css';
import ClampLines from 'react-clamp-lines';

import 'react-dates/initialize';
import "./../../styles/ReactDatesOverride.css"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import { withStore } from '@spyna/react-store'
import { withApollo } from "react-apollo";
import { withRouter } from 'react-router-dom';
import { EventBooking } from "./EventBooking";
import ConfirmDialog from '../components/ConfirmDialog';

import { MyCategoryTag } from "../components/MyCategoryTag";

import getIPInfo from '../../utils/getIPInfo'
// import TimeAgo from './../../utils/TimeAgo'
import ISO6391 from 'iso-639-1';

// import { Mutation } from "react-apollo"
import { Query } from "react-apollo";
import gql from "graphql-tag"
import { CLOUDFRONT_SERVER } from '../../config';

import i18next from "i18next";
import moment from "moment"; 

import toast from 'toasted-notes' 
import 'toasted-notes/src/styles.css';

//UPDATE: For future pagination of reviews, use 
//  https://www.npmjs.com/package/mongoose-paginate-v2
//  https://www.bezkoder.com/node-js-mongodb-pagination/

//right now there is not many reviews, so pagination is not necessary for MVP. 



const getEventReviews = gql`
    query ($eventId: String!) {
        getEventReviews(eventId: $eventId) {
            dt, from, rating, message, response, 
            bookingId, clientId, clientName, clientAvatar, bookingDt, catNumber, duration
        }
    }
`;


const getEventAlbumPhotos = gql`
    query ($albumId: String!) {
        getEventAlbumPhotos(albumId: $albumId) {
            photoId, albumId, ext, pw, ph, userId, parentId, title { lng, title }, nsfw, position
        }
    }
`;

const addFavorite = gql`
    mutation addFavorite($userId: String, $type: Int, $profileId: String) {
        addFavorite(userId: $userId, type: $type, profileId: $profileId)
    }
`;

const removeFavorite = gql`
    mutation($userId: String, $type: Int, $profileId: String) {
        removeFavorite(userId: $userId, type: $type, profileId: $profileId) 
    }
`;

const isFavorited = gql`
    query($userId: String, $type: Int, $profileId: String) {
        isFavorited(userId: $userId, type: $type, profileId: $profileId) 
    }
`;


const getExchangeRate = gql`
    query($base: String, $to: String) {
        getExchangeRate(base: $base, to: $to,) { rate, baseToEUR }
    }
`;


const createMessageMutation = gql`
    mutation createMessage($toType: Int, $toProfileId: String, $message: String) {
        createMessage(toType: $toType, toProfileId: $toProfileId, message: $message) { 
            id, lastMessage, lastMessageDt
        }
    }
`;



const today = new Date();

class C extends PureComponent {

    // imgEl: HTMLImageElement;
    

    constructor(props) {
        
        super(props);

        this.state = {
            
            selectedAlbum: 0,
            selectedAlbumId: '',
            selectedAlbumPhotos: [],
            selectedPhoto: 0,
            selectedPhotoURL: '',
            openPhotoZoom: false,
            albumLoadingFinished: false,

            width: 0,
            height: 0,
            imgWidth: 0,
            imgHeight: 0,
            imgHeightMax: 0,
            imgWidthMax: 0,

            packageIndex: 0,
            showFloatBar: false,
            profileSaved: false,
            confirmLogin: false,
            confirmLoginText: '',
            availabilityRangeStart: moment().format("YYYY-MM-01"),
            availabilityLoadingFinished: false,
            availabilityArray: [],
            travelNotices: [],

            displayCurrency: 'EUR',
            displayCurrencyRate: 0,
            euroRate: 0,

            bookingModalVisible: false,
            messageModalVisible: false,
            messageField: '',
            messageToWhichEvent: '',
            bookingPackageId: '',
            bookingPackageDuration: 60,
            bookingDate: '',
            
            metric: 0,
            sizeChart: 0,
            
            
        }

        this.handleScroll = this.handleScroll.bind(this)
        this.fldValueChange = this.fldValueChange.bind(this);

    }


    fldValueChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({ [name]: value, });
        
    }
    
    selectAlbum = async (index, id) => {

        let albumPhotos = []

        await this.props.client.query({
            query: getEventAlbumPhotos,
            variables: { albumId: id }, 
            fetchPolicy: 'no-cache',
            })
            .then(result => {


                if (result.data.getEventAlbumPhotos.length > 0) {

                    // console.log('----- album selection: result.data: ', result.data)

                    result.data.getEventAlbumPhotos.map((photo, index) => {

                        albumPhotos.push({ 
                            i: index,
                            u: photo.userId,
                            a: photo.albumId,
                            p: photo.photoId+photo.ext,
                            t: photo.title,
                        })

                        return null
                    })
                    
                }
                
            })
            .catch(error => {
                // console.log("--- Error: ", error);
            })



        this.setState({
            selectedAlbum: index,
            selectedAlbumId: id,
            selectedAlbumPhotos: albumPhotos,
            selectedPhoto: 0,
            selectedPhotoURL: albumPhotos.length > 0 ? CLOUDFRONT_SERVER + '/img/L/' + albumPhotos[0].u + '/' + albumPhotos[0].a + '/' + albumPhotos[0].p : '',
            albumLoadingFinished : true,
        }); 

        //const isMobileScreen = window.matchMedia('(max-width: 790px)').matches;
        //if ((isMobileScreen) && (this.props.history.action !== 'POP')) {
        //    window.scrollTo({top: 144, behavior: 'smooth'});
        //} else {
        //    window.scrollTo({top: 96, behavior: 'smooth'});
        //}
        if (this.props.history.action !== 'POP') {
            window.scrollTo({top: 144, behavior: 'smooth'});
        }
        else {
            window.scrollTo({top: 96, behavior: 'smooth'});
        }

    }


    async componentDidMount() {
    
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        window.addEventListener('scroll', this.handleScroll);

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    
    handleScroll () {

        if (window.scrollY > 400) {

            this.setState({ 
                showFloatBar: true
            });

        } else {
            if (this.state.showFloatBar) {
                this.setState({ 
                    showFloatBar: false
                });
            }
        }

    }


    durationOptions = () => {
        return [
            { value: '15', code: '15', label: '15 min' },
            { value: '20', code: '20', label: '20 min' },
            { value: '30', code: '30', label: '30 min' },
            { value: '45', code: '45', label: '45 min' },
            { value: '60', code: '60', label: '1 hour' },
            { value: '90', code: '90', label: '1.5 hours' },
            { value: '120', code: '120', label: '2 hours' },
            { value: '150', code: '150', label: '2.5 hours' },
            { value: '180', code: '180', label: '3 hours' },
            { value: '210', code: '210', label: '3.5 hours' },
            { value: '240', code: '240', label: '4 hours' },
            { value: '300', code: '300', label: '5 hours' },
            { value: '360', code: '360', label: '6 hours' },
            { value: '420', code: '420', label: '7 hours' },
            { value: '480', code: '480', label: '8 hours' },
            { value: '540', code: '540', label: '9 hours' },
            { value: '600', code: '600', label: '10 hours' },
            { value: '660', code: '660', label: '11 hours' },
            { value: '720', code: '720', label: '12 hours' },
            { value: '1440', code: '1440', label: 'All day coverage' },
        ]
    }

    eventCategoryToString = () => {
        return [
            
            //value = category, code = position
            { value: '1', code: '14', label: 'Boudoir photoshoot' },
            { value: '2', code: '3', label: 'Couples photoshoot' },
            { value: '3', code: '2', label: 'Family photoshoot' },
            { value: '4', code: '13', label: 'Fashion photoshoot' },
            { value: '5', code: '10', label: 'Kids photoshoot' },
            { value: '6', code: '12', label: 'Lifestyle photoshoot' },
            { value: '7', code: '9', label: 'Newborn photoshoot' },
            { value: '11', code: '15', label: 'Nude photoshoot' },
            { value: '8', code: '1', label: 'Portrait photoshoot' },
            { value: '9', code: '8', label: 'Pregnancy photoshoot' },
            { value: '10', code: '5', label: 'Wedding photoshoot' },
            { value: '32', code: '16', label: 'Architecture photoshoot' },
            { value: '33', code: '4', label: 'Event photoshoot' },
            { value: '34', code: '17', label: 'Food photoshoot' },
            { value: '28', code: '18', label: 'Landscape photoshoot' },
            { value: '29', code: '19', label: 'Nature photoshoot' },
            { value: '30', code: '7', label: 'Pets photoshoot' },
            { value: '35', code: '11', label: 'Product photoshoot' },
            { value: '36', code: '6', label: 'Real estate photoshoot' },
            { value: '37', code: '20', label: 'Sport photoshoot' },
            { value: '38', code: '21', label: 'Street photoshoot' },
            { value: '31', code: '22', label: 'Wildlife photoshoot' },
            { value: '12', code: '23', label: 'Acting photoshoot' },
            { value: '13', code: '24', label: 'Art photoshoot' },
            { value: '14', code: '25', label: 'Bodypaint photoshoot' },
            { value: '15', code: '26', label: 'Cosplay photoshoot' },
            { value: '16', code: '27', label: 'Dance photoshoot' },
            { value: '17', code: '28', label: 'Editorial photoshoot' },
            { value: '18', code: '29', label: 'Fetish photoshoot' },
            { value: '19', code: '30', label: 'Fitness photoshoot' },
            { value: '20', code: '31', label: 'Lingerie photoshoot' },
            { value: '23', code: '33', label: 'Promotional photoshoot' },
            { value: '24', code: '34', label: 'Runway photoshoot' },
            { value: '25', code: '35', label: 'Swimwear photoshoot' },
            { value: '26', code: '36', label: 'Topless photoshoot' },
            { value: '27', code: '37', label: 'Underwater photoshoot' },
            
            
        ]
    }

    

    
    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    localeUses24HourTime = (locale) => {

        // if locale === undefined => current user's locale, otherwise uses "locale"

        //returns true, if 24H time is used in this locale/language
        return new Intl.DateTimeFormat(locale, { hour: 'numeric' })
            .formatToParts(new Date(2020, 0, 1, 13))
            .find(part => part.type === 'hour')
            .value.length === 2;

    }

    moveIndex = (index) => {
        
        this.setState({
            packageIndex: this.state.packageIndex + index,
        });   

    }

    generateArrow = (direction, disabled = false) => {
        
        const indexChange = direction === 'left' ? -1 : 1

        if (direction === 'left') {

            return (
                <GreyButton 
                    onClick={() => this.moveIndex(indexChange) }
                    disabled={disabled} >
                    <FAIcon icon={faChevronLeft} color={ disabled ? '#fff' : '#000'} />
                </GreyButton>
            )

        } else {

            return (
                <GreyButton 
                    onClick={() => this.moveIndex(indexChange) }
                    disabled={disabled} >
                    <FAIcon icon={faChevronRight} color={ disabled ? '#fff' : '#000'} />
                </GreyButton>
            )

        }

    }

    isDayBlocked = (day) => {

        // console.log('blocked.day', day)
        // console.log(' ----- ---  --- --- isDayBlocked.availabilityArray', this.state.availabilityArray)

        // example:
            // if (day.format('ddd') === 'Mon' && ['Jul', 'Aug'].includes(day.format('MMM')))  {
            //     return true
            // }            
            // return false
        
        if (this.state.availabilityArray.length > 0) {

            return !this.state.availabilityArray.some(date => day.isSame(date, 'day'));
            
        } else {

            return false

        }

    }

    
    isOutside = momentDate => {

        if (momentDate < moment(today).subtract(1, 'd'))
            return true
        
        return false
    }

    
    updateFavorites = async (eventId) => {

        if (this.props.store.get("userId") === undefined) {
            
            setTimeout(() => { 
                this.setState({ 
                    confirmLogin: true,
                    confirmLoginText: 'To save this event to your favorites you need to be logged in. Would you like to log in and return to this profile?'
                }) 
            }, 50);

        } else {

            const isSaved = this.state.profileSaved;
            
            if (isSaved) {
            
                await this.props.client.mutate({
                    mutation: removeFavorite,
                    variables: { userId: this.props.store.get("userId"), type: 5, profileId: eventId },
                }).then(results => {
                    // console.log('#mutation result ', results)
                }).catch(error => {
                    //console.log("Error: ", error);
                })

            } else {
                
                await this.props.client.mutate({
                    mutation: addFavorite,
                    variables: { userId: this.props.store.get("userId"), type: 5, profileId: eventId },
                }).then(results => {
                    // console.log('#mutation result ', results)
                }).catch(error => {
                    //console.log("Error: ", error);
                })
                
            }

            setTimeout(() => { 
                this.setState({ 
                    profileSaved: !this.state.profileSaved 
                }) 
            }, 50);

        }


    }

    startBooking = async (eventId) => {

        if (this.props.store.get("userId") === undefined) {
            
            setTimeout(() => { 
                this.setState({ 
                    confirmLogin: true,
                    confirmLoginText: 'To book an event you need to be logged in. Would you like to log in and return to this profile?'
                }) 
            }, 50);

        } else {

            setTimeout(() => { 
                this.setState({ 
                    bookingModalVisible: true
                }) 
            }, 50);

        }


    }

    startMessage = async (eventId) => {

        if (this.props.store.get("userId") === undefined) {
            
            setTimeout(() => { 
                this.setState({ 
                    confirmLogin: true,
                    confirmLoginText: 'To message event host you need to be logged in. Would you like to log in and return to this profile?'
                }) 
            }, 50);

        } else {

            setTimeout(() => { 
                this.setState({ 
                    messageModalVisible: true,
                    messageToWhichEvent: eventId
                }) 
            }, 50);


        }


    }


    postMessage = async (eventId) => {

        let resultOK = false

        await this.props.client
            .mutate({
                mutation: createMessageMutation,
                variables: {
                    toType: 4, //0 = photog, 1=modell, 2 = muah,  3 = studio, 4 = event
                    toProfileId: eventId,
                    message: this.state.messageField,
                },
            })
            .then(results => {

                // console.log(results)
                
                if (JSON.stringify(results).length > 15) {

                    resultOK = true

                } else {
                    toast.notify(
                        <div style={{ "color": "red" }}>Error #11542. Unable to send a message. Please send us the error number so we could fix it.</div>, 
                        { duration: 10000 }
                        );
                }
                
            })
            .catch(error => {
                
                toast.notify(
                    <div style={{ "color": "red" }}>Error #1154. Unable to send a message. Please send us the error number so we could fix it.</div>, 
                    { duration: 10000 }
                    );
                
                //console.log("Error: ", error);
            })
    

        if (resultOK) { 

            setTimeout(() => { 
                this.setState({ 
                    messageModalVisible: false
                }) 
            }, 50);

            //window.location.reload();
            this.props.history.push({ pathname: '../inbox/' })
            // this.props.history.push({ pathname: '/' })
        }

    }


    
    loadPreferredCurrency = async () => {

        let displayCurrency = 'EUR'

        let userHasCurrencyPreference = false;

        // console.log('store.currency: ', this.props.store.get('currency'))

        if (this.props.store.get('currency')) {
            userHasCurrencyPreference = true;
        }

        if (userHasCurrencyPreference) {
            
            displayCurrency = this.props.store.get('currency')

        } else {

            if ((localStorage.getItem('ipCurrency') !== '') && (localStorage.getItem('ipCurrency') !== 'null')) {

                displayCurrency = localStorage.getItem('ipCurrency')

            } else {

                let ipData
                const today = new Date();

                ipData = await getIPInfo(); 
                
                let ipAddress
                if (ipData.city !== ipData.region) {
                    ipAddress = ipData.city + ', ' + ipData.region
                } else {
                    ipAddress = ipData.city
                }
                ipAddress = ipAddress + ', ' + ipData.country

                //save ip date to the local storage immediatelly after geolocated!
                localStorage.setItem('ipCity', ipData.city);
                localStorage.setItem('ipRegion', ipData.region);
                localStorage.setItem('ipCountry', ipData.country);
                localStorage.setItem('ipCurrency', ipData.currency);
                localStorage.setItem('ipLng', ipData.lng);
                localStorage.setItem('ipLat', ipData.lat);
                localStorage.setItem('ipAddress', ipAddress);
                localStorage.setItem('ipLocatedDate', moment(today).format('YYYY-MM-DD'));
                displayCurrency = ipData.currency
            
            }

        }

        this.setState({ 
            displayCurrency: displayCurrency,
        });

    }


    loadCurrencyRates = async (base, to) => {

        let displayCurrencyRate = 0;
        let euroRate = 0;
        
        // convert user package currency to visitor preferred currency
        await this.props.client.query({ 
            query: getExchangeRate, variables: { base, to }, fetchPolicy: 'no-cache',
        })
        .then(results => {

            if (JSON.stringify(results).length > 25) {
                displayCurrencyRate = results.data.getExchangeRate.rate;
                euroRate = results.data.getExchangeRate.baseToEUR
            }

        })
        .catch(error => { 
            //console.log(error) 
        })

        setTimeout(() => { this.setState({ 
            displayCurrencyRate: displayCurrencyRate, 
            euroRate: euroRate,
        }); }, 50);


    }

    
    
    render() {

        // const availabilityArr = [
        //     { value: 0, code: 0, label: 'Not available at this moment' },
        //     { value: 1, code: 1, label: 'Available to everyone' },
        //     { value: 2, code: 2, label: 'Only for members with an active profile' },
        //     { value: 3, code: 3, label: 'Only for Pro members with an active profile' },
        //     { value: 4, code: 4, label: 'Only for Verified Pro members with an active profile' },
        // ];

        
        // const distanceOptions = [
        //     { value: '0', label: 'km', disabled: this.state.selectedPackageTravel === false },
        //     { value: '1', label: 'miles', disabled: this.state.selectedPackageTravel === false },
        // ];

        // const startOfWeek = moment().startOf('week').isoWeekday();  // const endOfWeek   = moment().endOf('week').isoWeekday();
        
        // let weekdayArr = []
        //             if (startOfWeek === 1) { 
        //                 weekdayArr = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        //             } else {
        //                 weekdayArr = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        //             }

        // const use24h = this.localeUses24HourTime(); 

        // const isMobileScreen = window.matchMedia('(max-width: 790px)').matches;
        
        
        moment.locale(i18next.language);

        let profileId = '';
        if (this.props.match.params.profileId) {
            profileId = this.props.match.params.profileId
        } else {
            profileId = this.props.profileId
        }
        
        const userId = this.props.store.get("userId");

        
        this.props.client.query({
            query: isFavorited,
            variables: { userId: userId, type: 5, profileId },
            fetchPolicy: 'no-cache',
        }).then(results => {
            
            // console.log('#mutation result ', results)
            if (results.data.isFavorited === true) {
                this.setState({ 
                    profileSaved: true
                })
            }

        }).catch(error => {
            //console.log("Error: ", error);
        })
  

        const bookingModalStyles = {
            overlay: { zIndex: 2000, },
            content: {

                top: `0`,
                left: '0',
                border: '0px',
                borderRadius: '0',
                
                height: `100vh`,
                width: `100%`,

                paddingTop: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                                                    
                
            },

            
        }

        const bookingModalStylesMobile = {
            overlay: { zIndex: 1000 },
            content: {
                
                margin: 'auto',
                bottom: 'auto',
                overflow : 'auto',

                top: `0`,
                left: '0',
                border: '0px',
                borderRadius: '0',
                
                height: `100vh`,
                width: `100%`,

                paddingTop: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                 

            }
        }

        const momentToday = moment(today);

        this.loadPreferredCurrency();

        return (
            
            <WithViewEvent
            
                profileId={profileId}
                
            >

                {

                    data => {
                    

                        if (data.event.length === 0) {
                            return (
                                <div className="bodywrapper">
                                <div className="settingsWrapper">
                                    <div className='settingsHeader'>Loading ...</div>
                                </div>
                                </div>
                                )
                        }

                        if (this.state.selectedAlbumId === '' && data.event.albums.length > 0) {

                            this.selectAlbum(0, data.event.albums[0].id)
                            
                        } else {

                            setTimeout(() => { this.setState({ albumLoadingFinished: true }) }, 50);
                            
                        }

                        //console.log('ViewEvent data 1:', data)
                        //console.log('ViewEvent this.state', this.state);

                        if (data.event.status === 0) { 
                            //0 = Inactive & hidden
                            //this.props.history.push({ pathname: '../events' })
                            return (
                                <div className="bodywrapper">
                                <div className="settingsWrapper">
                                    <div className='settingsHeader'>Sorry, this profile is not available ...</div>
                                </div>
                                </div>
                                )
                        } 
                        //if (data.event.status === 1) { 
                        //   //1 = Visible to everyone
                        //} 
                        if ((data.event.status === 2) && (this.props.userId === undefined)) { 
                            //2 = Visible to registered users
                            //this.props.history.push({ pathname: '../events' })
                            return (
                                <div className="bodywrapper">
                                <div className="settingsWrapper">
                                    <div className='settingsHeader'>Sorry, this profile is not available ...</div>
                                </div>
                                </div>
                                )
                        } 
                        if ((data.event.status === 3) && (this.props.store.get("isPro") !== true)) { 
                            //3 = Visible to Pro users
                            //this.props.history.push({ pathname: '../events' })
                            return (
                                <div className="bodywrapper">
                                <div className="settingsWrapper">
                                    <div className='settingsHeader'>Sorry, this profile is not available ...</div>
                                </div>
                                </div>
                                )
                        }
                        if ((data.event.status === 4) && ((this.props.store.get("isPro") !== true) || (this.props.store.get("isVerified") !== true))) {  
                            //4 = Visible to Verified Pro users
                            //this.props.history.push({ pathname: '../events' })
                            return (
                                <div className="bodywrapper">
                                <div className="settingsWrapper">
                                    <div className='settingsHeader'>Sorry, this profile is not available ...</div>
                                </div>
                                </div>
                                )
                        }


                        let numberOfStars = '⭐⭐⭐⭐⭐';
                        if (data.event.avgRating <= 5) { numberOfStars = '⭐⭐⭐⭐⭐' }
                        if (data.event.avgRating < 4.5) { numberOfStars = '⭐⭐⭐⭐☆' }
                        if (data.event.avgRating < 3.5) { numberOfStars = '⭐⭐⭐☆☆' }
                        if (data.event.avgRating < 2.5) { numberOfStars = '⭐⭐☆☆☆' }
                        if (data.event.avgRating < 1.5) { numberOfStars = '⭐☆☆☆☆' }
                      
                        
                        const isVerified = data.event.isVerified
                        const isTopRated = data.event.isTopRated
                        const isPro = data.event.isPro
                        const isTop2023 = data.event.isTop2023
                        const isFoundingMember = data.event.isFoundingMember


                        let nextEventString = ""
                        if (data.event.dates.length > 0) {

                            nextEventString = "Next: "
                            for (var i = 0; i < data.event.dates.length; i++) {
                            

                                if (moment(data.event.dates[i].start) > momentToday) {

                                    if (data.event.dates[i].start === data.event.dates[i].end) {
                                        nextEventString = nextEventString + moment(data.event.dates[i].start).format('MMMM D, YYYY') + ' (' + data.event.dates[i].st + ' - ' + data.event.dates[i].et + '), '
                                    } else if (moment(data.event.dates[i].start).format('MMMM') === moment(data.event.dates[i].end).format('MMMM')) {
                                        nextEventString = nextEventString + moment(data.event.dates[i].start).format('MMMM D') + ' - ' + moment(data.event.dates[i].end).format('D, YYYY') + ', '
                                    } else if (moment(data.event.dates[i].start).format('YYYY') === moment(data.event.dates[i].end).format('YYYY')) {
                                        nextEventString = nextEventString + moment(data.event.dates[i].start).format('MMMM D') + ' - ' + moment(data.event.dates[i].end).format('MMMM D, YYYY') + ', '
                                    } else {
                                        nextEventString = nextEventString + moment(data.event.dates[i].start).format('MMMM D, YYYY') + ' - ' + moment(data.event.dates[i].end).format('MMMM D, YYYY') + ', '
                                    }

                                    if (data.event.dates[i].lenDays === 0) {

                                        if (data.event.dates[i].lenHours === 0) {

                                        } else {

                                            if (data.event.dates[i].lenHours === 1) {
                                                nextEventString = nextEventString + data.event.dates[i].lenHours + " hour, "
                                            } else {
                                                nextEventString = nextEventString + data.event.dates[i].lenHours + " hours, "
                                            }

                                        }

                                    } else if (data.event.dates[i].lenDays === 1) {
                                        nextEventString = nextEventString + data.event.dates[i].lenDays + " day, "
                                    } else {
                                        nextEventString = nextEventString + data.event.dates[i].lenDays + " days, "
                                    }

                                    if (data.event.dates[i].price < 0.1) {
                                        nextEventString = nextEventString + "free"
                                    } else {
                                        nextEventString = nextEventString + data.event.dates[i].price + " " + data.event.dates[i].currency
                                    }

                                    
                                    this.loadCurrencyRates(data.event.dates[0].currency, this.state.displayCurrency);

                                    break;
                                }

                            }


                        } else {
                            nextEventString = "No upcoming dates";
                        }

                        
                        let profileHeadline = data.event.title[0].title ? data.event.title[0].title : "";
                        if (profileHeadline.length < 5) { profileHeadline = "Photography event | " + data.event.title[0].title }
                        profileHeadline = data.event.title[0].title

                        let metaDescription = data.event.title[0].summary ? data.event.title[0].summary : "";
                        if (metaDescription.length < 5) { metaDescription = data.event.title[0].title + ' in ' + data.event.addr_locality + ', ' + data.event.country }

                        let metaKeywords = 'photosouls, profile, photographer, ' + data.event.title[0].title + ', ' + data.event.addr_locality + ', ' + data.event.country
                


                        return (

                            <ExtraWrapper>

                            <Helmet>
                                <title>{profileHeadline}</title>
                                <meta name="description" content={metaDescription} />
                                <meta name="keywords" content={metaKeywords} />
                            </Helmet>
                                
                                
                            {
                                this.props.history.action === 'POP' ?
                                    null
                                : 
                                <HistoryBackButton onClick={ () => { this.props.history.goBack();  } } >
                                    <FAIcon icon={faChevronLeft} color={'#000'} />
                                </HistoryBackButton>
                            }


                                <ProfileTopBar>

                                    <ProfileBarLeft>

                                        <CardDiv>
                                            
                                           
                                            <MemberInfoCard>

                                                <CardTitle>{ data.event.title[0].title }{ isVerified ? <VerifiedBadge src={require('./../../assets/badges/verified-48px.png')} alt="is verified user" /> : null }</CardTitle>
                                                {/* <SubTitle>{ data.event.dates.length > 1 ? <>[NEXT]</> : null } { nextEventInfo }</SubTitle> */}
                                                <SubTitle>{ nextEventString }</SubTitle>
                                                <SubTitle>{ data.event.virtual ? <>Online event</> : <>{ data.event.address }</> }</SubTitle>
                                                
                                            </MemberInfoCard>

                                            <MemberBadgesRow>
                                                { isTopRated ? <BtnIconNoMargin src={require('./../../assets/badges/toprated-48px.png')} alt="Top rated" /> : null }
                                                { isPro ? <BtnIconNoMargin src={require('./../../assets/badges/pro-48px.png')} alt="Pro" /> : null }
                                                { isTop2023 ? <BtnIconNoMargin src={require('./../../assets/badges/toppro2023-48px.png')} alt="Top Pro" /> : null }
                                                { isFoundingMember ? <BtnIconNoMargin src={require('./../../assets/badges/founding-48px.png')} alt="Founding memmber" /> : null }                                                     
                                            </MemberBadgesRow>

                                       

                                        </CardDiv>

                                    </ProfileBarLeft>

                                    <ProfileBarCenter>
                                        { isTopRated ? <Badge src={require('./../../assets/badges/toprated-128px.png')} alt="Top rated" /> : null }
                                        { isPro ? <Badge src={require('./../../assets/badges/pro-128px.png')} alt="Pro" /> : null }
                                        { isTop2023 ? <Badge src={require('./../../assets/badges/toppro2023-128px.png')} alt="Top Pro" /> : null }
                                        { isFoundingMember ? <Badge src={require('./../../assets/badges/founding-128px.png')} alt="Founding member" /> : null }                                                                                                  
                                    </ProfileBarCenter>


                                    {/* Save, Message, Book */}
                                    <ProfileBarRight>

                                        <BtnSecondary onClick={() => this.updateFavorites(data.event.id)} >
                                            { 
                                                this.state.profileSaved ? 
                                                    <>
                                                        <BtnIcon src={require('./../../assets/saved-true-64.png')} alt="Saved" />     
                                                        Saved
                                                    </>
                                                    :
                                                    <>
                                                        <BtnIcon src={require('./../../assets/saved-false-64-doubleline.png')} alt="Not saved" />     
                                                        Save
                                                    </>
                                            }
                                        </BtnSecondary>

                                        <BtnSecondaryShort onClick={() => this.updateFavorites(data.event.id)} >
                                            { 
                                                this.state.profileSaved ? 
                                                    <BtnIconNoMargin src={require('./../../assets/saved-true-64.png')} alt="Saved" />     
                                                    :
                                                    <BtnIconNoMargin src={require('./../../assets/saved-false-64-doubleline.png')} alt="Not saved" />     
                                            }
                                        </BtnSecondaryShort>

                                        <BtnSecondary
                                            onClick={ () => this.startMessage(data.event.id) }
                                            >
                                            Message
                                        </BtnSecondary>
                                        
                                        <BtnSecondaryShort
                                            onClick={ () => this.startMessage(data.event.id) }
                                            >
                                            <BtnIconNoMargin src={require('./../../assets/chat.png')} alt="Message" />
                                        </BtnSecondaryShort>
                                        
                                        {
                                            data.event.dates.length > 0 ?
                                                <BtnBook
                                                    movetoright='true'
                                                    onClick={ () => this.startBooking(data.event.id) }
                                                    >
                                                    Book
                                                </BtnBook>
                                            :
                                            null
                                        }
                                        

                                    </ProfileBarRight>

                                </ProfileTopBar>


                                {
                                    this.state.showFloatBar ? 
                                        <FloatBarContainer>
                                            <FloatBar>

                                                <ProfileBarLeftFloat>

                                                    <CardDivFloat>

                                                        {/* <AvatarArea>
                                                            { 
                                                                data.event.albums[0].coverPhoto ? 
                                                                    <AvatarFloat 
                                                                        src={data.event.albums[0].coverPhoto} 
                                                                        alt=" " 
                                                                        onLoad={(e) => {
                                                                            //e.target.src = values.avatar --- causes huge CPU spike. but looks like not necessary anyway!
                                                                            e.target.style = 'display: flex' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                                                        }}
                                                                        onError={(e) => {
                                                                            //e.target.src = '' /--- causes huge CPU spike. but looks like not necessary anyway!
                                                                            e.target.style = 'display: none' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                                                        }}

                                                                    /> : <></>
                                                            }
                                                        </AvatarArea> */}

                                                        <MemberInfoCardFloat>

                                                            <CardTitleFloat>{ data.event.title[0].title }{ isVerified ? <VerifiedBadge src={require('./../../assets/badges/verified-48px.png')} alt="is verified user" /> : null }</CardTitleFloat>
                                                            <SubTitleFloat>{ nextEventString }</SubTitleFloat>
                                                            <SubTitleFloat>{ data.event.virtual ? <>Online event</> : <>{ data.event.address }</> } </SubTitleFloat>

                                                        </MemberInfoCardFloat>

                                                        <MemberBadgesRowFloat>
                                                            { isTopRated ? <BtnIconNoMargin src={require('./../../assets/badges/toprated-48px.png')} alt="Top rated" /> : null }
                                                            { isPro ? <BtnIconNoMargin src={require('./../../assets/badges/pro-48px.png')} alt="Pro" /> : null }
                                                            { isTop2023 ? <BtnIconNoMargin src={require('./../../assets/badges/toppro2023-48px.png')} alt="Top Pro" /> : null }
                                                            { isFoundingMember ? <BtnIconNoMargin src={require('./../../assets/badges/founding-48px.png')} alt="Founding member" /> : null }                                                     
                                                        </MemberBadgesRowFloat>


                                                    </CardDivFloat>

                                                </ProfileBarLeftFloat>

                                                <ProfileBarCenterFloat>
                                                    { isTopRated ? <Badge2 src={require('./../../assets/badges/toprated-128px.png')} alt="Top rated" /> : null }
                                                    { isPro ? <Badge2 src={require('./../../assets/badges/pro-128px.png')} alt="Pro" /> : null }
                                                    { isTop2023 ? <Badge2 src={require('./../../assets/badges/toppro2023-128px.png')} alt="Top pro" /> : null }
                                                    { isFoundingMember ? <Badge2 src={require('./../../assets/badges/founding-128px.png')} alt="Founding member" /> : null }                                                                                                  
                                                </ProfileBarCenterFloat>

                                                <ProfileBarRight>

                                                    <BtnSecondary onClick={() => this.setState({ profileSaved: !this.state.profileSaved }) } >
                                                        { 
                                                            this.state.profileSaved ? 
                                                                <>
                                                                    <BtnIcon src={require('./../../assets/saved-true-64.png')} alt="Saved" />     
                                                                    Saved
                                                                </>
                                                                :
                                                                <>
                                                                    <BtnIcon src={require('./../../assets/saved-false-64-doubleline.png')} alt="Not saved" />     
                                                                    Save
                                                                </>
                                                        }
                                                    </BtnSecondary>

                                                    <BtnSecondaryShort onClick={() => this.setState({ profileSaved: !this.state.profileSaved }) } >
                                                        { 
                                                            this.state.profileSaved ? 
                                                                <BtnIconNoMargin src={require('./../../assets/saved-true-64.png')} alt="Saved" />     
                                                                :
                                                                <BtnIconNoMargin src={require('./../../assets/saved-false-64-doubleline.png')} alt="Not saved" />     
                                                        }
                                                    </BtnSecondaryShort>

                                                    <BtnSecondary
                                                        onClick={ () => this.startMessage(data.event.id) }
                                                    >
                                                        Message
                                                    </BtnSecondary>
                                                    
                                                    <BtnSecondaryShort>
                                                        <BtnIconNoMargin src={require('./../../assets/chat.png')} alt="Message" />
                                                    </BtnSecondaryShort>
                                                    
                                                    {
                                                        data.event.dates.length > 0 ?
                                                            <BtnBook
                                                                onClick={ () => this.startBooking(data.event.id) }
                                                            >
                                                                Book
                                                            </BtnBook>
                                                        :
                                                        null
                                                    }

                                                    

                                                </ProfileBarRight>
                                                        
                                            </FloatBar>
                                        </FloatBarContainer>
                                        :
                                        null
                                }


                                {
                                    this.state.confirmLogin ?  
                                        <ConfirmDialog  
                                            text={ this.state.confirmLoginText }
                                            confirmText={'Log in'}
                                            cancelText={'Cancel'}
                                            onConfirm={ () => { 
                                                this.setState({ confirmLogin: false }); 
                                                this.props.history.push(`/login?returnURL=${window.location.pathname}`) 
                                            }}
                                            closePopup={ () => 
                                                this.setState({ confirmLogin: false })  
                                            }
                                        />
                                        : 
                                        <></>  
                                }  
                                
                                
                                    {/* Albums */}
                                    { ((data.event.albums.length > 0) && (this.state.selectedAlbumPhotos.length > 0)) ? 
                                        <AlbumSection>
                                    
                                            <SelectedAlbumSection>

                                                <SelectedPhotoPreview>

                                                    

                                                    <PhotoContainer >

                                                        <PhotoContainerContent>
                                                        
                                                            <GreyButtons>
                                                                { 
                                                                    this.state.selectedPhoto === 0 ? <>&nbsp;</> :
                                                                        <GreyButton 
                                                                            onClick={() =>  {
                                                                                const current = this.state.selectedPhoto;
                                                                                setTimeout(() => { this.setState({ 
                                                                                        selectedPhoto: current-1, 
                                                                                        selectedPhotoURL: CLOUDFRONT_SERVER + '/img/L/' + this.state.selectedAlbumPhotos[current-1].u + '/' + this.state.selectedAlbumPhotos[current-1].a + '/' + this.state.selectedAlbumPhotos[current-1].p
                                                                                        }); 
                                                                                    }, 50);
                                                                                }
                                                                            } 
                                                                            disabled={ this.state.selectedPhoto === 0 ? true : false} >
                                                                            <FAIcon icon={faChevronLeft} color={ this.state.selectedPhoto === 0 ? '#fff' : '#000'} />
                                                                        </GreyButton>
                                                                }
                                                                { 
                                                                    this.state.selectedPhoto === this.state.selectedAlbumPhotos.length-1 ? <></> :
                                                                        <GreyButton 
                                                                            onClick={() =>  {
                                                                                const current = this.state.selectedPhoto;
                                                                                setTimeout(() => { this.setState({ 
                                                                                        selectedPhoto: current+1, 
                                                                                        selectedPhotoURL: CLOUDFRONT_SERVER + '/img/L/' + this.state.selectedAlbumPhotos[current+1].u + '/' + this.state.selectedAlbumPhotos[current+1].a + '/' + this.state.selectedAlbumPhotos[current+1].p
                                                                                        }); 
                                                                                    }, 50);
                                                                                }
                                                                            } 
                                                                            disabled={ this.state.selectedPhoto === this.state.selectedAlbumPhotos.length-1 ? true : false} >
                                                                            <FAIcon icon={faChevronRight} color={ this.state.selectedPhoto === this.state.selectedAlbumPhotos.length-1 ? '#fff' : '#000'} />
                                                                        </GreyButton>
                                                                }
                                                            </GreyButtons>

                                                            <PhotoItself
                                                                src={this.state.selectedPhotoURL}
                                                                alt="Photo" 
                                                                isselected='true'
                                                                onLoad={(e) => { e.target.style = 'display: flex' }}
                                                                onError={(e) => { e.target.style = 'display: none' }}
                                                                onClick={() => this.setState({ openPhotoZoom: true }) }
                                                                
                                                            />

                                                            <SelectedPhotoTitle>
                                                                { this.state.selectedAlbumPhotos.length > 0 ?
                                                                    `${this.state.selectedPhoto+1}/${this.state.selectedAlbumPhotos.length}: ${this.state.selectedAlbumPhotos[this.state.selectedPhoto].t[0].title}`
                                                                    :
                                                                    null
                                                                }
                                                            </SelectedPhotoTitle>
                                                            
                                                            
                                                            
                                                        </PhotoContainerContent>

                                                    </PhotoContainer>



                                                </SelectedPhotoPreview>

                                                
                                                <Thumbs>
                                                    { 
                                                        this.state.selectedAlbumPhotos.length > 0 ?
                                                            this.state.selectedAlbumPhotos.map((photo, index) => {

                                                                const source = CLOUDFRONT_SERVER + '/img/XS/' + photo.u + '/' + photo.a + '/' + photo.p
                                                                return (
                                                                    <Thumb 
                                                                        key={index}
                                                                        src={source}
                                                                        alt="Photo" 
                                                                        isselected={this.state.selectedPhoto === index ? 'true' : 'false' }
                                                                        onLoad={(e) => { e.target.style = 'display: flex' }}
                                                                        onError={(e) => { e.target.style = 'display: none' }}
                                                                        onClick={() => {
                                                                            setTimeout(() => { this.setState({ 
                                                                                selectedPhoto: index, 
                                                                                selectedPhotoURL: CLOUDFRONT_SERVER + '/img/L/' + photo.u + '/' + photo.a + '/' + photo.p
                                                                                }); 
                                                                            }, 50);
                                                                        }}
                                                                    />
                                                                )
                                                            })
                                                            : 
                                                            null
                                                    }
                                                </Thumbs>
                                                

                                            </SelectedAlbumSection>


                                            { 
                                                
                                                data.event.albums.length > 0 ? 
                                                    <AlbumListRow>
                                                        { 
                                                            data.event.albums.map((album, index) => {

                                                                return (
                                                                    <AlbumTag2 
                                                                        key={index} 
                                                                        onClick={() => this.selectAlbum(index, album.id)}
                                                                        isselected={this.state.selectedAlbum === index ? 'true' : 'false' }
                                                                    >
                                                                        {album.name[0].name}
                                                                    </AlbumTag2>

                                                                )

                                                            })
                                                        }
                                                    </AlbumListRow>
                                                    :
                                                    <AlbumSpacer/>
                                                    
                                            }


                                        </AlbumSection>
                                        :
                                        <Section>
                                            <SectionTitle>Albums</SectionTitle>
                                            <SectionContent>
                                                {
                                                    this.state.albumLoadingFinished ? 
                                                        <>Sorry, the album has no photos that would match your content preferences.</>
                                                    :
                                                        <>Loading albums & photos, please wait...</>
                                                }
                                            </SectionContent>
                                        </Section>
                                    }


                                    

                                    {/* Sections */}
                                    { 
                                        data.event.sections.map((section, index) => {

                                            return (
                                                <Section key={index}>
                                                    
                                                    <WideSectionTitle>{section.title[0].title}</WideSectionTitle>
                                                                                                        
                                                    <WideSectionContent>
                                                        <div className="contentoverride">
                                                        {
                                                            parse(DOMPurify.sanitize(section.desc[0].desc))                                                                
                                                        }
                                                        </div>
                                                    </WideSectionContent>

                                                </Section>
                                            )
                                        })
                                    }
                                    


                                    {/* Categories & styles */}
                                    <Section>

                                        <SectionTitle>Categories & styles</SectionTitle>
                                        <SectionContent>

                                            <CategoryList>
                                                { 
                                                    data.event.categories.map((category, index) => {
                                                        return (
                                                            <MyCategoryTag
                                                                key={index}
                                                                category={category}
                                                            />
                                                        );
                                                    })
                                                }
                                            </CategoryList>
                                            
                                        </SectionContent>
                                    </Section>


                                    {/* Languages */}
                                    <Section>

                                        <SectionTitle>Languages</SectionTitle>
                                        <SectionContent>
                                            <ListRow>
                                            { 
                                                data.event.languages.map((language, index) => {

                                                    return (
                                                        <LanguageTag key={index}>
                                                            {this.capitalize(ISO6391.getNativeName(language))}
                                                        </LanguageTag>
                                                    )
                                                })
                                            }
                                            </ListRow>
                                        </SectionContent>

                                    </Section>



                                    {/* Location */}
                                    <Section>

                                        <SectionTitle>Location</SectionTitle>
                                        <SectionContent>

                                            { data.event.virtual ? <>Online event</> : <>{ data.event.address }</> }
                                            
                                        </SectionContent>
                                    </Section>


                                    {/* Dates */}
                                    <Section>
                                        <SectionTitle>Dates</SectionTitle>
                                        <SectionContent>

                                        {
                                                            
                                            data.event.dates.map((dateElement, index) => (

                                                <SubTitleFloat key={index}>

                                                {
                                                    moment(data.event.dates[index].start) > momentToday ?
                                                        
                                                        <>

                                                            { 
                                                                data.event.dates[index].start === data.event.dates[index].end ?
                                                                    moment(data.event.dates[index].start).format('MMMM D, YYYY') + ' (' + data.event.dates[index].st + ' - ' + data.event.dates[index].et + '), '
                                                                :
                                                                    moment(data.event.dates[index].start).format('MMMM') === moment(data.event.dates[index].end).format('MMMM') ?
                                                                        moment(data.event.dates[index].start).format('MMMM D') + ' - ' + moment(data.event.dates[index].end).format('D, YYYY') + ', '
                                                                    :
                                                                    moment(data.event.dates[index].start).format('YYYY') === moment(data.event.dates[index].end).format('YYYY') ?
                                                                        moment(data.event.dates[index].start).format('MMMM D') + ' - ' + moment(data.event.dates[index].end).format('MMMM D, YYYY') + ', '
                                                                    :
                                                                    moment(data.event.dates[index].start).format('MMMM D, YYYY') + ' - ' + moment(data.event.dates[index].end).format('MMMM D, YYYY') + ', '

                                                            }
                                                            {

                                                                data.event.dates[index].lenDays === 0 ? 
                                                                data.event.dates[index].lenHours === 0 ? null
                                                                    :
                                                                    data.event.dates[index].lenHours === 1 ? 
                                                                        <>{data.event.dates[index].lenHours + " hour, "}</>
                                                                        :
                                                                        <>{data.event.dates[index].lenHours + " hours, "}</>
                                                                :
                                                                data.event.dates[index].lenDays === 1 ? 
                                                                    <>{data.event.dates[index].lenDays + " day, "}</>
                                                                :
                                                                <>{data.event.dates[index].lenDays + " days, "}</>

                                                            }

                                                            {
                                                                data.event.dates[index].price < 0.1 ?
                                                                    <>free</>
                                                                :
                                                                data.event.dates[index].price + " " + data.event.dates[index].currency
                                                            }

                                                        </>
                                                        :
                                                        null

                                                }

                                                </SubTitleFloat>

                                            ))
                                        }
                                            
                                        </SectionContent>
                                    </Section>
                                    

                                    {/* Hosted by */}
                                    <Section>
                                        <SectionTitle>Hosted by</SectionTitle>

                                        <SectionContent>

                                           
                                        {
                                                            
                                            data.event.hosts.map((host, index) => (

                                                <div key={index}>

                                                    <CardDivFloat>

                                                        <AvatarArea>
                                                            { 
                                                                host.coverPhoto ? 
                                                                    <HostAvatarFloat 
                                                                        src={host.coverPhoto} 
                                                                        alt="Photo" 
                                                                        onLoad={(e) => {
                                                                            //e.target.src = values.avatar --- causes huge CPU spike. but looks like not necessary anyway!
                                                                            e.target.style = 'display: flex' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                                                        }}
                                                                        onError={(e) => {
                                                                            //e.target.src = '' /--- causes huge CPU spike. but looks like not necessary anyway!
                                                                            e.target.style = 'display: none' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                                                        }}

                                                                    /> : <></>
                                                            }
                                                        </AvatarArea>

                                                        <HostTitle>

                                                            <CardTitleFloat>{ host.name }</CardTitleFloat>
                                                            <SubTitleFloat>{ host.title[0].title }</SubTitleFloat>

                                                        </HostTitle>


                                                    </CardDivFloat>

                                                    <IncreaseHeight/>
                                                        {
                                                            parse(DOMPurify.sanitize(host.intro[0].intro))                                                                
                                                        }
                                                    <IncreaseHeight/>                                                    
                                                    <IncreaseHeight/>                                                    
                                                    <IncreaseHeight/>                                                    


                                                </div>

                                            ))
                                        }


                                        </SectionContent>

                                        
                                    </Section> 




                                    {/* REVIEWS */}
                                    { data.event.reviewsTotal >= 5 ? 
                                        <>
                                            <Section>
                                                <SectionTitle>
                                                    {data.event.reviewsTotal} reviews<br/>
                                                    <SectionTitleSmaller><span role="img" aria-label="rating">{numberOfStars}</span> ({data.event.avgRating})</SectionTitleSmaller>

                                                </SectionTitle>
                                                <SectionContent>


                                                <Query
                                                    asyncMode
                                                    query={getEventReviews}
                                                    variables={{'eventId': profileId}}
                                                    fetchPolicy="network-only"
                                                >
                                                {
                                                    ({ data }) => {
                                                    
                                                        //console.log('reviews data: ', data)

                                                        if (data === undefined) {
                                                            
                                                            return(<>
                                                                <SubTitle>Sorry, we're unable to get the reviews at this moment.</SubTitle>
                                                            </>)

                                                        } else
                                                        
                                                        if (JSON.stringify(data).length > 35) {
                                                            
                                                            //console.log('------------ data 2: ', data) 
                                                            
                                                            return (<>

                                                                { 
                                                                    
                                                                    data.getEventReviews.map((review, index) => {

                                                                        //console.log('----- event review:', review)


                                                                        //dt, from, rating, message, response, 
                                                                        //bookingId, clientID, clientName, clientAvatar, reviewDt, reviewCatNumber, reviewDuration
                                                                        
                                                                        // let reviewStars = '⭐⭐⭐⭐⭐';
                                                                        // if (review.rating/2 <= 5) { reviewStars = '⭐⭐⭐⭐⭐' }
                                                                        // if (review.rating/2 < 4.5) { reviewStars = '⭐⭐⭐⭐☆' }
                                                                        // if (review.rating/2 < 3.5) { reviewStars = '⭐⭐⭐☆☆' }
                                                                        // if (review.rating/2 < 2.5) { reviewStars = '⭐⭐☆☆☆' }
                                                                        // if (review.rating/2 < 1.5) { reviewStars = '⭐☆☆☆☆' } 

                                                                        const reviewDate = moment(review.dt/1000*1000).format('MMMM YYYY');

                                                                        return (
                                                                            
                                                                            <ReviewCard
                                                                                key={index}>
                                                    
                                                                                <ReviewHeader>
                                                                                    <ReviewAvatarArea>
                                                                                        { 
                                                                                            review.clientAvatar ? 
                                                                                                <ReviewAvatarFloat 
                                                                                                    src={review.clientAvatar} 
                                                                                                    alt="Photo" 
                                                                                                    onLoad={(e) => {
                                                                                                        //e.target.src = values.avatar --- causes huge CPU spike. but looks like not necessary anyway!
                                                                                                        e.target.style = 'display: flex' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                                                                                    }}
                                                                                                    onError={(e) => {
                                                                                                        //e.target.src = '' /--- causes huge CPU spike. but looks like not necessary anyway!
                                                                                                        e.target.style = 'display: none' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                                                                                    }}

                                                                                                /> : <></>
                                                                                        }
                                                                                    </ReviewAvatarArea>



                                                                                    <ReviewTitle>

                                                                                        {/* <CardTitleFloat>{ this.eventCategoryToString().find(op => { return op.value === review.catNumber.toString() }).label } ({ this.durationOptions().find(op => { return op.code === review.duration.toString() }).label }),  { reviewStars }</CardTitleFloat> */}
                                                                                        <SubTitleFloat>
                                                                                            {reviewDate}, {review.clientName}    
                                                                                        </SubTitleFloat>
                                                                                        <SubTitleFloat>
                                                                                        
                                                                                        </SubTitleFloat>
                                                                                        
                                                                                    </ReviewTitle>
                                                                                
                                                                                </ReviewHeader>

                                                                                <ReviewMessage>
                                                                                    <ClampLines
                                                                                        text={review.message}
                                                                                        id={index}
                                                                                        lines={2}
                                                                                        ellipsis="..."
                                                                                        moreText="Read more"
                                                                                        lessText="Collapse"
                                                                                        className="clampLinesCustomClass"
                                                                                        innerElement="p"
                                                                                    />
                                                                                </ReviewMessage>


                                                                                {

                                                                                    review.response.length > 0 ? 
                                                                                        (
                                                                                            <>
                                                                                                <ResponseHeader>
                                                                                                    Response:
                                                                                                </ResponseHeader>          
                                                                                                <ResponseMessage>

                                                                                                    <ClampLines
                                                                                                        text={review.response}
                                                                                                        id={1234567}
                                                                                                        lines={2}
                                                                                                        ellipsis="..."
                                                                                                        moreText="Read more"
                                                                                                        lessText="Collapse"
                                                                                                        className="clampLinesCustomClass"
                                                                                                        innerElement="p"
                                                                                                    />

                                                                                                </ResponseMessage>
                                                                                            </>
                                                                                        )
                                                                                    :
                                                                                        null
                                                                                }
                                                                                
                                                                                

                                                                                
                                                                            </ReviewCard>
                                                    
                                                                        );

                                                                    })


                                                                }

                                                            </>);

                                                        } else {
                                                            return(<>
                                                                <SubTitle>Loading reviews...</SubTitle>
                                                            </>)
                                                        }
                                                        
                                                    
                                                    }

                                                }
                                
                                                </Query>

                                                </SectionContent>
                                            </Section>
                                        </>
                                        :
                                        null
                                    }
                                


                                    <IncreaseHeight/>
                                    <IncreaseHeight/>
                                    <IncreaseHeight/>
                                    <IncreaseHeight/>
                               
                                
                                <Footer showbutton="true"/>

                            


                            {/* Booking modal */}
                            <Modal 
                                isOpen={this.state.bookingModalVisible}
                                closeTimeoutMS={500}

                                onRequestClose={() => { this.setState({ bookingModalVisible: !this.state.bookingModalVisible, })  }}
                                ariaHideApp={false}
                                style={ window.innerWidth > 790 ? bookingModalStyles : bookingModalStylesMobile }
                                
                                onAfterOpen={() => {
                                    document.body.style.top = `0px`
                                    document.body.style.width = `100vw`
                                    document.body.style.position = 'fixed'
                                }}

                                onAfterClose={() => {
                                    const scrollY = document.body.style.top
                                    document.body.style.position = ''
                                    document.body.style.top = ''
                                    document.body.style.width = ''
                                    window.scrollTo(0, parseInt(scrollY || '0') * -1)
                                }}
                            >
                            
                                <EventBooking 
                                    hideModal={ () => this.setState({ bookingModalVisible: false, }) }
                                    data={data.event}
                                    isPro={isPro}
                                    hasOneDate={data.event.dates.length <= 1 ? true : false}
                                    defaultAddress= { data.event.virtual ? "Online event" : data.event.address }
                                    displayCurrencyRate={ this.state.displayCurrencyRate }
                                    euroRate={ this.state.euroRate }
                                    displayCurrency={ this.state.displayCurrency }
                                />

                            </Modal>


                            { this.state.messageModalVisible ?
                            
                                <div className='react-confirm-alert-overlay'>
                                    <div className='react-confirm-alert-body-min80'>

                                        <ModalTitle>
                                            
                                            <SectionHeadline>
                                                Send a message to the event host
                                            </SectionHeadline>

                                        </ModalTitle>

                                        <ModalTitle>

                                        <TextareaStyled
                                            name={'messageField'}
                                            value={this.state.messageField}
                                            onChange={this.fldValueChange}
                                            rows={5}
                                            // cols={5}
                                        />
                                        </ModalTitle>
                            
                                        <Section></Section>

                                        <ModalTitle>
                                        {/* For padding */}
                                            <ButtonArea>

                                                <BtnBook onClick={ () => { this.postMessage(this.state.messageToWhichEvent); } }>
                                                    Send
                                                </BtnBook>

                                                <BtnSecondaryCancel onClick={ () => { setTimeout(() => { this.setState({ messageModalVisible: false }) }, 50); }}>
                                                    Cancel
                                                </BtnSecondaryCancel>

                                            </ButtonArea>
                                        </ModalTitle>

                                    </div>
                                </div>

                                :
                                null
                            }
  

                            </ExtraWrapper>

                        );
                    }
                }
                
            </WithViewEvent>

        );
    }
}





const personalInfoFields = [
    'userId', 'fName', 'lName', 'bDay', 'email', 'phone', 'phoneDisplay', 'phoneCountry', 'avatar', 'isVerified', 'isPro', 'temperror', 'pwdUpdated',
    'chkReminders', 'chkRemindersP', 'chkMessages', 'chkMessagesP', 'chkRecomm', 'chkRecommP', 'chkInsp', 'chkInspP', 'chkLocal', 'chkLocalP', 
    'chkHow', 'chkHowP', 'chkInvite', 'chkInviteP', 'chkSurvey', 'chkSurveyP', 'chkPolicy', 'chkPolicyP', 'chkTips', 'chkTipsP', 'currency'
]


export const ViewEvent = withRouter(withStore(withApollo(C), personalInfoFields));


const ExtraWrapper = styled.div`

    width: calc(100% - 32px);
    position: relative;
    overflow-x: hidden;

    @media (max-width: 1090px) {
        width: calc(100% - 16px);
    }

`;

const ProfileTopBar = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    top: 0;
    right: 0;
    top: 0; 
    /* height: 80px; */

    width: 80%;
    max-width: 1080px;

    padding-left: 8px;
    
    justify-content: flex-start;


    font-family: 'Fira Sans', sans-serif; 
    font-size: 17px;
    line-height: 25px;

    white-space: pre-line;
    font-weight: 300;
    color: #333333;

    padding-bottom: 8px;

    margin: 40px auto 0px auto;

    @media (max-width: 1350px) {
        width: calc(100% - 16px);
    }


`;

const FloatBarContainer = styled.div`

    position: fixed;
    
    top: 0;
    right: 0;

    /* height: 64px; */

    width: 100%;

    font-family: 'Fira Sans', sans-serif; 
    font-size: 17px;
    line-height: 25px;

    white-space: pre-line;
    font-weight: 300;
    color: #333333;

    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    padding-bottom: 8px;


    z-index: 999;


`;

const FloatBar = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;
  
    width: 80%;
    max-width: 1080px;

    justify-content: space-between;

    margin: 0px auto 0px auto;

    

    @media (max-width: 1350px) {

        width: 100%;
        padding-bottom: 8px;

    }
    
`;



const ProfileBarLeft = styled.div`
    
    flex-grow: 2;  

`;

const ProfileBarLeftFloat = styled.div`
    
    flex-grow: 2;  
    padding-top: 16px;

    justify-content: flex-start;
    align-items: left;

    @media (max-width: 1050px) {
        
        padding-top: 16px;
        margin-left: -16px;
    } 

`;

const ProfileBarCenter = styled.div`
    
    flex-grow: 1;  

    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;

    margin-right: 16px;
    margin-left: 16px;

    padding-top: 8px;
    padding-bottom: 8px;

    @media (max-width: 1050px) {
        display: none;
    } 


`;

const ProfileBarCenterFloat = styled.div`
    
    flex-grow: 1;  

    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;

    margin-right: 16px;
    margin-left: 16px;

    padding-top: 8px;
    padding-bottom: 8px;

    @media (max-width: 1050px) {
        display: none;
    } 
`;



const ProfileBarRight = styled.div`
   
    flex-grow: 2;  

    display: flex;
    flex-direction: row;

    /* text-align: right; */

    justify-content: flex-end;
    align-items: center;

    @media (max-width: 790px) {
        margin-right: 12px;
    }

    
`;


const AlbumSection = styled.div`
     
    width: 80%;
    max-width: 1080px;

    margin: auto;

    
    /* background-color: #ebe; */
    
    padding-left: 16px;
    margin-top: 8px;
    
    @media (max-width: 790px) {
        width: calc(100% - 16px)
    }

`;

const SelectedAlbumSection = styled.div`

    width: 100%;

    min-height: 60vh;
    height: calc(60vh + 72px);

    /* background-color: #deb; */

    @media (max-width: 790px) {
        min-height: auto;
        height: auto;
    }

`;


const SelectedPhotoPreview = styled.div`
    
    float: left;

    /* background-color: #ebe;  */

    /* width: calc(100% - 96px); */
    width: 100%;

    /* height: calc(100% - 32px);  */
    min-height: 60vh;
    height: 60vh;
    

    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 790px) {
        width: 100%;

        min-height: auto;
        height: auto;
    }
    
`;

const PhotoContainer = styled.div`
    
    /* width: ${(props) => props.width}; */
    /* height: ${(props) => props.height}; */

    float: left;
    width: 100%;
    height: 100%;
   
    text-align: center;

    margin: 0 auto;

`;

const PhotoContainerContent = styled.div`
   
    position: relative;
    height: 100%;

`;


const PhotoItself = styled.img`
   
    max-width: 100%;
    max-height: 100%;

    /* object-fit: cover; */
    object-fit: contain;
    

    margin: 0 auto;

    /* border: 2px solid red; */
    border-radius: 5px;
    box-shadow: 0px 2px 8px 0 rgba(170, 170, 170, 0.4);


    /* &:hover { cursor: pointer; opacity: 1; }    */

`;


const SelectedPhotoTitle = styled.div`

    width: 100%;
    height: 32px;

    /* margin-bottom: 4px; */

    /* background-color: rgb(0,0,200); */

    text-align: center;

    font-family: 'Fira Sans';
    font-size: 12px;
    line-height: 1.2;
    padding-top: 4px;

    @media (max-width: 790px) {
        width: 100%;
    }

`;

const Thumbs = styled.div`
   
    /* float: right;
    width: 80px;
    min-height: 70vh;
    height: 70vh;

    padding-top: 0px;
    padding-left: 4px;
    

    overflow: hidden; */

    float: left;
    width: 100%;
    height: auto;
    min-height: auto;

    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    
    padding-top: 32px;
    padding-left: 0px;
    padding-bottom: 8px;

    
    /* &:hover { overflow-y: auto; overflow-x: hidden; }     */
    &:hover { overflow-x: auto; overflow-y: hidden; }    

    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        box-shadow: inset 0 0 4px rgb(255, 149, 0, 0.9); 
    }

    @media (max-width: 790px) {
        /* float: left;
        width: 100%;
        height: auto;
        min-height: auto;

        display: flex;
        flex-wrap: wrap;    
        flex-direction: row; */

        padding-top: 8px;
        /* padding-left: 0px; */

        /* &:hover { overflow-x: auto; overflow-y: hidden; }     */
    } 

`;

const Thumb = styled.img`
   
    width: 64px;
    height: 64px;

    object-fit: cover;

    /* margin-bottom: 12px; */
    margin-right: 8px;
    margin-bottom: 8px;
    
    border-radius: 5px;

    box-shadow: 0px 2px 8px 0 rgba(170, 170, 170, 0.3);

    border: ${(props) => props.isselected === 'true' ? "1px solid #D8D8D8" : '0px'};
    
    outline: ${(props) => props.isselected === 'true' ? "2px solid #fff;" : 'none'};
    outline-offset: -5px;
    
    opacity: ${(props) => props.isselected === 'true' ? "1" : '0.5'};
    

    &:hover { cursor: pointer; opacity: 1; transform: scale(1.02); }    

    /* @media (max-width: 790px) {
        margin-right: 12px;
    } */

`;


const ListRow = styled.div`
   
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    top: 16px; 

`;

const AlbumListRow = styled.div`
   
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    margin-top: 0px;
    padding-top: 0px;

    @media (max-width: 790px) {
        margin-top: 8px;
        padding-top: 8px;
    }


`;



// const AlbumTag2 = styled.div`
   
//     display: flex;
//     flex-wrap: wrap;    

//     margin-right: 8px;
//     margin-bottom: 8px;

//     padding-top: 4px;
//     padding-bottom: 4px;
//     padding-left: 16px;
//     padding-right: 16px;
    
//     font-size: 16px;
//     font-weight: 400;
    
//     border-radius: 48px;

//     box-shadow: 0 2px 8px 0 rgba(125, 125, 125, 0.2);

//     border: ${(props) => props.isSelected === 'true' ? "1px solid #fF9500;" : '1px solid #ebebeb;'};
//     background: ${(props) => props.isSelected === 'true' ? "rgb(255, 149, 0, 0.2);" : '#fff;'};
    
//     &:hover {
//         background: ${(props) => props.isSelected === 'true' ? "rgb(255, 149, 0, 0.3);" : '##f8f8f8;'};
//         transform: scale(1.02);
//         cursor: pointer;
//         }


// `;


const AlbumTag2 = styled(Button)`
    && {
    height: 40px;

    padding: 16px;

    margin-right: 16px;
    margin-bottom: 8px;

    background: ${(props) => props.isselected === 'true' ? '#f5f5f5': "white"};
    border: ${(props) => props.isselected === 'true' ? '4px double #cbcbcb;' : "1px solid #cbcbcb;"};

    border-radius: 20px;  


    font-family: 'Fira Sans';
    color: #333333;
    
    text-transform: none;
    font-weight: 400;
    font-size: 16px;

    &:hover {
        border-color: #333333;
        }

    }
    
`;


const LanguageTag = styled.div`
   
    display: flex;
    flex-wrap: wrap;    

    font-family: 'Fira Sans';
    font-size: 17px;
    font-weight: 300;
    
    margin-right: 8px;
    margin-bottom: 8px;
    margin-top: -2px;

    padding-right: 16px;


`;

const CategoryList = styled.div`
  
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;
    padding-top: 4px;

    width: 100%;


`;



const HistoryBackButton = styled(Button)`
    && {
        
    /* margin: -4px -4px 0 0px; */

    height: 48px;
    min-width: 48px;
    max-width: 48px;
    border-radius: 24px;

    margin-left: 26px;
    margin-top: 16px;
    /* margin-right: 4px;
    margin-left: 4px; */
    margin-bottom: -24px;

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;

    
    font-weight: 400;
    font-size: 19px;

    background-color: #EBEBEB;
    opacity: 0.6;

    &:hover {
        background-color: #EBEBEB;
        opacity: 0.6;
        }
    
    &:disabled {
        background-color: #fff;
        color: #fff;
        }

    @media (min-width: 790px) {
        display: none;
    }

    }
    
    
`;


const CardDiv = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;    

    padding-right: 0px;

`;

const CardDivFloat = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;    

    /* padding-left: 8px; */

`;

const ReviewCard = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;    

    padding-left: 0px;
    margin-left: 0px;

    margin-bottom: 32px;

`;

const ReviewHeader = styled.div`
    

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;    

    width: 100%;
    padding-left: 0px;

    margin-bottom: 8px;

`;

const ReviewMessage = styled.div`
    
    display: flex;
    flex-direction: row;

    font-family: 'Fira Sans';
    font-style: italic;
    font-size: 17px;
    font-weight: 300;

    border-left: 2px solid #ddd;
    padding-left: 8px;

    width: 100%;

`;

const ResponseHeader = styled.div`
    
    display: flex;
    flex-direction: row;

    font-family: 'Fira Sans';
    font-size: 17px;
    font-weight: 400;

    margin-top: 16px;
    margin-left: 64px; 
    width: 100%;

`;

const ResponseMessage = styled.div`
    
    display: flex;
    flex-direction: row;

    font-family: 'Fira Sans';
    font-style: italic;
    font-size: 17px;
    font-weight: 300;

    border-left: 2px solid #ddd;
    padding-left: 8px;

    margin-top: 16px;
    margin-left: 64px; 
    width: 100%;

`;



const ReviewAvatarArea = styled.div`
    
    width: 64px; 
    margin: 0px;

    
    /* float: left */

`;





const ReviewAvatarFloat = styled.img`

    height: 48px;
    width: 48px;
    border-radius: 48px;
    margin-left: 0px;
    
    
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.4);

    @media (max-width: 1050px) {
        margin-left: 8px;
        
    } 

`;


const MemberInfoCard = styled.div`
    
    padding-left: 8px;
    width: calc(100% - 90px);

`;

const MemberInfoCardFloat = styled.div`
    
    /* padding-left: 24px; */
    width: 100%;
    min-width: 100%;
    justify-content: flex-start;

    @media (max-width: 1300px) {

        margin-left: 24px;
        padding: 8px;
        width: 100%;
        min-width: 100%;
        
    } 
    

`;


const ReviewTitle = styled.div`
    
    width: calc(100% - 80px);
    min-width: calc(100% - 80px);
    justify-content: flex-start;

    

`;

const MemberBadgesRow = styled.div`
    
    display: none;
    
    width: calc(100% - 80px);
    margin-top: 12px;
    padding-left: 8px;

    @media (max-width: 1050px) {
        display: flex;
        flex-wrap: wrap;    
        flex-direction: row;
        

    } 
        
`;

const MemberBadgesRowFloat = styled.div`
    
    display: none;
    
    width: calc(100% - 80px);
    margin-left: 16px;
    margin-top: 8px;

    @media (max-width: 1050px) {
        display: flex;
        flex-wrap: wrap;    
        flex-direction: row;
        margin-left: 32px;

    } 
        
`;



const CardTitle = styled.h1`
    
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 2px;
    /* @media (max-width: 790px) {
        display: none;
        } */

`;

const SubTitle = styled.div`
    
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5em;
    
    /* @media (max-width: 790px) {
        display: none;
        }
     */

`;

const CardTitleFloat = styled.div`
    
    font-size: 17px;
    font-weight: 400;

    margin-top: 2px;
    /* @media (max-width: 790px) {
        display: none;
        } */

`;

const SubTitleFloat = styled.div`
    
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5em;
    
    /* @media (max-width: 790px) {
        display: none;
        }
     */

`;

const GreyButtons = styled.div`

    display: flex;
    position: absolute;

    flex-direction: row;
    justify-content: space-between;

    width: calc(100% - 8px);
    min-width: calc(100% - 8px);

    margin-top: -48px;
    top: calc(50% + 24px);
    
    z-index: 800;

    /* background-color: #ebe; */
    padding-left: 4px;
    padding-right: 4px;
    
    @media (max-width: 790px) {
        top: calc(50% + 12px);
    }


`;


const GreyButton = styled(Button)`
    && {
        
    /* margin: -4px -4px 0 0px; */

    height: 48px;
    min-width: 48px;
    max-width: 48px;
    border-radius: 24px;

    /* margin-right: 4px;
    margin-left: 4px; */

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;
    font-weight: 400;
    font-size: 19px;

    background-color: #EBEBEB;
    opacity: 0.6;

    &:hover {
        background-color: #EBEBEB;
        opacity: 0.6;
        }
    
    &:disabled {
        background-color: #fff;
        color: #fff;
        }

    }
    
`;

const FAIcon = styled(FontAwesomeIcon)`
    && {
    font-size: 21px;
    font-weight: 400;
    }
`;


const Section = styled.div`
      
    /* background-color: #bee; */
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 80%;
    max-width: 1080px;
    
    padding-left: 16px;
    padding-right: 16px;
    
    justify-content: space-between;

    font-family: 'Fira Sans', sans-serif; 
    font-size: 17px;
    line-height: 25px;

    white-space: pre-line;
    font-weight: 300;
    color: #333333;

    margin: 32px auto 0px auto;


    @media (max-width: 1350px) {
        width: calc(100% - 16px);
        margin-right: 16px;
    }



`;

const SectionTitle = styled.div`
    
    width: 20%;

    font-family: 'Fira Sans';
    font-size: 19px;
    font-weight: 500;

    padding-top: 2px; 

    @media (max-width: 1050px) {
        width: 100%;
    }

`;


const WideSectionTitle = styled.h2`
    
    width: 100%;

    font-family: 'Fira Sans';
    font-size: 19px;
    font-weight: 500;

    padding-top: 2px; 

    @media (max-width: 1050px) {
        font-size: 21px;
    }

`;


const WideSectionContent = styled.div`
    
    width: 100%;

    font-family: 'Fira Sans';
    font-size: 17px;
    font-weight: 300;
    
    padding-top: 8px;


    @media (max-width: 1050px) {
        font-size: 19px;
    }

`;

const SectionTitleSmaller = styled.div`
    
    font-family: 'Fira Sans';
    font-size: 19px;
    font-weight: 300;

    

    padding-top: 2px; 

`;

const SectionContent = styled.div`
    
    /* background-color: #eaf; */
    width: calc(80% - 32px);
    max-width: 1080px;

    font-family: 'Fira Sans';
    font-size: 17px;
    font-weight: 300;
    
    padding-top: 4px;

    margin-top: 0;
    
    display: absolute;

    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;

    @media (max-width: 1050px) {

        width: 100%;
        padding-left: 0px;
        padding-top: 16px;

        
    }


`;


const IncreaseHeight = styled.div`
    
    height: 16px;  

`;

const AlbumSpacer = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;

`;






const BtnSecondary = styled(Button)`
    && {
    
    display: flex;

    min-height: 40px;
    
    padding-left: 16px;
    padding-right: 16px;

    margin-right: 16px;

    text-transform: none;
    text-decoration: underline;
    text-underline-offset: 2px;

    font-weight: 400;
    font-size: 17px;
    color: #333333;
    
    @media (max-width: 1200px) {
        display: none;
    }

    &:hover {
        opacity: 0.9;
        box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};
        text-decoration: underline;
        }
    }
    
    
`;

const BtnSecondaryCancel = styled(Button)`
    && {
    
    display: flex;

    min-height: 40px;
    
    padding-left: 16px;
    padding-right: 16px;

    margin-right: 16px;
    margin-left: 16px;

    text-transform: none;
    text-decoration: underline;
    text-underline-offset: 2px;

    font-weight: 400;
    font-size: 17px;
    color: #333333;
    

    &:hover {
        opacity: 0.9;
        box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};
        text-decoration: underline;
        }
    }
    
    
`;

const BtnSecondaryShort = styled(Button)`
    && {

    display: none;
    
    min-height: 40px;
    min-width: 40px;
    
    margin-right: 16px;

    text-transform: none;
    text-decoration: underline;
    text-underline-offset: 2px;

    font-weight: 400;
    font-size: 17px;
    color: #333333;
    
    @media (max-width: 1200px) {
        display: flex;
    }

    &:hover {
        opacity: 0.9;
        box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};
        text-decoration: underline;
        }
    }
    
`;


const BtnBook = styled(Button)`
    && {
    min-height: 40px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    color: white;
    
    padding-left: 16px;
    padding-right: 16px;

    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    
    &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }

    @media (max-width: 790px) {
        margin-right: ${(props) => props.movetoright === 'true' ? '-16px' : '0px'};
    }

}

    
    
`;

const BtnIcon = styled.img`

    width: 20px;
    height: 20px;
    margin-right: 8px;         

`;

const BtnIconNoMargin = styled.img`

    height: 24px;
    margin-right: 8px;        

`;

const Badge = styled.img`

    margin-right: 8px;
    height: 64px;
    margin-top: -2px;

    &:last-child {
        margin-right: 0px;
    }

`;

const VerifiedBadge = styled.img`

    margin-left: 8px;

    height: 20px;
    
`;


const Badge2 = styled.img`

    margin-right: 8px;
    height: 64px;
    margin-top: 2px;

    &:last-child {
        margin-right: 0px;
    }

`;


const ModalTitle = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: flex-start;
    justify-content: flex-start;

    /* background-color: #ebe; */

    width: 100%;

    margin-left: 16px;
    margin-bottom: 24px;
    /* margin-top: -4px; */
    /* padding-left: 16px; */

`;

const SectionHeadline = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 500;
    font-size: 20px;

    width: 80%;

    /* color: #505050; */
    /* color: #383838; */
    color: #333333;

    /* background-color: #ded; */

`;

const ButtonArea = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: left;
    justify-content: flex-start;
    
    width: 100%;
    min-height: 40px;

    /* background-color: #ebe; */

`;

const TextareaStyled = styled.textarea`
    
    width: 100%;

    padding: 0px;
    margin: 0px;
    /* height: 24px; */
    padding: 8px;
    
    font-size: 18px;
    font-family: 'Fira Sans';
    font-weight: 300;

    color: #333333;
    
    border: 1px solid #cbcbcb;
    border-radius: 4px;  
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);    

    :focus {
        outline-color: #4C9AFF;
    }

    :disabled {
        background-color: #f7f7f7; 
    }

    @media (max-width: 690px) {
        font-size: 18px;
    }
`;




const AvatarArea = styled.div`
    
    width: 64px; 
    
`;

const HostAvatarFloat = styled.img`

    height: 64px;
    width: 64px;
    border-radius: 64px;

    margin-top: -6px;
    
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.4);
    
    @media (max-width: 1050px) {
        margin-left: 0px;
    }

`;


const HostTitle = styled.div`
    
    padding-left: 16px;
    width: calc(100% - 164px);
    min-width: calc(100% - 164px);
    
    justify-content: flex-start;

    margin-left: 0px;
    

    @media (max-width: 1050px) {

        width: calc(100% - 120px);
        min-width: calc(100% - 120px);
        
    } 
    

`;
