
import React, { Component } from 'react';
import styled from 'styled-components';
// import TimeAgo from './../../utils/TimeAgo'
import moment from "moment"; 
import i18next from "i18next";
import '../../../i18n'


export class EventCard extends Component {
    

    render() {
      
        moment.locale(i18next.language);
        
        const dates = this.props.dates

        const status = this.props.status

        const today = new Date();

        // console.log('this.props', this.props)
        // console.log(moment(dates[0].start))
        // console.log(moment(today))


        // if (moment(dates[0].start) < moment(today)) {
        //     console.log('vaiksem')
        // }

        //console.log('this.props.coverPhoto', this.props.coverPhoto)


        return (
        
            <>

                <CardContainer
                    onClick={this.props.onClick}>
                

                    <CoverPhoto>
                        
                        <CoverImage 
                            src={this.props.coverPhoto} 
                            alt="Event cover photo" 
                            onLoad={(e) => {
                                //e.target.src = this.props.coverPhoto  --- causes huge CPU spike. but looks like not necessary anyway!
                                e.target.style = 'display: flex' 
                            }}
                            onError={(e) => {
                                //e.target.src = '' --- causes huge CPU spike. but looks like not necessary anyway!
                                e.target.style = 'display: none' 
                            }}
                        />
                        
                    </CoverPhoto>


                    <IncreaseHeight/>


                    <TitleLine>{this.props.title}</TitleLine>

                    <SecondLine>
                            {
                                this.props.virtual === true ?
                                    <>
                                        Online event
                                    </>
                                :
                                    <>
                                        {this.props.location}
                                    </>

                            }
                        
                    </SecondLine>

                    {
                        

                        dates.map((dateElement, index) => (

                            <div key={index}>

                            {
                                moment(dates[index].start) > moment(today) ?
                                    
                                    <>
                                        
                                        <SecondLine>

                                            { 
                                                dates[index].start === dates[index].end ?
                                                    moment(dates[index].start).format('MMMM D, YYYY') + ' (' + dates[index].st + ' - ' + dates[index].et + ')'
                                                :
                                                    moment(dates[index].start).format('MMMM') === moment(dates[index].end).format('MMMM') ?
                                                        moment(dates[index].start).format('MMMM D') + ' - ' + moment(dates[index].end).format('D, YYYY')
                                                    :
                                                    moment(dates[index].start).format('YYYY') === moment(dates[index].end).format('YYYY') ?
                                                        moment(dates[index].start).format('MMMM D') + ' - ' + moment(dates[index].end).format('MMMM D, YYYY')
                                                    :
                                                    moment(dates[index].start).format('MMMM D, YYYY') + ' - ' + moment(dates[index].end).format('MMMM D, YYYY')

                                            }


                                            <SecondLineRight>
                                                {
                                                    dates[index].price < 0.1 ?
                                                        <>free</>
                                                    :
                                                        dates[index].price + " " + dates[index].currency
                                                }
                                            </SecondLineRight>

                                            <SecondLineRight>
                                                {

                                                    dates[index].lenDays === 0 ? 
                                                        dates[index].lenHours === 0 ? null
                                                        :
                                                        dates[index].lenHours === 1 ? 
                                                            <>{dates[index].lenHours + " hour,"}</>
                                                            :
                                                            <>{dates[index].lenHours + " hours,"}</>
                                                    :
                                                    dates[index].lenDays === 1 ? 
                                                        <>{dates[index].lenDays + " day,"}</>
                                                    :
                                                    <>{dates[index].lenDays + " days,"}</>

                                                }
                                            </SecondLineRight>

                                        </SecondLine>

                                        <IncreaseHeight/>


                                    </>
                                    :
                                    null

                            }
                            
                            </div>

                        ))

                    }
                    

                   



                    { status === 0 ? <EventIsHidden>Inactive & Hidden</EventIsHidden> : null} 


                    {/* #UPDATE: Profile card: If no active upcoming dates, say so with a tag (like it is "Inactive & Hidden" but say "No upcoming dates set"). List all upcoming dates under the card? */}


                </CardContainer> 

            </>
        
        );

    }


}

const TitleLine = styled.div`
    
    font-size: 17px;
    font-weight: 400;
    width: 99%;

    padding-left: 4px;
    padding-right: 4px;
    
    line-height: 1.3em;
    max-height: 2.6em;
    
    overflow: hidden !important; 
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    margin-bottom: 4px;

`;

const SecondLine = styled.div`
    
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5em;

    width: 99%;

    padding-left: 4px;
    padding-right: 4px;

`;

const SecondLineRight = styled.div`
    
    text-align: right;
    float: right; 
    margin-left: auto;
    padding-left: 4px;

`;


const IncreaseHeight = styled.div`
    
    height: 8px;  

`;

const CardContainer = styled.div`
    
    
    width: 300px;
    height: 315px;

   
    margin: 16px 16px 8px 16px;
    margin-left: 24px;
    align-items: flex-start;
    border-radius: 8px;
    
    border: none;
    z-index: 300;
    

    overflow: hidden;

    &:hover {
        transform: scale(1.01);
        cursor: pointer;
        color: #4C9AFF;
       
    }
    
    @media (max-width: 1000px) {
        width: 100%;
        height: auto;
       
    }

    @media (max-width: 500px) {
        width: 100%;
        height: auto;
       
    }
`;


const CoverPhoto = styled.div`

    width: 300px;
    height: 200px;
    
    align-items: flex-start;

    border-radius: 8px;
    z-index: 400;

    border: 1px solid #EBEBEB;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);


    &:hover {
        transform: scale(1.01);
        cursor: pointer;
       
    }
    
    @media (max-width: 1000px) {
        width: 100%;
        height: 350px;
    }

    @media (max-width: 500px) {
        width: 100%;
        height: 300px;
       
    }    
    

`;


const CoverImage = styled.img`

    width: 100%;
    height: 100%;
    
    object-fit: cover;

    border-radius: 5px;
    position: relative;

`;

const EventIsHidden = styled.div`
    
    text-align: left;
    float: left; 
    
    margin: 4px;
    padding: 4px;

    border-radius: 2px;
    

    color: #fff;
    background-color: rgba(255, 0, 0, 0.6);

    font-size: 16px;
    font-weight: 400;

    line-height: 1.2em;

`;
