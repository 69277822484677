import React, { PureComponent } from 'react'

import { Field } from "formik";
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';

import { withApollo } from "react-apollo";
import { withStore } from '@spyna/react-store'

import Button from '@material-ui/core/Button';
import Modal from 'react-modal';

//import ReactGA from 'react-ga4';
//import { Formik, Form } from "formik";

import moment from "moment"; 
import { v4 as uuidv4 } from 'uuid';

import { MyInputField } from "../../../components/MyInputField";
import { MySelectField } from "../../../components/MySelectField";
import { MyCategoryTag } from "../../../components/MyCategoryTag";
import { MyError } from "../../../components/MyError";
import ConfirmDelete from '../ConfirmDelete';

import { UpgradeModal } from "../../../components/UpgradeModal";

//import logger from '../../../../utils/logger'

import MyPlacesAutoComplete from "../../../components/MyPlacesAutoComplete";
import MyLanguageSelection from "../../../components/MyLanguageSelection";
import PremiumURLGeneration from '../PremiumURLGeneration'; 
import InputNumber from 'rc-input-number';

import { SingleDatePicker } from 'react-dates';
import './../../../../styles/ReactDatesOverride.css';
import Select from 'react-select'

import ISO6391 from 'iso-639-1';
import i18next from "i18next";
import '../../../../i18n'

import gql from "graphql-tag"


const findURL = gql`
    query($URL: String!) {
        findURL(URL: $URL) {
            profileType,
            profileId,
            avatar,
        }
    }
`;


export const editEvent = gql`
    mutation( $profileId: String! $input: EventInput! ) {
        editEvent( profileId: $profileId, input: $input ) { id }
    }
`;




class C extends PureComponent { 
    
        
    state = {
        uploadState: "none",
        showPremiumURLGeneration: false,
        languages: this.props.values.languages,
        showCategorySelection: false,
        showDatesDialog: false,
        showUpgradeModal: false,

    };

    
    addPackage = async () => {

        const today = new Date();

        this.setState({
            packageId: '---#new#---',
            packageStartDate: moment(today).format('YYYY-MM-DD'), //no empty string! error. better null
            packageStartTime: '10:00',
            packageEndDate: moment(today).format('YYYY-MM-DD'),  //no empty string! error. better null
            packageEndTime: '17:00',

            packagePrice: 0,
            packageCurrency: this.props.store.get('currency'),
            // packageDeposit: 0,

            // packageFullPayment: true,

            packageLimit: 0,
            packageReserved: 0,
            packageBooked: 0,
            packageAvailable: 0,

            packageConfirmDelete: false,
        }); 

        this.toggleDatesDialog();

    }

    editPackageDetails = (params) => {

        let packageAvailable = (params.limit - params.reserved - params.booked);

        this.setState({
            packageId: params.id,
            
            packageStartDate: params.start,
            packageStartTime: params.st,
            packageEndDate: params.end,
            packageEndTime: params.et,

            packagePrice: params.price,
            packageCurrency: params.currency,
            // packageDeposit: params.packageDeposit,

            // packageFullPayment: params.packageFullPayment,

            packageLimit: params.limit,
            packageReserved: params.reserved,
            packageBooked: params.booked,
            
            packageAvailable: packageAvailable,

            packageConfirmDelete: false,

        }); 

        this.toggleDatesDialog();

    }


    savePackage = async () => {


        // logger('savePackage executed', 'info', { email: this.props.store.get('email') })


        let allowedtoSave = true;

        // //make sure no empty name and desc fields!
        // this.props.values.languages.map((language, index) => {
        //     const fldPackageNameLng = 'fldPackageName_' + language;
        //     const fldPackageDescriptionLng = 'fldPackageDescription_' + language;
        //     if ((this.state[fldPackageNameLng] === '') || (this.state[fldPackageDescriptionLng] === '')) {
        //         setTimeout(() => { this.setState({ errorMsg: `Package name and description fields cannot be left empty`, }); }, 50);
        //         allowedtoSave = false;
        //     }
        //     return null
        // })


        if (allowedtoSave) {

            var tempuuid = uuidv4();

            var astart = moment(this.state.packageStartDate, "YYYY-MM-DD");
            var aend = moment(this.state.packageEndDate, "YYYY-MM-DD");
            var calcLenDays = moment.duration(aend.diff(astart)).asDays();
            
            const use24h = this.localeUses24HourTime(); 
            var tmpStartValue = this.timeOptions(use24h).find(op => { return op.code === this.state.packageStartTime })
            var tmpEndValue = this.timeOptions(use24h).find(op => { return op.code === this.state.packageEndTime })
            var calcLenHours = parseInt((parseInt(tmpEndValue.value)-parseInt(tmpStartValue.value))/2);


            if (this.state.packageId === '---#new#---') {
                
                let arr = this.props.values.dates;
                
                await arr.push({
                    
                    id: tempuuid, // generate our own id (as db id is generated on save, but we might not save the album yet), until the album is saved to the database.
                    start: this.state.packageStartDate,
                    st: this.state.packageStartTime,
                    end: this.state.packageEndDate,
                    et: this.state.packageEndTime,

                    lenDays: calcLenDays,
                    lenHours: calcLenHours,

                    price: parseFloat(this.state.packagePrice),
                    currency: this.state.packageCurrency,
                    // deposit: parseFloat(this.state.packageDeposit),
                    // fullPayment: this.state.packageFullPayment,

                    limit: parseInt(this.state.packageLimit),
                    reserved: parseInt(this.state.packageReserved),
                    booked: parseInt(this.state.packageBooked),

                    
                })

                //sort the dates array from earliest to the latest...
                arr.sort(function(dt1, dt2) {
                    var date1 = moment(dt1.start);
                    var date2 = moment(dt2.start);
                    if (date1 < date2) { return -1; }
                    if (date1 > date2) { return 1; }
                    return 0;
                });

                this.props.values.dates = arr;
                
            } else {

                for (var i = this.props.values.dates.length-1; i >= 0; i--) {

                    if (this.props.values.dates[i].id === this.state.packageId) { 

                        tempuuid = this.props.values.dates[i].id;
                        
                        this.props.values.dates[i].start = this.state.packageStartDate;
                        this.props.values.dates[i].st = this.state.packageStartTime;
                        this.props.values.dates[i].end = this.state.packageEndDate;
                        this.props.values.dates[i].et = this.state.packageEndTime;
                        
                        this.props.values.dates[i].lenDays = calcLenDays;
                        this.props.values.dates[i].lenHours = calcLenHours;
                        
                        this.props.values.dates[i].price = parseFloat(this.state.packagePrice);
                        this.props.values.dates[i].currency = this.state.packageCurrency;
                        // this.props.values.dates[i].deposit = this.state.packageDeposit;
                        // this.props.values.dates[i].fullPayment = this.state.packageFullPayment;

                        this.props.values.dates[i].limit = parseInt(this.state.packageLimit);
                        this.props.values.dates[i].reserved = parseInt(this.state.packageReserved);
                        this.props.values.dates[i].booked = parseInt(this.state.packageBooked);
                        
                    } 
                }

                let arr = this.props.values.dates;

                // console.log('arr: ', arr)

                arr.sort(function(dt1, dt2) {
                    var date1 = moment(dt1.start);
                    var date2 = moment(dt2.start);
                    if (date1 < date2) { return -1; }
                    if (date1 > date2) { return 1; }
                    return 0;
                });

                // console.log('sorted arr: ', arr)

            }

            this.setState({
                package: '',
                packageId: '',
                errorMsg: '',
            });

            this.forceUpdate();
            this.toggleDatesDialog();
            this.updateValues();
        }

    }

    cancelPackageEdit = () => {

        this.setState({
            package: '',
            packageId: '',
            errorMsg: '',
        });

        this.forceUpdate();
        this.toggleDatesDialog();

    }

    deletePackage = (deletePackageId) => {

        var packagesArray = this.props.values.dates;
        var packageRemovedArray = packagesArray.filter(function(pakett) { return pakett.id !== deletePackageId });
        this.props.values.dates = packageRemovedArray;

        this.setState({  
            packageConfirmDelete: false,
            package: '',
            packageId: '',
        });

        this.forceUpdate();
        this.toggleDatesDialog();
        this.updateValues();

    };


    updateValues = () => {

        var tempArray = this.props.values.dates;
        for (var z = tempArray.length-1; z >= 0; z--) {
            delete tempArray[z].__typename
        }
        
        const inputValue = {
            dates: tempArray
        }

        this.props.client.mutate({
            mutation: editEvent,
            variables: { profileId: this.props.values.id, input: inputValue },
        }).then(results => {
            //console.log('# updatePackages result: ', results)
        }).catch(error => {
            // console.log("Error: ", error);
        })


    } 

        
    numberFieldChange(name, value) {

        this.setState({ [name]: parseFloat(value), });

    }

    toggleCategorySelection = () => {  
        this.setState({  
            showCategorySelection: !this.state.showCategorySelection 
        });  
    }  


    toggleDatesDialog = () => {  

        this.setState({ 
            showDatesDialog: !this.state.showDatesDialog 
        });  

    }  

    togglePackageConfirmDelete = () => {  

        this.setState({  
            packageConfirmDelete: !this.state.packageConfirmDelete 
        });  

    }  

    componentDidMount() {

        //ReactGA.pageview(window.location.pathname + window.location.search);

        this.setState({
            uploadState: "none",
        });

        // this.props.showImmediateSaveLabel(true);

    
    }

    componentDidUpdate(prevProps, prevState) {

       //console.log(prevProps)
        //console.log(prevState)
        if (this.state.uploadState === "completed") {

            this.setState({
                uploadState: "finalized"
            });

            //console.log('didupdate : ', this.state.uploadState);

        }

    }

    onEditCoverPhoto = () => {
            
        this.upload.click()

        this.setState({
            uploadState: "started",
        });

    
    };


    doForceLanguageUpdate = (array) => {
        this.forceUpdate()
    }


    togglePremiumURLGeneration = () => {  

        if (this.props.values.isPro === true) {

            this.setState({  
                showPremiumURLGeneration: !this.state.showPremiumURLGeneration 
            });  

        } else {

            this.setState({ showUpgradeModal: true });

        }

    }  


    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }


    fldNameValueChange = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }
    

    fldNumberValueChange = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.props.values[name] = parseInt(value)

    }
    

    

    findTitleLngIndex = (lng) => {

        let foundIndex = 0;
        for (var i = this.props.values.title.length-1; i >= 0; i--) {
            if (this.props.values.title[i].lng === lng) {
                foundIndex = i;
            }
        }

        return foundIndex

    }

    updateCategory = (categoryId, setChecked) => {

        if (setChecked === true) {

            this.props.values.categories.push(parseInt(categoryId))

        } else {

            //remove from array
            var categoriesArray = this.props.values.categories;
            var categoryRemovedArray = categoriesArray.filter(function(cat) { return cat !== parseInt(categoryId) });
            this.props.values.categories = categoryRemovedArray;

        }
        
        this.forceUpdate()

    }

    
    timeOptions = (use24h) => {

        if (use24h) {
            return [
                { value: '0', code: '00:00', label: '00:00' },
                { value: '1', code: '00:30', label: '00:30' },
                { value: '2', code: '01:00', label: '01:00' },
                { value: '3', code: '01:30', label: '01:30' },
                { value: '4', code: '02:00', label: '02:00' },
                { value: '5', code: '02:30', label: '02:30' },
                { value: '6', code: '03:00', label: '03:00' },
                { value: '7', code: '03:30', label: '03:30' },
                { value: '8', code: '04:00', label: '04:00' },
                { value: '9', code: '04:30', label: '04:30' },
                { value: '10', code: '05:00', label: '05:00' },
                { value: '11', code: '05:30', label: '05:30' },
                { value: '12', code: '06:00', label: '06:00' },
                { value: '13', code: '06:30', label: '06:30' },
                { value: '14', code: '07:00', label: '07:00' },
                { value: '15', code: '07:30', label: '07:30' },
                { value: '16', code: '08:00', label: '08:00' },
                { value: '17', code: '08:30', label: '08:30' },
                { value: '18', code: '09:00', label: '09:00' },
                { value: '19', code: '09:30', label: '09:30' },
                { value: '20', code: '10:00', label: '10:00' },
                { value: '21', code: '10:30', label: '10:30' },
                { value: '22', code: '11:00', label: '11:00' },
                { value: '23', code: '11:30', label: '11:30' },
                { value: '24', code: '12:00', label: '12:00' },
                { value: '25', code: '12:30', label: '12:30' },
                { value: '26', code: '13:00', label: '13:00' },
                { value: '27', code: '13:30', label: '13:30' },
                { value: '28', code: '14:00', label: '14:00' },
                { value: '29', code: '14:30', label: '14:30' },
                { value: '30', code: '15:00', label: '15:00' },
                { value: '31', code: '15:30', label: '15:30' },
                { value: '32', code: '16:00', label: '16:00' },
                { value: '33', code: '16:30', label: '16:30' },
                { value: '34', code: '17:00', label: '17:00' },
                { value: '35', code: '17:30', label: '17:30' },
                { value: '36', code: '18:00', label: '18:00' },
                { value: '37', code: '18:30', label: '18:30' },
                { value: '38', code: '19:00', label: '19:00' },
                { value: '39', code: '19:30', label: '19:30' },
                { value: '40', code: '20:00', label: '20:00' },
                { value: '41', code: '20:30', label: '20:30' },
                { value: '42', code: '21:00', label: '21:00' },
                { value: '43', code: '21:30', label: '21:30' },
                { value: '44', code: '22:00', label: '22:00' },
                { value: '45', code: '22:30', label: '22:30' },
                { value: '46', code: '23:00', label: '23:00' },
                { value: '47', code: '23:30', label: '23:30' },
            ]
        } else {
            return [
                { value: '0', code: '00:00', label: '12:00 AM' },
                { value: '1', code: '00:30', label: '12:30 AM' },
                { value: '2', code: '01:00', label: '01:00 AM' },
                { value: '3', code: '01:30', label: '01:30 AM' },
                { value: '4', code: '02:00', label: '02:00 AM' },
                { value: '5', code: '02:30', label: '02:30 AM' },
                { value: '6', code: '03:00', label: '03:00 AM' },
                { value: '7', code: '03:30', label: '03:30 AM' },
                { value: '8', code: '04:00', label: '04:00 AM' },
                { value: '9', code: '04:30', label: '04:30 AM' },
                { value: '10', code: '05:00', label: '05:00 AM' },
                { value: '11', code: '05:30', label: '05:30 AM' },
                { value: '12', code: '06:00', label: '06:00 AM' },
                { value: '13', code: '06:30', label: '06:30 AM' },
                { value: '14', code: '07:00', label: '07:00 AM' },
                { value: '15', code: '07:30', label: '07:30 AM' },
                { value: '16', code: '08:00', label: '08:00 AM' },
                { value: '17', code: '08:30', label: '08:30 AM' },
                { value: '18', code: '09:00', label: '09:00 AM' },
                { value: '19', code: '09:30', label: '09:30 AM' },
                { value: '20', code: '10:00', label: '10:00 AM' },
                { value: '21', code: '10:30', label: '10:30 AM' },
                { value: '22', code: '11:00', label: '11:00 AM' },
                { value: '23', code: '11:30', label: '11:30 AM' },
                { value: '24', code: '12:00', label: '12:00 PM' },
                { value: '25', code: '12:30', label: '12:30 PM' },
                { value: '26', code: '13:00', label: '01:00 PM' },
                { value: '27', code: '13:30', label: '01:30 PM' },
                { value: '28', code: '14:00', label: '02:00 PM' },
                { value: '29', code: '14:30', label: '02:30 PM' },
                { value: '30', code: '15:00', label: '03:00 PM' },
                { value: '31', code: '15:30', label: '03:30 PM' },
                { value: '32', code: '16:00', label: '04:00 PM' },
                { value: '33', code: '16:30', label: '04:30 PM' },
                { value: '34', code: '17:00', label: '05:00 PM' },
                { value: '35', code: '17:30', label: '05:30 PM' },
                { value: '36', code: '18:00', label: '06:00 PM' },
                { value: '37', code: '18:30', label: '06:30 PM' },
                { value: '38', code: '19:00', label: '07:00 PM' },
                { value: '39', code: '19:30', label: '07:30 PM' },
                { value: '40', code: '20:00', label: '08:00 PM' },
                { value: '41', code: '20:30', label: '08:30 PM' },
                { value: '42', code: '21:00', label: '09:00 PM' },
                { value: '43', code: '21:30', label: '09:30 PM' },
                { value: '44', code: '22:00', label: '10:00 PM' },
                { value: '45', code: '22:30', label: '10:30 PM' },
                { value: '46', code: '23:00', label: '11:00 PM' },
                { value: '47', code: '23:30', label: '11:30 PM' },
            ]
        }
    }



    currencyOptions = () => {
        return [
            { value: '0', code: 'AUD', label: 'Australian dollar' },
            { value: '1', code: 'BRL', label: 'Brazilian real' },
            { value: '2', code: 'BGN', label: 'Bulgarian lev' },
            { value: '3', code: 'CAD', label: 'Canadian dollar' },
            { value: '4', code: 'CLP', label: 'Chilean peso' },
            { value: '5', code: 'CNY', label: 'Chinese yuan' },
            { value: '6', code: 'COP', label: 'Colombian peso' },
            { value: '7', code: 'CRC', label: 'Costa Rican colon' },
            { value: '8', code: 'HRK', label: 'Croatian kuna' },
            { value: '9', code: 'CZK', label: 'Czech koruna' },
            { value: '10', code: 'DKK', label: 'Danish krone' },
            { value: '11', code: 'AED', label: 'Emirati dirham' },
            { value: '12', code: 'EUR', label: 'Euro' },
            { value: '13', code: 'HKD', label: 'Hong Kong dollar' },
            { value: '14', code: 'HUF', label: 'Hungarian forint' },
            { value: '15', code: 'INR', label: 'Indian rupee' },
            { value: '16', code: 'ILS', label: 'Israeli new shekel' },
            { value: '17', code: 'JPY', label: 'Japanese yen' },
            { value: '18', code: 'MYR', label: 'Malaysian ringgit' },
            { value: '19', code: 'MXN', label: 'Mexican peso' },
            { value: '20', code: 'MAD', label: 'Moroccan dirham' },
            { value: '21', code: 'TWD', label: 'New Taiwan dollar' },
            { value: '22', code: 'NZD', label: 'New Zealand dollar' },
            { value: '23', code: 'NOK', label: 'Norwegian krone' },
            { value: '24', code: 'PEN', label: 'Peruvian sol' },
            { value: '25', code: 'PHP', label: 'Philippine peso' },
            { value: '26', code: 'PLN', label: 'Polish zloty' },
            { value: '27', code: 'GBP', label: 'Pound sterling' },
            { value: '28', code: 'RON', label: 'Romanian leu' },
            { value: '29', code: 'RUB', label: 'Russian ruble' },
            { value: '30', code: 'SAR', label: 'Saudi Arabian riyal' },
            { value: '31', code: 'SGD', label: 'Singapore dollar' },
            { value: '32', code: 'ZAR', label: 'South African rand' },
            { value: '33', code: 'KRW', label: 'South Korean won' },
            { value: '34', code: 'SEK', label: 'Swedish krona' },
            { value: '35', code: 'CHF', label: 'Swiss franc' },
            { value: '36', code: 'THB', label: 'Thai baht' },
            { value: '37', code: 'TRY', label: 'Turkish lira' },
            { value: '38', code: 'USD', label: 'United States US dollar' },
            { value: '39', code: 'UYU', label: 'Uruguayan peso' },
        ]
    }


    
    cancelOptions = () => {
        return [
            { value: '1', code: '1', label: '24 hours' },
            { value: '2', code: '2', label: '2 days' },
            { value: '3', code: '3', label: '3 days' },
            { value: '5', code: '5', label: '5 days' },
            { value: '7', code: '7', label: '7 days' },
        ]
    }

    cancelOptions2 = () => {
        return [
            { value: '3', code: '3', label: '3 days' },
            { value: '5', code: '5', label: '5 days' },
            { value: '7', code: '7', label: '7 days' },
            { value: '14', code: '14', label: '2 weeks' },
            { value: '28', code: '28', label: '4 weeks' },
            { value: '60', code: '60', label: '2 months' },
            { value: '90', code: '90', label: '3 months' },
        ]
    }

    cancelOptions3 = () => {
        return [
            { value: '10', code: '10', label: '10%' },
            { value: '20', code: '20', label: '20%' },
            { value: '25', code: '25', label: '25%' },
            { value: '30', code: '30', label: '30%' },
            { value: '35', code: '35', label: '35%' },
            { value: '40', code: '40', label: '40%' },
        ]
    }

    cancelOptions4 = () => {
        return [
            { value: '24', code: '24', label: '24 hours' },
            { value: '48', code: '48', label: '48 hours' },
            { value: '72', code: '72', label: '72 hours' },
        ]
    }


    localeUses24HourTime = (locale) => {

        // if locale === undefined => current user's locale, otherwise uses "locale"

        //returns true, if 24H time is used in this locale/language
        return new Intl.DateTimeFormat(locale, { hour: 'numeric' })
            .formatToParts(new Date(2020, 0, 1, 13))
            .find(part => part.type === 'hour')
            .value.length === 2;

    }
      
    handleChange = event => {

        this.setState({
            [event.target.value]: event.target.checked,
        });
    
        this.props.values[event.target.value] = event.target.checked ? true : false

    };

    fldValueChange = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }
    
    render() {

        moment.locale(i18next.language);
        // const startOfWeek = moment().startOf('week').isoWeekday();  // const endOfWeek   = moment().endOf('week').isoWeekday();
        const use24h = this.localeUses24HourTime(); 
        
        const statuses = [
            { value: '0', label: 'Inactive & hidden' },
            { value: '1', label: 'Visible to everyone' },
            { value: '2', label: 'Visible to registered users only' },
            { value: '3', label: 'Visible to registered Pro members only' },
            { value: '4', label: 'Visible to Verified Pro members only' },
        ];

        const categories = [
            
            { value: '1', label: 'Boudoir' },
            { value: '2', label: 'Couples' },
            { value: '3', label: 'Family' },
            { value: '4', label: 'Fashion' },
            { value: '5', label: 'Kids' },
            { value: '6', label: 'Lifestyle' },
            { value: '7', label: 'Newborn' },
            { value: '11', label: 'Nude' },
            { value: '8', label: 'Portrait' },
            { value: '9', label: 'Pregnancy' },
            { value: '10', label: 'Wedding' },
            { value: '--------------------', label: '20 * - => visual spacing' },
            { value: '12', label: 'Acting' },
            { value: '13', label: 'Art' },
            { value: '14', label: 'Bodypaint' },
            { value: '15', label: 'Cosplay' },
            { value: '16', label: 'Dance' },
            { value: '17', label: 'Editorial' },
            { value: '18', label: 'Fetish' },
            { value: '19', label: 'Fitness' },
            { value: '20', label: 'Lingerie' },
            // { value: '21', label: 'Pinup' },
            { value: '23', label: 'Promotional' },
            { value: '24', label: 'Runway' },
            { value: '25', label: 'Swimwear' },
            { value: '26', label: 'Topless' },
            { value: '27', label: 'Underwater' },
            { value: '--------------------', label: '20 * - => visual spacing' },
            { value: '28', label: 'Landscape' },
            { value: '29', label: 'Nature' },
            { value: '30', label: 'Pets' },
            { value: '31', label: 'Wildlife' },
            { value: '--------------------', label: '20 * - => visual spacing' },
            { value: '32', label: 'Architecture' },
            { value: '33', label: 'Event' },
            { value: '34', label: 'Food' },
            { value: '35', label: 'Product' },
            { value: '36', label: 'Realestate' },
            { value: '37', label: 'Sport' },
            { value: '38', label: 'Street' },
            { value: '--------------------', label: '20 * - => visual spacing' },
            { value: '43', label: 'Makeup & hair' },
            { value: '39', label: 'Hair' },
            { value: '40', label: 'Makeup' },
            { value: '41', label: 'Bodypaint' },
            { value: '42', label: 'Style' },

        ]


        this.props.values.languages.map((language, index) => {
         
            //make sure for this index, we have an object in the intro array
            if (this.props.values.title[index]) {}
            else {
                this.props.values.title.push({lng: language, title: ""})
            }

            return null
        })

        //console.log('this.props.values', this.props.values)
        //console.log( this.props.values.coverPhoto )
        // let coverPhoto = ''
        // if (this.props.values.coverPhoto !== undefined) {
        //     coverPhoto = this.props.values.coverPhoto;
        // };

        // console.log( coverPhoto )

        return (

            <PageContent>
            
                <PageTitle>Event details</PageTitle>

                { 
                    this.props.values.languages.map((language, index) => {

                        const fldSummaryLng = 'summary_' + language;
                        
                        const fldTitleLng = 'title_' + language;
                        return (

                            <div key={index}>
                                <LabelStyled>Title {this.props.values.languages.length > 1 ? '(' + this.capitalize(ISO6391.getNativeName(language))+')' : '' }</LabelStyled>
                                <InputStyled
                                    name={fldTitleLng}
                                    autoCapitalize = 'none' 
                                    autoComplete = 'off'
                                    defaultValue={this.props.values.title[this.findTitleLngIndex(language)].title}
                                    onChange={this.fldNameValueChange}
                                    onBlur={ async () => {

                                        let existed = false;
                                        for (var i = this.props.values.title.length-1; i >= 0; i--) {
                                            if (this.props.values.title[i].lng === language) {

                                                existed = true;
                                                this.props.values.title[i].title = this.state[fldTitleLng]

                                            }
                                        }

                                        // console.log('not found. add title: ', this.state[fldTitleLng])
                                        if (existed === false) {
                                            this.props.values.title.push({lng: language, title: this.state[fldTitleLng]})
                                        }
                                        

                                    }}
                                />
                                <IncreaseHeight/>

                               
                                <LabelStyled>Event summary in few sentences & call to action {this.props.values.languages.length > 1 ? '(' + this.capitalize(ISO6391.getNativeName(language))+')' : '' }</LabelStyled>
                                <TextareaStyled
                                    name={fldSummaryLng}
                                    rows={2}
                                    defaultValue={this.props.values.title[this.findTitleLngIndex(language)].summary}
                                    onChange={this.fldValueChange}
                                    onBlur={ async () => {

                                        let existed = false;
                                        for (var i = this.props.values.title.length-1; i >= 0; i--) {
                                            if (this.props.values.title[i].lng === language) {

                                                existed = true;
                                                this.props.values.title[i].summary = this.state[fldSummaryLng]

                                            }
                                        }

                                        // console.log('not found. add title: ', this.state[fldTitleLng])
                                        if (existed === false) {
                                            this.props.values.title.push({lng: language, summary: this.state[fldSummaryLng]})
                                        }

                                    }}
                                    // cols={5}
                                />
                                
                                <IncreaseHeight/>

                            </div> 
                        );

                    })
                }

                <IncreaseHeight8/>
                <IncreaseHeight8/>



                <LabelHeading>Dates</LabelHeading>
                <ChkGrid>
                    <DateCards>
                    { 
                        this.props.values.dates.map((dates, index) => {

                            // Aug 30th - Sep 3rd, 2021
                            // Sep 6th - 10th, 2021
                            // Apr 7th - 12th, 2021

                            let dateString = ''
                            // let timeString = ''
                            if (dates.start === dates.end) {

                                //in one day (start date = end date) 
                                dateString = moment(dates.start).format('MMMM D, YYYY') + ' (' + dates.st + ' - ' + dates.et + ')';
                        
                            } else {

                                if (moment(dates.start).format('MMMM') === moment(dates.end).format('MMMM')) {

                                    //within same month
                                    dateString = moment(dates.start).format('MMMM D') + ' - ' + moment(dates.end).format('D, YYYY');

                                } else {

                                    if (moment(dates.start).format('YYYY') === moment(dates.end).format('YYYY')) {
                                        
                                        //different months (but same year)
                                        dateString = moment(dates.start).format('MMMM D') + ' - ' + moment(dates.end).format('MMMM D, YYYY');

                                    } else {

                                        //different years
                                        dateString = moment(dates.start).format('MMMM D, YYYY') + ' - ' + moment(dates.end).format('MMMM D, YYYY');

                                    }

                                }

                            }


                            let participantLimit = ''
                            participantLimit = `${dates.limit} spots, `;

                            let availableSpots = ''
                            let intAvailable = (dates.limit - dates.reserved - dates.booked)
                            availableSpots = `${intAvailable} available`;


                            return (
                                
                                <DateCard 
                                    key={index} 
                                    onClick={() => this.editPackageDetails(dates) }
                                >
                                
                                    <DateContent>

                                    <DatesRow>

                                        <DatesRowElement>
                                            { 
                                                dateString
                                            }   
                                        </DatesRowElement>    
 
                                        
                                    </DatesRow>
                                    
                                    <DatesRow>
                                        <DatesRowElement>
                                            {
                                                dates.price
                                            }
                                        </DatesRowElement>
                                        <DatesRowElement>
                                            {
                                                dates.currency
                                            }
                                        </DatesRowElement>
                                    </DatesRow>

                                    <DatesRow>
                                        <DatesRowElement>
                                            {
                                                participantLimit
                                            }
                                        </DatesRowElement>
                                        <DatesRowElement>
                                            {
                                                availableSpots
                                            }
                                        </DatesRowElement>
                                    </DatesRow>

                                    </DateContent>
                                </DateCard>
                            );
                        })
                    }
                    </DateCards>


                    <Modal
                        isOpen={this.state.showDatesDialog}
                        onRequestClose={this.toggleDatesDialog}
                        shouldCloseOnOverlayClick={false}
                        style={customStyles2}

                        onAfterOpen={() => {
                            document.body.style.top = `0px`
                            document.body.style.width = `100vw`
                            document.body.style.position = 'fixed'
                        }}

                        onAfterClose={() => {
                            const scrollY = document.body.style.top
                            document.body.style.position = ''
                            document.body.style.top = ''
                            document.body.style.width = ''
                            window.scrollTo(0, parseInt(scrollY || '0') * -1)
                        }}
                    >

                        { this.state.packageId === '---#new#---' ? 
                            <DialogTitle showAlways='true'>Add dates, pricing & availability</DialogTitle> 
                            : 
                            <DialogTitle showAlways='true'>Edit dates, pricing & availability</DialogTitle> 
                        }
                        <IncreaseHeight/>
  
                        
                        <ColumnWrapper>

                            <ColItem2e>
                                <LabelStyled>Start date:</LabelStyled>
                                <SingleDatePicker
                                    date={moment(this.state.packageStartDate)} // momentPropTypes.momentObj or null
                                    disabled={ false } //might need to use disabled, if someone has already booked. then cannot change dates. but cancel event instead... (go through cancellation process)
                                    onDateChange={date => {
                                        
                                        const newValue = moment(date);
                                        const endValue = moment(this.state.packageEndDate)
                                        if (newValue > endValue) {
                                            setTimeout(() => { 
                                                this.setState({ 
                                                    packageStartDate: newValue.format('YYYY-MM-DD'),
                                                    packageEndDate: newValue.format('YYYY-MM-DD'),
                                                    }); 
                                                }, 50)
                                        } else {
                                            
                                            setTimeout(() => { 
                                                this.setState({ 
                                                    packageStartDate: newValue.format('YYYY-MM-DD'),
                                                }); 
                                                
                                            }, 50);

                                        }

                                    }} // PropTypes.func.isRequired
                                    focused={this.state.packageStartDateFocused} // PropTypes.bool
                                    onFocusChange={({ focused }) => this.setState({ packageStartDateFocused: focused })} // PropTypes.func.isRequired
                                    id="packageStartDate" // PropTypes.string.isRequired,
                                    numberOfMonths={1}
                                    anchorDirection="left"
                                    displayFormat="L" //LL //ll
                                    isOutsideRange={() => false}
                                />
                            </ColItem2e>

                            <ColItem2f>
                                <LabelStyled>Start time:</LabelStyled>
                                <Select 
                                    name='packageStartTime'
                                    isDisabled={ false }
                                    classNamePrefix="select"
                                    styles={colourStyles}
                                    options={this.timeOptions(use24h)}
                                    value={this.timeOptions(use24h).find(op => { return op.code === this.state.packageStartTime })}
                                    defaultValue={this.timeOptions(use24h).find(op => { return op.code === this.state.packageStartTime })}
                                    isSearchable={true}
                                    onChange={(e) => { 
                                        const startValue = e.value;
                                        const endValue = this.timeOptions(use24h).find(op => { return op.code === this.state.packageEndTime }).value
                                        if (parseInt(startValue) > parseInt(endValue)) {
                                            setTimeout(() => { 
                                                this.setState({ 
                                                    packageStartTime: e.code,
                                                    packageEndTime: e.code,
                                                    }); 
                                                }, 50)
                                        } else {
                                            setTimeout(() => { 

                                                this.setState({ 
                                                    packageStartTime: e.code, 
                                                }); 
                                            
                                            }, 50) 
                                        }
                                    }}
                                />
                            </ColItem2f>
                            <></>
                        </ColumnWrapper>


                      

                        <IncreaseHeight/>
                        <IncreaseHeight8/>

                        <ColumnWrapper>
                        
                            <ColItem2e>
                                <LabelStyled>End date:</LabelStyled>
                                <SingleDatePicker
                                        date={moment(this.state.packageEndDate)} // momentPropTypes.momentObj or null
                                        disabled={ false }
                                        onDateChange={date => setTimeout(() => { this.setState({ packageEndDate: moment(date).format('YYYY-MM-DD') }); }, 50)  } // PropTypes.func.isRequired
                                        focused={this.state.packageEndDateFocused} // PropTypes.bool
                                        onFocusChange={({ focused }) => this.setState({ packageEndDateFocused: focused })} // PropTypes.func.isRequired
                                        id="packageEndDate" // PropTypes.string.isRequired,
                                        numberOfMonths={1}
                                        anchorDirection="left"
                                        displayFormat="L" //LL //ll
                                        isOutsideRange={(day) => day < moment(this.state.packageStartDate)}
                                    />
                            </ColItem2e>

                            <ColItem2f>
                                <LabelStyled>End time:</LabelStyled>
                                <Select 
                                    name='packageEndTime'
                                    isDisabled={ false }
                                    classNamePrefix="select"
                                    styles={colourStyles}
                                    options={this.timeOptions(use24h)}
                                    value={this.timeOptions(use24h).find(op => { return op.code === this.state.packageEndTime })}
                                    defaultValue={this.timeOptions(use24h).find(op => { return op.code === this.state.packageEndTime })}
                                    isSearchable={true}
                                    onChange={(e) => { 
                                        const startValue = this.timeOptions(use24h).find(op => { return op.code === this.state.packageStartTime }).value
                                        const endValue = e.value;
                                        if (parseInt(startValue) > parseInt(endValue)) {
                                            setTimeout(() => { 
                                                this.setState({ 
                                                    packageStartTime: e.code,
                                                    packageEndTime: e.code
                                                    }); 
                                                }, 50)
                                        } else {
                                            setTimeout(() => { this.setState({ packageEndTime: e.code, }); }, 50) 
                                        }
                                    }}
                                />
                            </ColItem2f>
                            <></>
                        </ColumnWrapper>

                       
                        <IncreaseHeight/>
                        <IncreaseHeight/>

                        <ColumnWrapper>
                            <ColItem2e>
                                <IncreaseHeight/>
                                <LabelStyled>Price:</LabelStyled>
                                <InputNumberStyled 
                                    name='packagePrice'
                                    defaultValue={this.state.packagePrice} 
                                    //style={{ border: '1px solid #EBE' }}
                                    onChange={(value) => this.numberFieldChange('packagePrice', value)}
                                    precision={2}
                                    type="number"
                                />
                                
                            </ColItem2e>
                            
                            <ColItem2f>
                                <IncreaseHeight/>
                                <LabelStyled>Currency:</LabelStyled>
                                <Select 
                                    name='packageCurrency'
                                    classNamePrefix="select"
                                    styles={colourStyles}
                                    options={this.currencyOptions()}
                                    value={this.currencyOptions().find(op => { return op.code === this.state.packageCurrency })}
                                    defaultValue={this.currencyOptions().find(op => { return op.code === this.props.store.get('currency') })}
                                    isSearchable={true}
                                    onChange={(e) => { setTimeout(() => { this.setState({ packageCurrency: e.code, }); }, 50) }}
                                />
                            </ColItem2f>
                        </ColumnWrapper>


                        <IncreaseHeight/>

                        {/* <FormControlLabel 
                            control={
                                <OrangeCheckBox
                                    checked={this.state.packageFullPayment}
                                    onChange={(e) => { 
                                        const staatus = e.target.checked
                                        setTimeout(() => { this.setState({ packageFullPayment: staatus }); }, 50) 
                                    }}
                                    value="packageFullPayment"
                                />
                            }
                        label={'Full payment is required on booking'}
                        />           



                        <IncreaseHeight/>

                        { this.state.packageFullPayment === false ? 
                            <ColumnWrapper>
                                <ColItem2e>
                                    <LabelStyled>Deposit on booking:</LabelStyled>
                                    <InputNumberStyled 
                                        name='packageDeposit'
                                        defaultValue={this.state.packageDeposit} 
                                        //style={{ border: '1px solid #EBE' }}
                                        onChange={(value) => this.numberFieldChange('packageDeposit', value)}
                                        precision={2}
                                        type="number"
                                    />
                                </ColItem2e>
                            </ColumnWrapper> 
                            : 
                            <></>
                        }
                         */}


                        <ColumnWrapper>
                            <ColItem2e>
                                <IncreaseHeight/>
                                <LabelStyled>Participant limit:</LabelStyled>
                                <InputNumberStyled 
                                    name='packageLimit'
                                    defaultValue={this.state.packageLimit} 
                                    //style={{ border: '1px solid #EBE' }}
                                    onChange={(value) => {
                                        this.numberFieldChange('packageLimit', value)
                                        setTimeout(() => { 
                                            this.numberFieldChange('packageAvailable', (this.state.packageLimit - this.state.packageReserved - this.state.packageBooked))
                                        }, 50)  
                                    } }
                                    precision={0}
                                    type="number"
                                />
                                
                            </ColItem2e>
                            
                            <ColItem2f>
                                <IncreaseHeight/>
                                <LabelStyled>Reserved outside PhotoSouls:</LabelStyled>
                                <InputNumberStyledRight 
                                    name='packageReserved'
                                    defaultValue={this.state.packageReserved} 
                                    onChange={(value) => {
                                        this.numberFieldChange('packageReserved', value);
                                        setTimeout(() => { 
                                            this.numberFieldChange('packageAvailable', (this.state.packageLimit - this.state.packageReserved - this.state.packageBooked))
                                        }, 50)
                                    }}
                                    precision={0}
                                    type="number"
                                />
                            </ColItem2f>
                        </ColumnWrapper>
     
     
                        <ColumnWrapper>
                            <ColItem2e>
                                <IncreaseHeight/>
                                <LabelStyled>Bookings through PhotoSouls:</LabelStyled>
                                <InputNumberStyled 
                                    name='packageBooked'
                                    disabled={true}
                                    value={this.state.packageBooked} 
                                    // onChange={(value) => this.numberFieldChange('packageBooked', value)}
                                    precision={0}
                                    type="number"
                                />
                                
                            </ColItem2e>
                            
                            <ColItem2f>
                                <IncreaseHeight/>
                                <LabelStyled>Available:</LabelStyled>
                                <InputNumberStyledRight
                                    name='packageAvailable'
                                    disabled={true}
                                    value={this.state.packageAvailable} 
                                    // onChange={(value) => this.numberFieldChange('packageAvailable', value)}
                                    precision={0}
                                    type="number"
                                />
                            </ColItem2f>
                        </ColumnWrapper>


                        <IncreaseHeight/>
                        { this.state.errorMsg ? ( <><IncreaseHeight/> <MyError message = {this.state.errorMsg} /> </>) : null }
                        <IncreaseHeight/>

                        <BottomButtonArea>
                                {
                                    this.state.packageId !== "---#new#---" ? 
                                        (
                                            <ButtonAreaLeft>
                                                <StyledCancel onClick={this.togglePackageConfirmDelete}>Delete these dates</StyledCancel>
                                            </ButtonAreaLeft>
                                        )
                                    :
                                    <ButtonAreaLeft></ButtonAreaLeft>
                                }
                            <ButtonAreaRight>

                                <StyledSave onClick={ () => { this.savePackage();} }>
                                Save</StyledSave>
                                
                                <StyledCancel onClick={ () => { this.cancelPackageEdit();} }>
                                Cancel</StyledCancel>

                            </ButtonAreaRight>
                            
                        </BottomButtonArea>

                        


                           


                    </Modal>
                </ChkGrid> 
                
                <div style={{marginTop: "2px", marginLeft: "2px"}}>
                    <LinkButton onClick={this.addPackage}>Add dates, pricing & availability info</LinkButton>
                    <IncreaseHeight/>
                    
                </div>

                {
                    this.state.packageConfirmDelete ?  
                        <ConfirmDelete  
                            text='Are you sure you would like to delete these dates?'
                            closePopup={this.togglePackageConfirmDelete}
                            onConfirm={() => this.deletePackage(this.state.packageId)}
                        />
                        : 
                        <></>  
                }  


                <IncreaseHeight/>    
                <IncreaseHeight/>   
                <IncreaseHeight/>
                <LabelHeading>Cancellation Policy for the Client</LabelHeading>
                <IncreaseHeight/>

                    <OneRow>
                            
                        <LabelStyled2>1) Free cancellation within</LabelStyled2>
                            <OneRowCellMinWidth2>
                                <Select 
                                    name='cancelAfter'
                                    classNamePrefix="select"
                                    styles={colourStyles}
                                    options={this.cancelOptions()}
                                    defaultValue={ this.props.values.cancelAfter === null ? 
                                        this.cancelOptions().find(op => { return op.value === "1" }) 
                                        : 
                                        this.cancelOptions().find(op => { return op.value === this.props.values.cancelAfter.toString() })
                                    }
                                    isSearchable={true}
                                    onChange={(e) => { 

                                        setTimeout(() => { 
                                            this.props.values.cancelAfter = parseInt(e.code); 
                                            // console.log(parseInt(e.code))
                                            // console.log(this.props.values.cancelAfter)    
                                        }, 50) 
                                        
                                        }
                                    }
                                /> 
                            </OneRowCellMinWidth2>
                        <LabelStyled2>after the booking is confirmed.</LabelStyled2>

                    </OneRow>


                    
                    <OneRow>
                            
                        <LabelStyled2>2) Free cancellation if cancelled at least</LabelStyled2>
                             
                            <OneRowCellMinWidth2>
                                <Select 
                                    name='cancelBefore'
                                    classNamePrefix="select"
                                    styles={colourStyles}
                                    options={this.cancelOptions2()}
                                    defaultValue={ this.props.values.cancelBefore === null ? 
                                        this.cancelOptions2().find(op => { return op.code === "7" })
                                        :
                                        this.cancelOptions2().find(op => { return op.code === this.props.values.cancelBefore.toString() })
                                    }
                                    isSearchable={true}
                                    onChange={(e) => { setTimeout(() => { this.props.values.cancelBefore = parseInt(e.code); }, 50) }}
                                />
                            </OneRowCellMinWidth2>
                        
                            
                        <LabelStyled2>before the photoshoot.</LabelStyled2>

                    </OneRow>
                    
                    <OneRow>
                        <LabelStyled2>3) You'll keep</LabelStyled2>
                        <OneRowCellMinWidth2>
                            <Select 
                                name='lateCancellationKeeps'
                                classNamePrefix="select"
                                styles={colourStyles}
                                options={this.cancelOptions3()}
                                defaultValue={ this.cancelOptions3().find(op => { return op.code === this.props.values.lateCancellationKeeps.toString() }) }
                                isSearchable={true}
                                onChange={(e) => { setTimeout(() => { this.props.values.lateCancellationKeeps = parseInt(e.code); }, 50) }}
                            />
                        </OneRowCellMinWidth2>
                        <LabelStyled2>if cancelled</LabelStyled2>
                        <LabelStyled2>less than</LabelStyled2>
                        <LabelStyled2>{this.state.selectedPackageCancelBefore} days</LabelStyled2>
                        <LabelStyled2>before.</LabelStyled2>
                    </OneRow>

                    <OneRow>
                        <LabelStyled2>4) You'll keep 50%</LabelStyled2>
                        <LabelStyled2>if cancelled</LabelStyled2>
                        <LabelStyled2>less than</LabelStyled2>
                        <OneRowCellMinWidth2>
                            <Select 
                                name='noCancellationsAfter'
                                classNamePrefix="select"
                                styles={colourStyles}
                                options={this.cancelOptions4()}
                                defaultValue={this.cancelOptions4().find(op => { return op.code === this.props.values.noCancellationsAfter.toString() })}
                                isSearchable={true}
                                onChange={(e) => { setTimeout(() => { this.props.values.noCancellationsAfter = parseInt(e.code); }, 50) }}
                            />
                        </OneRowCellMinWidth2>
                        <LabelStyled2>before.</LabelStyled2>
                    </OneRow>






                <FieldContainer>
                    <LabelHeading>Categories</LabelHeading>
                    <ChkGrid>
                        { 
                            this.props.values.categories.map((category, index) => {
                                return (
                                    <MyCategoryTag
                                        key={index}
                                        category={category}
                                        onClick={() => this.toggleCategorySelection()}
                                    />
                                );
                            })
                        }
                    <div style={{marginTop: "2px", marginLeft: "2px"}}>
                        <LinkButton onClick={this.toggleCategorySelection}>Add & Edit</LinkButton>
                    </div>

                    <Modal
                        isOpen={this.state.showCategorySelection}
                        onRequestClose={this.toggleCategorySelection}
                        style={customStyles}
                        onAfterOpen={() => {
                            document.body.style.top = `0px`
                            document.body.style.width = `100vw`
                            document.body.style.position = 'fixed'
                        }}

                        onAfterClose={() => {
                            const scrollY = document.body.style.top
                            document.body.style.position = ''
                            document.body.style.top = ''
                            document.body.style.width = ''
                            window.scrollTo(0, parseInt(scrollY || '0') * -1)
                        }}
                    >

                        
                        {
                            categories.map((category, index) => {

                                let isCategoryChecked = false;
                                for (var i = this.props.values.categories.length-1; i >= 0; i--) {
                                    if (this.props.values.categories[i] === parseInt(category.value)) {
                                        isCategoryChecked = true;
                                    }
                                }


                                return (
                                    <div key={index}>

                                            { category.value === '--------------------' ?

                                                <ChkContainer>
                                                    <IncreaseHeight/>
                                                </ChkContainer>

                                                :
                                                
                                                <ChkContainer>
                                                    <FormControlLabel 
                                                        control={
                                                            <OrangeCheckBox
                                                                name={category.value}
                                                                checked={isCategoryChecked}
                                                                onChange={(event) => {

                                                                    const target = event.target;
                                                                    const value = target.type === 'checkbox' ? target.checked : target.value;
                                                                    this.updateCategory(category.value, value);

                                                                }}
                                                                value={category}
                                                            />
                                                        }
                                                    label={category.label}
                                                    />          
                                                </ChkContainer> 

                                            }

                                            <Spacing/>

                                    </div>
                                )

                            })


                        }

                        <ButtonArea>
                                                                    
                            <StyledSave100
                                onClick={() => {
                                    this.toggleCategorySelection();
                                }}
                                >
                                OK
                            </StyledSave100>

                        </ButtonArea>

                    </Modal>

                    </ChkGrid>

                    
                </FieldContainer>


                

                
                <FormControlLabel 
                    control={
                        <OrangeCheckBox
                            checked={this.props.values.virtual}
                            onChange={ (event) => {
                                this.props.values[event.target.value] = event.target.checked ? true : false
                                this.forceUpdate();
                            }}
                            value="virtual"
                        />
                    }
                label={'This event takes place online'}
                />           
                


                { this.props.values.virtual === true ? 
                    
                    <></>
                    :
                    <FieldContainer>
                        <Field
                            name="address"
                            label='Event location (city & country)'
                            component={MyPlacesAutoComplete}

                            value={this.props.values.address}
                                    
                        />
                    </FieldContainer>
                    
                }


                <IncreaseHeight8/>


                <FieldContainer>
                    <Field
                        name="languages"
                        label='What languages do you speak, can read and write?'
                        component={MyLanguageSelection}
                        originalValue={this.props.values.languages}
                        doForceLanguageUpdate={this.doForceLanguageUpdate}
                        //InputProps={{ onBlur: console.log('nah') }}
                        //onKeyUp={console.log('nah')}
                    />
                </FieldContainer>


                <IncreaseHeight8/>


                <FieldContainer>
                <Field
                    name="publicURL"
                    label='Direct link to your event page:'
                    disabled={true} //once verified, set False
                    value={'photosouls.com/' + this.props.values.publicURL}
                    component={MyInputField}
                    
                />
                </FieldContainer>

                <FieldContainer>
                    <Field
                        name="premiumURL"
                        label='Premium direct link to your event:'
                        component={MyInputField}
                        disabled={true} //once verified, set False
                        value={this.props.values.premiumURL ? 'photosouls.com/' + this.props.values.premiumURL : 'photosouls.com/ ????'}
                        
                    />

                <LinkButton onClick={this.togglePremiumURLGeneration} >
                    Click here to select your premium URL
                </LinkButton>
                {
                    this.state.showPremiumURLGeneration ?  
                        <PremiumURLGeneration
                            closePopup={this.togglePremiumURLGeneration}
                            applyURL={(URL) => {
                                this.props.setField('premiumURL', URL);
                                this.togglePremiumURLGeneration();
                            }}
                            checkURL={async (URL) => {
                                
                                let isAvailable = false;

                                await this.props.client
                                    .query({ query: findURL, variables: { URL: '/' + URL }, fetchPolicy: 'no-cache',})
                                    .then(results => {
                                        if (JSON.stringify(results).length > 15) {
                                            if (results.data.findURL.profileId === '') {
                                                isAvailable = true;
                                                }
                                        }
                                    })
                                    .catch(error => { 
                                        // console.log(error) 
                                        })

                                if (isAvailable) {
                                    return "available" }
                                else {
                                    return "notavailable"
                                }

                            }}
                        />
                        : null  
                }  

                { this.state.showUpgradeModal ? 
                        <UpgradeModal
                            showUpgradeModal={this.state.showUpgradeModal}
                            closePopup={() => this.setState({ showUpgradeModal: false }) }
                            popTitle="Sorry, you cannot pick your own URL address for Hobby accounts"
                            popDescription="To have your own custom and easy to remember address for your PhotoSouls profile, please upgrade to the Pro account."
                        />
                    : null
                }
                
                </FieldContainer>


                <FieldContainer>
                    <Field
                        name="status"
                        label='Profile visibility'
                        component={MySelectField}
                        originalOptions={statuses}
                        originalValue={this.props.values.status}
                        onChange={ (e) =>  this.props.setField('status', parseInt(e.value)) }
                    />
                </FieldContainer>

                <FieldContainer>
                    <FormControlLabel 
                        control={
                            <OrangeCheckBox
                                checked={this.props.values.chkPromotions}
                                onChange={this.handleChange}
                                value="chkPromotions"
                            />
                        }
                    label={"I'm granting PhotoSouls the right to promote my event profile on external platforms (such as Instagram, Pinterest, etc.), and to use images from my public albums for this purpose."}
                    />          
                </FieldContainer>           

            </PageContent>
                    
        )

    }

}


export const DetailsPage = withStore(withApollo(C), ['']);


const LinkButton = styled.div`
    
    color: #4C9AFF;

    margin: 0 8px 0 0; 

    padding-top: 4px;
    font-weight: 400;
    font-size: 16px;

    &:hover {
        text-decoration: underline;
        cursor: pointer
        }

`;

const OrangeCheckBox = withStyles({
    root: {

      color: orange[400],
      '&$checked': {
        color: orange[600],
      },
    },
    checked: {},
    })(props => <Checkbox color="default" {...props} /> )
;

const PageContent = styled.div`
    
    width: 100%;
    font-family: 'Fira Sans';
    
`;

const PageTitle = styled.div`
    
    display: flex;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;

    display: none;
    @media (max-width: 790px) {
        display: block;
        }


`;


const DialogTitle = styled.div`
    
    display: flex;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;

    display: block;

`;


const FieldContainer = styled.div`
    
    margin: 32px auto 32px auto;
    
`;



const InputStyled = styled.input`
    
    width: calc(100% - 18px);
    padding: 0px;
    margin: 0px;
    height: 24px;
    padding: 8px;
    font-size: 16px;
    font-family: 'Fira Sans';

    color: #333333;
    
    border: 1px solid #cbcbcb;
    border-radius: 4px;  
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    :focus {
        outline-color: #4C9AFF;
    }

    :disabled {
        background-color: #f7f7f7; 
    }


    @media (max-width: 690px) {
        font-size: 17px;
    }
`;

const LabelStyled = styled.div`
    width: 100%;
    margin: 0px;
    padding: 0px;
    color: #333333;
    font-size: 16px;
    
    margin-bottom: 4px;
    margin-top: 0px;
    margin-left: 2px;
`;

const LabelHeading = styled.div`
    width: 100%;
    margin: 0px;
    padding: 0px;
    color: #333333;
    font-size: 18px;
    font-weight: 500;
    
    margin-bottom: 8px;
    margin-top: 0px;
    margin-left: 2px;
`;

const IncreaseHeight = styled.div`
    
    height: 16px;  

`;

const IncreaseHeight8 = styled.div`
    
    height: 8px;  

`;

const ChkGrid = styled.div`
    
    display: flex;
    flex-wrap: wrap;    

`;

const customStyles = {
    content : {
        boxShadow: '0 20px 50px rgba(0, 0, 0, 0.13)',
        borderRadius: '5px',
        height: '85%',
        overflow : 'auto',
        width: '400px',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '999999'
    },
    overlay: {zIndex: 1000}

  };

  const customStyles2 = {
    content : {
        boxShadow: '0 20px 50px rgba(0, 0, 0, 0.13)',
        borderRadius: '5px',
        height: '85%',
        overflow : 'auto',
        width: '80%',
        maxWidth: '600px',
        padding: '32px',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '999999'
    },
    overlay: {zIndex: 1000}

  };



const Spacing = styled.div`

    width: 24px;
    display: absolute;

`;


const ButtonArea = styled.div`

    color: #333333;
    align-items: flex-end;

    margin: 0px 0px 16px 0px;

`;


const StyledCancel = styled(Button)`
    && {

    padding-left: 16px;
    padding-right: 16px;
    
    margin-bottom: 32px;

    height: 40px;
    top: 18px;
    color: #333333;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    }
    
`;

const StyledSave = styled(Button)`
    && {
    height: 40px;
    top: 18px;
    
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 16px;
    margin-bottom: 32px;

    background: #ff9500;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.4);
    color: white;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
        &:hover {
        background: #ff9500;
        opacity: 0.9;
        color: white;
        }
    }
    
`;

const StyledSave100 = styled(Button)`
    && {
    height: 40px;
    top: 18px;
    
    background: #ff9500;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.4);
    color: white;
    width: 100%;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
        &:hover {
        background: #ff9500;
        opacity: 0.9;
        color: white;
        }
    }
    
`;

const ChkContainer = styled.div`
    
  padding-left: 16px;
  padding-top: 2px;
  padding-bottom: 2px;
  /* width: 155px; */
  
`;

const DateCards = styled.div`

    width: 100%;
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    align-items: flex-start;
    justify-content: space-between;
   
    z-index: 200;

`;



const DateCard = styled.div`
    
    width: calc(50% - 8px);
    
    border-radius: 8px;

    margin-top: 0px;
    margin-bottom: 16px;

    border: 1px solid #ebebeb;
    box-shadow: 0 2px 8px 0 rgba(125, 125, 125, 0.2);
    
    cursor: pointer;
    
    color: #333333;
    z-index: 300;

    &:hover {
        background: #f8f8f8; 
        transform: scale(1.02);
        }

    @media (max-width: 1000px) {
        width: 100%;
        min-width: 100%;
        margin-top: 12px;
    }

    
`;

const DateContent = styled.div`
    
    font-size: 16px;
    font-weight: 400;

    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;


`;

const DatesRow = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    font-size: 16px;
    font-weight: 400;

`;


const DatesRowElement = styled.div`
    
    margin-right: 4px;

`;



const BottomButtonArea = styled.div`
    width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const ButtonAreaLeft = styled.div`
    width: 50%;
    min-width: 50%;
    justify-content: space-between;
`;


const ButtonAreaRight = styled.div`
    justify-content: space-between;
`;

const ColumnWrapper = styled.div`
    width: 100%;
    min-width: 100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 890px) {
        flex-direction: column;
        justify-content: flex-start;
    }
`;


const ColItem2e = styled.div`
    width: calc(50% - 30px);
    display: relative;
    float:left;

    @media (max-width: 890px) {
        width: 100%;
        margin-top: 12px; 
    }

`;

const ColItem2f = styled.div`

    width: calc(50% - 12px);
    display: relative;
    margin-left: 2px;
    margin-right: 2px;

    float: left;

    @media (max-width: 890px) {
        width: 100%;
        min-width: 100%;
        margin-top: 12px;
    }

`;


const colourStyles = {

    control: (styles, {isDisabled, isFocused}) => ({ ...styles, 
        
        margin: 0,
        height: 42,
        paddingTop: 2,
        
        fontSize: 16,
        fontFamily: 'Fira Sans',
        fontWeight: '400',
        
        
        backgroundColor: isDisabled ? '#f7f7f7' : null,
        color: '#333333',
        
        borderRadius: 4,
        border: isFocused ? '2px solid #4C9AFF' : '1px solid #cbcbcb',
        boxShadow: '0 2px 8px 0 rgba(170, 170, 170, 0.2);',
        //boxShadow: isFocused ? '0 2px 8px 0 rgba(76, 154, 255, 0.8)' : '0 2px 8px 0 rgba(170, 170, 170, 0.2)',

        minWidth: '150px'
        }),
    
    singleValue: base => ({
        ...base,
        paddingTop: 2,
        color: '#333333',
        marginLeft: 0,
        }),
    
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        
        return {
            ...styles,

            cursor: isDisabled ? 'not-allowed' : 'default',
        
            fontSize: 16,
            fontFamily: 'Fira Sans',
            fontWeight: '400',
            height: 42,

            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',


            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && ('#4C9AFF'),

            },


        };
    },
    menu: base => ({
        ...base,
        // color: '#333333',
        
        borderRadius: 4,
        border: '2px solid #4C9AFF',
        boxShadow: '0 2px 8px 0 rgba(170, 170, 170, 0.2);',

        marginLeft: '-1px',

        width: 'calc(100% + 2px);',


    }),

}


const InputNumberStyled = styled(InputNumber)`
    
    width: 100%;
    padding: 0px;
    margin: 0px;
    
    && {
        input {
            
            
            font-size: 16px;
            font-family: 'Fira Sans';
            font-weight: 400;

            width: 100%;
            padding: 0px;
            margin: 0px;
            height: 24px;
            padding: 8px;
            
            color: #333333;

            border: 1px solid #cbcbcb;
            border-radius: 4px;  
            box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

            :focus {
                outline-color: #4C9AFF;
            }

            :disabled {
                background-color: #f7f7f7; 
            }

        }
    }
    
`;

const InputNumberStyledRight = styled(InputNumber)`
    
    width: calc(100% - 18px);
    padding: 0px;
    margin: 0px;
    
    && {
        input {
            
            
            font-size: 16px;
            font-family: 'Fira Sans';
            font-weight: 400;

            width: 100%;
            padding: 0px;
            margin: 0px;
            height: 24px;
            padding: 8px;
            
            color: #333333;

            border: 1px solid #cbcbcb;
            border-radius: 4px;  
            box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

            :focus {
                outline-color: #4C9AFF;
            }

            :disabled {
                background-color: #f7f7f7; 
            }

        }
    }
    
`;


const OneRow = styled.div`
    
    margin-left: 16px; 
    margin-bottom: 8px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    flex-wrap: wrap;
    flex: 1;

    align-items: center;

`;


const LabelStyled2 = styled.div`
    
   margin-left: 0px;
    margin-right: 4px;
    margin-bottom: 4px;
    margin-top: 6px;
    padding: 0px;
    
    color: #333333;
    font-size: 16px;


`;


const OneRowCellMinWidth2 = styled.div`
    
    margin-right: 8px;

`;


const TextareaStyled = styled.textarea`
    
    width: calc(100% - 18px);
    padding: 0px;
    margin: 0px;
    /* height: 24px; */
    padding: 8px;
    font-size: 16px;
    font-family: 'Fira Sans';
    line-height: 1.2em;

    color: #333333;
    
    border: 1px solid #cbcbcb;
    border-radius: 4px;  
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    :focus {
        outline-color: #4C9AFF;
    }

    :disabled {
        background-color: #f7f7f7; 
    }

    @media (max-width: 690px) {
        font-size: 17px;
    }
`;