
import React, { Component } from 'react';
import styled from 'styled-components';



export class AvatarImg extends Component {
    
  render() {
      
    const { image } = this.props;

    //const imgSize = this.props.size ? this.props.size : 32;
    //const bRadius = (imgSize/2)

    return (
      
        <>
            
            <CardDiv>

                <ImageItself 
                    src={image} 
                    // style={{ height: imgSize, width: imgSize, borderRadius: bRadius }}
                    alt=" "
                    onLoad={(e) => {
                        //e.target.src = image --- causes huge CPU spike. but looks like not necessary anyway!
                        e.target.style = 'display: flex' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                    }}
                    onError={(e) => {
                        //e.target.src = '' --- causes huge CPU spike. but looks like not necessary anyway!
                        e.target.style = 'display: none' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                    }}
                />
                

            </CardDiv>

        </>
    
    );

  }
}

const CardDiv = styled.p`
    
    display: float;
    margin: 0 0 0 0;

    width: 128px;
    height: 128px;
    border-radius: 128px;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.4);
    
`;


const ImageItself = styled.img`

    height: 128px;
    width: 128px;
    border-radius: 64px;
    /* //border-radius: 64px; */
    
`;
