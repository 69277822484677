import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// import { reactI18nextModule } from "react-i18next";
import { initReactI18next } from 'react-i18next';
import moment from 'moment';


// RN UPDATE: 2020 03:
// this was before upgrade, workign fine. after update not anymore. When disabled, everything at least looks like is working

  // ### let's importing only the locales that we need.
  // const availableLanguages = ['de', 'en', 'es', 'et', 'ru'];
  // availableLanguages.forEach((element) => {
  //   if (element !== 'en') {
  //      import(`./moment/locale/${element}`);
  //    }
  // }); 



        // function getLang() {
        //     if (navigator.languages != undefined) 
        //       return navigator.languages[0]; 
        //     return navigator.language;
        // }


  
i18n
  .use(Backend) 
  .use(LanguageDetector) 
  .use(initReactI18next)
  // .use(reactI18nextModule) 

  .init({
    
    debug: false,

    backend: { loadPath: '/locales/{{lng}}.json' },

    lng: "en",
    fallbackLng: "en", //without this, loads languages twice (first the 'en' (if 'en' is fallback), and then the one that they selected)
    
    //whitelist: availableLanguages,
    //ns: ['web', 'common'],

    //preloaded: "true" //needed if serverside rendering

    interpolation: {
        escapeValue: false, 
        format: (value, format, lng) => {
            if (format === 'currentDate') return 
              moment(value).locale(lng).format('LL');
            return value;
        },
    },
    react: {
      wait: false,
      // bindI18n: 'languageChanged',
      // transSupportBasicHtmlNodes: true,
      // transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      // useSuspense: false //   <---- this will do the magic
    }
  });

export default i18n;