import React, { PureComponent } from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";

import styled from 'styled-components';


class MyPlacesAutoComplete extends PureComponent {
  
    constructor(props) {
        super(props);

        this.state = {
            name: props.field.name,
            address: props.value || "",
        };
    }

    handleError = error => {
        this.props.form.setFieldError(this.state.name, error);
    };


    
    handleChange = address => { //this is fired by every keystroke on the edit field. "address" value is what they are typing in.

        this.setState(() => {

            this.props.form.setFieldTouched(`${this.state.name}.value`);
            this.props.form.setFieldTouched(`${this.state.name}.address`);

            this.props.form.setFieldValue(this.state.name, address );

            return { address };

        });
    };

    handleSelect = address => {

        let geoResult = '';

        geocodeByAddress(address)
            .then(results => { 
                    getLatLng(results[0]); 
                    geoResult = results[0];
                })
            .then(latLng => {
                this.setState(() => {
                    
                    this.props.form.setFieldValue(this.state.name, address);

                    //empty the fields. otherwise a bug. if first you select place with 3 fields, and then later place with 2 fields, the field that is not found anymore, remains filled
                    this.props.form.setFieldValue('addr_locality', '');
                    this.props.form.setFieldValue('addr_area2', '');
                    this.props.form.setFieldValue('addr_area1', '');
                    this.props.form.setFieldValue('country', '');

                    //console.log('geo', geoResult)

                    var i;
                    for (i = 0; i < geoResult.address_components.length; i++) {
                    
                        if (geoResult.address_components[i].types[0] === 'locality') {
                            this.props.form.setFieldValue('addr_locality', geoResult.address_components[i].long_name);
                        } else if (geoResult.address_components[i].types[0] === 'administrative_area_level_2') {
                            this.props.form.setFieldValue('addr_area2', geoResult.address_components[i].long_name);
                        } else if (geoResult.address_components[i].types[0] === 'administrative_area_level_1') {
                            this.props.form.setFieldValue('addr_area1', geoResult.address_components[i].long_name);
                        } else if (geoResult.address_components[i].types[0] === 'country') {
                            this.props.form.setFieldValue('country', geoResult.address_components[i].long_name);
                        }

                    };

                    const latValue = geoResult.geometry.location.lat()
                    const longValue = geoResult.geometry.location.lng()
                    const locationFieldValue = { type: "Point", coordinates: [ longValue, latValue ] }
                    
                    this.props.form.setFieldValue('location', locationFieldValue);

                    return { address };

                });
            })
            .catch(error => this.props.form.setFieldError(this.state.name, error));
    };

    render() {

        const {
            field: { name }, // { name, value, onChange, onBlur }
            //field: { onChange, ...field },
    
            //form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            classes,
            options,
            label,
            ...props
            
        } = this.props;

        //const error = errors[name];
        //const touch = touched[name];
        // console.log(this.state);
        
        
        return (
            
            <>

            { label ? <LabelStyled>{label}</LabelStyled> : null}

            <PlacesAutocomplete
                name={name}
                id={name}

                {...props}
                searchOptions={options || {}}
                
                value={this.state.address}
                
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                onError={this.handleError}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                        
                        <InputStyled
                            {
                            ...getInputProps({
                                placeholder: "",
                                })
                            }
                        />
                        
                        { 
                            suggestions.length ?
                            <DropDownContainer>
                            
                                {loading && <InputSuggestion>Loading...</InputSuggestion>}
                                
                                {
                                    suggestions.map((suggestion, index) => {
                                    
                                        //const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                                        
                                        const style = suggestion.active ? 
                                            { backgroundColor: "#cbcbcb", cursor: "pointer" }
                                            : 
                                            { backgroundColor: "#ffffff", cursor: "pointer" };
                                        
                                        return (
                                            <InputSuggestion 
                                                key={index}
                                                {...getSuggestionItemProps(suggestion, {
                                                    //className,
                                                    style,
                                                    })
                                                }
                                            >
                                                <span key={index}>{suggestion.description}</span>
                                            </InputSuggestion>
                                        );
                                    })}

                            </DropDownContainer>
                            :
                            <></>
                        }

                    </div>

                )}

            </PlacesAutocomplete>
            </>
        );
    }
}

const LabelStyled = styled.div`
    width: 100%;
    margin: 0px;
    padding: 0px;
    color: #333333;
    font-size: 16px;
    
    margin-bottom: 4px;
    margin-top: 0px;
`;

const DropDownContainer = styled.div`

    border: 2px solid #4C9AFF; 
    border-radius: 4px;  
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);
    outline-color: #4C9AFF;

    @media (max-width: 690px) {
        font-size: 17px;
    }

`;

const InputStyled = styled.input`
    
    width: calc(100% - 18px);
    padding: 0px;
    margin: 0px;
    height: 24px;
    padding: 8px;
    font-size: 16px;
    font-family: 'Fira Sans';

    color: #333333;
    
    border: 1px solid #cbcbcb;
    border-radius: 4px;  
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    :focus {
        outline-color: #4C9AFF;
    }

    :disabled {
        background-color: #f7f7f7; 
    }


    @media (max-width: 690px) {
        font-size: 17px;
    }
`;


const InputSuggestion = styled.div`

    width: calc(100% - 18px);
    margin: 0px;

    height: 1em;

    padding: 8px;
    font-size: 16px;

    overflow: hidden;

    color: #333333;
    font-family: 'Fira Sans';

    :focus {
        outline-color: #4C9AFF;
    }

    :disabled {
        background-color: #f7f7f7; 
    }


    @media (max-width: 690px) {
        font-size: 17px;
        
    }

`;

export default MyPlacesAutoComplete;
