
import React, { Component } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

// import TimeAgo from './../../utils/TimeAgo'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import getSymbolFromCurrency from 'currency-symbol-map'

import moment from "moment"; 
import i18next from "i18next";
import '../../i18n'

import { CLOUDFRONT_SERVER } from '../../config';


export class ModellCard extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            currentPhotoIndex: 0,
            isHovering: false,
        }
        this.handleMouseHover = this.handleMouseHover.bind(this);
    }


    handleMouseHover() {
        this.setState(this.toggleHoverState);
    }
    
    toggleHoverState(state) {
        return {
            isHovering: !state.isHovering,
        };
    }
    

    render() {
      
        moment.locale(i18next.language);
        
        // const lenDays = this.props.lenDays
        const category = this.props.category; //    -1    //no category for favorites. only use if seach filter has set specific category
        const albums = this.props.albums ? this.props.albums : []
        
        const avatar = this.props.avatar;
        
        const name = this.props.name ? this.props.name : '';

        //kas need panna äkki kaardil review reale, enne average rating ikooni ? Verified võib panna muidugi ka nime lõppu...
        // const isPro = this.props.isPro;
        // const isVerified = this.props.isVerified;

        const instaBook = this.props.instaBook ? this.props.instaBook : false;
        
        const avgRating = this.props.avgRating ? this.props.avgRating : 0;
        const reviewsTotal = this.props.reviewsTotal ? this.props.reviewsTotal : 0;
        
        const avgPrice = this.props.avgPrice ? this.props.avgPrice : 0;
        const currency = this.props.currency ? this.props.currency : '';
        
        let currencySymbol = getSymbolFromCurrency(currency);
        if (currencySymbol === undefined) { currencySymbol = '' }

        const tfp = this.props.tfp ? this.props.tfp : false;

        const address = this.props.address ? this.props.address : '';

        const isMobileScreen = window.matchMedia('(max-width: 790px)').matches;


        let bookingInfo = '';
        if (instaBook) {
            bookingInfo = `Instant booking, \n from ${currencySymbol}${Math.round(avgPrice)} ${currency}`;
        } else { 
            bookingInfo = `Starting from ${currencySymbol}${Math.round(avgPrice)} ${currency}`
        }

        if (tfp) {
            bookingInfo = bookingInfo + `; TFP`
        }


        let locationInfo = '';
        locationInfo = address; //Remove country, if the searcher country = the one in address.


        let photos = [];
        for (var i = 0; i < albums.length; i++) {
        // for (var i = albums.length-1; i >= 0; i--) {

            let photoDim = 1;
            if ((albums[i].cph > 0) && (albums[i].cpw > 0)) {
                photoDim = albums[i].cph / albums[i].cpw
            }

            let categoryMatch = false; //default
            if (category === -1) {

                // if category IS NOT SELECTED:
                //      array of albums with their cover photos. (Description, should we show something on the card) is the Album title
                //      no extra queries for additional photos needed on the card: rotate through existing album covers with their titles
                categoryMatch = true;

            } else {

                // if category IS SELECTED:
                //      show album cover image of the album that matches the category filter
                // (in the future):
                //      IF next or previous button is clicked on the card, query for additional photos where the category matches, for that particular photographer. rotate through those photos.
                //      and for that to be possible, scroll buttons must be visible, even if there is just one cover photo (because photo query would (probably) give next photo to show)

                if (albums[i].category.toString() === category) {
                    categoryMatch = true;
                }

            }

            if (categoryMatch) {

                photos.push({ 
                    p: CLOUDFRONT_SERVER + '/img/M/' + albums[i].coverPhotoURL,
                    pw: albums[i].cpw > 0 ? albums[i].cpw : 0,
                    ph: albums[i].cph > 0 ? albums[i].cph : 0,
                    dim: photoDim
                });

            }

        }


        // console.log('albums', albums)
        // console.log('photos', photos)

        return (
        
            <>

                <CardContainer
                    onMouseEnter={this.handleMouseHover}
                    onMouseLeave={this.handleMouseHover}
                    dim={photos.length > 0 ? photos[this.state.currentPhotoIndex].dim : 1}
                    >
                

                    <CoverPhoto
                        dim={photos.length > 0 ? photos[this.state.currentPhotoIndex].dim : 1}
                        >

                        { (photos.length > 0) ?
                            (this.state.isHovering || isMobileScreen) &&
                                <GreyButtons>
                                    { 
                                        this.state.currentPhotoIndex === 0 ?  
                                            <Link to={this.props.url} target={isMobileScreen ? "_self" : "_blank"} style={{ textDecoration: 'none' }}>
                                                <NoGreyButton></NoGreyButton>  
                                            </Link>
                                            :
                                            <GreyButton 
                                                onClick={() =>  {
                                                    const current = this.state.currentPhotoIndex;
                                                    setTimeout(() => { 
                                                        this.setState({ 
                                                            currentPhotoIndex: current-1, 
                                                        }); 
                                                    }, 50);
                                                }} 
                                                disabled={ this.state.selectedPhoto === 0 ? true : false} >
                                                <ButtonIcon icon={faChevronLeft} color={ this.state.selectedPhoto === 0 ? '#fff' : '#000'} />
                                            </GreyButton>
                                    }

                                    <Link to={this.props.url} target={isMobileScreen ? "_self" : "_blank"} style={{ textDecoration: 'none', width: '100%' }}>
                                        <ButtonMiddleSection></ButtonMiddleSection>
                                    </Link>

                                    { 
                                        this.state.currentPhotoIndex === photos.length-1 ? 
                                            <Link to={this.props.url} target={isMobileScreen ? "_self" : "_blank"} style={{ textDecoration: 'none' }}>
                                                <NoGreyButton></NoGreyButton>  
                                            </Link>
                                            :
                                            <GreyButton 
                                                onClick={() =>  {
                                                    const current = this.state.currentPhotoIndex;
                                                    setTimeout(() => { 
                                                        this.setState({ 
                                                            currentPhotoIndex: current+1, 
                                                        }); 
                                                    }, 50);
                                                }} 
                                                disabled={ this.state.selectedPhoto === photos.length-1 ? true : false} >
                                                <ButtonIcon icon={faChevronRight} color={ this.state.selectedPhoto === photos.length-1 ? '#fff' : '#000'} />
                                            </GreyButton>
                                    }
                                </GreyButtons>
                            :
                            null
                        }

                        <Link to={this.props.url} target={isMobileScreen ? "_self" : "_blank"} style={{ textDecoration: 'none' }}>
                            <CoverImage 
                                onClick={this.props.onClick}
                                src={photos.length > 0 ? photos[this.state.currentPhotoIndex].p : null} 
                                alt="Photo" 
                                onLoad={(e) => {
                                    //e.target.src = this.props.coverPhoto  --- causes huge CPU spike. but looks like not necessary anyway!
                                    e.target.style = 'display: flex' 
                                }}
                                onError={(e) => {
                                    //e.target.src = '' --- causes huge CPU spike. but looks like not necessary anyway!
                                    e.target.style = 'display: none' 
                                }}
                            />
                        </Link>
                        
                    </CoverPhoto>


                    <Link to={this.props.url} target={isMobileScreen ? "_self" : "_blank"} style={{ textDecoration: 'none' }}>
                        <CardFooter
                            onClick={this.props.onClick}
                        >

                                <AvatarBlock>
                                    { 
                                        avatar ? 
                                            <AvatarImg 
                                                src={avatar} 
                                                alt="Avatar" 
                                                onLoad={(e) => {
                                                    //e.target.src = values.avatar --- causes huge CPU spike. but looks like not necessary anyway!
                                                    e.target.style = 'display: flex' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                                }}
                                                onError={(e) => {
                                                    //e.target.src = '' /--- causes huge CPU spike. but looks like not necessary anyway!
                                                    e.target.style = 'display: none' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                                }}

                                            /> : <></>
                                    }
                                </AvatarBlock>


                                <InfoBlock>
                                    <InfoContent>

                                        <NameContainer>{name}</NameContainer>
                                        {
                                            reviewsTotal >= 5 ?
                                                <RatingContainer>
                                                    {/* <FAIcon icon={faStar} color={'#ff9500'} /> */}
                                                    <span role="img" aria-label="rating">⭐</span>
                                                    <AvgRating>{avgRating}</AvgRating>
                                                    <ReviewCount>({reviewsTotal} reviews)</ReviewCount>
                                                </RatingContainer>
                                                :
                                                null
                                        }
                                        <RowContainer>{bookingInfo}</RowContainer>
                                        <RowContainer>{locationInfo}</RowContainer>

                                    </InfoContent>
                                </InfoBlock>


                        </CardFooter>
                    </Link>

                </CardContainer> 

            </>
        
        );

    }


}



const CardContainer = styled.div`
    
    width: 288px;
    height: calc(360px + 120px);
    margin: 16px 48px 32px 0px;

    align-items: flex-start;
    border-radius: 8px;
    border: none;
    z-index: 300;

    &:hover {
        transform: scale(1.005);
        cursor: pointer;
        color: #4C9AFF;
    }
    
    @media (max-width: 1885px) { 
        width: 260px;
        height: calc(325px + 120px);
    }

    @media (max-width: 1730px) { 
        width: 292px;
        height: calc(365px + 120px);
    }

    @media (max-width: 1528px) { 
        width: 282px;
        height: calc(350px + 120px);
    }

    @media (max-width: 1485px) { 
        width: 252px;
        height: calc(315px + 120px);
    }

    @media (max-width: 1350px) { 
        width: 232px;
        height: calc(290px + 120px);
    }

    @media (max-width: 790px) {
        width: 100%;
        height: calc((100vw/4*5) + 100px);
        margin: 16px 4px 32px 0px;
    } 
    

`;


const CoverPhoto = styled.div`

    width: 288px; /* x360 */ 
    height: 360px;
    max-height: 360px;

    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);
    z-index: 400;

   
    &:hover {
        transform: scale(1.005);
        cursor: pointer;
    }

    @media (max-width: 1885px) { 
        width: 260px;
        height: 325px;
        max-height: 325px;
    }
    @media (max-width: 1730px) { 
        width: 292px;
        height: 365px;
        max-height: 365px;
    }
    @media (max-width: 1528px) { 
        width: 282px;
        height: 350px;
        max-height: 350px;
    }

    @media (max-width: 1485px) { 
        width: 252px;
        height: 315px;
        max-height: 315px;
    }

    @media (max-width: 1350px) { 
        width: 232px;
        height: 290px;
        max-height: 290px;
    }

    @media (max-width: 790px) {
        width: 100%;
        height: calc(100vw/4*5);
        max-height: 600px;
    } 
    

`;


const CoverImage = styled.img`

    width: 100%;
    height: 100%;
    
    position: relative;
    object-fit: cover;
    border-radius: 5px;

`;


const CardFooter = styled.div`
    
    /* background-color: #ebe; */
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;    

    margin-top: 12px;

    text-decoration: none;
    
    @media (max-width: 729px) {
        margin-top: 12px;
    } 


`;


const AvatarBlock = styled.div`
    
    /* background-color: #beb; */
    
    width: 56px; 
    justify-content: flex-start;

`;

const AvatarImg = styled.img`

    height: 56px;
    width: 56px;

    border-radius: 56px;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.4);

`;


const InfoBlock = styled.div`
    
    /* background-color: #feb; */
    
    width: calc(100% - 68px);
    min-width: calc(100% - 68px);
    margin-left: 12px;

    justify-content: flex-start;


`;

const InfoContent = styled.div`
    
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;    

`;

const NameContainer = styled.div`
    
    font-family: 'Fira Sans';
    font-size: 18px;
    font-weight: 400;

    color: #333333;


`;

const RatingContainer = styled.div`

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;    
    
    margin-top: 0px;

`;


const AvgRating = styled.div`
    
    font-family: 'Fira Sans';
    font-size: 16px;
    font-weight: 300;

    align-content: flex-start;
    color: #333333;

    margin-left: 4px;

`;

const ReviewCount = styled.div`
    
    font-family: 'Fira Sans';

    font-size: 16px;
    font-weight: 300;

    align-content: flex-start;
    color: #9b9b9b;

    margin-left: 4px; 
`;

const RowContainer = styled.div`

    font-family: 'Fira Sans';
    font-size: 16px;
    font-weight: 300;

    color: #333333;

`;

const GreyButtons = styled.div`

    display: flex;
    position: relative;

    flex-direction: row;
    justify-content: space-between;

    width: calc(100% - 8px);
    min-width: calc(100% - 8px);

    margin-top: -48px;
    top: calc(50% + 24px);
    
    z-index: 9000;

    /* background-color: #ebe; */

    padding-left: 4px;
    padding-right: 4px;
    
    @media (max-width: 790px) {
        top: calc(50% + 24px);
    }


`;


const ButtonMiddleSection = styled.div`

    width: 100%;
    min-width: 100%;

    /* background-color: #de3; */

    height: 48px;
    min-height: 48px;

`;

const GreyButton = styled(Button)`
    && {
        
    /* margin: -4px -4px 0 0px; */

    height: 48px;
    min-width: 48px;
    max-width: 48px;
    border-radius: 24px;

    /* margin-right: 4px;
    margin-left: 4px; */

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;
    font-weight: 400;
    font-size: 19px;

    background-color: #EBEBEB;
    opacity: 0.6;

    z-index: 90000;

    &:hover {
        background-color: #EBEBEB;
        opacity: 0.6;
        }
    
    &:disabled {
        background-color: #fff;
        color: #fff;
        }

    }
    
`;




const NoGreyButton = styled.div`

    height: 48px;
    width: 48px;
    min-width: 48px;
    max-width: 48px;
    
    /* background-color: #fa1; */
    
`;

const ButtonIcon = styled(FontAwesomeIcon)`
    && {
    font-size: 21px;
    font-weight: 400;
    }
`;