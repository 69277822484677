
import React, { useState, useEffect } from "react";

import styled from 'styled-components';

import Button from '@material-ui/core/Button';

export const ConfirmField = ({
    field: { onChange, ...field },
    form: { touched, errors }, 
    label, propStore, originalValue,
    allowedToChange,
    useNumberComponent = false,
    ...props
  }) =>  {


        const [tempField, setTempField] = useState("");

        useEffect(() => { setTempField(originalValue); }, [propStore, field.name, originalValue])

        const errorMsg = touched[field.name] && errors[field.name]; 
        const mybordercolor = errorMsg ? "#ff4343" : "#cbcbcb";
        

        let renderButtons
            renderButtons = (
                <>
                    { allowedToChange ? 
                        props.emailChange || props.pwdChange ? <StyledEdit onClick={props.midateha}>Change</StyledEdit> : <StyledEdit onClick={console.log('edit')}>Edit</StyledEdit>
                        :
                        <NoButton disabled={true}> </NoButton>
                    }
                </>
            )
    

        return (
            <>
            
            <SettingsFieldWrapper>

                <FieldItself>

                    { label ? <LabelStyled>{label}</LabelStyled> : null}
                    

                        <InputStyled
                            
                            theme={{ bordercolor: mybordercolor }}
                            autoCapitalize = 'none'

                            //name={field.name}
                            //value={tempField}
                            onChange={e => setTempField(e.target.value)}

                            value = {tempField}
                            name={field.name}
                            //{...field}
                            //{...props}
                            //onChange={ useNumberComponent ? (newValue) => { setFieldValue(field.name, newValue) } : onChange }
                            //name={field.name}
                            //value={tempField}
                            //onChange={e => setTempField(e.target.value)}


                            disabled={true}
                        />
                        


                    { !errorMsg ? <ErrorStyled></ErrorStyled> : <ErrorStyled>{errorMsg}</ErrorStyled>}


                </FieldItself>


                <ButtonArea>
                    {renderButtons}
                </ButtonArea>

            </SettingsFieldWrapper>

            </>
        );
      };



const SettingsFieldWrapper = styled.div`

    position: relative;
    display: flex;
    margin-left: 24px;

    padding-bottom: 32px; 
    
    height: 60px;
    width: 100%;
    max-width: 700px;

`;

const FieldItself = styled.div`

    width: 100%;
    padding-right: 8px;

`;

const ButtonArea = styled.div`

    display: flex;

    padding-bottom: 8px;
    padding-left: 8px;

    color: #333333;
    align-items: flex-end;

`;


const LabelStyled = styled.div`
  width: 100%;
  margin: 0px;
  padding: 0px;
  color: #333333;
  font-family: 'Fira Sans';
  font-size: 16px;
  
  margin-bottom: 4px;
  margin-top: 0px;
`;

const InputStyled = styled.input`
    width: calc(100% - 18px);
    padding: 0px;
    margin: 0px;
    height: 24px;
    padding: 8px;
    
    font-size: 16px;
    font-family: 'Fira Sans';

    border: 1px solid ${props => props.theme.bordercolor};
    border-radius: 4px;  
    color: #333333;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);
    :disabled {
        background-color: #f7f7f7; 
    }
    :focus {
        outline-color: #4C9AFF;
    }
    @media (max-width: 400px) {
        font-family: 'Fira Sans';
        font-size: 17px;
    }
`;

const ErrorStyled = styled.div`
  width: 100%;
  margin: 0px;
  padding: 0px;
  height: 22px;
  color: #FF4343; 
  font-size: 13px;
  margin-top: 4px;
  
`;



const StyledEdit = styled(Button)`
    && {
    height: 40px;
    top: 12px;
    color: #333333;
    width: 45%;
    min-width: 85px;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
/* 
    display: flex;
    justify-content: flex-start;
    padding-left: 8px; */
    
    }
    
`;

const NoButton = styled(Button)`
    && {
    height: 40px;
    top: 12px;
    background-color: white;
    color: #333333;
    width: 45%;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
/* 
    display: flex;
    justify-content: flex-start;
    padding-left: 8px; */
    
    }
    
`;

