import React, { PureComponent } from 'react'

import { Helmet } from "react-helmet";

//import i18next from "i18next";
import '../../../../i18n'

import ReactGA from 'react-ga4';
// import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';

import { Formik, Form } from "formik";

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import { withStore } from '@spyna/react-store'
import { withApollo } from "react-apollo";
import { withRouter } from 'react-router-dom';

import ConfirmDelete from '../ConfirmDelete'; 
import { ErrorModal } from "../../../components/ErrorModal";

import { AboutPage } from "./AboutPage";
import { IntroPage } from "./IntroPage";
import { CategoriesPage } from "./CategoriesPage";
import { DetailsPage } from "./DetailsPage";
import { AlbumsPage } from "./AlbumsPage";
import { PackagesPage } from "./PackagesPage";
import { AvailabilityPage } from "./AvailabilityPage";
import { ContactPage } from "./ContactPage";
import TimeAgo from '../../../../utils/TimeAgo'

import gql from "graphql-tag"


export const defaultModellFormValues = {
  
    name: "",
    gender: 0,
    experience: 0,
    status: 0,

};


const deleteModellMutation = gql`
    mutation($profileId: String!) {
        deleteModell(profileId: $profileId)
    }
`;



class C extends PureComponent {


    state = {
        pageToShow: 'about',
        isMobileMenuVisible: false,
        showConfirmDelete: false,
        showSaveButton: true,
        showImmediateSave : false,
        activationError: '',
        errorTab: '',
    };


    toggleConfirmDelete = () => {  

        this.setState({  
            showConfirmDelete: !this.state.showConfirmDelete 
        });  

    }  

    async componentDidMount() {
    
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

        //if coming directly with url entering (without profile type checking, go back...)
        // if (this.props.location.state === undefined) {
        //     this.props.history.push({ pathname: '/user/profiles' })
        // }
        //console.log(this.props.location.state.profileType)

        //console.log(this.props)
        

    }

    setPage = (pageName) => {
        this.setState({pageToShow: pageName, isMobileMenuVisible: false});
        this.showSaveButton(true);
    }

    showSaveButton = (visibility) => {
        this.setState( { showSaveButton: visibility } );
    }

    showImmediateSaveLabel = (visibility) => {
        this.setState( { showImmediateSave: visibility } );
    }



    deleteProfile = async () => {

        this.setState({  
            showConfirmDelete: false 
        });

        const { match: { params: { profileId } } } = this.props;

        await this.props.client
            .mutate({
                mutation: deleteModellMutation,
                variables: {
                    profileId: profileId
                },
            })
            .then(results => {
                //console.log('deleted', results)
            })
            .catch(error => {
                // console.log("Error: ", error);
            })

        this.props.history.push("/user/profiles");
        window.location.reload();  
        
    }


    validateBeforePublishing = (values) => {

        let errors = {};
        let allowedToSubmit = true;


        // Every time a profile is saved, and its status is set to be public, check this.
        //      and if conditions are not met anymore, change the visibility status to hidden and give a modal message about it.
        
        if (values.status !== 0) {

            // in in case they "ruined" the profile in terms of "valid" (like removed albums and photos and as those changes were immediatelly submitted, 
            //      and the profile was active before, then you must now set the profile as inactive (and save this change immediatelly))

            //SO: Make sure the following fields are filled in order to make the package public:
            
            // console.log(values)
            let categorySet = false;
            if (values.catActing || values.catArt || values.catBodypaint || values.catBoudoir || values.catCosplay || values.catDance || values.catEditorial
                || values.catFashion || values.catFetish || values.catFitness || values.catLifestyle || values.catLingerie || values.catNude || values.catPortrait
                || values.catPregnancy || values.catPromotional || values.catRunway || values.catSport || values.catSwimwear || values.catTopless || values.catUnderwater) { 
                    categorySet = true 
            }
            

                // Profile name / Title for the tour
                if (!values.name) {
                    setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'about', activationError: `To set your profile visible, please fill the name field.`, }); }, 50);
                    allowedToSubmit = false;
                }

                if (!values.serviceProviderName) {
                    setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'contact', activationError: `To set your profile visible, please fill in the service provider info on the Contact page.`, }); }, 50);
                    allowedToSubmit = false;
                }

                if (!values.serviceProviderAddress) {
                    setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'contact', activationError: `To set your profile visible, please fill in the service provider info on the Contact page.`, }); }, 50);
                    allowedToSubmit = false;
                }

                if (!values.serviceProviderEmail) {
                    setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'contact', activationError: `To set your profile visible, please fill in the service provider info on the Contact page.`, }); }, 50);
                    allowedToSubmit = false;
                }

                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.serviceProviderEmail)) {
                    setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'contact', activationError: `Invalid service provider email address entered on the Contact page.`, }); }, 50);
                    allowedToSubmit = false;
                }

                // Intro (and at least one section for tours). 
                if (values.intro.length === 0) {
                    setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'intro', activationError: `To set your profile visible, please fill in the introduction.`, }); }, 50);
                    allowedToSubmit = false;
                }

                if (categorySet === false) {
                    setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'categories', activationError: `To set your profile visible, please select at least one category you're willing to participate.`, }); }, 50);
                    allowedToSubmit = false;
                }

                // Intro / section may not be blank. 
                if (values.intro.length !== 0 && (values.intro[0].intro === '' || values.intro[0].intro === '<p><br></p>')) {
                    setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'intro', activationError: `To set your profile visible, please fill in the introduction.`, }); }, 50);
                    allowedToSubmit = false;
                }
                
                // At least ONE album is required
                if (values.albums.length === 0) {
                    setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'albums', activationError: `To set your profile visible, at least one public album is required`, }); }, 50);
                    allowedToSubmit = false;
                }

                //At least one album must be public & without NSFW 
                if (values.albums.length !== 0) {

                    let visibleAndSafe = false;
                    for (var i = values.albums.length-1; i >= 0; i--) {
                        if ((values.albums[i].visibility !== 0) && (values.albums[i].nsfw === false)) {
                            visibleAndSafe = true;
                        }
                    }

                    if (visibleAndSafe !== true) {
                        setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'albums', activationError: `To set your profile visible, at least one album must be public and without NSFW content`, }); }, 50);
                        allowedToSubmit = false;
                    }

                }
                
                 // At least one package is required!
                // if (values.packages.length === 0) {
                //     setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'packages', activationError: `To set your profile visible, at least one package is required`, }); }, 50);
                //     allowedToSubmit = false;
                // }
                
                //At least one package must be public
                // if (values.packages.length !== 0) {

                //     let isAvailable = false;
                //     for (var z = values.packages.length-1; z >= 0; z--) {
                //         if (values.packages[z].availability !== 0) {
                //             isAvailable = true;
                //         }
                //     }

                //     if (isAvailable !== true) {
                //         setTimeout(() => { this.setState({ showErrorModal: true, errorTab: 'packages', activationError: `To set your profile visible, at least one package must be available to public`, }); }, 50);
                //         allowedToSubmit = false;
                //     }

                // }

                // Availability must be set.. actually not.. because if not set then no booking can be made, and instead, 
                //      there is a button for "request booking" for example... but othign bad happens when nobody can book them...
                //      the reason is that we try to avoid db queries here, as every state chage will trigger this function, so everything would become SOOOOO SLOW
                

            if (!allowedToSubmit) {
                errors.whatswrong = 'CANNOT SET ACTIVE -- RULES NOT MET';
                // values.status = 0;
            } 
       
        }

        if (allowedToSubmit) {
            setTimeout(() => { this.setState({ showErrorModal: false, activationError: ``, }); }, 50);
        }

        return errors;

    }



    toggleNav = () => {
        this.setState({isMobileMenuVisible: !this.state.isMobileMenuVisible});
        //console.log('toggle nav')
    };

    
    render() {

        const { submit, initialValues = defaultModellFormValues } = this.props;

        return (
            
            <ProfileExtraWrapper>

                <Helmet>
                    <title>Model profile</title>
                    <meta name="description" content="Your profile on PhotoSouls" />
                    <meta name="keywords" content="photosouls,profiles,listings" />
                </Helmet>
                


                <div className="bodywrapper">
                    <GeneralWrapper>
                     
                    <Formik
                        validateOnChange={false} 
                        validateOnBlur={false}
                        initialValues={initialValues}
                        validate={this.validateBeforePublishing}
                        onSubmit={submit}
                        >
                        
                        {({ isSubmitting, values, dirty, setFieldValue, errors }) =>
                        (

                            <Form style={{ display: "flex" }}>

                                <PageContent>

                                    <TopBar>

                                        <TopBarLeft>

                                            <CardDiv>
                                            
                                                <AvatarArea>
                                                    { 
                                                        values.avatar ? <Avatar src={values.avatar} 
                                                        alt=" " 
                                                        onLoad={(e) => {
                                                            //e.target.src = values.avatar --- causes huge CPU spike. but looks like not necessary anyway!
                                                            e.target.style = 'display: flex' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                                        }}
                                                        onError={(e) => {
                                                            //e.target.src = '' --- causes huge CPU spike. but looks like not necessary anyway!
                                                            e.target.style = 'display: none' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                                        }}

                                                        /> : <></>
                                                    }
                                                </AvatarArea>

                                                <BurgerButton onClick={this.toggleNav}>
                                                    <FontAwesomeIcon icon={faBars} color="#333333"/>
                                                </BurgerButton>

                                                {this.state.isMobileMenuVisible ? 
                                                    <MobileMenu>
                                                        <MobileBurgerButton onClick={this.toggleNav}>
                                                            <FontAwesomeIcon icon={faTimes} color="#333333"/>
                                                        </MobileBurgerButton>
                                                        <MenuItemAbout onClick={()=> this.setPage('about')} activepage={this.state.pageToShow}>About you</MenuItemAbout>
                                                        <MenuItemIntro onClick={()=> this.setPage('intro')} activepage={this.state.pageToShow}>Intoduction</MenuItemIntro>
                                                        <MenuItemCategories onClick={()=> this.setPage('categories')} activepage={this.state.pageToShow}>Categories</MenuItemCategories>
                                                        <MenuItemDetails onClick={()=> this.setPage('details')} activepage={this.state.pageToShow}>Details</MenuItemDetails>
                                                        <MenuItemAlbums onClick={()=> this.setPage('albums')} activepage={this.state.pageToShow}>Albums</MenuItemAlbums>
                                                        <MenuItemPackages onClick={()=> this.setPage('packages')} activepage={this.state.pageToShow}>Packages</MenuItemPackages>
                                                        <MenuItemAvailability onClick={()=> this.setPage('availability')} activepage={this.state.pageToShow}>Availability</MenuItemAvailability>
                                                        <MenuItemContact onClick={()=> this.setPage('contact')} activepage={this.state.pageToShow}>Contact</MenuItemContact>
                                                    </MobileMenu>
                                                    :
                                                    <></>
                                                }

                                                <MemberInfoCard>

                                                    <CardTitle>{values.name}</CardTitle>
                                                    <SubTitle>Model, {TimeAgo(values.createdAt)},</SubTitle>
                                                    <SubTitle>
                                                        { values.addr_locality ? values.addr_locality +', ' : null } 
                                                        { values.country }
                                                    </SubTitle>
                                                    
                                                </MemberInfoCard>
                                            

                                            </CardDiv>

                                        </TopBarLeft>
                                        
                                        
                                        <TopBarRight>

                                            <ButtonArea>

                                                {
                                                    this.state.showImmediateSave ? 
                                                        <>
                                                        <StyledSave type="submit" disabled></StyledSave>
                                                        <StyledSave type="submit" disabled={isSubmitting ? true : !this.state.showSaveButton }>Exit</StyledSave> 
                                                        </>
                                                        :
                                                        <StyledSave type="submit" disabled={isSubmitting ? true : !this.state.showSaveButton }>Save & exit</StyledSave> 

                                                }

                                            </ButtonArea>

                                        </TopBarRight>
                                        

                                    </TopBar>


                                    <LeftMenu>
                                        <MenuItemAbout onClick={()=> this.setPage('about')} activepage={this.state.pageToShow}>About you</MenuItemAbout>
                                        <MenuItemIntro onClick={()=> this.setPage('intro')} activepage={this.state.pageToShow}>Intoduction</MenuItemIntro>
                                        <MenuItemCategories onClick={()=> this.setPage('categories')} activepage={this.state.pageToShow}>Categories</MenuItemCategories>
                                        <MenuItemDetails onClick={()=> this.setPage('details')} activepage={this.state.pageToShow}>Details</MenuItemDetails>
                                        <MenuItemAlbums onClick={()=> this.setPage('albums')} activepage={this.state.pageToShow} >Albums</MenuItemAlbums>
                                        <MenuItemPackages onClick={()=> this.setPage('packages')} activepage={this.state.pageToShow}>Packages</MenuItemPackages>
                                        <MenuItemAvailability onClick={()=> this.setPage('availability')} activepage={this.state.pageToShow}>Availability</MenuItemAvailability>
                                        <MenuItemContact onClick={()=> this.setPage('contact')} activepage={this.state.pageToShow}>Contact</MenuItemContact>
                                    </LeftMenu>
                                
                                    

                                    

                                    <RightArea>
                                                
                                        {/* { this.state.activationError ? ( <MyErrorWrapper><MyError message = {this.state.activationError} /> </MyErrorWrapper> ) : null } */}

                                        { this.state.activationError ? 
                                            <ErrorModal
                                                showErrorModal={this.state.showErrorModal}
                                                closePopup={() => { 
                                                    this.setState({ showErrorModal: false, activationError: '' }) 
                                                    this.setPage(this.state.errorTab);
                                                }}
                                                popTitle={"Your profile is incomplete. Can't set it visible yet."}
                                                popDescription={this.state.activationError}
                                            />
                                        : null 
                                        }

                                        <div style={{ width: '100%', 'maxWidth': "1200px", top: "0px" }}>
                                        
                                            {
                                                this.state.pageToShow === "about" ? <AboutPage 
                                                    values={values} 
                                                    setField={setFieldValue} />
                                                :
                                                this.state.pageToShow === "intro" ? <IntroPage 
                                                    values={values} 
                                                    setField={setFieldValue}/>
                                                :
                                                this.state.pageToShow === "categories" ? <CategoriesPage 
                                                    values={values} 
                                                    setField={setFieldValue} />
                                                :
                                                this.state.pageToShow === "details" ? <DetailsPage 
                                                    values={values} 
                                                    setField={setFieldValue} />
                                                :
                                                this.state.pageToShow === "albums" ? <AlbumsPage 
                                                    values={values} 
                                                    showImmediateSaveLabel={this.showImmediateSaveLabel} 
                                                    showSaveButton={this.showSaveButton} 
                                                    />
                                                :
                                                this.state.pageToShow === "packages" ? <PackagesPage 
                                                    values={values} 
                                                    showImmediateSaveLabel={this.showImmediateSaveLabel} 
                                                    showSaveButton={this.showSaveButton}  
                                                    setField={setFieldValue}/>
                                                :
                                                this.state.pageToShow === "availability" ? <AvailabilityPage 
                                                    values={values} 
                                                    showImmediateSaveLabel={this.showImmediateSaveLabel} 
                                                    showSaveButton={this.showSaveButton}  
                                                    setField={setFieldValue}/>
                                                :
                                                this.state.pageToShow === "contact" ? <ContactPage 
                                                    values={values} 
                                                    setField={setFieldValue} />
                                                :
                                                <AboutPage 
                                                    values={values} 
                                                    setField={setFieldValue} />
                                            }
                                                
                                              
                                            
                                            
                                            {
                                                this.state.pageToShow === "about" ? 
                                                    (
                                                        <StyledCancel onClick={this.toggleConfirmDelete}>Delete this profile</StyledCancel>
                                                    )
                                                :
                                                <></>
                                            }
                                            {
                                                this.state.showConfirmDelete ?  
                                                    <ConfirmDelete  
                                                        text='Warning! You cannot un-delete or restore the profile after it is deleted. Are you sure you would like to delete this profile? '
                                                        closePopup={this.toggleConfirmDelete}
                                                        onConfirm={this.deleteProfile}
                                                    />
                                                    : 
                                                    <></>  
                                            }  


                                            <MobileBottomNav>

                                                {
                                                    this.state.pageToShow === "about" ? 
                                                        (<>
                                                        <Button onClick={()=> this.setPage('intro')} activepage={this.state.pageToShow}>
                                                            {'Next >>'}
                                                        </Button>
                                                        </>
                                                        )
                                                    :
                                                    this.state.pageToShow === "intro" ? 
                                                        (<>
                                                        <Button onClick={()=> this.setPage('about')} activepage={this.state.pageToShow}>
                                                            {'<< Back'}
                                                        </Button>
                                                        <Button onClick={()=> this.setPage('categories')} activepage={this.state.pageToShow}>
                                                            {'Next >>'}
                                                        </Button>
                                                        </>)
                                                    :
                                                    this.state.pageToShow === "categories" ? 
                                                        (<>
                                                        <Button onClick={()=> this.setPage('intro')} activepage={this.state.pageToShow}>
                                                            {'<< Back'}
                                                        </Button>
                                                        <Button onClick={()=> this.setPage('details')} activepage={this.state.pageToShow}>
                                                            {'Next >>'}
                                                        </Button>
                                                        </>)
                                                    :
                                                    this.state.pageToShow === "details" ? 
                                                        (<>
                                                        <Button onClick={()=> this.setPage('categories')} activepage={this.state.pageToShow}>
                                                            {'<< Back'}
                                                        </Button>
                                                        <Button onClick={()=> this.setPage('albums')} activepage={this.state.pageToShow}>
                                                            {'Next >>'}
                                                        </Button>
                                                        </>)
                                                    :
                                                    this.state.pageToShow === "albums" ? 
                                                        (<>
                                                        <Button onClick={()=> this.setPage('details')} activepage={this.state.pageToShow}>
                                                            {'<< Back'}
                                                        </Button>
                                                        <Button onClick={()=> this.setPage('packages')} activepage={this.state.pageToShow}>
                                                            {'Next >>'}
                                                        </Button>
                                                        </>)
                                                    :
                                                    this.state.pageToShow === "packages" ? 
                                                        (<>
                                                        <Button onClick={()=> this.setPage('albums')} activepage={this.state.pageToShow}>
                                                            {'<< Back'}
                                                        </Button>
                                                        <Button onClick={()=> this.setPage('availability')} activepage={this.state.pageToShow}>
                                                            {'Next >>'}
                                                        </Button>
                                                        </>)
                                                    :
                                                    this.state.pageToShow === "availability" ? 
                                                        (<>
                                                        <Button onClick={()=> this.setPage('packages')} activepage={this.state.pageToShow}>
                                                            {'<< Back'}
                                                        </Button>
                                                        <Button onClick={()=> this.setPage('contact')} activepage={this.state.pageToShow}>
                                                            {'Next >>'}
                                                        </Button>
                                                        </>)
                                                    :
                                                    this.state.pageToShow === "contact" ? 
                                                        (<>
                                                        <Button onClick={()=> this.setPage('availability')} activepage={this.state.pageToShow}>
                                                            {'<< Back'}
                                                        </Button>
                                                        </>)
                                                    :
                                                    null
                                                }
                                                                                                
                                            </MobileBottomNav>


                                        </div>

                                        

                                    </RightArea>

                                    
                                </PageContent>

                                
                                

                            </Form>

                            

                            
                        )
                        }
                    </Formik>

                    

                    </GeneralWrapper>

                
                    

                </div>

                

            </ProfileExtraWrapper>

        )
    }
}

const ProfileExtraWrapper = styled.div`

    @media (max-width: 1090px) {
        overflow-x: hidden;
    }

`;


const TopBar = styled.div`
    
    position: absolute;
    top: 0;
    right: 0;

    top: 0; 
    height: 96px;
    width: 100%;

    background-color: #fdfdfd;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);
    z-index: 1;

    display: flex;
    flex-direction: row;

    justify-content: space-between;

    @media (max-width: 790px) {
        width: calc(100% + 2px);
        }
`;

const TopBarLeft = styled.div`
    
    flex-grow: 0;  

`;


const TopBarRight = styled.div`
   
    flex-grow: 2;  

    display: flex;
    flex-direction: row;

    text-align: center;
    
    justify-content: center;
    align-items: center;

    
`;


const CardDiv = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;    

    /* padding-right: 32px; */
    
    margin-left: 16px;
    margin-top: 16px;

    @media (max-width: 790px) {
        flex-direction: start;
        }

`;

const CardTitle = styled.div`
    
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 2px;
    @media (max-width: 790px) {
        display: none;
        }

`;

const SubTitle = styled.div`
    
    font-size: 13px;
    font-weight: 300;
    line-height: 1.5em;
    
    @media (max-width: 790px) {
        display: none;
        }
    

`;

const Avatar = styled.img`

    height: 64px;
    width: 64px;
    border-radius: 64px;
    
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.4);

`;


const AvatarArea = styled.div`
    
    width: 64px; 
    margin: auto;

    
    /* float: left */

`;

const MemberInfoCard = styled.div`
    
    padding-left: 16px;
    width: calc(100% - 80px);
    
    
`;


const PageContent = styled.div`
    
    display: flex;

    width: 100%;
    
    padding-bottom: 32px;
    
    
    
`;

const LeftMenu = styled.div`
    
    display: flex;
    flex-direction: column;

    width: 176px; 
    /* height: 100%; */
    font-size: 17px;

    margin-left: 16px;
    margin-top: -16px;
    padding-top: 16px;
    
    background-color: #F9F9F9;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    border-left: 1px solid #D8D8D8;
    border-right: 1px solid #D8D8D8;

    @media (max-width: 790px) {
        display: none;
        border-left: 0px;
        border-right: 0px;
        
    }

`;

const MobileMenu = styled.div`
    
    display: flex;
    flex-direction: column;

    position: fixed;

    left: 64px;
    top: 16px;
    
    width: 184px; 
    font-size: 17px;

    margin-left: 8px;
    padding-top: 16px;
    
    background-color: #F9F9F9;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    border: 1px solid #D8D8D8;

    display: none;
    z-index: 90000000;

    @media (max-width: 790px) {
        display: block;
        left: 88px;
        z-index: 90000000;
    }

`;


const BurgerButton = styled.div`
    
    flex-direction: column;

    margin-top: 8px;
    height: 40px;

    font-size: 28px;

    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;

    /* background-color: #deb; */
    /* box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2); */
    /* border-left: 1px solid #D8D8D8; */

    display: none;
    @media (max-width: 790px) {
        display: block;
        border-left: 0px;
    }

`;

const MobileBurgerButton = styled.div`
    
    flex-direction: column;

    width: 40px; 
    font-size: 24px;

    margin-left: 0px;
    margin-top: -17px;

    padding-top: 24px;
    padding-left: 16px;
    padding-bottom: 16px;
    
    
`;


const MobileBottomNav = styled.div`
    
    justify-content: flex-start;
    
    padding-bottom: 16px;
    padding-top: 16px;

    display: none;

    @media (max-width: 790px) {
        display: flex;
        
    }
`;



const ButtonArea = styled.div`

    display: flex;
    align-items: center;

`;

const StyledSave = styled(Button)`
    && {
    min-height: 40px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    color: white;
    
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 16px;

    text-transform: none;
    font-weight: 400;
    font-size: 17px;
        &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }
    }
    
`;

const StyledCancel = styled(Button)`
    && {
    height: 40px;
    margin-top: 16px;

    padding-left: 16px;
    padding-right: 16px;

    margin-bottom: 32px;

    color: #333333;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;

    @media (max-width: 790px) {
        border: 1px solid #efefef;
        box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};
    }


    }
    
`;


const MenuItemAbout = styled.div`
    padding: 8px 16px 8px 16px;
    background-color: ${(props) => props.activepage === 'about' ? "white" : '#F9F9F9'};
    border-top: ${(props) => props.activepage === 'about' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    border-bottom: ${(props) => props.activepage === 'about' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    font-weight: ${(props) => props.activepage === 'about' ? 500 : 400};
    &:hover { text-decoration: underline; cursor: pointer}    
`;
const MenuItemIntro = styled.div`
    padding: 8px 16px 8px 16px;
    background-color: ${(props) => props.activepage === 'intro' ? "white" : '#F9F9F9'};
    border-top: ${(props) => props.activepage === 'intro' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    border-bottom: ${(props) => props.activepage === 'intro' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    font-weight: ${(props) => props.activepage === 'intro' ? 500 : 400};
    &:hover { text-decoration: underline; cursor: pointer}    
`;
const MenuItemCategories = styled.div`
    padding: 8px 16px 8px 16px;
    background-color: ${(props) => props.activepage === 'categories' ? "white" : '#F9F9F9'};
    border-top: ${(props) => props.activepage === 'categories' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    border-bottom: ${(props) => props.activepage === 'categories' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    font-weight: ${(props) => props.activepage === 'categories' ? 500 : 400};
    &:hover { text-decoration: underline; cursor: pointer}    
`;
const MenuItemDetails = styled.div`
    padding: 8px 16px 8px 16px;
    background-color: ${(props) => props.activepage === 'details' ? "white" : '#F9F9F9'};
    border-top: ${(props) => props.activepage === 'details' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    border-bottom: ${(props) => props.activepage === 'details' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    font-weight: ${(props) => props.activepage === 'details' ? 500 : 400};
    &:hover { text-decoration: underline; cursor: pointer}    
`;
const MenuItemAlbums = styled.div`
    padding: 8px 16px 8px 16px;
    background-color: ${(props) => props.activepage === 'albums' ? "white" : '#F9F9F9'};
    border-top: ${(props) => props.activepage === 'albums' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    border-bottom: ${(props) => props.activepage === 'albums' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    font-weight: ${(props) => props.activepage === 'albums' ? 500 : 400};
    &:hover { text-decoration: underline; cursor: pointer}    
`;
const MenuItemPackages = styled.div`
    z-index: 1000;
    padding: 8px 16px 8px 16px;
    background-color: ${(props) => props.activepage === 'packages' ? "white" : '#F9F9F9'};
    border-top: ${(props) => props.activepage === 'packages' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    border-bottom: ${(props) => props.activepage === 'packages' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    font-weight: ${(props) => props.activepage === 'packages' ? 500 : 400};
    &:hover { text-decoration: underline; cursor: pointer}    
`;
const MenuItemAvailability = styled.div`
    padding: 8px 16px 8px 16px;
    background-color: ${(props) => props.activepage === 'availability' ? "white" : '#F9F9F9'};
    border-top: ${(props) => props.activepage === 'availability' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    border-bottom: ${(props) => props.activepage === 'availability' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    font-weight: ${(props) => props.activepage === 'availability' ? 500 : 400};
    &:hover { text-decoration: underline; cursor: pointer}    
`;
const MenuItemContact = styled.div`
    padding: 8px 16px 8px 16px;
    background-color: ${(props) => props.activepage === 'contact' ? "white" : '#F9F9F9'};
    border-top: ${(props) => props.activepage === 'contact' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    border-bottom: ${(props) => props.activepage === 'contact' ? "1px solid #D8D8D8" : '1px solid #F9F9F9'};
    font-weight: ${(props) => props.activepage === 'contact' ? 500 : 400};
    &:hover { text-decoration: underline; cursor: pointer}    
`;



const RightArea = styled.div`
   
    flex-direction: column;
    display: flex;
    
    /* width: calc(80% - 128px); */
    width: 100%;
    max-width: 1200px;
   
    padding-left: 32px;
    padding-right: 32px;

    margin-top: -16px;
    margin-left: 16px;

    padding-top: 24px;

    /* border-right: 1px solid #D8D8D8; */
    /* box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2); */

    @media (max-width: 790px) {
        
        padding-left: 24px;
        padding-right: 32px;
        width: calc(100% - 72px);

        /* width: calc(100% - 144px); */
        /* border-right: 0px; */

        }
`;


const GeneralWrapper = styled.div`
    
    white-space: pre-line;
    width: 80%;
    max-width: 1200px;

    margin: 40px auto 32px auto;
    font-family: 'Fira Sans';

    @media (max-width: 790px) {
        width: calc(100% + 74px);
        margin-left: -40px;
    }

`;



const personalInfoFields = [
    'fName', 'lName', 'bDay', 'email', 'phone', 'phoneDisplay', 'phoneCountry', 'avatar', 'isVerified', 'isPro', 'temperror', 'pwdUpdated',
    'chkReminders', 'chkRemindersP', 'chkMessages', 'chkMessagesP', 'chkRecomm', 'chkRecommP', 'chkInsp', 'chkInspP', 'chkLocal', 'chkLocalP', 
    'chkHow', 'chkHowP', 'chkInvite', 'chkInviteP', 'chkSurvey', 'chkSurveyP', 'chkPolicy', 'chkPolicyP', 'chkTips', 'chkTipsP'
]

export const ModellForm = withRouter(withStore(withApollo(C), personalInfoFields));
