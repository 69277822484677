import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
// mport { HttpLink } from 'apollo-link-http';
import { createUploadLink } from 'apollo-upload-client'
import { ApolloLink } from 'apollo-link';


var uriaddress = 'no-uri'


if (window.location.href.includes('localhost')) {
  uriaddress = 'http://localhost:4000/graphql';

} else if (window.location.href.includes('test.photosouls')) {
  uriaddress = 'https://api.photosouls.com/graphql';

} else if (window.location.href.includes('photosouls')) {
  uriaddress = 'https://api.photosouls.com/graphql';
}


const uploadLink = createUploadLink({ uri: uriaddress, credentials: 'include', headers: { "keep-alive": "true" } });
//const oldLink = new HttpLink({ uri: uriaddress, credentials: 'include', headers: { "keep-alive": "true" } })


export const client = new ApolloClient({

  defaultOptions: {
    
    watchQuery: {
      errorPolicy: 'all'
    },
    query: {
      errorPolicy: 'all'
    },
    mutate: {
      errorPolicy: 'all'
    },

    connectToDevtools: false, //!! IMPORTANT !!! REMOVE IN PRODUCTION!!! keep only while in development. Right now in production, to debug there....
    
  },

  link: ApolloLink.from([ uploadLink ]),

  cache: new InMemoryCache()

});