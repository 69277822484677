const startTimes = [
    { value: '0', code: '00:00', usCode: '12:00 AM', minutesPast: 0, },
    { value: '1', code: '00:30', usCode: '12:30 AM', minutesPast: 30, },
    { value: '2', code: '01:00', usCode: '01:00 AM', minutesPast: 60, },
    { value: '3', code: '01:30', usCode: '01:30 AM', minutesPast: 90, },
    { value: '4', code: '02:00', usCode: '02:00 AM', minutesPast: 120, },
    { value: '5', code: '02:30', usCode: '02:30 AM', minutesPast: 150, },
    { value: '6', code: '03:00', usCode: '03:00 AM', minutesPast: 180, },
    { value: '7', code: '03:30', usCode: '03:30 AM', minutesPast: 210, },
    { value: '8', code: '04:00', usCode: '04:00 AM', minutesPast: 240, },
    { value: '9', code: '04:30', usCode: '04:30 AM', minutesPast: 270, },
    { value: '10', code: '05:00', usCode: '05:00 AM', minutesPast: 300, },
    { value: '11', code: '05:30', usCode: '05:30 AM', minutesPast: 330, },
    { value: '12', code: '06:00', usCode: '06:00 AM', minutesPast: 360, },
    { value: '13', code: '06:30', usCode: '06:30 AM', minutesPast: 390, },
    { value: '14', code: '07:00', usCode: '07:00 AM', minutesPast: 420, },
    { value: '15', code: '07:30', usCode: '07:30 AM', minutesPast: 450, },
    { value: '16', code: '08:00', usCode: '08:00 AM', minutesPast: 480, },
    { value: '17', code: '08:30', usCode: '08:30 AM', minutesPast: 510, },
    { value: '18', code: '09:00', usCode: '09:00 AM', minutesPast: 540, },
    { value: '19', code: '09:30', usCode: '09:30 AM', minutesPast: 570, },
    { value: '20', code: '10:00', usCode: '10:00 AM', minutesPast: 600, },
    { value: '21', code: '10:30', usCode: '10:30 AM', minutesPast: 630, },
    { value: '22', code: '11:00', usCode: '11:00 AM', minutesPast: 660, },
    { value: '23', code: '11:30', usCode: '11:30 AM', minutesPast: 690, },
    { value: '24', code: '12:00', usCode: '12:00 PM', minutesPast: 720, },
    { value: '25', code: '12:30', usCode: '12:30 PM', minutesPast: 750, },
    { value: '26', code: '13:00', usCode: '01:00 PM', minutesPast: 780, },
    { value: '27', code: '13:30', usCode: '01:30 PM', minutesPast: 810, },
    { value: '28', code: '14:00', usCode: '02:00 PM', minutesPast: 840, },
    { value: '29', code: '14:30', usCode: '02:30 PM', minutesPast: 870, },
    { value: '30', code: '15:00', usCode: '03:00 PM', minutesPast: 900, },
    { value: '31', code: '15:30', usCode: '03:30 PM', minutesPast: 930, },
    { value: '32', code: '16:00', usCode: '04:00 PM', minutesPast: 960, },
    { value: '33', code: '16:30', usCode: '04:30 PM', minutesPast: 990, },
    { value: '34', code: '17:00', usCode: '05:00 PM', minutesPast: 1020, },
    { value: '35', code: '17:30', usCode: '05:30 PM', minutesPast: 1050, },
    { value: '36', code: '18:00', usCode: '06:00 PM', minutesPast: 1080, },
    { value: '37', code: '18:30', usCode: '06:30 PM', minutesPast: 1110, },
    { value: '38', code: '19:00', usCode: '07:00 PM', minutesPast: 1140, },
    { value: '39', code: '19:30', usCode: '07:30 PM', minutesPast: 1170, },
    { value: '40', code: '20:00', usCode: '08:00 PM', minutesPast: 1200, },
    { value: '41', code: '20:30', usCode: '08:30 PM', minutesPast: 1230, },
    { value: '42', code: '21:00', usCode: '09:00 PM', minutesPast: 1260, },
    { value: '43', code: '21:30', usCode: '09:30 PM', minutesPast: 1290, },
    { value: '44', code: '22:00', usCode: '10:00 PM', minutesPast: 1320, },
    { value: '45', code: '22:30', usCode: '10:30 PM', minutesPast: 1350, },
    { value: '46', code: '23:00', usCode: '11:00 PM', minutesPast: 1380, },
    { value: '47', code: '23:30', usCode: '11:30 PM', minutesPast: 1410, },
];

function TimeToMinutes(code) {
    

    let output = 0;

    for (var i = 0; i < startTimes.length; i++) {
                
        if (startTimes[i].code === code) { 
            output = startTimes[i].minutesPast;
        }

    }

    //console.log(memberSince)
    return output
    
}


export default TimeToMinutes;


