import moment from "moment"; 
import '../i18n'

function TimeAgo(params) {
    
    // moment.locale(i18next.language);
    // const now = moment(new Date()); //todays date
    // const joined = moment(parseInt(params.joined))
    // const daysBetween = now.diff(joined, 'days')
    // var memberSince = ''
    // if (daysBetween < 30) { memberSince = 'New member' }
    // else { memberSince = 'Member for ' + moment(parseInt(params.joined)).fromNow(true) }
    //console.log('params', params)

    const now = moment(new Date()); //todays date
    const joined = moment(parseInt(params))
    const daysBetween = now.diff(joined, 'days')
    const monthsBetween = now.diff(joined, 'months')
    const yearsBetween = now.diff(joined, 'years')

    var yearsWord = ''
    if (yearsBetween === 1) { yearsWord = 'year' } else { yearsWord = 'years'}

    var memberSince = '';

    if (daysBetween < 30) { 
        memberSince = 'New member' 
    }
    if (monthsBetween === 1) {
        memberSince = 'Member for ' + monthsBetween + ' month'
    }
    if ((monthsBetween > 1) && (monthsBetween <= 12)) {
        memberSince = 'Member for ' + monthsBetween + ' months'
    }
    if ((monthsBetween > 12) && (yearsBetween === 1)) {
        
        if (monthsBetween % 12 === 0) {
            memberSince = 'Member for ' + yearsBetween + ' ' + yearsWord;
        } else if (monthsBetween % 12 === 1) { 
            memberSince = 'Member for ' + yearsBetween + ' ' + yearsWord + ' and ' + monthsBetween % 12 + ' month';
        } else { 
            memberSince = 'Member for ' + yearsBetween + ' ' + yearsWord + ' and ' + monthsBetween % 12 + ' months';
        }
        
    }
    if (yearsBetween > 1) {
        
        if (monthsBetween % 12 === 0) {
            memberSince = 'Member for ' + yearsBetween + ' ' + yearsWord;
        } else if (monthsBetween % 12 === 1) { 
            memberSince = 'Member for ' + yearsBetween + ' ' + yearsWord + ' and ' + monthsBetween % 12 + ' month';
        } else { 
            memberSince = 'Member for ' + yearsBetween + ' ' + yearsWord + ' and ' + monthsBetween % 12 + ' months';
        }
        
    }

    //console.log(memberSince)
    return memberSince
    
}


export default TimeAgo;


