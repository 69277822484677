import React, { PureComponent } from 'react'


import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

import { withStore } from '@spyna/react-store'
import { withApollo } from "react-apollo";
import { withRouter } from 'react-router-dom';
import { StudioCard } from "../studios/StudioCard";
import Footer from '../../modules/components/Footer/Footer'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import Button from '@material-ui/core/Button';
import Modal from 'react-modal';

import { Formik, Form, Field } from "formik";
import { RadioButtonGroup } from 'react-rainbow-components';
import InputNumber from 'rc-input-number';

import { DayPickerRangeController } from 'react-dates'
import 'react-dates/initialize';
import "../../styles/ReactDatesOverride.css"

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';

import styled from 'styled-components';

import MyPlacesAutoComplete from "./../components/MyPlacesAutoComplete";
import { MySelectField } from "./../components/MySelectField";
import getIPInfo from '../../utils/getIPInfo'
//import getBrowserLocales from '../../utils/getBrowserLocales'
import DateRange from '../../utils/DateRange'

import moment from "moment"; 
import i18next from "i18next";

import gql from "graphql-tag"
import { Query } from "react-apollo";


const getStudios = gql`
    query (
        $around: Int, $lng: String, $lat: String, 
        $startDate: String, $endDate: String,
        $isPro: Boolean, $isVerified: Boolean, $isFoundingMember: Boolean, $isTopRated: Boolean, $isTop2023: Boolean, $isTop2024: Boolean, $isTop2025: Boolean, $isTop2026: Boolean, $isTop2027: Boolean, $isTop2028: Boolean, $isTop2029: Boolean, $isTop2030: Boolean, $isTop2031: Boolean, $isTop2032: Boolean, $isTop2033: Boolean, 
        $instaBook: Boolean, $tfp: Boolean, $languages: [String], $minPrice: Float, $maxPrice: Float, $gender: Int,
        ) {
            getStudios( 
                around: $around, lng: $lng, lat: $lat, 
                startDate: $startDate, endDate: $endDate,
                isPro: $isPro, isVerified: $isVerified, isFoundingMember: $isFoundingMember, isTopRated: $isTopRated, isTop2023: $isTop2023, isTop2024: $isTop2024, isTop2025: $isTop2025, isTop2026: $isTop2026, isTop2027: $isTop2027, isTop2028: $isTop2028, isTop2029: $isTop2029, isTop2030: $isTop2030, isTop2031: $isTop2031, isTop2032: $isTop2032, isTop2033: $isTop2033, 
                instaBook: $instaBook, tfp: $tfp, languages: $languages, minPrice: $minPrice, maxPrice: $maxPrice, gender: $gender,
                ) { 
                    id, name, avatar, isPro, isVerified, isFoundingMember, isTopRated, isTop2023, isTop2024, isTop2025, isTop2026, isTop2027, isTop2028, isTop2029, isTop2030, isTop2031, isTop2032, isTop2033,
                    avgRating, reviewsTotal, avgPrice, currency, tfp, instaBook, address, publicURL, premiumURL,
                    albums { name {lng, name}, coverPhotoURL, cpw, cph, },  
                }
        }
`;

            

const today = new Date();


class C extends PureComponent {

    constructor(props) {
        
        super(props);

        this.state = {

            ipCity: 'San Francisco',
            ipRegion: 'California',
            ipCountry: 'United States',
            ipCurrency: 'USD',
            ipLng: '-122.4952',  
            ipLat: '37.7833',
            ipAddress: 'San Francisco, California, United States',

            setLocationFilter: false,
            locationDistance: 300,
            distanceMode: '0',
            modalTopPos: 100,
            modalHeight: 350,
            modalMargin: '0',

            setDatesFilter: false,
            dateFilterModal: false,
            startDate: null,
            endDate: null,

            moreFiltersSet: false,
            moreFiltersModal: false,
            minPrice: 0,
            maxPrice: 9999,
            duration: 0,
            startTime: 0,
            gender: 0,
            lngEN: false,
            lngES: false,
            lngFR: false,
            lngIT: false,
            lngDE: false,
            lngRU: false,
            chkPro: false,
            chkVerified: false,
            chkInstant: false,
            // chkTFP: false,

            mountCompleted: false,
            
        }

        // this.handleDatePickerChange = this.handleDatePickerChange.bind(this)
     
    }


    // handleDatePickerChange (date) {
    //     const dates = this.state.selectedAvailabilityDates
    //     const formattedDate = moment(date).format('YYYY-MM-DD');
    //     const newDates = dates.includes(formattedDate) ? dates.filter(d => formattedDate !== d) : [...dates, formattedDate] 
    //     this.setState({ selectedAvailabilityDates: newDates })
    //     this.props.onChange && this.props.onChange(newDates.toJS())
    // }

  
    async componentDidMount() {
   
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

        let ipData
        const today = new Date();

        // console.log('ipCity: ', localStorage.getItem('ipCity'))
        if ((localStorage.getItem('ipCity') === null) 
            || (localStorage.getItem('ipCity') === 'null') 
            || (localStorage.getItem('ipCity') === 'undefined') 
            || (localStorage.getItem('ipLocatedDate') === null) 
            || (localStorage.getItem('ipLocatedDate') !== moment(today).format('YYYY-MM-DD'))) {
        
                // NO geolocation in the local storage yet. gelocate!
                // and no need to do it more than once a day. YEs there are circumstances where people travel etc, but then they can manually change the addess, and that will eb saved also!
                // but once a day vs every request makes a massive difference at api usage cost when running at scale.
                ipData = await getIPInfo(); 
                // console.log(ipData)
                
                let ipAddress
                if (ipData.city !== ipData.region) {
                    ipAddress = ipData.city + ', ' + ipData.region
                } else {
                    ipAddress = ipData.city
                }
                ipAddress = ipAddress + ', ' + ipData.country
                //console.log('ipAddress', ipAddress)

                //save ip date to the local storage immediatelly after geolocated!
                localStorage.setItem('ipCity', ipData.city);
                localStorage.setItem('ipRegion', ipData.region);
                localStorage.setItem('ipCountry', ipData.country);
                localStorage.setItem('ipCurrency', ipData.currency);
                localStorage.setItem('ipLng', ipData.lng);
                localStorage.setItem('ipLat', ipData.lat);
                localStorage.setItem('ipAddress', ipAddress);
                localStorage.setItem('ipLocatedDate', moment(today).format('YYYY-MM-DD'));

        }       

        if ((localStorage.getItem('locationDistance') === 'null') 
            || (localStorage.getItem('locationDistance') === null)) {
                localStorage.setItem('locationDistance', 300);
                localStorage.setItem('distanceMode', '0');
        }
        if ((localStorage.getItem('startDate') === 'null') 
            || (localStorage.getItem('startDate') === 'Invalid date')) {
                localStorage.setItem('startDate', null);
                localStorage.setItem('endDate', null);
        }


       


        if (JSON.parse(localStorage.getItem('moreFiltersSet')) === true) {
            this.setState({ 
                moreFiltersSet: JSON.parse(localStorage.getItem('moreFiltersSet')),
                minPrice: localStorage.getItem('minPrice'), 
                maxPrice: localStorage.getItem('maxPrice'), 
                duration: localStorage.getItem('duration'), 
                startTime: localStorage.getItem('startTime'), 
                gender: localStorage.getItem('gender'), 
                lngEN: JSON.parse(localStorage.getItem('lngEN')),
                lngES: JSON.parse(localStorage.getItem('lngES')),
                lngFR: JSON.parse(localStorage.getItem('lngFR')),
                lngIT: JSON.parse(localStorage.getItem('lngIT')),
                lngDE: JSON.parse(localStorage.getItem('lngDE')),
                lngRU: JSON.parse(localStorage.getItem('lngRU')),
                chkPro: JSON.parse(localStorage.getItem('chkPro')),
                chkVerified: JSON.parse(localStorage.getItem('chkVerified')),
                chkInstant: JSON.parse(localStorage.getItem('chkInstant')),
                // chkTFP: JSON.parse(localStorage.getItem('chkTFP')),

                ipCity: localStorage.getItem('ipCity'),
                ipRegion: localStorage.getItem('ipRegion'),
                ipCountry: localStorage.getItem('ipCountry'),
                ipCurrency: localStorage.getItem('ipCurrency'),
                ipLng: localStorage.getItem('ipLng'), 
                ipLat: localStorage.getItem('ipLat'),
                ipAddress: localStorage.getItem('ipAddress'), 
    
                locationDistance: localStorage.getItem('locationDistance'), 
                distanceMode: localStorage.getItem('distanceMode'), 
    
                setDatesFilter: JSON.parse(localStorage.getItem('setDatesFilter')),
                startDate: localStorage.getItem('startDate') === 'null' || localStorage.getItem('startDate') === null || localStorage.getItem('startDate') === 'Invalid date' ? null : moment(localStorage.getItem('startDate')),
                endDate: localStorage.getItem('endDate') === 'null' || localStorage.getItem('endDate') === null || localStorage.getItem('endDate') === 'Invalid date' ? null : moment(localStorage.getItem('endDate')),
    
                mountCompleted: true,
    
            });

        } else {

            this.setState({ 
            
                ipCity: localStorage.getItem('ipCity'),
                ipRegion: localStorage.getItem('ipRegion'),
                ipCountry: localStorage.getItem('ipCountry'),
                ipCurrency: localStorage.getItem('ipCurrency'),
                ipLng: localStorage.getItem('ipLng'), 
                ipLat: localStorage.getItem('ipLat'),
                ipAddress: localStorage.getItem('ipAddress'), 
    
                locationDistance: localStorage.getItem('locationDistance'), 
                distanceMode: localStorage.getItem('distanceMode'), 
    
                setDatesFilter: JSON.parse(localStorage.getItem('setDatesFilter')),
                startDate: localStorage.getItem('startDate') === 'null' || localStorage.getItem('startDate') === null || localStorage.getItem('startDate') === 'Invalid date' ? null : moment(localStorage.getItem('startDate')),
                endDate: localStorage.getItem('endDate') === 'null' || localStorage.getItem('endDate') === null || localStorage.getItem('endDate') === 'Invalid date' ? null : moment(localStorage.getItem('endDate')),

                mountCompleted: true,

            });
    

        }
        

        
    
    }


    componentDidUpdate(prevProps, prevState) {

        //console.log('storage updated')
        localStorage.setItem('locationDistance', this.state.locationDistance);
        localStorage.setItem('distanceMode', this.state.distanceMode);

        localStorage.setItem('setDatesFilter', this.state.setDatesFilter);
        localStorage.setItem('startDate', this.state.startDate);
        localStorage.setItem('endDate', this.state.endDate);
      
        

    }

    handleChkChange = event => {

        this.setState({
            [event.target.value]: event.target.checked,
        });
    
        // this.props.values[event.target.value] = event.target.checked ? true : false

    };


    render() {

        //moment.locale(getBrowserLocales);
        moment.locale(i18next.language);
        // i18next.changeLanguage(i18next.language)

        //console.log('this.state: ', this.state)

        const filterStyles = {
            overlay: { zIndex: 1000 },
            content: {

                top: `${ this.state.modalTopPos }`,

                left: '5%',
                margin: `${ this.state.modalMargin }`,
                
                bottom: 'auto',
                
                height: `${ this.state.modalHeight }`,
                width: `${ this.state.modalWidth }px`,

                maxWidth: '74%',

                boxShadow: '0 20px 50px rgba(0, 0, 0, 0.33)',
                borderRadius: '5px',
                paddingTop: '16px',
                paddingLeft: '32px',
                paddingRight: '32px',
                                                    
                overflow : 'auto',
                 

            }
        }

        const filterStylesMobile = {
            overlay: { zIndex: 1000 },
            content: {

                top: `90px`,

                left: '5%',
                margin: `auto`,
                
                bottom: 'auto',
                
                height: `${ this.state.modalHeight }`,
                width: `73%`,

                boxShadow: '0 20px 50px rgba(0, 0, 0, 0.33)',
                borderRadius: '5px',
                paddingTop: '16px',
                paddingLeft: '32px',
                paddingRight: '32px',
                                                    
                overflow : 'auto',
                 

            }
        }

        const distanceOptions = [
            { value: '0', label: 'km', disabled: false },
            { value: '1', label: 'miles', disabled: false },
        ];

        const startTimeOptions = [
            { value: '0', label: "No preference" },
            { value: '1', label: 'Morning' },
            { value: '2', label: 'Noon' },
            { value: '3', label: 'Evening' },
            { value: '4', label: 'Night' },
        ];
        
        const durationOptions = [
            { value: '0', label: "All the time needed for great photos" },
            { value: '1', label: 'Up to an hour' },
            { value: '2', label: 'Couple of hours' },
            { value: '3', label: 'Half a day' },
            { value: '4', label: 'Entire day' },
            { value: '5', label: 'Two days' },
            { value: '6', label: 'Long-term project' },
        ];

        const genderOptions = [
            { value: '0', label: "No preference" },
            { value: '1', label: 'Male' },
            { value: '2', label: 'Female' },
            { value: '3', label: 'Other' },
        ];

        
        

        let getStudiosFilters = {}
            
        //around area (km or miles)
        if (this.state.distanceMode === '0') {
            getStudiosFilters = { ...getStudiosFilters, ...{ around: parseInt(this.state.locationDistance) } }
        } else {
            getStudiosFilters = { ...getStudiosFilters, ...{ around: (parseInt(this.state.locationDistance) * 1.609) } }
        }
        
        //lat & lng
        getStudiosFilters = { ...getStudiosFilters, ...{ lng: this.state.ipLng.toString(), lat: this.state.ipLat.toString() } }

        
        //date filter
        if (this.state.setDatesFilter && this.state.startDate) {

            let algus = moment(this.state.startDate).format('YYYY-MM-DD');
            let lopp = moment(this.state.endDate).format('YYYY-MM-DD');
            if (this.state.startDate && !this.state.endDate) { 
                lopp = algus 
            }

            getStudiosFilters = { ...getStudiosFilters, ...{ startDate: algus, endDate: lopp } }

        }
        

        if (this.state.moreFiltersSet) {

            if (this.state.chkPro) {
                getStudiosFilters = { ...getStudiosFilters, ...{ isPro: true } }
            }

            if (this.state.chkVerified) {
                getStudiosFilters = { ...getStudiosFilters, ...{ isVerified: true } }
            }


            if (this.state.chkInstant) {
                getStudiosFilters = { ...getStudiosFilters, ...{ instaBook: true } }
            }


            // if (this.state.chkTFP) {
            //     getStudiosFilters = { ...getStudiosFilters, ...{ tfp: true } }
            // }


            if ( (this.state.lngEN) || (this.state.lngES) || (this.state.lngFR) || (this.state.lngIT) || (this.state.lngDE) || (this.state.lngRU) ) {

                let languageArray = []

                if (this.state.lngEN) { languageArray.push("en") } 
                if (this.state.lngES) { languageArray.push("es") } 
                if (this.state.lngFR) { languageArray.push("fr") } 
                if (this.state.lngIT) { languageArray.push("it") }
                if (this.state.lngDE) { languageArray.push("de") } 
                if (this.state.lngRU) { languageArray.push("ru") }

                getStudiosFilters = { ...getStudiosFilters, ...{ languages: languageArray } }

            }

            if (this.state.minPrice > 0) {
                getStudiosFilters = { ...getStudiosFilters, ...{ minPrice: parseFloat(this.state.minPrice) } }
            }

            if (this.state.maxPrice < 9999) {
                getStudiosFilters = { ...getStudiosFilters, ...{ maxPrice: parseFloat(this.state.maxPrice) } }
            }
            
            if (this.state.gender > 0) {
                getStudiosFilters = { ...getStudiosFilters, ...{ gender: parseInt(this.state.gender) } }
            }


        }

        if (this.state.mountCompleted === false) {

            // console.log('---------- #  return NULL (as loading not completed')

            return null;
        } else {

            // console.log('+++++++++++++++++++++++    # return starts. State: ', this.state)
            // console.log('+++++++++++++++++++++++    # return starts. Props: ', this.props)

            return (
                
                <>

                    <Helmet>
                        <title>Top Photography Studios for Rent | PhotoSouls - Find Your Ideal Studio Space</title>
                        <meta name="description" content="Discover a range of professional photo studios available for rent at PhotoSouls. From cozy spaces to large studios, find the perfect backdrop for your next shoot." />
                        <meta name="keywords" content="photosouls,studios" />
                    </Helmet>
                    

                    <div className="bodywrapper">

                        
                        <ButtonMenu>
                        
                            <MainTitle>
                                The best studios, ready to host your amazing photoshoots
                            </MainTitle>
                            

                            {/* 
                            ------------- Location filter ---------
                            */}
                            <FilterButton isSet={true} 
                                //onClick={ (e) => { this.setState({ setLocationFilter: !this.state.setLocationFilter, modalTopPos: e.clientY-32+'px', modalHeight: window.innerWidth > 790 ? '310px' : '350px', modalMargin: '0', modalWidth: 500,}) }}
                                onClick={ (e) => { this.setState({ setLocationFilter: !this.state.setLocationFilter, modalTopPos: '16px', modalHeight: window.innerWidth > 790 ? '380px' : '390px', modalMargin: 'auto', modalWidth: 500,}) }}
                                >
                                Around {this.state.ipCity}, &plusmn; {this.state.locationDistance}  {this.state.distanceMode === '0' ? distanceOptions[0].label : distanceOptions[1].label }
                            </FilterButton>
                            <Modal 
                                isOpen={this.state.setLocationFilter}
                                closeTimeoutMS={500}
                                
                                onRequestClose={() => { this.setState({ setLocationFilter: !this.state.setLocationFilter, })  }}
                                ariaHideApp={false}
                                style={window.innerWidth > 790 ? filterStyles : filterStylesMobile}
                                onAfterOpen={() => {
                                    document.body.style.top = `0px`
                                    document.body.style.width = `100vw`
                                    document.body.style.position = 'fixed'
                                }}

                                onAfterClose={() => {
                                    const scrollY = document.body.style.top
                                    document.body.style.position = ''
                                    document.body.style.top = ''
                                    document.body.style.width = ''
                                    window.scrollTo(0, parseInt(scrollY || '0') * -1)
                                }}
                                >

                                <BackButton onClick={() => { this.setState({ setLocationFilter: !this.state.setLocationFilter, }) }} >
                                    <FAIcon icon={faChevronLeft} color={'#000'} />
                                </BackButton>
                                
                                {/* <ClearFilter onClick={() => { this.setState({ setLocationFilter: !this.state.setLocationFilter, }) }} >
                                    Cancel
                                </ClearFilter> */}


                                <br/>
                            
                                <SubTitle>Where you would like to have your photoshoot?</SubTitle>
                                <IncreaseHeight16/>
                                <Formik
                                    initialValues={this.state}
                                    onSubmit={async (values) => {

                                        await new Promise((r) => setTimeout(r, 50));
                                        //alert(JSON.stringify(values, null, 2));

                                        // first check if location field has been changed.... only then can/and should update.
                                        if (values.location) {


                                            setTimeout(() => { this.setState({ 
                                            
                                                ipCity: values.addr_locality,
                                                ipRegion: values.addr_area1,
                                                ipCountry: values.country,
                                                ipLng: values.location.coordinates[0],
                                                ipLat: values.location.coordinates[1],
                                                ipAddress: values.ipAddress,

                                                }); 
                                            }, 50)

                                            localStorage.setItem('ipCity', values.addr_locality);
                                            localStorage.setItem('ipRegion', values.addr_area1);
                                            localStorage.setItem('ipCountry', values.country);
                                            localStorage.setItem('ipLng', values.location.coordinates[0]);
                                            localStorage.setItem('ipLat', values.location.coordinates[1]);
                                            localStorage.setItem('ipAddress', values.ipAddress);

                                        } 

                                        
                                        this.setState({ setLocationFilter: !this.state.setLocationFilter, }) 
                                        

                                    }}
                                    >
                                    
                                    <Form>

                                        <Field
                                            name="ipAddress"
                                            label='Enter Location:'
                                            component={MyPlacesAutoComplete}
                                            value={this.state.ipAddress}
                                        />


                                        <IncreaseHeight16/>

                                        <FlexContainer>

                                            <LeftContainer>   
                                                <LabelStyled>Max distance:</LabelStyled>             
                                                <InputNumberStyled 
                                                    name='locationDistance'
                                                    defaultValue={this.state.locationDistance} 
                                                    //style={{ border: '1px solid #EBE' }}
                                                    onChange={(value) => {
                                                        this.setState({ locationDistance: parseFloat(value) }); 
                                                        }}
                                                    precision={0}
                                                    max={1000}
                                                    min={1}
                                                    type="number"
                                                />
                                                
                                            </LeftContainer>

                                            <DistanceContainer>
                                                <RadioButtonGroup
                                                    id="selectMaxDistanceModeSelection"
                                                    options={distanceOptions}
                                                    value={this.state.distanceMode}
                                                    onChange={ (event) => {
                                                        
                                                        const newModeValue = event.target.value;
                                                        // const distanceMilesToKm = (this.state.locationDistance * 1.609)
                                                        setTimeout(() => { this.setState({ distanceMode: newModeValue, }); }, 50) 


                                                    }}
                                                    //size='small'
                                                />
                                            </DistanceContainer>

                                        </FlexContainer>
                                        

                                        <IncreaseHeight16/>
                                        <ButtonArea>

                                            <StyledSave 
                                                type="submit"
                                                >
                                                Save
                                            </StyledSave>
                                            
                                            {/* <StyledCancel 
                                                onClick={() => { 
                                                    
                                                    this.setState({ setLocationFilter: !this.state.setLocationFilter, }) 

                                                }} 
                                                >
                                                Cancel
                                            </StyledCancel> */}

                                        </ButtonArea>


                                    </Form> 


                                </Formik>
                            
                            </Modal>
                    

                            
                            {/* 
                            ---------- Date filter ------- 
                            */}
                            <FilterButton isSet={this.state.setDatesFilter} 
                                //onClick={ (e) => { this.setState({ dateFilterModal: !this.state.dateFilterModal, modalTopPos: e.clientY-32+'px', modalHeight: '485px', modalMargin: '0', modalWidth: 500,}) }}
                                onClick={ (e) => { this.setState({ dateFilterModal: !this.state.dateFilterModal, modalTopPos: '16px', modalHeight: window.innerWidth > 790 ? '600px' : '600px', modalMargin: 'auto', modalWidth: 500,}) }}
                                >
                                { 
                                    this.state.setDatesFilter ? 
                                        DateRange(this.state.startDate, this.state.endDate, today) 
                                        :
                                        'Dates' 
                                }
                            </FilterButton>
                            <Modal 
                                isOpen={this.state.dateFilterModal}
                                closeTimeoutMS={500}

                                onRequestClose={() => { this.setState({ dateFilterModal: !this.state.dateFilterModal, })  }}
                                ariaHideApp={false}
                                style={window.innerWidth > 790 ? filterStyles : filterStylesMobile}
                                onAfterOpen={() => {
                                    document.body.style.top = `0px`
                                    document.body.style.width = `100vw`
                                    document.body.style.position = 'fixed'
                                }}

                                onAfterClose={() => {
                                    const scrollY = document.body.style.top
                                    document.body.style.position = ''
                                    document.body.style.top = ''
                                    document.body.style.width = ''
                                    window.scrollTo(0, parseInt(scrollY || '0') * -1)
                                }}
                                >

                                <BackButton onClick={ () => setTimeout(() => { this.setState({ dateFilterModal: false }); }, 50) } >
                                    <FAIcon icon={faChevronLeft} color={'#000'} />
                                </BackButton>
                                { 
                                    this.state.setDatesFilter && this.state.dateFilterModal && this.state.startDate !== null ? 
                                        <ClearFilter onClick={() => { 
                                                    this.setState({ dateFilterModal: !this.state.dateFilterModal, setDatesFilter: false, startDate: null, endDate: null }) 
                                            }}>
                                            Clear filter
                                        </ClearFilter>
                                        :
                                        null
                                }

                                <br/>

                            
                                <SubTitle>When would you like to have your photoshoot?</SubTitle>
                                
                                <PushLeft20>

                                    <DayPickerRangeController
                                        startDate={this.state.startDate ? this.state.startDate.locale(i18next.language) : null} //? is used for workaround. without this, moment.locale is not applied.
                                        endDate={this.state.endDate ? this.state.endDate.locale(i18next.language) : null}
                                        onDatesChange={(dates) => { this.setState({ startDate: dates.startDate, endDate: dates.endDate, setDatesFilter: true }) }}
                                        focusedInput={this.state.focusedInput || "startDate"}
                                        onFocusChange={(focusedInput) => { this.setState({ focusedInput }) }}
                                        numberOfMonths={1}
                                        minimumNights={0}
                                        noBorder={true}

                                    />

                                </PushLeft20>

                                
                                <ButtonArea>

                                    <StyledSave 
                                        onClick={() => { this.setState({ dateFilterModal: !this.state.dateFilterModal, }) }}
                                        disabled={this.state.startDate === null} 
                                        >
                                        Save
                                    </StyledSave>
                                    
                                    {/* <StyledCancel 
                                        onClick={() => { 
                                            
                                            this.setState({ dateFilterModal: !this.state.dateFilterModal, setDatesFilter: false, startDate: null, endDate: null }) 

                                        }} 
                                        >
                                        Clear filter
                                    </StyledCancel> */}

                                </ButtonArea>
                                
                                <SectionSubTitle>Only the studios available based on their calendar will be shown to you</SectionSubTitle>
                                { 
                                    window.innerWidth > 790 ?
                                        null 
                                        : 
                                        <><br/><br/><br/><br/><br/></>
                                }
                            
                            </Modal>



                            {/* 
                            ---------- More filters ------- 
                            */}
                            <FilterButton isSet={this.state.moreFiltersSet} 
                                onClick={ (e) => { this.setState({ moreFiltersModal: !this.state.moreFiltersModal, modalTopPos: '16px', modalHeight: window.innerWidth > 790 ? '90%' : '75%',  modalMargin: 'auto', modalWidth: 550,}) }}
                                >
                                More filters
                            </FilterButton>
                            <Modal 
                                isOpen={this.state.moreFiltersModal}
                                closeTimeoutMS={500}
                                
                                onRequestClose={() => { this.setState({ moreFiltersModal: !this.state.moreFiltersModal, })  }}
                                ariaHideApp={false}
                                style={window.innerWidth > 790 ? filterStyles : filterStylesMobile}
                                onAfterOpen={() => {
                                    document.body.style.top = `0px`
                                    document.body.style.width = `100vw`
                                    document.body.style.position = 'fixed'
                                }}

                                onAfterClose={() => {
                                    const scrollY = document.body.style.top
                                    document.body.style.position = ''
                                    document.body.style.top = ''
                                    document.body.style.width = ''
                                    window.scrollTo(0, parseInt(scrollY || '0') * -1)
                                }}
                                >

                                <BackButton onClick={ () => setTimeout(() => { this.setState({ moreFiltersModal: false }); }, 50) } >
                                    <FAIcon icon={faChevronLeft} color={'#000'} />
                                </BackButton>
                                { 
                                    this.state.moreFiltersSet && this.state.moreFiltersModal ? 
                                        <ClearFilter onClick={() => { 
                                                    this.setState({ moreFiltersSet: false,
                                                        moreFiltersModal: false,
                                                        minPrice: 0,
                                                        maxPrice: 9999,
                                                        duration: 0,
                                                        startTime: 0,
                                                        gender: 0,
                                                        lngEN: false,
                                                        lngES: false,
                                                        lngFR: false,
                                                        lngIT: false,
                                                        lngDE: false,
                                                        lngRU: false,
                                                        chkPro: false,
                                                        chkVerified: false,
                                                        chkInstant: false, 
                                                    })

                                                    localStorage.setItem('minPrice', this.state.minPrice);
                                                    localStorage.setItem('maxPrice', this.state.maxPrice);
                                                    localStorage.setItem('duration', this.state.duration);
                                                    localStorage.setItem('startTime', this.state.startTime);
                                                    localStorage.setItem('gender', this.state.gender);
                                                    localStorage.setItem('lngEN', this.state.lngEN);
                                                    localStorage.setItem('lngES', this.state.lngES);
                                                    localStorage.setItem('lngFR', this.state.lngFR);
                                                    localStorage.setItem('lngIT', this.state.lngIT);
                                                    localStorage.setItem('lngDE', this.state.lngDE);
                                                    localStorage.setItem('lngRU', this.state.lngRU);
                                                    localStorage.setItem('chkPro', this.state.chkPro);
                                                    localStorage.setItem('chkVerified', this.state.chkVerified);
                                                    localStorage.setItem('chkInstant', this.state.chkInstant);
                                                    // localStorage.setItem('chkTFP', this.state.chkTFP);

                                                    localStorage.setItem('moreFiltersSet', true);


                                            }}>
                                            Clear filter
                                        </ClearFilter>
                                        :
                                    null
                                }


                                <br/>
                        
                            
                                <Formik
                                    initialValues={this.state}
                                    onSubmit={async (values) => {

                                        await new Promise((r) => setTimeout(r, 50));
                                        //alert(JSON.stringify(values, null, 2));

                                        localStorage.setItem('minPrice', this.state.minPrice);
                                        localStorage.setItem('maxPrice', this.state.maxPrice);
                                        localStorage.setItem('duration', this.state.duration);
                                        localStorage.setItem('startTime', this.state.startTime);
                                        localStorage.setItem('gender', this.state.gender);
                                        localStorage.setItem('lngEN', this.state.lngEN);
                                        localStorage.setItem('lngES', this.state.lngES);
                                        localStorage.setItem('lngFR', this.state.lngFR);
                                        localStorage.setItem('lngIT', this.state.lngIT);
                                        localStorage.setItem('lngDE', this.state.lngDE);
                                        localStorage.setItem('lngRU', this.state.lngRU);
                                        localStorage.setItem('chkPro', this.state.chkPro);
                                        localStorage.setItem('chkVerified', this.state.chkVerified);
                                        localStorage.setItem('chkInstant', this.state.chkInstant);
                                        // localStorage.setItem('chkTFP', this.state.chkTFP);

                                        localStorage.setItem('moreFiltersSet', true);

                                        this.setState({ 
                                            moreFiltersModal: !this.state.moreFiltersModal, 
                                            moreFiltersSet: true 
                                        })
                                                
                                        
                                        

                                    }}
                                    >

                                    <Form>

                                        
                                        <SubTitle>Price range</SubTitle>
                                        <LabelStyled>The photoshoot price depends on the length and other variables. Your filter will be set based on the average hourly rates. </LabelStyled>    
                                        
                                        {/* Currently the average hourly rate in the selected area is €18/hour */}
                                        <IncreaseHeight16/>
                                        <FlexContainer>

                                            <Width30>   
                                                <LabelStyled>Min price:</LabelStyled>             
                                                <InputNumberStyled 
                                                    name='minPrice'
                                                    defaultValue={this.state.minPrice} 
                                                    //style={{ border: '1px solid #EBE' }}
                                                    onChange={(value) => {
                                                        this.setState({ minPrice: parseFloat(value) }); 
                                                        }}
                                                    precision={0}
                                                    max={1000}
                                                    min={0}
                                                    type="number"
                                                />
                                                
                                            </Width30>
                                            
                                            <Width30>
                                                <LabelStyled>Max price:</LabelStyled>             
                                                <InputNumberStyled 
                                                    name='maxPrice'
                                                    defaultValue={this.state.maxPrice} 
                                                    //style={{ border: '1px solid #EBE' }}
                                                    onChange={(value) => {
                                                        this.setState({ maxPrice: parseFloat(value) }); 
                                                        }}
                                                    precision={0}
                                                    max={9999}
                                                    min={0}
                                                    type="number"
                                                />
                                            </Width30>

                                            {/* <Width30>
                                                <ChkContainerWTopMargin>
                                                    <FormControlLabel 
                                                        control={
                                                            <OrangeCheckBox
                                                                checked={this.state.chkTFP}
                                                                onChange={this.handleChkChange}
                                                                value="chkTFP"
                                                            />
                                                        }
                                                    label={'Open for TFP'}
                                                    />          
                                                </ChkContainerWTopMargin>
                                            </Width30> */}

                                        </FlexContainer>
                                        
                                        <IncreaseHeight16/>
                                        <IncreaseHeight16/>



                                        <SubTitle>Duration</SubTitle>
                                        <Field
                                            name="duration"
                                            label='How much time you have?'
                                            component={MySelectField}

                                            originalOptions={durationOptions}
                                            originalValue={this.state.duration}
                                            onChange={ (e) => {
                                                this.setState({ duration: parseInt(e.value) }); 
                                            }}
                                                
                                        />
                                        <IncreaseHeight16/>
                                        <IncreaseHeight16/>


                                        <SubTitle>Preferred time</SubTitle>
                                        <Field
                                            name="startTime"
                                            label='When would you like to start with your photoshoot?'
                                            component={MySelectField}

                                            originalOptions={startTimeOptions}
                                            originalValue={this.state.startTime}
                                            onChange={ (e) => {
                                                this.setState({ startTime: parseInt(e.value) }); 
                                            }}
                                                
                                        />
                                        <IncreaseHeight16/>
                                        <IncreaseHeight16/>
                                        

                                        <SubTitle>Language</SubTitle>
                                        <LabelStyled>If you have a preference in the language you would like use when communicating with your studiorapher, please specify it here.</LabelStyled>    
                                        {/* 
                                        Ceate a link at the bottom of checkboxes: "Show more". 
                                        This will expand and open the list of all languages used by the listings (in this area only maybe?).
                                        */}

                                        <ChkGrid>
                                            <ChkContainer>
                                                <FormControlLabel 
                                                    control={
                                                        <OrangeCheckBox
                                                            checked={this.state.lngEN}
                                                            onChange={this.handleChkChange}
                                                            value="lngEN"
                                                        />
                                                    }
                                                label={'English'}
                                                />          
                                            </ChkContainer>      
                                            <ChkContainer>
                                                <FormControlLabel 
                                                    control={
                                                        <OrangeCheckBox
                                                            checked={this.state.lngES}
                                                            onChange={this.handleChkChange}
                                                            value="lngES"
                                                        />
                                                    }
                                                label={'Spanish'}
                                                />          
                                            </ChkContainer>     
                                            <ChkContainer>
                                                <FormControlLabel 
                                                    control={
                                                        <OrangeCheckBox
                                                            checked={this.state.lngFR}
                                                            onChange={this.handleChkChange}
                                                            value="lngFR"
                                                        />
                                                    }
                                                label={'French'}
                                                />          
                                            </ChkContainer>   
                                            <ChkContainer>
                                                <FormControlLabel 
                                                    control={
                                                        <OrangeCheckBox
                                                            checked={this.state.lngIT}
                                                            onChange={this.handleChkChange}
                                                            value="lngIT"
                                                        />
                                                    }
                                                label={'Italian'}
                                                />          
                                            </ChkContainer>   

                                            <ChkContainer>
                                                <FormControlLabel 
                                                    control={
                                                        <OrangeCheckBox
                                                            checked={this.state.lngDE}
                                                            onChange={this.handleChkChange}
                                                            value="lngDE"
                                                        />
                                                    }
                                                label={'German'}
                                                />          
                                            </ChkContainer>     
                                            <ChkContainer>
                                                <FormControlLabel 
                                                    control={
                                                        <OrangeCheckBox
                                                            checked={this.state.lngRU}
                                                            onChange={this.handleChkChange}
                                                            value="lngRU"
                                                        />
                                                    }
                                                label={'Russian'}
                                                />          
                                            </ChkContainer>     
                                            
                                        </ChkGrid>    

                                        <IncreaseHeight16/>
                                        <IncreaseHeight16/>


                                        <SubTitle>Gender</SubTitle>
                                        <Field
                                            name="gender"
                                            label='Any gender preferences?'
                                            component={MySelectField}

                                            originalOptions={genderOptions}
                                            originalValue={this.state.gender}
                                            onChange={ (e) => {
                                                this.setState({ gender: parseInt(e.value) }); 
                                            }}
                                                
                                        />
                                        <IncreaseHeight16/>
                                        <IncreaseHeight16/>


                                        <SubTitle>Membership type</SubTitle>
                                        <ChkContainer100p>
                                                <FormControlLabel 
                                                    control={
                                                        <OrangeCheckBox
                                                            checked={this.state.chkPro}
                                                            onChange={this.handleChkChange}
                                                            value="chkPro"
                                                        />
                                                    }
                                                label={'Show only PhotoSouls Pro memebers'}
                                                />          
                                        </ChkContainer100p>     
                                        <ChkContainer100p>
                                            <FormControlLabel 
                                                control={
                                                    <OrangeCheckBox
                                                        checked={this.state.chkVerified}
                                                        onChange={this.handleChkChange}
                                                        value="chkVerified"
                                                    />
                                                }
                                            label={'Show only PhotoSouls Verified studios'}
                                            />          
                                        </ChkContainer100p>     
                                        <IncreaseHeight16/>
                                        <IncreaseHeight16/>
                                        

                                        <SubTitle>Instant Book</SubTitle>
                                        <ChkContainer100p>
                                            <FormControlLabel 
                                                control={
                                                    <OrangeCheckBox
                                                        checked={this.state.chkInstant}
                                                        onChange={this.handleChkChange}
                                                        value="chkInstant"
                                                    />
                                                }
                                            label={'Show only studios that can be booked without extra communication and waiting for their approval.'}
                                            />          
                                        </ChkContainer100p>     
                                        <IncreaseHeight16/>
                                        <IncreaseHeight16/>


                                        <ButtonArea>

                                            <StyledSave type="submit"
                                                >
                                                Save
                                            </StyledSave>
                                            
                                            <StyledCancel 
                                                onClick={() => { 
                                                    
                                                    this.setState({ 
                                                        moreFiltersSet: false,
                                                        moreFiltersModal: false,
                                                        minPrice: 0,
                                                        maxPrice: 9999,
                                                        duration: 0,
                                                        startTime: 0,
                                                        gender: 0,
                                                        lngEN: false,
                                                        lngES: false,
                                                        lngFR: false,
                                                        lngIT: false,
                                                        lngDE: false,
                                                        lngRU: false,
                                                        chkPro: false,
                                                        chkVerified: false,
                                                        chkInstant: false,
                                                        // chkTFP: false,
                                                    }) 

                                                    localStorage.removeItem('minPrice');
                                                    localStorage.removeItem('maxPrice');
                                                    localStorage.removeItem('duration');
                                                    localStorage.removeItem('startTime');
                                                    localStorage.removeItem('gender');
                                                    localStorage.removeItem('lngEN');
                                                    localStorage.removeItem('lngES');
                                                    localStorage.removeItem('lngFR');
                                                    localStorage.removeItem('lngIT');
                                                    localStorage.removeItem('lngDE');
                                                    localStorage.removeItem('lngRU');
                                                    localStorage.removeItem('chkPro');
                                                    localStorage.removeItem('chkVerified');
                                                    localStorage.removeItem('chkInstant');
                                                    // localStorage.removeItem('chkTFP');

                                                    localStorage.setItem('moreFiltersSet', false);


                                                }} 
                                                >
                                                Clear filters
                                            </StyledCancel>

                                        </ButtonArea>
                    
                                    </Form> 


                                </Formik>
                            
                            </Modal>
                        

                        </ButtonMenu>
                            



                        { this.state.moreFiltersModal || this.state.dateFilterModal || this.state.setLocationFilter ? 

                            null
                            :
                            <ProfileResults>

                                <Query
                                    query={getStudios}
                                    variables={getStudiosFilters}
                                    fetchPolicy="no-cache"
                                >
                                {
                                    ({ data, loading }) => {
                                    
                                        // console.log('getStudiosFilters', getStudiosFilters)
                                        // console.log('getStudios data: ', data)

                                        // if ((data === undefined) || (data === null) || (loading === true)) {
                                        if ((data === undefined) || (data === null) || ((loading === true) && (JSON.stringify(data).length < 35))) {

                                            return (
                                                <MainTitle>Searching for studios, please wait...</MainTitle> 
                                            )    

                                        } else if (JSON.stringify(data).length > 35) {
                                            
                                            // console.log('data 2: ', data) 
                                            return (<>

                                                { 
                                                    
                                                    data.getStudios.map((studio, index) => {

                                                        //console.log('studio (data for studio card details)', studio)

                                                        let profileURL = ""
                                                        if (studio.premiumURL) {
                                                            profileURL = '/' + studio.premiumURL
                                                        } else if (studio.publicURL) {
                                                            profileURL = '/' + studio.publicURL
                                                        } else {
                                                            profileURL = '/studiorapher/' + studio.id
                                                        }


                                                        return (
                                                            
                                                            <StudioCard
                                                                key={index}

                                                                address={studio.address} 
                                                                albums={studio.albums} 
                                                                avatar={studio.avatar} 
                                                                avgPrice={studio.avgPrice} 
                                                                tfp={studio.tfp} 
                                                                currency={studio.currency}
                                                                instaBook={studio.instaBook}
                                                                avgRating={studio.avgRating}
                                                                reviewsTotal={studio.reviewsTotal}
                                                                category={-1} //no category for favorites. only use if seach filter has set specific category
                                                                id={studio._id} 
                                                                isPro={studio.isPro} 
                                                                isVerified={studio.isVerified} 
                                                                isFoundingMember={studio.isFoundingMember} 
                                                                isTopRated={studio.isTopRated} 
                                                                isTop2023={studio.isTop2023} 
                                                                isTop2024={studio.isTop2024} 
                                                                isTop2025={studio.isTop2025} 
                                                                isTop2026={studio.isTop2026} 
                                                                isTop2027={studio.isTop2027} 
                                                                isTop2028={studio.isTop2028} 
                                                                isTop2029={studio.isTop2029} 
                                                                isTop2030={studio.isTop2030} 
                                                                isTop2031={studio.isTop2031} 
                                                                isTop2032={studio.isTop2032} 
                                                                isTop2033={studio.isTop2033} 
                                                                name={studio.name} 
                                                                url={profileURL}
                                                                // onClick={() => { this.props.history.push(profileURL) }}
                                                            /> 
                                    
                                                        );

                                                    })


                                                }

                                            </>);

                                        } else {
                                            return(<>
                                                <SubTitle><Em><span role="img" aria-label='no'>😔</span></Em>Sorry, we didn't find any studios matching the filter set above. But hey, that could change – with you!</SubTitle>
                                                <IncreaseHeight16/>
                                                <SubTitle>Are you a photographer? This is your golden opportunity to be among the first ones to showcase your talent and offer your services in this area! By signing up now, you'll not only be pioneering the photography scene but also getting ahead of the competition.</SubTitle>
                                                <IncreaseHeight16/>
                                                <SubTitle>Click below to create your profile and start connecting with potential clients today!</SubTitle>
                                                <IncreaseHeight16/>
                                                <NewLineSection>
                                                <BtnPrimary onClick={ () => this.props.history.push({ pathname: 'signup/' }) }>Sign Up Now</BtnPrimary>
                                                </NewLineSection>
                                                <ProfileResults>
                                                {
                                                    this.state.setDatesFilter || this.state.catFilterSet || this.state.moreFiltersSet ? 
                                                    <LinkButton onClick={() => { 

                                                        this.setState({ 

                                                            setDatesFilter: false, 
                                                            startDate: null, 
                                                            endDate: null,

                                                            moreFiltersSet: false,
                                                            minPrice: 0,
                                                            maxPrice: 9999,
                                                            duration: 0,
                                                            startTime: 0,
                                                            gender: 0,
                                                            lngEN: false,
                                                            lngES: false,
                                                            lngFR: false,
                                                            lngIT: false,
                                                            lngDE: false,
                                                            lngRU: false,
                                                            chkPro: false,
                                                            chkVerified: false,
                                                            chkInstant: false, 

                                                            catFilterSet: false,

                                                        })

                                                        }}>
                                                        Click here to clear filters
                                                    </LinkButton>
                                                    :
                                                    null

                                                }
                                                <br/><br/><br/>
                                                </ProfileResults>
                                            </>)
                                        }
                                        
                                    
                                    }

                                }
                
                                </Query>
                                
                            </ProfileResults>

                        }



        

                    <Footer showbutton="true"/>
                    </div>

                    

                </>

            )
            
        }
    }
}



const personalInfoFields = [
    'fName', 'lName', 'bDay', 'email', 'phone', 'phoneDisplay', 'phoneCountry', 'avatar', 'isVerified', 'isPro', 'temperror', 'pwdUpdated',
    'chkReminders', 'chkRemindersP', 'chkMessages', 'chkMessagesP', 'chkRecomm', 'chkRecommP', 'chkInsp', 'chkInspP', 'chkLocal', 'chkLocalP', 
    'chkHow', 'chkHowP', 'chkInvite', 'chkInviteP', 'chkSurvey', 'chkSurveyP', 'chkPolicy', 'chkPolicyP', 'chkTips', 'chkTipsP'
]

export const ListStudios = withRouter(withStore(withApollo(C), personalInfoFields));



const LinkButton = styled.div`
    
    color: #4C9AFF;

    margin: 0 8px 0 0; 

    font-weight: 400;
    font-size: 20px;

    font-family: 'Fira Sans';


    &:hover {
        text-decoration: underline;
        cursor: pointer
        }

`;


const StyledSave = styled(Button)`
    && {
    height: 40px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    color: white;
    
    
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 16px;
    margin-bottom: 32px;
    margin-top: 16px;

    text-transform: none;
    font-weight: 400;
    font-size: 17px;
        &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }
    }
    
`;

const StyledCancel = styled(Button)`
    && {
    height: 40px;
    margin-top: 16px;

    padding-left: 16px;
    padding-right: 16px;

    margin-bottom: 32px;

    color: #333333;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    }
    
`;


const ClearFilter = styled(Button)`
    && {
    height: 40px;

    margin-top: 8px;

    padding-left: 16px;
    padding-right: 16px;


    color: #333333;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    }
    
`;


const ButtonMenu = styled.div`
    
    padding-bottom: 32px;
    

`;



const MainTitle = styled.h1`
    
    font-size: 22px;
    font-weight: 500;
    padding: 0px 24px 24px 0px;


    font-family: 'Fira Sans';
    color: #333333;

`;


const SubTitle = styled.div`
    
    font-size: 20px;
    font-weight: 400;
    padding: 16px 24px 16px 0px;


    font-family: 'Fira Sans';
    color: #333333;

`;




const FilterButton = styled(Button)`
    && {
    height: 40px;

    padding: 16px;

    margin-right: 8px;
    margin-bottom: 8px;

    background: ${(props) => props.isSet ? '#f5f5f5': "white"};
    border: ${(props) => props.isSet ? '4px double #cbcbcb;' : "1px solid #cbcbcb;"};

    border-radius: 20px;  


    font-family: 'Fira Sans';
    color: #333333;

    
    /* box-shadow: ${(props) => props.isSet ? '0 2px 8px 0 rgba(170, 170, 170, 0.4);' : "0"}; */


    text-transform: none;
    font-weight: 400;
    font-size: 17px;

    &:hover {
        border-color: #333333;
        }

    }
    
`;




const ProfileResults = styled.div`

    width: 100%;
    

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    align-items: flex-start;
    justify-content: center;
   
    flex-direction: row;
    justify-content: flex-start;

    z-index: 200;

`;

// const ProfileResults = styled.div`

//     width: 100%;

//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
    
//     align-items: flex-start;
//     justify-content: center;
   
//     flex-direction: row;
//     justify-content: flex-start;

//     padding-top: 8px;
//     z-index: 200;

// `;



const ButtonArea = styled.div`

    display: flex;

    padding-bottom: 0px;
    padding-left: 0px;

    color: #333333;
    align-items: flex-end;

`;



const IncreaseHeight16 = styled.div`
    
    height: 16px;  

`;

const LeftContainer = styled.div`

`;

const Width30 = styled.div`
    width: 30%;
    min-width: 30%;
    padding-right: 32px;

`;


const DistanceContainer = styled.div`
    
    margin-top: 24px;
    margin-left: 16px;

`;

const FlexContainer = styled.div`
    display: flex;

`;




const InputNumberStyled = styled(InputNumber)`
    
    width: calc(100% - 18px);
    padding: 0px;
    margin: 0px;
    
    && {
        input {
            
            
            font-size: 16px;
            font-family: 'Fira Sans';
            font-weight: 400;

            width: 100%;
            padding: 0px;
            margin: 0px;
            height: 24px;
            padding: 8px;
            
            color: #333333;

            border: 1px solid #cbcbcb;
            border-radius: 4px;  
            box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

            :focus {
                outline-color: #4C9AFF;
            }

            :disabled {
                background-color: #f7f7f7; 
            }

        }
    }
    
`;

const LabelStyled = styled.div`
    width: 100%;
    margin: 0px;
    padding: 0px;
    color: #333333;
    font-size: 16px;
    
    margin-bottom: 4px;
    margin-top: 0px;
    margin-left: 2px;
`;




const PushLeft20 = styled.div`
    margin-left: -20px;
`;

const OrangeCheckBox = withStyles({
    root: {
      color: orange[400],
      '&$checked': {
        color: orange[600],
      },
    },
    checked: {},
    })(props => <Checkbox color="default" {...props} /> )
;


const ChkContainer = styled.div`
    
  padding-left: 0px;
  padding-top: 8px;
  padding-bottom: 0px;
  width: 155px;

  font-family: 'Fira Sans';
  color: #333333;
  
`;

const ChkContainer100p = styled.div`
    
  padding-left: 0px;
  padding-top: 8px;
  padding-bottom: 0px;
  width: 100%;
  
  font-family: 'Fira Sans';
  color: #333333;

`;

const ChkGrid = styled.div`
    
    display: flex;
    flex-wrap: wrap;    

`;



const SectionSubTitle = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 300;
    font-size: 17px;

    /* color: #505050; */
    /* color: #383838; */
    color: #333333;

    /* background-color: #ded; */

    margin-bottom: 8px;
`;


const BackButton = styled(Button)`
    && {
        
    /* margin: -4px -4px 0 0px; */

    height: 48px;
    min-width: 48px;
    max-width: 48px;
    border-radius: 24px;

    margin-right: 16px;
    margin-top: 8px;
    /* margin-right: 4px;
    margin-left: 4px; */

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;

    
    font-weight: 400;
    font-size: 19px;

    background-color: #EBEBEB;
    opacity: 0.6;

    &:hover {
        background-color: #EBEBEB;
        opacity: 0.6;
        }
    
    &:disabled {
        background-color: #fff;
        color: #fff;
        }

    }
    
`;

const FAIcon = styled(FontAwesomeIcon)`
    && {
    font-size: 22px;
    font-weight: 400;
    }
`;



const Em = styled.span`
    padding-right: 8px;
`;

const NewLineSection = styled.div`
    
    margin-top: 16px; 
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    justify-content: space-between;

`;

const BtnPrimary = styled(Button)`
    
    && {
    min-height: 64px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    display: block;
    color: white;
    
    padding-left: 32px;
    padding-right: 32px;
  
    text-transform: none;
    font-weight: 400;
    font-size: 22px;
    
    &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }

}     
`;
