
import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export class Card extends Component {
    
  render() {
      
    const { title, desc, icon, link } = this.props;

    
    return (
      
        <>
            <StyledLink to={link}>
            
                <CardDiv>

                    <MyIcon icon={icon}/>

                    <CardTitle>{title}</CardTitle>
                    <CardDesc>{desc}</CardDesc>

                </CardDiv>

            </StyledLink>

        </>
    
    );

  }
}


const StyledLink = styled(Link)`
    text-decoration: none;

`;

const CardDiv = styled.div`
    
    width: 250px;

    padding: 16px 24px 16px 24px;
    border-radius: 8px;

    margin-left: 24px;
    margin-right: 16px;
    margin-top: 16px;
    margin-bottom: 16px;

    background: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.3);
    
    font-size: 17px;
    font-weight: 400;

    color: #333333;

    &:hover {
        background: #f8f8f8;
        }

    
    
`;


const CardTitle = styled.div`
    
    font-size: 18px;
    font-weight: 500;
    margin-top: 16px;
    margin-bottom: 16px;
`;

const CardDesc = styled.div`
    
    font-size: 17px;
    font-weight: 300;
    margin-bottom: 16px;
`;

const MyIcon = styled(FontAwesomeIcon)`
    && {
    color: #cbcbcb;
    padding-top: 8px;
    font-size: 32px;
    font-weight: 300;
    }
`;