import React, { Component } from 'react';
// import { YellowBox } from 'react-native';
// import _ from 'lodash';

import "./index.css"
import { Routes } from './routes';


import { createStore } from '@spyna/react-store'


// YellowBox.ignoreWarnings([
//     'componentWillReceiveProps', 
//     'componentWillMount',
// ]);

// const _console = _.clone(console);

// console.warn = message => {
//     if (message.indexOf('componentWillReceiveProps') <= -1){
//         _console.warn(message);
//         } 
// };

class App extends Component {
    render() {

        return (

            <>
                <Routes />
            </>

        )
    }
}
  

  const initialValue = {
    // amount: 15,
    // username : {
    //   name : 'spyna',
    //   url : 'https://spyna.it'
    // }
  }

  export default createStore(App, initialValue)
  
