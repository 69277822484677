
import React, { Component } from 'react';
import styled from 'styled-components';

export class HostInfoCard extends Component {
    
    
  render() {
    
    
    return (
      
        <>
            
            <CardContent>
                
                <TopArea>
                { 
                    this.props.coverPhoto ? 
                        <Avatar 
                            src={this.props.coverPhoto} 
                            alt="Host photo" 
                            onLoad={(e) => {
                                //e.target.src = this.props.avatar --- causes huge CPU spike. but looks like not necessary anyway!
                                e.target.style = 'display: flex' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                            }}
                            onError={(e) => {
                                //e.target.src = '' --- causes huge CPU spike. but looks like not necessary anyway!
                                e.target.style = 'display: none' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                            }}
                            
                        /> 
                        : 
                        <EmptyAvatar /> 
                }
                </TopArea>
                    
                    
                <CardTitle>{this.props.hostName}</CardTitle>
                
                <SubTitle>{this.props.hostTitle}</SubTitle>
                
            </CardContent>

        </>
    
    );

  }
}

const CardContent = styled.div`
    
    width: 100%;
    min-width: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
`;

const TopArea = styled.div`
    
    margin-top: 32px;
    margin-bottom: 16px;

`;


const Avatar = styled.img`

    height: 80px;
    width: 80px;
    border-radius: 40px;
    
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.4);

`;

const EmptyAvatar = styled.div`

    height: 80px;
    width: 80px;
    border-radius: 40px;
    
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.4);

`;


const CardTitle = styled.div`
    
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 4px;

`;

const SubTitle = styled.div`
    
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5em;

    
    padding: 8px;

`;


