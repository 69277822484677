
import React, { Component } from 'react';

import styled from 'styled-components';

import { withStore } from '@spyna/react-store'

import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';

import getIPInfo from './../../utils/getIPInfo'
import moment from "moment"; 

import '../../i18n'



class C extends Component {
       

    constructor(props) {
        
        super(props);
        
        this.state = {
            selectedYearly: true,
            subscriptionCurrency: 'USD',
        };
    
    }


    async componentDidMount() {

        // ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        // ReactGA.send("pageview");

        //Special discounts
        // 60% = 3.99 monthly 
        // 250@3,99 
        //  + 500@4,99 
        //  + 500@5,99 = 2500   
        // ||  1k + 2.5k + 3k = 6.5k with 1250 users => average of 5,20 per user (for life)
        //console.log(this.props.store.get("email"))

        let userCurrency = '';
        
        if (this.props.store.get('currency')) {

            userCurrency = this.props.store.get("currency");
            
        } else {

            if ((localStorage.getItem('ipCurrency') !== '') && (localStorage.getItem('ipCurrency') !== 'null')) {

                userCurrency = localStorage.getItem('ipCurrency')
    
            } else {
    
                let ipData
                const today = new Date();
    
                ipData = await getIPInfo(); 
                
                let ipAddress
                if (ipData.city !== ipData.region) {
                    ipAddress = ipData.city + ', ' + ipData.region
                } else {
                    ipAddress = ipData.city
                }
                ipAddress = ipAddress + ', ' + ipData.country
    
                //save ip date to the local storage immediatelly after geolocated!
                localStorage.setItem('ipCity', ipData.city);
                localStorage.setItem('ipRegion', ipData.region);
                localStorage.setItem('ipCountry', ipData.country);
                localStorage.setItem('ipCurrency', ipData.currency);
                localStorage.setItem('ipLng', ipData.lng);
                localStorage.setItem('ipLat', ipData.lat);
                localStorage.setItem('ipAddress', ipAddress);
                localStorage.setItem('ipLocatedDate', moment(today).format('YYYY-MM-DD'));
                userCurrency = ipData.currency
    
            }
        } 

        //if still not set after props loading or ipCurrency, then set default as usd
        if (userCurrency === '') {
            userCurrency = 'USD';
        }

        if ((userCurrency === 'EUR') || (userCurrency === 'CZK') || (userCurrency === 'DKK') || (userCurrency === 'HUF') || (userCurrency === 'RON') ||
            (userCurrency === 'LEU') || (userCurrency === 'SEK') || (userCurrency === 'UAH') || (userCurrency === 'NOK') ) {

            this.setState({ 
                subscriptionCurrency: 'EUR', 
                currencyMark: '€',
                monthlyPrice: 9.99,
                minFee: 10,
                specialDiscount: '60%', 
                specialOfferPrice: 3.99, //47.88
            });
            
        } else if (userCurrency === 'GBP') {

            this.setState({ 
                subscriptionCurrency: 'GBP', 
                currencyMark: '£',
                monthlyPrice: 8.99,
                minFee: 9,
                specialDiscount: '60%', 
                specialOfferPrice: 3.59, //43,08
            });

        } else if (userCurrency === 'CAD') {

            this.setState({ 
                subscriptionCurrency: 'CAD', 
                currencyMark: 'CA$',
                monthlyPrice: 14.55,
                minFee: 15,
                specialDiscount: '60%', 
                specialOfferPrice: 5.82, //69,84
            });


        } else if (userCurrency === 'AUD') {

            this.setState({ 
                subscriptionCurrency: 'AUD', 
                currencyMark: 'A$',
                monthlyPrice: 14.99,
                minFee: 15,
                specialDiscount: '60%', 
                specialOfferPrice: 5.99, //71,88
            });

        } else {

            this.setState({ 
                subscriptionCurrency: 'USD', 
                currencyMark: '$',
                monthlyPrice: 9.99,
                minFee: 10,
                specialDiscount: '60%', 
                specialOfferPrice: 3.99, //47.88
            });

        }
        


    }


    clickSignupPro() {
        
        if (this.state.selectedYearly) {

            // Yearly

            if (this.state.subscriptionCurrency === 'EUR') {
                window.open('https://buy.stripe.com/7sI7tBdFB4U2f96eV3?prefilled_email=' + this.props.store.get("email"), '_blank'); //EUR yearly
            } else if (this.state.subscriptionCurrency === 'GBP') {
                window.open('https://buy.stripe.com/7sI5ltfNJ9ai3qo28o?prefilled_email=' + this.props.store.get("email"), '_blank'); //GBP yearly
            } else if (this.state.subscriptionCurrency === 'CAD') {
                window.open('https://buy.stripe.com/aEUbJRatp1HQ1ig7sJ?prefilled_email=' + this.props.store.get("email"), '_blank'); //CAD yearly
            } else if (this.state.subscriptionCurrency === 'AUD') {
                window.open('https://buy.stripe.com/14k6px30X72a8KI8wP?prefilled_email=' + this.props.store.get("email"), '_blank'); //AUD yearly
            } else {
                window.open('https://buy.stripe.com/9AQ15d8lh72a1ig3ck?prefilled_email=' + this.props.store.get("email"), '_blank'); //USD yearly
            }

        } else {

            // Monthly 

            if (this.state.subscriptionCurrency === 'EUR') {
                window.open('https://buy.stripe.com/9AQg07bxt3PYbWU6ou?prefilled_email=' + this.props.store.get("email"), '_blank'); //monthly EUR
            } else if (this.state.subscriptionCurrency === 'GBP') {
                window.open('https://buy.stripe.com/eVacNVatp9aid0Y3cm?prefilled_email=' + this.props.store.get("email"), '_blank'); //monthly GBP
            } else if (this.state.subscriptionCurrency === 'CAD') {
                window.open('https://buy.stripe.com/bIY6pxeJFgCK0ec7sD?prefilled_email=' + this.props.store.get("email"), '_blank'); //monthly CAD
            } else if (this.state.subscriptionCurrency === 'AUD') {
                window.open('https://buy.stripe.com/dR6g07gRNfyGf96aEQ?prefilled_email=' + this.props.store.get("email"), '_blank'); //monthly AUD
            } else {
                window.open('https://buy.stripe.com/7sI7tBcBx2LU9OM4gn?prefilled_email=' + this.props.store.get("email"), '_blank'); //monthly USD
            }

        }
        

    }


    render() {
      
        const PurpleSwitch = withStyles({
            switchBase: {
                
                color: orange[500],
                '&$checked': { color: orange[500], },
                '&$checked + $track': { backgroundColor: orange[500], },
            },
            checked: {},
            track: { backgroundColor: orange[500], },
          })(Switch);


        const annualSaving = '30%';
        const specialLimitedTo = '250'; //250@3,99 + 500@4,99 + 500@5,99 = 1250@6500 MRR (avg = 5,20 per user). Sealt edasi vaatame mitmele ja kui suure ale teeb.


        
        return (
        
            <>
            
                <StyledTable>

                    <thead>
                        <TableRow>
                            <StyledHeaderCell>Feature</StyledHeaderCell>
                            <StyledHeaderCell2>Hobby</StyledHeaderCell2>
                            <StyledHeaderCell3>Pro</StyledHeaderCell3>
                        </TableRow>
                    </thead>

                    <tbody>
                        <TableRow>
                            <TableCell>Portfolio albums</TableCell>
                            <TableCell>3</TableCell>
                            <TableCell>30</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Portfolio photos</TableCell>
                            <TableCell>30</TableCell>
                            <TableCell>300</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Album visibility restrictions</TableCell>
                            <TableCell><Em><span role="img" aria-label='no'>❌</span></Em></TableCell>
                            <TableCell><Em><span role="img" aria-label='yes'>✅</span></Em></TableCell>
                        </TableRow>
                            <TableRow>
                                <TableCellDesc>Make an album only visible to certain users, such as Pro members, those with a minimum 4 or 5-star rating or verified identity, and users I have previously collaborated with.</TableCellDesc>
                            </TableRow>
                        <TableRow>
                            <TableCell>Not Safe For Work (NSFW) content in your albums</TableCell>
                            <TableCell><Em><span role="img" aria-label='no'>❌</span></Em></TableCell>
                            <TableCell><Em><span role="img" aria-label='yes'>✅</span></Em></TableCell>
                        </TableRow>
                            <TableRow>
                                <TableCellDesc>NSFW content is allowed only if content is tagged as such. Pornography and other obscene materials are not allowed.</TableCellDesc>
                            </TableRow>
                        <TableRow>
                            <TableCell>Can see NSFW content</TableCell>
                            <TableCell><Em><span role="img" aria-label='no'>❌</span></Em></TableCell>
                            <TableCell><Em><span role="img" aria-label='yes'>✅</span></Em></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCellDesc>Only after the the NSFW content visibility consent is given in the account settings.</TableCellDesc>
                        </TableRow>
                        <TableRow>
                            <TableCell>Priority listing in search results</TableCell>
                            <TableCell><Em><span role="img" aria-label='no'>❌</span></Em></TableCell>
                            <TableCell><Em><span role="img" aria-label='yes'>✅</span></Em></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Multi-location availability with a travel calendar</TableCell>
                            <TableCell><Em><span role="img" aria-label='no'>❌</span></Em></TableCell>
                            <TableCell><Em><span role="img" aria-label='yes'>✅</span></Em></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Number of profiles</TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>10</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Tours & workshops</TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>100</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Premium address for profiles</TableCell>
                            <TableCell><Em><span role="img" aria-label='no'>❌</span></Em></TableCell>
                            <TableCell><Em><span role="img" aria-label='yes'>✅</span></Em></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCellDesc>www.photosouls.com/your-preferred-name</TableCellDesc>
                        </TableRow>
                        <TableRow>
                            <TableCell>Pro badge in search results & profile page</TableCell>
                            <TableCell><Em><span role="img" aria-label='no'>❌</span></Em></TableCell>
                            <TableCell><Em><span role="img" aria-label='yes'>✅</span></Em></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCellOrangeText>Upcoming in the future:</TableCellOrangeText>
                        </TableRow>
                        <TableRow>
                            <TableCell>Profile performance statistics</TableCell>
                            <TableCell><Em><span role="img" aria-label='no'>❌</span></Em></TableCell>
                            <TableCell><Em><span role="img" aria-label='yes'>✅</span></Em></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Contract templates & photoshoot checklists</TableCell>
                            <TableCell><Em><span role="img" aria-label='yes'>✅</span></Em></TableCell>
                            <TableCell><Em><span role="img" aria-label='yes'>✅</span></Em></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Delivery module</TableCell>
                            <TableCell><Em><span role="img" aria-label='yes'>✅</span></Em></TableCell>
                            <TableCell><Em><span role="img" aria-label='yes'>✅</span></Em></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCellDesc>Photo delivery to clients, with beautiful gallery templates.</TableCellDesc>
                        </TableRow>
                        <TableRow>
                            <TableCell>Google Calendar integration</TableCell>
                            <TableCell><Em><span role="img" aria-label='yes'>✅</span></Em></TableCell>
                            <TableCell><Em><span role="img" aria-label='yes'>✅</span></Em></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCellDesc>Synchronize your Google Calendar for always up-to-date availability info and bookings, automatically.</TableCellDesc>
                        </TableRow>
                        <TableRow>
                            <TableCell>Send broadcasted messages</TableCell>
                            <TableCell>0</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCellDesc>Notify nearby members within a 50 km (30 miles) radius of a particular location about your availability for a photoshoot. To prevent spamming, there is a limit in place (per month). You can send additional messages for {this.state.currencyMark}25 per message.</TableCellDesc>
                        </TableRow>
                        <TableRow>
                            <TableCell>Delay to receive broadcasted messages</TableCell>
                            <TableCell>3h</TableCell>
                            <TableCell>0</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Communication module</TableCell>
                            <TableCell><Em><span role="img" aria-label='no'>❌</span></Em></TableCell>
                            <TableCell><Em><span role="img" aria-label='yes'>✅</span></Em></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCellDesc>Reach out past clients and those who have favorited your profile.</TableCellDesc>
                        </TableRow>

                        <TableRowOrange>
                            <TableCellOrange>Membership and commissions</TableCellOrange>
                        </TableRowOrange>

                        <TableRow>
                            <TableCell>Platform service fee on bookings</TableCell>
                            <TableCell>20%</TableCell>
                            <TableCell>15%</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCellDesc>
                                Service fee is deducted from each booking, with a minimum of {this.state.currencyMark}10 per booking. When you offer a free photoshoot, your client will be charged {this.state.currencyMark}10, to cover the service fee.
                            </TableCellDesc>
                        </TableRow>

                        { this.props.hideCTA ? null :
                            <TableRow>
                                <TableCell>Pro membership</TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                    <MinHeightForTableCell>
                                        {
                                            this.state.selectedYearly ?
                                            <>
                                                <strike>{this.state.currencyMark + this.state.monthlyPrice}</strike><> </>
                                                {this.state.currencyMark + this.state.specialOfferPrice}
                                            </>
                                            :
                                            <>
                                                {this.state.currencyMark + this.state.monthlyPrice}
                                            </>
                                        }
                                        {/* <Em><span role="img" aria-label='select below'>👇</span></Em> */}
                                    </MinHeightForTableCell>
                                </TableCell>
                            </TableRow>
                        }

                        { this.props.hideCTA ? null :
                            <TableRow>
                                <TableCellDesc>

                                    <PriceRow
                                        FixedSize={this.state.selectedYearly ? "3" : "2"}>
                                    
                                        <PriceSelect>
                                            <PayAnnually 
                                                isYearly={this.state.selectedYearly}
                                                onClick={ () => {
                                                    this.setState({ selectedYearly: true })
                                                    }
                                                }
                                            >
                                            Pay annually
                                            </PayAnnually>
                                            
                                            <SelectSwitch>
                                                <FormControlLabel
                                                    control={
                                                        <PurpleSwitch 
                                                            checked={!this.state.selectedYearly} 
                                                            onChange={() => {
                                                                this.setState({ selectedYearly: !this.state.selectedYearly })
                                                            }} 
                                                            name="pFr" 
                                                            />}
                                                />
                                            </SelectSwitch>
                                            
                                            <PayMonthly 
                                                isYearly={this.state.selectedYearly}
                                                onClick={ () => {
                                                    this.setState({ selectedYearly: false })
                                                    }
                                                }
                                            >
                                            Pay monthly
                                            </PayMonthly>
                                        </PriceSelect>



                                        {
                                            this.state.selectedYearly ?
                                                <PriceRowNoBorder>
                                                    <YearlyDiscount 
                                                        isYearly={this.state.selectedYearly}
                                                        >
                                                        <strike><strong>Save {annualSaving}</strong> when you pay annually!</strike> 👇
                                                    </YearlyDiscount>
                                                </PriceRowNoBorder>
                                                :
                                                <PriceRowNoBorder>
                                                    <YearlyDiscount 
                                                        isYearly={this.state.selectedYearly}
                                                        >
                                                        <strong>Save {annualSaving}</strong> when you pay annually!
                                                    </YearlyDiscount>
                                                </PriceRowNoBorder>
                                        }

                                        {
                                            this.state.selectedYearly ?
                                            <PriceRowNoBorder>
                                                <SpecialOffer>
                                                    {/* SPECIAL OFFER: Become one of the first 500 Pro members and get 50% discount locked in for life! */}
                                                    {/* SPECIAL OFFER: Pay annually and become one of the first 500 Pro members and receive a lifetime 50% discount!  */}
                                                    {/* <strong>Save 50% !</strong> Be one of the first 500 to pay annually for your Pro membership and receive a lifetime 50% discount. Don't wait - sign up now and start enjoying the benefits of being a Pro member at a discounted rate for life! */}
                                                    {/* <strong>SPECIAL OFFER: Save 50%</strong> on Pro membership when you pay annually. For a limited time, you'll also receive a lifetime 50% discount as a bonus. Don't miss out - sign up now and take advantage of these savings for life! */}
                                                    {/* <strong>LIMITED TIME OFFER: Save 50%</strong> when you pay annually! Sign up now and receive a lifetime 50% membership fee discount as a bonus. Upgrade to Pro and get discounted rates for life! */}
                                                    <strong>LIMITED TIME OFFER for the next {specialLimitedTo} members: Save {this.state.specialDiscount}</strong> when you pay annually! Sign up now and receive a lifetime {this.state.specialDiscount} membership fee discount as a bonus!
                                                </SpecialOffer>
                                            </PriceRowNoBorder>
                                            :
                                            null
                                        }
                                        

                                        <PriceRowNoBorderEnd>
                                            
                                            

                                            {
                                                this.state.selectedYearly ?
                                                <>
                                                
                                                    <PriceComponent
                                                        strike={this.state.selectedYearly}
                                                    >
                                                        
                                                        <CurrencyMark>{this.state.currencyMark}</CurrencyMark>
                                                        <BasePrice suurus="big">{ this.state.monthlyPrice}</BasePrice> 
                                                        <PriceSuffix>/mo</PriceSuffix>

                                                    </PriceComponent>

                                                    <SpecialPriceComponent
                                                        strike={!this.state.selectedYearly}
                                                        curr={this.state.subscriptionCurrency}
                                                    >
                                                        <CurrencyMark>{this.state.currencyMark}</CurrencyMark>
                                                        <BasePrice>{this.state.specialOfferPrice}</BasePrice> 
                                                        <PriceSuffix>/mo</PriceSuffix>
                                                        {/* <YearlyPrice>= 59,88 /yr</YearlyPrice> */}

                                                    </SpecialPriceComponent>

                                                </>
                                                :
                                                <PriceComponent
                                                        strike={this.state.selectedYearly}
                                                    >
                                                        
                                                    <CurrencyMark>{this.state.currencyMark}</CurrencyMark>
                                                    <BasePrice suurus="big">{this.state.monthlyPrice}</BasePrice> 
                                                    <PriceSuffix>/mo</PriceSuffix>

                                                </PriceComponent>

                                            }


                                        </PriceRowNoBorderEnd>


                                    </PriceRow>

                                </TableCellDesc>
                            </TableRow>
                        }

                        <TableRow>
                            <TableCellDesc>
                            <br/>
                                PhotoSouls is a self-funded startup, and not a big company backed by venture capitalists or investment funds.
                                When you pay for the Pro membership and platform fees, you're helping us to pay for servers, and support our team. We reinvest every penny back into the platform so it can grow, have more features, and bring you more bookings in the future. 
                                Thank you for supporting us!
                            </TableCellDesc>
                        </TableRow> 
                    
                    </tbody>
                    
                </StyledTable>
            
                
                { window.innerWidth > 790 ? <><br/><br/></> : <><br/></> }

                { this.props.hideCTA ? 
                <ButtonArea
                    desktop={window.innerWidth > 790 ? "true" : "false"}>

                    <StyledSave 
                        onClick={() => { this.props.paneKinni() }}
                        >
                        OK
                    </StyledSave>
                </ButtonArea>
                :
                <ButtonArea
                    desktop={window.innerWidth > 790 ? "true" : "false"}>

                    <StyledSave 
                        onClick={() => { this.clickSignupPro() }}
                        >
                        Upgrade to Pro
                    </StyledSave>

                    <StyledCancel
                        onClick={() => { this.props.paneKinni() }}
                        >
                        Cancel
                    </StyledCancel>

                </ButtonArea>
                }

            
            </>

        
        );

    }
}

export const UpgradeComparisonTable = withStore(C);


const StyledTable = styled.table`
    
    width: 100%;
    table-layout: fixed;
    overflow-wrap: break-word;

    text-align: left;
    position: relative;
    border-collapse: collapse; 
    
    border: 2px solid #ffa309;
    border-radius: 4px;

    font-family: 'Fira Sans';
    font-size: 19px;

`;

const StyledHeaderCell = styled.th`

    background: white;
    position: sticky;
    top: 0;

    background-color: #ffa309;
    color: #fff;

    padding: 12px;

`;

const StyledHeaderCell2 = styled.th`

    background: white;
    position: sticky;
    top: 0;

    background-color: #ffa309;
    color: #fff;

    padding: 12px;
    width: 64px;

`;

const StyledHeaderCell3 = styled.th`

    background: white;
    position: sticky;
    top: 0;

    background-color: #ffa309;
    color: #fff;

    padding: 12px;
    width: 48px;

`;

const TableRowOrange = styled.tr`

    padding: 12px;
    background-color: #ffa309;
    color: #fff;

`;

const TableRow = styled.tr`

    /* padding: 12px; */

`;

const MinHeightForTableCell = styled.div`

    height: 48px;
    min-height: 48px;
    
`;

const TableCell = styled.td`

    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px;
    padding-bottom: 6px;

    vertical-align: text-top;

    width: 90%;
`;



const TableCellOrangeText = styled.td.attrs({ colSpan: 3 })`

    padding: 12px;
    color: #ffa309;
    font-weight: 500;
    overflow:auto;

`;

const TableCellOrange = styled.td.attrs({ colSpan: 3 })`

    padding: 12px;
    background-color: #ffa309;
    color: #fff;
    overflow:auto;
`;


//const TableCellDesc = styled.td`
const TableCellDesc = styled.td.attrs({ colSpan: 3 })`
  
    padding-left: 24px;   
    padding-right: 24px;
    padding-bottom: 12px;

    font-family: 'Fira Sans';
    font-weight: 300;
    font-size: 16px;

    font-style: italic;

    color: #808080;
    /* color: #505050; */

`;


const Em = styled.span`
    padding-right: 16px;
`;


const PriceRow = styled.div`
    
    /* min-height: ${(props) => props.FixedSize === '3' ? '320px;' : '205px;' }; */

    font-size: 22px;
    font-family: 'Fira Sans';
    font-weight: 400;
    
    padding-right: 0px;

    border-bottom: 1px solid #EBEBEB;

    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 980px) {
        
        min-height: 100px;

    }


`;



const PriceSelect = styled.div`

    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    margin-top: 8px;

    @media (max-width: 980px) {
        
        font-size: 18px;

    }

`;

const PayAnnually = styled.div`
    
    margin-right: 18px;

    color: ${(props) => props.isYearly ? '#ff9500' : '#a8a8a8' };
    /* text-decoration: ${(props) => props.isYearly ? 'none;' : 'underline;' }; */
    text-underline-offset: 2px;

`;

const SelectSwitch = styled.div`
    
    margin-top: -4px;

`;

const PayMonthly = styled.div`
    
    margin-left: -8px;

    color: ${(props) => props.isYearly ? '#a8a8a8' : '#ff9500' };

    /* text-decoration: ${(props) => props.isYearly ? 'underline;' : 'none;' }; */
    text-underline-offset: 2px;

`;

const YearlyDiscount = styled.div`
    
    color: #333333;
    /* color: ${(props) => props.isYearly ? '#333333' : '#a8a8a8' }; */
    /* text-decoration: ${(props) => props.isYearly ? 'none;' : 'line-through;' }; */

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 18px;


`;


const PriceRowNoBorder = styled.div`
    
    min-height: 32px;

    font-size: 22px;
    font-family: 'Fira Sans';
    font-weight: 400;
    
    padding-right: 0px;

    padding-top: 12px;

    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;
    justify-content: flex-start;

    text-align: left;
    align-items: center;

`;

const PriceRowNoBorderEnd = styled.div`
    
    width: 100%;
    font-size: 22px;
    font-family: 'Fira Sans';
    font-weight: 400;
    
    margin-bottom: 0px;
    margin-top: 0px;
    
    padding-top: 0px;
    padding-right: 0px;

    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;
    justify-content: flex-end;

    text-align: left;
    align-items: center;

`;

const SpecialOffer = styled.div`
    
    font-size: 18px;
    font-family: 'Fira Sans';
    font-weight: 400;
    
    margin-top: 0px;

    padding-left: 16px;

    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 8px;

    background-color: #ffa309;
    color: #fff;
    border: 2px solid #fff;
    outline: 2px solid #ffa309;
    outline-offset: -4pxpx;

    /* border: 2px solid #ff9500; */
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);
    border-radius: 8px;

`;


const PriceComponent = styled.div`

    
    color: ${(props) => props.strike ? '#a8a8a8' : '#333333' };
    text-decoration: ${(props) => props.strike ? 'line-through;' : 'none;' };

    display: flex;

    flex-wrap: wrap;    
    flex-direction: row;
    justify-content: center;
    align-items: baseline;

    margin-right: 16px;
    margin-bottom: 24px;
    margin-top: 32px;

    padding-top: 8px;
    
`;

const SpecialPriceComponent = styled.div`

    width: ${(props) => (props.curr === 'EUR' || props.curr === 'USD' || props.curr === 'GBP') ? '125px' : '145px' };
    color: ${(props) => props.strike ? '#a8a8a8' : '#333333' };
    text-decoration: ${(props) => props.strike ? 'line-through;' : 'none;' };

    border: 2px solid #ff9500;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);
    border-radius: 8px;
    
    /* padding-left: 12px; */
    padding-right: 12px;
    padding-top: 8px; 
    padding-bottom: 8px; 

    padding-left: 8px;

    display: flex;

    flex-wrap: wrap;    
    flex-direction: row;
    justify-content: center;
    align-items: baseline;

    margin-top: 20px;
    /* background-color: #fed; */

     @media (max-width: 1340px) {

       justify-content: flex-end;
        
        
    }

`;


const BasePrice = styled.div`

    font-weight: 500;

    font-size: ${(props) => props.suurus === 'big' ? '38px;' : '32px;' };
    margin: 0px;
    
`;

const PriceSuffix = styled.div`

    
    font-weight: 300;
    font-size: 16px;
    margin-left: 4px;
    
`;

const CurrencyMark = styled.div`
    
    font-weight: 400;
    font-size: 20px;
    margin-right: 4px;
    margin-left: 4px;
    
    
    
`;


const ButtonArea = styled.div`

    position: relative;
    
    bottom: ${(props) => props.desktop === 'true' ? "5%" : '0'};
    
    background-color: #fff;

    padding-top: 8px;
    padding-right: 40px;
    margin-bottom: 4px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    color: #333333;
    
`;



const StyledSave = styled(Button)`
    && {
    min-height: 40px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    color: white;
    
    padding-left: 16px;
    padding-right: 16px;
    
    margin-right: 24px;
    margin-bottom: 24px;

    text-transform: none;
    font-weight: 400;
    font-size: 17px;
        &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }
    }
    
`;

const StyledCancel = styled(Button)`
    && {
    height: 40px;
    
    padding-left: 16px;
    padding-right: 16px;

    color: #333333;
    
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    }
    
`;