import React, { PureComponent } from 'react'
import { WithViewEvent, WithEditEvent } from "@psd/controller";
import { EventForm, defaultEventFormValues } from "./Event/EventForm";

import { withStore } from '@spyna/react-store'
import { withApollo } from "react-apollo";
import { withRouter } from 'react-router-dom';

import "./profiles.css";

class C extends PureComponent {

    render() {

        const { 
            match: { params: { profileId } } 
        } = this.props;

        return (

            <WithViewEvent 
                
                profileId = {profileId}
                
            >

                {
                    
                    data => {
                        
                        if (data.event.length === 0) {
                            return (
                                <div className="bodywrapper">
                                    <div className="settingsWrapper">
                                        <div className='settingsHeader'>Loading ...</div>
                                    </div>
                                </div>
                                )
                        }

                    
                        const { profileId: _, owner: ___, ...event } = data.event;
                        

                        return (

                            <WithEditEvent>
                                {
                                    ({ editEvent }) => (

                                        <EventForm
                                        
                                            initialValues={{
                                                ...defaultEventFormValues,
                                                ...event
                                            }}

                                            submit = { async (values) => {
                                                
                                                //console.log('SUBMITTING - values.intro ', values.intro)
                                                //console.log('SUBMITTING - values ', values)

                                                // The problem with some fields is that they actually dont belong to the model (temp fields in form), 
                                                // or we do not want to alter them (set by other api actions)
                                                // so we can / MUST remove those from data, otherwise we get an error:
                                                delete values.__typename; //without this, Graphql will ERROR
                                                delete values.location.__typename; //without this, Graphql will ERROR
                                                
                                                for (var i = values.title.length-1; i >= 0; i--) {
                                                    delete values.title[i].__typename
                                                }

                                                for (var f = values.dates.length-1; f >= 0; f--) {
                                                    delete values.dates[f].__typename
                                                }

                                                for (var l = values.sections.length-1; l >= 0; l--) {
                                                    delete values.sections[l].__typename
                                                    for (var x = values.sections[l].title.length-1; x >= 0; x--) {
                                                        delete values.sections[l].title[x].__typename
                                                    }      
                                                    for (var y = values.sections[l].desc.length-1; y >= 0; y--) {
                                                        delete values.sections[l].desc[y].__typename
                                                    } 
                                                }

                                                for (var z = values.albums.length-1; z >= 0; z--) {
                                                    delete values.albums[z].__typename
                                                    for (var b = values.albums[z].name.length-1; b >= 0; b--) {
                                                        delete values.albums[z].name[b].__typename
                                                    }                                                
                                                }

                                                if (values.albums.length > 0) {
                                                    values.coverPhoto = values.albums[0].coverPhotoURL; //this will be set at the point of uploading, by backend. No form alteration is allowed !!! Otherwise path gets messed up
                                                }
                                                

                                                for (var r = values.hosts.length-1; r >= 0; r--) {
                                                    delete values.hosts[r].__typename

                                                    for (var t = values.hosts[r].title.length-1; t >= 0; t--) {
                                                        delete values.hosts[r].title[t].__typename
                                                    }    
                                                    for (var u = values.hosts[r].intro.length-1; u >= 0; u--) {
                                                        delete values.hosts[r].intro[u].__typename
                                                    }                                                
                                                }


                                                delete values.userId; //not needed, as this will not change (and if it is included in the data, GQL will give error)
                                                delete values.createdAt;
                                                delete values.reports; 
                                                delete values.id;
                                                
                                                
                                                
                                                delete values.publicURL // this cannot be altered!
                                                

                                                //add or update hidden fields
                                                values.lastUpdated = new Date().toISOString()

                                                // console.log(values)
                                                
                                                await editEvent({
                                                    variables: {
                                                        profileId,
                                                        input: values
                                                    },
                                                    refetchQueries: ["viewEvent", "me"],
                                                    awaitRefetchQueries: true
                                                });

                                                
                                                this.props.history.push("/user/profiles");

                                            }
                                            }
                                        />
                                    )
                                
                                }

                            </WithEditEvent>
                        );
                    }
                }

            </WithViewEvent>
        );

    }
}


export const EditEvent = withRouter(withStore(withApollo(C)));
