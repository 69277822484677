import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
//import { hydrate, render } from "react-dom";

import { ApolloProvider } from 'react-apollo'
import ReactGA from 'react-ga4';

import { datadogLogs } from '@datadog/browser-logs'


import * as serviceWorker from './serviceWorker';

import "./index.css"
//import './i18n';
import { client } from './apollo';
// import { initEventLogging, logEvent } from './utils/analytics/amplitude'; //old
// import { initEventLogging } from '@psd/web/src/utils/analytics/amplitude'; //newer



import App from './App'


ReactGA.initialize('G-FWJT5Y88GP');

datadogLogs.init({
    clientToken: 'pub135e3b2ba1f08bf141d459bcaa497716',
    site: 'datadoghq.eu',
    service: (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'WEB-dev' : 'WEB',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
})



//initEventLogging();
//logEvent('app started')

const rootElement = document.getElementById("root");


// if (rootElement.hasChildNodes()) {

//     hydrate(

//         <ApolloProvider client={client}>
//             <Suspense fallback={(<div className="loader"></div>)}>
//                 <App/>
//             </Suspense>
//         </ApolloProvider>
//         , rootElement

//     );

// } else {

//     render(

//         <ApolloProvider client={client}>
//             <Suspense fallback={(<div className="loader"></div>)}>
//                 <App/>
//             </Suspense>
//         </ApolloProvider>
//         , rootElement

//     );

// }


ReactDOM.render(
    <ApolloProvider client={client}>
        <Suspense fallback={(<div className="loader"></div>)}>
            <App/>
        </Suspense>
    </ApolloProvider>
    , rootElement
    );

serviceWorker.unregister();

