import React, { PureComponent } from 'react'

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

import { withStore } from '@spyna/react-store'
import { withApollo } from "react-apollo";
import { withRouter } from 'react-router-dom';
import { PhotogCard } from "../photogs/PhotogCard";
import { ModellCard } from "../models/ModellCard";
import { MuahCard } from "../muahs/MuahCard";
import { StudioCard } from "../studios/StudioCard";
import { EventCard } from "../events/EventCard";
import Footer from '../../modules/components/Footer/Footer'

import styled from 'styled-components';
import gql from "graphql-tag"
import { Query } from "react-apollo";


const myFavorites = gql`
    query {
        myFavorites { 
            id, type, photogId, modellId, muahId, studioId, eventId, faved
        }
    }
`;

const getPhotogs4Favorites = gql`
    query ($ids: [String]) {
        getPhotogs(ids: $ids) {
            id, name, avatar, isPro, isVerified, isFoundingMember, isTopRated, isTop2023, isTop2024, isTop2025, isTop2026, isTop2027, isTop2028, isTop2029, isTop2030, isTop2031, isTop2032, isTop2033, avgRating, reviewsTotal, avgPrice, currency, tfp, instaBook, address, publicURL, premiumURL,
            albums { name {lng, name}, category, coverPhotoURL, cpw, cph, },  
        }
    }
`;

const getModells4Favorites = gql`
    query ($ids: [String]) {
        getModells(ids: $ids) {
            id, name, avatar, isPro, isVerified, isFoundingMember, isTopRated, isTop2023, isTop2024, isTop2025, isTop2026, isTop2027, isTop2028, isTop2029, isTop2030, isTop2031, isTop2032, isTop2033, avgRating, reviewsTotal, avgPrice, currency, tfp, instaBook, address, publicURL, premiumURL,
            albums { name {lng, name}, category, coverPhotoURL, cpw, cph, },  
        }
    }
`;


const getMuahs4Favorites = gql`
    query ($ids: [String]) {
        getMuahs(ids: $ids) {
            id, name, avatar, isPro, isVerified, isFoundingMember, isTopRated, isTop2023, isTop2024, isTop2025, isTop2026, isTop2027, isTop2028, isTop2029, isTop2030, isTop2031, isTop2032, isTop2033, avgRating, reviewsTotal, avgPrice, currency, tfp, instaBook, address, publicURL, premiumURL,
            albums { name {lng, name}, category, coverPhotoURL, cpw, cph, },  
        }
    }
`;

const getStudios4Favorites = gql`
    query ($ids: [String]) {
        getStudios(ids: $ids) {
            id, name, avatar, isPro, isVerified, isFoundingMember, isTopRated, isTop2023, isTop2024, isTop2025, isTop2026, isTop2027, isTop2028, isTop2029, isTop2030, isTop2031, isTop2032, isTop2033, avgRating, reviewsTotal, avgPrice, currency, tfp, instaBook, address, publicURL, premiumURL,
            albums { name {lng, name}, category, coverPhotoURL, cpw, cph, },  
        }
    }
`;

const getEvents4Favorites = gql`
    query ($ids: [String]) {
        getEvents(ids: $ids) {
            id, status, title { lng, title }, categories,
            dates { id, start, end, st, et, lenDays, lenHours, price, currency, limit, reserved, booked, },
            virtual, coverPhoto, cpw, cph,
            address, addr_locality, addr_area2, addr_area1, country,
            location { type, coordinates, }
            languages, 

            premiumURL, publicURL,
            isPro, isVerified, isFoundingMember, isTopRated, isTop2023, isTop2024, isTop2025, isTop2026, isTop2027, isTop2028, isTop2029, isTop2030, isTop2031, isTop2032, isTop2033, avgRating, reviewsTotal, 
            albums { name {lng, name}, category, coverPhotoURL, cpw, cph, },  
        }
    }
`;

class C extends PureComponent {

    state = {
            
        favPhotog: [],
        favModell: [],
        favMuah: [],
        favStudio: [],
        favEvent: [],
        
    }

    componentDidMount() {

        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

    }


    loadFavData = async (data) => {

        for (var i = data.myFavorites.length-1; i >= 0; i--) {
            
            if (data.myFavorites[i].type === 1) { 
                let fav = this.state.favPhotog;
                fav.push(data.myFavorites[i].photogId)
                setTimeout(() => { this.setState({ favPhotog: fav, }); }, 50);
            }
            else if (data.myFavorites[i].type === 2) { 
                let fav = this.state.favModell;
                fav.push(data.myFavorites[i].modellId,)
                setTimeout(() => { this.setState({ favModell: fav, }); }, 50);
            }
            else if (data.myFavorites[i].type === 3) { 
                let fav = this.state.favMuah;
                fav.push(data.myFavorites[i].muahId)
                setTimeout(() => { this.setState({ favMuah: fav, }); }, 50);
            }
            else if (data.myFavorites[i].type === 4) { 
                let fav = this.state.favStudio;
                fav.push(data.myFavorites[i].studioId)
                setTimeout(() => { this.setState({ favStudio: fav, }); }, 50);
            }
            else if (data.myFavorites[i].type === 5) { 
                let fav = this.state.favEvent;
                fav.push(data.myFavorites[i].eventId)
                setTimeout(() => { this.setState({ favEvent: fav, }); }, 50);
            }

        }
    }



    render() {

        return (
            
            <>
                <Helmet>
                    <title>Favorites in PhotoSouls</title>
                    <meta name="description" content="Favorites" />
                    <meta name="keywords" content="photosouls,favorites" />
                </Helmet>

                <div className="bodywrapper">
                    

                    <Query 
                        asyncMode 
                        query={myFavorites} 
                        fetchPolicy="network-only"
                    >
                        {({ data, loading }) => {

                            // console.log('data', data)
                            // test here, why length is not working.because if backend is stopped (or server unreachable), front will give TypeError: Cannot read property 'length' of undefined
                           
                            if ((data === undefined) || (data === null) || (loading === true)) {

                                return (
                                    <MainTitle>Loading favorites. Please wait...</MainTitle> 
                                )    

                            } else
                            
                            if ((JSON.stringify(data).length > 35) && (loading === false)) {

                                this.loadFavData(data); //fills this.state.favPhotog and other state arrays
                                
                                //console.log('this.state.favPhotog', this.state.favPhotog) 
                                //console.log('this.state.favModell', this.state.favModell)  

                                return (
                                    <>
                                        { 
                                            
                                            
                                            this.state.favPhotog.length > 0 ?
                                                <>
                                                    <MainTitle>Favorite photographers</MainTitle>
                                                    <ProfileResults>

                                                        <Query
                                                            query={getPhotogs4Favorites}
                                                            variables={{'ids': this.state.favPhotog }}
                                                            fetchPolicy="network-only"
                                                        >
                                                        {
                                                            
                                                            ({ data }) => {
                                                            
                                                                //console.log('data 1: ', data)

                                                                if (data === undefined) {

                                                                    //do nothing ??    

                                                                } else
                                                                if (JSON.stringify(data).length > 35) {
                                                                    
                                                                    // console.log('data 2: ', data)  

                                                                    return (<>
                                
                                                                        { 
                                                                            
                                                                            data.getPhotogs.map((photog, index) => {

                                                                                //console.log('photog (data for photog card details)', photog)

                                                                                let profileURL = ""
                                                                                if (photog.premiumURL) {
                                                                                    profileURL = '/' + photog.premiumURL
                                                                                } else if (photog.publicURL) {
                                                                                    profileURL = '/' + photog.publicURL
                                                                                } else {
                                                                                    profileURL = '/photographer/' + photog.id
                                                                                }


                                                                                return (
                                                                                
                                                                                    <PhotogCard
                                                                                        key={index}

                                                                                        address={photog.address} 
                                                                                        albums={photog.albums} 
                                                                                        avatar={photog.avatar} 
                                                                                        avgPrice={photog.avgPrice} 
                                                                                        tfp={photog.tfp} 
                                                                                        currency={photog.currency}
                                                                                        instaBook={photog.instaBook}
                                                                                        avgRating={photog.avgRating}
                                                                                        reviewsTotal={photog.reviewsTotal}
                                                                                        category={-1} //no category for favorites. only use if seach filter has set specific category
                                                                                        id={photog._id} 
                                                                                        isPro={photog.isPro} 
                                                                                        isVerified={photog.isVerified} 
                                                                                        isFoundingMember={photog.isFoundingMember} 
                                                                                        isTopRated={photog.isTopRated} 
                                                                                        isTop2023={photog.isTop2023} 
                                                                                        isTop2024={photog.isTop2024} 
                                                                                        isTop2025={photog.isTop2025} 
                                                                                        isTop2026={photog.isTop2026} 
                                                                                        isTop2027={photog.isTop2027} 
                                                                                        isTop2028={photog.isTop2028} 
                                                                                        isTop2029={photog.isTop2029} 
                                                                                        isTop2030={photog.isTop2030} 
                                                                                        isTop2031={photog.isTop2031} 
                                                                                        isTop2032={photog.isTop2032} 
                                                                                        isTop2033={photog.isTop2033} 
                                                                                        name={photog.name} 
                                                                                        onClick={() => { this.props.history.push(profileURL) }}
                                                                                    /> 


                                                                                );
                                                                                
                                                                                
                                                                            }) //data.getPhotogs.map
                                                                        
                                                                        }

                                                                    </>);

                                                                }
                                                                
                                                                return null; 
                                                            
                                                            }

                                                        }
                                    
                                                        </Query>
                                                        
                                                    </ProfileResults>
                                                    <IncreaseHeight32/>
                                                </>
                                            :
                                            null
                                        }

                                        {
                                            this.state.favModell.length > 0 ?
                                                <>
                                                    <MainTitle>Favorite models</MainTitle>
                                                    <ProfileResults>

                                                        <Query
                                                            query={getModells4Favorites}
                                                            variables={{'ids': this.state.favModell }}
                                                            fetchPolicy="network-only"
                                                        >
                                                        {
                                                            
                                                            ({ data }) => {
                                                            
                                                                //console.log('data 1: ', data)

                                                                if (data === undefined) {

                                                                    //do nothing ??    

                                                                } else
                                                                if (JSON.stringify(data).length > 35) {
                                                                    
                                                                    // console.log('data 2: ', data)  

                                                                    return (<>
                                
                                                                        { 
                                                                            
                                                                            data.getModells.map((modell, index) => {

                                                                                //console.log('modell (data for modell card details)', modell)

                                                                                let profileURL = ""
                                                                                if (modell.premiumURL) {
                                                                                    profileURL = '/' + modell.premiumURL
                                                                                } else if (modell.publicURL) {
                                                                                    profileURL = '/' + modell.publicURL
                                                                                } else {
                                                                                    profileURL = '/model/' + modell.id
                                                                                }


                                                                                return (
                                                                                
                                                                                    <ModellCard
                                                                                        key={index}

                                                                                        address={modell.address} 
                                                                                        albums={modell.albums} 
                                                                                        avatar={modell.avatar} 
                                                                                        avgPrice={modell.avgPrice} 
                                                                                        tfp={modell.tfp} 
                                                                                        currency={modell.currency}
                                                                                        instaBook={modell.instaBook}
                                                                                        avgRating={modell.avgRating}
                                                                                        reviewsTotal={modell.reviewsTotal}
                                                                                        category={-1} //no category for favorites. only use if seach filter has set specific category
                                                                                        id={modell._id} 
                                                                                        isPro={modell.isPro} 
                                                                                        isVerified={modell.isVerified} 
                                                                                        isFoundingMember={modell.isFoundingMember} 
                                                                                        isTopRated={modell.isTopRated} 
                                                                                        isTop2023={modell.isTop2023} 
                                                                                        isTop2024={modell.isTop2024} 
                                                                                        isTop2025={modell.isTop2025} 
                                                                                        isTop2026={modell.isTop2026} 
                                                                                        isTop2027={modell.isTop2027} 
                                                                                        isTop2028={modell.isTop2028} 
                                                                                        isTop2029={modell.isTop2029} 
                                                                                        isTop2030={modell.isTop2030} 
                                                                                        isTop2031={modell.isTop2031} 
                                                                                        isTop2032={modell.isTop2032} 
                                                                                        isTop2033={modell.isTop2033} 
                                                                                        name={modell.name} 
                                                                                        onClick={() => { this.props.history.push(profileURL) }}
                                                                                    /> 


                                                                                );
                                                                                
                                                                                
                                                                            }) //data.getModells.map
                                                                        
                                                                        }

                                                                    </>);

                                                                }
                                                                
                                                                return null; 
                                                            
                                                            }

                                                        }
                                    
                                                        </Query>
                                                        
                                                    </ProfileResults>
                                                    <IncreaseHeight32/>
                                                </>
                                            :
                                            null

                                        }

                                    
                                        {
                                            this.state.favMuah.length > 0 ?
                                                <>
                                                    <MainTitle>Favorite makeup & hair professionals</MainTitle>
                                                    <ProfileResults>

                                                        <Query
                                                            query={getMuahs4Favorites}
                                                            variables={{'ids': this.state.favMuah }}
                                                            fetchPolicy="network-only"
                                                        >
                                                        {
                                                            
                                                            ({ data }) => {
                                                            
                                                                //console.log('data 1: ', data)

                                                                if (data === undefined) {

                                                                    //do nothing ??    

                                                                } else
                                                                if (JSON.stringify(data).length > 35) {
                                                                    
                                                                    // console.log('data 2: ', data)  

                                                                    return (<>
                                
                                                                        { 
                                                                            
                                                                            data.getMuahs.map((muah, index) => {

                                                                                //console.log('muah (data for muah card details)', muah)

                                                                                let profileURL = ""
                                                                                if (muah.premiumURL) {
                                                                                    profileURL = '/' + muah.premiumURL
                                                                                } else if (muah.publicURL) {
                                                                                    profileURL = '/' + muah.publicURL
                                                                                } else {
                                                                                    profileURL = '/model/' + muah.id
                                                                                }


                                                                                return (
                                                                                
                                                                                    <MuahCard
                                                                                        key={index}

                                                                                        address={muah.address} 
                                                                                        albums={muah.albums} 
                                                                                        avatar={muah.avatar} 
                                                                                        avgPrice={muah.avgPrice} 
                                                                                        tfp={muah.tfp} 
                                                                                        currency={muah.currency}
                                                                                        instaBook={muah.instaBook}
                                                                                        avgRating={muah.avgRating}
                                                                                        reviewsTotal={muah.reviewsTotal}
                                                                                        category={-1} //no category for favorites. only use if seach filter has set specific category
                                                                                        id={muah._id} 
                                                                                        isPro={muah.isPro} 
                                                                                        isVerified={muah.isVerified} 
                                                                                        isFoundingMember={muah.isFoundingMember} 
                                                                                        isTopRated={muah.isTopRated} 
                                                                                        isTop2023={muah.isTop2023} 
                                                                                        isTop2024={muah.isTop2024} 
                                                                                        isTop2025={muah.isTop2025} 
                                                                                        isTop2026={muah.isTop2026} 
                                                                                        isTop2027={muah.isTop2027} 
                                                                                        isTop2028={muah.isTop2028} 
                                                                                        isTop2029={muah.isTop2029} 
                                                                                        isTop2030={muah.isTop2030} 
                                                                                        isTop2031={muah.isTop2031} 
                                                                                        isTop2032={muah.isTop2032} 
                                                                                        isTop2033={muah.isTop2033} 
                                                                                        name={muah.name} 
                                                                                        onClick={() => { this.props.history.push(profileURL) }}
                                                                                    /> 


                                                                                );
                                                                                
                                                                                
                                                                            }) //data.getMuahs.map
                                                                        
                                                                        }

                                                                    </>);

                                                                }
                                                                
                                                                return null; 
                                                            
                                                            }

                                                        }
                                    
                                                        </Query>
                                                        
                                                    </ProfileResults>
                                                    <IncreaseHeight32/>
                                                </>
                                            :
                                            null

                                        }

                                        {
                                            this.state.favStudio.length > 0 ?
                                                <>
                                                    <MainTitle>Favorite studios</MainTitle>
                                                    <ProfileResults>

                                                        <Query
                                                            query={getStudios4Favorites}
                                                            variables={{'ids': this.state.favStudio }}
                                                            fetchPolicy="network-only"
                                                        >
                                                        {
                                                            
                                                            ({ data }) => {
                                                            
                                                                //console.log('data 1: ', data)

                                                                if (data === undefined) {

                                                                    //do nothing ??    

                                                                } else
                                                                if (JSON.stringify(data).length > 35) {
                                                                    
                                                                    // console.log('data 2: ', data)  

                                                                    return (<>
                                
                                                                        { 
                                                                            
                                                                            data.getStudios.map((studio, index) => {

                                                                                //console.log('studio (data for studio card details)', studio)

                                                                                let profileURL = ""
                                                                                if (studio.premiumURL) {
                                                                                    profileURL = '/' + studio.premiumURL
                                                                                } else if (studio.publicURL) {
                                                                                    profileURL = '/' + studio.publicURL
                                                                                } else {
                                                                                    profileURL = '/model/' + studio.id
                                                                                }


                                                                                return (
                                                                                
                                                                                    <StudioCard
                                                                                        key={index}

                                                                                        address={studio.address} 
                                                                                        albums={studio.albums} 
                                                                                        avatar={studio.avatar} 
                                                                                        avgPrice={studio.avgPrice} 
                                                                                        tfp={studio.tfp} 
                                                                                        currency={studio.currency}
                                                                                        instaBook={studio.instaBook}
                                                                                        avgRating={studio.avgRating}
                                                                                        reviewsTotal={studio.reviewsTotal}
                                                                                        category={-1} //no category for favorites. only use if seach filter has set specific category
                                                                                        id={studio._id} 
                                                                                        isPro={studio.isPro} 
                                                                                        isVerified={studio.isVerified} 
                                                                                        isFoundingMember={studio.isFoundingMember} 
                                                                                        isTopRated={studio.isTopRated} 
                                                                                        isTop2023={studio.isTop2023} 
                                                                                        isTop2024={studio.isTop2024} 
                                                                                        isTop2025={studio.isTop2025} 
                                                                                        isTop2026={studio.isTop2026} 
                                                                                        isTop2027={studio.isTop2027} 
                                                                                        isTop2028={studio.isTop2028} 
                                                                                        isTop2029={studio.isTop2029} 
                                                                                        isTop2030={studio.isTop2030} 
                                                                                        isTop2031={studio.isTop2031} 
                                                                                        isTop2032={studio.isTop2032} 
                                                                                        isTop2033={studio.isTop2033} 
                                                                                        name={studio.name} 
                                                                                        onClick={() => { this.props.history.push(profileURL) }}
                                                                                    /> 


                                                                                );
                                                                                
                                                                                
                                                                            }) //data.getStudios.map
                                                                        
                                                                        }

                                                                    </>);

                                                                }
                                                                
                                                                return null; 
                                                            
                                                            }

                                                        }
                                    
                                                        </Query>
                                                        
                                                    </ProfileResults>
                                                    <IncreaseHeight32/>
                                                </>
                                            :
                                            null

                                        }

                                        {
                                            this.state.favEvent.length > 0 ?
                                                <>
                                                    <MainTitle>Favorite events</MainTitle>
                                                    <ProfileResults>

                                                        <Query
                                                            query={getEvents4Favorites}
                                                            variables={{'ids': this.state.favEvent }}
                                                            fetchPolicy="network-only"
                                                        >
                                                        {
                                                            
                                                            ({ data }) => {
                                                            
                                                                //console.log('data 1: ', data)

                                                                if (data === undefined) {

                                                                    //do nothing ??    

                                                                } else
                                                                if (JSON.stringify(data).length > 35) {
                                                                    
                                                                    // console.log('data 2: ', data)  

                                                                    return (<>
                                
                                                                        { 
                                                                            
                                                                            data.getEvents.map((event, index) => {

                                                                                //console.log('studio (data for studio card details)', studio)

                                                                                let profileURL = ""
                                                                                if (event.premiumURL) {
                                                                                    profileURL = '/' + event.premiumURL
                                                                                } else if (event.publicURL) {
                                                                                    profileURL = '/' + event.publicURL
                                                                                } else {
                                                                                    profileURL = '/event/' + event.id
                                                                                }


                                                                                return (
                                                                                
                                                                                    <EventCard
                                                                                        key={index}

                                                                                        albums={event.albums} 

                                                                                        title={event.title} 

                                                                                        address={event.address} 
                                                                                        dates={event.dates} 

                                                                                        
                                                                                        //avatar={event.avatar} 
                                                                                        // avgPrice={event.avgPrice} 
                                                                                        // currency={event.currency}
                                                                                        // instaBook={event.instaBook}
                                                                                        avgRating={event.avgRating}
                                                                                        reviewsTotal={event.reviewsTotal}
                                                                                        
                                                                                        category={-1} //no category for favorites. only use if seach filter has set specific category
                                                                                        id={event._id} 
                                                                                        isPro={event.isPro} 
                                                                                        isVerified={event.isVerified} 
                                                                                        isFoundingMember={event.isFoundingMember} 
                                                                                        isTopRated={event.isTopRated} 
                                                                                        isTop2023={event.isTop2023} 
                                                                                        isTop2024={event.isTop2024} 
                                                                                        isTop2025={event.isTop2025} 
                                                                                        isTop2026={event.isTop2026} 
                                                                                        isTop2027={event.isTop2027} 
                                                                                        isTop2028={event.isTop2028} 
                                                                                        isTop2029={event.isTop2029} 
                                                                                        isTop2030={event.isTop2030} 
                                                                                        isTop2031={event.isTop2031} 
                                                                                        isTop2032={event.isTop2032} 
                                                                                        isTop2033={event.isTop2033} 
                                                                                        name={event.name} 
                                                                                        onClick={() => { this.props.history.push(profileURL) }}
                                                                                    /> 


                                                                                );
                                                                                
                                                                                
                                                                            }) //data.getStudios.map
                                                                        
                                                                        }

                                                                    </>);

                                                                }
                                                                
                                                                return null; 
                                                            
                                                            }

                                                        }
                                    
                                                        </Query>
                                                        
                                                    </ProfileResults>
                                                    <IncreaseHeight32/>
                                                </>
                                            :
                                            null

                                        }

                                    </>
                                )

                            } else {

                                return (

                                    <MainTitle>No favorites found</MainTitle> 
                                    
                                )

                            } 

                        }}
                    </Query>    


                <Footer showbutton="true"/>

                </div>

            </>

        )
    }
}



const personalInfoFields = [
    'fName', 'lName', 'bDay', 'email', 'phone', 'phoneDisplay', 'phoneCountry', 'avatar', 'isVerified', 'isPro', 'temperror', 'pwdUpdated',
    'chkReminders', 'chkRemindersP', 'chkMessages', 'chkMessagesP', 'chkRecomm', 'chkRecommP', 'chkInsp', 'chkInspP', 'chkLocal', 'chkLocalP', 
    'chkHow', 'chkHowP', 'chkInvite', 'chkInviteP', 'chkSurvey', 'chkSurveyP', 'chkPolicy', 'chkPolicyP', 'chkTips', 'chkTipsP'
]


export const Favorites = withRouter(withStore(withApollo(C), personalInfoFields));




const ProfileResults = styled.div`

    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    align-items: flex-start;
    
    justify-content: center;
   
    flex-direction: row;
    justify-content: flex-start;

    padding-top: 8px;
    z-index: 200;

`;

const MainTitle = styled.div`
    
    font-size: 22px;
    font-weight: 500;
    padding: 0px 24px 24px 0px;


    font-family: 'Fira Sans';
    color: #333333;

`;



const IncreaseHeight32 = styled.div`
    
    height: 32px;  

`;
