
import React, { Component } from 'react';
import styled from 'styled-components';
import '../../i18n'

import Button from '@material-ui/core/Button';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "./../../styles/quill.css";


import gql from "graphql-tag";

import { withApollo } from "react-apollo";






const termsQuery = gql`{ getKey(key:"terms") {value, key} }`;
const privacyPolicyQuery = gql`{ getKey(key:"privacy") {value, key} }`;
const cookiePolicyQuery = gql`{ getKey(key:"cookiePolicy") {value, key} }`;

const paymentTermsQuery = gql`{ getKey(key:"paymentTerms") {value, key} }`;
const refundPolicyQuery = gql`{ getKey(key:"refundPolicy") {value, key} }`;

const contentPolicyQuery = gql`{ getKey(key:"contentPolicy") {value, key} }`;
const reviewsPolicyQuery = gql`{ getKey(key:"reviewsPolicy") {value, key} }`;
const copyrightPolicyQuery = gql`{ getKey(key:"copyrightPolicy") {value, key} }`;

const keyValueUpdate = gql` mutation($key: String!, $value: String!) { updateKey(key: $key, value: $value) } `;



class C extends Component {
    

    constructor(props) {
        
        super(props)
        
        this.state = {
            showCategorySelection: false,
            categorySelected: false,
    
            termsVisible: false,
            termsTextOriginal: '',
            termsText: '',
        };

        this.termsChange = this.termsChange.bind(this);

    }

  
    termsChange (html) {

        this.setState({ termsText: html })
        
    }
    
    componentDidMount() {
        
        this.loadTerms(this.props.type);

    }

    

    loadTerms = async (type) => {
        
        let keyQuery = termsQuery;
        if (type === 'terms-of-service') { 
            keyQuery = termsQuery;
        } else if (type === 'privacy-policy') {
            keyQuery = privacyPolicyQuery;
        } else if (type === 'cookie-policy') {
            keyQuery = cookiePolicyQuery;
        } else if (type === 'payment-terms') {
            keyQuery = paymentTermsQuery;
        } else if (type === 'refund-policy') {
            keyQuery = refundPolicyQuery;
        } else if (type === 'content-policy') {
            keyQuery = contentPolicyQuery;
        } else if (type === 'reviews-policy') {
            keyQuery = reviewsPolicyQuery;
        } else if (type === 'copyright-policy') {
            keyQuery = copyrightPolicyQuery;
        } else {
            keyQuery = termsQuery;
        }

        await this.props.client.query({
            query: keyQuery,
            fetchPolicy: 'no-cache',
            })
            .then(results => {

                if (JSON.stringify(results).length > 15) {
                    this.setState({ 
                        termsTextOriginal: results.data.getKey.value === null ? '' : results.data.getKey.value,
                        termsText: results.data.getKey.value === null ? '' : results.data.getKey.value,
                    })
                } 
            })
            .catch(error => { 
                console.log("Error: ", error); 
            })

    }


    saveTerms = async (type, newValue) => {

        let keyToSave = "terms";
        if (type === 'terms-of-service') { keyToSave = "terms"; } 
        else if (type === 'privacy-policy') { keyToSave = "privacy"; } 
        else if (type === 'cookie-policy') { keyToSave = "cookiePolicy"; } 
        else if (type === 'payment-terms') { keyToSave = "paymentTerms"; } 
        else if (type === 'refund-policy') { keyToSave = "refundPolicy"; } 
        else if (type === 'content-policy') { keyToSave = "contentPolicy"; } 
        else if (type === 'reviews-policy') { keyToSave = "reviewsPolicy"; } 
        else if (type === 'copyright-policy') { keyToSave = "copyrightPolicy"; } 
        else {
            keyToSave = "terms";
        }

        await this.props.client
            .mutate({
                mutation: keyValueUpdate,
                variables: {
                    key: keyToSave,
                    value: newValue
                },
            })
            .then(results => {
                //console.log('saveTerms: ', results)
            })
            .catch(error => {
                // console.log("Error: ", error);
            })
             
    }



    render () {
      
        const modules = {
            toolbar: [
                //[{ 'header': [1, 2, false] }],
                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                //[{ 'size': ['16px', '18px', '20px', '22px'] }],
                //['bold', 'italic', 'underline','strike', 'blockquote'],
                ['bold', 'italic'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                [
                    {'color': 
                        // eslint-disable-next-line no-sparse-arrays
                        [
                        '#000000','#424242','#656565','#999999','#b7b7b7','#cbcbcb','#d9d9d9','#efefef','#f3f3f3','#ffffff',
                        '#8b1910','#ea3223','#f29d38','#fffd54','#74f84b','#73fbfd','#5686e1','#0023f5','#8a2ef5','#ea3ff7',
                        '#deb9b0','#edcdcc','#f8e5cf','#fdf1d0','#dbe9d4','#d2dfe2','#cbdaf5','#d2e1f1','#d7d2e7','#e6d1db',
                        '#d0816f','#de9c9a','#f1cba1','#fbe4a2','#bcd5ac','#a8c3c7','#a8c2ef','#a6c4e5','#b1a8d2','#cda8bb',
                        '#bd4a31','#d16d69','#ecb376','#f9d977','#9dc184','#7fa4ad','#769ee5','#7aa7d7','#8a7ebd','#b87e9e',
                        '#992a14','#bb2619','#da934b','#e9c251','#78a559','#537f8c','#4879d1','#4e84c0','#6251a1','#9b5377',
                        '#7a2816','#8c1a10','#a96224','#b8902f','#48732c','#254e5a','#2357c4','#22538f','#302070','#6a2345',
                        '#531606','#5d0d08','#704115','#7a601c','#304c1b','#16333b','#254582','#153760','#1d144a','#45162e',
                        ]
                    }, 
                    { 'background': 
                        [
                        '#000000','#424242','#656565','#999999','#b7b7b7','#cbcbcb','#d9d9d9','#efefef','#f3f3f3','#ffffff',
                        '#8b1910','#ea3223','#f29d38','#fffd54','#74f84b','#73fbfd','#5686e1','#0023f5','#8a2ef5','#ea3ff7',
                        '#deb9b0','#edcdcc','#f8e5cf','#fdf1d0','#dbe9d4','#d2dfe2','#cbdaf5','#d2e1f1','#d7d2e7','#e6d1db',
                        '#d0816f','#de9c9a','#f1cba1','#fbe4a2','#bcd5ac','#a8c3c7','#a8c2ef','#a6c4e5','#b1a8d2','#cda8bb',
                        '#bd4a31','#d16d69','#ecb376','#f9d977','#9dc184','#7fa4ad','#769ee5','#7aa7d7','#8a7ebd','#b87e9e',
                        '#992a14','#bb2619','#da934b','#e9c251','#78a559','#537f8c','#4879d1','#4e84c0','#6251a1','#9b5377',
                        '#7a2816','#8c1a10','#a96224','#b8902f','#48732c','#254e5a','#2357c4','#22538f','#302070','#6a2345',
                        '#531606','#5d0d08','#704115','#7a601c','#304c1b','#16333b','#254582','#153760','#1d144a','#45162e',
                        ] 
                    }
                ],
                [{ 'align': [] }],
                // ['link', 'image'],
                ['link'],
                ['clean'],
            ],
        }
        
        const formats = [

            'header', 'font', 'background', 'color', 'code', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent', 'script', 'align', 'direction',
            'link', 'image', 'code-block', 'formula', 'video'

        ]

        return (
        
            <div className="bodywrapper">

                <BackButtonWithText onClick={() => { this.props.hideModal() }}>
                    Go Back
                </BackButtonWithText>

                <StyledSave onClick={ () => {
                        this.saveTerms(this.props.type, this.state.termsText);
                        this.props.hideModal()
                        
                    }}
                >
                Save
                </StyledSave>
                <IncreaseHeight32/>

                    <ReactQuill 
                        onChange={this.termsChange} 
                        modules={modules}
                        formats={formats}
                        defaultValue={this.state.termsTextOriginal}
                        value={this.state.termsText}
                        theme={"snow"} 
                        
                    />


                <IncreaseHeight32/>
                <BackButtonWithText onClick={() => { this.props.hideModal() }}>
                    Go Back
                </BackButtonWithText>
                <StyledSave onClick={ () => {
                        this.saveTerms(this.props.type, this.state.termsText);
                        this.props.hideModal()
                    }}
                >
                Save
                </StyledSave>
                <IncreaseHeight32/>

            </div>
        
        );

    }


}




export const EditTerms = withApollo(C);


const BackButtonWithText = styled(Button)`
    && {
    height: 40px;

    margin-top: 8px;

    padding-left: 16px;
    padding-right: 16px;


    color: #333333;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    }
    
`;



const StyledSave = styled(Button)`
    && {
    height: 40px;

    background: #ff9500;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.4);
    color: white;
    
    margin-top: 8px;
    margin-left: 32px;

    padding-left: 16px;
    padding-right: 16px;
    
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
        &:hover {
        background: #ff9500;
        opacity: 0.9;
        color: white;
        }
    }
    
`;

const IncreaseHeight32 = styled.div`

    flex: row;
    width: 100%;
    height: 32px;  
        
`;

