
import React, { Component } from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

// import i18next from "i18next";
import './../../i18n'

import Button from '@material-ui/core/Button';
import Modal from 'react-modal';

const photogCategories = [
    { name: 'Boudoir', catNumber: '1', position: 14, thumb: 'https://www.photosouls.com/images/categories/boudoir.jpg', alt: 'Boudoir photoshoot' },
    { name: 'Couples', catNumber: '2', position: 3, thumb: 'https://www.photosouls.com/images/categories/couples.jpg', alt: 'Photoshoot of couples' },
    { name: 'Family', catNumber: '3', position: 2, thumb: 'https://www.photosouls.com/images/categories/family.jpg', alt: 'Family photoshoot' },
    { name: 'Fashion', catNumber: '4', position: 13, thumb: 'https://www.photosouls.com/images/categories/fashion.jpg', alt: 'Fashion photoshoot' },
    { name: 'Kids', catNumber: '5', position: 10, thumb: 'https://www.photosouls.com/images/categories/kids.jpg', alt: 'Kids photoshoot' },
    { name: 'Lifestyle', catNumber: '6', position: 12, thumb: 'https://www.photosouls.com/images/categories/lifestyle.jpg', alt: 'Lifestyle photoshoot' },
    { name: 'Newborn', catNumber: '7', position: 9, thumb: 'https://www.photosouls.com/images/categories/newborn.jpg', alt: 'Newborn photoshoot' },
    { name: 'Nude', catNumber: '11', position: 15, thumb: 'https://www.photosouls.com/images/categories/nude.jpg', alt: 'Nude photoshoot', chkNSFW: true },         
    { name: 'Portrait', catNumber: '8', position: 1, thumb: 'https://www.photosouls.com/images/categories/portrait.jpg', alt: 'Portrait photoshoot' },      
    { name: 'Pregnancy', catNumber: '9', position: 8, thumb: 'https://www.photosouls.com/images/categories/pregnancy.jpg', alt: 'Pregnancy photoshoot' },
    { name: 'Wedding', catNumber: '10', position: 5, thumb: 'https://www.photosouls.com/images/categories/wedding.jpg', alt: 'Wedding photoshoot' },
    { name: '--------------------', catNumber: '999', position: 999, thumb: '' },
    { name: 'Architecture', catNumber: '32', position: 16, thumb: 'https://www.photosouls.com/images/categories/architecture.jpg', alt: 'Architecture photoshoot' },
    { name: 'Event', catNumber: '33', position: 4, thumb: 'https://www.photosouls.com/images/categories/event.jpg', alt: 'Event photoshoot' },
    { name: 'Food', catNumber: '34', position: 17, thumb: 'https://www.photosouls.com/images/categories/food.jpg', alt: 'Food photoshoot' },
    { name: 'Landscape', catNumber: '28', position: 18, thumb: 'https://www.photosouls.com/images/categories/landscape.jpg', alt: 'Landscape photoshoot' },
    { name: 'Nature', catNumber: '29', position: 19, thumb: 'https://www.photosouls.com/images/categories/nature.jpg', alt: 'Nature photoshoot' },
    { name: 'Pets', catNumber: '30', position: 7, thumb: 'https://www.photosouls.com/images/categories/pets.jpg', alt: 'Pets photoshoot' },
    { name: 'Product', catNumber: '35', position: 11, thumb: 'https://www.photosouls.com/images/categories/product.jpg', alt: 'Product photoshoot' },
    { name: 'Real estate', catNumber: '36', position: 6, thumb: 'https://www.photosouls.com/images/categories/realestate.jpg', alt: 'Real estate photoshoot' },
    { name: 'Sport', catNumber: '37', position: 20, thumb: 'https://www.photosouls.com/images/categories/sport.jpg', alt: 'Sport photoshoot' },
    { name: 'Street', catNumber: '38', position: 21, thumb: 'https://www.photosouls.com/images/categories/street.jpg', alt: 'Street photoshoot' },
    { name: 'Wildlife', catNumber: '31', position: 22, thumb: 'https://www.photosouls.com/images/categories/wildlife.jpg', alt: 'Wildlife photoshoot' },
    { name: '--------------------', catNumber: '999', position: 999, thumb: '' },
    { name: 'Acting', catNumber: '12', position: 23, thumb: 'https://www.photosouls.com/images/categories/acting.jpg', alt: 'acting photoshoot' },
    { name: 'Art', catNumber: '13', position: 24, thumb: 'https://www.photosouls.com/images/categories/art.jpg', alt: 'Art photoshoot' },
    { name: 'Bodypaint', catNumber: '14', position: 25, thumb: 'https://www.photosouls.com/images/categories/bodypaint.jpg', alt: 'Bodypaint photoshoot' },
    { name: 'Cosplay', catNumber: '15', position: 26, thumb: 'https://www.photosouls.com/images/categories/cosplay.jpg', alt: 'Cosplay photoshoot' },
    { name: 'Dance', catNumber: '16', position: 27, thumb: 'https://www.photosouls.com/images/categories/dance.jpg', alt: 'Dance photoshoot' },
    { name: 'Editorial', catNumber: '17', position: 28, thumb: 'https://www.photosouls.com/images/categories/editorial.jpg', alt: 'Editorial photoshoot' },
    { name: 'Fetish', catNumber: '18', position: 29, thumb: 'https://www.photosouls.com/images/categories/fetish.jpg', alt: 'Fetish photoshoot', chkNSFW: true },
    { name: 'Fitness', catNumber: '19', position: 30, thumb: 'https://www.photosouls.com/images/categories/fitness.jpg', alt: 'Fitness photoshoot' },
    { name: 'Lingerie', catNumber: '20', position: 31, thumb: 'https://www.photosouls.com/images/categories/lingerie.jpg', alt: 'Lingerie photoshoot' },
    // { name: 'Pinup', catNumber: '21', position: 32, thumb: 'https://www.photosouls.com/images/categories/.jpg' },
    { name: 'Promotional', catNumber: '23', position: 33, thumb: 'https://www.photosouls.com/images/categories/promotional.jpg', alt: 'Promotional photoshoot' },
    { name: 'Runway', catNumber: '24', position: 34, thumb: 'https://www.photosouls.com/images/categories/runway.jpg', alt: 'Runway photoshoot' },
    { name: 'Swimwear', catNumber: '25', position: 35, thumb: 'https://www.photosouls.com/images/categories/swimwear.jpg', alt: 'Swimwear photoshoot' },
    { name: 'Topless', catNumber: '26', position: 36, thumb: 'https://www.photosouls.com/images/categories/topless.jpg', alt: 'Topless photoshoot', chkNSFW: true },
    { name: 'Underwater', catNumber: '27', position: 37, thumb: 'https://www.photosouls.com/images/categories/underwater.jpg', alt: 'Underwater photoshoot' },
]; 


let modellCategories = [
    { name: 'Acting', catNumber: '12', position: 14, thumb: 'https://www.photosouls.com/images/categories/acting.jpg', alt: 'Acting photoshoot' },
    { name: 'Art', catNumber: '13', position: 13, thumb: 'https://www.photosouls.com/images/categories/art.jpg', alt: 'Art photoshoot' },
    { name: 'Bodypaint', catNumber: '14', position: 12, thumb: 'https://www.photosouls.com/images/categories/bodypaint.jpg', alt: 'Bodypaint photoshoot' },
    { name: 'Boudoir', catNumber: '1', position: 4, thumb: 'https://www.photosouls.com/images/categories/boudoir.jpg', alt: 'Boudoir photoshoot' },
    { name: 'Cosplay', catNumber: '15', position: 15, thumb: 'https://www.photosouls.com/images/categories/cosplay.jpg', alt: 'Cosplay photoshoot' },
    { name: 'Dance', catNumber: '16', position: 16, thumb: 'https://www.photosouls.com/images/categories/dance.jpg', alt: 'Dance photoshoot' },
    { name: 'Editorial', catNumber: '17', position: 11, thumb: 'https://www.photosouls.com/images/categories/editorial.jpg', alt: 'Editorial photoshoot' },
    { name: 'Fashion', catNumber: '4', position: 3, thumb: 'https://www.photosouls.com/images/categories/fashion.jpg', alt: 'Fashion photoshoot' },
    { name: 'Fetish', catNumber: '18', position: 17, thumb: 'https://www.photosouls.com/images/categories/fetish.jpg', alt: 'Fetish photoshoot', chkNSFW: true },
    { name: 'Fitness', catNumber: '19', position: 5, thumb: 'https://www.photosouls.com/images/categories/fitness.jpg', alt: 'Fitness photoshoot' },
    { name: 'Lifestyle', catNumber: '6', position: 1, thumb: 'https://www.photosouls.com/images/categories/lifestyle.jpg', alt: 'Lifestyle photoshoot' },
    { name: 'Lingerie', catNumber: '20', position: 18, thumb: 'https://www.photosouls.com/images/categories/lingerie.jpg', alt: 'Lingerie photoshoot' },
    { name: 'Nude', catNumber: '11', position: 8, thumb: 'https://www.photosouls.com/images/categories/nude.jpg', alt: 'Nude photoshoot', chkNSFW: true },
    // { name: 'Pinup', catNumber: '21', position: 19, thumb: 'https://www.photosouls.com/images/categories/.jpg' },
    { name: 'Portrait', catNumber: '8', position: 2, thumb: 'https://www.photosouls.com/images/categories/portrait.jpg', alt: 'Portrait photoshoot' },
    { name: 'Pregnancy', catNumber: '9', position: 9, thumb: 'https://www.photosouls.com/images/categories/pregnancy.jpg', alt: 'Pregnancy photoshoot' },
    { name: 'Promotional', catNumber: '23', position: 10, thumb: 'https://www.photosouls.com/images/categories/promotional.jpg', alt: 'Promotional photoshoot' },
    { name: 'Runway', catNumber: '24', position: 20, thumb: 'https://www.photosouls.com/images/categories/runway.jpg', alt: 'Runway photoshoot' },
    { name: 'Sport', catNumber: '37', position: 21, thumb: 'https://www.photosouls.com/images/categories/sport.jpg', alt: 'Sport photoshoot' },
    { name: 'Swimwear', catNumber: '25', position: 6, thumb: 'https://www.photosouls.com/images/categories/swimwear.jpg', alt: 'Swimwear photoshoot' },
    { name: 'Topless', catNumber: '26', position: 7, thumb: 'https://www.photosouls.com/images/categories/topless.jpg', alt: 'Topless photoshoot', chkNSFW: true },
    { name: 'Underwater', catNumber: '27', position: 22, thumb: 'https://www.photosouls.com/images/categories/underwater.jpg', alt: '' },
]; 


const muahCategories = [
    { name: 'Makeup & hair', catNumber: '43', position: 0, thumb: 'https://www.photosouls.com/images/categories/muah.jpg', alt: 'Makeup and hair photoshoot' },
    { name: 'Makeup', catNumber: '40', position: 1, thumb: 'https://www.photosouls.com/images/categories/makeup.jpg', alt: 'Makeup photoshoot' },
    { name: 'Hair', catNumber: '39', position: 2, thumb: 'https://www.photosouls.com/images/categories/hair.jpg', alt: 'Hair photoshoot' },
    { name: 'Bodypaint', catNumber: '14', position: 3, thumb: 'https://www.photosouls.com/images/categories/bodypaint.jpg', alt: 'Bodypaint photoshoot' },
    { name: 'Stylist', catNumber: '42', position: 4, thumb: 'https://www.photosouls.com/images/categories/style.jpg', alt: 'Stylist photoshoot' },
]; 

const eventCategories = [
    { name: 'Boudoir', catNumber: '1', position: 6, thumb: 'https://www.photosouls.com/images/categories/boudoir.jpg', alt: 'Boudoir photography workshop' },
    { name: 'Couples', catNumber: '2', position: 7, thumb: 'https://www.photosouls.com/images/categories/couples.jpg', alt: 'Couples photography workshop' },
    { name: 'Family', catNumber: '3', position: 11, thumb: 'https://www.photosouls.com/images/categories/family.jpg', alt: 'Family photography workshop' },
    { name: 'Fashion', catNumber: '4', position: 12, thumb: 'https://www.photosouls.com/images/categories/fashion.jpg', alt: 'Fashion photography workshop' },
    { name: 'Kids', catNumber: '5', position: 13, thumb: 'https://www.photosouls.com/images/categories/kids.jpg', alt: 'Kids photography workshop' },
    { name: 'Lifestyle', catNumber: '6', position: 14, thumb: 'https://www.photosouls.com/images/categories/lifestyle.jpg', alt: 'Lifestyle photography workshop' },
    { name: 'Newborn', catNumber: '7', position: 15, thumb: 'https://www.photosouls.com/images/categories/newborn.jpg', alt: 'Newborn photography workshop' },
    { name: 'Nude', catNumber: '11', position: 8, thumb: 'https://www.photosouls.com/images/categories/nude.jpg', alt: 'Nude photography workshop', chkNSFW: true },         
    { name: 'Portrait', catNumber: '8', position: 4, thumb: 'https://www.photosouls.com/images/categories/portrait.jpg', alt: 'Portrait photography workshop' },      
    { name: 'Pregnancy', catNumber: '9', position: 16, thumb: 'https://www.photosouls.com/images/categories/pregnancy.jpg', alt: 'Pregnancy photography workshop' },
    { name: 'Wedding', catNumber: '10', position: 17, thumb: 'https://www.photosouls.com/images/categories/wedding.jpg', alt: 'Wedding photography workshop' },
    { name: '--------------------', catNumber: '999', position: 999, thumb: '' },
    { name: 'Architecture', catNumber: '32', position: 18, thumb: 'https://www.photosouls.com/images/categories/architecture.jpg', alt: 'Architecture photography workshop' },
    { name: 'Event', catNumber: '33', position: 19, thumb: 'https://www.photosouls.com/images/categories/event.jpg', alt: 'Event photography workshop' },
    { name: 'Food', catNumber: '34', position: 20, thumb: 'https://www.photosouls.com/images/categories/food.jpg', alt: 'Food photography workshop' },
    { name: 'Landscape', catNumber: '28', position: 1, thumb: 'https://www.photosouls.com/images/categories/landscape.jpg', alt: 'Landscape photography workshop' },
    { name: 'Nature', catNumber: '29', position: 2, thumb: 'https://www.photosouls.com/images/categories/nature.jpg', alt: 'Nature photography workshop' },
    { name: 'Pets', catNumber: '30', position: 21, thumb: 'https://www.photosouls.com/images/categories/pets.jpg', alt: 'Pets photography workshop' },
    { name: 'Product', catNumber: '35', position: 3, thumb: 'https://www.photosouls.com/images/categories/product.jpg', alt: 'Product photography workshop' },
    { name: 'Real estate', catNumber: '36', position: 22, thumb: 'https://www.photosouls.com/images/categories/realestate.jpg', alt: 'Real estate photography workshop' },
    { name: 'Sport', catNumber: '37', position: 10, thumb: 'https://www.photosouls.com/images/categories/sport.jpg', alt: 'Sport photography workshop' },
    { name: 'Street', catNumber: '38', position: 9, thumb: 'https://www.photosouls.com/images/categories/street.jpg', alt: 'Street photography workshop' },
    { name: 'Wildlife', catNumber: '31', position: 5, thumb: 'https://www.photosouls.com/images/categories/wildlife.jpg', alt: 'Wildlife photography workshop' },
    { name: '--------------------', catNumber: '999', position: 999, thumb: '' },
    { name: 'Acting', catNumber: '12', position: 23, thumb: 'https://www.photosouls.com/images/categories/acting.jpg', alt: 'Active photography workshop' },
    { name: 'Art', catNumber: '13', position: 24, thumb: 'https://www.photosouls.com/images/categories/art.jpg', alt: 'Art photography workshop' },
    { name: 'Bodypaint', catNumber: '14', position: 25, thumb: 'https://www.photosouls.com/images/categories/bodypaint.jpg', alt: 'Bodypaint photography workshop' },
    { name: 'Cosplay', catNumber: '15', position: 26, thumb: 'https://www.photosouls.com/images/categories/cosplay.jpg', alt: 'Cosplay photography workshop' },
    { name: 'Dance', catNumber: '16', position: 27, thumb: 'https://www.photosouls.com/images/categories/dance.jpg', alt: 'Dance photography workshop' },
    { name: 'Editorial', catNumber: '17', position: 28, thumb: 'https://www.photosouls.com/images/categories/editorial.jpg', alt: 'Editorial photography workshop' },
    { name: 'Fetish', catNumber: '18', position: 29, thumb: 'https://www.photosouls.com/images/categories/fetish.jpg', alt: 'Fetish photography workshop', chkNSFW: true },
    { name: 'Fitness', catNumber: '19', position: 30, thumb: 'https://www.photosouls.com/images/categories/fitness.jpg', alt: 'Fitness photography workshop' },
    { name: 'Lingerie', catNumber: '20', position: 31, thumb: 'https://www.photosouls.com/images/categories/lingerie.jpg', alt: 'Lingerie photography workshop' },
    // { name: 'Pinup', catNumber: '21', position: 32, thumb: 'https://www.photosouls.com/images/categories/.jpg' },
    { name: 'Promotional', catNumber: '23', position: 32, thumb: 'https://www.photosouls.com/images/categories/promotional.jpg', alt: 'Promotional photography workshop' },
    { name: 'Runway', catNumber: '24', position: 33, thumb: 'https://www.photosouls.com/images/categories/runway.jpg', alt: 'Runway photography workshop' },
    { name: 'Swimwear', catNumber: '25', position: 34, thumb: 'https://www.photosouls.com/images/categories/swimwear.jpg', alt: 'Swimwear photography workshop' },
    { name: 'Topless', catNumber: '26', position: 35, thumb: 'https://www.photosouls.com/images/categories/topless.jpg', alt: 'Topless photography workshop', chkNSFW: true },
    { name: 'Underwater', catNumber: '27', position: 36, thumb: 'https://www.photosouls.com/images/categories/underwater.jpg', alt: 'Underwater photography workshop' },
    { name: '--------------------', catNumber: '999', position: 999, thumb: '' },
    { name: 'Makeup & hair', catNumber: '0', position: 37, thumb: 'https://www.photosouls.com/images/categories/muah.jpg', alt: 'MUAH photography workshop' },
    { name: 'Makeup', catNumber: '2', position: 38, thumb: 'https://www.photosouls.com/images/categories/makeup.jpg', alt: 'Makeup photography workshop' },
    { name: 'Hair', catNumber: '1', position: 39, thumb: 'https://www.photosouls.com/images/categories/hair.jpg', alt: 'Hair photography workshop' },
    { name: 'Bodypaint', catNumber: '3', position: 40, thumb: 'https://www.photosouls.com/images/categories/bodypaint.jpg', alt: 'Bodypaint photography workshop' },
    { name: 'Stylist', catNumber: '4', position: 41, thumb: 'https://www.photosouls.com/images/categories/style.jpg', alt: 'Styling photography workshop' },

]; 

export class CategoryButtons extends Component {
    

    


    state = {
        showCategorySelection: false,
        categorySelected: false,
    };


    selectCategory = (cat, name) => {
        this.props.onClick(cat, name)
        setTimeout(() => { this.setState({ categorySelected: true, showCategorySelection: false }); }, 50) 
    };

    toggleCategorySelection = () => {  

        this.setState({  
            showCategorySelection: !this.state.showCategorySelection,
        });  

    }  


    render() {
      
        //console.log('this.state.showCategorySelection', this.state.showCategorySelection)

        let categories = [];
        let popupTitle = ""

        if (this.props.categories === 'photog') { 
            categories = photogCategories
            popupTitle = "What kind of photoshoot are you looking for?"
        } else if (this.props.categories === 'modell') { 
            categories = modellCategories
            popupTitle = "What kind of photoshoot are you planning?"
        } else if (this.props.categories === 'muah') { 
            categories = muahCategories
            popupTitle = "What kind of professional are you looking for?"
        } else if (this.props.categories === 'event') { 
            categories = eventCategories
            popupTitle = "Interested in a specific category?"
        } else
            categories = photogCategories


        const oneWidth = 150 + 16;
        const wLen = this.props.winLen - (this.props.winLen*0.1);
        
        let maxCategories = Math.floor(wLen / oneWidth ) - 1;
        if (this.props.isonMainPage === 'true') { 
            maxCategories = 6; 
        }
        if (wLen < 1100) { 
            maxCategories = 5; 
        }
 
        let catStorted = [];
        for (var o = categories.length-1; o >= 0; o--) {
            catStorted.push(categories[o]) 
        }
        catStorted.sort((a, b) => a.position - b.position);

        let catArray = []
        for (var i = 0; i < maxCategories; i++) {
            if (i < catStorted.length && catStorted[i].name !== '--------------------') { 
                catArray.push(catStorted[i]) 
            }
        }


        let RenderCategoryButtons = []
        if (this.props.catFilterSet) {

            // console.log('catFilterModal', this.props.catFilterModal)
            // console.log('categorySelected', this.state.categorySelected)
            // console.log('showCategorySelection', this.state.showCategorySelection)

            if (this.props.catFilterModal && !this.state.showCategorySelection) {
                this.toggleCategorySelection();
            }

        } else {

            catArray.forEach((cat) => {

                if (cat.chkNSFW === true) {

                    if (this.props.chkNSFW === 'true') {
                        RenderCategoryButtons.push(
                    
                            <CategoryButton 
                                key={cat.catNumber} 
                                onClick={() => this.selectCategory(cat.catNumber, cat.name)} 
                            >
            
                                <CategoryThumb 
                                    src={cat.thumb} 
                                    alt={cat.alt}
                                />
            
                                { cat.name } 
            
                            </CategoryButton>
            
                        )
                    }

                } else {
                    RenderCategoryButtons.push(
                    
                        <CategoryButton 
                            key={cat.catNumber} 
                            onClick={() => this.selectCategory(cat.catNumber, cat.name)} 
                        >
        
                            <CategoryThumb 
                                src={cat.thumb} 
                                alt={cat.alt}
                            />
        
                            { cat.name } 
        
                        </CategoryButton>
        
                    )
                }
            })
        }
        


        return (
        
            <>

                <CategoryButtonRow>

                    { !this.props.catFilterSet ? 
                        <>             
                            { RenderCategoryButtons }
                            { this.props.categories !== 'muah' ?
                                <MoreCategoriesButton
                                    onClick={() => this.toggleCategorySelection()}
                                >
                                    More categories...
                                </MoreCategoriesButton>
                                :
                                null
                            }
                        </>
                        :
                        null
                    }
                    
 
                    <Modal
                        isOpen={this.state.showCategorySelection}
                        closeTimeoutMS={500}

                        onAfterOpen={() => {
                            document.body.style.top = `0px`
                            document.body.style.width = `100vw`
                            document.body.style.position = 'fixed'
                        }}

                        onAfterClose={() => {
                            const scrollY = document.body.style.top
                            document.body.style.position = ''
                            document.body.style.top = ''
                            document.body.style.width = ''
                            window.scrollTo(0, parseInt(scrollY || '0') * -1)
                        }}

                        onRequestClose={this.toggleCategorySelection}
                        style={customStyles}
                        ariaHideApp={false}
                    >

                        <BackButton onClick={ () => { this.toggleCategorySelection() } } >
                            <FAIcon icon={faChevronLeft} color={'#000'} />
                        </BackButton>
                        { 
                            <ClearFilter onClick={() => { this.selectCategory('000', '---'); }}>
                                Clear filter
                            </ClearFilter>
                        }




                        <IncreaseHeight/>

                        <SubTitle>{popupTitle}</SubTitle>

                        <CategoryButtonRow>
                        {
                            
                            categories.map((category, index) => {

                                let allCategoryButtons = []
                                
                                //console.log(category.name + ' , ' + category.chkNSFW)

                                if (category.chkNSFW === true) {

                                    if (this.props.chkNSFW === 'true') {
                                        allCategoryButtons.push(
                                    
                                            <CategoryButton 
                                                key={index} 
                                                newline={category.name === '--------------------' ? true : false}
                                                winLen={window.innerWidth-184}
                                                onClick={() => {
                                                    
                                                    this.selectCategory(category.catNumber, category.name);

                                                    }
                                                } 
                                            >

                                            { category.name !== '--------------------' ?
                                                <>
                                                    <CategoryThumb 
                                                        src={category.thumb} 
                                                        alt={category.alt}
                                                    />
                                                    { category.name } 
                                                </>
                                                :
                                                <></>
                                            }

                                            </CategoryButton>

                                        )
                                    }

                                }
                                else {

                                    allCategoryButtons.push(
                                
                                        <CategoryButton 
                                            key={index} 
                                            newline={category.name === '--------------------' ? true : false}
                                            winLen={window.innerWidth-184}
                                            onClick={() => {
                                                
                                                this.selectCategory(category.catNumber, category.name);

                                                }
                                            } 
                                        >

                                        { category.name !== '--------------------' ?
                                            <>
                                                <CategoryThumb 
                                                    src={category.thumb} 
                                                    alt={category.alt}
                                                />
                                                { category.name } 
                                            </>
                                            :
                                            <></>
                                        }

                                        </CategoryButton>

                                    )

                                }
                                

                                return (
                                    
                                    <div key={index}>

                                        { allCategoryButtons }
                                        
                                    </div>
                                )

                            })


                        }
                        </CategoryButtonRow>

                        { !this.props.catFilterSet ? 
                            <ButtonArea>
                                                                        
                                <StyledCancel100
                                    onClick={() => {
                                        this.toggleCategorySelection();
                                    }}
                                    >
                                    Cancel
                                </StyledCancel100>

                            </ButtonArea>
                            :
                            <ButtonArea>
                                                                        
                                <StyledCancel100
                                    onClick={() => { this.selectCategory('000', '---'); }}
                                    >
                                    Clear filter
                                </StyledCancel100>
                                <IncreaseHeight16/>
                                <IncreaseHeight16/>

                            </ButtonArea>
                        }

                        
                        <IncreaseHeight16/>

                        <BackButton onClick={ () => { this.toggleCategorySelection() } } >
                            <FAIcon icon={faChevronLeft} color={'#000'} />
                        </BackButton>

                        <IncreaseHeight/>
                        <IncreaseHeight/>



                    </Modal>
 


                </CategoryButtonRow>

            </>
        
        );

    }


}



const CategoryButtonRow = styled.div`
    
    padding-bottom: 32px;
    width: 100%;

    display: flex;
    flex-wrap: wrap;

`;



const CategoryButton = styled.div`
        
    width: ${(props) => props.newline ? props.winLen+'px' : "150px"};
    min-width: ${(props) => props.newline ? props.winLen+'px' : "150px"};
    overflow: hidden;

    height: ${(props) => props.newline ? "16px": "230px"};
    min-height: ${(props) => props.newline ? "16px": "230px"};

    pointer-events: ${(props) => props.newline ? "none": "auto"};

    align-items: flex-start;
    border-radius: 5px;

    font-size: 15px;
    font-weight: 400;
    font-family: 'Fira Sans';
    color: #333333;

    margin-right: 16px;
    margin-bottom: 16px;
    
    

    &:hover {
        transform: scale(1.02);
        cursor: ${(props) => props.newline ? "cursor": "pointer"};
    }
    
`;




const CategoryThumb = styled.img`

    width: 150px;
    min-width: 150px;

    height: 188px;
    min-height: 188px;
    
    
    object-fit: cover;

    border-radius: 5px;

`;


const MoreCategoriesButton = styled.div`
    
    width: calc(150px - 34px);
    height: calc(188px - 34px);

    border: 1px solid #cbcbcb;
    
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 5px;


    font-family: 'Fira Sans';
    color: #333333;
    font-size: 17px;
    font-weight: 400;
       
    padding: 16px;

    &:hover {
        transform: scale(1.02);
        cursor: pointer;
        background: #f5f5f5;
        border-color: #333333;
    }
`;


const customStyles = {
    content : {
        boxShadow: '0 20px 50px rgba(0, 0, 0, 0.33)',
        borderRadius: '5px',
        height: '90%',
        overflow : 'auto',
        width: '90%',
        maxWidth: '90%',
        
        paddingTop: '32px',
        paddingLeft: '32px',

        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '999999'

    },
    overlay: {zIndex: 1000}

  };


  const ButtonArea = styled.div`

    color: #333333;
    align-items: flex-end;
    width: calc(100% - 64px);


    margin: auto;

`;

const IncreaseHeight16 = styled.div`
    
    height: 16px;  

`;

const StyledCancel100 = styled(Button)`
    && {

    height: 40px;
    top: 18px;

    padding-left: 16px;
    padding-right: 16px;
    
    color: #333333;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;

    margin-bottom: 32px;
    width: 100%;

    }
    
`;

const SubTitle = styled.div`
    
    font-size: 20px;
    font-weight: 400;
    padding: 0px 24px 16px 0px;


    font-family: 'Fira Sans';
    color: #333333;

    margin-bottom: 16px;

`;


const BackButton = styled(Button)`
    && {
        
    /* margin: -4px -4px 0 0px; */

    height: 48px;
    min-width: 48px;
    max-width: 48px;
    border-radius: 24px;

    margin-right: 16px;
    /* margin-right: 4px;
    margin-left: 4px; */

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;

    
    font-weight: 400;
    font-size: 19px;

    background-color: #EBEBEB;
    opacity: 0.6;

    &:hover {
        background-color: #EBEBEB;
        opacity: 0.6;
        }
    
    &:disabled {
        background-color: #fff;
        color: #fff;
        }

    }
    
`;

const FAIcon = styled(FontAwesomeIcon)`
    && {
    font-size: 22px;
    font-weight: 400;
    }
`;

const IncreaseHeight = styled.div`
    
    height: 16px;  
    min-height: 16px;

`;

const ClearFilter = styled(Button)`
    && {
    height: 40px;

    padding-left: 16px;
    padding-right: 16px;

    color: #333333;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    }
    
`;