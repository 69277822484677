import React, { PureComponent } from 'react'
import { withFormik  } from "formik";

import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Select from 'react-select'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import { withApollo } from "react-apollo";

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

// import i18next from "i18next";
import { withStore } from '@spyna/react-store'

import { UpgradeModal } from "../../../components/UpgradeModal";

import "./../settings.css";
import './../../../../i18n'



import gql from "graphql-tag"
const updateMutation = gql`
    mutation($fld: String!, $value: String!) {
        updateField(fld: $fld, value: $value)
    }
`;

const currencies = [
    { value: '0', code: 'AED', label: 'AED - Emirati dirham' },
    { value: '1', code: 'AUD', label: 'AUD - Australian dollar' },
    { value: '2', code: 'BGN', label: 'BGN - Bulgarian lev' },
    { value: '3', code: 'BRL', label: 'BRL - Brazilian real' },
    { value: '4', code: 'CAD', label: 'CAD - Canadian dollar' },
    { value: '5', code: 'CHF', label: 'CHF - Swiss franc' },
    { value: '6', code: 'CLP', label: 'CLP - Chilean peso' },
    { value: '7', code: 'CNY', label: 'CNY - Chinese yuan' },
    { value: '8', code: 'COP', label: 'COP - Colombian peso' },
    { value: '9', code: 'CRC', label: 'CRC - Costa Rican colon' },
    { value: '10', code: 'CZK', label: 'CZK - Czech koruna' },
    { value: '11', code: 'DKK', label: 'DKK - Danish krone' },
    { value: '12', code: 'EUR', label: 'EUR - Euro' },
    { value: '13', code: 'GBP', label: 'GBP - Pound sterling' },
    { value: '14', code: 'HKD', label: 'HKD - Hong Kong dollar' },
    { value: '15', code: 'HRK', label: 'HRK - Croatian kuna' },
    { value: '16', code: 'HUF', label: 'HUF - Hungarian forint' },
    { value: '17', code: 'INR', label: 'INR - Indian rupee' },
    { value: '18', code: 'ILS', label: 'ISL - Israeli new shekel' },
    { value: '19', code: 'JPY', label: 'JPY - Japanese yen' },
    { value: '20', code: 'KRW', label: 'KRW - South Korean won' },
    { value: '21', code: 'MAD', label: 'MAD - Moroccan dirham' },
    { value: '22', code: 'MYR', label: 'MYR - Malaysian ringgit' },
    { value: '23', code: 'MXN', label: 'MXN - Mexican peso' },
    { value: '24', code: 'NOK', label: 'NOK - Norwegian krone' },
    { value: '25', code: 'NZD', label: 'NZD - New Zealand dollar' },
    { value: '26', code: 'PEN', label: 'PEN - Peruvian sol' },
    { value: '27', code: 'PHP', label: 'PHP - Philippine peso' },
    { value: '28', code: 'PLN', label: 'PLN - Polish zloty' },
    { value: '29', code: 'RON', label: 'RON - Romanian leu' },
    { value: '30', code: 'RUB', label: 'RUB - Russian ruble' },
    { value: '31', code: 'SAR', label: 'SAR - Saudi Arabian riyal' },
    { value: '32', code: 'SEK', label: 'SEK - Swedish krona' },
    { value: '33', code: 'SGD', label: 'SGD - Singapore dollar' },
    { value: '34', code: 'THB', label: 'THB - Thai baht' },
    { value: '35', code: 'TRY', label: 'TRY - Turkish lira' },
    { value: '36', code: 'TWD', label: 'TWD - New Taiwan dollar' },
    { value: '37', code: 'USD', label: 'USD - United States dollar' },
    { value: '38', code: 'UYU', label: 'UYU - Uruguayan peso' },
    { value: '39', code: 'ZAR', label: 'ZAR - South African rand' },

];



class C extends PureComponent {

    state = {
        showUpgradeModal: false,
        
    };


    componentDidMount() {

        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

    }


    handleChange = event => {

        this.setState({
            [event.target.value]: event.target.checked,
        });

    
        console.log(event.target.value)
        console.log(event.target.checked)

        this.props.store.set([event.target.value], event.target.checked ? "true" : false)
        
        this.props.client
        .mutate({
            mutation: updateMutation,
            variables: {
                fld: event.target.value,
                value: event.target.checked.toString()
            },
        })
        .then(results => {
            //console.log(results)
        })
        .catch(error => {
            //console.log("Error: ", error);
        })

      };


    render() {
        
       
        
        let selectedCurrency = 37; //default = USD (just in case if for some reason (error) the default was not set at signup)
        for (var i = 0; i < currencies.length; i++) {
            if (currencies[i].code === this.props.store.get('currency')) {
                selectedCurrency = i;
            }
        }

        // console.log('store currency: ', this.props.store.get('currency'))
        // console.log('selectedCurrency #: ', selectedCurrency)
        // console.log('selectedCurrency code: ', currencies[selectedCurrency].code)

        const chkBoudoir = this.props.store.get('chkBoudoir') === "true" ? true : false; 
        const chkCouples = this.props.store.get('chkCouples') === "true" ? true : false; 
        const chkFamily = this.props.store.get('chkFamily') === "true" ? true : false; 
        const chkFashion = this.props.store.get('chkFashion') === "true" ? true : false; 
        const chkKids = this.props.store.get('chkKids') === "true" ? true : false; 
        const chkLifestyle = this.props.store.get('chkLifestyle') === "true" ? true : false; 
        const chkMaternity = this.props.store.get('chkMaternity') === "true" ? true : false; 
        const chkNewborn = this.props.store.get('chkNewborn') === "true" ? true : false; 
        const chkPortrait = this.props.store.get('chkPortrait') === "true" ? true : false; 
        const chkWedding = this.props.store.get('chkWedding') === "true" ? true : false; 

        const chkLandscape = this.props.store.get('chkLandscape') === "true" ? true : false; 
        const chkNature = this.props.store.get('chkNature') === "true" ? true : false; 
        const chkPets = this.props.store.get('chkPets') === "true" ? true : false; 
        const chkWildlife = this.props.store.get('chkWildlife') === "true" ? true : false; 

        const chkArchitecture = this.props.store.get('chkArchitecture') === "true" ? true : false; 
        const chkEvent = this.props.store.get('chkEvent') === "true" ? true : false; 
        const chkFood = this.props.store.get('chkFood') === "true" ? true : false; 
        const chkProduct = this.props.store.get('chkProduct') === "true" ? true : false; 
        const chkRealestate = this.props.store.get('chkRealestate') === "true" ? true : false; 
        const chkSport = this.props.store.get('chkSport') === "true" ? true : false; 
        const chkStreet = this.props.store.get('chkStreet') === "true" ? true : false; 
        
        const chkNSFW = this.props.store.get('chkNSFW') === "true" ? true : false; 
        const isPro = this.props.store.get('isPro') === true ? true : false; 

       

        return (

            
            <ExtraWrapper>

                <Helmet>
                    <title>Global Settings</title>
                    <meta name="description" content="Global Settings" />
                    <meta name="keywords" content="photosouls,global settings" />
                </Helmet>
            
            
                

     
                    <ModalTitle>

                        <BackButton onClick={ () => { this.props.history.push({ pathname: '../settings' }) } } >
                            <FAIcon icon={faChevronLeft} color={'#000'} />
                        </BackButton>

                        <TitleHeadline>
                        Global Preferences
                        </TitleHeadline>

                    </ModalTitle>
                        

                        <br/>
                        <LabelStyled>Preferred Currency</LabelStyled>
                        <Select 
                            name='currency'
                            classNamePrefix="select"
                            styles={colourStyles}
                            options={currencies} 
                            isSearchable={true}
                            onChange={(e) => {
                                
                                console.log('onChange')
                                this.props.client
                                    .mutate({
                                        mutation: updateMutation,
                                        variables: { fld: 'currency', value: e.code },
                                    })
                                    .then(results => {
                                        //console.log(results)
                                    })
                                    .catch(error => {
                                        //console.log("Error: ", error);
                                    });

                                this.props.store.set('currency', e.code)

                                // console.log(e)

                                }
                            }
                            defaultValue={currencies[selectedCurrency]}

                        />
                        <br/>



                        <StyledH1>
                        Select categories you're interested in
                        </StyledH1>

                            <StyledH2>
                            People
                            </StyledH2>

                                <StyledGRow>
                                    <FormControlLabel 
                                        control={
                                            <OrangeCheckBox
                                                checked={chkBoudoir}
                                                onChange={this.handleChange}
                                                value="chkBoudoir"
                                            />
                                        }
                                    label="Boudoir"
                                    />                       
                                </StyledGRow>

                                <StyledGRow>
                                    <FormControlLabel 
                                        control={
                                            <OrangeCheckBox
                                                checked={chkCouples}
                                                onChange={this.handleChange}
                                                value="chkCouples"
                                            />
                                        }
                                    label="Couples & Engagement"
                                    />
                                </StyledGRow>

                                <StyledGRow>
                                    <FormControlLabel 
                                        control={
                                            <OrangeCheckBox
                                                checked={chkFamily}
                                                onChange={this.handleChange}
                                                value="chkFamily"
                                            />
                                        }
                                    label="Family"
                                    />
                                </StyledGRow>
                                
                                <StyledGRow>
                                    <FormControlLabel 
                                        control={
                                            <OrangeCheckBox
                                                checked={chkFashion}
                                                onChange={this.handleChange}
                                                value="chkFashion"
                                            />
                                        }
                                    label="Fashion"
                                    />
                                </StyledGRow>

                                <StyledGRow>
                                    <FormControlLabel 
                                        control={
                                            <OrangeCheckBox
                                                checked={chkKids}
                                                onChange={this.handleChange}
                                                value="chkKids"
                                            />
                                        }
                                    label="Kids"
                                    />
                                </StyledGRow>

                                <StyledGRow>
                                    <FormControlLabel 
                                        control={
                                            <OrangeCheckBox
                                                checked={chkLifestyle}
                                                onChange={this.handleChange}
                                                value="chkLifestyle"
                                            />
                                        }
                                    label="Lifestyle"
                                    />
                                </StyledGRow>

                                <StyledGRow>
                                    <FormControlLabel 
                                        control={
                                            <OrangeCheckBox
                                                checked={chkMaternity}
                                                onChange={this.handleChange}
                                                value="chkMaternity"
                                            />
                                        }
                                    label="Maternity"
                                    />
                                </StyledGRow>
                                

                                <StyledGRow>
                                    <FormControlLabel 
                                        control={
                                            <OrangeCheckBox
                                                checked={chkNewborn}
                                                onChange={this.handleChange}
                                                value="chkNewborn"
                                            />
                                        }
                                    label="Newborn"
                                    />
                                </StyledGRow>

        
                                <StyledGRow>
                                    <FormControlLabel 
                                        control={
                                            <OrangeCheckBox
                                                checked={chkPortrait}
                                                onChange={this.handleChange}
                                                value="chkPortrait"
                                            />
                                        }
                                    label="Portraits"
                                    />
                                </StyledGRow>


                            
                                <StyledGRow>
                                    <FormControlLabel 
                                        control={
                                            <OrangeCheckBox
                                                checked={chkWedding}
                                                onChange={this.handleChange}
                                                value="chkWedding"
                                            />
                                        }
                                    label="Weddings"
                                    />
                                </StyledGRow>




                                
                            <StyledH2>
                            </StyledH2>
                            <StyledH2>
                            Nature and animals
                            </StyledH2>

                                <StyledGRow>
                                    <FormControlLabel 
                                        control={
                                            <OrangeCheckBox
                                                checked={chkLandscape}
                                                onChange={this.handleChange}
                                                value="chkLandscape"
                                            />
                                        }
                                    label="Landscape"
                                    />                       
                                </StyledGRow>

                                <StyledGRow>
                                    <FormControlLabel 
                                        control={
                                            <OrangeCheckBox
                                                checked={chkNature}
                                                onChange={this.handleChange}
                                                value="chkNature"
                                            />
                                        }
                                    label="Nature"
                                    />
                                </StyledGRow>

                                <StyledGRow>
                                    <FormControlLabel 
                                        control={
                                            <OrangeCheckBox
                                                checked={chkPets}
                                                onChange={this.handleChange}
                                                value="chkPets"
                                            />
                                        }
                                    label="Pets"
                                    />
                                </StyledGRow>

                                <StyledGRow>
                                    <FormControlLabel 
                                        control={
                                            <OrangeCheckBox
                                                checked={chkWildlife}
                                                onChange={this.handleChange}
                                                value="chkWildlife"
                                            />
                                        }
                                    label="Wildlife"
                                    />
                                </StyledGRow>

        
                            <StyledH2>
                            </StyledH2>
                            <StyledH2>
                            Other
                            </StyledH2>

                                <StyledGRow>
                                    <FormControlLabel 
                                        control={
                                            <OrangeCheckBox
                                                checked={chkArchitecture}
                                                onChange={this.handleChange}
                                                value="chkArchitecture"
                                            />
                                        }
                                    label="Architecture"
                                    />                       
                                </StyledGRow>

                                <StyledGRow>
                                    <FormControlLabel 
                                        control={
                                            <OrangeCheckBox
                                                checked={chkEvent}
                                                onChange={this.handleChange}
                                                value="chkEvent"
                                            />
                                        }
                                    label="Event"
                                    />
                                </StyledGRow>
        
                                <StyledGRow>
                                    <FormControlLabel 
                                        control={
                                            <OrangeCheckBox
                                                checked={chkFood}
                                                onChange={this.handleChange}
                                                value="chkFood"
                                            />
                                        }
                                    label="Food"
                                    />
                                </StyledGRow>

                                <StyledGRow>
                                    <FormControlLabel 
                                        control={
                                            <OrangeCheckBox
                                                checked={chkProduct}
                                                onChange={this.handleChange}
                                                value="chkProduct"
                                            />
                                        }
                                    label="Product"
                                    />
                                </StyledGRow>

                                <StyledGRow>
                                    <FormControlLabel 
                                        control={
                                            <OrangeCheckBox
                                                checked={chkRealestate}
                                                onChange={this.handleChange}
                                                value="chkRealestate"
                                            />
                                        }
                                    label="Real-estate"
                                    />
                                </StyledGRow>

                                <StyledGRow>
                                    <FormControlLabel 
                                        control={
                                            <OrangeCheckBox
                                                checked={chkSport}
                                                onChange={this.handleChange}
                                                value="chkSport"
                                            />
                                        }
                                    label="Sport"
                                    />
                                </StyledGRow>

                                <StyledGRow>
                                    <FormControlLabel 
                                        control={
                                            <OrangeCheckBox
                                                checked={chkStreet}
                                                onChange={this.handleChange}
                                                value="chkStreet"
                                            />
                                        }
                                    label="Street"
                                    />
                                </StyledGRow>


                            <StyledH2>
                            </StyledH2>
                            <StyledH2>
                            Not Safe For Work (NSFW) Content
                            </StyledH2>

                                <StyledGRow>
                                    <FormControlLabel 
                                        control={
                                            <OrangeCheckBox
                                                checked={chkNSFW}
                                                //onChange={this.handleChange}
                                                onChange={(event) => {

                                                    //extra checks are needed only when when activating
                                                    if (event.target.checked) {
                                                        
                                                        if (isPro) {
                                                            this.handleChange(event);
                                                        } else  {
                                                            this.setState({ showUpgradeModal: true });
                                                        }

                                                    } else {
                                                        this.handleChange(event)
                                                    }

                                                }}
                                                value="chkNSFW"
                                            />
                                        }
                                    label="Show NSFW content"
                                    />      
                                </StyledGRow>
                                    
                                { this.state.showUpgradeModal ? 
                                    <UpgradeModal
                                        showUpgradeModal={this.state.showUpgradeModal}
                                        closePopup={() => this.setState({ showUpgradeModal: false }) }
                                        popTitle="NSFW content can only be seen by Pro members"
                                        popDescription="If you would like to see NSFW content, please upgrade to the Pro account."
                                    />
                                : null
                                }
                                
                                
                                
                            
                   
                            
                            <StyledH3>
                            Definition of Not Safe For Work (NSFW)
                            </StyledH3>
 
                                <StyledP>
                                In order to maintain a safe and inclusive environment for people from all walks of life, we ask that Not Safe For Work (NSFW) content be labeled accurately in order to cater to the preferred experience of our entire community. Not Safe For Work (NSFW) Content includes, but is not necessarily limited to nudity with or without sexual implications. Not Safe For Work (NSFW) Content may also include shocking or disturbing images, such as violence or blood, not typically suitable for viewers under the age of 18. Your avatar or cover photo should also not contain any Not Safe For Work (NSFW) Content.
                                </StyledP>
                                

                            {/* <StyledH3>
                            Not Safe For Work (NSFW) Content on Mobile Apps
                            </StyledH3>
 
                                <StyledP>
                                In order to make our apps available to everyone, we filter Not Safe For Work (NSFW) Content out of our iOS and Android apps. The content is still visible for users on our website via web browser, but will not be surfaced through our mobile applications. Meaning, you will not be able to access NSFW content via search, in people’s profiles, and it will not displayed within your Explore Feed. This is due to guidelines put in place by both Apple and Google. We are trying to make the PhotoSouls mobile experience accessible to everyone, and this means abiding by the rules put in place by the governing policy.
                                </StyledP>
 */}


                            <StyledH3>
                            Not Safe For Work (NSFW) Content Moderation
                            </StyledH3>
 
                                <StyledP>
                                We have a team of photo moderators that monitor the site. We also have an automated moderation system. If a photo is reported for being improperly marked as Not Safe For Work (NSFW) Content, a moderator will look at the photo to determine if it is Not Safe For Work (NSFW) Content or not. It's important to recognize that the filtering or removal of content on PhotoSouls is the sole discretion of our moderators. As per our Terms of Service, our moderators reserve the right to filter and/or remove content at anytime without detailed explanation.
                                </StyledP>

                            <StyledH3>
                            Unsuitable Content
                            </StyledH3>
 
                                <StyledP>
                                We do not allow visual material containing the description or display of sexual organs or activity on PhotoSouls. This may include details or close-ups of vaginas, penises or anuses. Overtly sexual images or images portraying sexual acts (intercourse, masturbation, oral, etc..) are also unacceptable. Excessive gore, violence, cruelty (animal or otherwise) and any form of illegal activity (depending on the context to which it has been uploaded) is also prohibited. Images of cultural appropriation may also be considered unsuitable content, depending on the context of the image.
                                </StyledP>
                                


                            {/* <StyledH3>
                            How to enable Not Safe For Work (NSFW) Content on your account
                            </StyledH3>
 
                                <StyledP>
                                By default, Not Safe For Work (NSFW) content will be hidden on PhotoSouls. If you're OK seeing NSFW content, go to your General Preferences and activate the content categories you would like to see.
                                </StyledP> */}

                        <StyledH1></StyledH1>
                        
                    
            </ExtraWrapper>


        )
    }
}


const AccountSettingsGlobal = withFormik({
    
    //validationSchema: validEmailField,
    //console.log(this);

    mapPropsToValues: () => ({}),
    
    handleSubmit: async (values, { props, setErrors }) => {
        //console.log('handlesubmit')
    }
  })(C);
  

const personalInfoFields = [
    'fName', 'lName', 'bDay', 'email', 'phone', 'phoneDisplay', 'phoneCountry', 'avatar', 'isVerified', 'isPro', 'temperror', 'pwdUpdated',
    'chkReminders', 'chkRemindersP', 'chkMessages', 'chkMessagesP', 'chkRecomm', 'chkRecommP', 'chkInsp', 'chkInspP', 'chkLocal', 'chkLocalP', 
    'chkHow', 'chkHowP', 'chkInvite', 'chkInviteP', 'chkSurvey', 'chkSurveyP', 'chkPolicy', 'chkPolicyP', 'chkTips', 'chkTipsP', 'isPro'
]


export default withStore(withApollo(AccountSettingsGlobal), personalInfoFields);



const StyledH1 = styled.div`

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    width: calc(100% - 32px);
    padding-bottom: 24px;
    padding-top: 24px;

    font-size: 20px;
    font-family: 'Fira Sans';
    font-weight: 500;
    color: #333333;

    margin-left: 24px;
    
    @media (max-width: 600px) {
        margin-left: 24px;
    }

  
`;

const StyledH2 = styled.div`
  width: calc(100% - 32px);
  margin-left: 48px;

  font-size: 18px;
  font-family: 'Fira Sans';
  font-weight: 700;
  color: #333333;
  margin-bottom: 4px;

  @media (max-width: 600px) {
    margin-left: 24px;
  }

`;

const StyledH3 = styled.div`
  width: calc(100% - 32px);
  margin-left: 72px;

  font-size: 18px;
  font-family: 'Fira Sans';
  font-weight: 700;
  color: #333333;

  margin-top: 16px;
  margin-bottom: 16px;

  @media (max-width: 600px) {
    margin-left: 24px;
  }

`;


const StyledGRow = styled.div`
  width: calc(100% - 32px);
  display: flex;
  margin-left: 72px;
  @media (max-width: 600px) {
    margin-left: 48px;
  }
`;


const StyledP = styled.div`
 width: calc(100% - 32px);
  margin-left: 72px;
  margin-bottom: 24px;
  font-weight: 300;
  font-size: 17px;
  font-family: 'Fira Sans';
  @media (max-width: 600px) {
    margin-left: 24px;
  }
`;






const ExtraWrapper = styled.div`

    white-space: pre-line;
    width: calc(80% - 64px);
    max-width: 1200px;

    margin: 40px auto 32px auto;

    font-family: 'Fira Sans';

    @media (max-width: 790px) {
        width: calc(100% - 32px);
        margin: 0;
        overflow-x: hidden;
    }

`;



const ModalTitle = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;

    margin-left: 24px;

    @media (max-width: 970px) {
        flex-direction: column;
        margin-top: 16px;
    }


`;


const BackButton = styled(Button)`
    && {
        
    /* margin: -4px -4px 0 0px; */

    height: 48px;
    min-width: 48px;
    max-width: 48px;
    border-radius: 24px;

    margin-right: 16px;
    /* margin-right: 4px;
    margin-left: 4px; */

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;

    
    font-weight: 400;
    font-size: 19px;

    background-color: #EBEBEB;
    opacity: 0.6;

    &:hover {
        background-color: #EBEBEB;
        opacity: 0.6;
        }
    
    &:disabled {
        background-color: #fff;
        color: #fff;
        }

    }
    
`;

const FAIcon = styled(FontAwesomeIcon)`
    && {
    font-size: 22px;
    font-weight: 400;
    }
`;



const TitleHeadline = styled.div`
    
    padding: 12px 24px 24px 0px;

    font-family: 'Fira Sans';
    font-size: 22px;
    font-weight: 500;

    color: #333333;

`;



const OrangeCheckBox = withStyles({
    root: {
      color: orange[400],
      '&$checked': {
        color: orange[600],
      },
    },
    checked: {},
  })(props => <Checkbox color="default" {...props} />);



const LabelStyled = styled.div`

    width: 100%;
    margin: 0px;
    padding: 0px;
    color: #333333;
    font-size: 16px;

    margin-bottom: 4px;
    margin-top: 0px;
    margin-left: 24px;

`;


const colourStyles = {

    control: (styles, {isDisabled, isFocused}) => ({ ...styles, 
        
        margin: 0,
        marginLeft: '24px',
        height: 42,
        paddingTop: 2,
        
        fontSize: 16,
        fontFamily: 'Fira Sans',
        fontWeight: '400',
        
        
        backgroundColor: isDisabled ? '#f7f7f7' : null,
        color: '#333333',
        
        borderRadius: 4,
        border: isFocused ? '2px solid #4C9AFF' : '1px solid #cbcbcb',
        boxShadow: '0 2px 8px 0 rgba(170, 170, 170, 0.2);',
        //boxShadow: isFocused ? '0 2px 8px 0 rgba(76, 154, 255, 0.8)' : '0 2px 8px 0 rgba(170, 170, 170, 0.2)',

        }),
    
    singleValue: base => ({
        ...base,
        paddingTop: 2,
        color: '#333333',
        marginLeft: 0,
        }),
    
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        
        return {
            ...styles,

            cursor: isDisabled ? 'not-allowed' : 'default',
        
            fontSize: 16,
            fontFamily: 'Fira Sans',
            fontWeight: '400',
            height: 42,

            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',


            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && ('#4C9AFF'),

            },


        };
    },
    menu: base => ({
        ...base,
        // color: '#333333',
        
        borderRadius: 4,
        border: '2px solid #4C9AFF',
        boxShadow: '0 2px 8px 0 rgba(170, 170, 170, 0.2);',

        marginLeft: '24px',

        width: 'calc(100% - 24px);',


    }),

}

