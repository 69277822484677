
import React from 'react';  
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import './../../../i18n'

import { Divider } from "../../components/Divider";
import '../../../styles/ModalPopup.css';    


class ProfileSelectionMenu extends React.Component {  

    render() {  

        return (  
            
            <>
            
                <div className='react-confirm-alert-overlay'>
                
                    <div className='react-confirm-alert-body'>
                            
                        <StyledHeader>What kind of profile you would like to add?</StyledHeader>

                        <SelectionArea>
                            
                            <SelectionButton
                                    onClick={() => {
                                        this.props.createNewProfile("photog");
                                    }}
                                    >
                                    Photographer
                            </SelectionButton>

                            <Spacing/>

                            <SelectionButton
                                    onClick={() => {
                                        this.props.createNewProfile("modell");
                                    }}
                                    >
                                    Model
                            </SelectionButton>

                            <SelectionButton
                                    onClick={() => {
                                        this.props.createNewProfile("muah");
                                    }}
                                    >
                                    Makeup, Hair, and/or Stylist
                            </SelectionButton>

                            <Spacing/>
                            
                            <SelectionButton
                                    onClick={() => {
                                        this.props.createNewProfile("studio");
                                    }}
                                    >
                                    Studio
                            </SelectionButton>

                            

                        </SelectionArea>

                        <Divider />
                        

                        <ButtonArea>
                                                            
                            <StyledCancel
                                onClick={() => {
                                    this.props.closePopup();
                                }}
                                >
                                Cancel
                            </StyledCancel>

                        </ButtonArea>
                        

                    </div>

                        

                </div>

            </>

        );  
    }  
    
}  

export default ProfileSelectionMenu;

const Spacing = styled.div`

    width: 24px;
    display: absolute;
    
`;

const SelectionArea = styled.div`

    width: 100%;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    
`;

const SelectionButton = styled.div`

    width: calc(50% - 44px);

    margin: 0px 0px 16px 0px;
    padding: 16px 16px 16px 16px;

    border-radius: 8px;
    
    justify-content: center;
    align-items: center;
    text-align: center;

    cursor: pointer;

    background: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.3);

    font-size: 17px;
    font-weight: 400;

    color: #333333;

    &:hover {
        background: #f8f8f8;
        }
    
`;


const StyledHeader = styled.div`
  width: 100%;
  
  margin: 0px 0px 16px 0px;
  padding: 0px;

  color: #333333;
  font-weight: 400;
  font-size: 21px;

  
`;

const ButtonArea = styled.div`

    color: #333333;
    align-items: flex-end;

    margin: 0px 0px 16px 0px;

`;


const StyledCancel = styled(Button)`
    && {
    height: 40px;
    top: 18px;
    color: #333333;
    width: 100%;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    }
    
`;

