
import React, { PureComponent } from 'react'
import { Form as AntForm } from "antd";
import { withFormik, Field, Form } from "formik";

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

import "./ChangePassword.css";

import toast from 'toasted-notes' 
import 'toasted-notes/src/styles.css';

import { changePasswordSchema } from "@psd/common";

import { MyInputField } from "../../components/MyInputField";
import { MyH1 } from "../../components/MyH1";
import { MyP } from "../../components/MyP";
import { MyError } from "../../components/MyError";
import { MyButton } from "../../components/MyButton";


const FormItem = AntForm.Item;

class C extends PureComponent {

    componentDidMount() {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }

    render() {

    const { loadingVisible } = this.props.state;

    return (
     

        <div className="ForgotWrapper">

            <Helmet>
                <title>Change your PhotoSouls password</title>
                <meta name="description" content="Change your PhotoSouls password" />
                <meta name="keywords" content="photosouls,photographers,models,workshops" />
            </Helmet>
        

            <div className="ForgotRightSideWrapper">


                <MyH1 headline="Change your password" />
                <MyH1 headline="-" />

                <MyP
                    p="Enter the new password you would like to use with your PhotoSouls.com account."
                />
            
                <Form>
                    
                    <Field
                        name="newPassword"
                        label="Password"
                        type="password"
                        component={MyInputField}
                    />
                        
                    {
                        this.props.errors.eMessage ? (
                            <MyError
                                message={this.props.errors.eMessage}
                                />
                            ): null
                    }

                    <FormItem>

                        <MyButton
                            type="submit"
                            caption='Change the password'
                            loading={loadingVisible}
                            />

                    </FormItem>

                </Form>

            </div>

        </div>

    )
  }
}



export const ChangePasswordView = withFormik({
    
    validationSchema: changePasswordSchema,
    
    mapPropsToValues: () => ({ newPassword: ""}),
    
    handleSubmit: async ({newPassword}, { props, setErrors }) => {
    
            
        props.updateLoader(true);

        try {

            //.log('ahsoo')
            const apiResponse = await props.submit({newPassword, key: props.token});

            //console.log('apiResponse ', apiResponse)

            props.updateLoader(false);

            //kindlasti handle error eespool, kui result. mudu kui resulti pole (on errotext), siis tuleks päris error...
            if (apiResponse.responseType === "error") {

                setErrors({ eMessage: apiResponse.message, });
                
            }
            else if (apiResponse.data.forgotPasswordChange.sessionId) {          

                toast.notify(
                    <div>Your password is now changed and you are logged in</div>, 
                    { duration: 5000 }
                    );

                props.onFinish();


            }


        } catch (e) {
                           
            props.updateLoader(false);
            
            // if (e.graphQLErrors[0].message === "APIErorr") {

            //     setErrors({ eMessage: e.graphQLErrors[0].data[0].message, });

            // } else {

                setErrors({ eMessage: "Unable to connect to PhotoSouls. Please try again later", });

            // }


        }

    }
  })(C);
  