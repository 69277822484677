import React, { PureComponent } from 'react'

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

import "./index.css";
import { FooterSection } from "./_footerSection";

import styled from 'styled-components';


export default class FindTalent extends PureComponent {

    componentDidMount() {
        
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

    }

    render() {

        return (
            
            <>

                <Helmet>
                    <title>Find and book talent in PhotoSouls</title>
                    <meta name="description" content="Book talents in PhotoSouls - Photographers, Models, Stylists, tc." />
                    <meta name="keywords" content="book,talent, photographers, models, stylists" />
                </Helmet>


               
                <Section8>

                    <RowCenter>
                        <H2>Who or what are you looking for?</H2>

                        <H2Desc> </H2Desc>

                        <BoxWrapper onClick={ () => this.props.history.push({ pathname: '../photographers' }) }>
                            <TalentImg src={'../images/web/photog-360.png'} alt="Collage of photos" />
                            <BoxContent>
                            Photographers
                            </BoxContent>
                        </BoxWrapper>

                        <BoxWrapper onClick={ () => this.props.history.push({ pathname: '../models' }) }>
                            <TalentImg src={'../images/web/model-360.png'} alt="Collage of photos" />
                            <BoxContent>
                            Models
                            </BoxContent>
                        </BoxWrapper>

                        <BoxWrapper onClick={ () => this.props.history.push({ pathname: '../muahs' }) }>
                            <TalentImg src={'../images/web/makeup-360.png'} alt="Collage of photos" />
                            <BoxContent>
                            Makeup artists / stylists
                            </BoxContent>
                        </BoxWrapper>

                        <BoxWrapper onClick={ () => this.props.history.push({ pathname: '../studios' }) }>
                            <TalentImg src={'../images/web/studio-360.png'} alt="Collage of photos" />
                            <BoxContent>
                            Studio to rent
                            </BoxContent>
                        </BoxWrapper>

                        <BoxWrapper onClick={ () => this.props.history.push({ pathname: '../events' }) }>
                            <TalentImg src={'../images/web/tour-360.png'} alt="Collage of photos" />
                            <BoxContent>
                            Photo tour or workshop
                            </BoxContent>
                        </BoxWrapper>

                    </RowCenter>

                </Section8>



                {/* Footer */}
                <FooterSection/>
                    
            </>

        )
    }
}


const Section8 = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;

    background-color: #fff;

    position: relative;
    z-index: 1;
    min-height: 400px;

    @media (max-width: 980px) {
        
        min-height: 600px;

    }

`;


const RowCenter = styled.div`
    
    width: 1170px;

    margin: 0 auto;
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    text-align: center;
    justify-content: center;
    align-items: center;

    padding-top: 24px;
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 980px) {
        
        /* margin-top: 0px; */

    }

`;

const H2 = styled.h2`

    color: #ff9500;
    font-family: 'Fira Sans';
    font-size: 36px;
`;

const H2Desc = styled.div`
    
    width: 100%;

    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    padding-top: 0px;
    padding-bottom: 32px;

    text-align: center;
    justify-content: center;
    align-items: flex-start;

    /* background-color: #ded; */

    font-size: 22px;
    font-family: 'Fira Sans';
    font-weight: 400;


    @media (max-width: 980px) {
        
        /* margin-top: 0px; */

    }

`;


const BoxWrapper = styled.div`
    
    width: 250px;
    height: 250px;

    /* background-color: #feb; */

    margin: 0 auto;

    margin-bottom: 24px;

    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    text-align: center;
    justify-content: center;
    align-items: flex-start;

    border-radius: 8px;
    border: 1px solid #EBEBEB;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    &:hover {
        transform: scale(1.03);
        cursor: pointer;
    }

`;


const BoxContent = styled.div`
    
    font-size: 22px;
    font-family: 'Fira Sans';
    font-weight: 400;

    margin-left: 12px;
    margin-right: 12px;

`;


const TalentImg = styled.img`

    margin-top: 14px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 0px;

    width: 220px;
    height: 140px;

    

`;

