import React, { PureComponent } from 'react'
import { WithViewMuah, WithEditMuah } from "@psd/controller";
import { MuahForm, defaultMuahFormValues } from "./Muah/MuahForm";

import { withStore } from '@spyna/react-store'
import { withApollo } from "react-apollo";
import { withRouter } from 'react-router-dom';

import "./profiles.css";

class C extends PureComponent {

    render() {

        const { 
            match: { params: { profileId } } 
        } = this.props;


        return (

            <WithViewMuah 
                
                profileId = {profileId}
                
            >

                {
                    
                    data => {
                        
                        if (data.muah.length === 0) {
                            return (
                                <div className="bodywrapper">
                                    <div className="settingsWrapper">
                                        <div className='settingsHeader'>Loading ...</div>
                                    </div>
                                </div>
                                )
                        }

                    
                        const { profileId: _, owner: ___, ...muah } = data.muah;
                        

                        return (

                            <WithEditMuah>
                                {
                                    ({ editMuah }) => (

                                        <MuahForm
                                        
                                            initialValues={{
                                                ...defaultMuahFormValues,
                                                ...muah
                                            }}

                                            submit = { async (values) => {
                                                
                                                //console.log('SUBMITTING - values.intro ', values.intro)
                                                //console.log('SUBMITTING - values.albums ', values.albums)

                                                // The problem with some fields is that they actually dont belong to the model (temp fields in form), 
                                                // or we do not want to alter them (set by other api actions)
                                                // so we can / MUST remove those from data, otherwise we get an error:
                                                delete values.__typename; //without this, Graphql will ERROR
                                                delete values.location.__typename; //without this, Graphql will ERROR
                                                for (var i = values.intro.length-1; i >= 0; i--) {
                                                    delete values.intro[i].__typename
                                                }

                                                for (var z = values.albums.length-1; z >= 0; z--) {
                                                    delete values.albums[z].__typename
                                                    for (var x = values.albums[z].name.length-1; x >= 0; x--) {
                                                        delete values.albums[z].name[x].__typename
                                                    }                                                
                                                }

                                                for (var r = values.packages.length-1; r >= 0; r--) {
                                                    delete values.packages[r].__typename
                                                    for (var t = values.packages[r].name.length-1; t >= 0; t--) {
                                                        delete values.packages[r].name[t].__typename
                                                    }    
                                                    for (var u = values.packages[r].description.length-1; u >= 0; u--) {
                                                        delete values.packages[r].description[u].__typename
                                                    }                                                
                                                }

                                                // for (var g = values.availability.length-1; g >= 0; g--) {
                                                //     delete values.availability[g].__typename
                                                //     delete values.availability[g].location.__typename
                                                // }

                                                delete values.userId; //not needed, as this will not change (and if it is included in the data, GQL will give error)
                                                delete values.createdAt;
                                                delete values.reports; 
                                                delete values.id;
                                                delete values.avatar; //this will be set by the API at the point of uploading. No form alteration is allowed !!! Otherwise path gets messed up
                                                delete values.publicURL // this cannot be altered!

                                                delete values.photoCount //don't touch! calculated at backend                                                

                                                //add or update hidden fields
                                                values.lastUpdated = new Date().toISOString()

                                                // console.log(values)
                                                
                                                await editMuah({
                                                    variables: {
                                                        profileId,
                                                        input: values
                                                    },
                                                    refetchQueries: ["viewMuah", "me"],
                                                    awaitRefetchQueries: true
                                                });

                                                
                                                this.props.history.push("/user/profiles");

                                            }
                                            }
                                        />
                                    )
                                
                                }

                            </WithEditMuah>
                        );
                    }
                }

            </WithViewMuah>
        );

    }
}


export const EditMuah = withRouter(withStore(withApollo(C)));
