import React, { useEffect, useState } from "react";
//import { PaymentElement, LinkAuthenticationElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";

import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import ScrollToTop from './../../routes/ScrollToTop';

export default function CheckoutForm(props) {

	const stripe = useStripe();
	const elements = useElements();

	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {

		document.getElementsByClassName('ReactModal__Content')[0].scrollTo(0, 0);

	}, []);


	useEffect(() => {
    
		if (!stripe) {
			return;
		}

		const clientSecret = new URLSearchParams(window.location.search).get(
			"payment_intent_client_secret"
		);

		if (!clientSecret) {
			return;
		}
    
		stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
			
			switch (paymentIntent.status) {

				case "succeeded":
					setMessage("Payment succeeded!");
					break;

				case "processing":
					setMessage("Your payment is processing.");
					break;

				case "requires_payment_method":
					setMessage("Your payment was not successful, please try again.");
					break;

				default:
					setMessage("Something went wrong.");
					break;

			}

		});

  	}, [stripe]);


	
	const handleSubmit = async (e) => {
		
		e.preventDefault();

		if (!stripe || !elements) {
			return; // Stripe.js has not yet loaded. Make sure to disable form submission until Stripe.js has loaded.
		}

		setIsLoading(true);

		if (props.instantBooking) {

			// console.log('instant payment')
	
			await stripe.confirmPayment({
				elements,
				confirmParams: {
					// Return URL where the customer should be redirected after the PaymentIntent is confirmed.
					return_url: 'https://www.photosouls.com/bookings',
				},
				redirect: "if_required", // prevents the automatic redirect to the return_url, which causes the issue where the promise is not handled (below, i can never handle the "succeeded" paymentintent (to post info to the database))
			})
			.then(function(result) {

				// console.log('result: ', result)

				if (result.paymentIntent) {

					if (result.paymentIntent.status === "succeeded") {
						props.postBooking(result.paymentIntent.id);
					}

				}

				if (result.error) {

					if (result.error.type === "card_error" || result.error.type === "validation_error") {
						setMessage(result.error.message);
					} else {
						setMessage("An unexpected error occurred.");
					}

				} 
					
			});

		} else {

			
			// console.log('charge later payment')

			await stripe.confirmSetup({
				elements,
				confirmParams: {
					// Return URL where the customer should be redirected after the PaymentIntent is confirmed.
					return_url: 'https://www.photosouls.com/bookings',
				},
				redirect: "if_required", // prevents the automatic redirect to the return_url, which causes the issue where the promise is not handled (below, i can never handle the "succeeded" paymentintent (to post info to the database))
			})
			.then(function(result) {

				// console.log('result: ', result)
				
				if (result.setupIntent) {

				 	if (result.setupIntent.status === "succeeded") {
				 		props.postBooking(result.setupIntent.id);
				 	}

				}

				if (result.error) {

					if (result.error.type === "card_error" || result.error.type === "validation_error") {
						setMessage(result.error.message);
					} else {
						setMessage("An unexpected error occurred.");
					}

				} 
					
			});

		}

		setIsLoading(false);
		

	};

	
	const paymentElementOptions = {
		layout: "tabs",
		// fields: {
		// 	billingDetails: {
		// 		address: {
		// 			country: 'never', //NB NB!! hiding country causes problems, as it is a required field for credit card processing.. and user so far might not have entered it anywhere else. so better have it here and ask... i mean, don't disable the asking :D
		// 			postalCode: 'never'
		// 		}
		// 	}
		// }
	}

	
	return (
		
		<>

			<ScrollToTop />

			{props.renderPriceInfo}

			<Section>

				<SectionTitle>
					
					<SectionHeadline>
						Pay with
					</SectionHeadline>

				</SectionTitle>

				<SectionBody>

					<IncreaseHeight8/>
					<form id="payment-form" onSubmit={handleSubmit}>
					
						{/* <LinkAuthenticationElement
							id="link-authentication-element"
							onChange={(e) => setEmail(e.target.value)}
						/> */}
						<PaymentElement id="payment-element" 
							options={paymentElementOptions} 
						/>
					
					</form>
				

				</SectionBody>

			</Section>


			{props.renderCancellationAndTerms}
		

			{message && <Section><ErrorArea>{message}</ErrorArea></Section>}


			<Section>
				<BtnBook 
					// onClick={ () => { this.validateBooking(); } }
					disabled={ isLoading || !stripe || !elements } 
					id="submit"
					form='payment-form' 
					type="submit"
				>
					{ isLoading ?
						<LocalImage src={require('./../../assets/whiteLoader.gif')} />
						: 
						props.instantBooking === true ? <>Confirm & Pay</> : <>Confirm & Book</>
					}
						
				</BtnBook>
			</Section>
			
			<Section> <IncreaseHeight32/></Section>
			<IncreaseHeight32/>
		
		</>

	);


}



const BtnBook = styled(Button)`
    && {
    min-height: 40px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    color: white;
    
    padding-left: 16px;
    padding-right: 16px;

    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    
    &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }

}
`;


const IncreaseHeight32 = styled.div`
    
    height: 32px;  
    min-height: 32px;

`;


const IncreaseHeight8 = styled.div`
    
    height: 8px;  
    min-height: 8px;

`;

const ErrorArea = styled.div`
    
	margin-left: 0px;

    margin-bottom: 8px;
    padding: 8px;
    
    font-size: 16px;
    font-weight: 400;
    
    color: #ffffff;
    background-color: #FF4343;
    border: 1px solid #f60000;
    box-shadow: 0 2px 8px 0 rgba(197, 12, 12, 0.2);
    
`;

const LocalImage = styled.img`
    height: 40px;
    width: 40px;
    margin: -2px auto auto auto;
`;

const Section = styled.div`
    
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    margin-left: 16px;
    margin-right: 0px;
    padding-right: 8px;
    
    width: calc(100% - 16px);

    align-items: flex-start;
    justify-content: flex-start;

    /* background-color: #fed;  */

    margin-bottom: 32px;

`;

const SectionTitle = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: center;
    justify-content: space-between;

    width: 100%;
    min-height: 40px;

    /* background-color: #ebe; */

`;


const SectionBody = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 300;
    font-size: 18px;

    line-height: 24px;

    color: ${(props) => props.error ? 'rgba(255, 0, 0, 0.9)' : '#505050' };

    white-space: pre-wrap;

    /* width: calc(100% - 112px); */
    width: 100%;
    

`;

const SectionHeadline = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 500;
    font-size: 20px;

    /* color: #505050; */
    /* color: #383838; */
    color: #333333;

    /* background-color: #ded; */

`;
