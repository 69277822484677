
import React, { Component } from 'react';
import styled from 'styled-components';

const StyledP = styled.p`
    margin-top: 24px;
    font-size: 16px;
    color: #333333;
`;


export class Divider extends Component {
    
  render() {
      
    const { p } = this.props;
    
    return (
      
      <StyledP>{p}</StyledP>
    
    );

  }
}
