
import React, { Component } from 'react';
import styled from 'styled-components';
// import Button from '@material-ui/core/Button';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faStar, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

// import getSymbolFromCurrency from 'currency-symbol-map'

import moment from "moment"; 
import i18next from "i18next";
import '../../i18n'


export class MessageListItem extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
        }
    }



    render() {
      

        moment.locale(i18next.language);

        // const now = moment(new Date()); //todays date
        // const messageDate = moment(this.props.message.lastMessageDt/1000*1000)
        const messageDateISO = new Date(parseInt(this.props.message.lastMessageDt))

        // let lastByCreator = true;
       
        let avatarImage = this.props.message.fromAvatar
        let messageSenderName = this.props.message.fromName
        let messageUnread = '0';

        if (this.props.message.myUserId === this.props.message.fromUserId) {
            avatarImage = this.props.message.toAvatar
            messageSenderName = this.props.message.toName
            if (this.props.message.fromIsUnread) {
                messageUnread = '1'
            };
        }

        const messageTimeAgo = moment(messageDateISO).fromNow()

        // console.log(this.props)
        let activeMessage = '0'
        if (this.props.message.messageId === this.props.selectedMessageId) {
            activeMessage = '1';
        }




        return (
        
            <>

                <MessageRow 
                    key={this.props.index}
                    onClick={this.props.onClick}
                    activeMessage={activeMessage}
                    isMobile={this.props.isMobile}
                >

                    <MessageSender>

                        <AvatarArea>
                            { 
                                avatarImage ? 
                                    <Avatar 
                                        src={avatarImage} 
                                        alt="Avatar" 
                                        onLoad={(e) => {
                                            //e.target.src = values.avatar --- causes huge CPU spike. but looks like not necessary anyway!
                                            e.target.style = 'display: flex' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                        }}
                                        onError={(e) => {
                                            //e.target.src = '' /--- causes huge CPU spike. but looks like not necessary anyway!
                                            e.target.style = 'display: none' // inline styles in html format. for example: 'padding: 8px; margin: 16px;'
                                        }}

                                    /> : <></>
                            } 
                        </AvatarArea>

                        <MemberInfoCard>

                            <CardTitle>{messageSenderName}</CardTitle>
                            <SubTitle>{messageTimeAgo}</SubTitle>
                                
                        </MemberInfoCard>
                    
                    </MessageSender>



                    <MessagePreview
                        messageUnread={messageUnread}
                    >
                        {this.props.message.lastMessage}
                    </MessagePreview>


                </MessageRow> 

            </>
        
        );

    }


}






const MessageRow = styled.div`
        
    width: calc(100% - 16px);
    /* height: 96px; */

    padding: 8px 8px 8px 8px;

    align-items: flex-start;
    
    border-bottom: 1px solid rgba(170, 170, 170, 0.3);

    /* background-color: ${(props) => props.isSelected === true ? 'rgba(170, 170, 170, 0.1)' : props.activeMessage === '1' ? '#fadfb2' : '#ffffff'}; */
    background-color: ${(props) => props.isMobile === '1' ? '#ffffff' : props.activeMessage === '1' ? '#fdf4e5' : '#ffffff'};

    &:hover {
        /* transform: scale(1.02); */
        background-color: rgba(170, 170, 170, 0.2);
        cursor: pointer;
    }


`;


const MessageSender = styled.div`
            
    font-size: 16px;
    font-family: 'Fira Sans';
    font-weight: 400;

    padding-right: 16px;

    /* background-color: #aee; */

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;    

    padding-right: 32px;
    margin-top: 4px;
    margin-bottom: 12px;

`;



const MessagePreview = styled.div`

    /* font-style: italic; */
    font-size: 16px;
    font-family: 'Fira Sans';
    /* font-weight: ${(props) => props.messageUnread === '1' ? '500' : '300'}; */
    font-weight: 400;

    line-height: 1.5em;
    max-height: 3em;       /* height is 2x line-height, so two lines will display */
    overflow: hidden;

    display: inline-block;
    vertical-align: middle;

    padding-right: 16px;

    /* background-color: #aea; */

    margin-bottom: 4px;

`;



const MemberInfoCard = styled.div`
    
    padding-left: 16px;
    width: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
        
`;


const AvatarArea = styled.div`
    
    width: 64px; 
    margin: auto;

    margin-top: 0;

    
    /* float: left */

`;

const Avatar = styled.img`

    height: 64px;
    width: 64px;
    border-radius: 64px;
    
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.4);

`;


const CardTitle = styled.div`
    
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 2px;
    /* @media (max-width: 790px) {
        display: none;
        } */

`;

const SubTitle = styled.div`
    
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5em;
    
    /* @media (max-width: 790px) {
        display: none;
        }
     */

`;


