/* eslint-disable no-lone-blocks */
import React, { PureComponent } from 'react'
import { withStore } from '@spyna/react-store'

import Header from './Header'
import getIPInfo from './../../../utils/getIPInfo'
import moment from "moment"; 

import gql from "graphql-tag";
import { Query } from "react-apollo";
import { CLOUDFRONT_SERVER } from '../../../config';

const meQuery = gql`
    {
        me { id, 
            fName, lName, bDay, email, phone, phoneDisplay, phoneCountry, 
            avatar, pwdUpdated,
            chkReminders, chkRemindersP, chkMessages, chkMessagesP, chkRecomm, chkRecommP, chkInsp, chkInspP, chkLocal, chkLocalP,
            chkHow, chkHowP, chkInvite, chkInviteP, chkSurvey, chkSurveyP, chkPolicy, chkPolicyP, chkTips, chkTipsP,
            currency,
            chkBoudoir, chkCouples, chkFamily, chkFashion, chkKids, chkLifestyle, chkMaternity, chkNewborn, chkPortrait, chkWedding,
            chkLandscape, chkNature, chkPets, chkWildlife, chkArchitecture, chkEvent, chkFood, chkProduct, chkRealestate, chkSport, chkStreet,
            chkNSFW, chkLocationSharing, isPro, StripeExpressID,
            
        }
    }
`;

// 
            

class HeaderDecider extends PureComponent {

    state = {
        ready4next: false,
    };


    async componentDidMount() {
        


        let displayCurrency = 'USD';
        // -- LOAD DEFAULTS
        // -- for some fields it's required, for some not important (in that case ignore and don't)
        //this.props.store.set('fName', '')
        //this.props.store.set('lName', '')
        if ((localStorage.getItem('ipCurrency') !== '') && (localStorage.getItem('ipCurrency') !== 'null')) {

            displayCurrency = localStorage.getItem('ipCurrency')

        } else {

            let ipData
            const today = new Date();

            ipData = await getIPInfo(); 
            
            let ipAddress
            if (ipData.city !== ipData.region) {
                ipAddress = ipData.city + ', ' + ipData.region
            } else {
                ipAddress = ipData.city
            }
            ipAddress = ipAddress + ', ' + ipData.country

            //save ip date to the local storage immediatelly after geolocated!
            localStorage.setItem('ipCity', ipData.city);
            localStorage.setItem('ipRegion', ipData.region);
            localStorage.setItem('ipCountry', ipData.country);
            localStorage.setItem('ipCurrency', ipData.currency);
            localStorage.setItem('ipLng', ipData.lng);
            localStorage.setItem('ipLat', ipData.lat);
            localStorage.setItem('ipAddress', ipAddress);
            localStorage.setItem('ipLocatedDate', moment(today).format('YYYY-MM-DD'));
            displayCurrency = ipData.currency

        }

        
        //this.props.store.set('gender', '0'); //Required at least some default to prevent error
        this.props.store.set('currency', displayCurrency); //Required at least some default to prevent error. Later the value is actually retrieved from the base
        this.props.store.set('bDay', '2000-01-01T00:00:00.000+0000')  //Required at least some default to prevent error
        
        this.setState({
            ready4next: true,
            ipCurrency: displayCurrency,
        });

        //this.props.store.set('email', '')
        //this.props.store.set('phone', '')
        //this.props.store.set('phoneDisplay', '')
        //this.props.store.set('phoneCountry', '')
        //this.props.store.set('avatar', '')

        // console.log('did mount')
        
    }


    loadMeData = async (meData) => {
        
        // -- Load all profile data, preferences & settings
        //console.log('meData: ', meData)
        
        this.props.store.set('userId', meData.id ? meData.id : 'avatar')
        this.props.store.set('fName', meData.fName ? meData.fName : '')
        this.props.store.set('lName', meData.lName ? meData.lName : '')
        this.props.store.set('bDay', meData.bDay ? meData.bDay : '2000-01-01T00:00:00.000+0000') 

        this.props.store.set('email', meData.email ? meData.email : '')

        this.props.store.set('phone', meData.phone ? meData.phone : '') //'+3725077207'
        this.props.store.set('phoneDisplay', meData.phoneDisplay ? meData.phoneDisplay : '') //'+372 507 7207'
        this.props.store.set('phoneCountry', meData.phoneCountry ? meData.phoneCountry : '') //ee

        this.props.store.set('avatar', meData.avatar ? meData.avatar : CLOUDFRONT_SERVER + '/avatar/latinized/A.jpg')

        this.props.store.set('pwdUpdated', meData.pwdUpdated ? meData.pwdUpdated : '')


        this.props.store.set('chkReminders', meData.chkReminders ? meData.chkReminders : 'true')
        this.props.store.set('chkRemindersP', meData.chkRemindersP ? meData.chkRemindersP : 'true')
        this.props.store.set('chkMessages', meData.chkMessages ? meData.chkMessages : 'true')
        this.props.store.set('chkMessagesP', meData.chkMessagesP ? meData.chkMessagesP : 'true')
        this.props.store.set('chkRecomm', meData.chkRecomm ? meData.chkRecomm : 'true')
        this.props.store.set('chkRecommP', meData.chkRecommP ? meData.chkRecommP : 'true')
        this.props.store.set('chkInsp', meData.chkInsp ? meData.chkInsp : 'true')
        this.props.store.set('chkInspP', meData.chkInspP ? meData.chkInspP : 'true')
        this.props.store.set('chkLocal', meData.chkLocal ? meData.chkLocal : 'true')
        this.props.store.set('chkLocalP', meData.chkLocalP ? meData.chkLocalP : 'true')
        this.props.store.set('chkHow', meData.chkHow ? meData.chkHow : 'true')
        this.props.store.set('chkHowP', meData.chkHowP ? meData.chkHowP : 'true')
        this.props.store.set('chkInvite', meData.chkInvite ? meData.chkInvite : 'true')
        this.props.store.set('chkInviteP', meData.chkInviteP ? meData.chkInviteP : 'true')
        this.props.store.set('chkSurvey', meData.chkSurvey ? meData.chkSurvey : 'true')
        this.props.store.set('chkSurveyP', meData.chkSurveyP ? meData.chkSurveyP : 'true')
        this.props.store.set('chkPolicy', meData.chkPolicy ? meData.chkPolicy : 'true')
        this.props.store.set('chkPolicyP', meData.chkPolicyP ? meData.chkPolicyP : 'true')
        this.props.store.set('chkTips', meData.chkTips ? meData.chkTips : 'true')
        this.props.store.set('chkTipsP', meData.chkTipsP ? meData.chkTipsP : 'true')

        this.props.store.set('currency', meData.currency ? meData.currency : this.state.ipCurrency)

        this.props.store.set('chkBoudoir', meData.chkBoudoir ? meData.chkBoudoir : 'true')
        this.props.store.set('chkCouples', meData.chkCouples ? meData.chkCouples : 'true')
        this.props.store.set('chkFamily', meData.chkFamily ? meData.chkFamily : 'true')
        this.props.store.set('chkFashion', meData.chkFashion ? meData.chkFashion : 'true')
        this.props.store.set('chkKids', meData.chkKids ? meData.chkKids : 'true')
        this.props.store.set('chkLifestyle', meData.chkLifestyle ? meData.chkLifestyle : 'true')
        this.props.store.set('chkMaternity', meData.chkMaternity ? meData.chkMaternity : 'true')
        this.props.store.set('chkNewborn', meData.chkNewborn ? meData.chkNewborn : 'true')
        this.props.store.set('chkPortrait', meData.chkPortrait ? meData.chkPortrait : 'true')
        this.props.store.set('chkWedding', meData.chkWedding ? meData.chkWedding : 'true')
        this.props.store.set('chkLandscape', meData.chkLandscape ? meData.chkLandscape : 'true')
        this.props.store.set('chkNature', meData.chkNature ? meData.chkNature : 'true')
        this.props.store.set('chkPets', meData.chkPets ? meData.chkPets : 'true')
        this.props.store.set('chkWildlife', meData.chkWildlife ? meData.chkWildlife : 'true')
        this.props.store.set('chkArchitecture', meData.chkArchitecture ? meData.chkArchitecture : 'true')
        this.props.store.set('chkEvent', meData.chkEvent ? meData.chkEvent : 'true')
        this.props.store.set('chkFood', meData.chkFood ? meData.chkFood : 'true')
        this.props.store.set('chkProduct', meData.chkProduct ? meData.chkProduct : 'true')
        this.props.store.set('chkRealestate', meData.chkRealestate ? meData.chkRealestate : 'true')
        this.props.store.set('chkSport', meData.chkSport ? meData.chkSport : 'true')
        this.props.store.set('chkStreet', meData.chkStreet ? meData.chkStreet : 'true')

        
        this.props.store.set('chkNSFW', meData.chkNSFW ? meData.chkNSFW : 'false')

        //if (meData.chkLocationSharing !== '-') { 
        this.props.store.set('chkLocationSharing', meData.chkLocationSharing ? meData.chkLocationSharing : 'false')
        //}

        this.props.store.set('isPro', meData.isPro ? true : false)
        this.props.store.set('StripeExpressID', meData.StripeExpressID ? meData.StripeExpressID : '')
        this.props.store.set('isVerified', meData.isVerified ? true : false)

    }


    render() {

        let avatar = this.props.store.get('avatar');
        let isLoggedIn = "false";

        // console.log('0: ' + window.location.pathname)

        return (

            <div>

                { this.state.ready4next ?

                    <>

                        <Query 
                            asyncMode 
                            query={meQuery} 
                            onCompleted={data => {
                                
                                // console.log(data);

                                if (data === undefined) {

                                    // do nothing. api error? 
                                    // console.log('HeaderDecider: Query data undefined', data)
                                        
                                } else if (data.me === null) {

                                    // console.log('HeaderDecider: data.me is null (pid cookie invalid)', data)
                                    // window.location.href = '/user/logout';


                                } else if (JSON.stringify(data).length > 15) {

                                    //console.log('JSON longer than 15', data)
                                    this.loadMeData(data.me)

                                    avatar = data.me.avatar //Must be set additionally! otherwise header not working properly

                                } else {
                                    
                                    // unknown case. Just in case fallback to this.
                                    // console.log('HeaderDecider: fallback')


                                }
                            
                            }
                            }
                        >
            
                            {({ loading, error, data }) => {

                                {/* console.log('heihoo', loading, error, data) */}
                                

                                    if (loading) return (<></>);
                                    
                                    if (error) { 
                                        
                                        if (error.message === 'Network error: Failed to fetch') {
                                            
                                            //console.log('SERVER ERROR:', error.message);
                                            
                                            this.props.store.set('loggedin', 'false')
                                            return (<Header loggedinuser="false"/>)

                                        } else {

                                            // This error, looks like, appears only when the user session is lost (or server is down). 
                                            // It can be re-produced in the way that the user you are logged in with, you delete the user in MongoDB and relaod the page. 
                                            // then without location.href redirect to logout (actual log out), the site does not load at all.
                                            // but with logout, we eliminate the incorrect session info.
                                            window.location.href = '/user/logout';
                                        }

                                    }
                                    
                                    if (data === undefined) {

                                        //api error ?? do nothing...    
                                        
                                    } else
                                    if (JSON.stringify(data).length > 15) {

                                        isLoggedIn = "true"
                                        
                                        // #NB avatar must be set in order for Header to work properly!
                                        avatar = data.me.avatar
                                        
                                        {/* console.log('Query component responded with data') */}
                                        {/* console.log('1: ' + window.location.pathname) */}
                                        //window.location.pathname === '/sitemap' ? return null

                                        this.props.store.set('loggedin', isLoggedIn)

                                        

                                        return (<Header loggedinuser={isLoggedIn} avatar={avatar}/>)
                                    
                                    } else {
                                        {/* console.log('Query component responded - No data') */}
                                        {/* console.log('2: ' + window.location.pathname) */}

                                        this.props.store.set('loggedin', 'false')
                                        return (<Header loggedinuser="false"/>)

                                    } 
                                
                            }}
                        </Query> 

                    </>
                    :
                    null

                }


            </div>  

        )
    }

}


export default withStore(HeaderDecider, [])
