import React, { PureComponent } from 'react'

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

export class Feedback extends PureComponent {

    componentDidMount() {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }

    render() {
        return (
            
            <>

                <Helmet>
                    <title>Feedback</title>
                    <meta name="description" content="feedback" />
                    <meta name="keywords" content="photosouls,feedback" />
                </Helmet>

                <div className="bodywrapper">
                    <div>User feedback page</div>

                </div>

            </>

        )
    }
}
