import React, { PureComponent } from 'react'
import { withApollo } from "react-apollo";

import styled from 'styled-components';

import ReactQuill from 'react-quill';
import "./../../../../styles/quill.css";

import ISO6391 from 'iso-639-1';


class C extends PureComponent { 

    state = {
        lngCount: this.props.values.languages.length,
        lngCurrent: 0,
        lngCurrentCode: '',
        currentDefaultText: '',
    };

    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    findLngIndex = (lng) => {

        let foundIndex = 0;
        for (var i = this.props.values.intro.length-1; i >= 0; i--) {
            if (this.props.values.intro[i].lng === lng) {
                foundIndex = i;
            }
        }

        return foundIndex

    }

    fldValueChange = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }
    
    render() {

        const modules = {

            toolbar: [

                //[{ 'header': [1, 2, false] }],
                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                //[{ 'size': ['16px', '18px', '20px', '22px'] }],
                //['bold', 'italic', 'underline','strike', 'blockquote'],
                ['bold', 'italic'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                [
                    {'color': 
                        // eslint-disable-next-line no-sparse-arrays
                        [
                        '#000000','#424242','#656565','#999999','#b7b7b7','#cbcbcb','#d9d9d9','#efefef','#f3f3f3','#ffffff',
                        '#8b1910','#ea3223','#f29d38','#fffd54','#74f84b','#73fbfd','#5686e1','#0023f5','#8a2ef5','#ea3ff7',
                        '#deb9b0','#edcdcc','#f8e5cf','#fdf1d0','#dbe9d4','#d2dfe2','#cbdaf5','#d2e1f1','#d7d2e7','#e6d1db',
                        '#d0816f','#de9c9a','#f1cba1','#fbe4a2','#bcd5ac','#a8c3c7','#a8c2ef','#a6c4e5','#b1a8d2','#cda8bb',
                        '#bd4a31','#d16d69','#ecb376','#f9d977','#9dc184','#7fa4ad','#769ee5','#7aa7d7','#8a7ebd','#b87e9e',
                        '#992a14','#bb2619','#da934b','#e9c251','#78a559','#537f8c','#4879d1','#4e84c0','#6251a1','#9b5377',
                        '#7a2816','#8c1a10','#a96224','#b8902f','#48732c','#254e5a','#2357c4','#22538f','#302070','#6a2345',
                        '#531606','#5d0d08','#704115','#7a601c','#304c1b','#16333b','#254582','#153760','#1d144a','#45162e',
                        ]
                    }, 
                    { 'background': 
                        [
                        '#000000','#424242','#656565','#999999','#b7b7b7','#cbcbcb','#d9d9d9','#efefef','#f3f3f3','#ffffff',
                        '#8b1910','#ea3223','#f29d38','#fffd54','#74f84b','#73fbfd','#5686e1','#0023f5','#8a2ef5','#ea3ff7',
                        '#deb9b0','#edcdcc','#f8e5cf','#fdf1d0','#dbe9d4','#d2dfe2','#cbdaf5','#d2e1f1','#d7d2e7','#e6d1db',
                        '#d0816f','#de9c9a','#f1cba1','#fbe4a2','#bcd5ac','#a8c3c7','#a8c2ef','#a6c4e5','#b1a8d2','#cda8bb',
                        '#bd4a31','#d16d69','#ecb376','#f9d977','#9dc184','#7fa4ad','#769ee5','#7aa7d7','#8a7ebd','#b87e9e',
                        '#992a14','#bb2619','#da934b','#e9c251','#78a559','#537f8c','#4879d1','#4e84c0','#6251a1','#9b5377',
                        '#7a2816','#8c1a10','#a96224','#b8902f','#48732c','#254e5a','#2357c4','#22538f','#302070','#6a2345',
                        '#531606','#5d0d08','#704115','#7a601c','#304c1b','#16333b','#254582','#153760','#1d144a','#45162e',
                        ] 
                    }
                ],
                [{ 'align': [] }],
                //['link', 'image'],
                ['clean'],

            ],

        }
        
        const formats = [

            'header', 'font', 'background', 'color', 'code', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent', 'script', 'align', 'direction',
            'link', 'image', 'code-block', 'formula', 'video'

        ]


        // let lngArr = []
        this.props.values.languages.map((language, index) => {
         
            // const languageName = this.capitalize(ISO6391.getNativeName(language));
            // lngArr.push({value: index, code: language, label: languageName})

            //make sure for this index, we have an object in the intro array
            if (this.props.values.intro[index]) {}
            else {
                this.props.values.intro.push({lng: language, intro: ''})
            }

            // console.log(this.props.values.intro)
            
            return null
        })


        return (

           <PageContent>
                
                <PageTitle>Introduction</PageTitle>
                
                { // generate Photo title fields for each language
                    this.props.values.languages.map((language, index) => {

                        const fldHeadlineLng = 'headline_' + language;
                        const fldSummaryLng = 'summary_' + language;
                        
                        return (

                            <div key={index}>
                            
                                <LabelStyled>Profile headline {this.props.values.languages.length > 1 ? '(' + this.capitalize(ISO6391.getNativeName(language))+')' : '' }</LabelStyled>
                                <InputStyled
                                    name={fldHeadlineLng}
                                    autoCapitalize = 'none' 
                                    autoComplete = 'off'
                                    defaultValue={this.props.values.intro[this.findLngIndex(language)].headline}
                                    onChange={this.fldValueChange}
                                    onBlur={ async () => {

                                        let existed = false;
                                        for (var i = this.props.values.intro.length-1; i >= 0; i--) {
                                            if (this.props.values.intro[i].lng === language) {

                                                existed = true;
                                                this.props.values.intro[i].headline = this.state[fldHeadlineLng]

                                            }
                                        }

                                        // console.log('not found. add title: ', this.state[fldTitleLng])
                                        if (existed === false) {
                                            this.props.values.intro.push({lng: language, headline: this.state[fldHeadlineLng]})
                                        }
                                        

                                    }}
                                />
                                <IncreaseHeight/>

                                <LabelStyled>Introduction {this.props.values.languages.length > 1 ? '(' + this.capitalize(ISO6391.getNativeName(language))+')' : '' }</LabelStyled>
                                <ReactQuill
                                    key={index}
                                    onChange={(html) => {
                                        this.props.values.intro[this.findLngIndex(language)].intro = html;
                                    }} 
                                    modules={modules}
                                    formats={formats}
                                    defaultValue={this.props.values.intro[this.findLngIndex(language)].intro}
                                    theme={"snow"}
                                />   
                                
                                <IncreaseHeight/>

                                <LabelStyled>Profile summary & call to action {this.props.values.languages.length > 1 ? '(' + this.capitalize(ISO6391.getNativeName(language))+')' : '' }</LabelStyled>
                                <TextareaStyled
                                    name={fldSummaryLng}
                                    rows={2}
                                    defaultValue={this.props.values.intro[this.findLngIndex(language)].summary}
                                    onChange={this.fldValueChange}
                                    onBlur={ async () => {

                                        let existed = false;
                                        for (var i = this.props.values.intro.length-1; i >= 0; i--) {
                                            if (this.props.values.intro[i].lng === language) {

                                                existed = true;
                                                this.props.values.intro[i].summary = this.state[fldSummaryLng]

                                            }
                                        }

                                        // console.log('not found. add title: ', this.state[fldTitleLng])
                                        if (existed === false) {
                                            this.props.values.intro.push({lng: language, summary: this.state[fldSummaryLng]})
                                        }

                                    }}
                                    // cols={5}
                                />
                                
                                <IncreaseHeight/>
                                
                            </div> 
                        );
                    })
                }

{/* 
                <Field
                    name="intro_languages"
                    label='Enter your introduction for the selected language. You can change the language and create the intro for each language you speak.'
                    component={MySelectField}

                    originalOptions={lngArr}
                    originalValue={0}
                    onChange={ (e) => {

                            let newDefaultText = '';
                            if (this.props.values.intro[e.value]) {
                                newDefaultText = this.props.values.intro[e.value].intro
                            }

                            setTimeout(() => {
                                this.setState({  
                                    lngCurrent: e.value,
                                    lngCurrentCode: lngArr[e.value].code,
                                    currentDefaultText: newDefaultText
                                }); 
                            }, 50)
            
                            //console.log(e)
                            //console.log('Field change. State is (lngCurrent, code, newDefaultText)', e.value, lngArr[e.value].code, newDefaultText)
                        } 
                    }
                    
                /> */}
                
                {/* <IncreaseHeight/>

                { 
                    //For each language, have it's own Quill
                    this.props.values.languages.map((language, index) => {

                        //console.log('state.lngCurrent', this.state.lngCurrent)

                        if (this.state.lngCurrentCode === language) {

                            let mitmesIntroElement = 0;
                            for (var i = this.props.values.intro.length-1; i >= 0; i--) {
                                if (this.props.values.intro[i].lng === this.state.lngCurrentCode) { 
                                    mitmesIntroElement = i
                                } 
                            }

                            return (
                                
                                <ReactQuill
                                    key={index}
                                    onChange={(html) => {
                                        this.props.values.intro[mitmesIntroElement].intro = html;
                                    }} 
                                    modules={modules}
                                    formats={formats}
                                    defaultValue={this.props.values.intro[mitmesIntroElement].intro}
                                    theme={"snow"}
                                />           

                            );
                        }

                        return null
                    })
                } */}


                

                <IncreaseHeight/>


            </PageContent>
            

        )

    }
    
}

export const IntroPage = (withApollo(C));



const IncreaseHeight = styled.div`
    
    height: 24px;  

`;

const PageContent = styled.div`
    
    width: 100%;
    /* height: 100%; */
    /* min-height: 75vh; */
    font-family: 'Fira Sans';
    
`;

const PageTitle = styled.div`
    
    display: flex;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;

    display: none;
    @media (max-width: 790px) {
        display: block;
        }


`;

const LabelStyled = styled.div`
    width: 100%;
    margin: 0px;
    padding: 0px;
    color: #333333;
    font-size: 16px;
    
    margin-bottom: 4px;
    margin-top: 0px;
    margin-left: 2px;
`;


const InputStyled = styled.input`
    
    width: calc(100% - 18px);
    padding: 0px;
    margin: 0px;
    height: 24px;
    padding: 8px;
    font-size: 16px;
    font-family: 'Fira Sans';

    color: #333333;
    
    border: 1px solid #cbcbcb;
    border-radius: 4px;  
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    :focus {
        outline-color: #4C9AFF;
    }

    :disabled {
        background-color: #f7f7f7; 
    }


    @media (max-width: 690px) {
        font-size: 17px;
    }
`;


const TextareaStyled = styled.textarea`
    
    width: calc(100% - 18px);
    padding: 0px;
    margin: 0px;
    /* height: 24px; */
    padding: 8px;
    font-size: 16px;
    font-family: 'Fira Sans';
    line-height: 1.2em;

    color: #333333;
    
    border: 1px solid #cbcbcb;
    border-radius: 4px;  
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    :focus {
        outline-color: #4C9AFF;
    }

    :disabled {
        background-color: #f7f7f7; 
    }

    @media (max-width: 690px) {
        font-size: 17px;
    }
`;