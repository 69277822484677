import React, { PureComponent } from 'react'

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

import { Link } from "react-router-dom";

import "./index.css";
import { FooterSection } from "./_footerSection";

import styled from 'styled-components';
// import Button from '@material-ui/core/Button';

import gql from "graphql-tag"
import { Query } from "react-apollo";



const getSitemap = gql`
    query ($page: String) {
        getSitemap(page: $page) {
            page, title, url,  
        }
    }
`;


export default class Sitemap extends PureComponent {

    componentDidMount() {
        
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

    }

    render() {

        return (
            
            <>

                { window.location.pathname === '/sitemap' ? 
                    <Helmet>
                        <title>Sitemap - PhotoSouls Photographers</title>
                        <meta name="description" content="The PhotoSouls sitemap includes links to some of our most popular photographers, organized by country and photoshoot types" />
                        <meta name="keywords" content="photosouls, sitemap, photographers" />
                    </Helmet>
                    : window.location.pathname === '/sitemap/models' ? 
                    <Helmet>
                        <title>Sitemap - PhotoSouls Models</title>
                        <meta name="description" content="The PhotoSouls sitemap includes links to some of our most popular models, organized by country and photoshoot types" />
                        <meta name="keywords" content="photosouls, sitemap, models" />
                    </Helmet>
                    : window.location.pathname === '/sitemap/help-pages' ? 
                    <Helmet>
                        <title>Sitemap of PhotoSouls Help Center Pages</title>
                        <meta name="description" content="Explore the PhotoSouls Help Center Sitemap for all our support pages and resources. Quickly find the assistance you need with our organized directory of pages." />
                        <meta name="keywords" content="photosouls, sitemap, help center, knowledge base" />
                    </Helmet>
                    : window.location.pathname === '/sitemap/common-pages' ?
                    <Helmet>
                        <title>Sitemap - PhotoSouls Most Common Pages</title>
                        <meta name="description" content="Navigate with ease using the PhotoSouls Sitemap, featuring direct links to our most visited pages. " />
                        <meta name="keywords" content="photosouls, sitemap, common pages" />
                    </Helmet>
                    :
                    <Helmet>
                        <title>Sitemap - PhotoSouls</title>
                        <meta name="description" content="Explore the complete sitemap of PhotoSouls. Our sitemap is your roadmap to discovering all facets of our photography community." />
                        <meta name="keywords" content="photosouls, sitemap" />
                    </Helmet>

                }

                <Section9>
                        
                    <RowCenter>

                        <Query query={getSitemap} variables={{'page': "/index" }} fetchPolicy="network-only">
                        {
                            ({ data }) => {
                                if (data === undefined) {
                                    //do nothing ??    
                                } else
                                if (JSON.stringify(data).length > 35) {
                                    return (<>
                                        { 
                                            data.getSitemap.map((sitemapEntry, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {window.location.href === sitemapEntry.url ? 
                                                            <StyledASelected href={sitemapEntry.url}>{sitemapEntry.title}</StyledASelected>
                                                            :
                                                            <StyledA href={sitemapEntry.url}>{sitemapEntry.title}</StyledA>
                                                        }
                                                        {index+1 !== data.getSitemap.length ? <ASeparator>|</ASeparator>:null }
                                                    </React.Fragment>
                                                );
                                            }) //data.getSitemap.map
                                        }
                                    </>);
                                }
                                return null; 
                            }
                        }
                        </Query> 

                    </RowCenter>
                    
                </Section9>

                <Section2>
                        

                {
                    window.location.pathname === '/sitemap' ? 
                        <>
                        <RowCenter2><H2>PhotoSouls Photographers</H2></RowCenter2>
                        <Row>
                            <Query query={getSitemap} variables={{'page': "/photographers" }} fetchPolicy="network-only">
                            {
                                ({ data }) => {
                                    if (data === undefined) {
                                        //do nothing ??    
                                    } else
                                    if (JSON.stringify(data).length > 35) {
                                        return (<>
                                            { 
                                                data.getSitemap.map((sitemapEntry, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <Col30>
                                                                <SitemapLink href={sitemapEntry.url}>{sitemapEntry.title}</SitemapLink>
                                                            </Col30>
                                                        </React.Fragment>
                                                    );
                                                }) //data.getSitemap.map
                                            }
                                        </>);
                                    }
                                    return null; 
                                }
                            }
                            </Query> 
                        </Row>
                        </>
                    : window.location.pathname === '/sitemap/models' ? 
                        <>
                        <RowCenter2><H2>PhotoSouls Models</H2></RowCenter2>
                        <Row>
                            <Query query={getSitemap} variables={{'page': "/models" }} fetchPolicy="network-only">
                                {
                                    ({ data }) => {
                                        if (data === undefined) {
                                            //do nothing ??    
                                        } else
                                        if (JSON.stringify(data).length > 35) {
                                            return (<>
                                                { 
                                                    data.getSitemap.map((sitemapEntry, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <Col30>
                                                                    <SitemapLink href={sitemapEntry.url}>{sitemapEntry.title}</SitemapLink>
                                                                </Col30>
                                                            </React.Fragment>
                                                        );
                                                    }) //data.getSitemap.map
                                                }
                                            </>);
                                        }
                                        return null; 
                                    }
                                }
                            </Query> 
                        </Row>
                        </>
                    : window.location.pathname === '/sitemap/help-pages' ? 
                        <>
                        <RowCenter2><H2>Help Center Pages</H2></RowCenter2>
                        <Row>
                            <Query query={getSitemap} variables={{'page': "/help-pages" }} fetchPolicy="network-only">
                                {
                                    ({ data }) => {
                                        if (data === undefined) {
                                            //do nothing ??    
                                        } else
                                        if (JSON.stringify(data).length > 35) {
                                            return (<>
                                                { 
                                                    data.getSitemap.map((sitemapEntry, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <Col30>
                                                                    <SitemapLink href={sitemapEntry.url}>{sitemapEntry.title}</SitemapLink>
                                                                </Col30>
                                                            </React.Fragment>
                                                        );
                                                    }) //data.getSitemap.map
                                                }
                                            </>);
                                        }
                                        return null; 
                                    }
                                }
                            </Query> 
                        </Row>
                        </>: window.location.pathname === '/sitemap/common-pages' ? 
                        <>
                        <RowCenter2><H2>Top Most Common Pages</H2></RowCenter2>
                        <Row>
                            <Query query={getSitemap} variables={{'page': "/common-pages" }} fetchPolicy="network-only">
                                {
                                    ({ data }) => {
                                        if (data === undefined) {
                                            //do nothing ??    
                                        } else
                                        if (JSON.stringify(data).length > 35) {
                                            return (<>
                                                { 
                                                    data.getSitemap.map((sitemapEntry, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <Col30>
                                                                    <SitemapLink href={sitemapEntry.url}>{sitemapEntry.title}</SitemapLink>
                                                                </Col30>
                                                            </React.Fragment>
                                                        );
                                                    }) //data.getSitemap.map
                                                }
                                            </>);
                                        }
                                        return null; 
                                    }
                                }
                            </Query> 
                        </Row>
                        </>
                    : null
                }
                
                        


                </Section2>

                
                     


                <FooterSection/>

            </>

        )
    }
}





const Section9 = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;

    background-color: #ff9500;
    /* background-color: #f3f2f0; */
    /* background-image: linear-gradient(#fff,#f3f2f0); */

    position: relative;
    z-index: 1;

`;

const StyledA = styled.a`
    
    color: #ffffff;

    font-family: 'Fira Sans';
    font-size: 20px;

    padding-right: 16px;

    text-decoration: none;
    
`;

const SitemapLink = styled.a`
    
    color: #333333;

    font-family: 'Fira Sans';
    font-size: 17px;

    text-decoration: underline;

    padding-bottom: 16px;
    
`;

const StyledASelected = styled.a`
    
    color: #ffffff;

    font-family: 'Fira Sans';
    font-size: 20px;

    padding-right: 16px;

    text-decoration: underline;
    text-underline-offset: 8px;
    
`;

const ASeparator = styled.div`
    
    color: #ffffff;
    font-size: 20px;
    padding-right: 16px;
    
`;

const RowCenter = styled.div`
    
    width: 1170px;

    margin: 0 auto;
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    text-align: center;
    justify-content: center;
    align-items: center;

    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;

    @media (max-width: 980px) {
        
        /* margin-top: 0px; */

    }

`;


const RowCenter2 = styled.div`
    
    width: 1170px;

    margin: 0 auto;
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    text-align: center;
    justify-content: center;
    align-items: center;

    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 980px) {
        
        /* margin-top: 0px; */

    }

`;


const Section2 = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    max-width: 1170px;
    margin: auto;

    background-color: #ffffff;

    position: relative;
    z-index: 1;

`;


const H2 = styled.h2`

    color: #ff9500;
    font-family: 'Fira Sans';
    font-size: 36px;

    @media (max-width: 970px) {

    font-size: 32px;

    }

`;

const Row = styled.div`
    
    width: 1170px;

    margin: 0 auto;
    padding-right: 32px;
    padding-bottom: 32px;
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    text-align: center;
    justify-content: center;
    align-items: top;

    @media (max-width: 980px) {
        
        width: 100%;

    }

`;



const Col30 = styled.div`
    
    width: 30%;
    min-width: 300px;

    margin: 0 auto;
    
    top: 0px;
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    text-align: left;
    justify-content: left;
    align-items: flex-start;

    @media (max-width: 980px) {
    }

`;
