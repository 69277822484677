import React, { PureComponent } from 'react'

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

import "./index.css";
import { FooterSection } from "./_footerSection";

import styled from 'styled-components';
// import Button from '@material-ui/core/Button';


export default class About extends PureComponent {

    componentDidMount() {
        
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

    }

    render() {

        return (
            
            <>

                <Helmet>
                    <title>About PhotoSouls</title>
                    <meta name="description" content="Discover the story behind PhotoSouls, a haven for photography enthusiasts and artists." />
                    <meta name="keywords" content="photosouls, team, about" />
                </Helmet>
                

                <Section9>
                        
                    <RowCenter>
                        <H2white>About Us</H2white>
                    </RowCenter>

                </Section9>


                {/* Join us */}
                <Section1>

                    <Row>
                        
                        <Left2>
                            <S4img src={'images/web/about.png'} alt="PhotoSouls team" />
                        </Left2>

                        <Right2>

                            <H1>Passion and mission</H1>

                            <Ptext>PhotoSouls is young, and we might not have all the numbers to brag about yet, but one day we will be the most popular platform for photography services.</Ptext>

                            <Ptext>We were founded on a pure passion for photography from both sides of the camera. Our only goal here is to help more people get breathtaking photos and enjoy photography.</Ptext>

                            <Ptext>Our team has experience running successful companies over the past two decades, so hopefully that will help us fulfill our mission: to get millions of photoshoots happening worldwide.</Ptext>
                            
                            <Ptext>We hope you'll join us on this journey!</Ptext>

                        </Right2>

                    </Row>


                </Section1>




                {/* Footer */}
                <FooterSection/>


            </>

        )
    }
}




const Section1 = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;

    background-color: #fff;
    background-image: linear-gradient(#f3f2f0,#fff);

    position: relative;
    z-index: 1;
    min-height: 568px;

    @media (max-width: 980px) {
        
        min-height: 600px;

    }


`;

const Section9 = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;

    background-color: #ff9500;
    /* background-color: #f3f2f0; */
    /* background-image: linear-gradient(#fff,#f3f2f0); */

    position: relative;
    z-index: 1;

`;


const Row = styled.div`
    
    width: 1170px;

    margin: 0 auto;
    padding-left: 24px;
    padding-right: 24px;
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    @media (max-width: 980px) {
        
        /* margin-top: 0px; */
        /* text-align: center;
        justify-content: center;
        align-items: center; */

    }

`;


const RowCenter = styled.div`
    
    width: 1170px;

    margin: 0 auto;
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    text-align: center;
    justify-content: center;
    align-items: center;

    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;

    @media (max-width: 980px) {
        
        /* margin-top: 0px; */

    }

`;


const Ptext = styled.div`
    
    width: 100%;

    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    padding-top: 0px;
    padding-bottom: 32px;

    /* background-color: #ded; */

    font-size: 22px;
    font-family: 'Fira Sans';
    font-weight: 400;


    @media (max-width: 980px) {
        
        /* margin-top: 0px; */

    }

`;


const Left2 = styled.div`
    
    width: calc(40%);
    padding-top: 32px;
    padding-bottom: 32px;

    /* background-color: #aea; */

    @media (max-width: 980px) {
        width: 100%;
        padding-bottom: 0px;
        
    }
    

`;

const Right2 = styled.div`
    
    width: calc(60% - 64px);
    padding-top: 32px;
    padding-bottom: 64px;

    padding-left: 24px;

    /* background-color: #ced; */

    @media (max-width: 980px) {
        
        width: 100%;
        padding-left: 0px;
        padding-top: 0px;

    }
    

`;



const H1 = styled.h1`

    color: #ff9500;
    font-family: 'Fira Sans';
    font-size: 36px;
`;


const H2white = styled.h2`

    color: #fff;
    font-family: 'Fira Sans';
    font-size: 36px;

`;



const S4img = styled.img`

    margin-top: 32px;
    width: 440px;

    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1180px) {

        margin-top: 32px;
        width: 364px;

    }

    @media (max-width: 970px) {

        margin-top: 0px;
        width: 340px;

    }


`;


