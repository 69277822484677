import React, { PureComponent } from 'react'

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

import { withStore } from '@spyna/react-store'
import { withApollo } from "react-apollo";
import { withRouter } from 'react-router-dom';
import { BookingCard } from "./BookingCard";
import { BookingDetails } from "./BookingDetails";
import Footer from '../../modules/components/Footer/Footer'

import Button from '@material-ui/core/Button';
import moment from "moment"; 

import Modal from 'react-modal';

import styled from 'styled-components';
import gql from "graphql-tag"
import { Query } from "react-apollo";
import TimeToMinutes from '../../utils/TimeToMinutes';

           
// on rejection or confirmation, send email (if not immediatelly instabooked, because then we can send out email that it was confirmed)

// on cancellation (either side), send the cancellation email.

// Once booking is approved (or automatically approved, charge the card). 
            // - [ ]  Rejection email (to client when confirmed by service provider)
            // - [ ]  Cancellation email (for both parties)


            

const getBookings = gql`
    query (
        $queryFilter: String,
        $queryOrder: String, 
        ) {
            getBookings( 
                queryFilter: $queryFilter, 
                queryOrder: $queryOrder, 
                ) { 
                    bookingId, status, 

                    clientUserId, providerUserId,
                    dt, dt2, st, et,   
                    catNumber, location,

                    packageName, packageDesc, eventDesc { title, desc, },

                    price, bookingFee, total, paidNow, paidLater, currency,
                    userCurrencyPrice, userCurrencyBookingFee, userCurrencyTotal, userCurrencyPaidNow, userCurrencyPaidLater, userCurrency, 


                    providerIsPro, provider, providerName, 
                    providerAvatar, providerReviews, providerRating, providerJoined,
                    providerReceiptName, providerAddress, providerEmail,

                    clientIsPro, clientType, clientFName, clientLName, 
                    clientAvatar, clientReviews, clientRating, clientJoined,

                    cancelAfter, cancelBefore, lateCancellationKeeps, noCancellationsAfter,

                    message { dt, from, message, },
                    review { id, dt, from, rating, message, privateMessage, response, reported, reportResolved, hideBasedOnReport, reportDecision },

                    booked, confirmed, rejected, canceled, refunded,
                    rejectedReason, cancelReason, refundReason,

        
                }
        }
`;



class C extends PureComponent {

    state = {
        
        awaitingConfirmation: [],
        upcomingBookings: [],
        awaitingDelivery: [],
        
        activePanel: 'awaitingConfirmation', //default

        statusAwaiting: [],
        statusConfirmed: [],
        statusDelivered: [],
        statusRejected: [],
        statusCancelled: [],
        statusRefunded: [],
        statusChargeback: [],
        
        bookingsLoaded: false,
        showBookingDetails: false,

    }

    async componentDidMount() {

        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });


        // DEFAULT activePanel: 'awaitingConfirmation'

        if (this.state.awaitingConfirmation.length <= 0) {

            if (this.state.upcomingBookings.length > 0) {

                this.setState({ activePanel: 'upcoming',  });

            } else {

                if (this.state.awaitingDelivery.length > 0) {

                    this.setState({ activePanel: 'awaitingDelivery', });

                } else {

                    this.setState({ activePanel: 'upcoming',  });

                }
            }

        }


    }

    loadBookingsData = async (data) => {

        for (var i = data.getBookings.length-1; i >= 0; i--) {
            
            //  0=awaiting      1=confirmed      2=delivered    3=rejected      4=cancelled      5=refunded    6=chargeback
            if (data.getBookings[i].status === 0) { 
                let status = this.state.statusAwaiting;
                status.push(data.getBookings[i].bookingId);
                setTimeout(() => { this.setState({ statusAwaiting: status, bookingsLoaded: true, }); }, 50);
            } else if (data.getBookings[i].status === 1) { 
                let status = this.state.statusConfirmed;
                status.push(data.getBookings[i].bookingId);
                setTimeout(() => { this.setState({ statusConfirmed: status, bookingsLoaded: true, }); }, 50);
            } else if (data.getBookings[i].status === 2) { 
                let status = this.state.statusDelivered;
                status.push(data.getBookings[i].bookingId);
                setTimeout(() => { this.setState({ statusDelivered: status, bookingsLoaded: true, }); }, 50);
            } else if (data.getBookings[i].status === 3) { 
                let status = this.state.statusRejected;
                status.push(data.getBookings[i].bookingId);
                setTimeout(() => { this.setState({ statusRejected: status, bookingsLoaded: true, }); }, 50);
            } else if (data.getBookings[i].status === 4) { 
                let status = this.state.statusCancelled;
                status.push(data.getBookings[i].bookingId);
                setTimeout(() => { this.setState({ statusCancelled: status, bookingsLoaded: true, }); }, 50);
            } else if (data.getBookings[i].status === 5) { 
                let status = this.state.statusRefunded;
                status.push(data.getBookings[i].bookingId);
                setTimeout(() => { this.setState({ statusRefunded: status, bookingsLoaded: true, }); }, 50);
            } else if (data.getBookings[i].status === 6) { 
                let status = this.state.statusChargeback;
                status.push(data.getBookings[i].bookingId);
                setTimeout(() => { this.setState({ statusChargeback: status, bookingsLoaded: true, }); }, 50);
            }

        }


    }


    render() {


        const bookingModalStyles = {
            overlay: { zIndex: 2000 },
            content: {

                top: `0`,
                left: '0',
                border: '0px',
                borderRadius: '0',
                
                height: `100vh`,
                width: `100%`,

                paddingTop: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                                                    

            }
        }

        // console.log('--- state', this.state)
        const now = moment(new Date()); //todays date

        return (
            
            <ExtraWrapper>
                
                <Helmet>
                    <title>Bookings in PhotoSouls</title>
                    <meta name="description" content="Bookings" />
                    <meta name="keywords" content="photosouls,bookings" />
                </Helmet>

                
                    
                

                    <MainTitle>
                        Bookings
                    </MainTitle> 

                    <ButtonMenu>
                        {/* {
                            this.state.awaitingConfirmation.length > 0 ?
                                <FilterButton isSet={this.state.activePanel === 'awaitingConfirmation'} 
                                    onClick={ (e) => { this.setState({ activePanel: 'awaitingConfirmation', }) }}
                                >
                                    Awaiting confirmation
                                </FilterButton>
                                :
                                null
                        } */}


                        <FilterButton 
                            // isset={this.state.activePanel === 'upcoming'} 
                            isset={this.state.activePanel === 'upcoming' ? "true" : "false"}
                            onClick={ (e) => { this.setState({ activePanel: 'upcoming', }) }}
                            >
                            Upcoming
                        </FilterButton>


                        {/* {
                            (this.state.awaitingDelivery.length > 0) ?
                                <FilterButton isSet={this.state.activePanel === 'awaitingDelivery'} 
                                    onClick={ (e) => { this.setState({ activePanel: 'awaitingDelivery', }) }}
                                    >
                                    Awaiting delivery
                                </FilterButton>
                                :
                                null
                        } */}


                        <FilterButton 
                            // isset={this.state.activePanel === 'past'} 
                            isset={this.state.activePanel === 'past' ? "true" : "false"}
                            onClick={ (e) => { this.setState({ activePanel: 'past', }) }}
                            >
                            Past
                        </FilterButton>


                        {/* <FilterButton isSet={this.state.activePanel === 'canceled'} 
                            onClick={ (e) => { this.setState({ activePanel: 'canceled', }) }}
                            >
                            Canceled
                        </FilterButton> */}

                    </ButtonMenu>



                    {/* List bookings as cards */}
                    <ProfileResults>

                        <Query
                            query={getBookings}
                            variables={
                                {
                                    'queryFilter': this.state.activePanel === 'upcoming' ? "upcoming" : "past", 
                                    'queryOrder': this.state.activePanel === 'upcoming' ? "asc" : "desc",  
                                 }
                            }
                            fetchPolicy="network-only"
                            
                        >
                        {
                            
                            ({ data }) => {
                            
                                if (data === undefined) {

                                    return(
                                            <SubTitle key={data.bookingId}>Sorry, we're not able to get the list of bookings at this moment. Please try again later.</SubTitle>
                                    )

                                } else
                                if (JSON.stringify(data).length > 35) {
                                    
                                    
                                    if (this.state.bookingsLoaded === false) {
                                        this.loadBookingsData(data); //fills this.state.favPhotog and other state arrays
                                    }

                                    const myUserId = this.props.store.get("userId");


                                    return (<>

                                        { 

                                            data.getBookings.map((booking, index) => {

                                                const bookedDate = moment(booking.dt/1000*1000).add(TimeToMinutes(booking.st), 'm');

                                                //console.log('booking.dt', booking.dt)
                                                //console.log('bookedDate.valueOf()', bookedDate.valueOf())
                                                //console.log('now.valueOf()', now.valueOf())

                                                let inThePast = false;
                                                if (bookedDate.valueOf() < now.valueOf()) { 
                                                    inThePast = true; 
                                                }

                                                let showCard = false;
                                                if (this.state.activePanel === 'upcoming' && inThePast === false) {
                                                    showCard = true
                                                } else if (this.state.activePanel === 'past' && inThePast === true) {
                                                    showCard = true
                                                } 

                                                if (showCard) {
                                                        
                                                    return (
                                                    
                                                        <BookingCard
                                                            key={index}
                                                            index={index}
                                                            inThePast={inThePast}
                                                            bookingData={booking}
                                                            myUserId={myUserId}
                                                            onClick={() => { 
                                                                // console.log('booking card clicked')
                                                                this.setState({ showBookingDetails: !this.state.showBookingDetails, selectedBookingData: booking});
                                                                
                                                            }} 
                                            
                                                        /> 

                                                    );

                                                    } else {
                                                        return null;
                                                    }

                                            }) //data.getBookings.map


                                        
                                        } 

                                    </>);

                                }
                                
                                return null; 
                            
                            }

                        }

                        </Query>

                    </ProfileResults>

                    <Modal 
                        isOpen={this.state.showBookingDetails}
                        closeTimeoutMS={500}

                        onRequestClose={() => { this.setState({ showBookingDetails: !this.state.showBookingDetails, })  }}
                        ariaHideApp={false}
                        style={bookingModalStyles}
                        
                        onAfterOpen={() => {
                            document.body.style.top = `0px`
                            document.body.style.width = `100vw`
                            document.body.style.position = 'fixed'
                        }}

                        onAfterClose={() => {
                            const scrollY = document.body.style.top
                            document.body.style.position = ''
                            document.body.style.top = ''
                            document.body.style.width = ''
                            window.scrollTo(0, parseInt(scrollY || '0') * -1)
                        }}
                    >
                    
                        <BookingDetails 
                            bookingData={this.state.selectedBookingData}
                            myUserId={this.props.store.get("userId")}
                            hideModal={ () => this.setState({ showBookingDetails: false, }) }
                            data={this.state.selectedBookingData}
                        />
                        

                    </Modal>


                    <Footer showbutton="true"/>
                    
                

            </ExtraWrapper>

        )
    }
}



const personalInfoFields = [
    'fName', 'lName', 'bDay', 'email', 'phone', 'phoneDisplay', 'phoneCountry', 'avatar', 'isVerified', 'isPro', 'temperror', 'pwdUpdated',
    'chkReminders', 'chkRemindersP', 'chkMessages', 'chkMessagesP', 'chkRecomm', 'chkRecommP', 'chkInsp', 'chkInspP', 'chkLocal', 'chkLocalP', 
    'chkHow', 'chkHowP', 'chkInvite', 'chkInviteP', 'chkSurvey', 'chkSurveyP', 'chkPolicy', 'chkPolicyP', 'chkTips', 'chkTipsP'
]


export const Bookings = withRouter(withStore(withApollo(C), personalInfoFields));



const ExtraWrapper = styled.div`

    width: 90%;
    margin: 40px auto 32px auto;

    position: relative;

    @media (max-width: 790px) {
        width: calc(100% - 24px);
        margin-left: 16px;
    }

`;

const ProfileResults = styled.div`

    margin-left: 3px;
    width: 100%;
    position: relative;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    align-items: flex-start;
    
    justify-content: center;
   
    flex-direction: row;
    justify-content: flex-start;

    z-index: 200;

    overflow-x: hidden;

`;


const SubTitle = styled.div`
    
    font-size: 20px;
    font-weight: 400;
    padding: 16px 24px 16px 0px;


    font-family: 'Fira Sans';
    color: #333333;

`;




const MainTitle = styled.div`
    
    font-size: 22px;
    font-weight: 500;
    padding: 0px 24px 24px 0px;


    font-family: 'Fira Sans';
    color: #333333;

    display: relative;
    width: calc(100% - 48px);
    margin-left: 8px;

`;


const FilterButton = styled(Button)`
    && {
    height: 40px;

    padding: 16px;

    margin-right: 8px;
    margin-bottom: 8px;

    background: ${(props) => props.isset === "true" ? '#f5f5f5': "white"};
    border: ${(props) => props.isset === "true" ? '4px double #cbcbcb;' : "1px solid #cbcbcb;"};

    border-radius: 20px;  


    font-family: 'Fira Sans';
    color: #333333;

    
    /* box-shadow: ${(props) => props.isset === "true" ? '0 2px 8px 0 rgba(170, 170, 170, 0.4);' : "0"}; */


    text-transform: none;
    font-weight: 400;
    font-size: 17px;

    &:hover {
        border-color: #333333;
        }

    }
    
`;


const ButtonMenu = styled.div`
    
    padding-bottom: 32px;
    margin-left: 8px;

    display: relative;
    width: calc(100% - 32px);
    overflow-x: hidden;

`;