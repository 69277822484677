import React, { useState } from "react";
//  import { useTranslation } from 'react-i18next';

import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faTwitter, faPinterestP } from '@fortawesome/free-brands-svg-icons';

// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
// import styled from 'styled-components';

import "./Footer.css";



export default function Footer(props) {

    const [isFooterVisible, setFooterVisibility] = useState(false);

    // const { t, i18n } = useTranslation();

    const toggleFooter = () => {
        setFooterVisibility(!isFooterVisible);
    };

    
    // const changeLanguage = (event) => {
    //     i18n.changeLanguage(event.target.value)
    //     window.location.reload();
    //     //setFooterVisibility(!isFooterVisible);
    // }

    // const currentLanguage = i18n.language;

    let RenderFooter
    
    if (isFooterVisible) {
        
        RenderFooter = (
            
            <footer className="Footer">

                <div className="FooterContent">
                    
                    <div className="FooterLeftSide">

                        <div className="FooterUSP">PhotoSouls will find you the right people for better photos and amazing experiences.</div>

                        <div className="logoRow">
                        </div>

                        <div className="FooterCopyright">© {(new Date().getFullYear())} PhotoSouls, Inc. All rights reserved.</div>

                    </div>

                    <div className="FooterRightSide">

                        <div className="FooterUpperPart">

                            <div className="FooterCol1">
                                <Link to="/help" onClick={() => setFooterVisibility(false)}>Help Center</Link>
                                <Link to="/about" onClick={() => setFooterVisibility(false)}>About Us</Link>
                                <Link to="/contact" onClick={() => setFooterVisibility(false)}>Contact Us</Link>
                            </div>

                            <div className="FooterCol2">
                                <Link to="/terms/privacy-policy" onClick={() => setFooterVisibility(false)}>Privacy</Link>
                                <Link to="/terms/terms-of-service" onClick={() => setFooterVisibility(false)}>Terms</Link> 
                                <Link to="/sitemap" onClick={() => setFooterVisibility(false)}>Sitemap</Link> 
                            </div>

                            <div className="FooterCol3">

                                
                                {/* <MySelect
                                    disabled
                                    value={currentLanguage}
                                    onChange={changeLanguage}
                                    name="language"
                                    displayEmpty
                                    disableUnderline
                                    MenuProps={{
                                        style: {zIndex: 9999999}
                                    }}
                                    >
                                    <MenuItem value="en">English</MenuItem>
                                    <MenuItem value="es">Español</MenuItem>
                                    <MenuItem value="de">Deutsh</MenuItem>
                                    <MenuItem value="ru">Русский</MenuItem>
                                    <MenuItem value="et">Eesti</MenuItem>
                                </MySelect> 
                             */}


                            </div>

                        </div>
            
                        <div className="FooterBottomPart">
                            
                            <div className="FooterLogoFB">
                                <a href="https://www.facebook.com/photosouls/" target="_blank" rel="noopener noreferrer" onClick={() => setFooterVisibility(false)}>
                                    <FontAwesomeIcon icon={faFacebookF} color="#ffffff"/>
                                </a>
                            </div>
                            <div className="FooterLogoIG">
                                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" onClick={() => setFooterVisibility(false)}>
                                {/* #TODO: */}
                                    <FontAwesomeIcon icon={faInstagram} color="#ffffff"/>
                                </a>
                            </div>
                            <div className="FooterLogoTw">
                                <a href="https://twitter.com/Photosouls" target="_blank" rel="noopener noreferrer" onClick={() => setFooterVisibility(false)}>
                                    <FontAwesomeIcon icon={faTwitter} color="#ffffff"/>
                                </a>
                            </div>
                            <div className="FooterLogoPin">
                                <a href="https://www.pinterest.com" target="_blank" rel="noopener noreferrer" onClick={() => setFooterVisibility(false)}>
                                {/* #TODO: */}
                                    <FontAwesomeIcon icon={faPinterestP} color="#ffffff"/>
                                </a>
                            </div>
                            

                        </div>
                
                    </div>
                </div>
            </footer> 
        )

    } else {

        RenderFooter = (
        <div></div>
        )
        
    }

    let RenderFooterOpener

    if (!isFooterVisible && props.showbutton === "true") {

        RenderFooterOpener = (
            <button onClick={toggleFooter} className="btnShowFooter">About, Terms, Privacy</button>
        )

    }

    let RenderFooterCloser
    if (isFooterVisible) {

        RenderFooterCloser = (
            <button onClick={toggleFooter} className="btnCloseFooter">
            Close
            </button>
        )

  }

 
    return (
        
        <>

            {RenderFooterOpener}
            
            <CSSTransition
                in={isFooterVisible}
                timeout={350}
                classNames="NavAnimation"
                unmountOnExit
                >
            
            {RenderFooter}

            </CSSTransition>

            {RenderFooterCloser}

        </>
    );
}


// const MySelect = styled(Select)`
//     && { 
//         color: #333333;
//         font-size: 15px;
//         font-weight: 400;
//         transition: 0.5s;
//         padding-top: 0px;
//         margin-top: -10px;
//         text-decoration: none;
//         border: 0px;
//         z-index: 99999999;
//         &:hover {
//             transform: scale(1.1);
//             text-decoration: underline;
//             }
//         }
        
// `;