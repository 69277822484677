
import React, { PureComponent } from 'react'
import { withApollo } from "react-apollo";
import { withStore } from '@spyna/react-store'

import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import Select from 'react-select'


import Button from '@material-ui/core/Button';
import ConfirmDelete from '../ConfirmDelete';
import Modal from 'react-modal';

import Checkbox from '@material-ui/core/Checkbox';
// import Radio from '@material-ui/core/Radio';
//import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { orange, grey } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { UpgradeModal } from "../../../components/UpgradeModal";

import i18next from "i18next";
import moment from "moment"; 


import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";


// import ToggleButton from '@material-ui/lab/ToggleButton';
// import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { DayPickerSingleDateController, CalendarDay, } from 'react-dates'

//import "@fullcalendar/core/main.css";
//import "@fullcalendar/daygrid/main.css";
//import "@fullcalendar/core/main.css";

import "./../../../../styles/FullCalendarCustom.css";
import './../../../../styles/ModalPopup.css';    
import './../../../../styles/ReactDatesOverride.css';


import gql from "graphql-tag"

// const editMuah = gql`
//     mutation( $profileId: String! $input: MuahInput! ) {
//         editMuah( profileId: $profileId, input: $input ) { name }
//     }
// `;

const addEditMuahAvailability = gql`
    mutation( $mode: String! $muahId: String! $input: MuahAvailabilityInput! ) {
        addEditMuahAvailability( mode: $mode, muahId: $muahId, input: $input )
    }
`;

const getMuahAvailability = gql`
    query($muahId: String, $periodStart: String, $periodEnd: String, $availabilityId: String) {
        getMuahAvailability(muahId: $muahId, periodStart: $periodStart, periodEnd: $periodEnd, availabilityId: $availabilityId) { 
            pId, dId, dt, st, et, travel, location { type, coordinates }, addr, addr_locality, addr_area2, addr_area1, country
            }
    }
`;


class C extends PureComponent { 



    constructor(props) {
        
        super(props);
        
        this.state = {
            activeView: 'calendarView',
            selectedAvailability: '',
            selectedAvailabilityId: '',
            showUpgradeModal: false,
        };
    
        this.fldValueChange = this.fldValueChange.bind(this);
        this.handleDatePickerChange = this.handleDatePickerChange.bind(this)
          
    }

    handleDatePickerChange (date) {

        const dates = this.state.selectedAvailabilityDates
        const formattedDate = moment(date).format('YYYY-MM-DD');

        const newDates = dates.includes(formattedDate) ? dates.filter(d => formattedDate !== d) : [...dates, formattedDate] 

        setTimeout(() => { 
            this.setState({ selectedAvailabilityDates: newDates })
            }, 50)

        this.props.onChange && this.props.onChange(newDates.toJS())

        // console.log('new date selected: date: ', date)

    }

    fldValueChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({ [name]: value, });
        
    }

    async componentDidMount() {

        this.props.showImmediateSaveLabel(true);
    
    }

    async componentWillUnmount() {

        this.props.showImmediateSaveLabel(false);
    
    }

    addAvailability = async (availabilityDate) => {

        this.setState({

            activeView: 'availabilityDetails',

            selectedAvailabilityId: '%%-----## -!new-availability!- ##------%%',
            selectedAvailabilitySt: '09:00',
            selectedAvailabilityEt: '18:00',
            
            selectedAvailabilityMode: "0",
            selectedAvailabilitySd: availabilityDate,
            selectedAvailabilityDates: [availabilityDate],
            selectedAvailabilityMon: false,
            selectedAvailabilityTue: false,
            selectedAvailabilityWed: false,
            selectedAvailabilityThu: false,
            selectedAvailabilityFri: false,
            selectedAvailabilitySat: true,
            selectedAvailabilitySun: true,
            selectedAvailabilityWeekdays: ['Sat', 'Sun'],
            selectedAvailabilityEd: moment(availabilityDate).add('M', 1).format('YYYY-MM-DD'),

            selectedAvailabilityTravel: false,
            selectedAvailabilityLocation: { type: 'Point', coordinates: [] },
            selectedAvailabilityAddr: '',
            selectedAvailabilityAddr_locality: '',
            selectedAvailabilityAddr_area2: '',
            selectedAvailabilityAddr_area1: '',
            selectedAvailabilityCountry: '',


            selectedAvailabilityConfirmDelete: false,
        }); 

        this.props.showSaveButton(false);
        
        // setTimeout(() => { 
        //     window.scrollTo({top: 96, behavior: 'smooth'});
        // }, 50);
        

    }



    editAvailability = async (id) => {

        let params = {}
        
        // console.log('editAvailability id: ', id)
       
        await this.props.client
        .query({ 
            query: getMuahAvailability, 
            variables: { availabilityId: id }, 
            fetchPolicy: 'no-cache',})
        .then(results => {

            if (JSON.stringify(results).length > 25) {

                // let queryResult = results.data.getMuahAvailability[0];

                let isoDate = new Date(parseInt(results.data.getMuahAvailability[0].dt))

                params = {
                    dId: id,
                    st: results.data.getMuahAvailability[0].st,
                    et: results.data.getMuahAvailability[0].et,
                    mode: '0',
                    sd: moment(isoDate).format('YYYY-MM-DD'),
                    dates: [moment(isoDate).format('YYYY-MM-DD')],
                    mon: false,
                    tue: false,
                    wed: false,
                    thu: false,
                    fri: false,
                    sat: false,
                    sun: false,
                    ed: moment(isoDate).format('YYYY-MM-DD'),
                    travel: results.data.getMuahAvailability[0].travel,
                    location: results.data.getMuahAvailability[0].location,
                    addr: results.data.getMuahAvailability[0].addr,
                    locality: results.data.getMuahAvailability[0].addr_locality,
                    addr_area2: results.data.getMuahAvailability[0].addr_area2,
                    addr_area1: results.data.getMuahAvailability[0].addr_area1,
                    country: results.data.getMuahAvailability[0].country,
        
                } 

            } 
        })
        .catch(error => { console.log(error) })

        // let eventsArray = []
        // await this.props.client
        // .query({ 
        //     query: getPhotogAvailability, 
        //     variables: { 
        //         photogId: this.props.values.id,  
        //         periodStart: '2022-12-01', //moment(range.start).format('YYYY-MM-DD'),
        //         periodEnd:  '2022-12-31', //moment(range.end).format('YYYY-MM-DD'),   
        //     }, 
        //     fetchPolicy: 'no-cache',})
        // .then(results => {
        //     if (JSON.stringify(results).length > 25) {
        //         const data = results.data.getPhotogAvailability;
        //         for (var q = data.length-1; q >= 0; q--) {
        //             eventsArray.push(moment(new Date(parseInt(data[q].dt))).format('YYYY-MM-DD'));
        //         } 
        //     }
        // })
        // .catch(error => { console.log(error) })
        // console.log('eventsArray', eventsArray)


        const daysArr = []; 
        if (params.mon) daysArr.push("Mon");
        if (params.tue) daysArr.push("Tue")
        if (params.wed) daysArr.push("Wed")
        if (params.thu) daysArr.push("Thu")
        if (params.fri) daysArr.push("Fri")
        if (params.sat) daysArr.push("Sat")
        if (params.sun) daysArr.push("Sun")

        setTimeout(() => {
            this.setState({
                activeView: 'availabilityDetails',

                selectedAvailabilityId: params.dId,
                selectedAvailabilitySt: params.st,
                selectedAvailabilityEt: params.et,
                
                selectedAvailabilityMode: params.mode,
                selectedAvailabilitySd: params.sd,
                selectedAvailabilityDates: params.dates,
                selectedAvailabilityMon: params.mon,
                selectedAvailabilityTue: params.tue,
                selectedAvailabilityWed: params.wed,
                selectedAvailabilityThu: params.thu,
                selectedAvailabilityFri: params.fri,
                selectedAvailabilitySat: params.sat,
                selectedAvailabilitySun: params.sun,
                selectedAvailabilityWeekdays: daysArr,
                selectedAvailabilityEd: params.ed,

                selectedAvailabilityTravel: params.travel,
                selectedAvailabilityLocation: params.location,
                selectedAvailabilityAddr: params.addr,
                selectedAvailabilityAddr_locality: params.locality,
                selectedAvailabilityAddr_area2: params.addr_area2,
                selectedAvailabilityAddr_area1: params.addr_area1,
                selectedAvailabilityCountry: params.country,

                selectedPackageConfirmDelete: false,

            }); 
        }, 50);

        //console.log('this.state', this.state)

        this.props.showSaveButton(false);

    }

    saveAvailability = async () => {


        var tempuuid = uuidv4();
        var mode = 'add'; //default

        if (this.state.selectedAvailabilityId !== '%%-----## -!new-availability!- ##------%%') {
            mode = 'edit';
            // for (var q = this.props.values.availability.length-1; q >= 0; q--) {
            //     if (this.props.values.availability[q].id === this.state.selectedAvailabilityId) { 
            //         tempuuid = this.props.values.availability[q].id;
            //     } 
            // }

            tempuuid = this.state.selectedAvailabilityId;
        }

        var availabilityDetails = {
            id: tempuuid, // generate our own id (as db id is generated on save, but we might not save the album yet), until the album is saved to the database.
            st: this.state.selectedAvailabilitySt,
            et: this.state.selectedAvailabilityEt,
            mode: this.state.selectedAvailabilityMode,
            sd: this.state.selectedAvailabilitySd,
            dates: this.state.selectedAvailabilityDates,
            mon: this.state.selectedAvailabilityMon,
            tue: this.state.selectedAvailabilityTue, 
            wed: this.state.selectedAvailabilityWed,
            thu: this.state.selectedAvailabilityThu,
            fri: this.state.selectedAvailabilityFri,
            sat: this.state.selectedAvailabilitySat, 
            sun: this.state.selectedAvailabilitySun,
            ed: this.state.selectedAvailabilityEd,
            travel: this.state.selectedAvailabilityTravel,
            location:this.state.selectedAvailabilityLocation,
            addr: this.state.selectedAvailabilityAddr,
            addr_locality: this.state.selectedAvailabilityAddr_locality,
            addr_area2: this.state.selectedAvailabilityAddr_area2,
            addr_area1: this.state.selectedAvailabilityAddr_area1,
            country: this.state.selectedAvailabilityCountry,
        }


        // if (this.state.selectedAvailabilityId === '%%-----## -!new-availability!- ##------%%') {
            
        //     var arr = this.props.values.availability;
        //     await arr.push(availabilityDetails)
        //     this.props.values.availability = arr;

        // } else {

            // for (var i = this.props.values.availability.length-1; i >= 0; i--) {
            //     if (this.props.values.availability[i].id === this.state.selectedAvailabilityId) { 
            //         tempuuid = this.props.values.availability[i].id;
            //         this.props.values.availability[i].st = availabilityDetails.st;
            //         this.props.values.availability[i].et = availabilityDetails.et;
            //         this.props.values.availability[i].mode = availabilityDetails.mode;
            //         this.props.values.availability[i].sd = availabilityDetails.sd;
            //         this.props.values.availability[i].dates = availabilityDetails.dates;
            //         this.props.values.availability[i].mon = availabilityDetails.mon;
            //         this.props.values.availability[i].tue = availabilityDetails.tue;
            //         this.props.values.availability[i].wed = availabilityDetails.wed;
            //         this.props.values.availability[i].thu = availabilityDetails.thu;
            //         this.props.values.availability[i].fri = availabilityDetails.fri;
            //         this.props.values.availability[i].sat = availabilityDetails.sat;
            //         this.props.values.availability[i].sun = availabilityDetails.sun; 
            //         this.props.values.availability[i].ed = availabilityDetails.ed;
            //         this.props.values.availability[i].travel = availabilityDetails.travel;
            //         this.props.values.availability[i].location = availabilityDetails.location;
            //         this.props.values.availability[i].addr = availabilityDetails.addr;
            //         this.props.values.availability[i].addr_locality = availabilityDetails.addr_locality;
            //         this.props.values.availability[i].addr_area2 = availabilityDetails.addr_area2;
            //         this.props.values.availability[i].addr_area1 = availabilityDetails.addr_area1;
            //         this.props.values.availability[i].country = availabilityDetails.country;

            //     } 
            // }

        // }

        this.setState({
            activeView: 'calendarView',
            selectedAvailability: '',
            selectedAvailabilityId: '',
            errorMsg: '',
        });

        
        this.props.showSaveButton(true);

        // console.log('updateAvailabilities mode', mode)
        // console.log('updateAvailabilities availabilityDetails', availabilityDetails)
        
        await this.updateAvailabilities(mode, availabilityDetails);
        
        this.forceUpdate();
        
    }


    cancelAvailabilityEdit = () => {

        this.setState({
            activeView: 'calendarView',
            selectedAvailability: '',
            selectedAvailabilityId: '',
            errorMsg: '',
        });

        this.props.showSaveButton(true);
        this.forceUpdate();

    }


    deleteAvailability = async (deleteAvailabilityId) => {

        // var availabilityArray = this.props.values.availability;
        // var availabilityRemovedArray = availabilityArray.filter(function(availability) { return availability.id !== deleteAvailabilityId });
        // this.props.values.availability = availabilityRemovedArray;

        this.setState({

            selectedAvailabilityConfirmDelete: false,

            activeView: 'calendarView',
            selectedAvailability: '',
            selectedAvailabilityId: '',
            errorMsg: '',
        });

        this.props.showSaveButton(true);

        await this.updateAvailabilities('delete', {id: deleteAvailabilityId});
        this.forceUpdate();
        

    };

    deleteAllAvailability = async () => {

        this.props.values.availability = [];

        this.setState({

            selectedAllAvailabilityConfirmDelete: false,

            activeView: 'calendarView',
            selectedAvailability: '',
            selectedAvailabilityId: '',
            errorMsg: '',
        });

        this.props.showSaveButton(true);

        await this.updateAvailabilities('deleteall', {});
        this.forceUpdate();
        

    };


    updateAvailabilities = async (mode, availabilityDetails) => {

        // some cleanup needed for Graphql to prevent model match errors
        // var tempArray = this.props.values.availability;
        // for (var z = tempArray.length-1; z >= 0; z--) {
        //     delete tempArray[z].__typename
        //     delete tempArray[z].location.__typename
        // }

        

        // const inputValue = {
        //     availability: tempArray
        // }

        // await this.props.client.mutate({
        //     mutation: editMuah,
        //     variables: { profileId: this.props.values.id, input: inputValue },
        // }).then(results => {
        //     //console.log('# updatePackages result: ', results)
        // }).catch(error => {
        //     console.log("Error: ", error);
        // })
        

        // console.log('mode', mode)
        // console.log('availabilityDetails', availabilityDetails)


        if (mode === 'edit') {
            delete availabilityDetails.location.__typename
        }

        await this.props.client.mutate({
            mutation: addEditMuahAvailability,
            variables: { mode, muahId: this.props.values.id, input: availabilityDetails },
        }).then(results => {
            //console.log('# updatePackages result: ', results)
        }).catch(error => {
            console.log("Error: ", error);
        })

        //console.log('## update completed')


    } 


    toggleAvailabilityConfirmDelete = () => {  

        this.setState({  
            selectedAvailabilityConfirmDelete: !this.state.selectedAvailabilityConfirmDelete 
        });  

    }  

    toggleAllAvailabilityConfirmDelete = () => {  

        this.setState({  
            selectedAllAvailabilityConfirmDelete: !this.state.selectedAllAvailabilityConfirmDelete 
        });  

    }  



    renderEventContent = (eventInfo) => {
        return (
            <>
                {eventInfo.event.title}
            </>
        )
    }

    handleDateClick = (selectInfo) => {
        this.addAvailability(selectInfo.dateStr); 
    }


    handleEventClick = (clickInfo) => {

        // console.log('clickInfo.event.extendedProps ', clickInfo.event.extendedProps)
        this.editAvailability(clickInfo.event.extendedProps.dId)
    }


    timeOptions = (use24h) => {

        if (use24h) {
            return [
                { value: '0', code: '00:00', label: '00:00' },
                { value: '1', code: '00:30', label: '00:30' },
                { value: '2', code: '01:00', label: '01:00' },
                { value: '3', code: '01:30', label: '01:30' },
                { value: '4', code: '02:00', label: '02:00' },
                { value: '5', code: '02:30', label: '02:30' },
                { value: '6', code: '03:00', label: '03:00' },
                { value: '7', code: '03:30', label: '03:30' },
                { value: '8', code: '04:00', label: '04:00' },
                { value: '9', code: '04:30', label: '04:30' },
                { value: '10', code: '05:00', label: '05:00' },
                { value: '11', code: '05:30', label: '05:30' },
                { value: '12', code: '06:00', label: '06:00' },
                { value: '13', code: '06:30', label: '06:30' },
                { value: '14', code: '07:00', label: '07:00' },
                { value: '15', code: '07:30', label: '07:30' },
                { value: '16', code: '08:00', label: '08:00' },
                { value: '17', code: '08:30', label: '08:30' },
                { value: '18', code: '09:00', label: '09:00' },
                { value: '19', code: '09:30', label: '09:30' },
                { value: '20', code: '10:00', label: '10:00' },
                { value: '21', code: '10:30', label: '10:30' },
                { value: '22', code: '11:00', label: '11:00' },
                { value: '23', code: '11:30', label: '11:30' },
                { value: '24', code: '12:00', label: '12:00' },
                { value: '25', code: '12:30', label: '12:30' },
                { value: '26', code: '13:00', label: '13:00' },
                { value: '27', code: '13:30', label: '13:30' },
                { value: '28', code: '14:00', label: '14:00' },
                { value: '29', code: '14:30', label: '14:30' },
                { value: '30', code: '15:00', label: '15:00' },
                { value: '31', code: '15:30', label: '15:30' },
                { value: '32', code: '16:00', label: '16:00' },
                { value: '33', code: '16:30', label: '16:30' },
                { value: '34', code: '17:00', label: '17:00' },
                { value: '35', code: '17:30', label: '17:30' },
                { value: '36', code: '18:00', label: '18:00' },
                { value: '37', code: '18:30', label: '18:30' },
                { value: '38', code: '19:00', label: '19:00' },
                { value: '39', code: '19:30', label: '19:30' },
                { value: '40', code: '20:00', label: '20:00' },
                { value: '41', code: '20:30', label: '20:30' },
                { value: '42', code: '21:00', label: '21:00' },
                { value: '43', code: '21:30', label: '21:30' },
                { value: '44', code: '22:00', label: '22:00' },
                { value: '45', code: '22:30', label: '22:30' },
                { value: '46', code: '23:00', label: '23:00' },
                { value: '47', code: '23:30', label: '23:30' },
            ]
        } else {
            return [
                { value: '0', code: '00:00', label: '12:00 AM' },
                { value: '1', code: '00:30', label: '12:30 AM' },
                { value: '2', code: '01:00', label: '01:00 AM' },
                { value: '3', code: '01:30', label: '01:30 AM' },
                { value: '4', code: '02:00', label: '02:00 AM' },
                { value: '5', code: '02:30', label: '02:30 AM' },
                { value: '6', code: '03:00', label: '03:00 AM' },
                { value: '7', code: '03:30', label: '03:30 AM' },
                { value: '8', code: '04:00', label: '04:00 AM' },
                { value: '9', code: '04:30', label: '04:30 AM' },
                { value: '10', code: '05:00', label: '05:00 AM' },
                { value: '11', code: '05:30', label: '05:30 AM' },
                { value: '12', code: '06:00', label: '06:00 AM' },
                { value: '13', code: '06:30', label: '06:30 AM' },
                { value: '14', code: '07:00', label: '07:00 AM' },
                { value: '15', code: '07:30', label: '07:30 AM' },
                { value: '16', code: '08:00', label: '08:00 AM' },
                { value: '17', code: '08:30', label: '08:30 AM' },
                { value: '18', code: '09:00', label: '09:00 AM' },
                { value: '19', code: '09:30', label: '09:30 AM' },
                { value: '20', code: '10:00', label: '10:00 AM' },
                { value: '21', code: '10:30', label: '10:30 AM' },
                { value: '22', code: '11:00', label: '11:00 AM' },
                { value: '23', code: '11:30', label: '11:30 AM' },
                { value: '24', code: '12:00', label: '12:00 PM' },
                { value: '25', code: '12:30', label: '12:30 PM' },
                { value: '26', code: '13:00', label: '01:00 PM' },
                { value: '27', code: '13:30', label: '01:30 PM' },
                { value: '28', code: '14:00', label: '02:00 PM' },
                { value: '29', code: '14:30', label: '02:30 PM' },
                { value: '30', code: '15:00', label: '03:00 PM' },
                { value: '31', code: '15:30', label: '03:30 PM' },
                { value: '32', code: '16:00', label: '04:00 PM' },
                { value: '33', code: '16:30', label: '04:30 PM' },
                { value: '34', code: '17:00', label: '05:00 PM' },
                { value: '35', code: '17:30', label: '05:30 PM' },
                { value: '36', code: '18:00', label: '06:00 PM' },
                { value: '37', code: '18:30', label: '06:30 PM' },
                { value: '38', code: '19:00', label: '07:00 PM' },
                { value: '39', code: '19:30', label: '07:30 PM' },
                { value: '40', code: '20:00', label: '08:00 PM' },
                { value: '41', code: '20:30', label: '08:30 PM' },
                { value: '42', code: '21:00', label: '09:00 PM' },
                { value: '43', code: '21:30', label: '09:30 PM' },
                { value: '44', code: '22:00', label: '10:00 PM' },
                { value: '45', code: '22:30', label: '10:30 PM' },
                { value: '46', code: '23:00', label: '11:00 PM' },
                { value: '47', code: '23:30', label: '11:30 PM' },
            ]
        }
    }
    

    localeUses24HourTime = (locale) => {

        // if locale === undefined => current user's locale, otherwise uses "locale"

        //returns true, if 24H time is used in this locale/language
        return new Intl.DateTimeFormat(locale, { hour: 'numeric' })
            .formatToParts(new Date(2020, 0, 1, 13))
            .find(part => part.type === 'hour')
            .value.length === 2;

    }


    handleWeekdays = (event, newLimits) => {

        setTimeout(() => {
            this.setState({  
                selectedAvailabilityWeekdays: newLimits,
                selectedAvailabilityMon: false,
                selectedAvailabilityTue: false,
                selectedAvailabilityWed: false,
                selectedAvailabilityThu: false,
                selectedAvailabilityFri: false,
                selectedAvailabilitySat: false,
                selectedAvailabilitySun: false,
            }); 

            for (var i = this.state.selectedAvailabilityWeekdays.length-1; i >= 0; i--) {
                const fldName = 'selectedAvailability' + this.state.selectedAvailabilityWeekdays[i]
                this.setState({ [fldName]: true });
            }

        }, 50);

    };


    handlePlacesChange = address => { //this is fired by every keystroke on the edit field. "address" value is what they are typing in.

        this.setState(() => {
            return { selectedAvailabilityAddr: address };
        });

    };


    handlePlacesSelect = address => {

        let geoResult = '';

        geocodeByAddress(address)
            .then(results => { 
                    getLatLng(results[0]); 
                    geoResult = results[0];
                })
            .then(latLng => {

                this.setState(() => {
                
                    let addr_locality = '';
                    let addr_area2 = '';
                    let addr_area1 = '';
                    let country = '';
        
                    var i;
                    for (i = 0; i < geoResult.address_components.length; i++) {
                    
                        if (geoResult.address_components[i].types[0] === 'locality') {
                            addr_locality = geoResult.address_components[i].long_name;
                        } else if (geoResult.address_components[i].types[0] === 'administrative_area_level_2') {
                            addr_area2 = geoResult.address_components[i].long_name;
                        } else if (geoResult.address_components[i].types[0] === 'administrative_area_level_1') {
                            addr_area1 = geoResult.address_components[i].long_name;
                        } else if (geoResult.address_components[i].types[0] === 'country') {
                            country = geoResult.address_components[i].long_name;
                        }

                    };

                    const latValue = geoResult.geometry.location.lat()
                    const longValue = geoResult.geometry.location.lng()
                    const locationFieldValue = { type: "Point", coordinates: [ longValue, latValue ] }

                    return { 
                        selectedAvailabilityAddr: address,
                        selectedAvailabilityAddr_locality: addr_locality,
                        selectedAvailabilityAddr_area2: addr_area2,
                        selectedAvailabilityAddr_area1: addr_area1,
                        selectedAvailabilityCountry: country,
                        selectedAvailabilityLocation: locationFieldValue,
                    };

                });

            })
            .catch(error => console.log(error));
    };


    getEvents = async (range) => {

        // console.log('getEvents called with these range properties: ', range)

        let eventsArray = []
        const use24h = this.localeUses24HourTime();             
    
        await this.props.client
        .query({ 
            query: getMuahAvailability, 
            variables: { 
                muahId: this.props.values.id,  
                periodStart: moment(range.start).format('YYYY-MM-DD'),
                periodEnd: moment(range.end).format('YYYY-MM-DD'),   
            }, 
            fetchPolicy: 'no-cache',})
        .then(results => {

            if (JSON.stringify(results).length > 25) {

                
                const data = results.data.getMuahAvailability;
                // console.log('data', data)

                for (var q = data.length-1; q >= 0; q--) {

                    let displayClass = [""]
                    if (data[q].travel) {
                        displayClass = ["travelingEvent"]
                    }

                    let eventTitle = '';
                    if (use24h) {
                        eventTitle = data[q].st + ' - ' + data[q].et
                    } else {
                        eventTitle = moment(data[q].st, ["HH:mm"]).format("h A") + ' - ' + moment(data[q].et, ["HH:mm"]).format("h A")
                    }

                    eventsArray.push({
                        id: data[q].dId,
                        title: eventTitle,
                        start: new Date(parseInt(data[q].dt)),
                        className: displayClass,
                        dId: data[q].dId,
                    });
                    
                    
                } 
            }

        })
        .catch(error => { console.log(error) })

        // console.log('eventsArray', eventsArray)

        return eventsArray;

    }



    render() {

        moment.locale(i18next.language);

        const startOfWeek = moment().startOf('week').isoWeekday();  // const endOfWeek   = moment().endOf('week').isoWeekday();
        // let weekdayArr = []
        //                     if (startOfWeek === 1) { 
        //                         weekdayArr = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        //                     } else {
        //                         weekdayArr = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        //                     }
        const use24h = this.localeUses24HourTime(); 
        const localeForFC = i18next.language;

        
        const modalStyles = {
            overlay: { zIndex: 1000 },
            content: {
                top: '16px',
                left: '5%',
                margin: 'auto',
                bottom: 'auto',
                
                height: '630px',
                width: '350px',

                maxWidth: '74%',

                boxShadow: '0 20px 50px rgba(0, 0, 0, 0.33)',
                borderRadius: '5px',
                paddingTop: '16px',
                paddingLeft: '32px',
                paddingRight: '32px',
                                                    
                overflow : 'auto',
                 

            }
        }

        const modalStylesMobile = {
            overlay: { zIndex: 1000 },
            content: {
                
                left: '5%',
                margin: 'auto',
                bottom: 'auto',
                
                height: '90%',
                width: '73%',

                boxShadow: '0 20px 50px rgba(0, 0, 0, 0.33)',
                borderRadius: '5px',
                paddingTop: '16px',
                paddingLeft: '32px',
                paddingRight: '32px',
                                                    
                overflow : 'auto',
                 

            }
        }


       
        return (

            <PageContent>

                <PageTitle>Availability</PageTitle>

                
                <LinkButton 
                    onClick={() => { 
                        const today = new Date(); 
                        const formattedToday = moment(today).format('YYYY-MM-DD') 
                        this.addAvailability(formattedToday); 
                    }}
                >
                    Add availability for bookings
                </LinkButton>



                <IncreaseHeight/>


                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                        left: "prev,next",
                        center: "title",
                        //right: "dayGridMonth,timeGridWeek"
                        right: ""
                    }}
                    initialView="dayGridMonth"
                    expandRows={true}
                    height={500}

                    locale={localeForFC}
                    firstDay={startOfWeek}

                    editable={false}
                    selectable={false}
                    selectMirror={true}
                    dayMaxEvents={true}
                    //eventLimit={1}
                    //eventLimitText={" "}

                    events={(range) => this.getEvents(range)} //}
                    //eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                    eventContent={this.renderEventContent}
                    // datesSet={(displayRange) => console.log(displayRange)}
                    
                    dateClick={this.handleDateClick}
                    // dateClick={this.handleDateClick} //{this.handleDateClick}
                    eventClick={this.handleEventClick}
                    longPressDelay={1} //For touch devices, the amount of time the user must hold down before an event becomes draggable or a date becomes selectable.
                    selectLongPressDelay={1}

                    eventBackgroundColor='#ff9500'
                    eventBorderColor='#ff9500'
                    //weekNumbers={true}
                    
                />        

                <IncreaseHeight/>
                
                <LinkButton onClick={this.toggleAllAvailabilityConfirmDelete} >
                    Remove all availability from the calendar 
                </LinkButton>
                {
                    this.state.selectedAllAvailabilityConfirmDelete ?  
                        <ConfirmDelete  
                            text='Are you sure you would like to remove all the availability info from your calendar?'
                            closePopup={this.toggleAllAvailabilityConfirmDelete}
                            onConfirm={this.deleteAllAvailability}
                        />
                        : 
                        <></>  
                }  

                <IncreaseHeight/>
                <IncreaseHeight/>
                

                <Modal 
                    isOpen={this.state.activeView === "availabilityDetails"}
                    //onRequestClose={() => { this.setState({ setLocationFilter: !this.state.setLocationFilter, })  }}
                    ariaHideApp={false}
                    style={window.innerWidth > 790 ? modalStyles : modalStylesMobile}
                    onAfterOpen={() => {
                            document.body.style.top = `0px`
                            document.body.style.width = `100vw`
                            document.body.style.position = 'fixed'
                        }}

                        onAfterClose={() => {
                            const scrollY = document.body.style.top
                            document.body.style.position = ''
                            document.body.style.top = ''
                            document.body.style.width = ''
                            window.scrollTo(0, parseInt(scrollY || '0') * -1)
                        }}
                >
                
                    <>
                            
                    <IncreaseHeight/>
                
                    {
                        this.state.selectedAvailabilityId === '%%-----## -!new-availability!- ##------%%' ?
                            (<PopupHeader>Select the date or dates, and time, when you are available for booking</PopupHeader>)
                        :
                            (<PopupHeader>Edit availability</PopupHeader>)
                    }

                    <IncreaseHeight/>

                    <DayPickerSingleDateController
                        numberOfMonths={1}
                        onDateChange={this.handleDatePickerChange}
                        focused={true}
                        initialVisibleMonth={() => moment(this.state.selectedAvailabilitySd)}
                        renderCalendarDay={props => {
                            const { day, modifiers } = props
                            try {
                                if (this.state.selectedAvailabilityDates.includes(moment(day).format('YYYY-MM-DD'))) {
                                modifiers && modifiers.add('selected')
                                } else {
                                    modifiers && modifiers.delete('selected')
                                }
                            } catch {
                                console.log('err')
                            }
                            
                            return ( 
                                
                                <CalendarDay   
                                    { ...props } 
                                    modifiers={modifiers} 
                                    onClick={() => day.onDayClick(day.day)}
                                /> 
                                
                            )
                            
                        }} 
                            
                    />

                                    
                    {/* From & To  */}
                    <Container>
                        <ColumnWrapper>
                            <ColItem2c>
                                <LabelStyled>From:</LabelStyled>
                                <Select 
                                    name='selectedAvailabilitySt'
                                    classNamePrefix="select"
                                    styles={colourStyles}
                                    options={this.timeOptions(use24h)}
                                    value={ this.timeOptions(use24h).find(op => op.code === this.state.selectedAvailabilitySt ) }
                                    defaultvalue={this.timeOptions(use24h).find(op => op.code === this.state.selectedAvailabilitySt )}
                                    isSearchable={true}
                                    menuShouldScrollIntoView={true}
                                    onChange={(e) => { 
                                        const startValue = e.value;
                                        const endValue = this.timeOptions(use24h).find(op => { return op.code === this.state.selectedAvailabilityEt }).value
                                        if (parseInt(startValue) > parseInt(endValue)) {
                                            setTimeout(() => { 
                                                this.setState({ 
                                                    selectedAvailabilitySt: e.code,
                                                    selectedAvailabilityEt: e.code
                                                    }); 
                                                }, 50)
                                        } else {
                                            setTimeout(() => { this.setState({ selectedAvailabilitySt: e.code, }); }, 50) 
                                        }
                                    }}
                                />

                            </ColItem2c>

                            <ColItem2d>
                                <LabelStyled>To:</LabelStyled>
                                <Select 
                                    name='selectedAvailabilityEt'
                                    classNamePrefix="select"
                                    styles={colourStyles}
                                    options={this.timeOptions(use24h)}
                                    value={this.timeOptions(use24h).find(op => { return op.code === this.state.selectedAvailabilityEt })}
                                    defaultValue={this.timeOptions(use24h).find(op => { return op.code === this.state.selectedAvailabilityEt })}
                                    isSearchable={true}
                                    onChange={(e) => { 
                                        const startValue = this.timeOptions(use24h).find(op => { return op.code === this.state.selectedAvailabilitySt }).value
                                        const endValue = e.value;
                                        if (parseInt(startValue) > parseInt(endValue)) {
                                            setTimeout(() => { 
                                                this.setState({ 
                                                    selectedAvailabilitySt: e.code,
                                                    selectedAvailabilityEt: e.code
                                                    }); 
                                                }, 50)
                                        } else {
                                            setTimeout(() => { this.setState({ selectedAvailabilityEt: e.code, }); }, 50) 
                                        }
                                    }}
                                />
                            
                            </ColItem2d>
                            <></>
                        </ColumnWrapper>
                    </Container>


                    <IncreaseHeight/>
                    <IncreaseHeight/>

                    <FormControlLabel 
                        control={
                            <OrangeCheckBox
                                name="chkTravel"
                                checked={this.state.selectedAvailabilityTravel}
                                onChange={(e) => { 

                                    //extra checks are needed only when when activating
                                    if (e.target.checked) {
                                        
                                        if ((this.props.values.isPro === true)) {

                                            const staatus = e.target.checked
                                            setTimeout(() => { this.setState({ selectedAvailabilityTravel: staatus }); }, 50) 
                                        
                                        } else  {
                                            this.setState({ showUpgradeModal: true });
                                        }

                                    } else {
                                        const staatus = e.target.checked
                                        setTimeout(() => { this.setState({ selectedAvailabilityTravel: staatus }); }, 50) 
                                    }

                                }}
                                value="travel"
                            />
                        }
                    label={"I will be traveling on those dates"}
                    />     

                    { this.state.showUpgradeModal ? 
                        <UpgradeModal
                            showUpgradeModal={this.state.showUpgradeModal}
                            closePopup={() => this.setState({ showUpgradeModal: false }) }
                            popTitle="Your free Hobby account cannot set multi-location availability"
                            popDescription="To set this, please upgrade to the Pro account."
                        />
                    : null
                    }

                    {
                        this.state.selectedAvailabilityTravel ?
                            <>
                                <IncreaseHeight/>

                                <LabelStyled>Enter the location where people can book you:</LabelStyled>

                                <PlacesAutocomplete
                                    name={'selectedAvailabilityAddr'}
                                    id={'selectedAvailabilityAddr'}
                                    value={this.state.selectedAvailabilityAddr}
                                    onChange={this.handlePlacesChange}
                                    onSelect={this.handlePlacesSelect}
                                    // onError={this.handleError}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            
                                            <InputStyled
                                                {
                                                ...getInputProps({
                                                    placeholder: "",
                                                    })
                                                }
                                            />
                                            
                                            { 
                                                suggestions.length ?
                                                <DropDownContainer>
                                                
                                                    {loading && <InputSuggestion>Loading...</InputSuggestion>}
                                                    
                                                    {
                                                        suggestions.map((suggestion, index) => {
                                                        
                                                            //const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                                                            
                                                            const style = suggestion.active ? 
                                                                { backgroundColor: "#cbcbcb", cursor: "pointer" }
                                                                : 
                                                                { backgroundColor: "#ffffff", cursor: "pointer" };
                                                            
                                                            return (
                                                                <InputSuggestion 
                                                                    key={index}
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        //className,
                                                                        style,
                                                                        })
                                                                    }
                                                                >
                                                                    <span key={index}>{suggestion.description}</span>
                                                                </InputSuggestion>
                                                            );
                                                        })}

                                                </DropDownContainer>
                                                :
                                                <></>
                                            }

                                        </div>

                                    )}

                                </PlacesAutocomplete>


                            </>
                            :
                            null
                    }

                    <IncreaseHeight/>

                    {/* <IncreaseHeight/>

                    <RadioGroup 
                        aria-label="availability-mode" 
                        name="mode" 
                        value={this.state.selectedAvailabilityMode} 
                        onChange={(e) => { 
                                    const mode = e.target.value;
                                    setTimeout(() => { this.setState({ selectedAvailabilityMode: mode }); }, 50) 
                        }}
                    >
                        <FormControlLabel value="0" control={<OrangeRadio />} label="Apply to specific dates" />
                        <FormControlLabel value="1" control={<OrangeRadio />} label="Apply to repeating days of the week" />
                    </RadioGroup> 

                    <IncreaseHeight/>
                    <IncreaseHeight/>
                    
                        {
                            this.state.selectedAvailabilityMode === '0' ?
                                <>
                                    
                                    <AddLeftMargin>
                                        
                                        <DayPickerSingleDateController
                                            numberOfMonths={1}
                                            onDateChange={this.handleDatePickerChange}
                                            focused={true}
                                            initialVisibleMonth={() => moment(this.state.selectedAvailabilitySd)}
                                            renderCalendarDay={props => {
                                                const { day, modifiers } = props
                                                if (this.state.selectedAvailabilityDates.includes(moment(day).format('YYYY-MM-DD'))) {
                                                    modifiers && modifiers.add('selected')
                                                } else {
                                                    modifiers && modifiers.delete('selected')
                                                }
                                                return ( <CalendarDay { ...props } modifiers={modifiers} /> )
                                            }} 
                                                
                                        />

                                    
                                        
                                        <FormControlLabel 
                                            control={
                                                <OrangeCheckBox
                                                    name="chkTravel"
                                                    checked={this.state.selectedAvailabilityTravel}
                                                    onChange={(e) => { 
                                                        const staatus = e.target.checked
                                                        setTimeout(() => { this.setState({ selectedAvailabilityTravel: staatus }); }, 50) 
                                                        }}
                                                    value="travel"
                                                />
                                            }
                                        label={"I will be traveling on those dates"}
                                        />     

                                        <IncreaseHeight/>

                                        {
                                            this.state.selectedAvailabilityTravel ?
                                                <>
                                                    <LabelStyled>Enter the location where people can book you:</LabelStyled>

                                                    <PlacesAutocomplete
                                                        name={'selectedAvailabilityAddr'}
                                                        id={'selectedAvailabilityAddr'}
                                                        value={this.state.selectedAvailabilityAddr}
                                                        onChange={this.handlePlacesChange}
                                                        onSelect={this.handlePlacesSelect}
                                                        // onError={this.handleError}
                                                    >
                                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                            <div>
                                                                
                                                                <InputStyled
                                                                    {
                                                                    ...getInputProps({
                                                                        placeholder: "",
                                                                        })
                                                                    }
                                                                />
                                                                
                                                                { 
                                                                    suggestions.length ?
                                                                    <DropDownContainer>
                                                                    
                                                                        {loading && <InputSuggestion>Loading...</InputSuggestion>}
                                                                        
                                                                        {
                                                                            suggestions.map((suggestion, index) => {
                                                                            
                                                                                //const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                                                                                
                                                                                const style = suggestion.active ? 
                                                                                    { backgroundColor: "#cbcbcb", cursor: "pointer" }
                                                                                    : 
                                                                                    { backgroundColor: "#ffffff", cursor: "pointer" };
                                                                                
                                                                                return (
                                                                                    <InputSuggestion 
                                                                                        key={index}
                                                                                        {...getSuggestionItemProps(suggestion, {
                                                                                            //className,
                                                                                            style,
                                                                                            })
                                                                                        }
                                                                                    >
                                                                                        <span key={index}>{suggestion.description}</span>
                                                                                    </InputSuggestion>
                                                                                );
                                                                            })}

                                                                    </DropDownContainer>
                                                                    :
                                                                    <></>
                                                                }

                                                            </div>

                                                        )}

                                                    </PlacesAutocomplete>


                                                </>
                                                :
                                                null
                                        }
                                        

                                    </AddLeftMargin>
                                    

                                </>
                            :
                                <>


                                <AddLeftMargin>
                                    <LabelStyled>Start from:</LabelStyled>
                                    <ColItem2e>
                                        <SingleDatePicker
                                            date={moment(this.state.selectedAvailabilitySd)} // momentPropTypes.momentObj or null
                                            onDateChange={date => {
                                                
                                                const newValue = moment(date);
                                                const maxValue = moment(this.state.selectedAvailabilityEd);

                                                if ( newValue > maxValue ) {
                                                    setTimeout(() => { 
                                                        this.setState({ selectedAvailabilityEd: moment(maxValue).format('YYYY-MM-DD') }); 
                                                        }, 50)
                                                } else {
                                                    setTimeout(() => { this.setState({ selectedAvailabilitySd: moment(newValue).format('YYYY-MM-DD') }); }, 50) 
                                                }

                                            }} 
                                            focused={this.state.selectedAvailabilitySdFocused} // PropTypes.bool
                                            onFocusChange={({ focused }) => this.setState({ selectedAvailabilitySdFocused: focused })} // PropTypes.func.isRequired
                                            id="selectedAvailabilitySd" // PropTypes.string.isRequired,
                                            numberOfMonths={1}
                                            anchorDirection="left"
                                            displayFormat="L" //LL //ll
                                            // isOutsideRange={(day) => day > moment(this.state.selectedAvailabilityEd).add('M', 12).calendar()}
                                        />
                                    </ColItem2e>
                                    <IncreaseHeight/>
                                    <IncreaseHeight/>
                                    <IncreaseHeight/>
                                    <IncreaseHeight/>
                                </AddLeftMargin>
                                

                                <ColumnWrapper>
                                    <AddLeftMargin>
                                        <OrangeButtonGroup
                                            value={this.state.selectedAvailabilityWeekdays}
                                            onChange={this.handleWeekdays}
                                            aria-label="Days"
                                        >
                                        { 
                                            weekdayArr.map((day, index) => {
                                                return (
                                                    <OrangeToggleButton 
                                                        size="small"
                                                        key={index}
                                                        value={day} 
                                                        aria-label={day}
                                                    >
                                                        {day}
                                                    </OrangeToggleButton>
                                                );
                                            })
                                        }
                                        </OrangeButtonGroup>
                                    </AddLeftMargin>
                                </ColumnWrapper>
                                

                                <IncreaseHeight/>
                                <IncreaseHeight/>
                                
                                <AddLeftMargin>
                                    <LabelStyled>Repeat until (1 year max):</LabelStyled>
                                    <ColItem2e>
                                        <SingleDatePicker
                                            date={moment(this.state.selectedAvailabilityEd)} // momentPropTypes.momentObj or null
                                            onDateChange={date => {
                                                
                                                const today = new Date();
                                                const newValue = moment(date);
                                                const maxValue = moment(today).add('M', 12).format('YYYY-MM-DD');

                                                if ( moment(newValue) > moment(maxValue) ) {
                                                    setTimeout(() => { 
                                                        this.setState({ selectedAvailabilityEd: moment(maxValue).format('YYYY-MM-DD') }); 
                                                        }, 50)
                                                } else {
                                                    setTimeout(() => { this.setState({ selectedAvailabilityEd: moment(newValue).format('YYYY-MM-DD') }); }, 50) 
                                                }

                                            }} 
                                            focused={this.state.selectedAvailabilityEdFocused} // PropTypes.bool
                                            onFocusChange={({ focused }) => {
                                                // window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'});
                                                this.setState({ selectedAvailabilityEdFocused: focused })}
                                                } // PropTypes.func.isRequired
                                            id="selectedAvailabilityEd" // PropTypes.string.isRequired,
                                            numberOfMonths={1}
                                            anchorDirection="left"
                                            // appendToBody
                                            openDirection="up"
                                            
                                            displayFormat="L" //LL //ll
                                            // isOutsideRange={(day) => day > moment(this.state.selectedAvailabilityEd).add('M', 12).calendar()}
                                        />
                                    </ColItem2e>
                                    <IncreaseHeight/>
                                    <IncreaseHeight/>
                                    <IncreaseHeight/>
                                    <IncreaseHeight/>
                                </AddLeftMargin>
                                
                                </>
                        }
                                                    
                        */}


                    <BottomButtonArea>

                        {
                            this.state.selectedAvailabilityId !== "%%-----## -!new-availability!- ##------%%" ? 
                                (
                                    <ButtonAreaLeft>
                                        <StyledCancel onClick={ () => this.deleteAvailability(this.state.selectedAvailabilityId) }>Remove</StyledCancel>
                                    </ButtonAreaLeft>
                                )
                            :
                            <ButtonAreaLeft></ButtonAreaLeft>
                        }

                        <ButtonAreaRight>
                            <StyledSave onClick={this.saveAvailability}>Save</StyledSave> 
                            <StyledCancel onClick={this.cancelAvailabilityEdit}>Cancel</StyledCancel>
                        </ButtonAreaRight>
                    
                    </BottomButtonArea>
                    
                </>

                </Modal>
            
                

                

            </PageContent>


        )

    }
    
}




export const AvailabilityPage = withStore(withApollo(C), ['']);


// const ColItem2e = styled.div`
//     width: calc(50% - 68px);
//     display: relative;
//     float:left;

//     @media (max-width: 890px) {
//         width: 100%;
//         margin-top: 12px; 
//     }

// `;

const InputStyled = styled.input`
    
    width: calc(100% - 18px);
    padding: 0px;
    margin: 0px;
    height: 24px;
    padding: 8px;
    font-size: 16px;
    font-family: 'Fira Sans';
    font-weight: 400;

    color: #333333;
    
    border: 1px solid #cbcbcb;
    border-radius: 4px;  
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    :focus {
        outline-color: #4C9AFF;
    }

    :disabled {
        background-color: #f7f7f7; 
    }


    @media (max-width: 690px) {
        font-size: 17px;
    }

`;

// const OrangeButtonGroup = withStyles((theme) => ({
//     grouped: {
//         //   margin: theme.spacing(0.5),
//         border: 'none',
//         '&:not(:first-child)': {
//             borderRadius: 4,
//         },
//         '&:first-child': {
//             borderRadius: 4,
//         },
//     },
    
// }))(ToggleButtonGroup);


// const OrangeToggleButton = withStyles((theme) => ({
//     root: {
//         '&.Mui-selected': {
//             color: '#ffffff',
//             backgroundColor: '#ff9500',
//         },
//         '&:hover': {
//             background: '#ff9500',
//             opacity: 0.9,
//             color: '#ffffff',
//             },
//         '&:disabled': {
//             background: '#ffffff',
//             opacity: 0.9,
//             color: '#cdcdcd',
//             }
//     },
// }))(ToggleButton);

const OrangeCheckBox = withStyles({
    root: {
      color: orange[400],
      '&$checked': { color: orange[600], },
      '&$disabled': { color: grey[100], },
    },
    checked: {},
    disabled: {color: grey[100],}
    })(props => <Checkbox color="default" {...props} /> )
;

// const OrangeRadio = withStyles({
//     root: {
//       color: orange[400],
//       '&$checked': { color: orange[600], },
//       '&$disabled': { color: grey[100], },
//     },
//     checked: {},
//     disabled: {color: grey[100],}
//     })((props) => <Radio color="default" {...props} />)
// ;

const Container = styled.div`

    width: 100%;

    @media (max-width: 890px) {
        width: 100%;
        min-width: 100%;
    }

`;

const ColumnWrapper = styled.div`
    width: 100%;
    min-width: 100%;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;

    @media (max-width: 890px) {
        flex-direction: column;
        justify-content: flex-start;
    }
`;


// const AddLeftMargin = styled.div`
//     margin-left: 32px;
// `;

const ColItem2c = styled.div`
    width: 50%;
    display: relative;
    float: left;

    @media (max-width: 890px) {
        width: 45%;
        
    }

`;

const ColItem2d = styled.div`

    width: 50%;
    /* margin-right: -18px; */
    margin-left: 16px;
    display: relative;
    float: left;

    @media (max-width: 890px) {
        width: 45%;
        
    }

`;



const IncreaseHeight = styled.div`
    
    height: 16px;  

`;


const LinkButton = styled.div`
    
    color: #4C9AFF;

    margin: 0 8px 0 0; 

    font-weight: 400;
    font-size: 16px;

    &:hover {
        text-decoration: underline;
        cursor: pointer
        }

`;




const PageContent = styled.div`
    
    width: 100%;
    height: 100%;
    min-height: 75vh;
    font-family: 'Fira Sans';
    
`;

const PageTitle = styled.div`
    
    display: flex;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;

    display: ${(props) => props.showAlways === 'true' ? "block" : 'none'};

    @media (max-width: 790px) {
        display: block;
    }


`;


const PopupHeader = styled.div`
    
    font-size: 18px;
    color: #333333;
    font-family: 'Fira Sans';

    font-weight: 500;
    margin-bottom: 16px;

`;


const LabelStyled = styled.div`

    color: #333333;
    font-family: 'Fira Sans';
    font-size: 16px;
    font-weight: 400;
    
    margin-bottom: 4px;
    margin-top: 0px;
    margin-left: 2px;
`;



const BottomButtonArea = styled.div`
    width:100%;
    min-width: 100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;

`;

const ButtonAreaLeft = styled.div`

    justify-content: space-between;

`;

const ButtonAreaRight = styled.div`
    justify-content: space-between;

`;


const StyledSave = styled(Button)`
    && {
        height: 40px;
        
        background: ${(props) => props.disabled ? "white": '#ff9500'};
        box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

        color: white;
        
        
        padding-left: 16px;
        padding-right: 16px;
        margin-right: 16px;
        margin-bottom: 32px;
        margin-top: 16px;

        text-transform: none;
        font-weight: 400;
        font-size: 17px;
            
        &:hover {
            background: ${(props) => props.disabled ? "white" : '#ff9500'};
            opacity: 0.9;
            color: white;
        }
    }
    
`;

const StyledCancel = styled(Button)`
    && {
    height: 40px;
    margin-top: 16px;

    padding-left: 16px;
    padding-right: 16px;

    margin-bottom: 32px;

    color: #333333;
    text-transform: none;
    font-weight: 400;
    font-size: 17px;

    @media (max-width: 790px) {
        border: 1px solid #efefef;
        box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};
    }
    
    }
    
`;




const colourStyles = {

    control: (styles, {isDisabled, isFocused}) => ({ ...styles, 
        
        margin: 0,
        height: 42,
        paddingTop: 2,
        
        fontSize: 16,
        fontFamily: 'Fira Sans',
        fontWeight: '400',
        
        
        backgroundColor: isDisabled ? '#f7f7f7' : null,
        color: '#333333',
        
        borderRadius: 4,
        border: isFocused ? '2px solid #4C9AFF' : '1px solid #cbcbcb',
        boxShadow: '0 2px 8px 0 rgba(170, 170, 170, 0.2);',
        //boxShadow: isFocused ? '0 2px 8px 0 rgba(76, 154, 255, 0.8)' : '0 2px 8px 0 rgba(170, 170, 170, 0.2)',

        }),
    
    singleValue: base => ({
        ...base,
        paddingTop: 2,
        color: '#333333',
        marginLeft: 0,
        }),
    
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        
        return {
            ...styles,

            cursor: isDisabled ? 'not-allowed' : 'default',
        
            fontSize: 16,
            fontFamily: 'Fira Sans',
            fontWeight: '400',
            height: 42,

            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && ('#4C9AFF'),

            },


        };
    },
    menu: base => ({
        ...base,
        // color: '#333333',
        
        borderRadius: 4,
        border: '2px solid #4C9AFF',
        boxShadow: '0 2px 8px 0 rgba(170, 170, 170, 0.2);',

        marginLeft: '-1px',

        width: 'calc(100% + 2px);',
        zIndex: 100,

    }),

}


const DropDownContainer = styled.div`

    border: 2px solid #4C9AFF; 
    border-radius: 4px;  
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);
    outline-color: #4C9AFF;

    @media (max-width: 690px) {
        font-size: 17px;
    }

`;


const InputSuggestion = styled.div`

    width: calc(100% - 18px);
    padding: 0px;
    margin: 0px;
    height: 24px;
    padding: 8px;
    font-size: 16px;

    color: #333333;
    font-family: 'Fira Sans';

    :focus {
        outline-color: #4C9AFF;
    }

    :disabled {
        background-color: #f7f7f7; 
    }


    @media (max-width: 690px) {
        font-size: 17px;
    }

`;


