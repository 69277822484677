
import React, { PureComponent } from 'react'
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";


import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';
// import { PaymentElement } from '@stripe/react-stripe-js';
//import { PaymentElement, LinkAuthenticationElement, useStripe, useElements, ElementsConsumer, CardElement, } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faTimes } from '@fortawesome/free-solid-svg-icons'

import { MyError } from "../components/MyError";

import moment from "moment"; 
import i18next from "i18next";
import '../../i18n'

import { withStore } from '@spyna/react-store'
import { withApollo } from "react-apollo";
import { withRouter } from 'react-router-dom';

import { Query } from "react-apollo";
import gql from "graphql-tag";

import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

import toast from 'toasted-notes' 
import 'toasted-notes/src/styles.css';


const stripePromise = window.location.hostname === 'localhost' ? 
    loadStripe('pk_test_51MiJY8BYeQzxnFZwx8q9NVIEDGUsG0CLOAAXbHXbMvwA6uSBAVvh2CXE4NbdvwLWLwhxKUzywDKJP4nVVDtu74ls00P83XzWrm') 
    : 
    loadStripe('pk_live_51MiJY8BYeQzxnFZwrVxG6Yb3qfOel2uHqEDeEOd1NGELSxP0V3HKdYDlPXEOA5XpjDCudPMaBaFKoci9r3ZlbTR200PAG4XByF');
const stripeIntent = gql`
    query($type: String, $chargeAmount: Float, $currency: String) {
        stripeIntent(type: $type, chargeAmount: $chargeAmount, currency: $currency)
    }
`;

const refundPolicyQuery = gql`{ getKey(key:"refundPolicy") {value, key} }`;
const termsQuery = gql`{ getKey(key:"terms") {value, key} }`;
const paymentTermsQuery = gql`{ getKey(key:"paymentTerms") {value, key} }`;
const privacyQuery = gql`{ getKey(key:"privacy") {value, key} }`;


const today = new Date();


const createBookingMutation = gql`
    mutation(
        $input: BookingInput!
    ) {
        createBooking(
            input: $input
        ) { id }
    }
`;


class C extends PureComponent {
    
    constructor(props) {
        super(props);
        this.state = {
            showCategoryModal: false,
            showTimeModal: false,
            showTermsPayment: false,
            showTermsRelease: false,
            showTermsRefund: false,
            availableTimesFinished: false,
            calendarAvailabilityConfirmed: true, //here a default
            lastBookingDate: '',
            lastPricingUpdate: '',
            
            packageIndex: 0,
            availabilityRangeStart: moment().format("YYYY-MM-01"),
            availabilityLoadingFinished: true,
            availabilityArray: [],
            
            availableTimes: [],
            availabilitySlotStart: '00:00',
            availabilitySlotEnd: '23:30',

            travelNotices: [],
            
            startTime: '-',
            startTimeStart: '',
            startTimeEnd: '',

            bookingDate: '',
            bookingDateString: 'Select when you would like to have a photoshoot',
            bookingLocation: 'To confirm the photoshoot location, please select the date.',

            totalAmountString: '',
            paidNowString: '',
            paidLaterString: '',
            messageField: '',

            currency: '',
            packagePrice: 0,
            bookingFee: 0,
            totalAmount: 0,
            paidNow: 0,
            paidLater: 0,
            amountSentToStripe: 0,
            stripeIntentCreatedWith: 0,
            
            displayPrice: 0,
            displayBookingFee: 0,
            displayTotalAmount: 0,
            errorMsg: '',

            showStripeModal: false,
            chargeMinServiceFee: false,
            proceedWithStripe: false,

        }

        this.fldValueChange = this.fldValueChange.bind(this);

    }

    fldValueChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({ [name]: value, });
        
    }
    


    findCancellationPolicy = (event, bookingDate) => {
        
        let cancelAfterWord = '24 hours'

        if (event.cancelAfter === 1) { cancelAfterWord = '24 hours' }
        else if (event.cancelAfter === 2) { cancelAfterWord = '2 days' }
        else if (event.cancelAfter === 3) { cancelAfterWord = '3 days' }
        else if (event.cancelAfter === 5) { cancelAfterWord = '5 days' }
        else if (event.cancelAfter === 7) { cancelAfterWord = '7 days' }
        else { cancelAfterWord = '7 days' }

        let description = `Cancelling your event is free and you will get full refund if you cancel within ${cancelAfterWord} after booking, or at least ${event.cancelBefore} days before the event start date.`


        if (bookingDate !== '') {

            const now = moment(new Date()); //todays date
            const bDate = moment(bookingDate)
            const finalFreeRefundDate = moment(bookingDate).subtract(event.cancelBefore, 'd');
            const hoursBetween = bDate.diff(now, 'hours')
            const finalRefundDateString = moment(finalFreeRefundDate).format('LL');

            if (finalFreeRefundDate < now) {

                //Too close already (free cancellation in the past)
                description = `We can refund you ${(100-event.lateCancellationKeeps)}% of the total paid amount if you make the request at least ${event.noCancellationsAfter} hours before your scheduled event. If you cancel with less than ${event.noCancellationsAfter} hours notice, we can refund you 50% of the total paid amount.` 

                if (hoursBetween < event.noCancellationsAfter) {
                    //description = `Since your photoshoot is scheduled to begin in less than ${event.noCancellationsAfter} hours, the booking amount is non-refundable.`
                    description = `Since your event is scheduled to begin in less than ${event.noCancellationsAfter} hours, if you need to cancel, we can offer a refund of 50% of the total paid amount.`
                }

            } else {

                description = description + `\n\nIf you request a cancellation after ${finalRefundDateString} but at least ${event.noCancellationsAfter} hours prior to your scheduled event, we will refund you ${(100-event.lateCancellationKeeps)}% of the total paid amount.\n\nIf you cancel less than ${event.noCancellationsAfter} hours before your scheduled event, we will refund you 50% of the total paid amount.` 

            }

        } else {

            description = description + `\n\nIf you request a cancellation less than ${event.cancelBefore} days before, we will refund you ${(100-event.lateCancellationKeeps)}% of the total paid amount.\n\nIf you cancel less than ${event.noCancellationsAfter} hours before your scheduled event, we will refund you 50% of the total paid amount.` 
            
        }

        description = description + `\n\nBefore cancelling your booking, we suggest reaching out to your event host to see if it's possible to reschedule for a different date and time, which would eliminate the need to cancel altogether.`



        return description

    }


    findPaymentInstructions = () => {

        let paymentInstructions = '';

        if (this.state.chargeMinServiceFee === true) {

            if ((this.state.instantBooking === true) && (this.state.calendarAvailabilityConfirmed === true)) {
                //nothing needs to be said, as instant booking is active. and they see the price. this is what we charge, immediatelly.
                //paymentInstructions = `Currently, only a booking fee of ${this.state.paidNowString} will be charged.\n\nThe remaining balance of your total, which amounts to ${this.state.paidLaterString}, is to be paid directly to the photographer after the photoshoot, and will not be charged by PhotoSouls.`
            } else {
                paymentInstructions = "You will only be charged after the host confirms your booking."
            }

        } else {

            //total is bigger than the service fee.

            //First let's see if full payment lifecycle is handled by Stripe Express or not.
            if (this.props.store.get("StripeExpressID") === '') {

                //no Stripe express id. 'Charge just service fee, and the rest is paid directly, off-site
                // console.log('this.state.paidLater', this.state.paidLater);
                // console.log('this.state.paidLaterString', this.state.paidLaterString);

                if ((this.state.instantBooking === true) && (this.state.calendarAvailabilityConfirmed === true)) {
                    paymentInstructions = `Currently, only a booking fee of ${this.state.paidNowString} will be charged.`
                    if (this.state.paidLater > 0) {
                        paymentInstructions = paymentInstructions + `\n\nThe remaining balance of your total, which amounts to ${this.state.paidLaterString}, is to be paid directly to the event host after the event, and will not be charged by PhotoSouls.`
                    }
                } else {
                    paymentInstructions = `After the event host confirms your booking, a booking fee of ${this.state.paidNowString} will be charged.`
                    if (this.state.paidLater > 0) {
                        paymentInstructions = paymentInstructions + `\n\nThe remaining balance of your total, which amounts to ${this.state.paidLaterString}, is to be paid directly to the event host after the event, and will not be charged by PhotoSouls.`
                    }
                }

                
            } else {

                //stripe express id exists. we can handle the entire payment flow.

                if ((this.state.instantBooking === true) && (this.state.calendarAvailabilityConfirmed === true)) {
                    paymentInstructions = '' // nothing needs to be told. customer is charged.
                } else {
                    paymentInstructions = "The total booking amount will only be charged after the event host confirms your booking."
                }


            }
        }

        return paymentInstructions;

    }


    applyDate = (object) => {
        
        setTimeout(() => { 

            this.setState(object); 
            
            window.scrollTo({top: 0, behavior: 'smooth'});

        }, 50)
        
    }




    validateBooking = async () => {

        // console.log('TIME FOR BOOKING. Current State for backend: ', this.state)

        let hasErrors = false;

        if (this.state.bookingPackageId === '') {

            hasErrors = true;
            setTimeout(() => { this.setState({ errorMsg: `Please select when you would like to participate`, }); }, 50);

        } else if (moment(this.state.startDate) < moment(today)) {

            hasErrors = true;
            setTimeout(() => { this.setState({ errorMsg: `You cannot book past events. Please select another date`, }); }, 50);

        } 

    
        if (hasErrors === false) {
         
            // Ready to contienue with Stripe for next, 

            let stripeIntentType = 'payment'; //default;
            if ((this.state.instantBooking === true) && (this.state.calendarAvailabilityConfirmed === true)) {
                stripeIntentType = 'payment' // immediate charge
            } else {
                stripeIntentType = 'setup' // for later chargin when confirmed
            }

            //create Stripe intent ... but it's only needed when the amount to be paid has changed since the last intent creation (which includes the first time)
            //if (this.state.stripeIntentCreatedWith !== this.state.paidNow) { DON't use this restriction! Client can go back, change the date, and from imemdiate paymentintent, we should now use setupintent instead in some cases, when setting up stripe on backend!!!!

                await this.props.client.query({ 
                    query: stripeIntent, 
                    variables: { 
                        type: stripeIntentType,
                        chargeAmount: (this.state.paidNow*100),
                        currency: this.state.displayCurrency
                    }, 
                    fetchPolicy: 'no-cache',})
                .then(results => {

                    // console.log('---  results from backend: ', results)
                    
                    if (JSON.stringify(results).length > 15) {
                        
                        this.setState({ 
                            stripeClientSecret: results.data.stripeIntent,
                            stripeIntentCreatedWith: (this.state.paidNow*100),
                            proceedWithStripe: true,
                        });

                    }

                })
                .catch(error => { 
                    setTimeout(() => { this.setState({ errorMsg: `Unable to connect with Stripe for payment information. Please try again later.`, }); }, 50);
                    console.log(error) 
                })

            //}

        }


    }



    postBooking = async () => {

        //console.log('TIME FOR BOOKING. Current State for backend: ', this.state)


        let resultOK = false;
        let variablesForInput = {};

        let instantBooking = false; //default
        if ((this.state.instantBooking === true) && (this.state.calendarAvailabilityConfirmed === true)) {
            instantBooking = true;
        } else {
            instantBooking = false;
        }

        if (this.state.messageField.length > 0) {

            variablesForInput = {

                status: 0,  //  STATUS=0 (AWAITING) AS DEFAULT, ALWAYS, until BACKEND DECIDES OTHERWISE.  0=awaiting      1=confirmed      2=delivered    3=rejected      4=cancelled      5=refunded    6=chargeback
                
                dt: this.state.startDate,
                dt2: this.state.endDate,

                st: this.state.startTime,
                et: this.state.endTime,
                
                provider: 4, //    0=photog      1=model      2=muah      3=studio      4=event
                eventId: this.props.data.id,
                
                packageId: this.state.bookingPackageId,
                packageName: this.state.packageName,
                eventDesc:  this.state.eventDesc,
            
                duration: this.state.bookingPackageDuration,
            
                //catNumber: parseInt(this.state.categoryNumber),
            
                location: this.state.bookingLocation, 
            
                currency: this.state.currency,
                price: this.state.packagePrice,
                bookingFee: this.state.bookingFee,
                total: this.state.totalAmount,
                
                // commission: this.state.paidNow,
                // payout: this.state.paidLater,

                cancelAfter: this.state.cancelAfter,
                cancelBefore: this.state.cancelBefore,
                chkNoLateBookingCancels: this.state.chkNoLateBookingCancels,
                lateBookingHours: this.state.lateBookingHours,
                
                instantBooking: instantBooking,
                stripePaymentIntent: instantBooking ? stripeIntent : '',
                stripeSetupIntent: instantBooking ? '' : stripeIntent,
                
                userCurrency: this.state.displayCurrency,
                exchangeRate: this.state.displayCurrencyRate,
                userCurrencyPrice: this.state.displayPrice,
                userCurrencyBookingFee: this.state.displayBookingFee,
                userCurrencyTotal: this.state.displayTotalAmount,
                userCurrencyPaidNow: this.state.paidNow,
                userCurrencyPaidLater: this.state.paidLater,
            
                euroRate: this.state.euroRate,
                
                message: {
                    dt: new Date(), 
                    from: 0, //0=client, 1=provider 
                    message: this.state.messageField,
                }, 

            }

        } else {

            variablesForInput = {

                status: 0,  //  STATUS=0 (AWAITING) AS DEFAULT, ALWAYS, until BACKEND DECIDES OTHERWISE.  0=awaiting      1=confirmed      2=delivered    3=rejected      4=cancelled      5=refunded    6=chargeback
                
                dt: this.state.startDate,
                dt2: this.state.endDate,

                st: this.state.startTime,
                et: this.state.endTime,
                
                provider: 4, //    0=photog      1=model      2=muah      3=studio      4=event
                eventId: this.props.data.id,
                
                packageId: this.state.bookingPackageId,
                packageName: this.state.packageName,
                eventDesc:  this.state.eventDesc,

                duration: this.state.bookingPackageDuration,
            
                //catNumber: parseInt(this.state.categoryNumber),
            
                location: this.state.bookingLocation, 
            
                currency: this.state.currency,
                price: this.state.packagePrice,
                bookingFee: this.state.bookingFee,
                total: this.state.totalAmount,
                
                //commission: this.state.paidNow,
                //payout: this.state.paidLater,

                cancelAfter: this.state.cancelAfter,
                cancelBefore: this.state.cancelBefore,
                chkNoLateBookingCancels: this.state.chkNoLateBookingCancels,
                lateBookingHours: this.state.lateBookingHours,

                userCurrency: this.state.displayCurrency,
                exchangeRate: this.state.displayCurrencyRate,
                userCurrencyPrice: this.state.displayPrice,
                userCurrencyBookingFee: this.state.displayBookingFee,
                userCurrencyTotal: this.state.displayTotalAmount,
                userCurrencyPaidNow: this.state.paidNow,
                userCurrencyPaidLater: this.state.paidLater,

                euroRate: this.state.euroRate,

            }
        }


       
        await this.props.client
        .mutate({
            mutation: createBookingMutation,
            variables: {
                input: variablesForInput
            },
        })
        .then(results => {
            
            if (JSON.stringify(results).length > 15) {

                resultOK = true
                //createdProfileId = results.data.createPhotog.id;

            } else {
                toast.notify(
                    <div style={{ "color": "red" }}>Error #194e. Unable to create a booking. Please send us the error number so we could fix it.</div>, 
                    { duration: 10000 }
                    );
            }
            
            //console.log(results.data.createPhotog.id)
        })
        .catch(error => {
            
            toast.notify(
                <div style={{ "color": "red" }}>Error #193e. Unable to create a booking. Please send us the error number so we could fix it.</div>, 
                { duration: 10000 }
                );
            
            // console.log("Error: ", error);
        })


        if (resultOK) { 
            this.props.history.push({ pathname: '../bookings/' })
        }

    }


    async componentDidMount() {
   
        let startDate = this.props.data.dates[0].start;
        let startTime = this.props.data.dates[0].st;
        let endDate = this.props.data.dates[0].end;
        let endTime = this.props.data.dates[0].et;

        let startDateString = "Start: {date & time}";
        let endDateString = "End: {date & time}";
        
        if (this.props.hasOneDate) {
            
            startDateString = "Start: " + moment(this.props.data.dates[0].start).format('MMMM D, YYYY') + ' (' + this.props.data.dates[0].st + ') '
            endDateString = "End: " + moment(this.props.data.dates[0].end).format('MMMM D, YYYY') + ' (' + this.props.data.dates[0].et + ') '
        }

        let hasSameDate = false;
        let sameDateString = "Date: " + moment(this.props.data.dates[0].start).format('MMMM D, YYYY')
        let sameDateTimeString = "Time: " + this.props.data.dates[0].st + ' - ' + this.props.data.dates[0].et
        if ((this.props.hasOneDate) && (this.props.data.dates[0].start === this.props.data.dates[0].end)) {
            hasSameDate = true;
        }


        let durationInMinutes = 0;
        let durationString = "Duration: ";
        if (this.props.data.dates[0].lenDays === 0) {
            if (this.props.data.dates[0].lenHours === 0) {
            } else {
                if (this.props.data.dates[0].lenHours === 1) {
                    durationString = durationString + this.props.data.dates[0].lenHours + " hour"
                } else {
                    durationString = durationString + this.props.data.dates[0].lenHours + " hours"
                }
            }
        } else if (this.props.data.dates[0].lenDays === 1) {
            durationString = durationString + this.props.data.dates[0].lenDays + " day"
        } else {
            durationString = durationString + this.props.data.dates[0].lenDays + " days"
        }
        durationInMinutes = (this.props.data.dates[0].lenDays * 24 * 60) + (this.props.data.dates[0].lenHours)


        let displayCurrencyRate = this.props.displayCurrencyRate ? this.props.displayCurrencyRate : 0;
        let displayCurrency = this.props.displayCurrency;

        let priceInfoString = "";
        if (this.props.data.dates[0].price < 0.1) {
            priceInfoString =  "Price: free"
        } else {

            if (displayCurrencyRate === 0) {
                priceInfoString = "Price: " + parseFloat(this.props.data.dates[0].price).toFixed(2) + ' ' + this.props.data.dates[0].currency
            } else {
                //priceInfoString = "Price: " + parseFloat(dateInfo.price).toFixed(2) + ' ' + dateInfo.currency
                priceInfoString = "Price: " + parseFloat(this.props.data.dates[0].price * displayCurrencyRate).toFixed(2) + ' ' + this.props.displayCurrency 
            
            }
        }

        let eventDesc = []
        for (var i = 0; i < this.props.data.sections.length; i++) {
            eventDesc.push({ 
                "title": this.props.data.sections[0].title[0].title,
                "desc": this.props.data.sections[0].desc[0].desc,
            }); 
        }


        

            if (this.props.hasOneDate) {

                setTimeout(() => { 

                    let startDate = this.props.data.dates[0].start;
                    let startTime = this.props.data.dates[0].st;
                    let endDate = this.props.data.dates[0].end;
                    let endTime = this.props.data.dates[0].et;

                    let sameDateString = "Date: " + moment(this.props.data.dates[0].start).format('MMMM D, YYYY')
                    let sameDateTimeString = "Time: " + this.props.data.dates[0].st + ' - ' + this.props.data.dates[0].et
                    let startDateString = "Start: " + moment(this.props.data.dates[0].start).format('MMMM D, YYYY') + ' (' + this.props.data.dates[0].st + ') '
                    let endDateString = "End: " + moment(this.props.data.dates[0].end).format('MMMM D, YYYY') + ' (' + this.props.data.dates[0].et + ') '

                    
                    let hasSameDate = false;
                    if (this.props.data.dates[0].start === this.props.data.dates[0].end) {
                        hasSameDate = true;
                    } 

                    let durationInMinutes = 0;
                    let durationString = "Duration: ";
                    if (this.props.data.dates[0].lenDays === 0) {
                        if (this.props.data.dates[0].lenHours === 0) {
                        } else {
                            if (this.props.data.dates[0].lenHours === 1) {
                                durationString = durationString + this.props.data.dates[0].lenHours + " hour"
                            } else {
                                durationString = durationString + this.props.data.dates[0].lenHours + " hours"
                            }
                        }
                    } else if (this.props.data.dates[0].lenDays === 1) {
                        durationString = durationString + this.props.data.dates[0].lenDays + " day"
                    } else {
                        durationString = durationString + this.props.data.dates[0].lenDays + " days"
                    }
                    durationInMinutes = (this.props.data.dates[0].lenDays * 24 * 60) + (this.props.data.dates[0].lenHours)


                    const commissionMin = 10;
                    const proCommissionMin = 10;
                    //const maxCommission = 100;

                    let commissionRate = 0.15;
                    if (!this.props.data.isPro) {
                        commissionRate = 0.20
                    }

                    let packagePrice = this.props.data.dates[0].price;
                    let currency = this.props.data.dates[0].currency;


                    let priceInfoString = "";
                    if (this.props.data.dates[0].price < 0.1) {
                        priceInfoString =  "Price: free"
                        
                        
                    } else {
                        
                        if (this.props.displayCurrencyRate === 0) {
                            priceInfoString = "Price: " + parseFloat(packagePrice).toFixed(2) + ' ' + this.props.data.dates[0].currency
                        } else {
                            //priceInfoString = "Price: " + parseFloat(dateInfo.price).toFixed(2) + ' ' + dateInfo.currency
                            priceInfoString = "Price: " + parseFloat(packagePrice * this.props.displayCurrencyRate).toFixed(2) + ' ' + this.props.displayCurrency 
                        
                        }
                        
                        
                    }

                    // console.log('priceInfoString', priceInfoString)
                    


                    let displayPrice = parseFloat(packagePrice * this.props.displayCurrencyRate).toFixed(2);

                    let displayBookingFee = 0;
                    let bookingFee = 0;
                    let nonProMin = commissionMin / this.props.euroRate * this.props.displayCurrencyRate; // minimum fee we charge (matching the display currency). If package price is less, it's paid by client who books, as a booking fee
                    let proMin = proCommissionMin / this.props.euroRate * this.props.displayCurrencyRate; // minimum fee we charge (matching the display currency). If package price is less, it's paid by client who books, as a booking fee
                    
                    //let chargeMinServiceFee = false;

                    if (this.props.data.isPro) {
                        if (displayPrice < proMin) {
                            bookingFee = parseFloat(proMin) - parseFloat(displayPrice);
                            displayBookingFee = parseFloat(proMin) - parseFloat(displayPrice)
                            //chargeMinServiceFee = true;
                        }
                    } else {
                        
                        if (displayPrice < nonProMin) {
                            bookingFee = parseFloat(nonProMin) - parseFloat(displayPrice);
                            displayBookingFee = parseFloat(nonProMin) - parseFloat(displayPrice)
                            //chargeMinServiceFee = true;
                        }
                    }


                    let totalAmount = parseFloat(parseFloat(packagePrice) + parseFloat(bookingFee)).toFixed(2) * 1;
                    let displayTotalAmount = parseFloat(parseFloat(displayPrice) + parseFloat(displayBookingFee)).toFixed(2) * 1;

                    let parsitudDisplayPriceForAnomalyRemoval = parseFloat(displayPrice).toFixed(2) * 1;

                    let paidNow = parseFloat(parsitudDisplayPriceForAnomalyRemoval * commissionRate).toFixed(2) * 1;
                    if (paidNow < nonProMin) {
                        paidNow = parseFloat(nonProMin).toFixed(2) * 1;;
                    }
                    

                    // if (paidNow > maxCommission) {
                    //     paidNow = maxCommission;
                    // }
                    
                    let paidLater = parseFloat(parseFloat(parsitudDisplayPriceForAnomalyRemoval) - parseFloat(paidNow)).toFixed(2) * 1;
                    if (paidLater < 0) {
                        paidLater = 0;
                    }

                    let eventDesc = [];
                    for (var i = 0; i < this.props.data.sections.length; i++) {
                        eventDesc.push({ 
                            "title": this.props.data.sections[i].title[0].title,
                            "desc": this.props.data.sections[i].desc[0].desc,
                        }); 
                    }

                    let objektike = { 
                        bookingPackageId: this.props.data.dates[0].id, 
                        startDate, endDate, startTime, endTime,
                        eventDesc,
                        bookingPackageDuration: durationInMinutes,
                        
                        sameDateString, 
                        sameDateTimeString, 
                        startDateString, 
                        endDateString, 
                        hasSameDate,
                        durationString,
                        priceInfoString,

                        packagePrice: packagePrice,
                        currency: currency,
                        bookingFee: bookingFee,
                        totalAmount: totalAmount,
                        paidNow: paidNow,
                        paidLater: paidLater,
                        
                        displayPrice: parsitudDisplayPriceForAnomalyRemoval,
                        displayBookingFee: displayBookingFee,
                        displayTotalAmount: displayTotalAmount,
                        
                        packagePriceString: parseFloat(displayPrice).toFixed(2) + ' ' + this.props.displayCurrency,
                        bookingFeeString: parseFloat(displayBookingFee).toFixed(2) + ' ' + this.props.displayCurrency,
                        totalAmountString: parseFloat(displayTotalAmount).toFixed(2) + ' ' + this.props.displayCurrency,
                        paidNowString: parseFloat(paidNow).toFixed(2) + ' ' + this.props.displayCurrency,
                        paidLaterString: parseFloat(paidLater).toFixed(2) + ' ' + this.props.displayCurrency,

    
                    }

                    // console.log(objektike);

                    this.setState(objektike);


                }, 50);

            } else {

                this.setState({ 
                    bookingPackageId: "",
                    startDate, endDate, startTime, endTime,
                    bookingPackageDuration: durationInMinutes,
                    displayCurrencyRate: displayCurrencyRate,
                    euroRate: this.props.euroRate ? this.props.euroRate : 0,
                    displayCurrency: displayCurrency,
                    bookingLocation: this.props.data.address,
                    packageName: this.props.data.title[0].title,
                    errorMsg: '',
                    startDateString,
                    endDateString,
                    hasSameDate,
                    sameDateString,
                    sameDateTimeString,
                    durationString,
                    priceInfoString,
                    cancelAfter: this.props.data.cancelAfter,
                    cancelBefore: this.props.data.cancelBefore,
                    chkNoLateBookingCancels: this.props.data.chkNoLateBookingCancels,
                    lateBookingHours: this.props.data.lateBookingHours,
                    eventDesc,

                });

            }

        


    }    



    render() {
      
        moment.locale(i18next.language);

        // console.log(' ------- STATE on booking modal: ', this.state)
        // console.log(' ------- PROPS on booking modal: ', this.props)

        // let starRating = '';
        // if (this.props.data.avgRating < 1.5) { starRating = '⭐☆☆☆☆' }
        // if (this.props.data.avgRating >= 1.5) { starRating = '⭐⭐☆☆☆' }
        // if (this.props.data.avgRating >= 2.5) { starRating = '⭐⭐⭐☆☆' }
        // if (this.props.data.avgRating >= 3.5) { starRating = '⭐⭐⭐⭐☆' }
        // if (this.props.data.avgRating >= 4.5) { starRating = '⭐⭐⭐⭐⭐' }

        const eventInfo = this.props.data;

        const hasOneDate = this.props.hasOneDate

        const locationString = "Location: " + this.props.defaultAddress

        const momentToday = moment(today);

        //when selecting particular date, set state:
        //state.startDateString = "Start: October 26, 2022 at 10:00"
        //state.endDateString = "End: October 29, 2022 at 14:00"
        

        // console.log(oneDateId)
        // console.log(eventInfo)

        let renderPriceInfo = (
            <Section>

                <SectionTitle>
                    
                    <SectionHeadline>
                        Price & payment
                    </SectionHeadline>

                </SectionTitle>

                <SectionBody error={this.state.bookingPackageId === '' ? true : false}>
                    
                    {
                        this.state.bookingPackageId === '' ?
                        <>For the price info, please select when you would like to participate</>
                        :
                        <>
                        
                        <Tbl>
                            <tbody>
                            { this.state.displayBookingFee !== 0 ? 
                            

                                
                                <TblRow>
                                    <TblColLeft>
                                        Event price:<br/>
                                        Minimum booking and service fee:<br/>
                                        <br/>
                                    </TblColLeft>
                                    <TblColRight>
                                        {this.state.packagePriceString}<br/>
                                        {this.state.bookingFeeString}<br/>
                                        <br/>
                                    </TblColRight>
                                </TblRow>
                                : null 
                            }

                            <TblRow>
                                <TblColLeft>
                                    Paid now (booking and service fee):<br/>
                                </TblColLeft>
                                <TblColRight>
                                    {this.state.paidNowString}
                                </TblColRight>
                            </TblRow>
                            
                            { this.state.paidLater === 0 ? 
                                null
                                :
                                <TblRow>
                                    <TblColLeft>
                                        To be paid later:
                                        {/* Paid later (after the photoshoot is over) */}
                                        {/* Paid later (after photos are delivered) */}
                                    </TblColLeft>
                                    <TblColRight>
                                        {this.state.paidLaterString}
                                    </TblColRight>
                                </TblRow>
                            }

                            <TblRow><td></td><td></td></TblRow>
                            <TblRow><td></td><td></td></TblRow>

                            <TblRow>
                                <TblColLeft>
                                    <strong>Total:</strong>
                                </TblColLeft>
                                <TblColRight>
                                    <strong>{this.state.totalAmountString}</strong>
                                </TblColRight>
                            </TblRow>
                            </tbody>
                        </Tbl>
                    
                        <IncreaseHeight> </IncreaseHeight>

                        <PricingRow>
                            
                            { this.findPaymentInstructions() }

                        </PricingRow>

                        {/* Offer options: */}
                        {/*     * Pay in full.  */}
                        {/*     * Pay the total now and you're all set */}
                        {/*     * Pay €XX.XX now, and the rest (€YY.YY) will be automatically charged to the same payment method on Apr 11, 2022. No extra fees. */}
                        
                        </>

                    }
                    
                    
                </SectionBody>

            </Section>
        )

        let renderCancellationAndTerms = (
            <>
                {/* Cancellation Policy */}
                <Section>

                    <SectionTitle>
                        
                        <SectionHeadline>
                            Cancellation policy
                        </SectionHeadline>

                    </SectionTitle>

                    <SectionBody>
                        {
                            this.findCancellationPolicy(this.props.data, this.state.bookingDate)
                        }
                    </SectionBody>
        
                </Section>
        
                    <Section> <Separator/> </Section>

                    {/* Terms */}
                    <Section>

                        <TermsText>
                            By confirming with the button below, I agree to the Cancellation Policy and 
                            <TermsLink term="refundPolicy" onClick={() => setTimeout(() => { this.setState({ showTermsRefund: true, termsType: 'refundPolicy' }); }, 50) }>Refund Policy</TermsLink>. 
                            I also agree to the  
                            <TermsLink term="terms" onClick={() => setTimeout(() => { this.setState({ showTermsPayment: true, termsType: 'terms' }); }, 50) }>Terms of Service</TermsLink>,
                            <TermsLink term="paymentTerms" onClick={() => setTimeout(() => { this.setState({ showTermsPayment: true, termsType: 'paymentTerms' }); }, 50) }>Payments Terms of Service</TermsLink>, and I acknowledge the
                            <TermsLink term="privacy" onClick={() => setTimeout(() => { this.setState({ showTermsRefund: true, termsType: 'privacy' }); }, 50) }>Privacy Policy</TermsLink>. 


                            {/* {
                                this.state.totalAmountString !== '' ?
                                    `I also agree to pay the total amount of ${this.state.totalAmountString}, which includes the services described in the selected package, and all the fees to book this package.`
                                    :
                                    null
                            } */}
                        </TermsText>
                        {/* <br/>
                        <TermsText>
                            {
                                this.state.totalAmountString !== '' ?
                                    `You will be charged only after the photographer has confirmed the booking. If your booking is declined or not confirmed within 24 hours, your will not be charged. We will send you e-mail updates about your booking status.`
                                    :
                                    null
                            }
                        </TermsText> */}


                        { this.state.showTermsPayment || this.state.showTermsRelease || this.state.showTermsRefund ?
                            <div className='react-confirm-alert-overlay'> 
                                <div className='react-confirm-alert-body-min80'>
                                    <div className="bodywrapper">

                                    <BackButton onClick={ () => setTimeout(() => { this.setState({ showTermsPayment: false, showTermsRelease: false, showTermsRefund: false }); }, 50) } >
                                        <FAIcon icon={faTimes} color={'#000'} />
                                    </BackButton>
                                    <br/>

                                        <Query query={ 
                                            this.state.termsType === 'refundPolicy' ? refundPolicyQuery
                                            : this.state.termsType === 'terms' ? termsQuery
                                            : this.state.termsType === 'paymentTerms' ? paymentTermsQuery
                                            : this.state.termsType === 'privcy' ? privacyQuery
                                            : termsQuery
                                        }>
                                            {({ data }) => {
                                                
                                                console.log(this.state.termsType)
                                                if (data === undefined) {
                                                    return (<div>Sorry, we're having technical problems at this moment. Please try again later.</div>)    
                                                } else
                                                
                                                    if (JSON.stringify(data).length > 15) {
                                                        const cleanKey = DOMPurify.sanitize(data.getKey.value);
                                                        const displayKey = parse(cleanKey);
                                                        return (
                                                            <div>{displayKey}</div>
                                                        );
                                                    }
                                                
                                                return <div>Loading ...</div>
                                            }}
                                        </Query>

                                    <br/><br/>
                                    <BackButton onClick={ () => setTimeout(() => { this.setState({ showTermsPayment: false, showTermsRelease: false, showTermsRefund: false }); }, 50) } >
                                        <FAIcon icon={faTimes} color={'#000'} />
                                    </BackButton>

                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }


                    </Section>
            </>                        
        )


        if (this.state.proceedWithStripe === true) {

            const appearance = { theme: 'stripe', };

            const options = {
                clientSecret: this.state.stripeClientSecret,
                appearance,
            };

            return (
            
                <ExtraWrapper>


                    <Header>
                        <Link to="/">
                            <img src={require("./../../assets/PhotoSouls-logo.svg")} className="Logo" alt="logo" />
                        </Link>
                    </Header>

                    <BookingContainer>

                        <ModalTitle>

                            <BackButton onClick={ () => { setTimeout(() => { this.setState({ proceedWithStripe: false, stripeIntentCreatedWith: 0, }); }, 50) } } >
                                <FAIcon icon={faChevronLeft} color={'#000'} />
                            </BackButton>

                            <TitleHeadline>
                                Confirm your event booking
                                {/* If no availability info is set for the selected day, have a title:  */}
                                {/* Request to book a photoshoot */}
                            </TitleHeadline>

                        </ModalTitle>

                        <Elements options={options} stripe={stripePromise}>

                            <CheckoutForm 
                                postBooking={this.postBooking}
                                renderPriceInfo={renderPriceInfo}
                                renderCancellationAndTerms={renderCancellationAndTerms}
                                instantBooking={((this.state.instantBooking === true) && (this.state.calendarAvailabilityConfirmed === true)) ? true : false}
                            />

                        </Elements>
                                
                    </BookingContainer>

                </ExtraWrapper>
            
            
            );

        } else {


            return (
            
                <ExtraWrapper>


                    <Header>
                        <Link to="/">
                            <img src={require("./../../assets/PhotoSouls-logo.svg")} className="Logo" alt="logo" />
                        </Link>


                    </Header>

                    
                    <BookingContainer>

                        
                    {/* Title */}
                    <ModalTitle>

                        <BackButton onClick={ () => { this.props.hideModal() } } >
                            <FAIcon icon={faChevronLeft} color={'#000'} />
                        </BackButton>


                        <TitleHeadline>
                            Book your spot
                            {/* If no availability info is set for the selected day, have a title:  */}
                            {/* Request to book a photoshoot */}
                        </TitleHeadline>

                    </ModalTitle>


                    
                        {/* Select Dates */}
                        <Section>

                            <SectionTitle>
                                
                                <SectionHeadline>
                                {
                                    (hasOneDate || this.state.bookingPackageId !== '') ?
                                        <>
                                            Event info:
                                        </>
                                    :
                                        <>
                                            Select when you would like to participate:
                                        </>
                                    
                                }
                                
                                    
                                </SectionHeadline>

                            </SectionTitle>



                            <SectionBody>
                                
                                {
                                
                                    this.state.bookingPackageId === '' ?
                                        <>
                                            {
                                                eventInfo.dates.map((dateInfo, index) => {

                                                    let startDate = dateInfo.start;
                                                    let startTime = dateInfo.st;
                                                    let endDate = dateInfo.end;
                                                    let endTime = dateInfo.et;

                                                    let sameDateString = "Date: " + moment(dateInfo.start).format('MMMM D, YYYY')
                                                    let sameDateTimeString = "Time: " + dateInfo.st + ' - ' + dateInfo.et
                                                    let startDateString = "Start: " + moment(dateInfo.start).format('MMMM D, YYYY') + ' (' + dateInfo.st + ') '
                                                    let endDateString = "End: " + moment(dateInfo.end).format('MMMM D, YYYY') + ' (' + dateInfo.et + ') '

                                                    
                                                    let hasSameDate = false;
                                                    if (dateInfo.start === dateInfo.end) {
                                                        hasSameDate = true;
                                                    } 

                                                    let durationInMinutes = 0;
                                                    let durationString = "Duration: ";
                                                    if (dateInfo.lenDays === 0) {
                                                        if (dateInfo.lenHours === 0) {
                                                        } else {
                                                            if (dateInfo.lenHours === 1) {
                                                                durationString = durationString + dateInfo.lenHours + " hour"
                                                            } else {
                                                                durationString = durationString + dateInfo.lenHours + " hours"
                                                            }
                                                        }
                                                    } else if (dateInfo.lenDays === 1) {
                                                        durationString = durationString + dateInfo.lenDays + " day"
                                                    } else {
                                                        durationString = durationString + dateInfo.lenDays + " days"
                                                    }
                                                    durationInMinutes = (dateInfo.lenDays * 24 * 60) + (dateInfo.lenHours)


                                                    const commissionMin = 10;
                                                    const proCommissionMin = 10;
                                                    //const maxCommission = 100;


                                                    let commissionRate = 0.15;
                                                    if (!this.props.data.isPro) {
                                                        commissionRate = 0.20
                                                    }

                                                    let packagePrice = dateInfo.price;
                                                    let currency = dateInfo.currency;

                                                    let priceInfoString = "";
                                                    if (dateInfo.price < 0.1) {
                                                        priceInfoString =  "Price: free"
                                                        
                                                        
                                                    } else {
                                                        
                                                        if (this.state.displayCurrencyRate === 0) {
                                                            priceInfoString = "Price: " + parseFloat(packagePrice).toFixed(2) + ' ' + dateInfo.currency
                                                        } else {
                                                            //priceInfoString = "Price: " + parseFloat(dateInfo.price).toFixed(2) + ' ' + dateInfo.currency
                                                            priceInfoString = "Price: " + parseFloat(packagePrice * this.state.displayCurrencyRate).toFixed(2) + ' ' + this.state.displayCurrency 
                                                        
                                                        }
                                                        
                                                        
                                                    }

                                                    let displayPrice = parseFloat(packagePrice * this.state.displayCurrencyRate).toFixed(2);
        
                                                    let displayBookingFee = 0;
                                                    let bookingFee = 0;
                                                    let nonProMin = commissionMin / this.state.euroRate * this.state.displayCurrencyRate; // minimum fee we charge (matching the display currency). If package price is less, it's paid by client who books, as a booking fee
                                                    let proMin = proCommissionMin / this.state.euroRate * this.state.displayCurrencyRate; // minimum fee we charge (matching the display currency). If package price is less, it's paid by client who books, as a booking fee
                                                    
                                                    //let chargeMinServiceFee = false;

                                                    if (this.props.data.isPro) {
                                                        if (displayPrice < proMin) {
                                                            bookingFee = parseFloat(proMin) - parseFloat(displayPrice);
                                                            displayBookingFee = parseFloat(proMin) - parseFloat(displayPrice)
                                                            //chargeMinServiceFee = true;
                                                        }
                                                    } else {
                                                        if (displayPrice < nonProMin) {
                                                            bookingFee = parseFloat(nonProMin) - parseFloat(displayPrice);
                                                            displayBookingFee = parseFloat(nonProMin) - parseFloat(displayPrice)
                                                            //chargeMinServiceFee = true;
                                                        }
                                                    }
                                                
                                                    
                                                    let totalAmount = parseFloat(parseFloat(packagePrice) + parseFloat(bookingFee)).toFixed(2) * 1;
                                                    let displayTotalAmount = parseFloat(parseFloat(displayPrice) + parseFloat(displayBookingFee)).toFixed(2) * 1;

                                                    let parsitudDisplayPriceForAnomalyRemoval = parseFloat(displayPrice).toFixed(2) * 1;

                                                    let paidNow = parseFloat(parsitudDisplayPriceForAnomalyRemoval * commissionRate).toFixed(2) * 1;
                                                    if (paidNow < nonProMin) {
                                                        paidNow = parseFloat(nonProMin).toFixed(2) * 1;;
                                                    }

                                                    //if (paidNow > maxCommission) {
                                                    //    paidNow = maxCommission;
                                                    //}
                                                    
                                                    let paidLater = parseFloat(parseFloat(parsitudDisplayPriceForAnomalyRemoval) - parseFloat(paidNow)).toFixed(2) * 1;
                                                    if (paidLater < 0) {
                                                        paidLater = 0;
                                                    }

                                                    let eventDesc = [];
                                                    for (var i = 0; i < eventInfo.sections.length; i++) {
                                                        eventDesc.push({ 
                                                            "title": eventInfo.sections[i].title[0].title,
                                                            "desc": eventInfo.sections[i].desc[0].desc,
                                                        }); 
                                                    }


                                                    let applyDateDetails = {
                                                        bookingPackageId: hasOneDate ? this.state.bookingPackageId : dateInfo.id, 
                                                        startDate, endDate, startTime, endTime,
                                                        eventDesc,
                                                        bookingPackageDuration: durationInMinutes,
                                                        
                                                        sameDateString, 
                                                        sameDateTimeString, 
                                                        startDateString, 
                                                        endDateString, 
                                                        hasSameDate,
                                                        durationString,
                                                        priceInfoString,

                                                        packagePrice: packagePrice,
                                                        currency: currency,
                                                        bookingFee: bookingFee,
                                                        totalAmount: totalAmount,
                                                        paidNow: paidNow,
                                                        paidLater: paidLater,
                                                        
                                                        displayPrice: parsitudDisplayPriceForAnomalyRemoval,
                                                        displayBookingFee: displayBookingFee,
                                                        displayTotalAmount: displayTotalAmount,
                                                        
                                                        packagePriceString: parseFloat(displayPrice).toFixed(2) + ' ' + this.state.displayCurrency,
                                                        bookingFeeString: parseFloat(displayBookingFee).toFixed(2) + ' ' + this.state.displayCurrency,
                                                        totalAmountString: parseFloat(displayTotalAmount).toFixed(2) + ' ' + this.state.displayCurrency,
                                                        paidNowString: parseFloat(paidNow).toFixed(2) + ' ' + this.state.displayCurrency,
                                                        paidLaterString: parseFloat(paidLater).toFixed(2) + ' ' + this.state.displayCurrency,

                                                    }

                                                

                                                    if (moment(dateInfo.start) > momentToday) {
                                                        return (

                                                            <TblRowBox>
                                                                
                                                                <Tbl2
                                                                    key={index} 
                                                                    onClick={() => this.applyDate(applyDateDetails) }
                                                                >
                                                                    <tbody> 
                                                                    <TblRow2>
                                                                        
                                                                            <TblColLeft2>
                                                                            
                                                                                {
                                                                                    hasSameDate ?
                                                                                    <>
                                                                                        
                                                                                        {sameDateString}<br/>
                                                                                        {sameDateTimeString}<br/>
                                                                                        <br/>
                                                                                        {durationString}<br/>
                                                                                        {priceInfoString}<br/>
                                                                                        {locationString}
                                                                                                
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        
                                                                                        {startDateString}<br/>
                                                                                        {endDateString}<br/>
                                                                                        <br/>
                                                                                        {durationString}<br/>
                                                                                        {priceInfoString}<br/>
                                                                                        {locationString}
                                                                                                
                                                                                    </>
                                                                                }
                                                                            
                                                                            </TblColLeft2>

                                                                            <TblColRight2>
                                                                                <SectionBtn 
                                                                                    onClick={() => this.applyDate(applyDateDetails) }
                                                                                >
                                                                                    Select
                                                                                </SectionBtn>

                                                                            </TblColRight2>

                                                                    </TblRow2>
                                                                    </tbody>
                                                                </Tbl2>

                                                            </TblRowBox>

                                                        )
                                                    } else {
                                                        return (<></>)
                                                    }


                                                })
                                                
                                            }
                                        </>
                                        :

                                        <TblRowBox>
                                                                
                                            <Tbl2>
                                                <tbody> 
                                                <TblRow2>
                                                    
                                                        <TblColLeft2>
                                                        
                                                            {
                                                                this.state.hasSameDate ?
                                                                <>
                                                                    
                                                                    {this.state.sameDateString}<br/>
                                                                    {this.state.sameDateTimeString}<br/>
                                                                    <br/>
                                                                    {this.state.durationString}<br/>
                                                                    {this.state.priceInfoString}<br/>
                                                                    {locationString}
                                                                            
                                                                </>
                                                                :
                                                                <>
                                                                    
                                                                    {this.state.startDateString}<br/>
                                                                    {this.state.endDateString}<br/>
                                                                    <br/>
                                                                    {this.state.durationString}<br/>
                                                                    {this.state.priceInfoString}<br/>
                                                                    {locationString}
                                                                            
                                                                </>
                                                            }
                                                        
                                                        </TblColLeft2>

                                                        <TblColRight2>
                                                            
                                                            { this.props.hasOneDate ? null : 
                                                            <SectionBtn onClick={() => setTimeout(() => { this.setState({ bookingPackageId: '',  }); }, 50) }>
                                                                Edit
                                                            </SectionBtn>
                                                            }

                                                        </TblColRight2>

                                                </TblRow2>
                                                </tbody> 
                                            </Tbl2>


                                        </TblRowBox>

                                        
                                }
                                
                            </SectionBody>


                        </Section>




                        {/* Message */}
                        <Section>

                            <SectionTitle>
                                
                                <SectionHeadline>
                                    Message to the event host
                                </SectionHeadline>

                            </SectionTitle>

                            <SectionBody>
                                Let the host know if you have any questions.
                                
                                <TextareaStyled
                                    name={'messageField'}
                                    value={this.state.messageField}
                                    onChange={this.fldValueChange}
                                    rows={5}
                                />
                            </SectionBody>

                        </Section>

                    
                        
                        { this.state.errorMsg ? ( <Section><IncreaseHeight/><MyError message = {this.state.errorMsg} /></Section>) : null }
                        

                        {/* Book button */}
                        <Section>

                                <BtnBook onClick={ () => { 
                                    window.scrollTo({top: 96, behavior: 'smooth'});
                                    this.validateBooking(); 
                                }}>
                                    Next
                                </BtnBook>

                        </Section>


                        <Section> <IncreaseHeight32/></Section>
                        
                        <IncreaseHeight32/>



                    </BookingContainer>

                    

                </ExtraWrapper>
            
            );

        } 

    }


}

const personalInfoFields = [
    'fName', 'lName', 'bDay', 'email', 'phone', 'phoneDisplay', 'phoneCountry', 'avatar', 'isVerified', 'isPro', 'temperror', 'pwdUpdated',
    'chkReminders', 'chkRemindersP', 'chkMessages', 'chkMessagesP', 'chkRecomm', 'chkRecommP', 'chkInsp', 'chkInspP', 'chkLocal', 'chkLocalP', 
    'chkHow', 'chkHowP', 'chkInvite', 'chkInviteP', 'chkSurvey', 'chkSurveyP', 'chkPolicy', 'chkPolicyP', 'chkTips', 'chkTipsP', 'currency'
]


export const EventBooking = withRouter(withStore(withApollo(C), personalInfoFields));


const ExtraWrapper = styled.div`

    width: calc(100% - 32px);

`;

const BookingContainer = styled.div`

    width: 100%;
    max-width: 760px;
    
    margin: auto;
    margin-top: 96px;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    
    align-items: center;
    justify-content: center;

    /* background-color: #ded; */

    z-index: 200;


`;

const Header = styled.div` 

    position: absolute;
    top: 0; 
    max-height: 72px;
    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    background-color: #fdfdfd;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

`;

const BackButton = styled(Button)`
    && {
        
    /* margin: -4px -4px 0 0px; */

    height: 48px;
    min-width: 48px;
    max-width: 48px;
    border-radius: 24px;

    margin-right: 16px;
    /* margin-right: 4px;
    margin-left: 4px; */

    text-align: center;
    justify-content: center;
    align-items: center;

    color: #333333;

    
    font-weight: 400;
    font-size: 19px;

    background-color: #EBEBEB;
    opacity: 0.6;

    &:hover {
        background-color: #EBEBEB;
        opacity: 0.6;
        }
    
    &:disabled {
        background-color: #fff;
        color: #fff;
        }

    }
    
`;

const FAIcon = styled(FontAwesomeIcon)`
    && {
    font-size: 22px;
    font-weight: 400;
    }
`;


const ModalTitle = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: flex-start;
    justify-content: flex-start;

    /* background-color: #ebe; */

    width: 100%;

    margin-left: 24px;
    /* margin-top: -4px; */
    /* padding-left: 16px; */

    @media (max-width: 970px) {
        flex-direction: column;
    }


`;

const TitleHeadline = styled.div`
    
    padding: 12px 24px 24px 0px;

    font-family: 'Fira Sans';
    font-size: 24px;
    font-weight: 500;

    color: #333333;

`;

const Section = styled.div`
    
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    margin-left: 16px;
    margin-right: 0px;
    padding-right: 8px;
    
    width: calc(100% - 16px);

    align-items: flex-start;
    justify-content: flex-start;

    /* background-color: #fed;  */

    margin-bottom: 32px;

`;




const SectionTitle = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: center;
    justify-content: space-between;

    width: 100%;
    min-height: 40px;

    /* background-color: #ebe; */

`;




const SectionHeadline = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 500;
    font-size: 20px;

    /* color: #505050; */
    /* color: #383838; */
    color: #333333;

    /* background-color: #ded; */

`;


const SectionBody = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 300;
    font-size: 18px;

    line-height: 24px;

    color: ${(props) => props.error ? 'rgba(255, 0, 0, 0.9)' : '#505050' };

    white-space: pre-wrap;

    /* width: calc(100% - 112px); */
    width: 100%;
    

`;



const TermsText = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 14px;

    color: #333333;

`;

const TermsLink = styled.span`
    
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 14px;

    margin-left: 4px;
    color: #333333;

    text-decoration: underline;
    text-underline-offset: 2px;

    &:hover {
        cursor: pointer; 
        opacity: 1;
        
        font-weight: 400;
        }

    /* background-color: #ded; */

`;



const Separator = styled.div`
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: center;
    justify-content: space-between;

    width: 100%;
    
    height: 1px;
    background-color: #cdcdcd;


`;



const BtnBook = styled(Button)`
    && {
    min-height: 40px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    color: white;
    
    padding-left: 16px;
    padding-right: 16px;

    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    
    &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }

}
`;



const SectionBtn = styled.div`
    
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 16px;

    color: #333333;

    text-decoration: underline;
    text-underline-offset: 2px;

    &:hover {
        cursor: pointer; 
        opacity: 1;
        font-weight: 500;
        }

    /* background-color: #ded; */

`;

const TextareaStyled = styled.textarea`
    
    width: calc(100% - 16px);

    padding: 0px;
    margin: 0px;
    /* height: 24px; */
    padding: 8px;
    
    font-size: 18px;
    font-family: 'Fira Sans';
    font-weight: 300;

    color: #333333;
    
    border: 1px solid #cbcbcb;
    border-radius: 4px;  
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    margin-top: 16px; 

    :focus {
        outline-color: #4C9AFF;
    }

    :disabled {
        background-color: #f7f7f7; 
    }

    @media (max-width: 690px) {
        font-size: 18px;
    }
`;




const Tbl = styled.table`
    
    width: 100%;

    border-left: 2px solid #cbcbcb;
    padding-left: 4px;

`;


const Tbl2 = styled.table`
    
    width: 100%;
    min-width: 100%;
    padding-right: 8px;
    
`;


const TblRow = styled.tr`
    
    padding-bottom: 8px;


`;

const TblRow2 = styled.tr`

`;

const TblRowBox = styled.div`
    
    margin-top: 24px;
    margin-bottom: 24px;
    margin-right: 4px;

    font-size: 15px;
    font-weight: 400;
    font-family: 'Fira Sans';
    color: #333333;

    padding: 24px;

    border: 1px solid #cbcbcb;
    border-radius: 5px;

    /* box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2); */
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.3); 
    background-color: #fdfdfd;

    &:hover {
        
        transform: scale(1.02);
        cursor: ${(props) => props.newline ? "cursor": "pointer"};
    }


`;

const TblColLeft = styled.td`
    
    /* background-color: #dede; */
    float: left;

`;

const TblColRight = styled.td`
    
    text-align: right;

    /* background-color: #feb; */
    width: 35%;

`;

const TblColLeft2 = styled.td`
    
    /* background-color: #dede; */
    float: left;

`;

const TblColRight2 = styled.td`
    
    text-align: right;

    /* background-color: #feb; */

`;





const PricingRow = styled.div`
    
    display: float;
    flex-direction: column;
    width: 100%;

    /* border-left: 2px solid #cbcbcb; */
    padding-left: 10px;
    
    /* background-color: #ebe; */

`;




const IncreaseHeight32 = styled.div`
    
    height: 32px;  
    min-height: 32px;

`;

const IncreaseHeight = styled.div`
    
    height: 16px;  
    min-height: 16px;

`;