import React, { PureComponent } from 'react'

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

import "./index.css";
import { FooterSection } from "./_footerSection";
import Button from '@material-ui/core/Button';

import styled from 'styled-components';


export default class Help extends PureComponent {

    componentDidMount() {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }

    render() {

        return (
            
            <>

                <Helmet>
                    <title>PhotoSouls Help Center</title>
                    <meta name="description" content="PhotoSouls Help Center is here to provide you with guidance on using our platform, troubleshooting issues, and enhancing your photography experience." />
                    <meta name="keywords" content="support, help, photosouls" />
                </Helmet>


               
                <Section8>

                    <RowCenter>
                        <H1>PhotoSouls Help Center</H1>

                        <H2Desc>Help Center is coming soon. Until then, please contact us directly with any questions you might have.</H2Desc>

                        <BtnPrimary onClick={ () => this.props.history.push({ pathname: 'contact' }) }>Contact Us</BtnPrimary>

                    </RowCenter>

                </Section8>



                {/* Footer */}
                <FooterSection/>
                    
            </>

        )
    }
}


const Section8 = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;

    background-color: #fff;

    position: relative;
    z-index: 1;
    min-height: 400px;

    @media (max-width: 980px) {
        
        min-height: 600px;

    }

`;


const RowCenter = styled.div`
    
    width: 1170px;

    margin: 0 auto;
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    text-align: center;
    justify-content: center;
    align-items: center;

    padding-top: 24px;
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 980px) {
        
        /* margin-top: 0px; */

    }

`;

const H1 = styled.h1`

    color: #ff9500;
    font-family: 'Fira Sans';
    font-size: 36px;
`;

const H2Desc = styled.div`
    
    width: 100%;

    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    padding-top: 0px;
    padding-bottom: 32px;

    text-align: center;
    justify-content: center;
    align-items: flex-start;

    /* background-color: #ded; */

    font-size: 22px;
    font-family: 'Fira Sans';
    font-weight: 400;


    @media (max-width: 980px) {
        
        /* margin-top: 0px; */

    }

`;

const BtnPrimary = styled(Button)`
    && {
    min-height: 40px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    color: white;
    
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 16px;

    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    
    &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }

}     
`;
