import React, { PureComponent } from 'react'
import { withApollo } from "react-apollo";

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';
import { withStore } from '@spyna/react-store'

import "./index.css";
import { FooterSection } from "./_footerSection";

import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { CategoryButtons } from "./../components/CategoryButtons";


import AskEmailsPermissions from './modals/AskEmailsPermissions'; 
import AskNotificationsPermissions from './modals/AskNotificationsPermissions'; 
import AskLocationPermissions from './modals/AskLocationPermissions'; 

class C extends PureComponent {

    state = {
        showInProgress: false,
        askEmailsPermissions: false,
        askLocationPermissions: false,
        askNotificationsPermissions: false
    };

    
    async componentDidMount() {


        // ReactGA.send("pageview");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        

        // ReactGA.event({
        //     category: "your category",
        //     action: "your action",
        //     label: "your label", // optional
        //     value: 99, // optional, must be a number
        //     nonInteraction: true, // optional, true/false
        //     transport: "xhr", // optional, beacon/xhr/image
        //   });




        // this.setState({ showInProcess: true }, () => {
        //     this.setState({
        //         showInProcess: false,
        //         askEmailsPermissions: this.props.store.get('chkRemindersP')
        //     });
        // });

    }

    render() {

        
        const loggedStatus = this.props.store.get('loggedin');
        
        if (loggedStatus === "false" || loggedStatus === "true") {

            // Loading has finished. At least decided wether logged in or not. It doesn't matter. What matters is that we are finished with initial step and can proceed.

            if (loggedStatus === "true") {

                //console.log(this.props.store.get('userId'))
                
                if (this.props.store.get('chkReminders') === '-') { 
                    this.setState({ askEmailsPermissions: true });
                }
                
                if (this.props.store.get('chkRemindersP') === '-') { 
                    this.setState({ askNotificationsPermissions: true });
                }
                
                if (this.props.store.get('chkLocationSharing') === '-') { 
                    this.setState({ askLocationPermissions: true });
                }
                
            } //loggedStatus === true
            

            return (
                
                
                <ExtraWrapper>

                    <Helmet>
                        <title>PhotoSouls | Photographers, Models, Workshops & Tours</title>
                        <meta name="description" content="Find the right people for better photos and amazing experiences. The best selection of photographers, models, workshops, studios." />
                        <meta name="keywords" content="photography,services,marketplace,photosouls,photographers,models,workshops,studios" />
                        
                    </Helmet>

                    {/* <>
                        { loggedStatus === 'true' ? 
                            
                            <PageMenu>
                                <FilterButton>Photographers</FilterButton>
                                <FilterButton>Models</FilterButton>
                                <FilterButton>Makeup & style</FilterButton>
                                <FilterButton>Studios</FilterButton>
                                <FilterButton>Events</FilterButton>
                            </PageMenu>
                            :
                            null
                        
                        }
                    </> */}
                    
                    {/* Hero: #1 Marketplace for Photography Services */}
                    <Section1>

                        <Row>
                            
                            <Left>

                                <SubHeader>#1 Marketplace for Photography Services</SubHeader>
                                <H1>Find the best talent for amazing photos and unforgettable experiences!</H1>
                                <Header2>From beautiful weddings to tiny newborns, inspiring landscapes to stunning architecture, product shoots, or a company event.</Header2>
                                <Header2>PhotoSouls will match you with the best people to make your photoshoot vision a reality!</Header2>
                                
                                <SectionRow>
                                    <BtnPrimary onClick={ () => this.props.history.push({ pathname: 'find-talent/' }) }>Find Talent</BtnPrimary>
                                    <BtnSecondary onClick={ () => this.props.history.push({ pathname: 'find-work/' }) }>Join as Talent</BtnSecondary>
                                </SectionRow>

                            </Left>

                            <Right>
                                <S1img src={'images/web/S1-img.png'} alt="Variety of photoshoots" />
                            </Right>

                        </Row>

                    </Section1>


                    {/* How does it work */}
                    <Section2>
                        
                        <RowCenter>
                            <H2>How does it work?</H2>
                        </RowCenter>

                        <Row>
    
                            <Col30>
                                <Circle><RoundNumber>1</RoundNumber></Circle>
                                <ItemCenterText>Select when you would like to have a photoshoot, or just browse through portfolios in your area.</ItemCenterText>
                            </Col30>

                            <Col30>
                                <Circle><RoundNumber>2</RoundNumber></Circle>
                                <ItemCenterText>Book your photoshoot with just a few clicks.</ItemCenterText>
                            </Col30>

                            <Col30>
                                <Circle><RoundNumber>3</RoundNumber></Circle>
                                <ItemCenterText>Enjoy your photos!</ItemCenterText>
                                {/* FIXME: FUTURE: Photos from your photoshoot will be available right on PhotoSouls. your funds will be released to the photographer after photos are delivered. This way you can be sure you will get photos, and photograher is sure they are getting paid after photos are delivered. */}
                            </Col30>

                        </Row>

                    </Section2>


                    {/* Popular photoshoots */}
                    <Section3>

                        <RowLeft>
                            <H2>Popular photoshoots</H2>
                        </RowLeft>

                            <CategoryButtons
                                catFilterModal={this.state.catFilterModal}
                                catFilterSet={this.state.catFilterSet}
                                isonMainPage='true'
                                categories='photog'
                                chkNSFW={this.props.store.get("chkNSFW")}
                                winLen={window.innerWidth}
                                onClick={(cat, name) => {
                                    
                                    // if (cat === '000') {
                                    //     //remove category filter
                                    //     setTimeout(() => { this.setState({ catFilterSet: false, catFilterCategory: '', catFilterName: '', catFilterModal: false,  }); }, 50) 
                                    // } else {
                                    //     //set category filter
                                    //     setTimeout(() => { this.setState({ catFilterSet: true, catFilterCategory: cat, catFilterName: name, catFilterModal: false,  }); }, 50) 
                                    // }

                                    this.props.history.push({ pathname: 'photographers' })

                                }}
                            />


                        
                    </Section3>


                    {/* Not just photographers */}
                    <Section2>


                        <RowCenter>
                            <H2>Not just photographers</H2>
                            <H2Desc>PhotoSouls is created to unite everyone taking photos.</H2Desc>

                            <BoxWrapper onClick={ () => this.props.history.push({ pathname: 'photographers' }) }>
                                <TalentImg src={'images/web/photog-360.png'} alt="Photographers near you" />
                                <BoxContent>
                                    Photographers
                                </BoxContent>
                            </BoxWrapper>

                            <BoxWrapper onClick={ () => this.props.history.push({ pathname: 'models' }) }>
                                <TalentImg src={'images/web/model-360.png'} alt="Models in your area" />
                                <BoxContent>
                                    Models
                                </BoxContent>
                            </BoxWrapper>

                            <BoxWrapper onClick={ () => this.props.history.push({ pathname: 'muahs' }) }>
                                <TalentImg src={'images/web/makeup-360.png'} alt="Best makeup artists" />
                                <BoxContent>
                                    Makeup artists & stylists
                                </BoxContent>
                            </BoxWrapper>

                            <BoxWrapper onClick={ () => this.props.history.push({ pathname: 'studios' }) }>
                                <TalentImg src={'images/web/studio-360.png'} alt="Available photo studios" />
                                <BoxContent>
                                    Studios
                                </BoxContent>
                            </BoxWrapper>

                            <BoxWrapper onClick={ () => this.props.history.push({ pathname: 'events' }) }>
                                <TalentImg src={'images/web/tour-360.png'} alt="Learn & develop with workshops" />
                                <BoxContent>
                                    Photo tours & workshops
                                </BoxContent>
                            </BoxWrapper>

                            <H2Desc>It's your go-to source for all photography services to get better photos.</H2Desc>

                        </RowCenter>

                    </Section2>


                    {/* Testimonials */}

                    {/* As seen on */}


                    {/* Join us */}
                    <Section4>

                        <Row>
                            
                            <Left2>
                                <S4img src={'images/web/join.jpg'} alt="Top talent - PhotoSouls photographers and models" />
                            </Left2>

                            <Right2>

                                <H2white>Join PhotoSouls & get more bookings!</H2white>

                                <Header2white>
                                    Our members get access to 1000s of bookings monthly. Select your membership level, create your profile, and start landing new bookings today!
                                </Header2white>
        
                                <Header2white>
                                    <ul>
                                        <li>Unlock new opportunities</li>
                                        <li>Develop your portfolio</li>
                                        <li>Create a network of clients</li>
                                        <li>Build an online reputation</li>
                                    </ul>
                                </Header2white>
                                

                                <BtnWhite onClick={ () => this.props.history.push({ pathname: 'find-work/' }) }>Read More</BtnWhite>
                            
                            </Right2>

                        </Row>


                    </Section4>

                    {/* ??? 7. FAQ */}

                    {/* ??? 8. Blog */}

                    {/* Footer */}
                    <FooterSection/>

                    {/* Ask for permissions */}
                    <>
                        {

                            this.state.askLocationPermissions ?
                                <AskLocationPermissions
                                    apolloClient={this.props.client}
                                    propStore={this.props.store}
                                    closePopup={() => { this.setState({ askLocationPermissions: !this.state.askLocationPermissions }); }}  
                                />
                            : null
                            
                                
                        }

                        {
                            this.state.askNotificationsPermissions ?  
                                <AskNotificationsPermissions
                                    apolloClient={this.props.client}
                                    propStore={this.props.store}
                                    closePopup={() => { this.setState({ askNotificationsPermissions: !this.state.askNotificationsPermissions }); }}  
                                />
                            : null  
                        }

                        {
                            this.state.askEmailsPermissions ?  
                                <AskEmailsPermissions
                                    apolloClient={this.props.client}
                                    propStore={this.props.store}
                                    closePopup={() => { this.setState({ askEmailsPermissions: !this.state.askEmailsPermissions }); }}  
                                />
                            : null  
                        }
                    </>

                </ExtraWrapper>

            )

        } else {
            return null
        }
            
    }
}

const exploreStore = [
    'loggedin', 'userId', 'chkRemindersP', 'chkReminders', 'chkLocationSharing', 
]




export default withStore(withApollo(C), exploreStore);

const ExtraWrapper = styled.div`

    overflow-x: hidden;

`;

// const PageMenu = styled.div`
    
//     margin-top: 16px;
//     margin-right: 8px;
//     margin-left: 24px;

   
//     width: calc(100% - 48px);

//     overflow-x: hidden;
    

// `;


// const FilterButton = styled(Button)`
//     && {
//     height: 40px;

//     padding: 16px;

//     margin-right: 8px;
//     margin-bottom: 8px;

//     background: ${(props) => props.isSet ? '#f5f5f5': "white"};
//     border: ${(props) => props.isSet ? '4px double #cbcbcb;' : "1px solid #cbcbcb;"};

//     border-radius: 20px;  


//     font-family: 'Fira Sans';
//     color: #333333;

    
//     /* box-shadow: ${(props) => props.isSet ? '0 2px 8px 0 rgba(170, 170, 170, 0.4);' : "0"}; */


//     text-transform: none;
//     font-weight: 400;
//     font-size: 17px;

//     &:hover {
//         border-color: #333333;
//         }

//     }
    
// `;



const Section1 = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    
    background-color: #f3f2f0;
    background-image: linear-gradient(#fff,#f3f2f0);

    position: relative;
    z-index: 1;
    min-height: 568px;

    @media (max-width: 980px) {
        
        min-height: 600px;

    }


`;

const Section2 = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;
    max-width: 1170px;
    margin: auto;

    background-color: #ffffff;

    position: relative;
    z-index: 1;

    min-height: 400px;

    @media (max-width: 980px) {
        
        min-height: 600px;

    }

`;


const Section3 = styled.div`
    
    white-space: pre-line;



    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 90%;
    max-width: 1170px;
    
    margin: 0px auto 32px auto;
    
    background-color: #fff;
    /* background-image: linear-gradient(#fff, #f3f2f0); */

       
    position: relative;
    z-index: 1;
    min-height: 360px;

    overflow-x: hidden;



`;


const Section4 = styled.div`
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    width: 100%;

    background-color: #ff9500;

    position: relative;
    z-index: 1;
    min-height: 400px;

    @media (max-width: 980px) {
        
        min-height: 600px;

    }

`;


const Row = styled.div`
    
    width: 1170px;

    margin: 0 auto;
    padding-left: 24px;
    padding-right: 24px;
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    @media (max-width: 980px) {
        
        /* margin-top: 0px; */
        /* text-align: center;
        justify-content: center;
        align-items: center; */

    }

`;



// const Row2 = styled.div`
    
//     width: 1170px;

//     margin: 0 auto;

//     margin-top: -16px;
//     /* padding-left: 32px; */
//     padding-right: 16px;
    
//     display: flex;
//     flex-wrap: wrap;    
//     flex-direction: row;

//     background-color: #ebe;

//     @media (max-width: 980px) {
        
//         /* margin-top: 0px; */
//         /* text-align: center;
//         justify-content: center;
//         align-items: center; */

//     }

// `;

const RowCenter = styled.div`
    
    width: 1170px;

    margin: 0 auto;
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    text-align: center;
    justify-content: center;
    align-items: center;

    padding-top: 48px;
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 980px) {
        
        /* margin-top: 0px; */

    }

`;

const RowLeft = styled.div`
    
    width: 1170px;

    margin: 0 auto;
    
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    text-align: left;
    justify-content: left;
    align-items: left;

    padding-top: 48px;
    padding-bottom: 24px;
    /* padding-left: 32px; */
    padding-right: 32px;

    @media (max-width: 980px) {
        
        /* margin-top: 0px; */
        padding-top: 0px;
    }

`;

const ItemCenterText = styled.div`
    
    width: 100%;

    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    height: 200px;

    padding-top: 32px;
    padding-left: 8px;
    padding-right: 8px;

    text-align: center;
    justify-content: center;
    align-items: flex-start;

    /* background-color: #ded; */

    font-size: 22px;
    font-family: 'Fira Sans';
    font-weight: 400;


    @media (max-width: 980px) {
        
        /* margin-top: 0px; */

    }

`;


const H2Desc = styled.div`
    
    width: 100%;

    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    padding-top: 0px;
    padding-bottom: 32px;

    text-align: center;
    justify-content: center;
    align-items: flex-start;

    /* background-color: #ded; */

    font-size: 22px;
    font-family: 'Fira Sans';
    font-weight: 400;


    @media (max-width: 980px) {
        
        /* margin-top: 0px; */

    }

`;


const Circle = styled.div`

    background-color: #ff9500;
    width: 56px;
    height: 56px;
    border-radius: 32px;

`;


const RoundNumber = styled.div`

    color: #fff;

    font-size: 36px;
    font-family: 'Fira Sans';

    padding-top: 6px;

`;



const Col30 = styled.div`
    
    width: 30%;
    min-width: 300px;

    margin: 0 auto;
    
    top: 0px;
    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    text-align: center;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: 980px) {
        

    }

`;

const BoxWrapper = styled.div`
    
    width: 200px;
    height: 200px;

    /* background-color: #feb; */

    margin: 0 auto;

    margin-bottom: 24px;

    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    text-align: center;
    justify-content: center;
    align-items: flex-start;

    border-radius: 8px;
    border: 1px solid #EBEBEB;
    box-shadow: 0 2px 8px 0 rgba(170, 170, 170, 0.2);

    &:hover {
        transform: scale(1.03);
        cursor: pointer;
    }

`;

const BoxContent = styled.div`
    
    font-size: 22px;
    font-family: 'Fira Sans';
    font-weight: 400;


`;

const Left = styled.div`
    
    width: 50%;

    padding-bottom: 40px;
    padding-right: 24px;
    padding-top: 64px;

    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 32px;

    }
    

`;


const Left2 = styled.div`
    
    width: calc(40%);
    padding-top: 32px;
    padding-bottom: 32px;

    /* background-color: #aea; */

    @media (max-width: 980px) {
        width: 100%;
        padding-bottom: 0px;
        
    }
    

`;

const Right2 = styled.div`
    
    width: calc(60% - 64px);
    padding-top: 32px;
    padding-bottom: 64px;

    padding-left: 32px;

    /* background-color: #ced; */

    @media (max-width: 980px) {
        
        width: 100%;
        padding-left: 0px;
        padding-top: 0px;

    }
    

`;


const Right = styled.div`
    
    width: calc(50%-64px);
    padding-left: 32px;
    padding-bottom: 32px;

    /* background-color: #ced; */

    @media (max-width: 980px) {
        
        width: 100%;
        padding-top: 0px;

    }
    

`;

const SubHeader = styled.div`
    
    color: #333333;

    font-family: 'Fira Sans';
    font-weight: 500;
    font-size: 20px;
    

`;

const H1 = styled.h1`

    margin-top: 20px; 

    color: #ff9500;
    font-family: 'Fira Sans';
    font-size: 36px;

    @media (max-width: 970px) {

        font-size: 32px;

    }

`;


const H2 = styled.h2`

    color: #ff9500;
    font-family: 'Fira Sans';
    font-size: 36px;

    @media (max-width: 970px) {

    font-size: 32px;

    }

`;


const H2white = styled.h2`

    color: #fff;
    font-family: 'Fira Sans';
    font-size: 36px;

    @media (max-width: 970px) {

    font-size: 32px;

    }
    
`;


const Header2 = styled.div`
    
    margin-top: 20px; 

    color: #333333;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 26px;
    
    @media (max-width: 970px) {

        font-size: 24px;

    }

`;

const Header2white = styled.div`
    
    margin-top: 20px; 

    color: #fff;

    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 26px;
    

`;

const SectionRow = styled.div`
    
    width: 100%;
    
    padding-top: 32px;

    display: flex;
    flex-wrap: wrap;    
    flex-direction: row;

    /* background-color: #dea; */

`;




const BtnPrimary = styled(Button)`
    && {
    min-height: 40px;
    
    background: ${(props) => props.disabled ? "white": '#ff9500'};
    box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};

    color: white;
    
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 16px;

    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    
    &:hover {
        background: ${(props) => props.disabled ? "white" : '#ff9500'};
        opacity: 0.9;
        color: white;
        }

}     
`;


const BtnWhite = styled(Button)`
    && {

    min-height: 40px;
    
    background: #fff;
    box-shadow: '0 2px 8px 0 rgba(170, 170, 170, 0.4)';

    color: #ff9500;
    
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 16px;

    text-transform: none;
    font-weight: 400;
    font-size: 17px;
    
    &:hover {
        background: #fff;
        color: #ff9500;
        opacity: 0.7;
        }

}     
`;

const BtnSecondary = styled(Button)`
    && {
    
    display: flex;

    min-height: 40px;
    
    padding-left: 16px;
    padding-right: 16px;

    margin-right: 16px;

    text-transform: none;
    text-decoration: underline;
    text-underline-offset: 2px;

    font-weight: 400;
    font-size: 17px;
    color: #333333;
    
    &:hover {
        opacity: 0.9;
        box-shadow: ${(props) => props.disabled ? "0" : '0 2px 8px 0 rgba(170, 170, 170, 0.4);'};
        text-decoration: underline;
        }
    }
`;

const S1img = styled.img`

    margin-top: 48px;
    width: 432px;

    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1150px) {

        margin-top: 72px;
        width: 364px;

    }

    @media (max-width: 970px) {

        margin-top: 0px;
        width: 304px;

       

    }

`;

const S4img = styled.img`

    margin-top: 32px;
    width: 440px;

    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1180px) {

        margin-top: 32px;
        width: 364px;

    }

    @media (max-width: 970px) {

        margin-top: 0px;
        width: 340px;

    }


`;



const TalentImg = styled.img`

    margin: 8px;
    width: 180px;
    height: 120px;
    margin-bottom: 0px;

`;

