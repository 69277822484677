
import * as React from 'react';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom'

import CookieConsent from "react-cookie-consent"

import HeaderDecider from '../modules/components/Header/HeaderDecider'

//import Footer from '../modules/components/Footer/Footer'

import { AuthRoute } from "./AuthRoute";
import { ProfileRedirect } from "./ProfileRedirect";

import Index from '../modules/index/index';
import FindWork from '../modules/index/find-work';
import FindTalent from '../modules/index/find-talent';
import BecomeAPhotographer from '../modules/index/become-a-photographer';
import BecomeAModel from '../modules/index/become-a-model';
import BecomeAMuah from '../modules/index/become-a-muah';
import ListYourStudio from '../modules/index/list-your-studio';
import ListYourEvent from '../modules/index/list-your-event';
import Help from '../modules/index/help';
import About from '../modules/index/about';
import Sitemap from '../modules/index/sitemap';
import Contact from '../modules/index/contact';
import BecomeAnAffiliate from '../modules/index/become-an-affiliate';

//import TermsOfService from '../modules/index/terms/terms-of-service';
//import Privacy from '../modules/index/terms/privacy-policy';
//import CookiePolicy from '../modules/index/terms/cookie-policy';
import Terms from '../modules/index/terms';


// import { SignupConnector } from '../modules/user/signup/SignupConnector';
import { SignupConnector } from '../modules/user/signup/SignupConnector';
import { LoginConnector } from '../modules/user/login/LoginConnector';
import { ForgotPasswordConnector } from '../modules/user/forgotpassword/ForgotPasswordConnector';
import { ChangePasswordConnector } from '../modules/user/changepassword/ChangePasswordConnector';
import { Logout } from '../modules/user/logout';

import AccountSettings from '../modules/user/settings';
import AccountSettingsPersonal from '../modules/user/settings/personal';
import AccountSettingsLogin from '../modules/user/settings/login';
import AccountSettingsPayments from '../modules/user/settings/payments';
import AccountSettingsNotifications from '../modules/user/settings/notifications';
import AccountSettingsGlobal from '../modules/user/settings/global';

import { Profiles } from '../modules/user/profiles';

import { ListPhotogs } from '../modules/photogs/ListPhotogs';
import { ViewPhotog } from '../modules/photogs/ViewPhotog';
import { EditPhotog } from '../modules/user/profiles/EditPhotog';

import { ListModells } from '../modules/models/ListModells';
import { ViewModell } from '../modules/models/ViewModell';
import { EditModell } from '../modules/user/profiles/EditModell';

import { ListMuahs } from '../modules/muahs/ListMuahs';
import { ViewMuah } from '../modules/muahs/ViewMuah';
import { EditMuah } from '../modules/user/profiles/EditMuah';

import { ListStudios } from '../modules/studios/ListStudios';
import { ViewStudio } from '../modules/studios/ViewStudio';
import { EditStudio } from '../modules/user/profiles/EditStudio';

import { ListEvents } from '../modules/events/ListEvents';
import { ViewEvent } from '../modules/events/ViewEvent';
import { EditEvent } from '../modules/user/profiles/EditEvent';

import { Feedback } from '../modules/user/feedback';
import { Invite } from '../modules/user/invite';
import { Favorites } from '../modules/favorites';
import { Bookings } from '../modules/bookings';
import { Inbox } from '../modules/inbox';

import ScrollToTop from './ScrollToTop';

import Haldus from '../modules/haldus';

export const Routes = (logeed) => (
    
    <BrowserRouter>

        <HeaderDecider/>

        <ScrollToTop />
        <Switch>

            <Route exact={true} path="/" component={Index} />
            <Route exact={true} path="/find-talent" component={FindTalent} />
            <Route exact={true} path="/find-work" component={FindWork} />
            <Route exact={true} path="/become-a-photographer" component={BecomeAPhotographer} />
            <Route exact={true} path="/become-a-model" component={BecomeAModel} />
            <Route exact={true} path="/become-a-muah" component={BecomeAMuah} />
            <Route exact={true} path="/list-your-studio" component={ListYourStudio} />
            <Route exact={true} path="/list-your-event" component={ListYourEvent} />
            <Route exact={true} path="/become-an-affiliate" component={BecomeAnAffiliate} />
            
            
            <Route exact={true} path="/photographers" component={ListPhotogs} />
            <Route exact={true} path="/models" component={ListModells} />
            <Route exact={true} path="/muahs" component={ListMuahs} />
            <Route exact={true} path="/studios" component={ListStudios} />
            <Route exact={true} path="/events" component={ListEvents} />

            
            <Route exact={true} path="/photographer/:profileId" component={ViewPhotog} />
            <Route exact={true} path="/model/:profileId" component={ViewModell} />
            <Route exact={true} path="/muah/:profileId" component={ViewMuah} />
            <Route exact={true} path="/studio/:profileId" component={ViewStudio} />
            <Route exact={true} path="/event/:profileId" component={ViewEvent} />

            <Route exact={true} path="/help" component={Help} />
            <Route exact={true} path="/about" component={About} />
            <Route exact={true} path="/contact" component={Contact} />

            <Route exact={true} path="/sitemap" component={Sitemap} />
            <Route exact={true} path="/sitemap/models" component={Sitemap} />
            <Route exact={true} path="/sitemap/help-pages" component={Sitemap} />
            <Route exact={true} path="/sitemap/common-pages" component={Sitemap} />

            <Route exact={true} path="/terms" component={Terms} />
            <Route exact={true} path="/terms/:termOrPolicy" component={Terms} />
                        
            <Route exact={true} path="/signup" component={SignupConnector} />
            <Route exact={true} path="/login" component={LoginConnector} />
            <Route exact={true} path="/user/forgot-password" component={ForgotPasswordConnector} />
            <Route exact={true} path="/user/change-password/:key" component={ChangePasswordConnector}/>
            <Route exact={true} path="/user/logout" component={Logout} />

            <AuthRoute exact={true} path="/favorites" component={Favorites} />
            <AuthRoute exact={true} path="/bookings" component={Bookings} />
            <AuthRoute exact={true} path="/inbox" component={Inbox} />
            <AuthRoute exact={true} path="/user/feedback" component={Feedback} />
            <AuthRoute exact={true} path="/user/invite" component={Invite} />

            <AuthRoute exact={true} path="/user/settings" component={AccountSettings} />
            <AuthRoute exact={true} path="/user/settings/personal" component={AccountSettingsPersonal} />
            <AuthRoute exact={true} path="/user/settings/login" component={AccountSettingsLogin} />
            <AuthRoute exact={true} path="/user/settings/payments" component={AccountSettingsPayments} />
            <AuthRoute exact={true} path="/user/settings/notifications" component={AccountSettingsNotifications} />
            <AuthRoute exact={true} path="/user/settings/global" component={AccountSettingsGlobal} />

            <AuthRoute exact={true} path="/user/profiles" component={Profiles} />
            <AuthRoute exact={true} path="/photographer/:profileId/edit" component={EditPhotog} />
            <AuthRoute exact={true} path="/model/:profileId/edit" component={EditModell} />
            <AuthRoute exact={true} path="/muah/:profileId/edit" component={EditMuah} />
            <AuthRoute exact={true} path="/studio/:profileId/edit" component={EditStudio} />
            <AuthRoute exact={true} path="/event/:profileId/edit" component={EditEvent} />

            <AuthRoute exact={true} path="/halduskeskkond" component={Haldus} />

            {/* <Route exact={true} path="/:premiumURL" component={RedirectURL} /> */}
            <ProfileRedirect path="/:URL" component={Index} />



        </Switch>


        <CookieConsent
            buttonStyle={{
                background: "#ff9500",
                color: "white",
                fontWeight: "600",
                fontSize: "16px",
                fontStyle: "Fira Sans",
                height: "48px",
                padding: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",

                
            }}
            contentStyle={{
                fontWeight: "300",
                fontSize: "16px",
                fontStyle: "Fira Sans",
            }}
            expires={365}
            //debug={true}
        >
            In order to give you the best experience, we use cookies and similar technologies for performance, analytics, personalisation, and to help our site function. 
            For more details about cookies and how to manage them, see our <Link to="/terms/cookie-policy" style={{ color: '#fff' }}>Cookie Policy</Link>. 
            By using our website, you’re agreeing to the collection of data as described in our <Link to="/terms/privacy-policy" style={{ color: '#fff' }}>Privacy Policy</Link>.
        </CookieConsent>


    </BrowserRouter>

)
